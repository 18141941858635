import * as React from "react";
import { MyZenNavBar } from "../../common/navbar/MyZenNavBar";
import "../../../styling/common/index.scss";
import "../../../styling/myzen/myzen.scss";
import { ZenNavBar } from "../../common/navbar/ZenNavBar";
import { useState } from "react";
import PrivateRoute from "../../common/PrivateRoute";
import { CreateJournalModal } from "./CreateJournalModal";
import { useZenJournalService } from "../../../hooks/ZenJournalContext";
import { JournalEntryPreview } from "./JournalEntryPreview";

export const MyZenJournal = (props: any) => {

      const [focusedMyZenItem, setFocusedMyZenItem] = useState(props.startFilter ? props.startFilter : "Entries");

      const useJournal = useZenJournalService();

      if (useJournal && useJournal.journalLogs == null) {
            let result =  useJournal.getUserJournal();
      }

      const validateJournalEntry = (entry: any) => {
            if (focusedMyZenItem == "Mood") {
                  return entry != null && entry.mood != null && entry.mood.id != null;
            } else if (focusedMyZenItem == "Practices") {
                  return (entry != null && entry.practiceLog != null)
            } else return true;
      }

      let journalLogs = useJournal.journalLogs && useJournal.journalLogs.length>0? useJournal.journalLogs.filter(e => validateJournalEntry(e)).map(log => <JournalEntryPreview key = {"jep-"+log.id} {...log}></JournalEntryPreview>) : null;

      return (
            <PrivateRoute>
                  <ZenNavBar activeItem="MyZen" primaryColor="#D1603D"></ZenNavBar>
                  <MyZenNavBar />
                  <div className="MyZenJournal">
                        {focusedMyZenItem == 'create-journal' ?
                              <CreateJournalModal setFocusedMyZenItem={(item) => setFocusedMyZenItem(item)} />
                              :
                              <React.Fragment>

                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                          {["Entries", "Practices", "Mood"].map(option =>
                                                <h1 className={focusedMyZenItem == option ? 'myZenPlannerHeader subPageActiveNavHeader' : 'myZenPlannerHeader'} onClick={() => setFocusedMyZenItem(option)}>{option}</h1>
                                          )}
                                    </div>
                                    
                                    <div style={{ display: "flex", flexDirection: "row", marginTop:"15px", paddingBottom: "25px" }}>
                                          <div className="zenGlowButton mingGlowButton" onClick={() => setFocusedMyZenItem("create-journal")}>
                                                Add New Entry
                                          </div>
                                    </div>

                                    {useJournal.journalLogs && useJournal.journalLogs.length > 0 ?
                                          <div className="journal-entries-holder">
                                                {journalLogs}
                                          </div>
                                          : null

                                    }
                              </React.Fragment>
                        }
                  </div>
            </PrivateRoute>
      );

}
