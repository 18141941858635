import React, { useState } from 'react';
import "../../../styling/vacana/vacana.scss";
import "../../../styling/common/index.scss";
import ZenContentInterface from '../practices/ZenContentInterface';
import { Link } from 'react-router-dom';
import { ZenPicSizer } from '../../practitioner/ZenPicSizer';

interface PathIconsInterface extends ZenContentInterface {
    pathDuration : any;
    pathSessionNumber:any;
    username?:any;
    imageCropX?:any;
    imageCropY?:any;
    imageCropWidth?:any;    
}
export const PathIcons = (props : PathIconsInterface) => {

    const durationUnit = props && props.pathDuration && props.pathDuration > 120? "Hours": props && props.pathDuration && props.pathDuration > 60? "Hour" : "Minutes";
    const durationValue = props && props.pathDuration && props.pathDuration > 60? Math.floor(props.pathDuration/60): props.pathDuration;

    return (
      
      <div className = "path-icons">
        {props && props.image?
            <div className =  "path-icon practitioner-icon">
                <Link to={props? "/practitioner/"+props.username : null}>
                <div className = "PractitionerPhotoIcon meditation-icons-practitioner-image" >
                <ZenPicSizer
                                    imageToShow={props.image}
                                    cropX={props.imageCropX}
                                    cropY={props.imageCropY}
                                    cropWidth={props.imageCropWidth}
                                    newWidth={70}
                                />
                </div>
                <h3 className = "PractitionerName"> {props && props.username? props.username : '-'} </h3>
                </Link>
            </div>
            :null
        }
            <div className = "path-icon zenK" style={{color:"#165369" }}>
                <h1 className="pathIconNumber">{props.pathSessionNumber}</h1>
                <h3 className="pathIconText">Sessions</h3>
            </div>
            <div className = "path-icon zenK" style={{color:"#165369" }}>
                <h1 className="pathIconNumber">{durationValue}</h1>
                <h3 className="pathIconText">{durationUnit}</h3>
            </div>
      </div>
    );
}