import * as React from "react";
import "../../../styling/explore/explore.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router";
import ZenInfomaticInterface from "../../zencontent/infomatics/ZenInfomaticInterface";
import { PracticePreview } from "../../zencontent/practices/previews/PracticePreview";
import { ZenNavBar } from "../../common/navbar/ZenNavBar";
import { MyZenNavBar } from "../../common/navbar/MyZenNavBar";
import { useZenCardsService } from "../../../hooks/ZenCardsContext";
import { ZenCardPreview } from "./ZenCardPreview";
import { UnlockZenCardModal } from "./UnlockZenCardModal";
import { ConfirmTranslation } from "../../../content/SignUpTranslations";


export const determineUserOwnsPortal = (userIcons: any [], cardKey:string) => {
        return userIcons && userIcons.length > 0 && userIcons.map(ui => ui.card).includes(cardKey);
}