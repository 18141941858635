import * as React from "react";
import { MyZenNavBar } from "../../common/navbar/MyZenNavBar";
import "../../../styling/common/index.scss";
import "../../../styling/common/zenCoreUIComponents.scss";
import { ZenNavBar } from "../../common/navbar/ZenNavBar";
import { useState } from "react";
import { ZenWeekPlanner } from "./ZenWeekPlanner";
import { ZenBookmarks } from "./ZenBookmarks";
import PrivateRoute from "../../common/PrivateRoute";
import axios from "axios";
import { useAuth } from "../../../hooks/auth";
import ZenWeekPlannerInterface from "../interface/ZenWeekPlannerInterface";
import ZenPracticeSessionInterface from "../interface/ZenPracticeSessionInterface";
import ZenWeekGoalsInterface from "../interface/ZenWeekGoalsInterface";
import { ZenPlannerGenerator } from "./ZenPlannerGenerator";
import { ProvideMyStuffContext } from "../../../hooks/MyStuffContext";
import { Link, useNavigate } from "react-router-dom";
import { ZEN_C_ENDPOINT } from "../../../config/EnvironmentalVariables";
import PuffLoader from "react-spinners/PuffLoader";
import { ZenPopUpMessage } from "../../common/ZenPopUpMessage";

export const getSuggestedSessionName = (sessionType: string) => {
      return sessionType == "meditation"? "Mindful Breathing" : sessionType ==  "Relax"? "Find a nice spot to read." : "Mindful Breathing"
}

export const MyZenPlanner = (props: any) => {

      const [focusedMyZenItem, setFocusedMyZenItem] = useState(props.bookmarks || props.favourites ? "Bookamrks" : "Planner");

      const [plannerSessions, setPlannerSessions] = useState(null);
      const [weekGoals, setWeekGoals] = useState(null);
      const [userRefreshes, setUserRefreshes] = useState(0);
      const [hasLoaded, setHasLoaded] = useState(null);

      const auth = useAuth();

      const [walkthroughStage, setWalkthroughStage] = useState(1);
      var bookmarkClasses = 'myZenPlannerHeader ';
      var plannerClasses = 'myZenPlannerHeader ';

      if (focusedMyZenItem == 'Planner') {
            plannerClasses = plannerClasses + 'subPageActiveNavHeader';

      } else {
            bookmarkClasses = bookmarkClasses + 'subPageActiveNavHeader';

      }

      const forceReload = () => {
            setHasLoaded(false);
            setTimeout(() => getPlannerSessions(),1000);
            setPlannerSessions(-1);
      }

      React.useEffect(() => {
            axios.get(ZEN_C_ENDPOINT+`/myzen/zenPlanner/getPlanner/` + auth.username)
            .then(res => {
                  var blueprintItems: ZenPracticeSessionInterface[] = res.data;
                  if(blueprintItems != null && blueprintItems.length > 0){
                        var userBlueprint: ZenWeekPlannerInterface = {
                              monday: blueprintItems.filter((i) => i.dayOfWeek == "Monday"),
                              tuesday: blueprintItems.filter((i) => i.dayOfWeek == "Tuesday"),
                              wednesday: blueprintItems.filter((i) => i.dayOfWeek == "Wednesday"),
                              thursday: blueprintItems.filter((i) => i.dayOfWeek == "Thursday"),
                              friday: blueprintItems.filter((i) => i.dayOfWeek == "Friday"),
                              saturday: blueprintItems.filter((i) => i.dayOfWeek == "Saturday"),
                              sunday: blueprintItems.filter((i) => i.dayOfWeek == "Sunday")
                        }
                        setPlannerSessions(userBlueprint);
                        setHasLoaded(true);
                  } else {
                        axios.get(ZEN_C_ENDPOINT+`/myzen/zenGoals/getWeeklyGoals/` + auth.username)
                        .then(res => {
                              var weeklyGoals: ZenWeekGoalsInterface[] = res.data;
                              setWeekGoals(weeklyGoals);
                              setHasLoaded(true);
                        }).catch(res => {
                              setHasLoaded(true);
                        });
                  }
            }).catch(res => {
                  axios.get(ZEN_C_ENDPOINT+`/myzen/zenGoals/getWeeklyGoals/` + auth.username)
                  .then(res => {
                        var weeklyGoals: ZenWeekGoalsInterface[] = res.data;
                        setWeekGoals(weeklyGoals);
                        setHasLoaded(true);
                  }).catch(res => {
                        setHasLoaded(true);
                  });
            });
      },[]);



      const getPlannerSessions = async () => {
            await axios.get(ZEN_C_ENDPOINT+`/myzen/zenPlanner/getPlanner/` + auth.username)
                  .then(res => {
                        var blueprintItems: ZenPracticeSessionInterface[] = res.data;
                        var userBlueprint: ZenWeekPlannerInterface = {
                              monday: blueprintItems.filter((i) => i.dayOfWeek == "Monday"),
                              tuesday: blueprintItems.filter((i) => i.dayOfWeek == "Tuesday"),
                              wednesday: blueprintItems.filter((i) => i.dayOfWeek == "Wednesday"),
                              thursday: blueprintItems.filter((i) => i.dayOfWeek == "Thursday"),
                              friday: blueprintItems.filter((i) => i.dayOfWeek == "Friday"),
                              saturday: blueprintItems.filter((i) => i.dayOfWeek == "Saturday"),
                              sunday: blueprintItems.filter((i) => i.dayOfWeek == "Sunday")
                        }
                        setPlannerSessions(userBlueprint);
                        setUserRefreshes(userRefreshes+1);
                        setHasLoaded(true);
                  }).catch(res => {
                        setHasLoaded(true);
                  });
      }

      var userHasPlannerSessions = plannerSessions != null && plannerSessions != -1 && (plannerSessions.monday.length > 0 || plannerSessions.tuesday.length > 0 || plannerSessions.wednesday.length > 0 || plannerSessions.thursday.length > 0 || plannerSessions.friday.length > 0 || plannerSessions.saturday.length > 0 || plannerSessions.sunday.length > 0);
      var userHasSetGoals = weekGoals != null && weekGoals.meditationSessionNumber > 0;    

      return (
            <PrivateRoute>
                  <React.Fragment>
                        <ZenNavBar activeItem="MyZen" primaryColor="#D1603D"></ZenNavBar>
                        <MyZenNavBar />
                        <div className="MyZenPlanner">

                              {!hasLoaded?
                              
                              <div className="zencLoader">
                              <PuffLoader color="#033f63" size={300}>
                              </PuffLoader></div>
                              :!userHasPlannerSessions && !userHasSetGoals ?
                              <React.Fragment>
                              <Link to="/goals">
                                    <div>
                                          <h4 style = {{textAlign:"center", margin:"35px"}}>Head over to the Zen Goals area and complete your tasks. Once you have, you can generate your planner here.</h4>
                                          <div className='zencTextButton' onClick={() => { }}>Let's Go!</div>
                                    </div>
                              </Link>
                              
                              </React.Fragment>
                                    :plannerSessions==-1?
                                    <div className="zencLoader">
                                    <PuffLoader color="#033f63" size={300}>
                                    </PuffLoader>
                                  </div>
                                    :!userHasPlannerSessions ?
                                          <ZenPlannerGenerator weekGoals={weekGoals} getPlannerSessions={() => forceReload()} />
                                          :
                                          <>
                                                <div style={{ display: "flex", flexDirection: "row" }}>
                                                      <h1 className={plannerClasses} onClick={() => setFocusedMyZenItem('Planner')}>Planner</h1>
                                                      <h1 className={bookmarkClasses} onClick={() => setFocusedMyZenItem('Bookmarks')}>Bookmarks</h1>
                                                </div>

                                                {focusedMyZenItem == 'Planner' ?

                                                      <div className="my-zen-week-planner">
                                                            {plannerSessions ?
                                                                  <ZenWeekPlanner forceReload={() => forceReload()} sessions={plannerSessions}></ZenWeekPlanner>
                                                                  : <></>}
                                                      </div>

                                                      :

                                                      <div className="my-zen-planner-bookmarks">
                                                            <ProvideMyStuffContext><ZenBookmarks favouritesOpen={props.favourites} /></ProvideMyStuffContext>
                                                      </div>
                                                }

                                                {props.walkthroughActive ?
                                                      <div className="zen-walkthrough-helper">
                                                            {walkthroughStage == 1 ?
                                                                  <h3 className="zen-walkthrough-text">
                                                                  </h3>
                                                                  :
                                                                  <h3 className="zen-walkthrough-text">
                                                                  </h3>
                                                            }
                                                      </div>
                                                      : <></>
                                                }
                                          </>
                              }

                        </div>

                              <div style={{opacity:0}}>{userRefreshes}</div>
                  </React.Fragment>    
                  </PrivateRoute>);

}
