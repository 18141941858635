import Amplify, { Auth } from "aws-amplify";
import React, { createContext, useContext, useEffect, useState } from "react";
import { AwsConfigAuth } from "../cognitoconfig.js";

Amplify.configure({ Auth: AwsConfigAuth });

interface UseAuth {
    isLoading: boolean;
    isAuthenticated: boolean;
    username: string;
    signIn: (username: string, password: string) => Promise<Result>;
    signUp: (username: string, password: string, email: string, phone: string) => Promise<Result>;
    signOut: () => void;
    testUserExists: any;
    validate: (username: string, code: string) => Promise<Result>;
    resendValidate: (username: string) => Promise<Result>;
    setNewForgottenPassword: (username: string, password: string, code: string) => void;
    requestPasswordReset: (username: string) => Promise<{ success: boolean; message: any; }>;
}

interface Result {
    success: boolean;
    message: string;
}

type Props = {
    children?: React.ReactNode;
};

const authContext = createContext({} as UseAuth);

export const ProvideAuth: React.FC<Props> = ({ children }) => {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
    return useContext(authContext);
};

const useProvideAuth = (): UseAuth => {
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [username, setUsername] = useState("");

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((result) => {
                setUsername(result.username.toLowerCase());
                setIsAuthenticated(true);
                setIsLoading(false);
            })
            .catch(() => {
                setUsername("");
                //setIsAuthenticated(false);
                setIsLoading(false);
            });
    }, []);

    const signIn = async (username: string, password: string) => {

        Amplify.configure({ Auth: AwsConfigAuth });


        try {
            const result = await Auth.signIn(username.toLowerCase(), password);
            setUsername(result.username.toLowerCase());
            setIsAuthenticated(true);
            return { success: true, message: "" };
        } catch (error) {

            return {
                success: false,
                message: error,
            };
        }
    };

    const testUserExists = async (username: string) => {

        Amplify.configure({ Auth: AwsConfigAuth });

        const result = await Auth.signIn(username.toLowerCase(), '123').then(res => {
            return true;
        }).catch(error => {
            const code = error.code;
            console.log(error);
            switch (code) {
                case 'UserNotFoundException':
                    return false;
                default:
                    return true;
            }
        });

        return result;
    }

    const signOut = async () => {
        try {
            await Auth.signOut();
            setUsername("");
            //setIsAuthenticated(false);
            return { success: true, message: "" };
        } catch (error) {
            return {
                success: false,
                message: "LOGOUT FAIL",
            };
        }
    };

    const signUp = async (username: string, password: string, email: string, phone: string) => {
        try {
            const result = await Auth.signUp({ username: username.toLowerCase(), password: password, attributes: { email: email } });
            setUsername(result.user.getUsername().toLowerCase());
            setIsAuthenticated(true);
            return { success: true, message: "" };
        } catch (error) {
            return {
                success: false,
                message: error,
            };
        }
    };

    const requestPasswordReset = async (username: string) => {
        try {
            const result = await Auth.forgotPassword(username.toLowerCase());
            return { success: true, message: "" };
        } catch (error) {
            return {
                success: false,
                message: error,
            };
        }
    };

    const setNewForgottenPassword = async (username: string, password: string, code: string) => {
        try {
            const result = await Auth.forgotPasswordSubmit(username.toLowerCase(), code, password);
            return { success: true, message: "" };
        } catch (error) {
            return {
                success: false,
                message: error,
            };
        }
    };

    const resendValidate = async (username: string) => {
        try {
            const result = await Auth.resendSignUp(username.toLowerCase());
            return { success: true, message: "" };
        } catch (error) {
            return {
                success: false,
                message: error,
            };
        }
    };

    const validate = async (username: string, code: string) => {
        try {
            const result = await Auth.confirmSignUp(username.toLowerCase(), code);
            return { success: true, message: "" };
        } catch (error) {
            return {
                success: false,
                message: error,
            };
        }
    };

    return {
        isLoading,
        isAuthenticated,
        username,
        signIn,
        signOut,
        signUp,
        validate,
        resendValidate,
        requestPasswordReset,
        setNewForgottenPassword,
        testUserExists
    };
};