import * as React from "react";
import '../../styling/common/index.scss';
import { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { useAuth } from "../../hooks/auth";
import { MyZenProfile } from "../myzen/profile/MyZenProfile";
import { ZencTitle } from "./ZencTitle";
import { ReactSession } from 'react-client-session';
import MetaTags from 'react-meta-tags';

export const LogIn = (props: any) => {

  const auth = useAuth();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loadProfile, setLoadProfile] = useState(false);
  const [loadRoute, setLoadRoute] = useState(null);

  const executeSignIn = async () => {
    const result = await auth.signIn(username.toLowerCase(), password);
    if (result.success && props.overrideRoute) {
      ReactSession.set("username", username.toLowerCase());
      ReactSession.set("mindfulnest", password);
      setLoadRoute(props.overrideRoute);
    } else if (result.success) {
      ReactSession.set("username", username.toLowerCase());
      ReactSession.set("mindfulnest", password);
      setLoadProfile(true);
    }
  };

  const ValidateUsername = (inputText: string) => {
    return inputText.length > 2;
  }

  const ValidatePassword = (inputText: string) => {
    var pwformat = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,15}$/;
    return inputText.match(pwformat);
  }

  return (
    <React.Fragment>      
    <MetaTags>
    <meta id="meta-description" name="description" content={"Log in to your Zencyclopedia account"} />
    <meta id="og-title" property="og:title" content="Log In - Zencyclopedia" />
  </MetaTags>
      {loadRoute ? <Navigate to={loadRoute} />
        : loadProfile ? <MyZenProfile />
          : <div className="log-in-container">
            <ZencTitle />
            <h1 className="log-in-header">Sign In</h1>
            <h4>
              Don't have an account yet? <Link to="/signUp">Sign Up</Link>
            </h4>
            <form noValidate >
              <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)} 
                className={ValidateUsername(username) ? 'su-text-input validated-field' : 'su-text-input'}
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={ValidatePassword(password) ? 'su-text-input validated-field' : 'su-text-input'}
              />
              <h4>
                forgotten your password? <Link to="/resetpassword">reset it here</Link>
              </h4>
              <div className="confirmValidation" onClick={() => executeSignIn()}>
                <h3>Log In</h3>
              </div>
            </form>
          </div>

      }
    </React.Fragment>
  );
}




