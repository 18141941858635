import * as React from "react";
import "../../styling/zenverse/Zenverse.scss";
import "../../styling/common/index.scss";
import "../../styling/common/zenCoreUIComponents.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // req
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useNavigate, useParams } from "react-router";
import { ZencyclopaediaItemPreview } from "../explore/previews/ZencyclopaediaItemPreview";
import { Link } from "react-router-dom";
import { ZencycyclopaediaPreviewGroup } from "../explore/ZencycyclopaediaPreviewGroup";
import PrivateRoute from "../common/PrivateRoute";
import { ZenNavBar } from "../common/navbar/ZenNavBar";
import { ZencyclopaediaTextItemPreview } from "../explore/previews/ZencyclopaediaTextItemPreview";
import { useZenverseService } from "../../hooks/ZenverseContext";
import { getPortalJSX } from "../../service/ZenverseService";
import { Configuration, OpenAIApi } from 'openai';
import { useZenQuotasService } from "../../hooks/ZenQuotasContext";
import { getWordOfMouthResponse } from "./ZenBotWordsInMouth";
import { MasterBot } from "./MasterBot";
import { BabyBot } from "./BabyBot";

export const ZenBotHome = () => {

  const [question, setQuestion] = React.useState("");
  const [answer, setAnswer] = React.useState("Ask me something about nothing...");
  const [page, setPage] = React.useState("ChooseBot");

  let quotasContext = useZenQuotasService();

  let userSubscriptionLevel = quotasContext != null && quotasContext.zenQuotasResults != null ? quotasContext.zenQuotasResults.subscriptionLevel : "FREE";


  const configuration = new Configuration({
    apiKey: "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",
  });


  const setUp = `Zencyclopedia was founded on February 22nd 2022, this date is a pallondrome (22/02/2022). Zencyclopedia was founded by Tom Woodhouse and Alexia Otero. Zencyclopedia is social media built with mindfulness in mind. zencyclopedia is social media built with mindfulness in mind";

  The following is a conversation with an AI Zen Meditation Guru. The assistant is helpful, creative, clever, and very friendly.

  Human: Hello, who are you?
  AI: I am an AI created by OpenAI. How can I help you today?
  Human:`;
  const about_zen_c_text = "Zencyclopedia was founded on February 22nd 2022, this date is a pallondrome (22/02/2022). Zencyclopedia was founded by Tom Woodhouse and Alexia Otero. Zencyclopedia is  social media built with mindfulness in mind.";


  let openai = new OpenAIApi(configuration);
  const askBabyBot = async () => {
    var womR = getWordOfMouthResponse(question);

    if (womR != null && womR.length > 0) {
      setAnswer(womR);
    } else {
      const response = await openai.createCompletion({
        model: "curie:ft-zencyclopedia-2023-03-04-22-47-54",
        prompt: question,
        temperature: 0,
        max_tokens: 100,
        top_p: 1.0,
        frequency_penalty: 0.5,
        presence_penalty: 0.0,
        stop: ["."],
      });
      setAnswer(response.data.choices[0].text);
    }

  }

  // [<BabyBot primaryColor="#D1603D" />,]

  const getSliderData = () => {
    return [
    <div className = "zenBotSliderOption" style = {{marginTop:"25px"}}>
      <div style = {{display:"flex", flexDirection:"row", width:"220px", paddingLeft:"10px"}}>
        <div>
          <div className = "babyBotNameTextP1"></div>
          <div className = "babyBotNameTextP2"></div>
        </div>
        <div style = {{width:"130px",  minWidth:"130px", paddingTop:"15px"}}>
      <BabyBot primaryColor="#D1603D" />
      </div>
      </div>
      <div style = {{width:"260px", margin:"auto", textAlign:"center"}}>
        <h4 style = {{marginTop:"15px"}}>I can help you navigate through the site and teach you all about ZENcyclopedia!</h4>
      </div>
      <div className = "freeBadge">FREE</div>
      </div>, 
      <div className = "zenBotSliderOption" style = {{marginTop:"25px"}}>
      <div style = {{display:"flex", flexDirection:"row", width:"220px", paddingLeft:"10px"}}>
        <div>
          <div className = "masterBotNameTextP1"></div>
          <div className = "masterBotNameTextP2"></div>
        </div>
        <div style = {{width:"130px",  minWidth:"130px", paddingTop:"15px"}}>
        <MasterBot />
      </div>
      </div>
      <div style = {{width:"275px", margin:"auto", textAlign:"center"}}>
        <h4 style = {{marginTop:"0"}}>I can answer questions and give useful pointers on anything related to ZEN and mindfulness.</h4>
      </div>
      <div style = {{display:"flex", flexDirection:"row", margin:"auto", width:"300px"}}>
      <div className = "premiumBadge">PREMIUM</div>
      <div className = "betaBadge">BETA</div>
      </div>
      </div>]
  }



  return (
    <PrivateRoute>
      <div className="zen-bot-home">
        <ZenNavBar />
        {page == "ChooseBot" && true ? //userSubscriptionLevel == "PREMIUM" ?
          <React.Fragment>
            <div className="zenBotNameTextP1"></div>
            <div className="zenBotNameTextP2"></div>
            <h3 style = {{margin:"auto", textAlign:"center", marginBottom:"10px"}}>Choose Your Bot!</h3>
            <Slider
              centerMode={true}
              centerPadding='5%'
              autoplay={false}
              //ref={sliderRef}
              // dots={true}
              infinite={true}
            >
              {getSliderData()}
            </Slider>
          </React.Fragment>
          :
          <div>
            <h3>Sorry, Zen Bot is currently only available to Premium users!</h3>
            <p>You can upgrade your subscription <Link to="/explore/subscriptions">here.</Link></p>
          </div>
        }
      </div>
    </PrivateRoute>
  );

}
