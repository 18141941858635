import axios from "axios";
import React, { createContext, useContext, useState } from "react";
import PathInterface from "../components/zencontent/courses/PathInterface";
import ZenContentInterface from "../components/zencontent/practices/ZenContentInterface";
import { PracticeFullyFocusedPreview } from "../components/zencontent/practices/previews/PracticeFullyFocusedPreview";
import { PracticeOverview } from "../components/zencontent/practices/previews/PracticeOverview";
import { PracticePortalPreview } from "../components/zencontent/practices/previews/PracticePortalPreview";
import { useAuth } from "./auth";
import { getStreamJSX } from "../service/StreamService";
import { ZEN_C_ENDPOINT } from "../config/EnvironmentalVariables";
import {Adsense} from '@ctrl/react-adsense';
import { PathOverview } from "../components/zencontent/courses/PathOverview";
import { useZenQuotasService } from "./ZenQuotasContext";
import { ZenStreamReelPreview } from "../components/zencontent/reels/ZenStreamReelPreview";
import { ReelStreamReelItem } from "../components/zencontent/reels/ReelStreamReelItem";

interface UseStream {

  streamItems:any [],
  reelItems:any [],
  addStreamItems: () => void,
  addReelItems: () => void,
}

type Props = {
  children?: React.ReactNode;
};
const streamContext = createContext({} as UseStream);

export const ProvideStreamContext: React.FC<Props> = ({ children }) => {
    const streamService = StreamService();
    return <streamContext.Provider value={streamService}>{children}</streamContext.Provider>;
};

export const useStreamService = () => {
    return useContext(streamContext);
};

export const StreamService = () => {
    const auth = useAuth();
    const quotasContext = useZenQuotasService();

    const [streamItems, setStreamItems] = useState(null);
    const [reelItems, setReelItems] = useState([]);


const generateStream = () => {
        let userSubLevel = quotasContext && quotasContext && quotasContext.zenQuotasResults ? 
          quotasContext.zenQuotasResults.subscriptionLevel
          :null;
        axios.get(ZEN_C_ENDPOINT+`/zen/stream/beta/`+auth.username)
                .then(async res => {
                  var items = await getStreamJSX(res.data, userSubLevel);
                  setStreamItems(items);
            });
    
}

const addReelItems = async () => {
  await axios.get(ZEN_C_ENDPOINT+`/zen/stream/reels/`+auth.username)
          .then(async res => {
            var newReelItems = [];
            console.log(res.data);
            console.log(res.data.length);
            
            var resItems = res.data.filter(ci => ci.contentType == "REEL").map((ri, index) => <ReelStreamReelItem key = {index+'rsi'+ri.id} {...ri} colourCode={index%4}/>)
            resItems.map(nri => newReelItems.push(nri));
            reelItems.map(i2 => newReelItems.push(i2));
            setReelItems(newReelItems);
      });
      return true;
}


const addStreamItems = async  () => {
  await axios.get(ZEN_C_ENDPOINT+`/zen/stream/reels/`+auth.username)
          .then(async res => {

      var newStreamItems = []
      streamItems.map(i => newStreamItems.push(i));
      var resultItems : any [] = await getStreamJSX(res.data);
      resultItems.map(i => newStreamItems.push(i));
      setStreamItems(newStreamItems);
    });
    return true;
}
  
const getStreamPathJSX= (path:PathInterface) =>  {
    var pathJSX = <PathOverview {...path}/>;
    return pathJSX;
  }
  
const getStreamMeditationJSX = (practice:ZenContentInterface) => {
        var practiceJSX = <PracticeOverview {...practice}/>;
      if(practice && practice && practice.previewType && practice.previewType == 'portal'){
        var practiceJSX = <PracticePortalPreview {...practice}/>;
      } else if(practice && practice && practice.previewType && practice.previewType == 'FullyFocused'){
        var practiceJSX = <PracticeFullyFocusedPreview {...practice}/>;
      } 
      return practiceJSX;
  }
    if(streamItems == null){
      generateStream();
    } 

    if(streamItems != null && streamItems.length < 5){
      addStreamItems();
    }
    


    return {
        streamItems,
        reelItems,
        addStreamItems,
        addReelItems
    };

}