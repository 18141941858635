

import * as React from "react";
import '../../styling/common/index.scss';
import '../../styling/common/zenCoreUIComponents.scss';
import { useState } from "react";
import axios from "axios";
import { useAuth } from "../../hooks/auth";
import { ZEN_C_ENDPOINT } from "../../config/EnvironmentalVariables";


export const ZenPopUpMessage = (props : any) => {
  var className = props.style == "warning"? "zenc-pop-up-warning": "zenc-pop-up";
    return ( 
      <div className="zenc-pop-up">
        {props.children}
      </div>
    );

}