import * as React from "react";
import "../../styling/zenverse/Zenverse.scss";
import "../../styling/common/index.scss";
import "../../styling/common/zenCoreUIComponents.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // req
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ZenPayment } from "./ZenPayment";
import { ZencTitle } from "../home/ZencTitle";
import { DonationPayment } from "./DonationPayment";

export const ZencyclopediaDonation = (props: any) => {

  const [page, setPage] = React.useState(1);
  const [amount, setAmount] = React.useState(0);

  return (//myZen.profile? (
    <div className="ZenBasicPage" style={{ paddingTop: "25px" }}>
      {page == 1 ?
        <div>
          <ZencTitle />
          <h3>Thanks for choosing to make a donation</h3>
          <h4>Your money will be put to good use to provide mental health resources for those that need it!</h4>
          <div style={{ display: "flex", flexDirection: "row", margin: "auto", padding: "25px", fontSize: "70%", textAlign: "center", width: "250px" }}>
            All that's left to do is decide how many <span className="donation-os"></span>'s
          </div>

          <div className="zen-donation-choose-amount">
            <div style={{ display: "flex", flexDirection: "row", margin: "auto", marginTop: "15px", marginBottom: "25px", maxWidth: "210px" }}>
              {[1, 5, 10].map(amount => {
                return <div className="zenGlowButton navajoGlowButton zenMoneyButton" onClick={() => { setAmount(amount); setPage(2); }}>£{amount}</div>
              })}
            </div>
            <div style={{ display: "flex", flexDirection: "row", margin: "auto", marginTop: "15px", marginBottom: "25px", maxWidth: "210px" }}>
              {[25, 50, 100].map(amount => {
                return <div className="zenGlowButton navajoGlowButton zenMoneyButton" onClick={() => { setAmount(amount); setPage(2); }}>£{amount}</div>
              })}
            </div>
            <div>Or enter a custom amount (in whole £'s e.g: 7)</div>
            <input
              type="number"
              placeholder={"amount"}
              value={amount}
              onChange={(e) => setAmount(parseInt(e.target.value))}
              className={'payment-value-input'}
            />
            <div className="zenGlowButton mingGlowButton" onClick={() => setPage(2)}>Go to payment</div>
          </div>
        </div>
        : <DonationPayment amount={amount * 100} />
      }
    </div>
  );

}
