import * as React from "react";
import { MyZenNavBar } from "../../common/navbar/MyZenNavBar";
import "../../../styling/common/index.scss";
import "../../../styling/common/zenCoreUIComponents.scss";
import "../../../styling/myzen/myzen.scss";
import { ZenNavBar } from "../../common/navbar/ZenNavBar";
import { useState } from "react";
import { MyZenProgress } from "./MyZenProgress";
import { ChooseMyZenSkills } from "./ChooseMyZenSkills";
import { ChooseZenWeeklyTargets } from "./ChooseZenWeeklyTargets";
import { MyZenTargets } from "./MyZenTargets";
import { PuffLoader } from "react-spinners";
import { ZENergyParagaraphText } from "./ZENergyParagaraphText";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useZenGoals } from "../../../hooks/ZenGoalsContext";
import { ZenApiResultStatus } from "../../../config/ZenApiResultStatus";

export const MyZenGoals = (props: any) => {

      const [focusedMyZenItem, setFocusedMyZenItem] = useState("Targets");
      const [showSetTraits, setShowSetTraits] = useState(false);
      const [showSetTargets, setShowSetTargets] = useState(false);

      const zenGoals = useZenGoals();

      var targetClassers = 'myZenPlannerHeader ';
      var progressClasses = 'myZenPlannerHeader ';

      if (focusedMyZenItem == 'Targets') {
            targetClassers = targetClassers + 'subPageActiveNavHeader';
      } else if (focusedMyZenItem == 'Progress') {
            progressClasses = progressClasses + 'subPageActiveNavHeader';

      }

      var hasLoadedSuccessfully = zenGoals.weekGoals != null; 

      const refreshGoals = () => {
            setShowSetTraits(false);
            setShowSetTargets(false);
            setFocusedMyZenItem("Targets");
      }

      return (

            <React.Fragment>
                        <div className="MyZenGoals">
                              {!zenGoals.hasLoaded() ?
                                    <div>
                                          <ZenNavBar activeItem="MyZen" primaryColor="#D1603D"></ZenNavBar>
                                          <PuffLoader />
                                    </div>
                                    : props.walkthrough && !showSetTraits && !showSetTargets && !hasLoadedSuccessfully && zenGoals.hasLoaded()?
                                          <div className='ming-page'>
                                                <div className="ming-page-text">
                                                      <h3>Let's create some practice goals to keep you motivated!</h3>
                                                      <h4>Hit your goals & build your zen streaks to boost your <ZENergyParagaraphText /> points and unlock rewards accross the site</h4>
                                                      <h4>By keeping "goals" up to date, this also ensures we show the most relevant content for your needs!</h4>
                                                      <div className= "zenGlowButton navajoGlowButton" style={{ marginTop: "15px" }} onClick={() => setShowSetTargets(true)}>
                                                            Create Your Mindfulness Targets
                                                      </div>
                                                </div>
                                          </div>
                                          : showSetTraits ?
                                                <ChooseMyZenSkills refresh = {() => refreshGoals()}/>
                                                : showSetTargets ?
                                                      <ChooseZenWeeklyTargets refresh = {() => refreshGoals()}/>
                                                      :hasLoadedSuccessfully?
                                                            <div>
                                                                  <ZenNavBar activeItem="MyZen" primaryColor="#D1603D"></ZenNavBar>
                                                                  <MyZenNavBar />

                                                                  <div style={{ display: "flex", flexDirection: "row" }}>
                                                                        <h1 className={targetClassers} onClick={() => setFocusedMyZenItem('Targets')}>Targets</h1>
                                                                        <h1 className={progressClasses} onClick={() => setFocusedMyZenItem('Progress')}>Progress</h1>
                                                                  </div>
                                                                  {focusedMyZenItem == 'Targets' ?
                                                                        <MyZenTargets weekGoals={zenGoals.weekGoals} userSkillGoals={zenGoals.userSkillGoals} setShowSetTraits={() => {zenGoals.getUserSkillGoals(); setShowSetTraits(true);}} />
                                                                        :
                                                                        <MyZenProgress />
                                                                  }

                                                                  {props.walkthrough && focusedMyZenItem == 'Targets' ?
                                                                        <>
                                                                              <div className="zen-walkthrough-helper" >
                                                                                    <h4 style={{ width: "80%", marginLeft: "10%" }}>Your Zen targets are stored here, you can come back and change them at any time.</h4>
                                                                              </div>
                                                                              <div className="zen-walkthrough-helper helper-bottom" onClick={() => { setFocusedMyZenItem('Progress'); }}>
                                                                                    <h4 style={{ width: "80%", marginLeft: "10%" }}><span className="navajoTextCol">Once you're ready, let's crack on and see how we measure your progress</span><span className="zen-walkthrough-text-next" onClick={() => setFocusedMyZenItem('Progress')}></span></h4>
                                                                              </div>
                                                                        </>
                                                                        : props.walkthrough ?
                                                                              <>
                                                                                    <div className="zen-walkthrough-helper" >
                                                                                          <h4 style={{ width: "90%", marginLeft: "5%", fontSize: "90%" }}>The Zen Progress tab let's you keep track of your milestones. Boost your ZENERGY score to unlock features on the site from Zen Portals, user icons and much more!</h4>
                                                                                    </div>
                                                                                    <Link to={"/myZen"}>
                                                                                          <div className="zen-walkthrough-helper helper-bottom">
                                                                                                <span className="zen-walkthrough-text-next"></span>
                                                                                                <h4 style={{ width: "80%", marginLeft: "10%" }}>Once you've checked out the Zen Progress section, let's head back to My Zen Profile to continue working through your tasks and explore the rest of the site!</h4>
                                                                                          </div>
                                                                                    </Link>
                                                                              </>
                                                                              : null
                                                                  }
                                                            </div>
                                                            : zenGoals.allSkills && zenGoals.allSkills.length > 0 && !hasLoadedSuccessfully ?
                                                                              <Navigate to={"/goals/walkthrough"} />
                                                            :zenGoals.resultStatus == ZenApiResultStatus.Fail ?
                                                                        <React.Fragment>
                                                                              <ZenNavBar activeItem="MyZen" primaryColor="#D1603D"></ZenNavBar>
                                                                              <div style={{ paddingTop: "250px" }}></div>
                                                                              <h2>We're having trouble finding your Zen Goals</h2>
                                                                        </React.Fragment>
                                                            : <Navigate to={"/goals/walkthrough"} />

                              }
                        </div>
                  </React.Fragment>
           );
}
