import * as React from "react";
import { MyZenNavBar } from "../common/navbar/MyZenNavBar";
import "../../styling/common/index.scss";
import { ZenNavBar } from "../common/navbar/ZenNavBar";
import { useState } from "react";
import axios from "axios";
import { useAuth } from "../../hooks/auth";
import PrivateRoute from "../common/PrivateRoute";
import { useParams } from "react-router-dom";
import { ZEN_C_ENDPOINT } from "../../config/EnvironmentalVariables";
import PractitionerInterface from "./PractitionerInterface";
import ZenContentInterface from "../zencontent/practices/ZenContentInterface";
import { getContentListPreviews } from "../../service/ContentService";
import { ZenLoader } from "../common/ZenLoader";
import Slider from "react-slick";
import { faBedPulse } from "@fortawesome/free-solid-svg-icons";

export const PractitionerContent = (props: { username?: string, practitionerInfo?: any, subPage?: string, setSubPage?: any }) => {

      const [activeItem, setActiveItem] = useState("all");
      const [getContentAttempts, setGetContentAttempts] = useState(0);
      const [searchPending, setSearchPending] = useState(false);
      const [practitionerContent, setPractitionerContent] = useState(null as ZenContentInterface[]);
      const [activeContent, setActiveContent] = useState(null as ZenContentInterface[]);
      const [pageNumber, setPageNumber] = useState(0);

      const sliderRef: any = React.createRef();
      const desktopSliderRef: any = React.createRef();

      const loadPractitionerContent = () => {
            loadMoreContent(1);

      }

      const loadMoreContent = async (pageNum: number) => {

            //getContentListPreviews
            await axios.get(ZEN_C_ENDPOINT + `/zen/explore/practitioner/` + props.practitionerInfo.username + `/` + pageNum)
                  .then(res => {
                        console.log("got practitioner content");
                        setPractitionerContent(res.data);
                        setActiveContent(res.data);
                        setSearchPending(false);
                        setPageNumber(pageNum);
                  }).catch(() => {
                        setSearchPending(false);
                  });

      }


      if (props.practitionerInfo != null && practitionerContent == null && getContentAttempts < 5 && !searchPending) {
            setGetContentAttempts(getContentAttempts + 1);
            setSearchPending(true);
            loadPractitionerContent();
      }

      const updateItems = async (filterType: string) => {
            console.log("filtering items");
            let newActiveItems = filterType == "all" ? practitionerContent
                  : practitionerContent.filter(pc => pc.contentType.toLowerCase() == filterType.toLowerCase());
            console.log("updating items");
            setSearchPending(false);
            setActiveContent(newActiveItems);
      }

      const filterItems = (filterType: string) => {
            setSearchPending(true);
            setTimeout(() => updateItems(filterType), 500);

      }

      const generateCarouselInner = (carouselRef: any) => {
            return [
                  <div className="practitioner-content-slider-item" onClick={() => filterItems("all")}>
                        <h1 className=" zenK"><b>All Content</b> </h1>
                        <h4 className="">Browse this practitioners content including meditations, nuggets, paths and more.</h4>
                  </div>,
                  <div className="practitioner-content-slider-item" onClick={() => filterItems("meditation")}>
                        <h1 className=" zenK"><b>Meditations</b> </h1>
                        <h4 className="">View all meditation practices from this practitioner</h4>
                  </div>,
                  <div className="practitioner-content-slider-item" onClick={() => filterItems("path")}>
                        <h1 className=" zenK"><b>Courses</b> </h1>
                        <h4 className="">Show all Paths & Courses by this practitioner</h4>
                  </div>,
                  <div className="practitioner-content-slider-item" onClick={() => filterItems("nugget")}>
                        <h1 className=" zenK"><b>Nuggets</b> </h1>
                        <h4 className="">See all Zen Nuggets posted  by this practitioenr</h4>
                  </div>,
                  <div className="practitioner-content-slider-item" onClick={() => filterItems("reel")}>
                        <h1 className=" zenK"><b>Zen Reel</b> </h1>
                        <h4 className="">Share a short peice of positivity or wisdom with the world.</h4>
                  </div>
            ];
      }

      return (
            <div style={{ paddingTop: "100px", margin: "auto", width: "100%", overflow: "hidden" }}>
                  <div style={{ margin: "auto", width: "100vw", marginBottom: "5px" }}>
                        <div className="zen-mobile">
                              <Slider
                                    centerMode={true}
                                    centerPadding='15%'
                                    ref={sliderRef}
                                    infinite={true}
                              >
                                    {generateCarouselInner(sliderRef)}
                              </Slider>
                        </div>
                        <div className="zen-desktop">
                              <Slider
                                    centerMode={true}
                                    centerPadding='35%'
                                    ref={desktopSliderRef}
                                    infinite={true}
                              >
                                    {generateCarouselInner(desktopSliderRef)}
                              </Slider>
                        </div>
                  </div>
                  {searchPending ?
                        <ZenLoader />
                        : activeContent != null ?
                              <React.Fragment>
                                    {/* <div style={{ display: "flex", flexDirection: "row" }}>
                                    <h1 className={activeItem == 'all' ? "subPageNavHeader subPageActiveNavHeader" : "subPageNavHeader"} onClick={() => props.setSubPage('practitioner-content')}>All</h1>
                                    <h1 className={activeItem == 'recent' ? "subPageNavHeader subPageActiveNavHeader" : "subPageNavHeader"} onClick={() => props.setSubPage('practitioner-bio')}>Recent</h1>
                                    <h1 className={activeItem == 'top' ? "subPageNavHeader subPageActiveNavHeader" : "subPageNavHeader"} onClick={() => props.setSubPage('practitioner-content')}>Top</h1>
                              </div> */}
                                    <div style={{ height: "55vh", overflowY: "scroll", paddingTop: "35px" }}>
                                          <div style={{ paddingBottom: "150px" }}>
                                                {getContentListPreviews(activeContent)}
                                                <div style={{ display: "flex", flexDirection: "row" }}>

                                                      {pageNumber > 1 ?
                                                            <div className="zenGlowButton sageGlowButton" onClick={() => loadMoreContent(pageNumber - 1)}>Back</div> : null}
                                                      <div className="zenGlowButton sageGlowButton" onClick={() => loadMoreContent(pageNumber + 1)}>Next</div>
                                                </div>
                                          </div>


                                    </div>
                              </React.Fragment>
                              : <ZenLoader></ZenLoader>
                  }

            </div>
      );

}
