import * as React from "react";
import "../../styling/zenverse/Zenverse.scss";
import "../../styling/common/zenCoreUIComponents.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // req
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useNavigate, useParams } from "react-router";
import { ZencyclopaediaItemPreview } from "../explore/previews/ZencyclopaediaItemPreview";
import { Link } from "react-router-dom";
import { ZencycyclopaediaPreviewGroup } from "../explore/ZencycyclopaediaPreviewGroup";
import PrivateRoute from "../common/PrivateRoute";
import { ZenNavBar } from "../common/navbar/ZenNavBar";
import { ZencyclopaediaTextItemPreview } from "../explore/previews/ZencyclopaediaTextItemPreview";
import { useZenverseService } from "../../hooks/ZenverseContext";
import { getPortalJSX } from "../../service/ZenverseService";
import { Configuration, OpenAIApi } from 'openai';
import Chatbot from "react-chatbot-kit";
import { ZencTitle } from "../home/ZencTitle";
import { MyZenNavBar } from "../common/navbar/MyZenNavBar";
import { useMyZenService } from "../../hooks/ZenUserContext";
import { useAuth } from "../../hooks/auth";
import { CardElement, Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {ZenergyPaymentCheckout} from "./ZenergyPaymentCheckout";

export const DonationSuccessful = (props: any) => {

  return (//myZen.profile? (
    <div className="ZenBasicPage">
      <h1>Payment Successful"</h1>
      <div className = "payment-successful-image"></div>
      <h3>Thank you for supporting our mission.</h3>
    </div>
  );

}
