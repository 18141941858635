export const HomeContentTranslations = {
  SignUpButtonTranslations : { 
    English : 'Sign Up', 
    Spanish : 'Registrate'
  },
  HomeLogInPrompt: {
    English : "Already have an account? ",
    Spanish : "Ya tienes una cuenta?"
  }, 
  LogInText: {
    English : "Log In",
    Spanish : "Iniciar sesion"
  }, 
  WhatDoWeOfferTranslation : {
    English : "What Do We Offer?",
    Spanish : "¿Que ofrecemos?"
  },
  CuttingEdgeTranslation: {
    English : "Cutting edge tech to bring Zen into the 21st century",
    Spanish : "Tecnologia de vanguard para traer Zen al siglo 21"
  },
  ZenVenomLab:{
    English:"Discover all about the ZenVenom Lab and the tech that powers our cutting edge mindfulness tools",
    Spanish:"Descubre todo sobre el Laboratorio Zen Venom y la tecnología que impulsa nuestras herramientas vanguardistas de meditacion"
  },
  ZenMonksWanted:{
    English:"Zen Monks Wanted! Enquire Within.",
    Spanish:"Se buscan monies zen! Indigo en tu interior."
  },
  DoYouHaveWhatItTakes:{
    English:"Do you think you have what it takes to become a Zencyclopedia practitioner?",
    Spanish:"¿Crees que tienes lo que se necesita para convertirte en un 'practicante' de Zencyclopadia?"
  },
  JourneyStartsHere:{
    English:"The journey starts here.",
    Spanish:"Tu aventura comienza aqui"
  },
  Philzentropy:{
    English:"philZENtropy",
    Spanish:"PhilZENtropia "
  },
  GetInvolved:{
    English:"Find out more about our tech for good ethos and what you can do to get involved",
    Spanish:"Descubre más sobre nuestra ética de “tecnología para el bien” y lo que puedes hacer para participar"
  },
}


export enum SignUpButtonTranslations {
    English = "Sign Up, It's FREE!",
    Spanish = "Registrate"
}

export enum HomeLogInPrompt {
  English = "Already have an account? ",
  Spanish = "Ya tienes una cuenta?"
}

export enum LogInText {
  English = "Log In",
  Spanish = "Iniciar sesion"
}

export enum WhatDoWeOfferTranslation {
  English = "What Do We Offer?",
  Spanish = "Que ofrecemos?"
}

export enum CuttingEdgeTranslation {
  English = "Cutting edge tech to bring Zen into the 21st century",
  Spanish = "Tecnologia de vanguard para traer Zen al siglo 21"
}

export enum ResourcesTranslationTranslation {
  English = "Resources and tools to empower people to take control of their own mental health",
  Spanish = "Recursos y herramientas para puedas tomar el control de tu propia salud mental"
}

export enum ArtificalIntelligenceTranslation {
  English = "Personalised meditation plans using our AI powered smart planning tools",
  Spanish = "Planes de meditación personalizados gracias a nuestras herramientas de planificación usando inteligencia artificial"
}

export enum MentalHealthEscapesTranslation {
  English = "Mental health boosting immerse virtual escapes",
  Spanish = "escapadas virtuales inmersivas que mejoran la salud mental"
}

export enum MindfulnessTrainingTranslation {
  English = "Free and accessible mindfulness training",
  Spanish = "Ormación gratuita y accesible para aprender a meditar"
}

export enum SocialMediaFeelGoodTranslation {
  English = "Social media that actually makes you feel good!",
  Spanish = "Unable red socials que te haga sentir bien!"
}
export enum BornInLondon {
  English = "Born in London",
  Spanish = "Born in London"
}

export enum MadeInBCNTranslation {
  English = "Made in Barcelona",
  Spanish = "Hecho en Barcelona"
}

export enum OurRootsTranslation {
  English = "Learn more about our roots",
  Spanish = "aprende mas sobre nuestros origenes"
}

 
