import React, { useState } from 'react';
import "../../../../styling/vacana/vacana.scss";
import "../../../../styling/common/index.scss";
import "../../../../styling/common/GongTimer.scss";
import "../../../../styling/zenverse/bubbleEnergy.scss";
import "../../../../styling/stream/stream.scss";
import "../../../../styling/zenverse/Zenverse.scss";
import { useCountdownTimer } from 'use-countdown-timer';

export const GongCounter = (props: any) => {


  const { countdown, start, reset, pause, isRunning } = useCountdownTimer({
    timer: 1000 * props.time,
    onExpire: () => props.timerFinished()
  });

  const minutesRef: any = React.createRef();

  var totalSeconds = Math.floor(countdown / 1000);
  var mins = Math.floor(totalSeconds / 60);
  var seconds = totalSeconds % 60;

  React.useEffect(() => {
    start();
  }, []);

  return (
    <div className="gwtw-countdown">
            <div id="countdown">
              <span id="minutes" ref={minutesRef}>{mins}:</span>
              <span id="seconds">
                {seconds < 10 ?
                  <>

                    0{seconds}
                  </>
                  :
                  seconds
                }</span>
            </div>
          </div>
  );
}