import React, { useState } from 'react';
import "../../../styling/stream/stream.scss";
import "../../../styling/common/index.scss";
import { TextNugget } from './TextNugget';
import { ImageNugget } from './ImageNugget';

export const NuggetSliderPreview = (props: any) => {

  const [showMore, setShowMore] = useState(props.showingMore? props.showingMore: false);

  const charactersAllowed = props && props.image != null? 250: 400;

  return (
    <div className={showMore? 'nuggetSliderPreview showAllSliderPreview': 'nuggetSliderPreview'}>

      {props.nuggetType == "TEXT" ?
        <div>
          <div style = {showMore? {height:"350px", overflowY:"scroll"} :{height:"280px", overflow:"hidden"}}>
          <img className="nugget-image" src={props.image} ></img>
          <h2>{props.title}</h2>
          {showMore?
              <h3>{props.description}</h3>
              : <h3>{props.description.substring(0, charactersAllowed)}</h3>
              }

          </div>
              {props.description.length>charactersAllowed && !showMore?
              <div 
              className = "zenGlowButton navajoGlowbutton" 
              style = {{position:"fixed", bottom:"19px", marginLeft:"75px"}}
              onClick = {() => {
                setShowMore(true);
                props.focusItem(props.index);
              }}
              >Show More</div>
              :showMore?
              <div 
              className = "zenGlowButton navajoGlowbutton" 
              style = {{margin:"auto"}}
              onClick = {() => {
                setShowMore(false);
                props.focusItem(null);
              }}
              >Show Less</div>
              :null}
        </div>
        : props.nuggetType == "IMAGE" ?
          <div>
            <img className="nugget-image" src={props.image} ></img>
            <h2>{props.title}</h2>
          </div>

          : props.nuggetType == "NEWS"?
          <div style = {{margin:"auto", textAlign:"center"}}>
            <h3 className = "goodNewsHeader">Good News!</h3>
            <img className="nugget-image" src={props.image} ></img>
            <h4 >{props.description}</h4>
            <div className = "zenGlowButton sageGlowButton">Read all about it</div>
          </div>:null}
    </div>
  );
}