import * as React from "react";
import { MyZenNavBar } from "../../common/navbar/MyZenNavBar";
import "../../../styling/common/index.scss";
import { ZenNavBar } from "../../common/navbar/ZenNavBar";
import { useState } from "react";
import axios from "axios";
import { useAuth } from "../../../hooks/auth";
import PrivateRoute from "../../common/PrivateRoute";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ZEN_C_ENDPOINT } from "../../../config/EnvironmentalVariables";
import { PuffLoader } from "react-spinners";
import { useMyZenService } from "../../../hooks/ZenUserContext";
import { useZenCardsService } from "../../../hooks/ZenCardsContext";
import { ZenCardMilestoneNotice } from "../../explore/icons/ZenCardMilestoneNotice";
import { ZenCardPreviewInterface } from "../../explore/icons/ZenCardPreview";
import { getSubscriptionAllowanceMessage } from "../../../service/ZenSubscriptionsService";
import { Elements } from "@stripe/react-stripe-js";
import { SubscriptionPaymentCheckout } from "./SubscriptionPaymentCheckout";
import { loadStripe } from "@stripe/stripe-js";


export const SubscriptionPayment = (props: any) => {

    const [stripePromise, setStripePromise] = React.useState(null);
    const [clientSecret, setClientSecret] = React.useState("");
    const [subscriptionId, setSubscriptionId] = React.useState("");
    
    //let subscriptionTierDisplayName = subscriptionTier == "PREMIUM"? "Premium" : "Basic";
    
  let zenStripeAPI = "https://www.zencyclopedia.co.uk/myzen/zenPayments";

    
    React.useEffect(() => {
      console.log("Getting Stripe Config.");
      axios.get(zenStripeAPI + `/stripeConfig`)
        .then(res => {
          console.log("PK "+res.data);
          setStripePromise(loadStripe(res.data));
        }).catch(() => {
         
        })
    }, []);
  
    React.useEffect(() => {
      console.log("making subscription intent");
      axios.post(zenStripeAPI + `/createSubscriptionIntent/`+props.subscriptionTier+`/`+props.customerId)
      .then(res => {
        setSubscriptionId(res.data.subscriptionId);
          setClientSecret(res.data.clientSecret);
      }).catch(function (error) {
        setClientSecret(null);
        console.log(error);
      });
    }, []);


      return (
        <div className="ZenBasicPage">
        {clientSecret && stripePromise && (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <SubscriptionPaymentCheckout subscriptionId = {subscriptionId} subscriptionTier = {props.subscriptionTier}/>
          </Elements>
        )}
      </div>
      );
  
  }
  
