import * as React from "react";
import "../../../styling/common/index.scss";
import { Link, useNavigate } from "react-router-dom";
import { ZenLoader } from "../../common/ZenLoader";
import { useZenQuotasService } from "../../../hooks/ZenQuotasContext";
import { useAuth } from "../../../hooks/auth";
import { getSubscriptionCost } from "../../../stubs/SubscriptionService";
import PrivateRoute from "../../common/PrivateRoute";
import { MyZenNavBar } from "../../common/navbar/MyZenNavBar";
import { ZenNavBar } from "../../common/navbar/ZenNavBar";

export const MySubscription = () => {

  let navigate = useNavigate();
  let auth = useAuth();

  let quotasContext = useZenQuotasService();


  var subscriptionCost = quotasContext.zenQuotasResults && quotasContext.zenQuotasResults.subscriptionLevel ? getSubscriptionCost(quotasContext.zenQuotasResults.subscriptionLevel) : null;

  var portalTimeAllowance = quotasContext.zenQuotasResults && quotasContext.zenQuotasResults.portalTimeAllowance > 14000 ? "Unlimited"
    : quotasContext.zenQuotasResults && quotasContext.zenQuotasResults.portalTimeAllowance > 0 ? quotasContext.zenQuotasResults.portalTimeAllowance + " minutes per week."
      : "100 minutes per week";

  console.log(quotasContext.zenQuotasResults != null ? quotasContext.zenQuotasResults.subscriptionLevel : "nothing");

  //TODO: make a function to do this for any word
  const getColouredFree = () => {
    return <h1 className="SubscriptionTierColoured okashi" style={{ color: "#033f63", marginTop: "0", marginBottom: "0" }}>
      <span >
        F
      </span>
      <span style={{ color: "#D1603D" }}>
        R
      </span>
      <span style={{ color: "#28666E" }} >
        E
      </span>
      <span style={{ color: "#B5B682" }}>
        E
      </span>
    </h1>;
  }

  const getColouredBasic = () => {
    return <h1 className="SubscriptionTierColoured okashi" style={{ color: "#033f63", marginTop: "0", marginBottom: "0" }}>
      <span >
        B
      </span>
      <span style={{ color: "#D1603D" }}>
        A
      </span>
      <span style={{ color: "#28666E" }} >
        S
      </span>
      <span style={{ color: "#B5B682" }}>
        I
      </span>
      <span className=''>
        C
      </span>
    </h1>;
  }
  const getColouredPremium = () => {
    return <h1 className="SubscriptionTierColoured okashi" style={{ color: "#033f63", marginTop: "0", marginBottom: "0" }}>
      <span >
        P
      </span>
      <span style={{ color: "#D1603D" }}>
        R
      </span>
      <span style={{ color: "#28666E" }} >
        E
      </span>
      <span style={{ color: "#B5B682" }}>
        M
      </span>
      <span className=''>
        I
      </span>
      <span style={{ color: "#D1603D" }}>
        U
      </span>
      <span style={{ color: "#28666E" }}>
        M
      </span>
    </h1>;
  }

  let userSubscriptionLevel = quotasContext != null && quotasContext.zenQuotasResults != null ? quotasContext.zenQuotasResults.subscriptionLevel : "FREE";

  console.log("YOUR SUBSCRIPTION LEVEL in MY SUBSCRIPTION IS: " + userSubscriptionLevel);


  return (<PrivateRoute>
    <ZenNavBar />
    <MyZenNavBar />
    <div className="ZenBasicPage zen-page-holder">
      <div className="zen-mobile">
        {quotasContext.zenQuotasResults != null && quotasContext.zenQuotasResults.subscriptionLevel != null ?
          <div style={{ paddingBottom: "150px", paddingTop: "75px" }}>
            
            {quotasContext.zenQuotasResults.subscriptionExpiryDate != null ?
            <div className = "subscription-expiry-notice">
              <h4 style={{ marginTop: "0", textAlign: "center" }}>Your subscription is due to expire on: <br></br> {quotasContext.zenQuotasResults.subscriptionExpiryDate} </h4>
              </div>
              : null}
            <h4 style={{ marginTop: "0", marginBottom: "0", height: "20px", lineHeight: "20px" }}>You are a</h4>
            <h1 style={{ marginTop: "0", marginBottom: "0" }}>{
              quotasContext.zenQuotasResults.subscriptionLevel == "PREMIUM" ?
                <React.Fragment>{getColouredPremium()}</React.Fragment>
                : quotasContext.zenQuotasResults.subscriptionLevel == "BASIC" ?
                  <React.Fragment>{getColouredBasic()}</React.Fragment>
                  : <React.Fragment>{getColouredFree()}</React.Fragment>
            }</h1>

            {quotasContext.zenQuotasResults.subscriptionLevel != "FREE" ?
              <React.Fragment><h3 style={{ marginTop: "0" }}>Subscriber</h3>
                <h4 className="subscription-conf-billing-info">Charged at {subscriptionCost} per month.</h4>
              </React.Fragment>

              :
              <React.Fragment><h3 style={{ marginTop: "0", marginBottom: "5px" }}>User</h3><h4 className="subscription-conf-billing-info">You are not currently being charged for your use of the platform.</h4>
              </React.Fragment>}


            <div className="white-page-inset" style={{ marginTop: "10px", paddingTop: "5px", paddingBottom: "5px" }}>
              <h2>What you get:</h2>

              <h3>{quotasContext.zenQuotasResults.portalTimeAllowance} minutes of Zen Portal Time per week.</h3>
              <h3><b>Zenscape Inventory Size:</b> {quotasContext.zenQuotasResults.inventorySize}</h3>
            </div>
            <Link to = "/explore/zencards">
            <h4 style={{ marginTop: "0", textAlign: "center" }}>You can view all of your Zen Power Cards here. </h4>
            </Link>
            <div style={{ display: "flex", flexDirection: "row", margin: "auto", alignItems: "center", maxWidth: "275px" }}>
              {quotasContext.zenQuotasResults.subscriptionLevel != "FREE" ?
                <Link to="/explore/subscriptions" style={{ margin: "auto" }}>
                  <div className="zencTextButton zencRedButton" style={{ margin: "auto" }}>
                    Downgrade
                  </div>
                </Link>
                : null}
              {quotasContext.zenQuotasResults.subscriptionLevel != "PREMIUM" ?
                <Link to="/explore/subscriptions" style={{ margin: "auto" }}>
                  <div className="zencTextButton zencGreenButton"  >
                    Upgrade
                  </div>
                </Link>
                : null}
              {/** option to upgrade if possible. */}
              {/** downgrade/cancel */}
            </div>
            {quotasContext.zenQuotasResults.subscriptionLevel != "FREE" ?
              <Link to={"/cancelsubscription"}>
                <div className="sub-cancel-link">cancel</div>
              </Link>
              : null
            }

          </div>
          : <ZenLoader />
        }
      </div>
      <div className="zen-desktop">
        {quotasContext.zenQuotasResults != null && quotasContext.zenQuotasResults.subscriptionLevel != null ?
          <div style={{ paddingBottom: "150px" }}>
            
            <div style={{ display: "flex", flexDirection: "row", margin: "auto", width: "fit-content", marginTop: "75px" }}>
              <div>
                <h4 style={{ marginTop: "0", marginBottom: "0", height: "20px", lineHeight: "20px" }}>You are a</h4>
                <h1 style={{ marginTop: "0", marginBottom: "0" }}>{
                  quotasContext.zenQuotasResults.subscriptionLevel == "PREMIUM" ?
                    <React.Fragment>{getColouredPremium()}</React.Fragment>
                    : quotasContext.zenQuotasResults.subscriptionLevel == "BASIC" ?
                      <React.Fragment>{getColouredBasic()}</React.Fragment>
                      : <React.Fragment>{getColouredFree()}</React.Fragment>
                }</h1>

                {quotasContext.zenQuotasResults.subscriptionLevel != "FREE" ?
                  <React.Fragment><h3 style={{ marginTop: "0" }}>Subscriber</h3>
                    <h4 className="subscription-conf-billing-info">Charged at {subscriptionCost} per month.</h4>
                  </React.Fragment>

                  :
                  <React.Fragment><h3 style={{ marginTop: "0" }}>User</h3><h4 className="subscription-conf-billing-info">You are not currently being charged for your use of the platform.</h4>
                  </React.Fragment>}

              </div>

              <div>
                {quotasContext.zenQuotasResults.subscriptionExpiryDate != null ?
            <div className = "subscription-expiry-notice">
              <h4 style={{ marginTop: "0", textAlign: "center" }}>Your subscription is due to expire on: <br></br> {quotasContext.zenQuotasResults.subscriptionExpiryDate} </h4>
              </div>
              : null}
                <div className="white-page-inset" style={{ marginTop: "0" }}>
                  <h2>What you get:</h2>

                  <h3>{quotasContext.zenQuotasResults.portalTimeAllowance} minutes of Zen Portal Time per week.</h3>
                  <h3><b>Zenscape Inventory Size:</b> {quotasContext.zenQuotasResults.inventorySize}</h3>
                </div>
              </div>
            </div>
            
            <Link to = "/explore/zencards">
            <h4 style={{ marginTop: "0", textAlign: "center" }}>You can view all of your Zen Power Cards here. </h4>
            </Link>
            <div style={{ display: "flex", flexDirection: "row", margin: "auto", alignItems: "center", maxWidth: "275px" }}>

              <Link to="/explore/subscriptions" style={{ margin: "auto" }}>
                {quotasContext.zenQuotasResults.subscriptionLevel != "FREE" ?
                  <div className="zencTextButton zencRedButton" style={{ margin: "auto" }}>
                    Downgrade
                  </div>
                  : null}
              </Link>

              <Link to="/explore/subscriptions" style={{ margin: "auto" }}>
                <div className="zencTextButton zencGreenButton"  >
                  Upgrade
                </div>
              </Link>
              {/** option to upgrade if possible. */}
              {/** downgrade/cancel */}
            </div>
            {quotasContext.zenQuotasResults.subscriptionLevel != "FREE" ?
              <Link to={"/cancelsubscription"}>
                <div className="sub-cancel-link">cancel</div>
              </Link>
              : null
            }

          </div>
          : <ZenLoader />
        }
      </div>
    </div></PrivateRoute>
  );

}

