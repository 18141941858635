import * as React from "react";
import "../../../styling/common/index.scss";
import "../../../styling/common/zenCoreUIComponents.scss";
import "../../../styling/myzen/myzen.scss";
import { useState } from "react";
import axios from "axios";
import { useParams } from "react-router";
import { ZEN_C_ENDPOINT } from "../../../config/EnvironmentalVariables";
import { Link, useSearchParams } from "react-router-dom";

export const ConfirmTShirtPurchase = () => {

      const [searchParams, setSearchParams] = useSearchParams();
      let pI = searchParams.get("payment_intent");
      console.log(pI);
      const {id} = useParams();
      console.log("ID: ");
      console.log(id);

      React.useEffect(() => {
            //Confirm T Shirt purchase with back end:
            axios.post(ZEN_C_ENDPOINT + `/myzen/shop/order/confirm`, {
                  orderId: id,
                  stripePaymentIntent: pI
            }).then(function (response) {
                  
            }).catch(function (error) {
                  console.log(error);
                  console.log("failed to make t shirt confirm req")
            });
      },[]);
  

      return (
            <React.Fragment>
                  <div className="ZenBasicPage zen-page-holder">

                        <h1>Order Successful!</h1>
                        
                        <h4>A confirmation email has been sent to your account.</h4>
                        <h1>Thanks for supporting our mission!</h1>

                        <Link to = "/"><div className = "zenGlowButton mingGlowButton">done</div></Link>
                       
                  </div>
            </React.Fragment>
      );
}
