import React, { useState } from 'react';
import "../../../styling/vacana/vacana.scss";
import "../../../styling/common/index.scss";
import "../../../styling/zenverse/bubbleEnergy.scss";
import "../../../styling/stream/stream.scss";
import "../../../styling/zenverse/Zenverse.scss";

export const BreathEnergyPortal = () => {

  const [walkthroughStage, setWalkthroughStage] = useState(1);
  const [practicePlaying, setPracticePlaying] = useState(false);
  const [speed, setSpeed] = useState("med");

  if (walkthroughStage > 5 && !practicePlaying) {
    setPracticePlaying(true);
  }



  // set time out once we begin

  return (
    <div>
      <div style={{ margin: "auto", display: "flex", flexDirection: "row", width: "300px", marginBottom:"15px"}}>
      <h3 style={{ margin: "auto" }} onClick={() => { setSpeed("slow") }}>Slow</h3>
      <h3 style={{ margin: "auto" }} onClick={() => { setSpeed("easy") }}>Easy</h3>
      <h3 style={{ margin: "auto" }} onClick={() => { setSpeed("fast") }}>Quick</h3>
    </div>
      <div className='bubble-energy-energy-holder'>

        <div className="breath-energy-void"></div>
        <div className="bubble-energy-container">
          <div className={speed == "slow" ? "bubble-energy-breath bubble-energy-breath-slow" : speed == "fast" ? "bubble-energy-breath bubble-energy-breath-fast" : "bubble-energy-breath"}>

          </div>
        </div>
      </div></div>
  );
}