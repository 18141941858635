import React, { useState } from 'react';
import "../../styling/zenverse/BuddhaBot.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import Draggable, { DraggableData } from 'react-draggable';

export const BabyBot = (props : any) => {


    const [showZenBotActions, setShowZenBotActions] = useState(false);

    const [showChooseColour, setShowChooseColour] = useState(false);

    const [buddhasRobe, setBuddhasRobe] = useState(props.primaryColor);

    const chooseColour = (colour:string) => {
      setShowZenBotActions(false);
      setShowChooseColour(false);
      props.setPrimaryColor(colour);
      if(colour == '#FFBC42'){

        setBuddhasRobe('#ff9442');
        
      } else {

        setBuddhasRobe(colour);
      }
    }

    return (
      // <Draggable 
      // key={'buddha-bot'}
      // allowAnyClick={true}>
        <div style = {{margin:"auto"}}>
      <div className="outer" onClick={() => {
        //setShowZenBotActions(!showZenBotActions)
      }}>
        <div className="middle">
        <div className="main clearfix">
          <div className="buddha">
            <span className="tail" style={{backgroundColor:buddhasRobe}}></span>
            <span className="legs" style={{backgroundColor:buddhasRobe}}></span>
            <div className="head">
              <span className="ears right"></span>
              <span className="ears left"></span>
              <span className="eye right"></span>
              <span className="eye left"></span>
              <span className="mouth"></span>
            </div>
            <span className="hand"></span>
          </div>
          <span className="shadow"></span>
        </div>
        </div>
        </div>
        {showZenBotActions && ! showChooseColour ? 
        <div className = 'zenBotActions'>
          <h3 onClick = {() => setShowChooseColour(!showChooseColour)}> Choose Main Colour</h3>
        </div> : <></>

        }
        {/* {showZenBotActions && showChooseColour ? 
        <div className = 'chooseColour' style={{display:"flex", flexDirection:"row" }}>
          <div className ='choosePrimaryCol chooseBlue' onClick = {() => chooseColour('#033f63')}></div>
          <div className ='choosePrimaryCol chooseOrange' onClick = {() => chooseColour('#FFBC42')}></div>
          <div className ='choosePrimaryCol chooseLRed' onClick = {() => chooseColour('#D1603D')}></div>
          
        </div> : <></>

        } */}
        </div>
    // </Draggable>
    );
}

