import * as React from "react";
import "../../../styling/common/index.scss";
import "../../../styling/common/zenCoreUIComponents.scss";
import "../../../styling/myzen/myzen.scss";
import { useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router";
import { PuffLoader } from "react-spinners";
import { Link } from "react-router-dom";
import { StarRatings } from "../common/StarRatings";

export const RetreatStreamAd = (props: any) => {

      const [page, setPage] = useState("main");

      let navigate = useNavigate();


      // FORMAT RATING:
      const regex = /^(\d+(\.\d+)?)(?:\s*\()(\d+)(?:\))$/;
      const match = regex.exec(props.rating);

      let rating = null;
      let revieNum = null;

      if (match !== null) {
            rating = parseFloat(match[1]);
            revieNum = parseInt(match[3], 10);
            console.log(`Rating: ${rating}, Number of reviews: ${revieNum}`);
      }

      let ratingClassName = rating != null && rating > 3.9 ? "review-rating-high" : rating != null && rating > 3 ? "review-rating-med" : "review-rating-normal";

      return (
            <React.Fragment>
                  <div className="zen-retreat-stream-ad" onClick={() => { window.open(props.link, '_blank'); }}>
                        <h4 style = {{margin:"auto",textAlign:"center", marginTop:"5px;"}}>Need a break?</h4>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                              <div>{props && props.image ?
                                    <img className="content-preview-image" src={props.image}></img>
                                    : null
                              }</div>
                              <div className="retreat-title">
                                    {props.title}
                              </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", minWidth: "90%", margin: "auto" }} className="zen-retreat-preview-footer">
                              <div style={{ display: "flex", flexDirection: "row", margin: "auto", width: "40%" }}>
                                    {rating != null && rating > 0 ?
                                          <React.Fragment>
                                                <StarRatings rating={rating} />
                                                {revieNum !=null && revieNum>0?<span className = "zen-retreat-location-rating-num" style={{ fontSize:"70%",paddingTop:"7px"}}>&nbsp;({revieNum})</span> : null}
                                          </React.Fragment>
                                          : null
                                    }
                              </div>
                              <div className = "zen-retreat-location" style={rating != null && rating > 0 ? { margin: "auto", width: "40%" } : { margin: "auto", width: "50%"}}>{props.location}</div>
                              <div className = "zen-retreat-price" style={rating != null && rating > 0 ? { margin: "auto", width: "20%" } : { margin: "auto", width: "50%"}}>£{props.price}</div>
                        </div>

                  </div>
            </React.Fragment>
      );
}
