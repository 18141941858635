import axios from "axios";
import React, { createContext, useContext, useState } from "react";
import { ZEN_C_ENDPOINT } from "../config/EnvironmentalVariables";
import { useAuth } from "./auth";

interface UseZenJournal {
  journalLogs: any [],
  createJournalEntry: (mood:string,comment:string) => any,
  getUserJournal: () => void,
  createPracticeLog: (practiceId:number, liked:boolean,comment:string,mood:string) => void,
}

type Props = {
  children?: React.ReactNode;
};
const ZenJournalContext = createContext({} as UseZenJournal);

export const ProvideZenJournalContext: React.FC<Props> = ({ children }) => {
    const zenJournalService = ZenJournalService();
    return <ZenJournalContext.Provider value ={zenJournalService}>{children}</ZenJournalContext.Provider>;
};

export const useZenJournalService = () => {
    return useContext(ZenJournalContext);
};

export const ZenJournalService = () => {
    const auth = useAuth();

    const [journalLogs, setJournalLogs] = useState(null);


  const getUserJournal = async () => {
      let x = await axios.get(ZEN_C_ENDPOINT+`/myzen/journal/getJournal/`+auth.username)
      .then(res => {
        if(res.data){
          setJournalLogs(res.data);
        } else {
          setJournalLogs([]);
        }
      }).catch(() => {
        setJournalLogs([]);
      })

      return true;
    }

    const createJournalEntry = async (mood:string,comment:string) => {
      
      console.log("creatING journal entry.");
      return await axios.post(ZEN_C_ENDPOINT+`/myzen/journal/addEntry`,{
        username: auth.username,
        content: comment,
        mood: {
            emojis:[mood]
        }
    })
      .then(res => {
        console.log("creatING journal entry. succes");
        return res.data;
      }).catch(() => {
        console.log("creatING journal entry fail");
        return null;
      });
      
    }

    const createPracticeLog = (practiceId:number, liked:boolean, comments:string,mood:string) => {

      const payload = mood? {
        username: auth.username,
        content: comments,
        mood: {
            emojis:[mood]
        },
        practiceLog:{
          practiceId:practiceId,
          enjoyed:liked,
          completed:true
        }
        } : {
          username: auth.username,
          content: comments,
          practiceLog:{
            practiceId:practiceId,
            enjoyed:liked,
            completed:true
          }
      }
      axios.post(ZEN_C_ENDPOINT+`/myzen/journal/addEntry`,payload)
      .then(res => {
        getUserJournal();
      })
      axios.post(ZEN_C_ENDPOINT+`/zen/content/addView/`+practiceId)
      .then(res => {
        getUserJournal();
      })
      
    }
    
    const moodLog = {

    };
    const practiceLog = {

    };
    const journalEntry = {

    };
    //const jounralLogs = [journalEntry];


 
    return {
      journalLogs,
      createJournalEntry,
      getUserJournal,
      createPracticeLog
    };

}