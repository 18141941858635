import * as React from "react";
import "../../styling/zenverse/Zenverse.scss";
import "../../styling/common/index.scss";
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ZenNavBar } from "../common/navbar/ZenNavBar";
import { useState } from "react";
import PrivateRoute from "../common/PrivateRoute";
import { PuffLoader, BarLoader } from "react-spinners";
import { useStreamService } from "../../hooks/StreamContext";
import ZenContentInterface from "../zencontent/practices/ZenContentInterface";
import { ProvideMyStuffContext, useMyStuffService } from "../../hooks/MyStuffContext";
import { ReelStreamReelItem } from "../zencontent/reels/ReelStreamReelItem";

export const ReelStream = () => {

  const streamService = useStreamService();

  const [primaryColor, setPrimaryColor] = useState('#D1603D');

  return (
    <div className="reelStream">
      <div className="reelStreamColumn">
      {streamService.reelItems.filter((ri, index) => index % 2 == 0)}
    </div>
    <div className="reelStreamColumn">
      <div className="reelSteamSpacer">
        <h2>Reel Stream</h2>
      </div>
      {streamService.reelItems.filter((ri, index) => index % 2 == 1)}
    </div>
    </div>
  );

}

