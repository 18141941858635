import * as React from "react";
import '../../styling/common/index.scss';
import '../../styling/common/zenCoreUIComponents.scss';
import { ZenHome } from "./ZenHome";

export const GdprBanner = (props: any) => {

  const [showMore, setShowMore] = React.useState(false);

  return (
    <React.Fragment>
      {!props.accepted ?
        <div className="zen-walkthrough-helper helper-bottom">

          <div style={{ width: "80%", marginLeft: "10%" }}>
            <h3>Once signed up, this site uses cookies to enhance user experience in a number of ways.</h3>
            {showMore?
            <>            
            <h3>We never share your personal data with anyone, and only collect information regarding suggesting you relevant content on the site.</h3>

            <h3>Free tier users will recieve ads powered by google, but we do not submit any zencyclopedia user data to any advertising partners. </h3>
            </>
          :null}

            <div className="zenc-button-row">
              <div className="zencTextButton zencRedButton" onClick={() => {setShowMore(true); props.setAccepted(false);}}>
                Read More
              </div>
              <div className="zencTextButton zencGreenButton" onClick={() => props.setAccepted(true)}>
                No Problem
              </div>
            </div>
          </div>
        </div> : null}
    </React.Fragment>

  );
}