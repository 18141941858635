import React, { useState } from 'react';
import "../../../styling/vacana/vacana.scss";
import "../../../styling/common/index.scss";
import "../../../styling/stream/stream.scss";
import { PathIcons } from './PathIcons';
import { Link } from 'react-router-dom';
import axios from "axios";
import { calculateTotalPracticeTime } from '../../../service/ContentService';
import { ZEN_C_ENDPOINT } from '../../../config/EnvironmentalVariables';
import { useAuth } from '../../../hooks/auth';
import { PathCompletionMeter } from './PathCompletionMeter';
import PathInterface from './PathInterface';
import { PracticeListItem } from '../practices/PracticeListItem';
import PractitionerInterface from '../../practitioner/PractitionerInterface';

export const PathOverview = (props: PathInterface) => {

  console.log("coming");
  
  const [showSyllabus, setShowSyllabus] = useState(false);
  const [getPathCompletionTries, setGetPathCompletionTries] = useState(0);
  const [pathCompletionRate, setPathCompletionRate] = useState(null);
  const [practitioner, setPracitioner] = useState(null as PractitionerInterface);
  const [getTries, setGetTries] = useState(0);
  const [searchPending, setSearchPending] = useState(null);

  const getPractitionerInfo = (username: string) => {
      setSearchPending(true);
      axios.get(ZEN_C_ENDPOINT + `/myzen/practitioner/` + username)
          .then(res => {
              if (res.data) {
                  setPracitioner(res.data);
              }
          })

  }


  if (practitioner == null && props.practitioner != null && getTries < 5 && !searchPending) {
      setGetTries(getTries + 1);
      getPractitionerInfo(props.practitioner);
  } 
  
  let auth = useAuth();

  const getCourseCompletion = () => {
    setSearchPending(true);
    axios.get(ZEN_C_ENDPOINT + `/myzen/myZenContent/pathCompletion/` + auth.username + `/` + props.id)
      .then(res => {
        setPathCompletionRate(res.data);
      })
  }

  if (practitioner == null) {
    //setPractitioner(getPractitioner(props.practitionerId));
  }

  if (props != null && pathCompletionRate == null && getPathCompletionTries < 5 && !searchPending) {
    setGetPathCompletionTries(getPathCompletionTries + 1);
    getCourseCompletion();
  }

  var holderClassNames = "stream-item path-overview";

  var pathSummaryLink = "/explore/course/" + props.id;

  var practiceList = props && props.syllabus && props.syllabus.length > 0 ? props.syllabus.filter((l) => l != null && l.title !=null).map(lesson => {
    return <PracticeListItem practice={lesson}></PracticeListItem>
  }) : [];

  const pathDuration = props && props.syllabus && props.syllabus.length > 0 ? calculateTotalPracticeTime(props.syllabus) : 0;
  const pathSessionNumber = practiceList.length;

  console.log("WE HAVE: ");
  console.log(props);
  return (

    <div className={holderClassNames}>
      <div className="path-details-section stream-path-details">
        <div className='course-tag'>Course</div>
        {/* <FavouriteIcons/> */}

        <div className='zen-mobile'>
        <h1 className="path-title okashi">{props.title} </h1>
        <h4 className="path-creator okashi">{practitioner ? "by " + practitioner.username : null}</h4>
        <Link to={pathSummaryLink}>
          <div className="path-image-holder-mobile">
            <img className="path-image" src={props.image ? props.image : require("../../../static/photos/AwM.png")} onClick={() => setShowSyllabus(!showSyllabus)}></img>
          </div>
        </Link>
        </div>

        <div className='zen-desktop'>
        <div style = {{display:"flex", flexDirection:"row", width:"fit-content",margin:"auto"}}>
        <Link to={pathSummaryLink}>
          <div className="path-image-holder">
            <img className="path-image" src={props.image ? props.image : require("../../../static/photos/AwM.png")} onClick={() => setShowSyllabus(!showSyllabus)}></img>
          </div>
        </Link>
        <h1 className="path-title okashi">{props.title} </h1>
        <h4 className="path-creator okashi">{practitioner ? "by " + practitioner.username : null}</h4>
        </div>
        </div>

        {pathCompletionRate != null && pathCompletionRate.totalLessons != null ?
          <div style={{ display: "flex", flexDirection: "row", width: "225px", margin:"auto"}}>
            {pathCompletionRate != null && pathCompletionRate.lessonsCompleted != null && pathCompletionRate.lessonsCompleted > 0?
            <h4>{pathCompletionRate.lessonsCompleted}/{pathCompletionRate.totalLessons}</h4>:null
            }
            <PathCompletionMeter pathId={props.id} pathCompletionRate={pathCompletionRate} preview={true} />
          </div>
          : null}
        <Link to={pathSummaryLink}>
          <PathIcons {...practitioner} pathSessionNumber={pathSessionNumber} pathDuration={pathDuration}></PathIcons>
        </Link>

        <Link to={pathSummaryLink}>
          <h4 style={{ width: "100%", margin: "auto", textAlign: "center",marginTop:"15px",marginBottom:"20px"}}>See More</h4>
        </Link>

      </div>
    </div>
  );

}