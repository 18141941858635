import * as React from "react";
import "../../../styling/explore/explore.scss";
import "../../../styling/common/index.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { faYinYang } from '@fortawesome/free-solid-svg-icons'
import { ZenNavBar } from "../../common/navbar/ZenNavBar";
import { Link } from "react-router-dom";

export const ExploreSliderPreview = (props: {article?:any, description?:string, index?: number, title: string, image?: any, photoBottom?: boolean, itemType?: string, translation?: string, itemResultId?: string, searchRoute?: string, colorClass?: string }) => {

    var holderClass = props.index % 2 == 1?  "zenscape-slider-item explore-slider-item" :  "zenscape-slider-item explore-slider-item zenscape-slider-item-alt";
    if(props.itemType == "med"){
        holderClass = holderClass + " meditation-slider-item";
    } else if (props.article){
        holderClass = holderClass + " article-slider-item";
    }
    return (
        <React.Fragment>
            <Link to={props.searchRoute}>
                <div className={holderClass} >
                    <img className={props.article? "explore-slider-image article-slider-image" : props.itemType == "med"? "explore-slider-image article-slider-image": "explore-slider-image"} src={props.image ? props.image : null} ></img>
                    <h1 className="zencyc-contents-item-header-bottom zenK"><b>{props.title}</b> <span className='zencyc-contents-item-header-trans'>{props.translation}</span></h1>
                    <h4 className = "explore-slider-description">{props.description}</h4>
                </div>
            </Link>
        </React.Fragment>
    );

}

