import * as React from "react";
import "../../styling/zenverse/Zenverse.scss";
import "../../styling/common/zenCoreUIComponents.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // req
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {ZenergyPaymentCheckout} from "./ZenergyPaymentCheckout";
import axios from "axios";

export const ZenergyPointsPayment = (props: any) => {


  const [stripePromise, setStripePromise] = React.useState(null);
  const [clientSecret, setClientSecret] = React.useState("");

  let zenStripeAPI = "https://www.zencyclopedia.co.uk/myzen/zenPayments";

  React.useEffect(() => {
    console.log("Getting Stripe Config.");
    axios.get(zenStripeAPI + `/stripeConfig`)
      .then(res => {
        console.log("PK "+res.data);
        setStripePromise(loadStripe(res.data));
      }).catch(() => {
       
      })
  }, []);

  React.useEffect(() => {
    console.log("making payment intent");
    axios.post(zenStripeAPI + `/createPaymentIntent/`+props.amount)
    .then(res => {
      console.log("CK "+res.data);
        setClientSecret(res.data);
    }).catch(function (error) {
      setClientSecret(null);
      console.log(error);
    });
  }, []);

  return (//myZen.profile? (
    <div className="ZenBasicPage">
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <ZenergyPaymentCheckout amount={props.amount} zenergyPoints={props.zenergyPoints}/>
        </Elements>
      )}
    </div>
  );

}
