import * as React from "react";
import "../../styling/zenverse/Zenverse.scss";
import "../../styling/common/zenCoreUIComponents.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // req
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useNavigate, useParams } from "react-router";
import { ZencyclopaediaItemPreview } from "../explore/previews/ZencyclopaediaItemPreview";
import { Link } from "react-router-dom";
import { ZencycyclopaediaPreviewGroup } from "../explore/ZencycyclopaediaPreviewGroup";
import PrivateRoute from "../common/PrivateRoute";
import { ZenNavBar } from "../common/navbar/ZenNavBar";
import { ZencyclopaediaTextItemPreview } from "../explore/previews/ZencyclopaediaTextItemPreview";
import { useZenverseService } from "../../hooks/ZenverseContext";
import { getPortalJSX } from "../../service/ZenverseService";
import { Configuration, OpenAIApi } from 'openai';
import Chatbot from "react-chatbot-kit";
import { ZencTitle } from "../home/ZencTitle";
import { MyZenNavBar } from "../common/navbar/MyZenNavBar";
import { useMyZenService } from "../../hooks/ZenUserContext";
import { useAuth } from "../../hooks/auth";
import { CardElement, Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {ZenergyPaymentCheckout} from "./ZenergyPaymentCheckout";
import axios from "axios";
import { DonationCheckout } from "./DonationCheckout";

export const DonationPayment = (props: any) => {

  const [stripePromise, setStripePromise] = React.useState(null);
  const [clientSecret, setClientSecret] = React.useState("");

  let zenStripeAPI = "https://www.zencyclopedia.co.uk/myzen/zenPayments";

  React.useEffect(() => {
    console.log("Getting Stripe Config.");
    axios.get(zenStripeAPI + `/stripeConfig`)
      .then(res => {
        console.log("PK "+res.data);
        setStripePromise(loadStripe(res.data));
      }).catch(() => {
       
      })
  }, []);

  React.useEffect(() => {
    console.log("making payment intent");
    axios.post(zenStripeAPI + `/createPaymentIntent/`+props.amount)
    .then(res => {
      console.log("CK "+res.data);
        setClientSecret(res.data);
    }).catch(function (error) {
      setClientSecret(null);
      console.log(error);
    });
  }, []);

  return (//myZen.profile? (
    <div>
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <DonationCheckout amount={props.amount}/>
        </Elements>
      )}
    </div>
  );

}
