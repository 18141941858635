import * as React from "react";
import "../../styling/common/index.scss";
import "../../styling/common/zenCoreUIComponents.scss";
import "../../styling/myzen/myzen.scss";
import { ZenNavBar } from "../common/navbar/ZenNavBar";
import { useState } from "react";
import { useZenExploreService } from "../../hooks/ZenExploreContext";
import axios from "axios";
import { ZEN_C_ENDPOINT } from "../../config/EnvironmentalVariables";
import { useAuth } from "../../hooks/auth";
import { PathPreview } from "../zencontent/courses/PathPreview";
import { useNavigate, useParams } from "react-router";
import { PuffLoader } from "react-spinners";
import { getContentCatagories } from "../../service/ZencyclopaediaService";
import { ZenPopUpMessage } from "../common/ZenPopUpMessage";
import { ZenSearchFilterTagsDisplay } from "./search/ZenSearchFilterTagsDisplay";
import { ZenSearchBar } from "./search/ZenSearchBar";
import { useZenSearchService } from "../../hooks/ZenSearchContext";
import Slider from "react-slick";
import { ExploreSliderPreview } from "./previews/ExploreSliderPreview";



export const CourseCarousel = () => {

      const sliderRef: any = React.createRef();
      const desktopSliderRef: any = React.createRef();


      const generateCarouselInner = (carouselRef:any) => {
            return [
                  <div style={{ margin: "auto" }}>
                        <div className="zenscape-slider-item explore-slider-item" onClick={() => {
                              carouselRef.current.slickGoTo(1);
                        }}>
                              <h3 className="explore-slider-title" >Explore our range of ZEN courses to bring direction to the next stage of your mindfulness journey!</h3>
                              <h4 className="explore-slider-description" >Find our top user rated courses in the carousel - or use the search bar to look for something more specific</h4>
                        </div>
                  </div>,

                  <ExploreSliderPreview index={0}
                        description={"A range of course's designed for those new to meditation, providing foundational techniques and guidance."}
                        photoBottom={true}
                        title='Beginner'
                        searchRoute={"/explore/courses/beginner"} image={require("../../static/photos/exploreIcons/courses/Frogmenu.png")}></ExploreSliderPreview>,
                  <ExploreSliderPreview index={1}
                        description={"Discover meditation techniques that can support mental well-being and alleviate symptoms of depression."}
                        photoBottom={true} title='Depression' searchRoute={"/explore/courses/depression"} image={require("../../static/photos/exploreIcons/courses/Masks.png")}></ExploreSliderPreview>,
                  <ExploreSliderPreview index={2}
                        photoBottom={true}
                        description={"Learn meditation practices specifically aimed at managing and reducing anxiety levels."}
                        title='Anxiety' searchRoute={"/explore/courses/anxiety"} image={require("../../static/photos/exploreIcons/courses/Anxiety.png")}></ExploreSliderPreview>,
                  <ExploreSliderPreview index={3}
                        description={"Experience deep relaxation through various meditation methods, promoting inner calm and stress relief."}
                        photoBottom={true} title='Relaxation' searchRoute={"/explore/courses/relax"} image={require("../../static/photos/mainGroups/relaxation.png")}></ExploreSliderPreview>,
                  <ExploreSliderPreview index={4}
                        image={require("../../static/photos/exploreIcons/courses/takeabreath.png")}
                        description={"Explore the transformative power of conscious breathing exercises and their benefits in meditation."}
                        title='Breathing' searchRoute={"/explore/courses/breathwork"} colorClass={'ZenCGradient1'}></ExploreSliderPreview>,
                  <ExploreSliderPreview index={5}
                        image={require("../../static/photos/exploreIcons/courses/yoga.png")}
                        description={"Combine meditation with gentle movements and postures to cultivate mindfulness in motion."}
                        title='Movement' searchRoute={"/explore/courses/move"} colorClass={'ZenCGradient3'}></ExploreSliderPreview>,
                  <ExploreSliderPreview index={6}
                        image={require("../../static/photos/exploreIcons/courses/Vipassana.png")}
                        description={"Engage in short and focused meditation sessions to fit into busy schedules and find moments of tranquility throughout the day."}
                        title='Shorts' searchRoute={"/explore/courses/shorts"} colorClass={'ZenCGradient1'}></ExploreSliderPreview>,
                  <ExploreSliderPreview index={7}
                        image={require("../../static/photos/exploreIcons/courses/buddha.png")}
                        description={"Deepen your understanding of meditation within the context of Buddhist principles and teachings."}
                        title='Buddhism' searchRoute={"/explore/courses/buddhism"} colorClass={'ZenCGradient3'}></ExploreSliderPreview>,

                  <ExploreSliderPreview index={8}
                        image={require("../../static/photos/exploreIcons/courses/teachings.png")}
                        description={"Delve into the philosophical and spiritual aspects of meditation through teachings and insightful discussions."}
                        title='Teachings' searchRoute={"/explore/courses/teachings"} colorClass={'ZenCGradient1'}></ExploreSliderPreview>,
                  <ExploreSliderPreview index={9}
                        image={require("../../static/photos/exploreIcons/courses/sleepy.png")}
                        description={"Learn meditation techniques specifically tailored to promote deep relaxation and improve the quality of sleep."}
                        title='Sleep' searchRoute={"/explore/courses/sleep"} colorClass={'ZenCGradient1'}></ExploreSliderPreview>,
                  <ExploreSliderPreview index={10}
                        image={require("../../static/photos/exploreIcons/courses/happy-sun.png")}
                        description={" Start your day with mindful meditation practices to set a positive and centered tone for the rest of the day."}
                        title='Morning' searchRoute={"/explore/courses/morning"} colorClass={'ZenCGradient3'}></ExploreSliderPreview>,
            ];
      }

      return (
            <div className="zenverseSliderHolder">
                  <div className="zen-mobile">
                        <Slider
                              centerMode={true}
                              centerPadding='15%'
                              autoplay={true}
                              ref={sliderRef}
                              dots={true}
                              infinite={true}
                        >
                              {generateCarouselInner(sliderRef)}
                        </Slider>
                  </div>
                  <div className="zen-desktop">
                        <Slider
                              centerMode={true}
                              centerPadding='35%'
                              autoplay={true}
                              ref={desktopSliderRef}
                              dots={true}
                              infinite={true}
                        >
                        {generateCarouselInner(desktopSliderRef)}
                        </Slider>
                  </div>
            </div>
      )
}