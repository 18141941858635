import React, { useState } from 'react';
import "../../../styling/vacana/vacana.scss";
import "../../../styling/common/index.scss";
import "../../../styling/common/completionMeter.scss";
import "../../../styling/stream/stream.scss";

import { useAuth } from '../../../hooks/auth';
import { ZEN_C_ENDPOINT } from '../../../config/EnvironmentalVariables';
import axios from 'axios';
import { getPathCompletionMeterClass, getPathCompletionProgressMeterClass } from '../../../service/ZenUserService';

export const PathCompletionMeter = (props: {altColors?:boolean, addPath?:any, userOwnsPath?:boolean, pathId : any, pathCompletionRate: any, preview?: boolean, pathAdded?: boolean}) => {

  var holderClass = props.preview ? "path-completion-meter" : "path-completion-meter-large";
  
  var meterClass = props.pathCompletionRate && !props.preview? getPathCompletionMeterClass(props.pathCompletionRate) 
  : props.pathCompletionRate && props.preview?  getPathCompletionProgressMeterClass(props.pathCompletionRate) 
  : null;

  return (
    <React.Fragment>
      {props.preview && props.pathCompletionRate && props.pathCompletionRate.lessonsCompleted >0? 
      <div className={props.altColors? "path-progress path-progress-alt" : "path-progress"}>
        <div className={"path-progress-value " + meterClass}>
          
        </div>
      </div>
      :props.preview?
      <div>
        0/{props.pathCompletionRate.totalLessons} sessions completed
      </div>
      :meterClass && props.pathCompletionRate && props.pathCompletionRate.lessonsCompleted >0? 
        <div className={holderClass}>
          <svg className="path-completion-meter-svg">
            <circle className="bg" cx="57" cy="57" r="52" />
            <circle className={meterClass} cx="57" cy="57" r="52" />
          </svg>
        </div>
        :!props.preview && (props.userOwnsPath || props.pathAdded)?
        <React.Fragment>
          <div style={{marginTop:"-5px"}}>
          <div className="removeZenscapeIcon pathSummaryActionIcon" style={{ width: "150px", height: "150px"}} onClick = {() => {}}></div>
          <h4 className='centered-text'>Remove Path</h4>
          </div>
        </React.Fragment>
        :!props.preview?
        <React.Fragment>
          <div style={{marginTop:"-5px"}}>
          <div className="addZenscapeIcon pathSummaryActionIcon" style={{ width: "150px", height: "150px" }} onClick = {() => props.addPath()}></div>
          <h4 className='centered-text'>Save Path</h4>
          </div>
        </React.Fragment>
        : null
      }
    </React.Fragment>


  );
}