export const getSubscriptionCost = (subTier:string) => {
    return subTier == "BASIC"? "99p" 
        : subTier == "PREMIUM"? "£1.99" 
        : null;
}

// import MyZenTaskInterface from "../components/myzen/profile/tasks/MyZenTaskInterface";
// import ZenBlueprintInterface from "../components/myzen/interface/ZenWeekPlannerInterface";
// import ZenPracticeSessionInterface from "../components/myzen/interface/ZenPracticeSessionInterface";
// import PractitionerInterface from "../components/profiles/interfaces/PractitionerInterface";

// export function fetchMyZenStarterTasks() : MyZenTaskInterface [] {

//     var tasks = [];

//     // 
//     tasks.push(
//         {
//             id:"1",
//             title:"Take a mindful moment in the ZenPortal",
//             action:"zenPortalWalkthrough",
//             icon:"ohm"

//         }
//     );
//     tasks.push(
//         {
//             id:"1",
//             title:"Check Out The Zen Stream!",
//             action:"zenPortalWalkthrough",
//             icon:"logo"
//         }
//     );

//     // 

//     //

//     return tasks;
// }

// export function fetchUserEnabledServices() : any {

//     //First Breathing.
//     //
// }

// export function fetchMyZenBlueprint() : any {
    
//     var med : ZenPracticeSessionInterface = {
//         practice: {
//             title:'Mindful Breathing',
//             duration:'15 Minutes'
//         },
//         blueprint:true,
//         sessionType:'med'
//     }
    



// var prac : ZenPracticeSessionInterface = {
//     practice: {
//         title:'META',
//         duration:'20 Minutes'
//     },
//     blueprint:true,
//     sessionType:'med'
// }


// var hobby : ZenPracticeSessionInterface = {
//     practice: {
//         title:'Mindful Walk',
//         duration:'30 Minutes'
//     },
//     blueprint:true,
//     sessionType:'hobby'
// }


// var zazen : ZenPracticeSessionInterface = {
//     practice: {
//         title:'ZAZEN',
//         duration:'45 Minutes'
//     },
//     blueprint:true,
//     sessionType:'zazen'
// }


// var yoga : ZenPracticeSessionInterface = {
//     practice: {
//         title:'QiGong',
//         duration:'20 Minutes'
//     },
//     blueprint:true,
//     sessionType:'yoga'
// }


// var relax : ZenPracticeSessionInterface = {
//     practice: {
//         title:'Yoga Nidra',
//         duration:'20 Minutes'
//     },
//     blueprint:true,
//     sessionType:'relax'
// }

// var lecture : ZenPracticeSessionInterface = {
//     practice: {
//         title:'Handling Anxiety',
//         duration:'20 Minutes'
//     },
//     blueprint:true,
//     sessionType:'lecture'
// }
// var mon = [med];
// var tue = [yoga];
// var wed = [hobby];
// var thu = [relax];
// var fri = [zazen];
// var sat = [prac,lecture];
// var sun = [med,relax];


// var blueprint : ZenBlueprintInterface = {
//     monday:mon,
//     tuesday:tue,
//     wednesday:wed,
//     thursday:thu,
//     friday:fri,
//     saturday:sat,
//     sunday:sun
// }

// return blueprint;
// }

// export function fetchMyZenPlanner() : any {
//     // Monday
//     var med : ZenPracticeSessionInterface = {
//         practice: {
//             title:'My First Meditation',
//             duration:'5 Minutes'
//         },
//         sessionType:'med'
//     }
// var prac : ZenPracticeSessionInterface = {
//     practice: {
//         title:'META',
//         duration:'20 Minutes'
//     },
//     sessionType:'med'
// }


// var hobby : ZenPracticeSessionInterface = {
//     practice: {
//         title:'Mindful Walk',
//         duration:'30 Minutes'
//     },
//     sessionType:'hobby'
// }


// var zazen : ZenPracticeSessionInterface = {
//     practice: {
//         title:'ZAZEN',
//         duration:'45 Minutes'
//     },
//     sessionType:'zazen'
// }


// var yoga : ZenPracticeSessionInterface = {
//     practice: {
//         title:'QiGong',
//         duration:'20 Minutes'
//     },
//     sessionType:'yoga'
// }


// var relax : ZenPracticeSessionInterface = {
//     practice: {
//         title:'Yoga Nidra',
//         duration:'20 Minutes'
//     },
//     sessionType:'relax'
// }

// var lecture : ZenPracticeSessionInterface = {
//     practice: {
//         title:'Handling Anxiety',
//         duration:'20 Minutes'
//     },
//     sessionType:'lecture'
// }

// var whim : ZenPracticeSessionInterface = {
//     practice: {
//         id:1
//     },
//     sessionType:'planned'
// }
// var mon = [med];
// var tue = [yoga];
// var wed = [whim,hobby];
// var thu = [relax];
// var fri = [zazen];
// var sat = [prac,lecture];
// var sun = [med,relax];


// var blueprint : ZenBlueprintInterface = {
//     monday:mon,
//     tuesday:tue,
//     wednesday:wed,
//     thursday:thu,
//     friday:fri,
//     saturday:sat,
//     sunday:sun
// }

// return blueprint;
// }
// export function fetchMyZenNotifications() : any {

// }
// export function fetchMyBookmarks() : any {

// }


// export function getTomAndHisCat() : PractitionerInterface {
//     var tomAndHisCat :PractitionerInterface = {
//     name:"TomAndHisCat",
//     image:require("../static/2241427_15ad667e_es.jpg")
//     }
//     return tomAndHisCat;
//     }