import * as React from "react";
import "../../styling/explore/explore.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

export const ShopMainItem = (props: { outOfStock?: boolean, inactive?: boolean, id?: number, title: string, image: any, photoBottom?: boolean, itemType?: string, tagLine?: string, translation?: string, itemResultId?: string, searchRoute?: string, clickAction?: any }) => {


    const sliderRef: any = React.createRef();

    const contentsItemLink = props.itemType && props.itemType == 'Infomatic' ?
        "/info/"
        : props.itemType && props.itemType == 'ZencycResultItem' ?
            "/search/" + props.itemResultId
            :
            props.itemType && props.itemType == 'zenShop' ?
                "/shop"
                :
                props.itemType && props.itemType == 'medBrowser' ?
                    "/search/2"
                    :
                    props.itemType && props.itemType == 'zenverse' ?
                        "/zenverse"
                        : props.searchRoute ? props.searchRoute

                            : props.id ? props.id.toString()
                                :
                                "";


    return (
        <div className={"zen-shop-item-holder"}>
            <div className={'zen-shop-item'} onClick={props.clickAction ? () => props.clickAction() : () => { }} >
                {props.photoBottom ?
                    <>
                        <h1 className="zencyc-contents-item-header zenK"><b>{props.title}</b> <br /><span className='zencyc-contents-item-header-trans'>{props.tagLine}</span></h1>
                        <img className="shop-item-image" src={props.image ? props.image : null} ></img>
                    </>
                    :
                    <div>
                        <div style={{ position: "relative" }}>
                            <img className="shop-item-image" src={props.image ? props.image : null} style={props.outOfStock ? { opacity: "1" } : {}}></img>
                        </div>
                        <div>
                            {props.outOfStock ? <img className="shop-item-oos-image" src={require("../../static/photos/shop/out-of-stock-image.png")} ></img> : null}
                            <h1 className="zencyc-contents-item-header-bottom zenK"><b>{props.title}</b> <br></br><span className='zencyc-contents-item-header-trans'>{props.tagLine}</span></h1>
                        </div>
                    </div>
                }
            </div>
        </div>

    );

}

