import * as React from "react";
import "../../styling/explore/explore.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { faYinYang } from '@fortawesome/free-solid-svg-icons'
import { ZenNavBar } from "../common/navbar/ZenNavBar";
import { ZencyclopaediaItemPreview } from "./previews/ZencyclopaediaItemPreview";

export const ZencycyclopaediaPreviewGroup = (props : any) => {


    const sliderRef:any = React.createRef();
    //onst resultItems = props.resultList? props.resultList.map() : null;



    return (
      <div>
        {props.resultList?
          <div className ='zencyclopaedia-contents-group'>
              {props.groupName?
              <h1 className="zencyc-contents-group-header zenK">{props.groupName}</h1>
              : <></>
            }
              <div className = {props.medium? 'zencyclopaedia-contents-group-items zencyclopaedia-contents-group-items-med' : 'zencyclopaedia-contents-group-items'}>
                {props.resultList}
              </div>
          </div>
        :
        <></>
        }
      </div>
    );
  
}

