import React, { useState } from 'react';
import "../../styling/common/index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import Draggable, { DraggableData } from 'react-draggable';

export const JustAnAd = (props : any) => {

    return (
        <div className = "justAnAd">


          <div className = "instaPortraitAd" style = {{paddingTop:"0px"}}> 
          <h3 className = "instaAdText" style = {{marginTop:"25px", paddingTop:"25px"}}><span className='okashi'>MOVE</span> the body</h3>
          <h3 className = "instaAdText" style = {{marginTop:"1175px"}}><span className='okashi'>STILL</span> the mind</h3>

          </div>


          <div style = {{height:"2000px"}}>

          {/* <div className = "justAnAdTextHolder">
            <h2 className = "adRegularText">a <span className = "adBoldText">ZEN MONK</span></h2>
            <h2 className = "adRegularText">walks into a pizza shop and demands:</h2>
            <h2 className='adBoldText' style = {{fontSize:"175%"}}>MAKE ME ONE WITH EVERYTHING</h2>
          </div> */}

          <div className = "justAnAdTextHolder justAnAdTextHolderRedBlack" style = {{textAlign:"center"}}>
            <h2 className = "adRegularText">a <span className = "adBoldText">ZEN MONK</span></h2>
            <h2 className = "adRegularText">walks into a pizza shop and demands:</h2>
            <h2 className='adBoldText' style = {{fontSize:"175%"}}>MAKE ME ONE WITH EVERYTHING</h2>
          </div>

          <div className = "justAnAdTextHolder"  style={{marginTop:"50px"}}>
            <h2 className = "adRegularText">We’re on a</h2>
            <h2 className='adBoldText' style = {{fontSize:"175%"}}>MISSION</h2>
            <h2 className = "adRegularText" style = {{fontSize:"155%"}}>To create the largest</h2>
            <h2 className='adBoldText'>FREE <span className = "adRegularText">and</span> ACCESSIBLE</h2>
            <h2 className='adBoldText'><span className = "adRegularText">collection of </span>MINDFULNESS </h2>
            <h2 className = "adRegularText"><span className = "adRegularText">resources on the planet</span></h2>
          </div>

          <div className = "justAnAdTextHolder"  style={{textAlign:"center",marginTop:"50px"}}>
            <h2 className = "adRegularText">We’re on a</h2>
            <h2 className='adBoldText' style = {{fontSize:"175%"}}>MISSION</h2>
            <h2 className = "adRegularText" style = {{fontSize:"155%"}}>To create the largest collection</h2>
            <h2 className='adBoldText'><span className = "adRegularText">of </span>FREE <span className = "adRegularText">and</span> ACCESSIBLE</h2>
            <h2 className = "adBoldText">MINDFULNESS<span className = "adRegularText"> resources on the planet</span></h2>
          </div>

          
          <div className = "justAnAdTextHolder" style={{textAlign:"center", marginTop:"50px"}}>
            <h2 className = "adRegularText">We’re on a</h2>
            <h2 className='adBoldText' style = {{fontSize:"175%"}}>MISSION</h2>
            <h2 className = "adRegularText" style = {{fontSize:"155%"}}>To create the largest</h2>
            <h2 className='adBoldText'>FREE <span className = "adRegularText">and</span> ACCESSIBLE</h2>
            <h2 className='adBoldText'><span className = "adRegularText">collection of </span>MINDFULNESS </h2>
            <h2 className = "adRegularText"><span className = "adRegularText">resources on the planet</span></h2>
          </div>

          <div className = "justAnAdTextHolder justAnAdTextHolderRed" style={{textAlign:"center", marginTop:"50px"}}>
            <h2 className = "adRegularText">We’re on a</h2>
            <h2 className='adBoldText' style = {{fontSize:"175%"}}>MISSION</h2>
            <h2 className = "adRegularText" style = {{fontSize:"155%"}}>To create the largest</h2>
            <h2 className='adBoldText'>FREE <span className = "adRegularText">and</span> ACCESSIBLE</h2>
            <h2 className='adBoldText'><span className = "adRegularText">collection of </span>MINDFULNESS </h2>
            <h2 className = "adRegularText"><span className = "adRegularText">resources on the planet</span></h2>
          </div>

          <div className = "justAnAdTextHolder justAnAdTextHolderRedBlack" style={{textAlign:"center", marginTop:"50px", color:"#f7eddc"}}>
            <h2 className = "adRegularText">We’re on a</h2>
            <h2 className='adBoldText' style = {{fontSize:"175%"}}>MISSION</h2>
            <h2 className = "adRegularText" style = {{fontSize:"155%"}}>To create the largest</h2>
            <h2 className='adBoldText'>FREE <span className = "adRegularText">and</span> ACCESSIBLE</h2>
            <h2 className='adBoldText'><span className = "adRegularText">collection of </span>MINDFULNESS </h2>
            <h2 className = "adRegularText"><span className = "adRegularText">resources on the planet</span></h2>
          </div>

          </div>
    
        </div>
    );
}

