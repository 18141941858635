import * as React from "react";
import "../../../styling/common/index.scss";
import "../../../styling/myzen/myzen.scss";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useZenGoals } from "../../../hooks/ZenGoalsContext";
import { useZenCardsService } from "../../../hooks/ZenCardsContext";
import { ZenCardMilestoneNotice } from "../../explore/icons/ZenCardMilestoneNotice";

export const ChooseZenWeeklyTargets = (props: { refresh: any }) => {

      let zenGoals = useZenGoals();

      const [showSelfCare, setShowSelfCare] = useState(false);
      const [rewardCard, setRewardCard] = React.useState(null);

      const cardsContext = useZenCardsService();

      const WEEK_GOALS_MILESTONE_KEY = "SWG_MS";

      const submitSkills = async (length: number, numberOfSessions: number) => {
            //Check if we have a milestone card for this:
            zenGoals.chooseGoalTargets(length, numberOfSessions);
            var cardExists = cardsContext.checkForMilestone(WEEK_GOALS_MILESTONE_KEY);
            if (!cardExists) {
                  console.log("attempting to claim Milestone");
                  var rewardCard = await cardsContext.claimMilestone(WEEK_GOALS_MILESTONE_KEY);
                  if (rewardCard != null && rewardCard.cardKey != null) {
                        console.log("GOT WEEK GOALS MS");
                        setRewardCard(rewardCard);
                  } else {
                        console.log("FAILED TO GET MS");
                        props.refresh();
                  }
            } else {
                  props.refresh();
            }
      }

      return (
            <React.Fragment>
            {rewardCard != null ?
            <div  style = {{position:"fixed", top:"15px", width:"100%"}}>
            <div style = {{margin:"auto"}}>
            <ZenCardMilestoneNotice cards={[rewardCard]} moveOnAction={() => {setRewardCard(null); props.refresh();}}></ZenCardMilestoneNotice>
            </div>
            </div>
            :
            <div className="ming-page">
                        <React.Fragment>
                              {showSelfCare && (4 > 2) ?


                                    <div>
                                          <h2>Why not supplement your mindfulness training with some other mindfulness boosting activities? </h2>
                                          <h2>These will be included in your smart planner</h2>
                                          <h4>1 Practices</h4>
                                          <h4>3 Practices</h4>
                                          <h4>5 Practices</h4>
                                    </div>
                                    :
                                    <div style = {{overflowY:"scroll"}}>
                                          <div className="bp-goal-title" style={{ marginTop: "35px", marginBottom: "15px" }}>
                                                Let's create a weekly target to keep us motivated. recommended = <span className="rec-icon"></span>
                                                <br></br>You can meditate in the Zencyclopedia Practice portal for 5 hours per month for free!
                                          </div>

                                          <div onClick={() => { submitSkills(30, 3); }} className="bp-goal-choice">
                                                <h2>30 Minutes</h2>
                                                <h3>(Three 10 minute sessions per week)</h3>

                                          </div>
                                          <div onClick={() => { submitSkills(30, 2); }} className="bp-goal-choice bp-goal-choice-alt">
                                                <h2>30 Minutes</h2>
                                                <h3>(<b>Just</b> two 15 minute sessions per week)</h3>
                                                <h3 className="bp-goal-time">30 Minutes</h3>

                                          </div>
                                          <div onClick={() => { submitSkills(60, 4); }} className="bp-goal-choice">
                                                <h4 className="rec-tag"></h4>
                                                <h2>1 Hour</h2>
                                                <h3>(Four 15 minute sessions per week)</h3>
                                          </div>
                                          <div onClick={() => { submitSkills(90, 6); }} className="bp-goal-choice bp-goal-choice-alt">
                                                <h2>1.5 hours</h2>
                                                <h3>(Six 15 minute sessions per week)</h3>
                                          </div>
                                          <div onClick={() => { submitSkills(150, 7); }} className="bp-goal-choice">
                                                <h2>2.5 hours</h2>
                                                <h3>(Seven 20 Minute Sessions)</h3>
                                          </div>
                                          <div onClick={() => { submitSkills(210, 7); }} className="bp-goal-choice bp-goal-choice-alt">
                                                <h2>3.5 hours</h2>
                                                <h3>(Seven 30 Minute Sessions)</h3>
                                          </div>
                                          <div onClick={() => { }} className="bp-goal-choice ">
                                                <h3 className="bp-goal-time">Custom</h3>
                                          </div>
                                    </div>
                              }</React.Fragment>
            </div>}
            </React.Fragment>
      );

}
