import * as React from "react";
import "../../styling/zenverse/Zenverse.scss";
import "../../styling/explore/explore.scss";
import Zenscape from "./interfaces/ZenscapeInterface";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { PortalIcon } from "./ZenscapePortal/PortalIcon";
import { ZenscapePortal } from "./ZenscapePortal/ZenscapePortal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { ZenverseSidebar } from "./ZenverseSidebar";
import { ZenShopIcon } from "./ZenShopIcon";
import { useNavigate, useParams } from "react-router";
import { ZenscapeSliderItem } from "../explore/previews/ZenscapeSliderItem";
import { Link } from "react-router-dom";
import { ZencycyclopaediaPreviewGroup } from "../explore/ZencycyclopaediaPreviewGroup";
import { ZenNavBar } from "../common/navbar/ZenNavBar";
import { ZencyclopaediaTextItemPreview } from "../explore/previews/ZencyclopaediaTextItemPreview";
import { useZenverseService } from "../../hooks/ZenverseContext";
import { getPortalJSX } from "../../service/ZenverseService";
import { ZenscapeDemoPortal } from "../practiceportal/ZenscapeDemoPortal";
import { useAuth } from "../../hooks/auth";

export const ZenBrowserSlider = () => {

  let navigate = useNavigate();
  const sliderRef: any = React.createRef();
  const desktopSliderRef: any = React.createRef();

  const getZenverseSliderInner = (carouselRef: any) => {
    return [<div style={{ margin: "auto" }}>
      <div className="zenscape-slider-item" onClick={() => {
        carouselRef.current.slickGoTo(1);
      }}>
        <div style={{ margin: "auto", paddingTop: "25px" }} >
          <h3 style={{ width: "70%", margin: "auto" }} className="explore-slider-title">Find a Zenscape to take your practice to the next level</h3>
          <h4 className="explore-slider-description" style={{ marginTop: "25px" }}>Whether you're looking for Breathing aids, mindful backing music or <b>AI</b> meditation guides, we've got you covered.</h4>
        </div>
      </div>
    </div>,
    <ZenscapeSliderItem key={"zsc1"}
      title='Breathing Aids'
      description="Explore our range of breathing aids that can be used alongside your usual practices to regulate your breathe"
      photoBottom={true}
      image={require("../../static/photos/zenscapes/nordii.png")}
      searchRoute='/zenverse/breathing'
      itemType="ZENSCAPE"
    />,
    <ZenscapeSliderItem key={"zsc2"}
      description="Immerse yourself in soothing and calming musical compositions specifically curated for a tranquil meditation experience."
      title='Music' photoBottom={true} image={require("../../static/photos/zenscapes/music.png")} searchRoute='/zenverse/music' itemType="ZENSCAPE"></ZenscapeSliderItem>,
      <ZenscapeSliderItem key={"zsc3"} title='City Soundscapes'
      description="Transport your mind to a serene state with soundscapes that capture the essence of peaceful city environments."
      photoBottom={true} image={require("../../static/photos/zenscapes/cityscapes.png")} searchRoute='/zenverse/cityscapes' itemType="ZENSCAPE" ></ZenscapeSliderItem>,
    <ZenscapeSliderItem key={"zsc4"} title='Solar Punk'
      description="Dive into futuristic and harmonious visuals that embrace a vibrant blend of nature and technology in your meditation journey."
      photoBottom={true} image={require("../../static/photos/zenscapes/solarPunk.png")} searchRoute='/zenverse/solarpunk' itemType="ZENSCAPE"></ZenscapeSliderItem>,
    <div style={{ margin: "auto" }}>
      <div className="zenscape-slider-item" >
        <div style={{ margin: "auto", paddingTop: "25px" }} >
          <h3 style={{ width: "70%", margin: "auto" }} className="explore-slider-title">Upgrade!</h3>
          <h4 className="explore-slider-description" style={{ marginTop: "25px" }}>Increase your Zenscape inventory size by claiming a Zen Power Card</h4>
          <Link to="explore/zencards/power"></Link><div className="zenGlowButton navajoGlowButton">Let's go</div>
        </div>
      </div>
    </div>,
    <ZenscapeSliderItem key={"zsc5"} title='Nature Escapes'
      description="Connect with the serenity of nature through captivating visuals and audio that transport you to tranquil natural landscapes."
      photoBottom={true} image={require("../../static/photos/zenscapes/nature.png")} searchRoute='/zenverse/nature' itemType="ZENSCAPE"></ZenscapeSliderItem>,
    <ZenscapeSliderItem key={"zsc6"} title='Training Portals'
      description="Access a collection of Zenscapes designed to support and guide your meditation training with immersive and engaging experiences."
      photoBottom={true} image={require("../../static/photos/zenscapes/training.png")} searchRoute='/zenverse/training' itemType="ZENSCAPE"></ZenscapeSliderItem>,];

  }

  return (
    <div className="zenverseSliderHolder">
    <div className="zen-mobile">
      <Slider
        centerMode={true}
        centerPadding='15%'
        // autoplay={true}
        ref={sliderRef}
        dots={true}
        infinite={true}
      >
        {getZenverseSliderInner(sliderRef)}
      </Slider>
    </div>
      <div className="zen-desktop">
        <Slider
          centerMode={true}
          centerPadding='35%'
          // autoplay={true}
          ref={desktopSliderRef}
          dots={true}
          infinite={true}
        >
          {getZenverseSliderInner(desktopSliderRef)}
        </Slider>
      </div>
    </div>
  );

}
