import React, { useState } from 'react';
import "../../../styling/stream/stream.scss";
import "../../../styling/common/index.scss";
import { Link } from 'react-router-dom';
import ZenInfomaticInterface from './ZenInfomaticInterface';


export const InfomaticStreamItem = (props : ZenInfomaticInterface) => {

    return (
        <div className = "infomatic-stream-item stream-item">
        <Link to={"explore/information/"+props.contentId}>
          <div className='zen-nugget'>
            <h3 className='info-stream-item-header zenK'>Learn all about...</h3>
            <img className = "info-stream-item-image" src={props.image}></img>
            <h1 className='info-stream-item-title okashi'>{props.title}</h1>
          </div>
      </Link>
        </div>
    );
}