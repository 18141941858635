import * as React from "react";
import { useState } from "react";
import "../../styling/common/index.scss";
import ReactCrop, { type Crop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css';

export const ZenPicSizer = (props: { imageToShow, cropX, cropY, cropWidth, newWidth }) => {

      const [dimensions, setDimensions] = useState({
            yOffset: 0,
            xOffset: 0,
            scaledWidth: props.newWidth,
            scaledHeight: props.newWidth

      });

      const [lastSetFor, setLastSetFor] = useState(null);
      const [loading, setOLoading] = useState(false);

      function getImageDimensions(image: string) {
            return new Promise<{ width: number; height: number }>((resolve, reject) => {
                  const img = new Image();
                  img.src = image;

                  img.onload = function () {
                        const width = img.width;
                        const height = img.height;
                        resolve({ width, height });
                  };

                  img.onerror = function () {
                        reject(new Error("Error loading image."));
                  };
            });
      }


      const calculateImageDimensionsAndOffsets = (originalH: any) => {
            const originalWidth = 250;
            const originalHeight = originalH;
            // Replace with your original image height

            const targetSquareSize = props.newWidth;

            // Calculate the scaling factor based on the crop dimensions
            const hScaleFactor = originalHeight / props.cropWidth;
            const wScaleFactor = originalWidth / props.cropWidth;

            // Calculate the scaled image dimensions
            const scaledWidth = wScaleFactor > 0 ? targetSquareSize * wScaleFactor : props.newWidth;
            const scaledHeight = targetSquareSize * hScaleFactor > 0 ? targetSquareSize * hScaleFactor : props.newWidth;

            const xOffSetSF = props.cropX / originalWidth;
            const xOffset = -1 * (scaledWidth * xOffSetSF);

            const yOffSetSF = props.cropY / originalHeight;
            const yOffset = -1 * (scaledHeight * yOffSetSF);

            return { scaledWidth, scaledHeight, xOffset, yOffset };
      };


      // React.useEffect(() => {
      if(!loading && lastSetFor != props.newWidth){
            setOLoading(true);
            console.log("GOING");
            getImageDimensions(props.imageToShow).then(({ width, height }) => {
                  // Use the width and height to calculate ratios or perform other actions.
                  var originalHeight = height * (250 / width);
                  setOLoading(false);
                  setLastSetFor(props.newWidth);
                  setDimensions(calculateImageDimensionsAndOffsets(originalHeight));
            }).catch(error => {
                        console.error("Error:", error.message);
            });
                    
      }
      // }, []);



      return (
            <div
                  style={{
                        overflow: "hidden",
                        height: props.newWidth,
                        maxHeight: props.newWidth,
                        minHeight: props.newWidth,
                        width: props.newWidth,
                        maxWidth: props.newWidth
                  }}>

                  <img
                        style={{
                              position: "relative",
                              marginTop: dimensions.yOffset,
                              marginLeft: dimensions.xOffset,
                              width: dimensions.scaledWidth,
                              minWidth:props.newWidth,
                              minHeight:props.newWidth,
                              height: dimensions.scaledHeight,
                              objectFit: "contain"
                        }}
                        src={props.imageToShow}></img>
            </div>

      )


}
