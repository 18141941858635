import * as React from "react";
import "../../../../styling/zenverse/Zenverse.scss";
import OhmSoundInterface from "./OhmSoundInterface";
import { OhmSoundPreview } from "./OhmSoundPreview";
import Draggable from 'react-draggable';
import { OhmSound } from "./OhmSound";
import { getZenSounds } from "../../../../stubs/ZenverseMock";
import { useZenAudioContext } from "../../../../hooks/ZenAudioContext";

export const OhmBoard = (props: OhmSoundInterface) => {
  
  const [closed, setClosed] = React.useState(false);
  const [showTouchScreen, setShowTouchScreen] = React.useState(true);

  const zenAudioContext = useZenAudioContext();

  const zenSounds: OhmSoundInterface[] = getZenSounds();
  const zenSoundIcons = zenSounds.map((sound, index) =>
    <Draggable
      key={sound.title}
      allowAnyClick={true}
      position={{ x: 0, y: 0 }}
      onStop={(position, ref) => handleDragComplete(position, sound)}>
      <div>
        <OhmSoundPreview
          title={sound.title}
          image={sound.image}
          audio={sound.audio}
        ></OhmSoundPreview>
      </div>
    </Draggable>
  );

  const [showOhmBoard, setShowOhmBoard] = React.useState<boolean>(true);
  const soundArray: OhmSoundInterface[] = [];
  const [activeSounds, setActiveSounds] = React.useState(soundArray);
  const [activeSoundItems, setActiveSoundItems] = React.useState([]);
  const [availableSounds, setAvailableSounds] = React.useState(zenSoundIcons);
  const [playing, setPlaying] = React.useState<boolean>(false);

  const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
  const audioContextRef = React.useRef<AudioContext>();
  audioContextRef.current = audioContext;

  const addSoundToBoard = (newSound: OhmSoundInterface) => {
    activeSounds.push(newSound);
    setActiveSounds(activeSounds);

    const newItems = activeSounds.map((sound, index) =>
      <div>
        <OhmSound
          title={sound.title}
          image={sound.image}
          audio={sound.audio}
          audioContext={audioContext}
          zenAudioContext={zenAudioContext}
          closed={closed}
          close={() => setClosed(true)}
        ></OhmSound>
      </div>
    );

    setActiveSoundItems(newItems);
    //reset available sounds:
    const activeKeys = activeSounds.map((sound) => sound.title);
    const newAvailableSounds = availableSounds.filter((sound) => (!activeKeys.includes(sound.key.toString())));
    setAvailableSounds(newAvailableSounds);

  }

  React.useEffect(() => {
    return () => {
      setClosed(true);
      if (audioContextRef.current != null) {
        audioContextRef.current.close();
      }
    }
  }, [])

  const handleDragComplete = (position: any, sound: OhmSoundInterface) => {

    addSoundToBoard(sound);
    if (position.screenY > 200) {
    }
  }

  let topPAdding = props.demoPortal? "50px" : "150px";

  return (
    <div className="ohmBoard">

      <div className={props.demoPortal? "OhmSidebarHolder OhmSidebarHolderDemo" : "OhmSidebarHolder"}>
        <div className="OhmSidebar">
          {/* <FontAwesomeIcon icon={faArrowCircleLeft} onClick={()=>props.setActivePortal('main')}/> */}
          {availableSounds}
        </div>
      </div>

      <div className="ohmContainer" style = {{paddingTop:topPAdding}}>
        <React.Fragment>
          {activeSoundItems && activeSoundItems.length == 1 ?
            <React.Fragment>
              {activeSoundItems}
              {showTouchScreen?
              <h3 className="centered-text" style = {{maxWidth:"80%",margin:"auto"}}>Mobile users must touch the screen anywhere to authorise sound within your browser!</h3>
              :null}
            </React.Fragment>
            : activeSoundItems && activeSoundItems.length > 1 ?
              <React.Fragment>
                {activeSoundItems}
              </React.Fragment>
              : <React.Fragment>
                <h3 className="centered-text" style={{margin:"auto",width:"60vw", paddingTop:"115px"}}>Create your meditation backing track by dragging sounds from the blue sound bar below!</h3>
              </React.Fragment>
          }
        </React.Fragment>

      </div>

    </div>
  );
}
