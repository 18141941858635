import * as React from "react";
import "../../styling/common/index.scss";
import {
  Link
} from "react-router-dom";
import { ZenProfileTranslation } from "../../content/NavBarTranslations";

export const PractitionerNavBar = (props: {noBorder?:boolean, setPage?:any, activePage?:string, subPage?:string,setSubPage?:any}) => {
  return (
      <div className={"MyZenNavBar"}>
      <div className="MyZenProfileNavBarLink">
          <h2 className="zenK" onClick = {() => {
            props.setPage("Reel");
            }}>
            Reel
          </h2>
      </div>
      <div className="MyZenProfileNavBarLink">
        <h2 className="zenK" onClick = {() => {
          props.setPage("home");
          }}>
          Home
        </h2>
      </div>
      <div className="MyZenProfileNavBarLink">
        <h2 className="zenK" onClick = {() => props.setPage("content")}>
          Content
        </h2>
      </div>
    </div>
     
  );

}