import * as React from "react";
import "../../../styling/zenverse/Zenverse.scss";
import "../../../styling/common/index.scss";
import "../../../styling/common/zenCoreUIComponents.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // req
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CardElement, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { formatOrderPrice, generateItemBasketPreview } from "../../../service/ContentService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

export const ZenShopPaymentCheckout = (props:any) => {
  const stripe = useStripe();

  const elements = useElements();

  const [message, setMessage] = React.useState(null);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [showItems, setShowItems] = React.useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/tshirtPaymentSuccess/`+props.order.id+`/`,
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsProcessing(false);
  };



  const getOrderItemSummary = () => {
    return props.order && props.order.orderItems? 
    props.order.orderItems.map(oI => generateItemBasketPreview(oI))
    :null
  }
  return true? (//myZen.profile? (
    <div >

      <h3 className="" ><b>Confirm Order</b></h3>
      
     <h2>Total Cost: {formatOrderPrice(props.order.orderprice)}</h2>
     {showItems?
      <div className = "orderItemsCheckoutBanner">
        <div style = {{color:"black"}}>{getOrderItemSummary()}</div>
       <FontAwesomeIcon icon={faCaretUp} style = {{margin:"auto", textAlign:"center"}} onClick = {() => setShowItems(false)}/>
      </div>
      :<div className = "orderItemsCheckoutBanner"  onClick = {() => setShowItems(true)}>
        <h3>
       For {props.order.orderItems.length} Items </h3>
       <FontAwesomeIcon icon={faCaretDown} style = {{margin:"auto", textAlign:"center"}}/>
      </div>
      }
 
      <form id="payment-form" onSubmit={handleSubmit} className = "zen-payment-form">
      <PaymentElement id="payment-element" />
      <button className = "zenGlowButton navajoGlowButton" disabled={isProcessing || !stripe || !elements} id="submit">
        <span id="button-text">
          {isProcessing ? "Processing ... " : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
    <div style={{ display: "flex", flexDirection: "row", margin:"auto", width:"90vw",marginBottom:"150px"}}>
        <div className = "checkout-lock-icon"></div>safe & secure checkout<div className = "checkout-stripe-logo" onClick = {() => {window.open("https://stripe.com/gb", '_blank');}}></div>
      </div>
    </div>
  ) : null;

}
