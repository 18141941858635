import * as React from "react";
import "../../../styling/explore/explore.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { ZEN_C_ENDPOINT } from "../../../config/EnvironmentalVariables";
import { Link, useParams } from "react-router-dom";
import { useMyCommunityService } from "../../../hooks/MyCommunityContext";


export const ZenArticleTextPage = (props:any) => {
    return (
        <div className = {props.pageType=="reg"? "article-text-page zen-info-page-reg":"article-text-page zen-info-page-alt"}>
            <h1 style = {{paddingLeft:"30px",paddingRight:"30px"}}>{props.title}</h1>
            <h4 style = {{paddingLeft:"30px",paddingRight:"30px"}}>{props.description}</h4>
        </div>
    );

}

