import * as React from "react";
import "../../../styling/common/index.scss";
import "../../../styling/myzen/myzen.scss";
import { useState } from "react";
import { ZenPracticeSessionPreview } from "./ZenPracticeSessionPreview";
import { useNavigate } from "react-router-dom";
import { CreatePracticeSession } from "./CreatePracticeSession";
import ZenWeekPlannerInterface from "../interface/ZenWeekPlannerInterface";
import { PracticeSessionModify } from "./PracticeSessionModify";

export const ZenWeekPlanner = (props: { sessions: ZenWeekPlannerInterface, forceReload: any }) => {

      const [showAddSession, setShowAddSession] = useState(null);

      const [editSession, setEditSession] = useState(null);

      let navigate = useNavigate();

      return (
            <div className="my-zen-props.sessions">
                  {showAddSession ?
                        <CreatePracticeSession finish={() => { props.forceReload(); }} type={showAddSession} setShowAddSession={setShowAddSession} />
                        :
                        editSession ?
                              <PracticeSessionModify finish={() => { props.forceReload(); setEditSession(null);}} session={editSession} setShowAddSession={(show) => setEditSession(show)} />
                              :
                              <div className="my-zen-planner-sessions">
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                          <div className="task-button-alt" style={{ width: "130px" }} onClick={() => setShowAddSession('Monday')}>
                                                <h1 className="addSessionHeader zenK" >
                                                      Create Session
                                                </h1>
                                          </div>
                                          {/* <div className="zen-bot-button" style={{width:"130px"}}  onClick={() => {}}>
                                    <h1 className="addSessionHeader zenK" >
                                    ZenBot
                                    </h1>
                              </div> */}
                                    </div>
                                    {props.sessions && props.sessions.monday && props.sessions.monday.length > 0 ?
                                          <>

                                                <h1 className='planner-day-header'><span className="paintedLine"></span>Monday<span className="paintedLine"></span></h1>
                                                {props.sessions.monday.map((session, index) => <ZenPracticeSessionPreview setEditSession={(session) => setEditSession(session)} refreshBlueprint={() => props.forceReload()} editSession={() => setShowAddSession(false)} session={session} key={session.id + "psp" + index} ></ZenPracticeSessionPreview>)}
                                          </>
                                          :
                                          <h3></h3>
                                    }
                                    {props.sessions && props.sessions.tuesday && props.sessions.tuesday.length > 0 ?
                                          <>
                                                <h1 className='planner-day-header'><span className="paintedLine"></span>Tuesday<span className="paintedLine"></span></h1>
                                                {props.sessions.tuesday.map((session, index) => <ZenPracticeSessionPreview setEditSession={(session) => setEditSession(session)} refreshBlueprint={() => props.forceReload()} editSession={() => setShowAddSession(false)} session={session} key={session.id + "psp" + index}></ZenPracticeSessionPreview>)}
                                          </> : null}
                                    {props.sessions && props.sessions.wednesday && props.sessions.wednesday.length > 0 ?
                                          <>
                                                <h1 className='planner-day-header'><span className="paintedLine"></span>Wednesday<span className="paintedLine"></span></h1>
                                                {props.sessions.wednesday.map((session, index) => <ZenPracticeSessionPreview setEditSession={(session) => setEditSession(session)} refreshBlueprint={() => props.forceReload()} editSession={() => setShowAddSession(false)} session={session} key={session.id + "psp" + index}></ZenPracticeSessionPreview>)}</> : null}

                                    {props.sessions && props.sessions.thursday && props.sessions.thursday.length > 0 ?
                                          <>
                                                <h1 className='planner-day-header'><span className="paintedLine"></span>Thursday<span className="paintedLine"></span></h1>
                                                {props.sessions.thursday.map((session, index) => <ZenPracticeSessionPreview setEditSession={(session) => setEditSession(session)} refreshBlueprint={() => props.forceReload()} editSession={() => setShowAddSession(false)} session={session} key={session.id + "psp" + index}></ZenPracticeSessionPreview>)}</> : null}

                                    {props.sessions && props.sessions.friday && props.sessions.friday.length > 0 ?
                                          <>
                                                <h1 className='planner-day-header'><span className="paintedLine"></span>Friday<span className="paintedLine"></span></h1>
                                                {props.sessions.friday.map((session, index) => <ZenPracticeSessionPreview setEditSession={(session) => setEditSession(session)} refreshBlueprint={() => props.forceReload()} editSession={() => setShowAddSession(false)} session={session} key={session.id + "psp" + index}></ZenPracticeSessionPreview>)}</> : null}
                                    {props.sessions && props.sessions.saturday && props.sessions.saturday.length > 0 ?
                                          <>
                                                <h1 className='planner-day-header'><span className="paintedLine"></span>Saturday<span className="paintedLine"></span></h1>
                                                {props.sessions.saturday.map((session, index) => <ZenPracticeSessionPreview setEditSession={(session) => setEditSession(session)} refreshBlueprint={() => props.forceReload()} editSession={() => setShowAddSession(false)} session={session} key={session.id + "psp" + index}></ZenPracticeSessionPreview>)}</> : null}

                                    {props.sessions && props.sessions.sunday && props.sessions.sunday.length > 0 ?
                                          <>
                                                <h1 className='planner-day-header'><span className="paintedLine"></span>Sunday<span className="paintedLine"></span></h1>
                                                {props.sessions.sunday.map((session, index) => <ZenPracticeSessionPreview setEditSession={(session) => setEditSession(session)} refreshBlueprint={() => props.forceReload()} editSession={() => setShowAddSession(false)} session={session} key={session.id + "psp" + index}></ZenPracticeSessionPreview>)}</> : null}
                                    {/* <h1>Extras</h1>
                        {props.sessions && props.sessions.? props.sessions.sunday.map((session, index) => <ZenPracticeSessionPreview setEditSession = {(session) => setEditSession(session)} refreshBlueprint={() =>props.forceReload()}  editSession={() =>setShowAddSession(false)}session={session} key={session.id+"psp"+index}></ZenPracticeSessionPreview>):null}
                        <div className="addSessionPlus" onClick={() => setShowAddSession('Monday')}></div> */}
                              </div>
                  }
            </div>
      );
}
