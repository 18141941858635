import * as React from "react";
import "../../../styling/explore/explore.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { ZEN_C_ENDPOINT } from "../../../config/EnvironmentalVariables";
import { Link } from "react-router-dom";
import { useMyCommunityService } from "../../../hooks/MyCommunityContext";
import { ZenPicSizer } from "../../practitioner/ZenPicSizer";


export const PractitionerSliderPreview = (props: { practitioner?:any, practitionerId?: string }) => {

    const [practitionerDetails, setPractitionerDetails] = React.useState(props.practitioner? props.practitioner : null);

    
    const getPractitionerFullDetails = async (practitionerId:any) => {
        console.log("getting details for: ");
        return await axios.get(ZEN_C_ENDPOINT + `/myzen/practitioner/` + practitionerId)
              .then(res => {
                setPractitionerDetails(res.data);
              }).catch(() => {
                   return null;
              });
    }

    React.useEffect(() => {
        if(props.practitioner == null && props.practitionerId != null){
            getPractitionerFullDetails(props.practitionerId);
        }
    },[]);


    const communityService = useMyCommunityService();

    console.log(props);
    
    const practitionerPageLink = practitionerDetails && practitionerDetails.pracitionerType == "COMMUNITY"? "/community/" : "/practitioner/";
    

    return (
        <div>
        
        {practitionerDetails != null ?
            <Link to={practitionerPageLink + practitionerDetails.username}>
                    <div className={practitionerDetails && practitionerDetails.pracitionerType == "COMMUNITY"? 'practitionerSliderPreview communityPractitionerItem' :'practitionerSliderPreview'}>
                        <div className="pract-preview-image-fitter">
                        <ZenPicSizer
                                                                              imageToShow={practitionerDetails.image}
                                                                              cropX = {practitionerDetails.imageCropX}
                                                                              cropY = {practitionerDetails.imageCropY}
                                                                              cropWidth = {practitionerDetails.imageCropWidth}
                                                                              newWidth = {100}
                                                                              />
                        {/* <img className="content-preview-image practitioner-preview-image" src={practitionerDetails.image}></img> */}
                        </div>

                        <div >
                            <h4 className="practitioner-preview-title">{practitionerDetails.username}</h4>
                            {practitionerDetails && practitionerDetails.pracitionerType == "COMMUNITY"?<h4 className="communityLabel">(community)</h4>:null}
                            
                              <p className="practitioner-preview-bio">
                                {practitionerDetails && practitionerDetails.bio?
                                <>
                              {practitionerDetails.bio.substring(0,120)}{practitionerDetails.bio.length>120?"...":null}
                              </>
                                :null}
                         
                              </p>
                              {communityService.checkIfUserFollowsPractitioner(practitionerDetails.username)?
                                <div className="zenGlowButton buddGlowButton" style = {{margin:"auto", marginTop:"15px"}}>Unfollow</div>
                                    :
                                    <div className="zenGlowButton sageGlowButton" style = {{margin:"auto", marginTop:"15px"}} onClick = {(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                          communityService.followPractitioner(practitionerDetails.username);
                                    }}>Follow</div>
                              }
                        </div>
                    </div> 
            </Link>: null}
        </div>
    );

}

