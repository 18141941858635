import axios from "axios";
import * as React from "react";
import { ZEN_C_ENDPOINT } from "../../../config/EnvironmentalVariables";
import { useAuth } from "../../../hooks/auth";
import "../../../styling/common/index.scss";
import "../../../styling/myzen/myzen.scss";


export const MyZenProgress = (props: any) => {

  const [zenergyStats, setZenergyStats] = React.useState(null);
  const [getTries, setGetTreis] = React.useState(0);
  const [searchPending, setSearchPending] = React.useState(false);

  let auth = useAuth();

  const getZenergyStats = async () => {
    setSearchPending(true);
    await axios.get(ZEN_C_ENDPOINT + `/myzen/zenergy/getZenergyStats/` + auth.username)
    .then(res => {
        if (res && res.data) {
            setZenergyStats(res.data);
            setSearchPending(false);
        } else {
            setSearchPending(false);
        }
    })
    .catch(function (error) {
      setSearchPending(false);
    });

  }

  if(!searchPending && getTries <3 && zenergyStats == null){
    setGetTreis(getTries+1);
    getZenergyStats();
  }


  return (

    <div className="MyZenProgress">
      <div className="zen-target">
        <div className="zen-target-goal-title">
          ZENergy points:
        </div>
        <div className="zen-target-goal-value">
          {zenergyStats && zenergyStats.zenergyPoints? zenergyStats.zenergyPoints : <>0</>}
        </div>
      </div>
      <div className="zen-target">
        <div className="zen-target-goal-title">
          Mindful Minutes:
        </div>
        <div className="zen-target-goal-value">
          {zenergyStats && zenergyStats.totalMeditatedMinutes? zenergyStats.totalMeditatedMinutes : <>0</>}
        </div>
      </div>
      <div className="zen-target">
        <div className="zen-target-goal-title">
          Zen Streak:
        </div>
        <div className="zen-target-goal-value">
          {zenergyStats && zenergyStats.zenStreak? zenergyStats.zenStreak : <>0</>}
        </div>
      </div>


      {/* <div className="zen-goals-skill-map">
              <h4>Your Zen Skill Map</h4>
            <Stage width={320} height={320} className='skill-map-stage'>
        <Layer>
        <Shape
            sceneFunc={(context, shape) => {
              context.beginPath();
              context.moveTo(160, 80);
              context.lineTo(240, 130);
              context.lineTo(210, 220);
              context.lineTo(90, 210);
              context.lineTo(80, 140);
              context.closePath();
              // (!) Konva specific method, it is very important
              context.fillStrokeShape(shape);
            }}
            stroke="#D1603D"
            strokeWidth={4}
          />
          {/* <Shape
            sceneFunc={(context, shape) => {
              context.beginPath();
              context.moveTo(200, 50);
              context.lineTo(280, 100);
              context.lineTo(280, 160);
              context.lineTo(200, 240);
              context.lineTo(130, 185);
              context.lineTo(120, 90);
              context.closePath();
              // (!) Konva specific method, it is very important
              context.fillStrokeShape(shape);
            }}
            stroke="#D1603D"
            strokeWidth={4}
          /> 
        </Layer>
      </Stage>
            </div> */}

    </div>);
}
