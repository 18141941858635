import * as React from "react";
import '../../../styling/common/zenCoreUIComponents.scss';
import '../../../styling/common/index.scss';

export const ZENergyParagaraphText = () => {
    return (
        <React.Fragment>
            <span className="zenergy-p"><span className="zenergy-p-z">Z</span><span className="zenergy-p-e1">E</span><span className="zenergy-p-n">N</span><span className="zenergy-p-e2">e</span><span className="zenergy-p-r">r</span><span className="zenergy-p-g">g</span><span className="zenergy-p-y">y</span></span>
        </React.Fragment>
    );
}