import * as React from "react";
import '../../styling/common/index.scss';
import '../../styling/common/zenCoreUIComponents.scss';
import { ZenHome } from "./ZenHome";
import { useNavigate } from "react-router";

export const ZenUserTermsAndConditions = (props: any) => {

  let navigate = useNavigate();

  return (
    <div className = "zenTermsAndConditions" style = {{paddingLeft:"5%",paddingRight:"5%", height: "100vh", overflowY:"scroll"}}>
      <div style = {{paddingBottom:"175px"}}>
      
        <h1>Terms and Conditions of Use for Zencyclopedia Website and Mobile App</h1>
        <h2><b>Last Updated:</b> 09/07/2023</h2>
        <h4>Please read these Terms and Conditions ("Terms") carefully before using the Zencyclopedia website and mobile application (collectively referred to as "the Platform"). These Terms govern your access to and use of the Platform provided by Zencyclopedia ("we," "us," or "our"). By accessing or using the Platform, you agree to be bound by these Terms. If you do not agree with any part of these Terms, please do not access or use the Platform.</h4>
        <h3 style = {{color: "#9e2e0b"}}>By accessing or using the Platform, you confirm that you are at least 18 years old and have the legal capacity to enter into these Terms. If you are accessing or using the Platform on behalf of a company or organization, you represent that you have the authority to bind that entity to these Terms.<br></br>
</h3>
<p  style = {{textAlign:"justify"}}>
<b>Disclaimer: Yoga, Exercises, and Breathing Techniques</b><br></br><br></br>
The information provided on the Zencyclopedia Platform regarding yoga, exercises, and breathing techniques is for general informational purposes only. Before participating in any physical activity or exercise program, including yoga or breathing techniques, it is important to consult with a qualified healthcare professional or certified instructor.<br></br>
</p>
<ul style = {{listStyle:"none", textAlign:"justify"}}>
  <li><b>Physical Limitations: </b>The practice of yoga, exercises, and breathing techniques involves physical movements and exertion. It is  essential to be aware of your physical limitations and any medical conditions or injuries you may have. Certain poses, exercises, or breathing techniques may not be suitable for everyone. Always listen to your body and modify or skip any activities that cause discomfort or pain.</li><br></br>
  <li><b>Professional Guidance: </b>We strongly recommend seeking guidance from a certified yoga instructor, fitness professional, or healthcare provider before attempting any new exercises, poses, or breathing techniques. They can provide personalized guidance, assess your abilities and limitations, and suggest appropriate modifications or alternatives to suit your individual needs.</li><br></br>
  <li><b>Personal Responsibility: </b>Engaging in yoga, exercises, or breathing techniques is done at your own risk. Zencyclopedia and its affiliates shall not be held responsible for any injuries, accidents, or health complications that may result from your participation in these activities. By using the Platform, you acknowledge and accept the inherent risks involved and agree to assume full responsibility for your actions.</li><br></br>
  <li><b>Individual Differences:</b>Each individual's physical condition, flexibility, and level of fitness may vary. It is important to progress at a pace that is suitable for your own abilities and gradually increase intensity or difficulty as your body allows. Do not compare your progress or abilities to others and avoid pushing yourself beyond your limits.</li><br></br>
  <li><b>Medical Considerations: </b>If you have any underlying health conditions, are pregnant, have recently undergone surgery, or have any concerns about your physical well-being, it is crucial to consult with a healthcare professional before engaging in yoga, exercises, or breathing techniques. They can provide you with tailored advice and recommendations based on your specific situation.</li><br></br>
  <li><b>Listen to Your Body: </b>Pay close attention to your body's signals and respect its limitations. Discontinue any activity that causes pain, dizziness, or discomfort. If you experience any unusual symptoms or medical concerns during or after practicing yoga, exercises, or breathing techniques, seek medical attention promptly.</li>
</ul>

<p style = {{textAlign:"justify"}}>
By accessing and using the Zencyclopedia Platform, you agree to release, indemnify, and hold harmless Zencyclopedia and its affiliates from any claims, liabilities, or damages arising out of or in connection with your use of the information or practices related to yoga, exercises, or breathing techniques found on the Platform.<br></br><br></br>
<b>License to Use</b><br></br>
Subject to these Terms, we grant you a limited, non-exclusive, non-transferable, and revocable license to access and use the Platform for personal, non-commercial purposes. This license does not permit you to modify, distribute, sell, or exploit any content or features of the Platform without our express written consent.<br></br>
<br></br>
<b>User Accounts</b><br></br>
To access certain features of the Platform, you may be required to create a user account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to provide accurate, complete, and up-to-date information during the registration process. You must notify us immediately of any unauthorized use of your account or any other breach of security. We reserve the right to suspend or terminate your account if we suspect any unauthorized activity.<br></br>
<br></br>
<b>User Content</b><br></br>
The Platform allows users to submit, post, or contribute content, including but not limited to articles, comments, and reviews ("User Content"). By submitting User Content, you grant us a worldwide, non-exclusive, royalty-free, perpetual, irrevocable, and sublicensable right to use, reproduce, modify, adapt, publish, translate, distribute, and display the User Content in any media. You represent and warrant that you own or have the necessary licenses, rights, consents, and permissions to submit User Content.<br></br>
<br></br>
<b>Prohibited Conduct</b><br></br>
When using the Platform, you agree not to:<br></br>
</p>
<ul style = {{listStyle:"none", textAlign:"justify"}}>
  <li>a) Violate any applicable laws, regulations, or third-party rights.</li>
  <li>b) Use the Platform in any manner that could disable, damage, or impair its functionality or interfere with other users' access to the Platform.</li>
  <li>c) Engage in any form of data mining, scraping, or harvesting.</li>
  <li>d) Impersonate any person or entity or misrepresent your affiliation with any person or entity.</li>
  <li>e) Transmit any viruses, malware, or other malicious code.</li>
  <li>f) Collect or store personal information of other users without their consent.</li>
  <li>g) Use the Platform for any illegal, harmful, or fraudulent purposes.</li>
</ul>
<p style = {{textAlign:"justify"}}>
<b>Intellectual Property</b><br></br>
The Platform and its content, including but not limited to text, graphics, logos, images, and software, are owned by or licensed to Zencyclopedia and are protected by intellectual property laws. You agree not to copy, distribute, modify, or create derivative works of the Platform or its content without our prior written consent.<br></br>
</p>

      <div style={{ margin: "auto", textAlign: "center" , marginBottom:"75px"}} className="zenGlowButton sageGlowButton" onClick={() => {
        if(props.confirmSignUp != null){
          props.confirmSignUp()
        } else {
          navigate("/home");
        }
      }}>I agree</div>
    </div>
    </div>
  );
}