

import * as React from "react";
import { ProvideStreamContext } from "../../../hooks/StreamContext";
import '../../../styling/common/zenCoreUIComponents.scss';
import { ZenStream } from "../../home/ZenStream";
import PrivateRoute from "../PrivateRoute";

export const StreamWalkthrough = () => {
    const [page, setPage] = React.useState(1);

    return (
        <PrivateRoute>
        <React.Fragment>
            {page == 1 ?
                <div className="ming-page">
                    <div className="ming-page-text" style = {{marginTop:"35px"}}>
                    <h4>Welcome to the Zen stream, here you can find a feed of uplifting, inspiring and feel good content at your fingertips. </h4>

                    <h4>Come here whenever you need inspiration on your next practice, and to discover new content across the site. </h4>

                        <div className='finish-session-icon' onClick={() => setPage(2)} style = {{marginTop:"25px"}}></div>
                    </div>
                </div>
                :<ProvideStreamContext> <ZenStream /></ProvideStreamContext>
            }
        </React.Fragment>
        </PrivateRoute>
    );
}