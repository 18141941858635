import * as React from "react";
import "../../../styling/common/index.scss";
import "../../../styling/myzen/myzen.scss";
import ZenWeekGoalsInterface from "../interface/ZenWeekGoalsInterface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faYinYang } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
import { useZenCardsService } from "../../../hooks/ZenCardsContext";

export const MyZenTargets = (props: { weekGoals: ZenWeekGoalsInterface, userSkillGoals: any, setShowSetTraits: () => void }) => {


  const mindfulMinutesTarget = {
    title: "Mindful Minutes (per week)",
    value: props.weekGoals.meditationTime
  };

  const sessionsPerWeekTarget = {
    title: "Meditation Sessions (per week)",
    value: props.weekGoals.meditationSessionNumber
  };

  const targets = [mindfulMinutesTarget, sessionsPerWeekTarget];



  return (
    <div className="my-zen-targets">

      {targets.map(target =>
        <div className="zen-target">
          <div className="zen-target-goal-title">
            {target.title}
          </div>
          <div className="zen-target-goal-value">
            {target.value}
          </div>
        </div>
      )}
      
      <Link to="/zenQuiz">
        <div className='zenGlowButton mingGlowButton' onClick={() => props.setShowSetTraits()} style={{ marginTop: "15px" }}>
          Take a Zen Skills Quiz
        </div>
      </Link>

      {/* <div className = "zen-button white-button bottom-b-button" style={{fontSize:'80%',fontWeight:'600', opacity:'0.4'}}>
          Create A Custom Zen Target
        </div> */}
      {props.userSkillGoals && props.userSkillGoals.length > 0 ?
        <ul className='my-zen-skills-list'>
          <h4 className="my-zen-skills-list-title">My Zen Skills</h4>
          {props.userSkillGoals.map(skill => {
            return <li className='my-zen-skill-list-item'>
              <FontAwesomeIcon className='my-zen-skill-list-item-icon' icon={faYinYang} /> <span className="my-zen-skill-list-item-title">{skill.skillTitle}</span></li>
          })}
          <div className='task-button-alt' style={{ fontSize: '80%', padding: '8px', marginTop: '15px' }} onClick={() => props.setShowSetTraits()}>
            Edit
          </div>
        </ul>
        :
        <div className='zenGlowButton mingGlowButton' onClick={() => props.setShowSetTraits()} style={{ marginTop: "15px" }}>
          Set Your Zen Traits
        </div>
      }
    </div>
  );
}
