import React, { useState } from 'react';
import "../../../styling/vacana/vacana.scss";
import "../../../styling/common/index.scss";
import "../../../styling/stream/stream.scss";
import "../../../styling/zenverse/Zenverse.scss";
import ReactPlayer from 'react-player'
import { useParams } from 'react-router';
import axios from "axios";
import useZenVideoPlayer from '../../media/useZenVideoPlayer';
import { ZenPracticePortal } from '../ZenPracticePortal';
import { ZEN_C_ENDPOINT } from '../../../config/EnvironmentalVariables';
import PracticeInterface from '../../zencontent/practices/PracticeInterface';
import PrivateRoute from '../../common/PrivateRoute';

export const ZenMeditationAudioPlayer = (props: { meditationId: string, practicePlaying: any, setFinished: any, setPracticePlaying: any, practiceVolume: any }) => {

  const mediaURL = React.useRef<string>();

  const [soundBuffer, setSoundBuffer] = useState(null);
  const [soundLoading, setLoading] = useState(null);
  const [soundSource, setSoundSource] = useState(null);

  const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
  const audioContextRef = React.useRef<AudioContext>();
  audioContextRef.current = audioContext;
  const soundSourceRef = React.useRef<AudioBufferSourceNode>();

  // const streamAudio = () => {
  //   console.log("getting audio");
  //   let soundRequest = new XMLHttpRequest();
  //   soundRequest.open('GET', ZEN_C_ENDPOINT + `/zen/meditations/streamMedia/` + props.meditationId, true);
  //   soundRequest.responseType = 'arraybuffer';
  //   soundRequest.addEventListener('load', () => {

  //     audioContext.decodeAudioData(soundRequest.response).then((buffer) => {
  //       const uint8Array = new Uint8Array(buffer);

  //       let binary = "";
  //       uint8Array.forEach((byte) => {
  //         binary += String.fromCharCode(byte);
  //       });

  //       const base64Data = btoa(binary);
  //       mediaURL.current = `data:video/mp4;base64,${base64Data}`;
  //       //mediaURL.current = `data:audio/mpeg;base64,${base64Data}`;
  //       //let source = audioContext.createBufferSource();

  //               // source.buffer = buffer;
  //               // source.loop = true;
  //               // source.connect(audioContext.destination);
  //               // soundSourceRef.current = source;
  //               // setSoundBuffer(buffer);
  //               // setSoundSource(source);
  //     }); 
  //   });
  //   soundRequest.send();
  // }

  // React.useEffect(() => {
  //   streamAudio();
  // }, []);

  
  console.log("Rendered Zen AWS Audio Player");

  const playAudio = () => {

  }

  const pauseAudio = () => {

  }

  return (
    <React.Fragment>

      <div className='practice-video-holder'>
        <ReactPlayer
          controls={true}
          onEnded={() => props.setFinished(true)}
          playing={props.practicePlaying}
          loop={true}
          url={ZEN_C_ENDPOINT + `/zen/meditations/streamMedia/` + props.meditationId}
          width='100%'
          // volume={props.practiceVolume}
          onPause={() => props.setPracticePlaying(false)}
          setPlaying={(playing) => props.setPracticePlaying(playing)} />
      </div>
    </React.Fragment>
  );
}