import * as React from "react";
import "../../../styling/explore/explore.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router";
import ZenCardInterface from "./ZenCardInterface";
import { useMyZenService } from "../../../hooks/ZenUserContext";
import { getSubscriptionAllowanceMessage } from "../../../service/ZenSubscriptionsService";
import { useZenQuotasService } from "../../../hooks/ZenQuotasContext";


export interface ZenCardPreviewInterface extends ZenCardInterface {
    setConfirmIcon?: () => void,
    userOwnsIcon?: boolean
    newCard?: boolean;
    sliderCard?:boolean;
}

export const ZenCardPreview = (props: ZenCardPreviewInterface) => {

    let navigate = useNavigate();
    let myZen = useMyZenService();
    let quotasContext = useZenQuotasService();


    // Mock function for which sub they are in. 
    let zenBasicSubscriptionTierIncludedIconKeys = ["PonderingPear"];

    let holderClassName = props.newCard? "zenCardPreview newZenCard" : "zenCardPreview";

    if(props.cardType == "POWER"){
        holderClassName = holderClassName + " zenPowerCard";
    } else if(props.cardType == "MILESTONE"){
        holderClassName = holderClassName + " zenMilestoneCard";
    } else if (props.cardType == "SUBSCRIPTION"){
        holderClassName = holderClassName + " zenSubscriptionCard";
        if(myZen && myZen.profile && myZen.profile.subscriptionTier == props.cardKey){
            holderClassName = holderClassName + " activeSubscriptionCard"
        }
    }

    if(!props.sliderCard){
        holderClassName = holderClassName + " fullMing"
    }


    const handleCardClick = () => {
        if(props.cardType = "SUBSCRIPTION"){
            //Navigate to Zen Subscription Browser, which shows in more detail the content unlocks attached to each card. 
        }
    }

    const isActiveSubscriptionCard = quotasContext && quotasContext.zenQuotasResults&& props.cardKey.includes(quotasContext.zenQuotasResults.subscriptionLevel);

    if(isActiveSubscriptionCard){
        holderClassName = holderClassName + "ActiveSubscriptionCard";
    }
    
    return (
        <div className={holderClassName}  onClick={props.cardType == "POWER"? () => props.setConfirmIcon(): props.cardType == "SUBSCRIPTION"? () => navigate("/explore/subscriptions") : () => {}}>
            {!props.userOwnsIcon && !props.newCard && props.cardKey!="FREE_SUBSCRIBER"? 
                <div className="zenCardPreviewLockHolder">
                    <img className="zenCardPreviewPhoto" src={props.image} ></img>
                    <div className="zenCardPreviewLock">
                        {props.zenergyCost>0?<div style={{ position: "relative", paddingTop: "65px" }}>{props.zenergyCost}</div>:null}
                    </div>
                </div>
                :
                <img className="zenCardPreviewPhoto" src={props.image} ></img>}


            <h2 className="zenCardPreviewTitle centered-text">{props.title}</h2>
            {/* Powers  */}
            <div className="zen-icon-preview-description">
                {/* <h4 className="centered-text zen-icon-prv-powers-header"><b>Powers:</b></h4> */}
                {props.cardType == "SUBSCRIPTION"?
                <React.Fragment>{getSubscriptionAllowanceMessage(props.title)}</React.Fragment>
                :<React.Fragment>{props.description}</React.Fragment>}
            </div>

            {/* Powers  */}
            <div className="zen-icon-preview-powers">
                {/* <h4 className="centered-text zen-icon-prv-powers-header"><b>Powers:</b></h4> */}
                {props.power}
            </div>

            
            {/*  COST or unlock:  */}

            {zenBasicSubscriptionTierIncludedIconKeys.includes(props.cardKey) ?
                <div style={{ display: "flex", flexDirection: "row", margin: "auto" }}>
                    <div style={{ margin: "auto" }} className="basic-tier-tag">basic tier</div>
                </div>
                : null
            }

            {/* Subscription Plan inclusions. */}
        </div>
    );

}

