import * as React from "react";
import { useState } from "react";
import { useZenverseService } from "../../../hooks/ZenverseContext";
import "../../../styling/zenverse/Zenverse.scss";
import ZenContentInterface from "../../zencontent/practices/ZenContentInterface";
import ZenscapeInterface from "../interfaces/ZenscapeInterface";


// interface PortalPreviewInterface extends ZenscapeInterface {
//   loadZenscapeJSX?:any
// }

export const PortalPreview = (props: ZenscapeInterface) => {

  const [seeMore, setSeeMore] = useState(false);

  const zenverse = useZenverseService();

  var isFavourite = zenverse && zenverse.favourites && zenverse.favourites.length >0?  zenverse.favourites.map((portal) => portal.id).includes(props.id) : false;
  var userHasPortal = zenverse && zenverse.myPortals && zenverse.myPortals.length >0?   zenverse.myPortals.map((portal) => portal.id).includes(props.id): false;

  return (
    <div className="zenscape-preview" >
    <h1 className="zenscape-preview-title okashi">{props && props.details ? props.details.title : null}</h1>
    <img className="portal-preview-image" src={props && props.details && props.details.image? props.details.image : null} onClick={() => props.loadZenscapeJSX(props)}></img>

    <div className="zenscape-preview-overview">
      <h2 className="zenscape-preview-desc zenK">{props && props.details ? props.details.description.trim() : null}</h2>
      <h3 className="zenscape-preview-desc zenK">{props && props.additionalText ? props.additionalText : null}</h3>
    </div>
  </div>
  );
}