import * as React from "react";
import "../../../styling/common/index.scss";
import { useState } from "react";
import axios from "axios";
import { useAuth } from "../../../hooks/auth";
import { ZEN_C_ENDPOINT } from "../../../config/EnvironmentalVariables";

export const ZenPlannerGenerator = (props: { weekGoals: any, getPlannerSessions: any }) => {

      const [prefferedDays, setPrefferedDays] = useState([]);

      const auth = useAuth();

      const generatePlanner = async () => {
            
            if (props.weekGoals.meditationSessionNumber - prefferedDays.length > 2) {
                  // Warn user they may not have chosen enough days
            }

            axios.post(ZEN_C_ENDPOINT + `/myzen/zenPlanner/generate`, {
                  username: auth.username,
                  meditationTime: props.weekGoals.meditationTime,
                  prefferedDays: prefferedDays,
                  meditationSessionNumber: props.weekGoals.meditationSessionNumber,
                  hobbyNumber: 0,
                  relaxNumber: 0,
                  mindfulMovementNumber: 0
            }).then(function (response) {
                  props.getPlannerSessions();
            }).catch(function (error) {
                  console.log(error);
            });
      }


      const dow = ["Monday", "Tuesday", "Wednesday", "Thursday", 'Friday', "Saturday", "Sunday"];

      return (

            <React.Fragment>
                  <div className="ming-page">
                        <div className="ming-page-text">
                              <div style = {{marginTop:"175px"}} >
                              <h4 className = "goldText"><span className='prompt-qouted'><i>“We are what we repeatedly do. Excellence, then, is not an act, but a habit”</i></span><span className='prompt-qouted-auth'> - Aristotle </span></h4>
                              <h4><br />Let’s create a mindfulness schedule to add some structure to your journey. </h4>

                              <div style={{ marginTop: "10px" }} className = "navColored">
                                    <h4>Your Weekly Zen Targets:</h4>
                                    <h4 style={{ fontWeight: "800" }}>{props.weekGoals ? props.weekGoals.meditationTime : 0} minutes meditation per week</h4>
                                    <h4 style={{ fontWeight: "800" }}>{props.weekGoals ? props.weekGoals.meditationSessionNumber : 0} sessions per week</h4>
                              </div>

                              <div style={{ marginTop: "10px" }}>
                                    <h3>Which days would you like to schedule practice for?</h3>
                                    <div style={{ width: "330px", display: "flex", flexDirection: "row", flexWrap: "wrap", margin: "auto", marginTop: "10px" }}>
                                          {dow.map((day) => {
                                                return prefferedDays.includes(day) ? (
                                                      <div className='days-option-gen zenGlowButton navajoGlowButton chosenZenGoal' onClick={() => setPrefferedDays(prefferedDays.filter(prefferedD => prefferedD != day))}>
                                                            {day}
                                                      </div>)
                                                      :
                                                      (<div className='days-option-gen zenGlowButton navajoGlowButton'
                                                            onClick={() => {
                                                                  prefferedDays.push(day);
                                                                  var newList = prefferedDays.splice(0, prefferedDays.length);
                                                                  setPrefferedDays(newList);
                                                            }}
                                                      >
                                                            {day}
                                                      </div>)

                                          })}

                                    </div>
                                    <div className='zenGlowButton mingGlowButton' onClick={() => generatePlanner()} style={{ marginTop: "15px", marginBottom: "250px" }}>
                                          Generate
                                    </div>
                              </div>

                              </div>

                        </div>
                  </div>
            </React.Fragment>

      );

}
