import * as React from "react";
import "../../../../styling/zenverse/Zenverse.scss";
import OhmSoundInterface from "./OhmSoundInterface";

export const OhmSoundPreview = (props : OhmSoundInterface) => {

    return (
      <div className="zen-sound-icon" >
        <img className = "zen-sound-icon-image" src={props.image} draggable={false}></img>
      </div>
    );
}
