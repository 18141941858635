import * as React from "react";
import "../../../styling/common/index.scss";
import "../../../styling/explore/explore.scss";
import { useState } from "react";
import axios from "axios";
import { useAuth } from "../../../hooks/auth";
import { ZEN_C_ENDPOINT } from "../../../config/EnvironmentalVariables";
import { ZenImageBrowser } from "./ZenImageBrowser";
import { MeditationIcons } from "../../zencontent/practices/MeditationIcons";
import ImageDrop from "./ImageDrop";
import BrowseLocalFilesButton from "./BrowseLocalFilesButton";
import { BarLoader } from "react-spinners";
import { useZenSearchService } from "../../../hooks/ZenSearchContext";
import { getContentListPreviews } from "../../../service/ContentService";
import ZenContentInterface from "../../zencontent/practices/ZenContentInterface";
import { PracticePreview } from "../../zencontent/practices/previews/PracticePreview";
import { ZenNugget } from "../../zencontent/nuggets/ZenNugget";
import { PathPreview } from "../../zencontent/courses/PathPreview";
import { ZenLoader } from "../../common/ZenLoader";

export const PathContentFinder = (props: any) => {

      const useSearch = useZenSearchService();
      const [activeContentReccs, setActiveContent] = useState(null);
      const [pageNumber, setPageNumber] = useState(1);
      const [sessionToConfirm, setSessionToConfirm] = useState(null);

      const generateChooseItemPreview = (item: ZenContentInterface) => {
                  if (item.contentType == 'MEDITATION') {
                    return <PracticePreview {...item} unclickable = {true} selectedItem = {sessionToConfirm && sessionToConfirm.id == item.id}/> ;
                  }
      }

      const setStartReccomendations = async () => {
            let result = await axios.get(ZEN_C_ENDPOINT + `/zen/explore/practitioner/` + props.userId + `/` + pageNumber)
                  .then(res => {
                        return res.data;
                  }).catch(() => {
                        return [];
                  });
            setActiveContent(result);
      }

      React.useEffect(() => {
            setStartReccomendations();
      }, []);

      return (
            <div>
                  <h3 style = {{margin:"auto", textAlign:"center", marginBottom:"15px"}}>Find content to link to this course:</h3>
                  {/* Content type filter row. */}
                  {/* search bar for active results */}
                  {activeContentReccs && activeContentReccs.length > 0 ?
                        <div>
                              {activeContentReccs.filter(contentItem => contentItem !=null && contentItem.id != null).map(cr => 
                              <div onClick={() => setSessionToConfirm(cr)} >
                                    {generateChooseItemPreview(cr)}
                              </div>)}
                        </div>
                        : activeContentReccs ?
                              <React.Fragment>
                                    {activeContentReccs}
                              </React.Fragment>
                              : <ZenLoader/>}
                              
                  {sessionToConfirm != null?
                  <div className = "confirmChooseSessionBanner" style = {{display:"block", height:"160px"}}>
                        <div className = "zenGlowButton sageGlowButton" onClick={() => props.addSessionToPath(sessionToConfirm)}>Choose Session
                        </div>
                        <h4 style = {{textAlign:"center"}}>Title: {sessionToConfirm.title}</h4>
                  </div>
                  :null}
            </div>
      )
}