import React, { useState } from 'react';
import "../../styling/common/index.scss";
import { PuffLoader } from 'react-spinners';

export const ZenLoader = () => {

    return (
        <div className="zencLoader">
            <PuffLoader color="#033f63" size={300}>
            </PuffLoader>
        </div>
    );
}