import * as React from "react";
import "../../styling/common/index.scss";
import "../../styling/common/zenCoreUIComponents.scss";
import "../../styling/myzen/myzen.scss";
import { ZenNavBar } from "../common/navbar/ZenNavBar";
import { useState } from "react";
import { useZenExploreService } from "../../hooks/ZenExploreContext";
import axios from "axios";
import { ZEN_C_ENDPOINT } from "../../config/EnvironmentalVariables";
import { useAuth } from "../../hooks/auth";
import { useNavigate, useParams } from "react-router";
import { PuffLoader } from "react-spinners";
import { getContentCatagories } from "../../service/ZencyclopaediaService";
import { ZenPopUpMessage } from "../common/ZenPopUpMessage";
import { ZenSearchFilterTagsDisplay } from "./search/ZenSearchFilterTagsDisplay";
import { ZenSearchBar } from "./search/ZenSearchBar";
import { useZenSearchService } from "../../hooks/ZenSearchContext";
import Slider from "react-slick";
import { ExploreSliderPreview } from "./previews/ExploreSliderPreview";
import { ArticlesCarousel } from "./ArticlesCarousel";
import { ArticlePreview } from "./articles/ArticlePreview";
import { ZenNugget } from "../zencontent/nuggets/ZenNugget";

export const ExploreArticles = () => {

      const { searchId } = useParams();
      const [hideBackArrow, setHideBackArrow] = useState(false);
      const [searchKey, setSearchKey] = useState(searchId);
      const [searchPending, setSearchPending] = useState(false);
      const [retrievePending, setRetrievePending] = useState(false);
      const [results, setResults] = useState(null);
      const [resultsType, setResultsType] = useState(null);
      const [focusedItem, setFocusedItem] = useState("Active");
      const [page, setPage] = useState("Explore");

      let auth = useAuth();

      const useSearch = useZenSearchService();
      let navigate = useNavigate();

      const generateArticlesCarousel = () => {
            return <ArticlesCarousel></ArticlesCarousel>;
      }



      const retreiveResults = async () => {
            let result = await useSearch.filterArticlesByTag(searchId, []);
            setResults(result);
            setResultsType(searchId);
            setRetrievePending(false);
      }

      if (searchId && resultsType != searchId && useSearch.searchType != searchId + "-FAIL" && !useSearch.searchPending && !retrievePending && searchId.toLowerCase() != "articles") {
            setRetrievePending(true);
            retreiveResults();
      }

      else if (searchId == null && resultsType != "articles" && !searchPending && !retrievePending) {
            console.log("Loadig Articles Menu.");
            setResultsType("articles");
            setResults(generateArticlesCarousel());
      }
      else {
            console.log("nothing to see there: " + searchId);
      }


      const updateSearch = async (searchText: string, tags: string[]) => {
            setRetrievePending(true);
            let result = await useSearch.filterArticlesByTag(searchId, []);
            setResults([]);
            setRetrievePending(false);
      }

      return (
            <React.Fragment>
                  <ZenNavBar activeItem="MyZen" primaryColor="#D1603D"></ZenNavBar>
                  <div className="my-articles-page">

                        {searchId && !hideBackArrow || (searchKey && searchKey.length > 2) ? <div className='explore-back-icon' onClick={() => navigate(-1)}></div> : null}

                        <>
                                    <ZenSearchBar
                                          handleSearchBarActive={() => setHideBackArrow(true)}
                                          updateSearch={(search, tags) => updateSearch(search, tags)}
                                          searchKey={searchId}
                                    />
                                    <div className="zencyclopaedia-results">
                                          {retrievePending ?
                                                <div className="zencLoader">
                                                      <PuffLoader color="#033f63" size={300}>
                                                      </PuffLoader>
                                                </div>
                                                : results ?
                                                      <>

                                                            {results}
                                                      </>
                                                      :
                                                      <div className="zencLoader">
                                                            <h1>NO RESULTS</h1>
                                                      </div>
                                          }
                                    </div></>
                        
                  </div>
            </React.Fragment>
      );
}
