import * as React from "react";
import { MyZenNavBar } from "../common/navbar/MyZenNavBar";
import "../../styling/common/index.scss";
import { ZenNavBar } from "../common/navbar/ZenNavBar";
import { useState } from "react";
import axios from "axios";
import { useAuth } from "../../hooks/auth";
import PrivateRoute from "../common/PrivateRoute";
import { Link, useParams } from "react-router-dom";
import { ZEN_C_ENDPOINT } from "../../config/EnvironmentalVariables";
import PractitionerInterface from "./PractitionerInterface";
import ZenContentInterface from "../zencontent/practices/ZenContentInterface";
import { getContentListPreviews } from "../../service/ContentService";
import { ZenLoader } from "../common/ZenLoader";
import { PractitionerContent } from "./PractitionerContent";
import { BarLoader } from "react-spinners";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { ReadingListStudio } from "./readinglist/ReadingListStudio";
import Button from '@mui/material/Button';

export const PractitionerHome = (props: { username?: string, practitionerInfo?: any, subPage?: string, setSubPage?: any, setPage?: any, getPractitionerInfo?: any }) => {

      const [activeItem, setActiveItem] = useState("practitioner-bio");
      const [showFullBio, setShowFullBio] = useState(null);
      const [newBio, setNewBio] = useState(props.practitionerInfo != null ? props.practitionerInfo.bio : null);
      const [activeLinks, setActiveLinks] = useState(props.practitionerInfo && props.practitionerInfo.links && props.practitionerInfo.links.split('|').length > 0 ? props.practitionerInfo.links.split('|') : []);
      const [practitionerContent, setPractitionerContent] = useState(null as ZenContentInterface[]);
      const [loadingStatus, setLoadingStatus] = React.useState<string>(null);
      const [pendingLink, setPendingLink] = useState(null);


      const chooseBio = () => {
            setLoadingStatus("loading");
            updateBio();
      }
      const updateBio = () => {
            axios.post(ZEN_C_ENDPOINT + `/myzen/practitioner/update`, {
                  username: props.username,
                  bio: newBio
            }).then(function (response) {
                  props.getPractitionerInfo();
                  props.setPage("Practitioner");
                  setActiveItem("practitioner-bio");
                  setLoadingStatus(null);
            }).catch(function (error) {
                  setLoadingStatus("error");
                  console.log(error);
            });

      }

      const updateLinks = () => {
            // add link checks.
            axios.post(ZEN_C_ENDPOINT + `/myzen/practitioner/update`, {
                  username: props.username,
                  links: activeLinks.join('|')
            }).then(function (response) {
                  props.getPractitionerInfo();
                  props.setPage("Practitioner");
                  setActiveItem("practitioner-bio");
                  setLoadingStatus(null);
            }).catch(function (error) {
                  setLoadingStatus("error");
                  console.log(error);
            });

      }

      const deleteLink = (linkToDelete) => {
            let newLinkList = [];
            activeLinks.filter(link => linkToDelete != link).map(l => newLinkList.push(l));
            setActiveLinks(newLinkList);
      }

      const addLink = (newLink) => {
            if (newLink != null && newLink.length > 2) {
                  let newLinkList = [];
                  activeLinks.map(l => newLinkList.push(l));
                  newLinkList.push(newLink);
                  setPendingLink("");
                  setActiveLinks(newLinkList);
            }
      }

      return (
            <div className="practitionerHomeContainer" style={{ overflowY: "scroll", maxHeight: "80vh" }}>
                  <div style={{ paddingBottom: "300px" }}>


                        {props.subPage == "practitioner-content" ?
                              <PractitionerContent
                                    practitionerInfo={props.practitionerInfo}
                              />
                              : activeItem == "updateReadingList" ?
                                    <ReadingListStudio userId={props.username} closePage={() => {
                                          props.setPage("Practitioner");
                                          setActiveItem("practitioner-bio");
                                    }} />
                                    : activeItem == "ChangeBio" ?
                                          <React.Fragment>
                                                {loadingStatus == "loading" ?
                                                      <div>
                                                            <h3 style={{ margin: "auto", textAlign: "center" }}>Uploading Bio...</h3>

                                                            <BarLoader color="#033f63">
                                                            </BarLoader>
                                                      </div>
                                                      : loadingStatus == "error" ?
                                                            <div>
                                                                  <h3 style={{ margin: "auto", textAlign: "center" }}>Oh no! Something went wrong...</h3>
                                                                  <h4 style={{ margin: "auto", textAlign: "center" }}>Please message <a href="mailto:contact@zencyclopedia.co.uk"><b>contact@zencyclopedia.co.uk </b> </a> and we'll get this sorted for you!</h4>

                                                            </div>
                                                            :
                                                            <div>

                                                                  <h3 style={{ margin: "auto", textAlign: "center", marginBottom: "15px" }}>Add a new Bio:</h3>
                                                                  <textarea
                                                                        placeholder="Enter Bio..."
                                                                        value={newBio}
                                                                        onChange={(e) => setNewBio(e.target.value)}
                                                                        className={'practice-text-input'}
                                                                  />

                                                                  <div style={{ display: "flex", flexDirection: "row", width: "250px", margin: "auto", marginTop: "15px" }}>
                                                                        <div className="zenGlowButton buddGlowButton" onClick={() => setActiveItem("practitioner-bio")}>Cancel</div>
                                                                        <div className="zenGlowButton mingGlowButton" onClick={() => chooseBio()}>Confirm</div>
                                                                  </div>
                                                            </div>}
                                          </React.Fragment>
                                          : activeItem == "AddProfileLink" ?
                                                <React.Fragment>
                                                      {loadingStatus == "loading" ?
                                                            <div>
                                                                  <h3 style={{ margin: "auto", textAlign: "center" }}>Uploading Links...</h3>

                                                                  <BarLoader color="#033f63">
                                                                  </BarLoader>
                                                            </div>
                                                            : loadingStatus == "error" ?
                                                                  <div>
                                                                        <h3 style={{ margin: "auto", textAlign: "center" }}>Oh no! Something went wrong...</h3>
                                                                        <h4 style={{ margin: "auto", textAlign: "center" }}>Please message <a href="mailto:contact@zencyclopedia.co.uk"><b>contact@zencyclopedia.co.uk </b> </a> and we'll get this sorted for you!</h4>

                                                                  </div>
                                                                  :
                                                                  <div>

                                                                        <h3 style={{ margin: "auto", textAlign: "center", marginBottom: "15px" }}>Edit Profile External Links:</h3>
                                                                        {activeLinks && activeLinks.length > 0 ?
                                                                              <React.Fragment>

                                                                                    {activeLinks.map(l => <div
                                                                                          className="zenPractitionerBlogLink">
                                                                                          <FontAwesomeIcon icon={faLink} />
                                                                                          {l}
                                                                                          <div className="deleteLink" onClick={() => deleteLink(l)}></div>
                                                                                    </div>)}
                                                                              </React.Fragment> : null}
                                                                        {activeLinks.length < 4 ?
                                                                              <div className="add-link-banner">
                                                                                    <div style={{ width: "200px", margin: "auto" }}>
                                                                                          <input
                                                                                                type="text"
                                                                                                placeholder={"enter link"}
                                                                                                value={pendingLink}
                                                                                                onChange={(e) => setPendingLink(e.target.value)}
                                                                                                className={'add-link-input'}
                                                                                          />
                                                                                    </div>
                                                                                    <button className="addContentPlus" onClick={() => addLink(pendingLink)}></button>
                                                                              </div>
                                                                              : null}

                                                                        <div style={{ display: "flex", flexDirection: "row", width: "250px", margin: "auto", marginTop: "15px" }}>
                                                                              <div className="zenGlowButton buddGlowButton" onClick={() => setActiveItem("practitioner-bio")}>Cancel</div>
                                                                              <div className="zenGlowButton mingGlowButton" onClick={() => updateLinks()}>Confirm</div>
                                                                        </div>
                                                                  </div>}
                                                </React.Fragment>
                                                : <React.Fragment>
                                                      <div style={{ display: "flex", flexDirection: "row" }}>
                                                            <h1 className={activeItem == 'practitioner-bio' ? "subPageNavHeader subPageActiveNavHeader" : "subPageNavHeader"} onClick={() => {
                                                                  props.setSubPage('practitioner-bio');
                                                            }}>Info</h1>
                                                            <h1 className={activeItem == 'practitioner-content' ? "subPageNavHeader subPageActiveNavHeader" : "subPageNavHeader"} onClick={() => props.setSubPage('practitioner-content')}>Content</h1>
                                                      </div>
                                                      {props.practitionerInfo != null ?
                                                            <div style={{ margin: "auto", textAlign: "center" }}>
                                                                  <h3 className="okashi">{props.username}</h3>

                                                                  {props.practitionerInfo.bio && !showFullBio ?
                                                                        <div>
                                                                              <h3>{props.practitionerInfo.bio.substring(0, 250)}</h3>
                                                                        </div>
                                                                        : props.practitionerInfo.bio ?
                                                                              <div>
                                                                                    <h3>{props.practitionerInfo.bio}</h3>
                                                                              </div>
                                                                              : null}
                                                                  {activeLinks && activeLinks.length > 0 ?
                                                                        <React.Fragment>

                                                                              {activeLinks.map(l => <div
                                                                                    className="zenPractitionerBlogLink"
                                                                                    onClick={() => { window.open(l, '_blank'); }}>
                                                                                    <FontAwesomeIcon icon={faLink} className="pbLink" />
                                                                                    {l}
                                                                              </div>)}
                                                                        </React.Fragment> : null}

                                                                  {props.practitionerInfo.bio && !showFullBio ?
                                                                        <div style={{ marginTop: "15px" }}>
                                                                              <div style={{ display: "flex", flexDirection: "row" }}>
                                                                                    {props.practitionerInfo.bio.length > 250 ?
                                                                                          <div className="zenGlowButton mingGlowButton" onClick={() => setShowFullBio(true)}>See More</div>
                                                                                          : null}
                                                                                    <Button onClick={() => setActiveItem("ChangeBio")}
                                                                                          variant="contained"
                                                                                          sx={{
                                                                                                background: '#FEDC97',
                                                                                                color: '#272727',
                                                                                                display: 'block',
                                                                                                margin: 'auto',
                                                                                          }}>Change Bio</Button>
                                                                              </div>
                                                                        </div>
                                                                        : props.practitionerInfo.bio ?
                                                                              <div>
                                                                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                                                                          {props.practitionerInfo.bio.length ?
                                                                                                <div className="zenGlowButton buddGlowButton" onClick={() => setShowFullBio(true)}>See Less</div>
                                                                                                : null}

                                                                                          <Button onClick={() => setActiveItem("ChangeBio")}
                                                                                                variant="contained"
                                                                                                sx={{
                                                                                                      background: '#FEDC97',
                                                                                                      color: '#272727',
                                                                                                      display: 'block',
                                                                                                      margin: 'auto',
                                                                                                }}>Change Bio</Button>
                                                                                    </div>
                                                                              </div>
                                                                              :
                                                                              <div className="zenGlowButton navajoGlowButton" onClick={() => setActiveItem("ChangeBio")}>Add Bio</div>}

                                                                  <Button onClick={() => props.setPage("ChangePhoto")}
                                                                        variant="contained"
                                                                        sx={{
                                                                              display: 'block',
                                                                              margin: 'auto',
                                                                              marginTop: '15px',
                                                                              background: '#f7eddc',
                                                                              color: '#272727'
                                                                        }}>Change Profile Picture</Button>

                                                                  <Button onClick={() => setActiveItem("AddProfileLink")}
                                                                        variant="contained"
                                                                        sx={{
                                                                              display: 'block',
                                                                              margin: 'auto',
                                                                              marginTop: '15px',
                                                                              background: '#FEDC97',
                                                                              color: '#272727'
                                                                        }}>Add Social Media Links</Button>
                                                                  <Button onClick={() => setActiveItem("updateReadingList")}
                                                                        variant="contained"
                                                                        sx={{
                                                                              display: 'block',
                                                                              margin: 'auto',
                                                                              marginTop: '15px',
                                                                              background: '#f7eddc',
                                                                              color: '#272727'
                                                                        }}>Add Content Reccomendations</Button>
                                                            </div>
                                                            : <ZenLoader />
                                                      }

                                                </React.Fragment>}
                  </div>
            </div>

      );

}
