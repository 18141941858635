import * as React from "react";
import '../../../styling/common/index.scss';
import { useState } from "react";
import { useAuth } from "../../../hooks/auth";
import axios from "axios";
import { useMyZenService } from "../../../hooks/ZenUserContext";
import { ConfirmTranslation, InvalidEmailTranslation, PasswordRequirementsTranslation, PasswordTranslation, SignUpFailedTranslation, SignUpTranslation, UsernameTranslation } from "../../../content/SignUpTranslations";
import { MadeInBCNTranslation } from "../../../content/HomeContentTranslations";
import { ZEN_C_ENDPOINT } from "../../../config/EnvironmentalVariables";
import { GdprBanner } from "../GdprBanner";
import { ReactSession } from 'react-client-session';
import ReactCodeInput from "react-verification-code-input";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import MetaTags from 'react-meta-tags';

export const ResetPassword = (props: any) => {

  const auth = useAuth();

  const [page, setPage] = useState("request");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");

  const userContext = useMyZenService();

  const ValidateUsername = (inputText: string) => {
    return inputText.length > 2;
  }

  const ValidatePassword = (inputText: string) => {
    var pwformat = /^(?=.*[!@#$%-^&*.])[a-zA-Z0-9!@#$%^&*.]{6,25}$/;
    return inputText.match(pwformat);
  }

  const requestPasswordReset = () => {
    auth.requestPasswordReset(username);
    setPage("code");
  }

  const confirmNewPassword = async () => {
    try {
      await Auth.forgotPasswordSubmit(username.toLowerCase(), code, password);
      setPage("finish");
      return { success: true, message: "" };
    } catch (error) {
      setPage("error");
      return {
        success: false,
        message: error,
      };
    }
  }

  return (
    <React.Fragment>      
      <MetaTags>
    <meta id="meta-description" name="description" content={"Reset the password for your account"} />
    <meta id="og-title" property="og:title" content="Reset Password - Zencyclopedia" />
  </MetaTags>
      {page == "request" ?
        <div className="ZenBasicPage">
          <h2 className="sign-up-header zenK"> Request a password reset:</h2>
          <h4 className="sign-up-header zenK"> Enter the username of the account you want to reset the password for:</h4>
          <form noValidate >
            {/* Username Input: */}
            <input
              type="text"
              placeholder={((props.lang && props.lang == "ESP") || userContext.language == 'ESP') ? UsernameTranslation.Spanish : UsernameTranslation.English}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className={ValidateUsername(username) ? 'su-text-input validated-field' : 'su-text-input'}
            />

          </form>

          <div className="zencTextButton zencGreenButton" style={{ marginTop: "15px" }} onClick={() => requestPasswordReset()}>
            Submit
          </div>
        </div>
        : page == "code" ?
          <div className="ZenBasicPage">
            <h1 className="sign-up-header zenK"> Reset Your Password</h1>
            <h3 className="val-email-prompt zenK">You should have received a confirmation code to your email. Enter it below along with your new password.</h3>
            <form noValidate >
              <div className="EnterValidationCodeHolder" >
                <ReactCodeInput
                  className="email-validator"
                  autoFocus={false}
                  onChange={(e) => setCode(e)} />
              </div>

              {/* Password Input: */}
              <input
                type="password"
                placeholder={"Enter your new password here!"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={ValidatePassword(password) ? 'su-text-input validated-field' : 'su-text-input'}
              />

            </form>

            <div className="zencTextButton zencGreenButton" onClick={() => confirmNewPassword()}>
              Submit
            </div>
          </div>
          : page == "error" ?
            <div className="ZenBasicPage">
              Oh no! something went wrong...
              
              <Link to={"/myZen"}>
                <div className="zencTextButton zencGreenButton" style={{ marginTop: "15px" }} >
                  Try Again
                </div>
              </Link>
              
              If the problem keeps happening, please get in touch.
            </div>
            :

            <div className="ZenBasicPage">
              Congratulations! You've changed your password!
              <Link to={"/myZen"}>
                <div className="zencTextButton zencGreenButton" style={{ marginTop: "15px" }} >
                  Great! take me back to my account
                </div>
              </Link>
            </div>
      }

    </React.Fragment>
  );
}






