import * as React from "react";
import "../../../../styling/common/index.scss";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { faOm } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router";
import axios from "axios";
import { useAuth } from "../../../../hooks/auth";
import { ZEN_C_ENDPOINT } from "../../../../config/EnvironmentalVariables";
import { ReactSession } from 'react-client-session';
import { useZenCardsService } from "../../../../hooks/ZenCardsContext";
import { useMyZenService } from "../../../../hooks/ZenUserContext";

export const MyZenTasks = (props: any) => {

      const auth = useAuth();
      let zenUserContext = useMyZenService();

      const [tasks, setTasks] = useState(null);
      const [cardAttempts, setCardAttempts] = useState(0);

      const getUserTasks = async () => {
            await axios.get(ZEN_C_ENDPOINT + `/myzen/myZen/getUserTasks/` + props.username)
                  .then(res => {
                        setTasks(res.data);
                  }).catch(() => {
                        setTasks([]);
                  });

            return true;
      }

      if (tasks == null && props.username) {
            let res = getUserTasks();
      }

      let navigate = useNavigate();


      const goToTask = (action: string) => {
            navigate("/" + action)
      }

      const WALKTHROUGH_MILESTONE_KEY = "WCOM_MS";
      const cardsContext = useZenCardsService();

      const checkForMilestoneCard = async () => {
            let rewardCard = await cardsContext.claimMilestone(WALKTHROUGH_MILESTONE_KEY);
            if (rewardCard != null) {
                  props.setRewardCard(rewardCard);
            }
      }

      const userHasNoTasks = (tasks != null) && !(tasks && tasks.length > 0);

      if (userHasNoTasks && cardAttempts < 5 && zenUserContext.profile && zenUserContext.profile.portalWalkthroughCompleted && !cardsContext.searchPending && !cardsContext.checkForMilestone(WALKTHROUGH_MILESTONE_KEY)) {
            setCardAttempts(cardAttempts + 1);
            checkForMilestoneCard();
      }


      const completeTask = (title: string) => {
           
            const walkthroughComplete = props.portalWalkthroughCompleted || ReactSession.get("myZenWalkthrough-"+props.username) == "TRUE";

            if (!walkthroughComplete) {
                  console.log("Setting walkthough complete, and completing walkthrough in back end");
                  ReactSession.set("myZenWalkthrough-"+props.username, "TRUE");
                  axios.post(ZEN_C_ENDPOINT + `/myzen/myZen/completeWalkthrough/` + props.username).then(function (response) {
                  })
                        .catch(function (error) {
                              console.log(error);
                        });
            }

            const apiresult = axios.post(ZEN_C_ENDPOINT + '/myzen/myZen/completeUserTask/', {
                  username: auth.username,
                  title: title
            }).then(function (response) {
            })
                  .catch(function (error) {
                        console.log(error);
                  });
      }

      return (
            <div className="my-notifications-container">
                  <div style={{ paddingBottom: "45px", margin:"auto"}}>
                        {!(tasks && tasks.length > 0) ? <h2 className="centered-text">You have no tasks.</h2> : <></>}
                        {tasks && tasks.length > 0 ? tasks.map((t, index) => {

                              var typeClass = 'MyZenFirsStepsNotification';
                              return <div className={"MyZenNotification " + typeClass} onClick={() => { completeTask(t.title); goToTask(t.action); }}>
                                    <h4 className="task-title">{t.title}</h4>
                                    {t.icon == 'ghost-practice' ?
                                          <FontAwesomeIcon icon={faCircleExclamation} className="ghost-practice-warning" />
                                          : t.icon == 'ohm' ?
                                                <FontAwesomeIcon icon={faOm} className="task-icon task-fa-icon" />
                                                : t.icon == 'logo' ?
                                                      <div className="task-icon task-logo-icon" />
                                                      : t.icon == 'icon' ?
                                                            <div className="task-icon task-target-icon" />
                                                            :
                                                            <></>
                                    }

                              </div>;
                        }) : null}
                  </div>
            </div>

      );

}
