import axios from "axios";
import React, { createContext, useContext, useRef, useState } from "react";
import { ZEN_C_ENDPOINT } from "../config/EnvironmentalVariables";
import { useAuth } from "./auth";

interface UseMyStuff {

  bookmarks: { id: number, contentId: number, username: string }[];
  myBookmarkSessions: any[];
  favourites: { id: number, contentId: number, username: string }[];
  myFavouriteSessions: any[];
  addBookmark: (contentId: number) => void;
  removeBookmark: (contentId: number) => void;
  addFavourite: (contentId: number) => void;
  removeFavourite: (contentId: number) => void;
  logContentInteraction:any;
  fetchBookmarks:any;
  fetchFavourites:any;
  getBookmarks:any;
  getFavourites:any
}

type Props = {
  children?: React.ReactNode;
};
const myStuffContext = createContext({} as UseMyStuff);

export const ProvideMyStuffContext: React.FC<Props> = ({ children }) => {
  const myStuffService = MyStuffService();
  return <myStuffContext.Provider value={myStuffService}>{children}</myStuffContext.Provider>;
};

export const useMyStuffService = () => {
  return useContext(myStuffContext);
};

export const MyStuffService = () => {

  const auth = useAuth();

  const [bookmarks, setBookmarks] = useState(null);
  const [favourites, setFavourites] = useState(null);
  const [loading, setLoading] = useState(null);
  const bookmarkAttempts = useRef(0);
  const favouriteAttempts = useRef(0);
  const [myFavouriteSessions, setMyfavouriteSessions] = useState(null);
  const [myBookmarkSessions, setMyBookmarkSessions] = useState(null);
  
  const fetchBookmarks = async () => {
    let result = await axios.get(ZEN_C_ENDPOINT + `/myzen/myStuff/bookmarks/` + auth.username)
      .then(async res => {
        if (res.data.length > 0) {
          let sessions = await axios.post(ZEN_C_ENDPOINT + `/zen/content/fetchList`, res.data.map(b => b.contentId))
          .then(res => {
            if (res.data != null) {
              return res.data;
            } else {
              return [];
            }
          }).catch(function (error) {
            return [];
            console.log(error);
          });
          return sessions;
        } else {
          return [];
        }
      }).catch(() => {
        return [];
      });

      return result;
  }

  const fetchFavourites = () => {
    let res = axios.get(ZEN_C_ENDPOINT + `/myzen/myStuff/favourites/` + auth.username)
      .then(async res => {
        if (res.data.length > 0) {
          let sessions = await axios.post(ZEN_C_ENDPOINT + `/zen/content/fetchList`, res.data.map(b => b.contentId))
          .then(res => {
            if (res.data != null) {
              return res.data;
            } else {
              return [];
            }
          }).catch(function (error) {
            return [];
          });
          return sessions;
        } else {
          return [];
        }
      }).catch(() => {
        return [];
      })

      return res;
  }

  

  const getBookmarks = () => {
    axios.get(ZEN_C_ENDPOINT + `/myzen/myStuff/bookmarks/` + auth.username)
      .then(res => {
        if (res.data.length > 0) {
          setBookmarks(res.data);
          setLoading(false);
          getBookmarkSessions(res.data);
          console.log("getting bookmarks Session info");
        } else {
          setBookmarks([]);
          setLoading(false);
        }
      }).catch(() => {
        setBookmarks([]);
        setLoading(false);
      })

  }

  const getFavourites = () => {
    axios.get(ZEN_C_ENDPOINT + `/myzen/myStuff/favourites/` + auth.username)
      .then(res => {
        if (res.data.length > 0) {
          setFavourites(res.data);
          setLoading(false);
          console.log("getting favourites Session info");
          getFavouriteSessions(res.data);
        } else {
          setFavourites([]);
          setLoading(false);
        }
      }).catch(() => {
        setFavourites([]);
        setLoading(false);
      })
  }

  const getBookmarkSessions = async (sessions:any) => {
    axios.post(ZEN_C_ENDPOINT + `/zen/content/fetchList`, sessions.map(b => b.contentId))
      .then(res => {
        if (res.data != null) {
          setMyBookmarkSessions(res.data);
        } else {
          setMyBookmarkSessions([]);
        }
      }).catch(function (error) {
        setMyBookmarkSessions([]);
        console.log(error);
      });
  }

  const getFavouriteSessions = async (sessions:any) => {
    axios.post(ZEN_C_ENDPOINT + `/zen/content/fetchList`, sessions.map(b => b.contentId))
      .then(res => {
        if (res.data != null) {
          setMyfavouriteSessions(res.data);
        } else {
          setMyfavouriteSessions([]);
      }
      }).catch(function (error) {
        setMyfavouriteSessions([]);
        console.log(error);
      });
  }


  const addBookmark = async (contentId: number) => {
    axios.post(ZEN_C_ENDPOINT + `/myzen/myStuff/addBookmark/` + auth.username, {
      username: auth.username,
      contentId: contentId
    })
      .then(res => {
        setBookmarks(res.data);
      });
  }

  const addFavourite = (contentId: number) => {
    axios.post(ZEN_C_ENDPOINT + `/myzen/myStuff/addPracticeToFavourites/` + auth.username, {
      username: auth.username,
      contentId: contentId
    })
      .then(res => {
        setFavourites(res.data);
      })
  }

  const removeBookmark = (contentId: number) => {
    axios.post(ZEN_C_ENDPOINT + `/myzen/myStuff/removeBookmark/` + auth.username, {
      username: auth.username,
      contentId: contentId
    })
      .then(res => {
        setBookmarks(res.data);
      });
  }

  const removeFavourite = (contentId: number) => {
    axios.post(ZEN_C_ENDPOINT + `/myzen/myStuff/removePracticeToFavourites/` + auth.username, {
      username: auth.username,
      contentId: contentId
    })
      .then(res => {
        setFavourites(res.data);
      })
  }

  const logContentInteraction = async (contentId: string) => {
    if(auth != null && auth.username !=null){
      await axios.get(ZEN_C_ENDPOINT+`/myzen/honeycohm/logContentInteraction`+auth.username+`/`+contentId)
      .then(res => {
        return true;
      }).catch(() => {
      return true;
      });
      return true;
    }
  }

  // if (!loading && auth.username && bookmarks == null && bookmarkAttempts && bookmarkAttempts.current && bookmarkAttempts.current <= 3) {
  //   console.log("Polling for user bookmarks "+bookmarkAttempts.current);
  //   setLoading(true);
  //   bookmarkAttempts.current++;
  //   let res = getBookmarks();
  // } else if (!loading && auth.username && favourites == null  && favouriteAttempts && favouriteAttempts.current && favouriteAttempts.current <= 3) {
  //   console.log("Polling for user favourites "+favourites.current);
  //   setLoading(true);
  //   favouriteAttempts.current++;
  //   let res = getFavourites();
  // }

  return {
    bookmarks,
    myBookmarkSessions,
    favourites,
    myFavouriteSessions,
    addBookmark,
    removeBookmark,
    addFavourite,
    removeFavourite,
    logContentInteraction,
    fetchBookmarks,
    fetchFavourites,
    getBookmarks,
    getFavourites
  };

}