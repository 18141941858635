import * as React from "react";
import "../../styling/common/index.scss";
import "../../styling/explore/explore.scss";
import { useState } from "react";
import axios from "axios";
import { useAuth } from "../../hooks/auth";
import { ZEN_C_ENDPOINT } from "../../config/EnvironmentalVariables";
import { MeditationIcons } from "../zencontent/practices/MeditationIcons";
import { BarLoader } from "react-spinners";
import ZenContentInterface from "../zencontent/practices/ZenContentInterface";
import { useNavigate } from "react-router";
import { PractitionerContentReccomendation } from "./readinglist/PractitionerContentReccomendation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo, faPodcast, faGlobe, faBook } from "@fortawesome/free-solid-svg-icons";

export const PractitionerReadingList = (props: { userId?: any, closePage?: any }) => {

      const [page, setPage] = useState("view-recs");
      const [contentReccs, setContentReccs] = useState([]);
      const [activeItem, setActiveItem] = useState(null);

      const getPractitionerReccs = async () => {
            await axios.get(ZEN_C_ENDPOINT + `/zen/practitionerRecommendations/` + props.userId)
                  .then(res => {
                        setContentReccs(res.data);
                  });
      }

      const showReview = (itemNumber) => {
            setActiveItem(contentReccs[itemNumber - 1]);
            setPage("review");
      }

      React.useEffect(() => {
            getPractitionerReccs();
      }, []);

      return (
            <div className="practitionerReadingList">

                  {page == "view-recs" ?
                        <div>
                              <h2 className="okashi" style={{ margin: "auto", textAlign: "center" }}>Reccomended Content List</h2>
                              <div>
                                    {contentReccs.map((s, index) => <PractitionerContentReccomendation
                                          hasBeenSaved={true}
                                          lessonNumber={index + 1}
                                          {...s}
                                          showReview={(index) => showReview(index)}></PractitionerContentReccomendation>)}
                              </div>
                        </div>
                        : page == "review" ?
                              <React.Fragment>
                                    <div className="shop-back-arrow" onClick={() => setPage("view-recs")}></div>
                                    <div className="content-reccomendation-full-holder">
                                          <h3>{activeItem.type == "Video" ?
                                                <FontAwesomeIcon icon={faVideo} />
                                                : activeItem.type == "Podcast" ?
                                                      <FontAwesomeIcon icon={faPodcast} />
                                                      : activeItem.type == "Website" ?
                                                            <FontAwesomeIcon icon={faGlobe} />
                                                            :
                                                            <FontAwesomeIcon icon={faBook} />
                                          } Reading List Reccomendation
                                          </h3>
                                          <h2 className="content-recc-title">{activeItem.title}</h2>
                                          <h4>{activeItem.description}</h4>
                                          <div className="zenGlowButton sageGlowbutton" onClick={() => {
                                                window.open(activeItem.link.includes('https://') ? activeItem.link : 'https://' + activeItem.link, '_blank');
                                          }
                                          }>Check it out</div>
                                    </div>
                              </React.Fragment>
                              : null}
            </div>
      )
}