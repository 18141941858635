import React, { useState } from 'react';
import "../../../styling/vacana/vacana.scss";
import "../../../styling/common/index.scss";
import "../../../styling/zenverse/bubbleEnergy.scss";
import "../../../styling/stream/stream.scss";
import "../../../styling/zenverse/Zenverse.scss";
import "../../../styling/common/simpleCountdown.scss";
import { SimpleCountDownTimer } from './SimpleCountDownTimer';
import { ZEN_C_ENDPOINT } from '../../../config/EnvironmentalVariables';

export const SimpleTimerPortal = () => {

  const [walkthroughStage, setWalkthroughStage] = useState(1);
  const [practicePlaying, setPracticePlaying] = useState(false);
  const [chosenMedTime, setChosenMedTime] = useState(null);
  const [medTime, setMedTime] = useState(null);
  const [soundLoading, setLoading] = useState(null);
  const [getSoundAttempts, setGetSoundAttempts] = useState(0);
  const [soundSource, setSoundSource] = useState(null);
  const [hasFinished, setHasFinished] = useState(false);


  const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();

  if (walkthroughStage > 5 && !practicePlaying) {
    setPracticePlaying(true);
  }

  const getTimerCompleteSound = async () => {
    setLoading(true);
    let soundRequest = new XMLHttpRequest();
    soundRequest.open('GET', ZEN_C_ENDPOINT + `/zen/zenMedia/zenSounds/stream/ascend`, true);
    soundRequest.responseType = 'arraybuffer';
    soundRequest.addEventListener('load', () => {

      audioContext.decodeAudioData(soundRequest.response).then((buffer) => {
        let source = audioContext.createBufferSource();

        source.buffer = buffer;
        source.loop = true;
        source.connect(audioContext.destination);
        setSoundSource(source);
        setLoading(false);
        //check.disabled = false;
      });

    });
    soundRequest.send();
  }

  const playFinishSound = () => {
    soundSource.start(0);
    setHasFinished(true);

  }
  const stopAlarmSound = () => {
    soundSource.stop(0);
  }


  var fixAudioContext = function (e) {
    if (audioContext) {
      // Create empty buffer
      var buffer = audioContext.createBuffer(1, 1, 22050);
      var source = audioContext.createBufferSource();
      source.buffer = buffer;
      // Connect to output (speakers)
      source.connect(audioContext.destination);
      // Play sound
      if (source.start) {
        source.start(0);
      }
      // else if (source.play) {
      //   source.play();
      // } else if (source.noteOn) {
      //   source.noteOn();
      // }
    }
    // Remove events
    document.removeEventListener('touchstart', fixAudioContext);
    document.removeEventListener('touchend', fixAudioContext);
  };
  // iOS 6-8
  document.addEventListener('touchstart', fixAudioContext);
  // iOS 9
  document.addEventListener('touchend', fixAudioContext);


  if (soundSource == null && !soundLoading && getSoundAttempts < 4) {
    getTimerCompleteSound();
    setGetSoundAttempts(getSoundAttempts + 1);
  }

  return (
    <div>

      {!medTime ?
        <div style={{ marginTop:"150px" }}>
          <h3 className="centered-text">Choose Duration</h3>

          <div style={{ margin: "auto", display: "flex", flexDirection: "row", flexWrap: "wrap", width: "300px" }}>
            <h3 className="simple-timer-suration-option" onClick={() => {setMedTime(30); setChosenMedTime(30); }}>5 mins</h3> 
            {/* setMedTime(5 * 60); setChosenMedTime(5 * 60); }}>5 mins</h3> */}
            <h3 className="simple-timer-suration-option" onClick={() => { setMedTime(10 * 60); setChosenMedTime(10 * 60); }}>10 mins</h3>
            <h3 className="simple-timer-suration-option" onClick={() => { setMedTime(15 * 60); setChosenMedTime(15 * 60); }}>15 mins</h3>
            <h3 className="simple-timer-suration-option" onClick={() => { setMedTime(20 * 60); setChosenMedTime(20 * 60); }}>20 mins</h3>
            <h3 className="simple-timer-suration-option" onClick={() => { setMedTime(30 * 60); setChosenMedTime(30 * 60); }}>30 mins</h3>
            <h3 className="simple-timer-suration-option" onClick={() => { setMedTime(60 * 60); setChosenMedTime(60 * 60); }}>1 Hour</h3>
          </div></div>
        : hasFinished ?
          <>
            <h3 className="centered-text">Nice Work!</h3>

            <div style={{ margin: "auto", display: "flex", flexDirection: "row", flexWrap: "wrap", width: "300px" }}>
              <div className="zencTextButton zencRedButton" onClick={() => stopAlarmSound()}>
                Stop Sound
              </div>
              <div className="zencTextButton zencGreenButton" onClick={() => {
                stopAlarmSound();
                setMedTime(chosenMedTime);

              }}>
                Restart
              </div>
            </div>
            <h4 className = "zenGlowButton navajoGlowButton centered-text" style = {{marginTop:"25px"}} onClick={() => {
                setMedTime(null);
                setHasFinished(false);
              }}>Choose Another Duration</h4>
          </>
          :
          <SimpleCountDownTimer time={medTime} timerFinished={() => playFinishSound()}></SimpleCountDownTimer>
      }


    </div>
  );
}