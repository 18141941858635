import React, { useState } from 'react';
import "../../../../styling/vacana/vacana.scss";
import "../../../../styling/common/index.scss";
import "../../../../styling/stream/stream.scss";

import ZenContentInterface from '../ZenContentInterface';
import { MeditationIcons } from '../MeditationIcons';
import { PracticePreviewControls } from './PracticePreviewControls';
import { Link } from 'react-router-dom';

export const PracticeOverview = (props: ZenContentInterface) => {
  const [showSyllabus, setShowSyllabus] = useState(false);

  return props && props ? (

    <div className="stream-item lecture-preview">
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="practice-image-holder">
          <img className="practice-overview-image" src={props && props.image ? props.image : require("../../../../static/photos/AwM.png")} onClick={() => setShowSyllabus(!showSyllabus)}></img>
          <div className = "zen-desktop">
          <MeditationIcons {...props}></MeditationIcons>
          </div>
        </div>
        <div className="practice-details-section">

          <div className="practice-title-row">
            <div className="practice-image-holder-mobile">
              <img className="practice-overview-image" src={props.image ? props.image : require("../../../../static/photos/AwM.png")} onClick={() => setShowSyllabus(!showSyllabus)}></img>
            </div>
            <div>
              <h4 className="practice-title okashi">{props.title}</h4>
              {/* <h4 className = "practitioner-name zenK">by {props.practitioner? props.practitioner.name:''}</h4> */}
            </div>
          </div>
          <div className = "zen-mobile">
          <MeditationIcons {...props}></MeditationIcons>
          </div>

          {props && props.description ?
            <h3 className="practice-description zenK"> {props.description.substring(0,175)}{props.description.length>175?'...':null}</h3>
            :
            null}

          <Link to={"/meditation/"+props.id}>
            <h4 style={{ width: "100%", margin: "auto", textAlign: "center", marginTop: "15px", marginBottom: "20px" }}>See More</h4>
          </Link>
          <PracticePreviewControls {...props} />

        </div>
      </div>
    </div>
  ) : (<></>);
}