import * as React from "react";
import "../../../styling/common/index.scss";
import "../../../styling/common/zenCoreUIComponents.scss";
import "../../../styling/myzen/myzen.scss";
import { useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router";
import { PuffLoader } from "react-spinners";
import { ZEN_C_ENDPOINT } from "../../../config/EnvironmentalVariables";
import { ZenNavBar } from "../../common/navbar/ZenNavBar";
import { useAuth } from "../../../hooks/auth";

export const SubscriptionCustomerDetailsModal = (props:any) => {

      const auth = useAuth();

        const [name, setName] = useState(null);
        const [address, setAddress] = useState('');
        const [county, setCounty] = useState('');
        const [city, setCity] = useState('');
        const [postcode, setPostcode] = useState('');
      
        const handleSubmit = () => {
          props.onComplete(name,{
            address:address,
            county:county,
            city:city,
            postcode:postcode
          });
        };
      
        return (
          <React.Fragment>
            <div className="sign-up-details-container">
              <h2 className="sign-up-header zenK">Please Enter Subscriber Details</h2>
              <h4 className="zenK">These will be stored securely by our third party subscription provider <div className = "checkout-stripe-logo" onClick = {() => {window.open("https://stripe.com/gb", '_blank');}}></div></h4>
              <form onSubmit={handleSubmit} noValidate>
                {/* Full Name Input: */}
                <input
                  type="text"
                  placeholder="Full Name"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="delivery-details-input"
                />
      
                {/* Address Input: */}
                <input
                  type="text"
                  placeholder="Address"
                  id="address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  className="delivery-details-input"
                />
      
                {/* County Input: */}
                <input
                  type="text"
                  placeholder="County"
                  id="county"
                  value={county}
                  onChange={(e) => setCounty(e.target.value)}
                  className="delivery-details-input"
                />
      
                {/* City Input: */}
                <input
                  type="text"
                  placeholder="City"
                  id="city"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  className="delivery-details-input"
                />
      
                {/* Postcode Input: */}
                <input
                  type="text"
                  placeholder="Postcode"
                  id="postcode"
                  value={postcode}
                  onChange={(e) => setPostcode(e.target.value)}
                  className="delivery-details-input"
                />
{/*       
                <input
                  type="email"
                  placeholder="Email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="delivery-details-input"
                /> */}
      
                {/* Submit Button: */}
                <div style = {{marginTop:"10px"}} className = "zenGlowButton mingGlowButton" onClick = {() => handleSubmit()}>submit</div>
              </form>
            </div>
          </React.Fragment>
        );
      };
      
