import axios from "axios";
import React, { createContext, useContext, useState } from "react";
import { ContentTypes } from "../components/zencontent/common/ContentTypes";
import { ZEN_C_ENDPOINT } from "../config/EnvironmentalVariables";
import { mockGetZenscapes } from "../stubs/ZenverseMock";
import { useAuth } from "./auth";
import { orderZenscapes } from "../service/ZenverseService";

interface UseZenverse {
  resultsName: string,
  userPortalDetails: any[],
  myPortals: any[],
  resultPortals: any[],
  favourites: any[],
  getUserPortals: () => void;
  searchPortalsByTag: (tag) => void;
  claimZenscape: (zenscapeId) => void;
  addPortalToFavourites: (zenscapeId) => void;
  removePortalFromFavourites: (zenscapeId) => void;
  unclaimZenscape: (zenscapeId) => void;
  searchPending: boolean,
  portalInventorySpaces: number,
  logUserZenPortaTime: (time) => void;
}

type Props = {
  children?: React.ReactNode;
};
const zenverseContext = createContext({} as UseZenverse);

export const ProvideZenverseContext: React.FC<Props> = ({ children }) => {
  const zenverseService = ZenverseService();
  return <zenverseContext.Provider value={zenverseService}>{children}</zenverseContext.Provider>;
};

export const useZenverseService = () => {
  return useContext(zenverseContext);
};

export const ZenverseService = () => {
  const auth = useAuth();

  const [userPortalDetails, setUserPortalDetails] = useState(null);
  const [myPortals, setMyPortals] = useState(null);
  const [resultPortals, setResultPortals] = useState(null);
  const [favourites, setFavourites] = useState(null);
  const [resultsName, setResultsName] = useState(null);
  const [searchPending, setSearchPending] = useState(false);
  const [portalInventorySpaces, setPortalInventorySpaces] = useState(null);
  const [triesCount, setTriesCount] = useState(0);
  const [portalTimeTriesCount, setPortalTimeTriesCount] = useState(0);
  const [portalTimeLeft, setPortalTimeLeft] = useState(null);

  const getUserPortalInventorySpaces = () => {
    setSearchPending(true);
    if (auth.username != null) {
      axios.get(ZEN_C_ENDPOINT + `/myzen/quotas/getZenscapeInventorySpaceRemaining/` + auth.username)
        .then(res => {
          setPortalInventorySpaces(res.data);
          setSearchPending(false);
        }).catch(() => {
          setSearchPending(false);
        })
    } else {
      setSearchPending(false);
    }
  }

  if (portalInventorySpaces == null && !searchPending && triesCount < 2 && auth.username) {
    setTriesCount(triesCount + 1)
    getUserPortalInventorySpaces();
  }

  const getUserPortals = async () => {
    setSearchPending(true);
    await axios.get(ZEN_C_ENDPOINT + `/myzen/myStuff/getUserZenscapes/` + auth.username)
      .then(async res => {
        if (res.data.length > 0) {
          setUserPortalDetails(res.data);
          setFavouritePortals(res.data);
          let r = await setMyPortalItems(res.data);
          setResultsName("myportals");
          setSearchPending(false);
        } else {
        setMyPortals([]);
        setResultsName("myportals");
        setSearchPending(false);
        }
      }).catch(async () => {
        setMyPortals([]);
        let r = await setMyPortalItems([]);
        setResultsName("myportals");
        setSearchPending(false);
      })

      return true;
  }


  const logUserZenPortaTime = (time: number) => {
    axios.post(ZEN_C_ENDPOINT + `/myzen/quotas/logZenZenscapeTime/` + auth.username + `/` + time)
      .then(res => {
        setPortalTimeLeft(portalTimeLeft - time);
        setSearchPending(false);
      }).catch(() => {
        setSearchPending(false);
      })
  }


  const setFavouritePortals = (userPortalList: any) => {

    setSearchPending(true);
    axios.post(ZEN_C_ENDPOINT + `/zen/zenscapes/list`, userPortalList.filter((p) => p.favourite).map(up => up.zenscapeId))
      .then(res => {
        if (res.data.length > 0) {
          setFavourites(res.data);
          setSearchPending(false);
        }
      }).catch(() => {
        setSearchPending(false);
      })
  }

  const setMyPortalItems = async (userPortalList: any) => {

    setSearchPending(true);
    await axios.post(ZEN_C_ENDPOINT + `/zen/zenscapes/list`, userPortalList.map(up => up.zenscapeId))
      .then(res => {
        if (res.data.length > 0) {
          setMyPortals(res.data);
        } else {
          setMyPortals([]);
        }
      }).catch(() => {
        setMyPortals([]);
      })

      return true;
  }


  const searchPortalsByTag = (tag: string) => {

    // setResultPortals([]);
    // setResultsName(tag);
    setSearchPending(true);
    var searchPath = tag == "new" ? ZEN_C_ENDPOINT + `/zen/content/newContent/` + ContentTypes.ZENSCAPE + `/`
      : ZEN_C_ENDPOINT + `/zen/content/searchContent/` + ContentTypes.ZENSCAPE + `/` + tag;

    axios.get(searchPath)
      .then(res => {
        if (res.data.length > 0) {
          let orderedZenscapes = orderZenscapes(res.data, tag.toLowerCase());
          axios.post(ZEN_C_ENDPOINT + `/zen/zenscapes/list`, res.data.map(sp => tag == "new" ? sp.id : sp))
            .then(res => {
              setResultPortals(orderZenscapes(res.data, tag));
              setResultsName(tag);
              setSearchPending(false);
            }).catch(() => {
              setResultPortals([]);
              setResultsName(tag);
              setSearchPending(false);
            })
        } else {
          //setResultPortals([]);
          setResultPortals(mockGetZenscapes());
          setResultsName(tag);
          setSearchPending(false);
        }
      }).catch(() => {
        //setResultPortals([]);
        setResultPortals(mockGetZenscapes());
        setResultsName(tag);
        setSearchPending(false);
      })
  }

  const claimZenscape = (zenscapeId: string) => {
    axios.post(ZEN_C_ENDPOINT + `/myzen/myStuff/addUserZenscape/` + auth.username + `/` + zenscapeId)
      .then(res => {

        setPortalInventorySpaces(portalInventorySpaces - 1);
        setUserPortalDetails(res.data);

        if (res.data.length > 0) {
          axios.post(ZEN_C_ENDPOINT + `/zen/zenscapes/list`, res.data.map(sp => sp.zenscapeId))
            .then(res => {
              if (res.data.length > 0) {
                setMyPortals(res.data);
                setSearchPending(false);
              } else if (res.data.length == 0) {
                setMyPortals([]);
                setSearchPending(false);
              } else {
                setMyPortals([]);
                setSearchPending(false);
              }
            }).catch(() => {
              setMyPortals([]);
              setSearchPending(false);
            })
        } else {
          setMyPortals([]);
          setResultsName("fail");
          setSearchPending(false);
        }
      })
  }

  const unclaimZenscape = (zenscapeId: string) => {
    axios.post(ZEN_C_ENDPOINT + `/myzen/myStuff/removeUserZenscape/` + auth.username + `/` + zenscapeId)
      .then(res => {
        setPortalInventorySpaces(portalInventorySpaces + 1);
        setUserPortalDetails(res.data);
        if (res.data.length > 0) {
          axios.post(ZEN_C_ENDPOINT + `/zen/zenscapes/list`, res.data.map(sp => sp.zenscapeId))
            .then(res => {
              if (res.data.length > 0) {
                setMyPortals(res.data);
                setSearchPending(false);
              } else if (res.data.length == 0) {
                setMyPortals([]);
                setSearchPending(false);
              } else {
                setMyPortals([]);
                setSearchPending(false);
              }
            }).catch(() => {
              setMyPortals([]);
              setSearchPending(false);
            })
        } else {
          setMyPortals([]);
          setResultsName("fail");
          setSearchPending(false);
        }
      })
  }

  const addPortalToFavourites = (zenscapeId: string) => {
    axios.post(ZEN_C_ENDPOINT + `/myzen/myStuff/addZenscapeToFavourites/` + auth.username + `/` + zenscapeId)
      .then(res => {
        if (res.data.length > 0) {
          setFavouritePortals(res.data);
        } else if (res.data.length == 0) {
          setFavourites([]);
        } else {
          setFavourites([]);
        }
      })
  }

  const removePortalFromFavourites = (zenscapeId: string) => {
    axios.post(ZEN_C_ENDPOINT + `/myzen/myStuff/removeZenscapeFavourite/` + auth.username + `/` + zenscapeId)
      .then(res => {
        if (res.data.length > 0) {
          setFavouritePortals(res.data);
        } else if (res.data.length == 0) {
          setFavourites([]);
        } else {
          setFavourites([]);
        }
      })
  }

  return {
    userPortalDetails,
    myPortals,
    resultPortals,
    favourites,
    getUserPortals,
    searchPortalsByTag,
    resultsName,
    claimZenscape,
    unclaimZenscape,
    addPortalToFavourites,
    removePortalFromFavourites,
    searchPending,
    portalInventorySpaces,
    logUserZenPortaTime
  };

}