import * as React from "react";
import "../../styling/zenverse/Zenverse.scss";
import "../../styling/common/index.scss";
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ZenNavBar } from "../common/navbar/ZenNavBar";
import { useState } from "react";
import PrivateRoute from "../common/PrivateRoute";
import {PuffLoader, BarLoader} from "react-spinners";
import { useStreamService } from "../../hooks/StreamContext";
import ZenContentInterface from "../zencontent/practices/ZenContentInterface";
import { ProvideMyStuffContext, useMyStuffService } from "../../hooks/MyStuffContext";
import { faList, faVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReelStream } from "./ReelStream";

export const ZenStream = () => {

  const streamService = useStreamService(); 
  const [updating, setUpdating] = useState(false);

    const [primaryColor, setPrimaryColor] = useState('#D1603D');
    const [showSwitchStreams, setShowSwitchStreams] = useState(true);
    const [showReels, setShowReels] = useState(false);

    const  handleScroll = async (e) => {

      const scrolledToBottom =  e.target.scrollHeight - e.target.scrollTop < (1.1 * e.target.clientHeight);
      //const bottom = e.target.scrollHeight - e.target.scrollTop < (1.1 * e.target.clientHeight);

      if(e.target.scrollTop> 900 && showSwitchStreams){
        setShowSwitchStreams(false);
      } else if(e.target.scrollTop < 900 && !showSwitchStreams){
        setShowSwitchStreams(true);
      }
      if((!updating) && (e.target.scrollHeight - e.target.scrollTop < (1.6 * e.target.clientHeight))){
            console.log("GETTING STREAM ITEMS");
            setUpdating(true);
            if(showReels){
              console.log("ADDING REEL ITEMS");
              await streamService.addReelItems();
              setUpdating(false);
            } else {
              await streamService.addStreamItems();
              setUpdating(false);
            }
      }


    }

    const reorder = (items: ZenContentInterface []) => {
          var orderedItems = [];

          while(orderedItems.length < items.length){
                var portals = items.filter(i => (i.contentType == "MEDITATION" && i.previewType == "portal"));

          }

    }
    
    


  const myStuffService = useMyStuffService();
  
    React.useEffect(() => {
      myStuffService.getBookmarks();
      myStuffService.getFavourites();
      streamService.addReelItems();
    }, []);


    return (
      <ProvideMyStuffContext>
      <PrivateRoute>

      <ZenNavBar primaryColor = {primaryColor}></ZenNavBar>
      <div className = "ZenStream" onScroll={(e) => handleScroll(e)}>
      <div className = "stream-buddha-bot">
      {/* <BuddhaBot primaryColor = {primaryColor} setPrimaryColor = {setPrimaryColor} ></BuddhaBot> */}
      </div>
      {showSwitchStreams?
      <div className = "swapStreamsBanner">
          <h2 style = {{margin:"auto", textAlign:"center"}} onClick={() => setShowReels(false)}><FontAwesomeIcon icon={faList}  /></h2>
          <h2  style = {{margin:"auto", textAlign:"center"}} onClick={() => setShowReels(true)}><FontAwesomeIcon icon={faVideo}  /></h2>
      </div>:
      <div className = "swapStreamsBanner swapStreamsBannerFaded">

      </div>
      }
          {!showReels && streamService.streamItems?
          <div className = 'stream-items'>
              {streamService.streamItems}
              <div className = "stream-loading">
                <div style={{margin:"auto",width:"fitContent"}}>
              <BarLoader color="#D1603D">

              </BarLoader>
                  </div>
              </div>
              </div>
            :showReels && streamService.reelItems && streamService.reelItems.length>0?
            <ReelStream/>
            :
            <div className='loadScreen'>
              <div className='loaderHolder'>
              <PuffLoader color="#033f63" size={250}>
              </PuffLoader>
            </div>
            </div>
          }
          
          
      </div>
      </PrivateRoute>
      </ProvideMyStuffContext>
    );

}

