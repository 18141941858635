import * as React from "react";
import "../../../styling/common/index.scss";
import "../../../styling/common/zenCoreUIComponents.scss";
import "../../../styling/myzen/myzen.scss";
import { useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router";
import { PuffLoader } from "react-spinners";
import { ZEN_C_ENDPOINT } from "../../../config/EnvironmentalVariables";
import { ZenNavBar } from "../../common/navbar/ZenNavBar";
import Ratings from 'react-ratings-declarative';

export const StarRatings = (props:any) => {

      return (
            <div>
                  <Ratings
                        rating={props.rating != null && props.rating> 0? props.rating: 0}
                        widgetDimensions="14px"
                        widgetSpacings="1px"
                  >
                        <Ratings.Widget widgetRatedColor="#FFBC42" />
                        <Ratings.Widget widgetRatedColor="#FFBC42" />
                        <Ratings.Widget widgetRatedColor="#FFBC42" />
                        <Ratings.Widget widgetRatedColor="#FFBC42" />
                        <Ratings.Widget widgetRatedColor="#FFBC42" />
                  </Ratings>
            </div>
      );
}
