import axios from "axios";
import React, { createContext, useContext, useState } from "react";
import { getAllPowerCodes } from "../components/explore/icons/CardSubtypes";
import ZenCardInterface from "../components/explore/icons/ZenCardInterface";
import { ZEN_C_ENDPOINT } from "../config/EnvironmentalVariables";
import { useAuth } from "./auth";

interface UseZenCards {
  zenCardsResults: ZenCardInterface[],
  userIcons: any [],
  getZenCards: () => void
  filterZenCards: (cardType:string) => void;
  claimZenCard: (username: string, iconCode: string,code?:string) => void
  searchPending: boolean,
  resultType:string,
  claimMilestone: (cardType:string) => any;
  checkForMilestone: (milestoneKey:string) => any;
  searchZenCards: (cardType:string) => any;
}

type Props = {
  children?: React.ReactNode;
};
const zenCardsContext = createContext({} as UseZenCards);

export const ProvideZenCardsContext: React.FC<Props> = ({ children }) => {
  const zenCardsService = ZenCardsService();
  return <zenCardsContext.Provider value={zenCardsService}>{children}</zenCardsContext.Provider>;
};

export const useZenCardsService = () => {
  return useContext(zenCardsContext);
};

export const ZenCardsService = () => {
  const auth = useAuth();

  const [zenCardsResults, setZenCardsResults] = useState(null);
  const [userIcons, setUserIcons] = useState(null);
  const [searchPending, setSearchPending] = useState(false);
  const [getTries, setGetTries] = useState(4);
  const [getUserPOrtalTries, setGetUserPortalTries] = useState(4);
  const [resultType, setResultType] = useState(null);
  


  const getZenCards = async () => {
    setSearchPending(true);
    await axios.get(ZEN_C_ENDPOINT + `/myzen/icons/list`)
      .then(res => {
        setZenCardsResults(res.data);
        setSearchPending(false);
        setResultType("all");
      }).catch(() => {
        setSearchPending(false);
      });
  }

  const filterZenCards = async (cardType:string) => {
    let search = cardType == "powers?"? "POWER" : cardType == "milestones"? "MILESTONE" : null;
    let powerCodes = getAllPowerCodes();
    setSearchPending(true);
    if(search){
      await axios.get(ZEN_C_ENDPOINT + `/myzen/cards/search/`+cardType)
        .then(res => {
          setZenCardsResults(res.data);
          setSearchPending(false);
          setResultType(cardType);
        }).catch(() => {
          setZenCardsResults([]);
          setSearchPending(false);
        });
    } else if (powerCodes.includes(cardType)) {
      await axios.get(ZEN_C_ENDPOINT + `/myzen/cards/searchBySubtype/`+cardType)
        .then(res => {
          setZenCardsResults(res.data);
          setSearchPending(false);
          setResultType(cardType);
        }).catch(() => {
          setZenCardsResults([]);
          setSearchPending(false);
        });
    }

  }

  const searchZenCards = async (cardType:string) => {
   
     let result = await axios.get(ZEN_C_ENDPOINT + `/myzen/cards/search/`+cardType)
        .then(res => {
          return res.data;
        }).catch(() => {
          return [];
        });

        return result;
  
  }

  const getZenUserIcons = async () => {
    setSearchPending(true);
    await axios.get(ZEN_C_ENDPOINT + `/myzen/icons/getUserIcons/`+auth.username)
      .then(res => {
        setUserIcons(res.data);
        setSearchPending(false);
        setResultType("mycards");
      }).catch(() => {
        setSearchPending(false);
      });

  }

  const claimZenCard = async (username:string, cardKey:string, code?:string) => {
    console.log("claiming ZEN CARD: "+cardKey);
    setSearchPending(true);
    if(code && code.length > 1){
      await axios.post(ZEN_C_ENDPOINT + `/myzen/cards/applyCardVoucher/`+code,{
        username:username,
        card:cardKey
      })
        .then(res => {
          setSearchPending(false);
        }).catch(() => {
          setSearchPending(false);
        });
    } else {
      await axios.post(ZEN_C_ENDPOINT + `/myzen/icons/addZenUserIcon`,{
        username:username,
        card:cardKey
      })
        .then(res => {
          setSearchPending(false);
        }).catch(() => {
          setSearchPending(false);
        });
    }
  } 

  const checkForMilestone = (milestoneKey:string) => {
    console.log("checking icons");
    console.log("returning: "+(userIcons != null && userIcons.length > 0 && userIcons.map(ui => ui.card).includes(milestoneKey)));
    return userIcons != null && userIcons.length > 0 && userIcons.map(ui => ui.card).includes(milestoneKey);
  }

  const claimMilestone = async (milestoneKey:string) => {
    console.log("claiming milestone");
    if(auth.isAuthenticated && auth.username != null){
      setSearchPending(true);
      return await axios.get(ZEN_C_ENDPOINT+`/myzen/milestones/claimExplorationMilestone/`+auth.username+`/`+milestoneKey,
      )
        .then(res => {
          setSearchPending(false);
          getZenUserIcons();
          return res.data;
        }).catch(() => {
          setSearchPending(false);
          return null;
        });
    } else {
      setSearchPending(false);
      return null
    };
  }
  
  if (getUserPOrtalTries > 0 && userIcons == null && !searchPending) {
    setGetUserPortalTries(getUserPOrtalTries - 1);
    getZenUserIcons();
  } 

  return {
    getZenCards,
    searchPending,
    zenCardsResults,
    userIcons,
    claimZenCard,
    filterZenCards,
    resultType,
    claimMilestone,
    checkForMilestone,
    searchZenCards
  }
}