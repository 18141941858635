import * as React from "react";
import "../../../styling/common/index.scss";
import "../../../styling/explore/explore.scss";
import { useState } from "react";
import axios from "axios";
import { getContentListPreview } from "../../../service/ContentService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from '@fortawesome/free-solid-svg-icons';
import { faVideo } from '@fortawesome/free-solid-svg-icons';
import { faPodcast } from '@fortawesome/free-solid-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

export const PractitionerContentReccomendation = (props: any) => {

      const move = (direction: string) => {
            props.changeLessonOrder(props.lessonNumber, direction);
      }

      return (
            <div className="external-content-recc">
                  {props.changeLessonOrder ?
                        <div style={{ float:"left", marginTop: "25px", marginLeft:"10px"}}>
                              <div className="movePracticeIcon movePracticeUp" onClick={() => props.changeLessonOrder(props.lessonNumber, "UP")} style={{ marginBottom: "15px" }}></div>
                              <div className="movePracticeIcon movePracticeDown" onClick={() => props.changeLessonOrder(props.lessonNumber, "DOWN")}></div>
                        </div>
                        : null}
                  {props.changeLessonOrder ?
                        <div style={{ float:"right", marginTop: "5px", marginRight:"5px"}}>
                              <div className="deleteReccLink" onClick={() => props.removeRecc(props.lessonNumber-1)}></div>
                        </div>
                        : null}

                  <div style={{ display: "flex", flexDirection: "row", margin: "auto" }}>

                        <div className="content-recc-type-icon">
                        {props.type == "Video"?
                              <FontAwesomeIcon icon={faVideo} />
                              :props.type == "Podcast"?
                              <FontAwesomeIcon icon={faPodcast} />
                              :props.type == "Website"?
                              <FontAwesomeIcon icon={faGlobe} />
                              :
                              <FontAwesomeIcon icon={faBook} />
                  }
                        </div>
                        <div className="external-content-recc-details">
                              <h3>{props.title}</h3>
                              {props.hasBeenSaved ?
                                    <div style={{ display: "flex", flexDirection: "row", margin: "auto" }}>
                                          <div className="zenGlowButton mingGlowbutton" onClick = {() => props.showReview(props.lessonNumber)}>Read review</div>
                                          <div className="zenGlowButton sageGlowbutton"  onClick = {() => {
                      window.open(props.link.includes('https://')? props.link : 'https://'+props.link, '_blank');
                      }
                    }>Check it out</div>
                                    </div>
                                    : null}
                        </div>
                  </div>
                  
            </div>
      )
}