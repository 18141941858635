import * as React from "react";
import "../../styling/zenverse/Zenverse.scss";
import "../../styling/common/zenCoreUIComponents.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // req
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Navigate, useNavigate, useParams } from "react-router";
import { ZencyclopaediaItemPreview } from "../explore/previews/ZencyclopaediaItemPreview";
import { Link } from "react-router-dom";
import { ZencycyclopaediaPreviewGroup } from "../explore/ZencycyclopaediaPreviewGroup";
import PrivateRoute from "../common/PrivateRoute";
import { ZenNavBar } from "../common/navbar/ZenNavBar";
import { ZencyclopaediaTextItemPreview } from "../explore/previews/ZencyclopaediaTextItemPreview";
import { useZenverseService } from "../../hooks/ZenverseContext";
import { getPortalJSX } from "../../service/ZenverseService";
import { Configuration, OpenAIApi } from 'openai';
import Chatbot from "react-chatbot-kit";
import { ZencTitle } from "../home/ZencTitle";
import { MyZenNavBar } from "../common/navbar/MyZenNavBar";
import { useMyZenService } from "../../hooks/ZenUserContext";
import { useAuth } from "../../hooks/auth";
import { useZenQuotasService } from "../../hooks/ZenQuotasContext";

export const AccountDetails = () => {

    let myZen =  useMyZenService();
    let auth =  useAuth();
    let quotasContext = useZenQuotasService();



  return myZen.profile? (
    <PrivateRoute>
    <div className="ZenBasicPage">
    <ZenNavBar/>
    <MyZenNavBar/>

      <h2 className="" style = {{marginTop:"135px"}}><b>Username:</b> {myZen.profile.username}</h2>
      <h2 className=""><b>Member Since: </b>{myZen.profile.memberSince}</h2>
      <Link to="/resetpassword">
      <div className = "zenGlowButton navajoGlowButton">Change password</div>
      </Link>
      <Link to="/myzen/mysubscription">
      <div className = "zenGlowButton mingGlowButton">View subscription details</div>
      </Link>

    </div>
    </PrivateRoute>
  ) : <Navigate to = "/home"></Navigate>;

}
