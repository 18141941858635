export const ExploreTranslations = {
  Catagories: {
    Meditations : { 
      English : 'Meditations',
      Spanish : 'Meditaciones'
    },Courses : { 
      English : 'Courses',
      Spanish : 'Cursos'
    },Communities : { 
      English : 'Communities',
      Spanish : 'Comunidades'
    },ZencyclopaediaInfomation : { 
      English : 'Zencyclopaedia (Information)',
      Spanish : 'Zenciclopaedia (información)'
    },ZenPortals : { 
      English : 'Zen portals (virtual escapes)',
      Spanish : 'Portales Zen (Escapadas virtuales)'
    },Shop : { 
      English : 'Shop',
      Spanish : 'Tienda'
    }
  },
  ContentFilters: {
    Top : {
      English : 'Top',
      Spanish : 'Top'
    },
    New : {
      English : 'New',
      Spanish : 'Nuevo'
    },
    Favourite: {
      English : 'Favourites',
      Spanish : 'Favoritos'
    },
    Anxiety: {
      English : 'Anxiety',
      Spanish : 'Ansiedad'
    },
    Depression: {
      English : 'Depression',
      Spanish : 'Depression'
    },
    Relaxation: {
      English : 'Relaxation',
      Spanish : 'Relajación'
    }
  }
}




// Zen schools = escuelas de zen 
// people = personas 
// Places = sitios 

// Within virtual escapes

// All = todos
// My portals = mis portales 
// New= nuevo 
// Recents= recientes 
// Recommended = recomendaciones 
// Top = top 
// Breathing aids =  ayudas para la respiración
// Music = musica 
// City soundscapes = sonidos de ciudad 
// Solar punk= solar punk 
// Nature escapes = escapadas en la naturaleza 
// training portals = portales de practica 