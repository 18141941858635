import * as React from "react";
import "../../styling/common/index.scss";
import "../../styling/common/zenCoreUIComponents.scss";
import "../../styling/myzen/myzen.scss";
import { ZenNavBar } from "../common/navbar/ZenNavBar";
import { useState } from "react";
import { useZenExploreService } from "../../hooks/ZenExploreContext";
import axios from "axios";
import { ZEN_C_ENDPOINT } from "../../config/EnvironmentalVariables";
import { useAuth } from "../../hooks/auth";
import { PathPreview } from "../zencontent/courses/PathPreview";
import { useNavigate, useParams } from "react-router";
import { PuffLoader } from "react-spinners";
import { getContentCatagories } from "../../service/ZencyclopaediaService";
import { ZenPopUpMessage } from "../common/ZenPopUpMessage";
import { ZenSearchFilterTagsDisplay } from "./search/ZenSearchFilterTagsDisplay";
import { ZenSearchBar } from "./search/ZenSearchBar";
import { useZenSearchService } from "../../hooks/ZenSearchContext";
import Slider from "react-slick";
import { ExploreSliderPreview } from "./previews/ExploreSliderPreview";
import { ZencyclopaediaTextItemPreview } from "./previews/ZencyclopaediaTextItemPreview";
import { ZencycyclopaediaPreviewGroup } from "./ZencycyclopaediaPreviewGroup";

export const MeditationsCarousel = () => {

      const sliderRef: any = React.createRef();
      const desktopSliderRef: any = React.createRef();


      const generateCarouselInner = (carouselRef:any) => {
            return [
                  <div style={{ margin: "auto" }}>
                        <div className="zenscape-slider-item explore-slider-item meditation-slider-item" onClick={() => {
                              carouselRef.current.slickGoTo(1);
                        }}>
                              <h1 className="explore-slider-intro-title">Embark on a transformative journey of self-discovery and inner peace with our diverse range of guided meditations!</h1>
                              <h4 className="explore-slider-description explore-slider-description-intro" style = {{fontSize:"90%"}}>Find our top user rated practices in the carousel - or use the search bar to look for something more specific</h4>
                        </div>
                  </div>,
                  <ExploreSliderPreview itemType = {"med"}index={0}
                        description={"A range of practices's designed for those new to meditation, providing foundational techniques and guidance."}
                        photoBottom={true}
                        title='Beginner'
                        searchRoute={"/explore/meditations/beginner"} image={require("../../static/photos/exploreIcons/BeginnerMedder.png")}></ExploreSliderPreview>,
                  <ExploreSliderPreview itemType = {"med"}index={1}
                        description={"Discover meditation techniques that can support mental well-being and alleviate symptoms of depression."}
                        photoBottom={true} title='Depression' searchRoute={"/explore/meditations/depression"} image={require("../../static/photos/exploreIcons/courses/Masks.png")}></ExploreSliderPreview>,
                  <ExploreSliderPreview itemType = {"med"}index={2}
                        photoBottom={true}
                        description={"Learn meditation practices specifically aimed at managing and reducing anxiety levels."}
                        title='Anxiety' searchRoute={"/explore/meditations/anxiety"} image={require("../../static/photos/exploreIcons/courses/Anxiety.png")}></ExploreSliderPreview>,
                  <ExploreSliderPreview itemType = {"med"}index={3}
                        description={"Experience deep relaxation through various meditation methods, promoting inner calm and stress relief."}
                        photoBottom={true} title='Relaxation' searchRoute={"/explore/meditations/relax"} image={require("../../static/photos/mainGroups/relaxation.png")}></ExploreSliderPreview>,
                  <ExploreSliderPreview itemType = {"med"}index={4}
                        image={require("../../static/photos/exploreIcons/blowingOut.png")}
                        description={"Explore the transformative power of conscious breathing exercises and their benefits in meditation."}
                        title='Breathing' searchRoute={"/explore/meditations/breathwork"} colorClass={'ZenCGradient1'}></ExploreSliderPreview>,
                  <ExploreSliderPreview itemType = {"med"}index={5}
                        image={require("../../static/photos/exploreIcons/courses/Vipassana.png")}
                        description={"Engage in short and focused meditation sessions to fit into busy schedules and find moments of tranquility throughout the day."}
                        title='Shorts' searchRoute={"/explore/meditations/shorts"} colorClass={'ZenCGradient1'}></ExploreSliderPreview>,
                  <ExploreSliderPreview itemType = {"med"}index={6}
                        image={require("../../static/photos/exploreIcons/meditations/WomanLeaping.png")}
                        description={"Combine meditation with gentle movements and postures to cultivate mindfulness in motion."}
                        title='Movement' searchRoute={"/explore/meditations/move"} colorClass={'ZenCGradient3'}></ExploreSliderPreview>,
                  <ExploreSliderPreview itemType = {"med"}index={7}
                        image={require("../../static/photos/exploreIcons/courses/buddha.png")}
                        description={"Deepen your understanding of meditation within the context of Buddhist principles and teachings."}
                        title='Buddhism' searchRoute={"/explore/meditations/buddhism"} colorClass={'ZenCGradient3'}></ExploreSliderPreview>,
                  <ExploreSliderPreview itemType = {"med"}index={8}
                        image={require("../../static/photos/exploreIcons/ZenTeacher.png")}
                        description={"Delve into the philosophical and spiritual aspects of meditation through teachings and insightful discussions."}
                        title='Teachings' searchRoute={"/explore/meditations/teachings"} colorClass={'ZenCGradient1'}></ExploreSliderPreview>,
                  <ExploreSliderPreview itemType = {"med"}index={11}
                        description={"A range of course's designed for those new to meditation, providing foundational techniques and guidance."}
                        photoBottom={true}
                        title='Transcendental'
                        searchRoute={"/explore/meditations/transcendental"} 
                        image={require("../../static/photos/exploreIcons/meditations/Transcendental.png")}></ExploreSliderPreview>,
                  <ExploreSliderPreview itemType = {"med"}index={10}
                        image={require("../../static/photos/exploreIcons/courses/happy-sun.png")}
                        description={" Start your day with mindful meditation practices to set a positive and centered tone for the rest of the day."}
                        title='Morning' searchRoute={"/explore/meditations/morning"} colorClass={'ZenCGradient3'}></ExploreSliderPreview>,
                        <ExploreSliderPreview itemType = {"med"}index={9}
                        image={require("../../static/photos/exploreIcons/SleepingBuddha2.png")}
                        description={"Learn meditation techniques specifically tailored to promote deep relaxation and improve the quality of sleep."}
                        title='Sleep' searchRoute={"/explore/meditations/sleep"} colorClass={'ZenCGradient1'}></ExploreSliderPreview>,
                  <ExploreSliderPreview itemType = {"med"}index={12}
                        description={"A range of course's designed for those new to meditation, providing foundational techniques and guidance."}
                        photoBottom={true}
                        title='AI Guided Meditations'
                        searchRoute={"/explore/meditations/beginner"} image={require("../../static/photos/exploreIcons/ZenBotRobot.png")}></ExploreSliderPreview>
                  
                  ];
      }

      const mainGroups = [];

  
  
      //mainGroups.push(<ZencyclopaediaTextItemPreview photoBottom = {true}title = 'All' searchRoute="/explore/meditations/all" image = {require("../static/photos/mainGroups/med_cat_frog.png")} colorClass={'ZenCGradient4'}></ZencyclopaediaTextItemPreview>);
      mainGroups.push(<ZencyclopaediaTextItemPreview photoBottom = {true}title = 'Top' searchRoute="/explore/meditations/top" colorClass={'ZenCGradient1'}></ZencyclopaediaTextItemPreview>);
      mainGroups.push(<ZencyclopaediaTextItemPreview photoBottom = {true}title = 'New' searchRoute="/explore/meditations/new" colorClass={'ZenCGradient3'}></ZencyclopaediaTextItemPreview>);
      mainGroups.push(<ZencyclopaediaTextItemPreview photoBottom = {true}title = 'Favourites' searchRoute="/favourites" colorClass={'ZenCGradient2'}></ZencyclopaediaTextItemPreview>);
    

      return (
            <div className="zenverseSliderHolder">
                  <ZencycyclopaediaPreviewGroup resultList = {mainGroups} />
                  <div className="zen-mobile">
                        <Slider
                              centerMode={true}
                              centerPadding='15%'
                              autoplay={true}
                              ref={sliderRef}
                              dots={true}
                              infinite={true}
                        >
                              {generateCarouselInner(sliderRef)}
                        </Slider>
                  </div>
                  <div className="zen-desktop">
                        <Slider
                              centerMode={true} 
                              centerPadding='35%'
                              autoplay={true}
                              ref={desktopSliderRef}
                              dots={true}
                              infinite={true}
                        >
                        {generateCarouselInner(desktopSliderRef)}
                        </Slider>
                  </div>
            </div>
      )
}