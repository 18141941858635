import axios from "axios";
import React, { createContext, useContext, useState } from "react";
import { getAllPowerCodes } from "../components/explore/icons/CardSubtypes";
import ZenCardInterface from "../components/explore/icons/ZenCardInterface";
import { ZEN_C_ENDPOINT } from "../config/EnvironmentalVariables";
import { useAuth } from "./auth";
import { PractitionerPreview } from "../components/explore/communities/PractitionerPreview";

interface UseMyCommunity {
  myCommunityResults: any [],
  checkIfUserFollowsPractitioner:any, 
  followPractitioner:any,
  getPractitionerReccomendations:any
}

type Props = {
  children?: React.ReactNode;
};
const myCommunityContext = createContext({} as UseMyCommunity);

export const ProvideMyCommunityContext: React.FC<Props> = ({ children }) => {
  const myCommunityService = MyCommunityService();
  return <myCommunityContext.Provider value={myCommunityService}>{children}</myCommunityContext.Provider>;
};

export const useMyCommunityService = () => {
  return useContext(myCommunityContext);
};

export const MyCommunityService = () => {
  const auth = useAuth();

  const [myCommunityResults, setMyCommunityResults] = useState(null);
  const [searchPending, setSearchPending] = useState(false);
  const [getTries, setGetTries] = useState(0);


  const getUserCommunityDetails = async () => {
    setSearchPending(true);
    await axios.get(ZEN_C_ENDPOINT + `/myzen/communities/`+auth.username)
      .then(res => {
        setMyCommunityResults(res.data);
        setSearchPending(false);
      }).catch(() => {
        setSearchPending(false);
      });
  }

  const sendPractitionerJoinRequest = (practitionerName:string, communityId:string) => {
        
  }

  const getPractitionerReccomendations = async () => {
    console.log("polling for practitioenr reccomendations...");
    let result = await axios.get(ZEN_C_ENDPOINT + `/myzen/communities/getReccomendations/`+auth.username)
      .then(res => {
        return res.data;
      }).catch(() => {
        return null;
      });

      return result;
  }


  const checkIfUserFollowsPractitioner = (practitionerId:string) => {
    console.log("checking if we follow: "+practitionerId);
    console.log("follow list: ");
    console.log(myCommunityResults);
    return myCommunityResults != null && myCommunityResults.length > 0?
     myCommunityResults.map(cr => cr.practitionerId).includes(practitionerId) : false;
  }

  const followPractitioner = async (practitioner:string) => {
    await axios.post(ZEN_C_ENDPOINT + `/myzen/communities/create`,
    {
      userId:auth.username,
      practitionerId:practitioner
    })
    .then(res => {
      setMyCommunityResults(res.data);
      setSearchPending(false);
      getUserCommunityDetails();
    }).catch(() => {
      setSearchPending(false);
    });
  }

  // const unfollowPractitioner = async (practitioner:string) => {
  //   await axios.post(ZEN_C_ENDPOINT + `/myzen/communities/create`,
  //   {
  //     userId:auth.username,
  //     practitionerId:practitioner
  //   })
  //   .then(res => {
  //     setMyCommunityResults(res.data);
  //     setSearchPending(false);
  //   }).catch(() => {
  //     setSearchPending(false);
  //   });
  // }


  if(myCommunityResults == null && !searchPending && getTries<=4){
    setGetTries(getTries+1);
    getUserCommunityDetails();
  }

  return {
    myCommunityResults,
    checkIfUserFollowsPractitioner,
    followPractitioner,
    getPractitionerReccomendations
  }
}