import * as React from "react";
import "../../../../styling/zenverse/Zenverse.scss";
import OhmSoundInterface from "./OhmSoundInterface";
import ReactSlider from "react-slider";
import { useState } from "react";
import { ZEN_C_ENDPOINT } from "../../../../config/EnvironmentalVariables";

export const OhmSound = (props: OhmSoundInterface) => {

    const [volume, setVolume] = useState(50);
    const [soundBuffer, setSoundBuffer] = useState(null);
    const [soundLoading, setLoading] = useState(null);
    const [soundSource, setSoundSource] = useState(null);
    const soundSourceRef = React.useRef<AudioBufferSourceNode>();

    var thumbClassName = props.title == "OrangeOhm" ? "ohm-thumb orange-ohm-thumb"
        : props.title == "PurpleOhm" ? "ohm-thumb purple-ohm-thumb"
            : props.title == "BlueOhm" ? "ohm-thumb blue-ohm-thumb"
                : props.title == "Ascend" ? "ohm-thumb ascend-thumb"
                    : props.title == "kathakaku" ? "ohm-thumb kathakaku-thumb"
                        : "ohm-thumb chakra-thumb";

    React.useEffect(() => {
        return () => {
            if(props && props.close != null){
                props.close();
            }
            
            if (soundSourceRef.current != null) {
                soundSourceRef.current.stop(0);
            }
        }
    }, [])

    const getZenSound = async () => {
        setLoading(true);
        let soundRequest = new XMLHttpRequest();
        soundRequest.open('GET', ZEN_C_ENDPOINT + `/zen/ohmMedia/ohmSounds/stream/` + props.title, true);
        soundRequest.responseType = 'arraybuffer';
        soundRequest.addEventListener('load', () => {

            props.audioContext.decodeAudioData(soundRequest.response).then((buffer) => {
                let source = props.audioContext.createBufferSource();

                source.buffer = buffer;
                source.loop = true;
                source.connect(props.audioContext.destination);
                if (!props.closed) {
                    source.start(0);
                }
                soundSourceRef.current = source;
                setSoundBuffer(buffer);
                setSoundSource(source);
                setLoading(false);
                //check.disabled = false;
            });

        });
        soundRequest.send();
    }

    const updateVolume = (vol: number) => {
        soundSource.stop(0); // this destroys the buffer source
        if (!props.closed) {
            let newSource = props.audioContext.createBufferSource();
            newSource.buffer = soundBuffer;
            newSource.loop = true;

            var gainNode = props.audioContext.createGain(); // Create a gainNode reference.
            gainNode.connect(props.audioContext.destination); // Add context to gainNode
            newSource.connect(gainNode)  //Connecting gain to source
            //gainNode.gain.setValueAtTime(vol/100, props.audioContext.currentTime);
            gainNode.gain.value = vol / 100;// vol/100;
            newSource.start();
            setSoundSource(newSource);
            setVolume(vol);
        }
    }

    if (soundSource == null && !soundLoading) {
        getZenSound();
    }

    var fixAudioContext = function (e) {
        if (props.audioContext) {
            // Create empty buffer
            var buffer = props.audioContext.createBuffer(1, 1, 22050);
            var source = props.audioContext.createBufferSource();
            source.buffer = buffer;
            // Connect to output (speakers)
            source.connect(props.audioContext.destination);
            // Play sound
            if (!props.closed && source.start) {
                source.start(0);
            }
            // else if (source.play) {
            //   source.play();
            // } else if (source.noteOn) {
            //   source.noteOn();
            // }
        }
        // Remove events
        document.removeEventListener('touchstart', fixAudioContext);
        document.removeEventListener('touchend', fixAudioContext);
    };
    // iOS 6-8
    document.addEventListener('touchstart', fixAudioContext);
    // iOS 9
    document.addEventListener('touchend', fixAudioContext);

    return (
        <div className="slider-holder">
            {/* <audio ref={soundRef} autoPlay={true}>
            <source src={props.audio.default} />
            Your browser does not support the <code>audio</code> element.
            </audio>
            */}
            {soundLoading || soundLoading == null ?
                <div>
                    <div className="chakra-thumb"></div>
                    <h3>LOADING</h3>
                </div>
                :
                <ReactSlider
                    className="horizontal-slider"
                    thumbClassName={thumbClassName}
                    trackClassName="ohm-track"
                    onAfterChange={(value) => updateVolume(value)}
                    value={volume}
                    renderThumb={(props, state) => <div {...props}></div>}
                />
            }


        </div>
    );
}

            // let context = window.AudioContext;

            //             .then(resp => resp.arrayBuffer())
            //       .then(buf => aCtx.decodeAudioData(buf)) // can be callback as well
            //       .then(decoded => {
            //         source.buffer = buf = decoded;
            //         source.loop = true;

            //         source.connect(gainNode);   //Connecting gain to source
            //         gainNode.gain.value = 1;  // 100% VOLUME RANGE OF VALUE IS 0-1

            //         check.disabled = false;
            //       });


            //    check.onchange = e => {
            //       if (check.checked) {
            //         source.start(0); // start our bufferSource
            //       } else {
            //         source.stop(0); // this destroys the buffer source
            //         source = aCtx.createBufferSource(); // so we need to create a new one
            //         source.buffer = buf;
            //         source.loop = true;

            //         source.connect(gainNode);   //Connecting gain to source
            //         gainNode.gain.value = 0.1;  // 10% VOLUME RANGE OF VALUE IS 0-1
            //       }
            //     };
