import React, { useState } from 'react';
import "../../../styling/stream/stream.scss";
import "../../../styling/common/index.scss";
import { Link } from 'react-router-dom';
import ZenContentInterface from '../practices/ZenContentInterface';
import { faVideo } from '@fortawesome/free-solid-svg-icons';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PractitionerInterface from '../../practitioner/PractitionerInterface';
import axios from 'axios';
import { ZEN_C_ENDPOINT } from '../../../config/EnvironmentalVariables';
import PracticeInterface from '../practices/PracticeInterface';
import ReactPlayer from 'react-player';

interface ReelInterface extends ZenContentInterface {
  streamItem?: boolean;
  colourCode?:any;
}
export const ReelStreamReelItem = (props: ReelInterface) => {


  const [practitioner, setPracitioner] = useState(null as PractitionerInterface);
  const [getTries, setGetTries] = useState(0);
  const [searchPending, setSearchPending] = useState(null);
  const [playReel, setPlayReel] = useState(false);

  const getPractitionerInfo = (username: string) => {
    setSearchPending(true);
    axios.get(ZEN_C_ENDPOINT + `/myzen/practitioner/` + username)
      .then(res => {
        console.log("RES:");
        console.log(res);
        if (res.data != null) {
          console.log("setting PR");
          setPracitioner(res.data);
        }
      })

  }

  const [med, setMed] = useState(null);

  if (med == null) {
    getMeditation(props.id);
  }

  function getMeditation(id: any): any {
    axios.get(ZEN_C_ENDPOINT + `/zen/meditations/` + id)
      .then(res => {
        const practiceData: any = res.data;
        console.log(practiceData);
        setMed(practiceData);
      })
  }


  if (practitioner == null && props.practitioner != null && getTries < 5 && !searchPending) {
    setGetTries(getTries + 1);
    getPractitionerInfo(props.practitioner);
  }

  console.log("Colour Code: "+props.colourCode);
  var colourClass = props.colourCode && props.colourCode == 0? "sageReel " 
  : props.colourCode && props.colourCode == 1? "blueReel " 
  : props.colourCode && props.colourCode == 2? "mingReel "
  : props.colourCode && props.colourCode == 3? "buddReel ": "sageReel ";
  
  console.log("Colour Class: "+colourClass);


  return (
    <div className={colourClass? colourClass + "reel-item" : "reel-item"}>
      <div>
        {!playReel ?
          <><div className="reel-stream-reel-practitioner">
            <h1 className="reel-stream-title zenK">{props.title}</h1>
            <h4 style={{ margin: "auto", textAlign: "center", fontSize:"60%" }}>
              by&nbsp;{practitioner ? practitioner.username : 'ZENcyclopedia'}
            </h4>
          </div>
            <div className='zen-reel'>

              <div className="reel-stream-reel-image-holder" onClick={() => setPlayReel(true)}>

                {/* {props.streamItem ? null :
                  <FontAwesomeIcon icon={faPlay} className='streamReelPlayIcon' />} */}

                <img className="reel-stream-reel-image" src={props.image} >
                </img>
                <div>

                </div>

              </div>

            </div></>
            :med!= null?
            <div>
              <ReactPlayer playing={true} autoPlay={true} controls={true} onEnded={() => setPlayReel(false)} loop={false} url={ZEN_C_ENDPOINT + `/zen/meditations/streamMedia/` + med.id} width='100%' onPause={() => setPlayReel(false)} setPlaying={(playing) => setPlayReel(playing)} />
            </div>
          : null}
      </div>
    </div>
  );
}