import * as React from "react";
import '../../styling/common/index.scss';
import '../../styling/ScrollHint.scss';
import { useState } from "react";

export const ScrollDownHint = () => {

  const [showHint, setShowHint] = useState(false);

  if (!showHint) {
    setTimeout(() => { setShowHint(true) }, 10000);
  }

  if (showHint) {
    setTimeout(() => { setShowHint(false) }, 4000);
  }
  return (
    <React.Fragment>
      {showHint ?
        <div className="scroll-hint-p-holder">
        <div className="scroll-hint-holder">
          <div className="scroll-container">
            <div className="scroller"></div>
          </div>
          <h3 className="scroll-down-hint-text">Scroll Down To Learn More!</h3>
        </div>
        </div>
        :null}
    </React.Fragment>
  );
}




