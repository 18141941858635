import React, { useState } from 'react';
import "../../../styling/vacana/vacana.scss";
import "../../../styling/common/index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPause } from '@fortawesome/free-solid-svg-icons';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@mui/material/styles';
import ZenMediaInterface from './ZenMediaInterface';

export const EnsoPlayer= (props:ZenMediaInterface) => {

    const [viewPause, setViewPause] = useState(false);

    const [ohmActive, setOhmActive] = useState(true);
   
    var remTime = props.duration && props.curTime? props.duration - props.curTime : 0;

    var date = new Date(0);
    date.setSeconds(remTime); // specify value for SECONDS here
    var timeString = remTime==0? '-' : date.toISOString().substr(11, 8);
    timeString = timeString.substring(0,2)=='00'? timeString.substring(3) : timeString;

    const theme = useTheme();

    const checkForValidTimeString = (time_string:string) => {
        return time_string && time_string != "-" && time_string.length > 0;
    }

    const checkZenscapeActive = (portal:string) => {
        if (portal == null) return false;
        else if (portal.includes("zenscape")) return true;
        else return false;
    }

    return (
      <div className='enzo-timer-holder'>

      <div className="enso-timer" onMouseEnter={() => setViewPause(true)} onMouseLeave={() => setViewPause(false)}>
        

        {checkZenscapeActive(props.activePortal)?
        
        <div className="zenscapeControlIcon zenscape-back-icon"onClick={() => props.setPlaying(props.playing)}></div>
        
        :props.playing? 
     
            <div className = 'audio-timer-playing' >
                {viewPause || !checkForValidTimeString(timeString)?
                        <FontAwesomeIcon icon={faPause} className = "audio-pause-icon" onClick={() => props.setPlaying(!props.playing)}/>
                    :
                    <h1 className ='audio-time-rem zenK'>{timeString}TS</h1>
                }
            </div>
            

            
         :
          <div className = 'audio-timer-playing' >
                {viewPause || !checkForValidTimeString(timeString)?
                    <FontAwesomeIcon icon={faPlay} className = "audio-play-icon" onClick={() => props.setPlaying(!props.playing)}/>
                    :
                    <h1 className ='audio-time-rem zenK'>{timeString}TS</h1>
                }
            </div>

        }
        

      
 

    </div>
  
  </div>
    );
}