import axios from "axios";
import React, { createContext, useContext, useState } from "react";
import ZenContentInterface from "../components/zencontent/practices/ZenContentInterface";
import { ZEN_C_ENDPOINT } from "../config/EnvironmentalVariables";
import { getZencyclopaediaMainGroups } from "../service/ZencyclopaediaService";
import { useAuth } from "./auth";

interface UseZenExplore {

  contentItems: ZenContentInterface [],
  content: ZenContentInterface,
  searchType: string,
  resultStatus: string,
  searchInfomatics : (id:string) => void,
  getZencyclopaedia : () => void,
  getMainGroups : () => void,
  searchMeditationsByTag: (search:string, tags?:string []) => void,
  searchCoursesByTag: (search:string, tags?:string []) => void,
  getAlLCourses: () => void,
  getUserCourses: () => void,
  searchPending:boolean
}

type Props = {
  children?: React.ReactNode;
};
const zenExploreContext = createContext({} as UseZenExplore);

export const ProvidezenExploreContext: React.FC<Props> = ({ children }) => {
    const zenExploreService = ZenExploreService();
    return <zenExploreContext.Provider value ={zenExploreService}>{children}</zenExploreContext.Provider>;
};

export const useZenExploreService = () => {
    return useContext(zenExploreContext);
};

export const ZenExploreService = () => {
    const auth = useAuth();

    const [contentItems, setContentItems] = useState(null);
    const [content, setContent] = useState(null);
    const [searchPending, setSearchPending] = useState(false);
    const [searchType, setSearchType] = useState<string>();
    const [resultStatus, setResultStatus] = useState<string>();

    const getMainGroups = () =>  {
      setContentItems(getZencyclopaediaMainGroups());
      setSearchType("main");
    }

    const getZencyclopaedia = () =>  {
      setSearchPending(true);
      axios.get(ZEN_C_ENDPOINT+`/zen/infomatics/readContent`)
      .then(res => {
        if(res.data.length >0 ){
          setSearchType("articles");
          setResultStatus("success");
          setContentItems(res.data);
          setSearchPending(false);
        } else {
          setContentItems([]);
          setSearchType("articles");
          setSearchPending(false);
        }
      }).catch(() => {
        setSearchPending(false);
      })
  }

  const searchInfomatics = (id:string) =>  {
    setSearchPending(true);
    axios.get(ZEN_C_ENDPOINT+`/zen/infomatics/groups/`+id)
    .then(res => {
      if(res.data){
        setContent(res.data);
        setSearchType(id);
        setSearchPending(false);
      } else {
        setContent(null);
        setSearchType(id);
        setSearchPending(false);
      }
    }).catch(() => {
      setSearchPending(false);
    })
}

const searchMeditationsByTag = (search:string, tags?:any []) =>  {
  console.log("searching meditations by tag");
  let searchTag = search? search : tags && tags.length>0? tags[0] : null;
  setSearchPending(true);

  var userN = auth.username != null && auth.username.length > 0? auth.username : "anon";
    
  var searchPath = searchTag == "top"? ZEN_C_ENDPOINT+`/zen/content/topContent/`+userN +`/MEDITATION/` 
    : searchTag == "new"? ZEN_C_ENDPOINT+`/zen/content/newContent/`+userN +`/MEDITATION/` 
    : ZEN_C_ENDPOINT+`/zen/content/filterContent/`+userN +`/MEDITATION/`+searchTag;
    
    axios.get(searchPath)
    .then(res => {
      if(res.data){
        setContentItems(res.data);
        setSearchType(search+tags.join(","));
        setSearchPending(false);
        setResultStatus("success");
      } else {
        console.log("A");
        setContentItems(null);
        setSearchType(search+tags.join(","));
        setSearchPending(false);
      }
    }).catch(() => {
      console.log("B");
      setSearchPending(false);
      setSearchType(search+tags.join(",")+"-FAIL");
    })
}

const searchCoursesByTag = (search:string, tags?:string []) =>  {
  let searchTag = search? search : tags && tags.length>0? tags[0] : null;
  console.log("SEARCHING COURSES")

  setSearchPending(true);

  var searchPath = searchTag == "top"? ZEN_C_ENDPOINT+`/zen/content/topContent/`+auth.username != null? auth.username : "anon"+`/PATH/` 
  : searchTag == "hot"? ZEN_C_ENDPOINT+`/zen/content/topContent/`+auth.username != null? auth.username : "anon"+`/PATH/` 
  : searchTag == "new"? ZEN_C_ENDPOINT+`/zen/content/newContent/`+auth.username != null? auth.username : "anon"+`/PATH/` 
    : ZEN_C_ENDPOINT+`/zen/content/filterContent/`+auth.username != null? auth.username : "anon"+`/PATH/`+searchTag;

  axios.get(searchPath)
  .then(res => {
    if(res.data){
      setContentItems(res.data);
      setSearchType(search+tags.join(","));
      setSearchPending(false);
      setResultStatus("success");
    } else {
      setSearchType(search+tags.join(","));
      setSearchPending(false);
    }
  }).catch(() => {
    setSearchType(search+tags.join(",")+"-FAIL");
    setSearchPending(false);
  })
}

const getAlLCourses = () =>  {
  setSearchPending(true);
  axios.get(ZEN_C_ENDPOINT+`/zen/paths/read/`)
  .then(res => {
    if(res.data){
      setContentItems(res.data);
      setSearchType("courses");
      setSearchPending(false);
      setResultStatus("success");
    } else {
      setContentItems(null);
      setSearchType("courses");
      setSearchPending(false);
    }
  }).catch(() => {
    setSearchPending(false);
  })
}

const getUserCourses = () =>  {
  setSearchPending(true);
  
  //Get User Path Ids From My Zen Service.
  axios.get(ZEN_C_ENDPOINT+`/zen/paths/read/`)
  .then(res => {
    if(res.data){
      setContentItems(res.data);
      setSearchType("mycourses");
      setSearchPending(false);
      setResultStatus("success");
    } else {
      setContentItems([]);
      setSearchType("mycourses");
      setSearchPending(false);
    }
  }).catch(() => {
    setSearchPending(false);
  })
}
  
  
    if(contentItems == null){
        getMainGroups();
    }

    return {
        contentItems,
        searchType,
        searchInfomatics,
        getZencyclopaedia,
        content,
        getMainGroups,
        searchMeditationsByTag,
        searchCoursesByTag,
        getAlLCourses,
        searchPending,
        getUserCourses,
        resultStatus
    };

}