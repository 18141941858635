import React, { useRef, useState } from "react";
import axios from "axios";

interface BrowseLocalFilesButtonProps {
  accept: string;
  onFileSelect: (files: FileList | null) => void;
  title?:string;
}

const BrowseLocalFilesButton: React.FC<BrowseLocalFilesButtonProps> = ({ accept, onFileSelect, title }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileSelect = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    onFileSelect(files);
    // Clear the file input value to allow selecting the same file again (if needed)
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <div style={{margin:"auto"}}>
      <input
        type="file"
        accept={accept}
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleInputChange}
        
      />
      <div className="zenGlowButton navajoGlowButton" onClick={handleFileSelect}>{title? <>{title}</>:"Browse Files"}</div>
    </div>
  );
};

export default BrowseLocalFilesButton;
