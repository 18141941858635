import * as React from "react";
import "../../styling/zenverse/Zenverse.scss";
import Zenscape from "./interfaces/ZenscapeInterface";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { PortalIcon } from "./ZenscapePortal/PortalIcon";
import { ZenscapePortal } from "./ZenscapePortal/ZenscapePortal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { ZenverseSidebar } from "./ZenverseSidebar";
import { ZenShopIcon } from "./ZenShopIcon";
import { useNavigate, useParams } from "react-router";
import { ZenscapeSliderItem } from "../explore/previews/ZenscapeSliderItem";
import { Link } from "react-router-dom";
import { ZencycyclopaediaPreviewGroup } from "../explore/ZencycyclopaediaPreviewGroup";
import { ZenNavBar } from "../common/navbar/ZenNavBar";
import { ZencyclopaediaTextItemPreview } from "../explore/previews/ZencyclopaediaTextItemPreview";
import { useZenverseService } from "../../hooks/ZenverseContext";
import { getPortalJSX } from "../../service/ZenverseService";
import { ZenscapeDemoPortal } from "../practiceportal/ZenscapeDemoPortal";
import { useAuth } from "../../hooks/auth";
import { ZenBrowserSlider } from "./ZenBrowserSlider";

export const ZenscapeBrowser = () => {

  let navigate = useNavigate();

  const useZenverse = useZenverseService();
  const { searchId } = useParams();
  const [getMyPortalAttemps, setGetMyPortalAttempts] = React.useState(0);
  const [getPortalAttemps, setGetPortalAttempts] = React.useState(0);
  const [zenscapes, setZenscapes] = React.useState([]);

  let auth = useAuth();

  //Determine whether we need to query for new portals:
  if (auth.isAuthenticated && auth.username && searchId && (searchId == "myportals" || searchId == "favourites") && useZenverse.resultsName != "myportals" && getMyPortalAttemps < 5 && !useZenverse.searchPending) {
    setGetMyPortalAttempts(getMyPortalAttemps + 1);
    useZenverse.getUserPortals();

  } else if (searchId && searchId != "myportals" && searchId != "favourites" && useZenverse.resultsName != searchId && getPortalAttemps < 5 && !useZenverse.searchPending) {

    setGetPortalAttempts(getPortalAttemps + 1);
    useZenverse.searchPortalsByTag(searchId);
  }

  if (searchId && useZenverse.resultsName == "myportals" && useZenverse.resultPortals != null && useZenverse.resultPortals.length > 0 && getMyPortalAttemps > 0 && !useZenverse.searchPending) {

    setGetMyPortalAttempts(0);
  } else if (searchId && searchId != "myportals" && useZenverse.resultsName == searchId && useZenverse.resultPortals != null && getPortalAttemps > 0 && !useZenverse.searchPending) {

    setGetPortalAttempts(0);
  }

  if (searchId && searchId == "favourites" && useZenverse.favourites && zenscapes.length != useZenverse.favourites.length && !useZenverse.searchPending) {

    setZenscapes(useZenverse.favourites);
  } else if (searchId == "myportals" && useZenverse.myPortals && zenscapes.length != useZenverse.myPortals.length && !useZenverse.searchPending) {

    setZenscapes(useZenverse.myPortals);
  } else if (searchId != "myportals" && searchId != "favourites" && useZenverse.resultPortals && useZenverse.resultPortals.length > 0 && zenscapes.length != useZenverse.resultPortals.length && !useZenverse.searchPending) {

    setZenscapes(useZenverse.resultPortals);

  }

  return (
    <React.Fragment>
      <div className="zenverseBrowser">
        {/* <ZenNavBar primaryColor="#D1603D"></ZenNavBar> */}
        {searchId ?
          <ZenscapeDemoPortal zenscapes={zenscapes} />
          :
          <div>
            <ZenNavBar />
            <div className="zenverseFiltersHolder">
              <ZencycyclopaediaPreviewGroup resultList={
                [<ZencyclopaediaTextItemPreview key = {"xtip1"} photoBottom={true} title='My Portals' image={require("../../static/photos/mainGroups/med_cat_frog.png")} colorClass={'ZenCGradient4'} searchRoute='/zenverse/myportals'></ZencyclopaediaTextItemPreview>,
                <ZencyclopaediaTextItemPreview key = {"xtip2"} searchRoute='/zenverse/breathing' photoBottom={true} title='New' image={require("../../static/photos/mainGroups/med_cat_frog.png")} colorClass={'ZenCGradient6'}></ZencyclopaediaTextItemPreview>,
                <ZencyclopaediaTextItemPreview key = {"xtip3"} photoBottom={true} title='Favourites' searchRoute="/zenverse/myportals/favourites" image={require("../../static/photos/mainGroups/med_cat_frog.png")} colorClass={'ZenCGradient3'}></ZencyclopaediaTextItemPreview>,
                ]}>

              </ZencycyclopaediaPreviewGroup>
            </div>
            <ZenBrowserSlider/>
            </div>
        }
      </div>
    </React.Fragment>
  );

}
