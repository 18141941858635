import React, { useState } from 'react';
import "../../../styling/vacana/vacana.scss";
import "../../../styling/common/index.scss";
import "../../../styling/stream/stream.scss";
import { PathIcons } from './PathIcons';
import { useParams } from 'react-router-dom';
import axios from "axios";
import { ZenNavBar } from '../../common/navbar/ZenNavBar';
import { MyZenNavBar } from '../../common/navbar/MyZenNavBar';
import { PathCompletionMeter } from './PathCompletionMeter';
import { ZEN_C_ENDPOINT } from '../../../config/EnvironmentalVariables';
import { useAuth } from '../../../hooks/auth';
import { calculateTotalPracticeTime } from '../../../service/ContentService';
import PathInterface from './PathInterface';
import { PracticeListItem } from '../practices/PracticeListItem';
import { ProvideZenJournalContext } from '../../../hooks/ZenJournalContext';
import { useMyStuffService } from '../../../hooks/MyStuffContext';
import { ZenLoader } from '../../common/ZenLoader';
import { PracticePreview } from '../practices/previews/PracticePreview';

export const PathSummary = () => {

  const [path, setPath] = useState(null as PathInterface);
  const [getTries, setGetTries] = useState(0);
  const [getPathCompletionTries, setGetPathCompletionTries] = useState(0);
  const [pathCompletionRate, setPathCompletionRate] = useState(null);
  const [searchPending, setSearchPending] = useState(null);
  const { id } = useParams();
  const [practitioner, setPractitioner] = useState(null);
  const [activeItem, setActiveItem] = useState("Overview");
  const [userCourses, setUserCourses] = useState(null);
  const [getUserCoursesTries, setGetUserCoursesTries] = useState(0);
  const [pathAdded, setPathAdded] = useState(null);

  let auth = useAuth();

  
  const getPractitionerInfo = (username: string) => {
    setSearchPending(true);
    axios.get(ZEN_C_ENDPOINT + `/myzen/practitioner/` + username)
        .then(res => {
            if (res.data) {
              setPractitioner(res.data);
            }
        })

}

  const getCourse = (pathId: any) => {
    setSearchPending(true);
    axios.get(ZEN_C_ENDPOINT + `/zen/paths/` + pathId)
      .then(res => {
        if (res.data) {
          setPath(res.data);
          console.log("We Have: ");
          console.log(res.data);
          getPractitionerInfo(res.data.practitioner);
          setSearchPending(false);
        }
      })
  }

  const getCourseCompletion = () => {
    setSearchPending(true);
    axios.get(ZEN_C_ENDPOINT + `/myzen/myZenContent/pathCompletion/` + auth.username + `/` + id)
      .then(res => {
        setPathCompletionRate(res.data);
      })
  }

  if (path == null && id && getTries < 5 && !searchPending) {
    setGetTries(getTries + 1);
    getCourse(id);
  }

  if (path != null && pathCompletionRate == null && getPathCompletionTries < 5 && !searchPending) {
    setGetPathCompletionTries(getPathCompletionTries + 1);
    getCourseCompletion();
  }

  const myStuff = useMyStuffService();

  React.useEffect(() => {
    myStuff.logContentInteraction(id);
  }, []);

  const getUserCourses = () => {
    setSearchPending(true);
    //Get User Path Ids From My Zen Service.
    axios.get(ZEN_C_ENDPOINT + `/myzen/myZenContent/getUserPaths/` + auth.username)
      .then(res => {
        if (res.data) {
          setUserCourses(res.data);
          setSearchPending(false);
        } else {
          setUserCourses([]);
          setSearchPending(false);
        }
      }).catch(() => {
        setUserCourses([]);
        setSearchPending(false);
      })
  }

  if (path != null && userCourses == null && !searchPending && getUserCoursesTries < 3) {
    getUserCourses();
    setGetUserCoursesTries(getUserCoursesTries + 1)
  }

  var practiceList = path && path.syllabus && path.syllabus.length > 0 ?
    path.syllabus.filter((l) => l != null && l.title != null).map(lesson => { return <PracticePreview key={"plm" + lesson.title} {...lesson} showCompletion ={true}></PracticePreview> })
   :[];

  const pathDuration = path && path.syllabus && path.syllabus.length > 0 ? calculateTotalPracticeTime(path.syllabus) : 0;
  const pathSessionNumber = practiceList.length;

  const userOwnsPath = userCourses && userCourses.length > 0 ? userCourses.includes(path.id) : false;

  const addPath = () => {
    axios.post(ZEN_C_ENDPOINT + `/myzen/myZenContent/addUserPath/` + auth.username + `/` + path.id)
      .then(res => {
        setPathAdded(true);
      });
  }
  
  return (
    <ProvideZenJournalContext>
      <div className="path-summary">
        <ZenNavBar primaryColor="#D1603D"></ZenNavBar>
        <MyZenNavBar></MyZenNavBar>
        <div>
          {path != null ?
            <React.Fragment>
              <div style={{ display: "flex", flexDirection: "row", margin: "auto" }}>
                <h1 className={activeItem == "Overview" ? "subPageNavHeader subPageActiveNavHeader" : "subPageNavHeader"} onClick={() => setActiveItem("Overview")}>Overview</h1>
                <h1 className={activeItem == "Practices" ? "subPageNavHeader subPageActiveNavHeader" : "subPageNavHeader"} onClick={() => setActiveItem("Practices")}>Practices</h1>
              </div>

              <div className="path-summary-details-section">
                {activeItem == "Overview" ?
                  <div style={{ paddingBottom: "250px" }}>
                    <h1 className="path-summary-title okashi">{path && path.title ? path.title : "PATH TITLE"} </h1>
                    <h4 className="path-creator okashi">{practitioner ? "by " + practitioner.name : null}</h4>
                    <div style={{ display: "flex", flexDirection: "row", margin: "auto", marginBottom: "25px", width: "300px" }}>
                      <img className="path-summary-image" src={path && path.image ? path.image : require("../../../static/photos/AwM.png")} ></img>

                      <PathCompletionMeter addPath={() => addPath()} pathId={path != null ? path.id : null} pathCompletionRate={pathCompletionRate} pathAdded={pathAdded} userOwnsPath={userOwnsPath} />

                    </div>
                    {/* Show Add Path to inventory if path is started but not owned. */}
                    {pathCompletionRate != null && (pathCompletionRate.lessonsCompleted != null && pathCompletionRate.lessonsCompleted > 0) && !userOwnsPath && !pathAdded ?
                      <div className="centered-text add-path-text" onClick={() => addPath()}>add path to inventory</div>
                      : null}

                    <PathIcons {...practitioner} pathSessionNumber={pathSessionNumber} pathDuration={pathDuration}></PathIcons>
                    <h3 className="path-description zenK">{path && path.description ? path.description : null}</h3>
                  </div>
                  :
                  <div>
                    <React.Fragment>
                      <div style={{ display: "flex", flexDirection: "row", margin: "auto", width: "275px", paddingTop: "10px", paddingBottom: "10px" }}>
                        {pathCompletionRate ?
                          <h4 className='centered-text'>
                            {pathCompletionRate.lessonsCompleted}/{pathCompletionRate.totalLessons} Practices Completed</h4>
                          :
                          <h4> 0/{path && path.syllabus && path.syllabus.length > 0 ? path.syllabus.length : "0"} Practices Completed</h4>
                        }

                      </div>
                      <div className='path-syllabus' style = {{paddingBottom:"150px"}}>
                        {practiceList}
                      </div>
                    </React.Fragment></div>
                }
              </div>
            </React.Fragment>
            : <ZenLoader />}
        </div>

      </div>
    </ProvideZenJournalContext>
  );


}