import axios from "axios";
import React, { createContext, useContext, useState } from "react";
import ZenContentInterface from "../components/zencontent/practices/ZenContentInterface";
import { ZEN_C_ENDPOINT } from "../config/EnvironmentalVariables";
import { getZencyclopaediaMainGroups } from "../service/ZencyclopaediaService";
import { useAuth } from "./auth";
import { ArticlePreview } from "../components/explore/articles/ArticlePreview";
import { ZenNugget } from "../components/zencontent/nuggets/ZenNugget";

interface UseZenSearch {
  contentItems: ZenContentInterface[],
  content: ZenContentInterface,
  searchType: string,
  resultStatus: string,
  setResultStatus: (status: string) => void,
  searchInfomatics: (id: string) => void,
  getZencyclopaedia: () => void,
  getMainGroups: () => any,
  searchMeditationsByTag: (search: string, tags?: string[]) => void,
  searchCoursesByTag: (search: string, tags?: string[]) => void,
  getAlLCourses: () => void,
  getUserCourses: () => void,
  searchPending: boolean,
  retrieveContentItems: () => void,
  filterMeditationsByTag: (search: string, tags?: string[]) => any,
  filterCoursesByTag: (search: string, tags?: string[]) => any,
  filterArticlesByTag: (search: string, tags?: string[]) => any,
}

type Props = {
  children?: React.ReactNode;
};
const zenSearchContext = createContext({} as UseZenSearch);

export const ProvidezenSearchContext: React.FC<Props> = ({ children }) => {
  const zenSearchService = ZenSearchService();
  return <zenSearchContext.Provider value={zenSearchService}>{children}</zenSearchContext.Provider>;
};

export const useZenSearchService = () => {
  return useContext(zenSearchContext);
};

export const ZenSearchService = () => {
  const auth = useAuth();

  const [contentItems, setContentItems] = useState(null);
  const [content, setContent] = useState(null);
  const [searchPending, setSearchPending] = useState(false);
  const [searchType, setSearchType] = useState<string>();
  const [resultStatus, setResultStatus] = useState<string>();

  const getMainGroups = () => {
    setSearchType("main");
    return getZencyclopaediaMainGroups();
  }

  const filterMeditationsByTag = async (search: string, tags?: any[]) => {
    let searchTag = search ? search : tags != null && tags.length > 0 ? tags[0] : null;
    console.log("filtering for: " + searchTag);
    var userN = auth.username != null && auth.username.length > 0 ? auth.username : "anon";

    var searchPath = searchTag == "top" ? ZEN_C_ENDPOINT + `/zen/content/topContent/` + userN + `/MEDITATION/`
      : searchTag == "new" ? ZEN_C_ENDPOINT + `/zen/content/newContent/` + userN + `/MEDITATION/`
        : ZEN_C_ENDPOINT + `/zen/content/filterContent/` + userN + `/MEDITATION/` + searchTag;

    let result = await axios.get(searchPath)
      .then(res => {
        if (res.data) {
          return res.data;
        } else {
          return [];
        }
      }).catch(() => {
        return null;
      });

    return result;
  }

  const filterCoursesByTag = async (search: string, tags?: any[]) => {
    console.log("SEARCHING COURSES: " + search);
    console.log("SEARCHING COURSES: " + tags);
    let searchTag = search ? search : tags && tags.length > 0 ? tags[0] : null;
    console.log("SEARCHING COURSES: " + searchTag);

    let userN = auth.username != null && auth.username.length > 0 ? auth.username : "anon";
    var searchPath = searchTag == "top" ? ZEN_C_ENDPOINT + `/zen/content/topContent/` + userN + `/PATH/`
      : searchTag == "hot" ? ZEN_C_ENDPOINT + `/zen/content/topContent/` + `/PATH/`
        : searchTag == "new" ? ZEN_C_ENDPOINT + `/zen/content/newContent/` + userN + `/PATH/`
          : ZEN_C_ENDPOINT + `/zen/content/filterContent/` + userN + `/PATH/` + searchTag;

    let result = await axios.get(searchPath)
      .then(res => {
        if (res.data) {
          return res.data;
        } else {
          return [];
        }
      }).catch(() => {
        return null;
      })
    return result;
  }

  const searchNuggetsByType = async (type: string) => {
    let result = await axios.get(ZEN_C_ENDPOINT + `/zen/nuggets/filter/` + type)
      .then(res => {
        if (res.data) {
          return res.data.map(item => <ZenNugget {...item}></ZenNugget>);
        } else {
          return [];
        }
      }).catch(() => {
        return null;
      })

    return result;
  }

  const filterArticlesByTag = async (search: string, tags?: any[]) => {
    let searchTag = search ? search : tags && tags.length > 0 ? tags[0] : null;

    if (search == "goodnews") {
      return searchNuggetsByType("NEWS");
    } else {
      let userN = auth.username != null && auth.username.length > 0 ? auth.username : "anon";
      var searchPath = searchTag == "top" ? ZEN_C_ENDPOINT + `/zen/content/topContent/` + userN + `/ARTICLE/`
        : searchTag == "hot" ? ZEN_C_ENDPOINT + `/zen/content/topContent/` + `/ARTICLE/`
          : searchTag == "new" ? ZEN_C_ENDPOINT + `/zen/content/newContent/` + userN + `/ARTICLE/`
            : ZEN_C_ENDPOINT + `/zen/content/filterContent/` + userN + `/ARTICLE/` + searchTag;

      let result = await axios.get(searchPath)
        .then(res => {
          if (res.data) {
            return res.data.map(item => <ArticlePreview {...item}></ArticlePreview>);
          } else {
            return [];
          }
        }).catch(() => {
          return null;
        })
      return result;
    }

  }


  const retrieveContentItems = () => {

  }

  const getZencyclopaedia = () => {
    setSearchPending(true);
    axios.get(ZEN_C_ENDPOINT + `/zen/infomatics/readContent`)
      .then(res => {
        if (res.data.length > 0) {
          setSearchType("articles");
          setResultStatus("success");
          setContentItems(res.data);
          setSearchPending(false);
        } else {
          setContentItems([]);
          setSearchType("articles");
          setSearchPending(false);
        }
      }).catch(() => {
        setSearchPending(false);
      })
  }

  const searchInfomatics = (id: string) => {
    setSearchPending(true);
    axios.get(ZEN_C_ENDPOINT + `/zen/infomatics/groups/` + id)
      .then(res => {
        if (res.data) {
          setContent(res.data);
          setSearchType(id);
          setSearchPending(false);
        } else {
          setContent(null);
          setSearchType(id);
          setSearchPending(false);
        }
      }).catch(() => {
        setSearchPending(false);
      })
  }




  const searchMeditationsByTag = (search: string, tags?: any[]) => {
    let searchTag = search ? search : tags && tags.length > 0 ? tags[0] : null;
    setSearchPending(true);
    var userN = auth.username != null && auth.username.length > 0 ? auth.username : "anon";

    var searchPath = searchTag == "top" ? ZEN_C_ENDPOINT + `/zen/content/topContent/` + userN + `/MEDITATION/`
      : searchTag == "new" ? ZEN_C_ENDPOINT + `/zen/content/newContent/` + userN + `/MEDITATION/`
        : ZEN_C_ENDPOINT + `/zen/content/filterContent/` + userN + `/MEDITATION/` + search;

    axios.get(searchPath)
      .then(res => {
        if (res.data) {
          setContentItems(res.data);
          setSearchType(search + tags.join(","));
          setSearchPending(false);
          setResultStatus("RESULTS_WAITING");
        } else {
          console.log("A");
          setContentItems(null);
          setSearchType(search + tags.join(","));
          setSearchPending(false);
          setResultStatus("NO_RESULTS");
        }
      }).catch(() => {
        console.log("B");
        setSearchPending(false);
        setSearchType(search + tags.join(",") + "-FAIL");
        setResultStatus("SEARCH_FAILED");
      })
  }

  const searchCoursesByTag = (search: string, tags?: string[]) => {
    let searchTag = search ? search : tags && tags.length > 0 ? tags[0] : null;
    console.log("SEARCHING COURSES: " + searchTag);

    setSearchPending(true);

    let userN = auth.username != null && auth.username.length > 0 ? auth.username : "anon";
    var searchPath = searchTag == "top" ? ZEN_C_ENDPOINT + `/zen/content/topContent/` + userN + `/PATH/`
      : searchTag == "hot" ? ZEN_C_ENDPOINT + `/zen/content/topContent/` + `/PATH/`
        : searchTag == "new" ? ZEN_C_ENDPOINT + `/zen/content/newContent/` + userN + `/PATH/`
          : ZEN_C_ENDPOINT + `/zen/content/filterContent/` + userN + `/PATH/` + searchTag;

    axios.get(searchPath)
      .then(res => {
        if (res.data) {
          setContentItems(res.data);
          setSearchType(search + tags.join(","));
          setSearchPending(false);
          setResultStatus("RESULTS_WAITING");
        } else {
          setContentItems(null);
          setSearchType(search + tags.join(","));
          setSearchPending(false);
          setResultStatus("NO_RESULTS");
        }
      }).catch(() => {
        setSearchType(search + tags.join(",") + "-FAIL");
        setSearchPending(false);
        setResultStatus("SEARCH_FAILED");
      })
  }

  const getAlLCourses = () => {
    setSearchPending(true);
    axios.get(ZEN_C_ENDPOINT + `/zen/paths/read/`)
      .then(res => {
        if (res.data) {
          setContentItems(res.data);
          setSearchType("courses");
          setSearchPending(false);
          setResultStatus("success");
        } else {
          setContentItems(null);
          setSearchType("courses");
          setSearchPending(false);
        }
      }).catch(() => {
        setSearchPending(false);
      })
  }

  const getUserCourses = () => {
    setSearchPending(true);

    //Get User Path Ids From My Zen Service.
    axios.get(ZEN_C_ENDPOINT + `/zen/paths/read/`)
      .then(res => {
        if (res.data) {
          setContentItems(res.data);
          setSearchType("mycourses");
          setSearchPending(false);
          setResultStatus("success");
        } else {
          setContentItems([]);
          setSearchType("mycourses");
          setSearchPending(false);
        }
      }).catch(() => {
        setSearchPending(false);
      })
  }

  return {
    contentItems,
    searchType,
    searchInfomatics,
    getZencyclopaedia,
    content,
    getMainGroups,
    searchMeditationsByTag,
    searchCoursesByTag,
    getAlLCourses,
    searchPending,
    getUserCourses,
    resultStatus,
    setResultStatus,
    retrieveContentItems,
    filterMeditationsByTag,
    filterCoursesByTag,
    filterArticlesByTag
  };

}