import * as React from "react";
import "../../../styling/explore/explore.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { ZEN_C_ENDPOINT } from "../../../config/EnvironmentalVariables";
import { Link, useParams } from "react-router-dom";
import { useMyCommunityService } from "../../../hooks/MyCommunityContext";
import { createArticleJSX } from "../../../service/ContentService";
import ZenContentInterface from "../../zencontent/practices/ZenContentInterface";


export const ArticlePreview = (props: ZenContentInterface) => {


    //const [practitionerInfo, setPractitionerInfo] = React.useState(props.practitioner != null ? props.practitioner : null);

    
    return (
        <Link to = {"/explore/article/"+props.id}>
        <div className="zencyclopaedia-content-list-item ZenArticlePreview" >
            
          <img className = "article-preview-image" src={props.image? props.image : null} ></img>
    
        <div className='practice-preview-info'>
            <h4 className = "practice-preview-title okashi">{props.title}</h4>
            <h4 className = "practitioner-preview-name zenK">by {props.practitioner? props.practitioner : 'ZENcyclopedia'}</h4>
            <div className = "practice-preview-controls">
          
            </div>
        </div>
        </div>
        </Link>
    );

}

