import React, { useState } from 'react';
import "../../../styling/vacana/vacana.scss";
import "../../../styling/common/index.scss";
import { Link } from 'react-router-dom';
import ZenContentInterface from './ZenContentInterface';

export const PracticeSliderPreview = (props : ZenContentInterface) => {

    return (
      <div className="zenscape-slider-item content-rec-slider-item path-slider-item" >
        <img className={"med-slider-preview-image"} src={props.image ? props.image : null} ></img>
        <h1 className="practice-slider-title zenK"><b>{props.title}</b> </h1>
        <h4 className="explore-slider-description">{props.description? props.description.substring(0,100) : ""}{props.description && props.description.length>100? "..." : ""}</h4> 
        <Link to = {"/meditation/"+props.id}>
        <div className = "zenGlowButton navajoGlowButton">
          See More
        </div>
      </Link>
      </div>
    );
}