import * as React from "react";
import '../../../styling/common/index.scss';
import { ZenNavBar } from "../../common/navbar/ZenNavBar";
import { ZencTitle } from "../ZencTitle";
import ReactPlayer from "react-player";

export const PractitionerWelcome = (props: any) => {

  const [playDemo, setPlayDemo] = React.useState(false);
  const [demoSource, setDemoSource] = React.useState(null);

  const aboutAccountsRef = React.useRef(null);
  const howToSignUpRef = React.useRef(null);
  const imActivatedRef = React.useRef(null);
  const accountSetUpRef = React.useRef(null);
  const postingContentRef = React.useRef(null);
  const growYourNetworkRef = React.useRef(null);
  const nextStepsRef = React.useRef(null);
  const haveYourSayRef = React.useRef(null);
  const stayInTouchRef = React.useRef(null);

  const getPractitioerAccountsDescription = () => {
    return <div><h3>Practitioner Accounts</h3>
      <h4>
        You need a practitioner account to post any kind of content onto the ZENcyclopedia platform.
        <br></br>Anyone can be eligible, as long as you are able to share some kind of uplifting and positive content.
        We're looking to promote a wide range of voices, whether you already have mindfulness teaching experience, or you simply want to share uplifting messages or photos during your practices and mindfulness journey, we think you have something great to offer!
        <br></br><br></br>Practitioners can interact with the site in a number of ways (see below) such as by posting meditations/videos, or text and image posts, as well as Zen Reels which are short videos which can be played directly from a users Zen Feed.
      </h4> </div>
  }

  const getCommunityAccountsDescription = () => {
    return <div><h3>Community Accounts</h3>
      <h4>
        Community pages serve as a hub to bring together practitioners to grow their networks, as well as providing a place for general users to discover new things.
        <br />
        Communities can cover a wide range of mindfulness subtopics, such as 'ZEN', 'Buddhism', 'Yoga', 'meditation for beginners'
        <br />
        All content on a Community page is comprised of practitioner submitted content, with the community group receiving a small commission of ad revenue for practitioner content that is discovered through the community.
        <br /><br />
        <span className="totemText">Community Pages will be released during August 2023.</span>
        <br /><br />
        Want to have your say on how we implement this? Have a community in mind you would like to be involved in?

        email us at <a href="mailto:contact@zencyclopedia.co.uk"><b>contact@zencyclopedia.co.uk </b></a>
      </h4>
    </div>
  }

  const playADemo = (name: string) => {
    if (name == "addMed") {
      setDemoSource("https://public-zen-c-images.s3.eu-west-2.amazonaws.com/addMedDemo.MOV");
    } else if (name == "addNugget") {
      setDemoSource("https://public-zen-c-images.s3.eu-west-2.amazonaws.com/addNugget.MOV");
    } else if (name == "addPic") {
      setDemoSource("https://public-zen-c-images.s3.eu-west-2.amazonaws.com/addPracPic.MOV");
    } else if (name == "addBio") {
      setDemoSource("https://public-zen-c-images.s3.eu-west-2.amazonaws.com/addBio.MOV");
    } else if (name == "addPath") {
      setDemoSource("https://public-zen-c-images.s3.eu-west-2.amazonaws.com/addPath.MOV");
    }
    setPlayDemo(true);
  }



  return (
    <div className="ZenBasicPage">

      <ZenNavBar home = {true}/>
      {playDemo ?
        <div className="zen-demo-video-player">
          <div onClick={() => setPlayDemo(false)} className="stopDemoButton"></div>
          <ReactPlayer playing={true} autoPlay={true} controls={true} onEnded={() => setPlayDemo(false)} loop={false} url={demoSource} width='100%' onPause={() => setPlayDemo(false)} setPlaying={(playing) => setPlayDemo(playing)} />
        </div> : null}
      <div style={{ overflowY: "scroll", maxHeight: "95vh" }}>
        <div style={{ paddingBottom: "200px" }}>
          <ZencTitle />
          <div className="practitionerWelcomeText">
            <h3>Welcome to the start of your journey as a ZENcyclopedia practitioner - we’re so pleased you want to contribute to our community and join our mission</h3>
            <div className="resourcePageContents">
              <h3>Page Resources</h3>
              <h2 onClick={() => {
                aboutAccountsRef.current?.scrollIntoView({ behavior: 'smooth' });
              }}>What is a Practitioner & Community account?</h2>
              <h2 onClick={() => {
                howToSignUpRef.current?.scrollIntoView({ behavior: 'smooth' });
              }}>How do I sign up?</h2>
              <h2 onClick={() => {
                accountSetUpRef.current?.scrollIntoView({ behavior: 'smooth' });
              }}>I'm activated - how do I get started?!</h2>
              <h4 onClick={() => {
                imActivatedRef.current?.scrollIntoView({ behavior: 'smooth' });
              }}>Setting up your account</h4>
              <h4 onClick={() => {
                postingContentRef.current?.scrollIntoView({ behavior: 'smooth' });
              }}>Posting Content</h4>
              <h2 onClick={() => {
                nextStepsRef.current?.scrollIntoView({ behavior: 'smooth' });
              }}>Next Steps</h2>
              <h4 onClick={() => {
                growYourNetworkRef.current?.scrollIntoView({ behavior: 'smooth' });
              }}>Grow your network</h4>
              <h4 onClick={() => {
                haveYourSayRef.current?.scrollIntoView({ behavior: 'smooth' });
              }}>Have your say</h4>
              <h4 onClick={() => {
                stayInTouchRef.current?.scrollIntoView({ behavior: 'smooth' });
              }}>Stay in touch</h4>
            </div>
            <div className="zenResourcePageSection accountTypesSection" ref={aboutAccountsRef}>
              <h1>Account Types</h1>
              <div className="accountTypesText">
                {getPractitioerAccountsDescription()}
                {getCommunityAccountsDescription()}
              </div>
            </div>
          </div>
          {/* How To Sign Up */}
          <div className="zenResourcePageSection howToSignUpSection" ref={howToSignUpRef} >
            <h1>How To Sign Up</h1>
            <h3>In order to apply for a practitioner account, we'll need to know a little bit about you!<br /><br />

              95% of practitioner requests get approved. We're commited to being a platform for all kinds of voices in the mindfulness community - we mainly just need to check you are human!

              To be considered, simply drop us an email to <a href="mailto:contact@zencyclopedia.co.uk"><b>contact@zencyclopedia.co.uk </b></a>

              You can either give us a short message letting us know what kind of content you wish to post, or if you're already posting mindfulness related content, you can always just point us to your social media.

            </h3>

          </div>

          {/* Getting started */}
          <div className="zenResourcePageSection getStartedResourcePageSection" ref={accountSetUpRef}>
            <h1 ref={imActivatedRef}>Getting started</h1>
            <div className="accountTypesText">
              <div style={{ margin: "auto" }}>
                <h2 style={{ marginBottom: 0 }}>Step 1</h2>
                <h1 style={{ marginTop: 0 }}>Add Bio</h1>
                <h3>Add a short introduction letting people know what you are all about!</h3>
                <h4>You can also add to your personal blogs or website using the add link button.</h4>
                <div style={{ margin: "auto" }}>
                  <div className="zenGlowButton mingGlowButton" style={{ margin: "auto" }}
                    onClick={() => playADemo("addBio")}>Show How To</div>
                  {/* add bio how to */}
                </div>
              </div>
              <div className="zen-mobile">
                <div className="paintedLineDivider" style={{ marginTop: "50px" }}>
                </div>
              </div>
              <div style={{ margin: "auto" }}>
                <h2 style={{ marginBottom: 0 }}>Step 2</h2>
                <h1 style={{ marginTop: 0 }}>Add Profile Picture & Video</h1>
                <h3>Upload a photo to represent your Practitioner account</h3>
                <h3 style={{ marginBottom: 0 }}>Why not record a short video to help people get to know you? (This will be added to your bio)</h3>
                <div style={{ margin: "auto" }}>
                  <div className="zenGlowButton mingGlowButton" style={{ margin: "auto" }}
                    onClick={() => playADemo("addPic")}>Show Demo</div>
                  <div className="zenGlowButton buddGlowButton" style={{ margin: "auto", marginTop: "10px" }}>Bio Videos Coming Soon!</div>
                  <div>
                    {/* add bio how to */}
                  </div>
                </div>
              </div>
            </div>
            <div className="paintedLineDivider" style={{ marginTop: "50px" }}>
            </div>
            <div className="accountTypesText">
              <div style={{ margin: "auto" }}>
                <h2 style={{ marginBottom: 0 }}>Step 3</h2>
                <h1 style={{ marginTop: 0 }}>Add Recommended reading list</h1>
                <h3>Want to share your favourite mindfulness resources with your community?</h3>
                <h4>Add recommended content to your profile - this can be external links to books, videos, podcasts or websites</h4>
                <div style={{ margin: "auto" }}>
                  <div className="zenGlowButton buddGlowButton" style={{ margin: "auto" }}
                    onClick={() => playADemo("")}>How to coming soon!</div>
                  {/* add bio how to */}
                </div>
              </div>
              <div style={{ margin: "auto" }}>
              </div>
            </div>
          </div>

          <div className="zenResourcePageSection howToPostSection" ref={postingContentRef}>
            <h1>Start Sharing your Wisdom!</h1>
            <div className="accountTypesText">
              <div style={{ margin: "auto" }}>
                <h2>Post Meditations/Videos</h2>
                <h3>Upload guided meditations, lectures, opinion pieces or other practice material.</h3>
                <h4>Videos can be uploaded from mobile or desktop platforms and should support all the most common video formats - see the how to by hitting the button below, or drop us an email if you have any problems!</h4>
                <div style={{ margin: "auto" }}>
                  <div className="zenGlowButton mingGlowButton" style={{ margin: "auto" }}
                    onClick={() => playADemo("addMed")}>Play Demo</div>
                </div>
              </div>

              <div className="zen-mobile">
                <div className="paintedLineDivider" style={{ marginTop: "50px" }}></div>
              </div>
              <div style={{ margin: "auto" }}>
                <h2>Share Zen Nuggets</h2>
                <h3>Zen Nuggets are Text or Image based posts allowing you to share small nuggets of positivity & wisdom!</h3>
                <h3>Best used to share quotes, small pieces of advice, or to give a quick insight into what you're up to and how you practice!</h3>
                <div style={{ margin: "auto" }}>
                  <div className="zenGlowButton mingGlowButton" style={{ margin: "auto" }}
                    onClick={() => playADemo("addNugget")}>Show how to</div>
                  <div>
                    {/* add bio how to */}
                  </div>
                </div>
              </div>
            </div>

            <div className="paintedLineDivider" style={{ marginTop: "50px" }}>
            </div>
            <div className="accountTypesText">
              <div style={{ margin: "auto" }}>
                <h2>Design a Mindfulness Course</h2>
                <h3>Create a course to teach a particular skill or aimed at a particular audience by grouping your content into collections.</h3>
                <h4></h4>
                <div style={{ margin: "auto" }}>
                  <div className="zenGlowButton mingGlowButton" style={{ margin: "auto" }}
                    onClick={() => playADemo("addPath")}>Show how to</div>
                  <div>
                    {/* add bio how to */}
                  </div>
                </div>
              </div>

              <div className="zen-mobile">
                <div className="paintedLineDivider" style={{ marginTop: "50px" }}>
                </div>
              </div>
              <div style={{ margin: "auto" }}>
                <h2>Create a Reel</h2>
                <h3>Zen Reels are short videos that can be played from a users Zen Feed</h3>
                <h3>Use these to share short updates or bits of advice</h3>
                <div style={{ margin: "auto" }}>
                  <div className="zenGlowButton buddGlowButton" style={{ margin: "auto" }}>Reels Coming Soon!</div>
                  <div>
                    {/* add bio how to */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Next Steps */}
          <div className="zenResourcePageSection nextStepsSection" ref={nextStepsRef} >
            <h1>Next Steps</h1>
            <div className="accountTypesText">
              <div style={{ margin: "auto" }}>
                <h2>Start growing you network</h2>
                <h3>The best way to start making connections is to create or join a community!</h3>
                <div className="zenGlowButton buddGlowButton" style={{ margin: "auto" }}>Zen Communities Coming Soon!</div>
              </div>

              <div className="zen-mobile">
                <div className="paintedLineDivider" style={{ marginTop: "50px" }}></div>
              </div>
              <div style={{ margin: "auto" }}>
                <h2>Share on social media</h2>
                <h3>Why not post onto your socials and let others know you are here!? find us on instagram as @zencyclopaedia</h3>
              </div>
            </div>

            <div className="paintedLineDivider" style={{ marginTop: "50px" }}>
            </div>
            <div className="accountTypesText">
              <div style={{ margin: "auto" }}>
                <h2>Stay in touch!</h2>
                <h4>Why not drop us a DM on instagram or give us an email to let us know you've gotten set up? We might be able to provide content creation tools such as video creation tools, AI photo generation or help promote you on our social media!</h4>
                <h4>You can also follow our progress and vote on changes by viewing our page on X</h4>
                <div className="buttonRow">

                  <div className="zenGlowButton mingGlowButton" style={{ margin: "auto" }} onClick={() => {
                    window.open('https://www.threads.net/@zencyclopaedia', '_blank');
                  }
                  }>Threads</div>

                  <div className="zenGlowButton sageGlowButton forcedMT" style={{ margin: "auto", marginTop: "15px" }} onClick={() => {
                    window.open('https://instagram.com/zencyclopaedia', '_blank');
                  }
                  }>
                    Instagram
                  </div>

                  <div>
                    {/* add bio how to */}
                  </div>
                </div>
              </div>
              <div style={{ margin: "auto" }}>
                <h2>We want your feedback!</h2>
                <h4>Got any ideas for how we can do better? Whether it's big or small, we want to hear from you. </h4>
                <h4>Our platform is still young & developing rapidly, we firmly believe the community driven approach is the best way to create a service that is accessible and brings benefits to the people who use it!</h4>
                <h4></h4>
                <div style={{ margin: "auto" }}>
                  <a href="mailto:contact@zencyclopedia.co.uk">
                    <div className="zenGlowButton mingGlowButton" style={{ margin: "auto" }}>Drop us an email</div>
                  </a>
                  <div>
                    {/* add bio how to */}
                  </div>
                </div>
              </div>

            </div>

            

          </div>

        </div>
      </div>
    </div>
  );
}

