import * as React from "react";
import "../../../styling/explore/explore.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { ZEN_C_ENDPOINT } from "../../../config/EnvironmentalVariables";
import { Link, useParams } from "react-router-dom";
import { useMyCommunityService } from "../../../hooks/MyCommunityContext";
import { createArticleJSX } from "../../../service/ContentService";
import { ZenLoader } from "../../common/ZenLoader";
import { ZenNavBar } from "../../common/navbar/ZenNavBar";


export const ZenArticle = (props: { practitioner?: any, practitionerId?: string, linkOnClick?: any }) => {

    const { searchId } = useParams();

    const [articlePages, setArticelPages] = React.useState(null);
    const [loadingPractitioner, setLoadingPractitioner] = React.useState(false);
    const [readMore, setReadMore] = React.useState(false);
    const [practitionerInfo, setPractitionerInfo] = React.useState(props.practitioner != null ? props.practitioner : null);

    const loadArticle = async () => {
        await axios.get(ZEN_C_ENDPOINT + `/zen/infomatics/groups/` + searchId)
            .then(res => {
                setArticelPages(createArticleJSX(res.data.pages));
            }).catch(() => {
            });
    }

    React.useEffect(() => {
        loadArticle()
    }, []);

    return (
        <div className="ZenBasicPage" style={{ paddingTop: "0" }}>
            <ZenNavBar/>
            <div style={{ overflowY: "scroll", marginTop: "0" }}>
                {articlePages?
                <div style={{ height: "fit-content", paddingBottom: "150px" }}>
                    {articlePages}</div>
                    :
                    <ZenLoader/>}
            </div>
        </div>
    );

}

