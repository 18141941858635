import * as React from "react";
import { MyZenNavBar } from "../../common/navbar/MyZenNavBar";
import "../../../styling/common/index.scss";
import { ZenNavBar } from "../../common/navbar/ZenNavBar";
import { useState } from "react";
import axios from "axios";
import { useAuth } from "../../../hooks/auth";
import PrivateRoute from "../../common/PrivateRoute";
import { MyZenTasks } from "./tasks/MyZenTasks";
import { Link } from "react-router-dom";
import { ZEN_C_ENDPOINT } from "../../../config/EnvironmentalVariables";
import { PuffLoader } from "react-spinners";
import { useMyZenService } from "../../../hooks/ZenUserContext";
import { useZenCardsService } from "../../../hooks/ZenCardsContext";
import { ZenCardMilestoneNotice } from "../../explore/icons/ZenCardMilestoneNotice";
import Slider from "react-slick";
import { PractitionerStudio } from "../../practitioner/studio/PractitionerStudio";
import { PractitionerHome } from "../../practitioner/PractitionerHome";
import { ReactSession } from 'react-client-session';
import { ChangeProfilePic } from "../../practitioner/ChangeProfilePic";
import { ZenPractitionerTermsAndConditions } from "../../home/ZenPractitionerTermsAndConditions";
import { ZenPicSizer } from "../../practitioner/ZenPicSizer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";

export const MyZenProfile = (props: { landOnPractitioner?: boolean, landOnCreate?: boolean }) => {

      const auth = useAuth();
      let zenUserContext = useMyZenService();

      const landingPage = props.landOnPractitioner ? "Practitioner" : props.landOnCreate ? "Practitioner-Create" : "MyZen";

      const [myZenPage, setMyZenPage] = useState(landingPage);
      const [focusedMyZenItem, setFocusedMyZenItem] = useState("Tasks");
      const [showWalkthrough, setShowWalkthrough] = useState(zenUserContext != null && zenUserContext.profile != null && !zenUserContext.profile.portalWalkthroughCompleted);
      const [rewardCard, setRewardCard] = useState(null);
      const [cardAttempts, setCardAttempts] = useState(0);
      const [practitionerAttempts, setPractitionerAttempts] = useState(0);
      const [loadingPractitioner, setLoadingPractitioner] = useState(false);
      const [practitionerInfo, setPractitionerInfo] = useState(null);
      const [tcsOutofDate, setTcsOutofDate] = useState(false);

      const isPractitioner = zenUserContext != null && zenUserContext.profile != null && zenUserContext.profile.practitioner;

      const getPractitionerInfo = async () => {
            await axios.get(ZEN_C_ENDPOINT + `/myzen/practitioner/` + auth.username)
                  .then(res => {
                        setPractitionerInfo(res.data);
                        if (res.data != null && !res.data.tcsUpToDate) {
                              setTcsOutofDate(true);
                        }
                        setLoadingPractitioner(false);
                  }).catch(() => {
                        setLoadingPractitioner(false);
                  });
      }

      if (practitionerInfo == null && !loadingPractitioner && practitionerAttempts <= 5) {
            setLoadingPractitioner(true);
            setPractitionerAttempts(practitionerAttempts + 1);
            getPractitionerInfo();
      }


      const walkthroughComplete = ReactSession.get("myZenWalkthrough-" + auth.username);

      const agreeToPractitionerTCs = () => {
            axios.post(ZEN_C_ENDPOINT + `/myzen/practitioner/update`, {
                  username: auth.username,
                  tcsUpToDate:true
            }).then(function (response) {
                  setTcsOutofDate(false);
            })
      }


      return (
            <PrivateRoute>
                  <ZenNavBar activeItem="MyZen" primaryColor="#D1603D" enabled={(zenUserContext.profile && zenUserContext.profile.portalWalkthroughCompleted)}></ZenNavBar>
                  <MyZenNavBar
                        activePage={myZenPage}
                        subPage={focusedMyZenItem}
                        setSubPage={setFocusedMyZenItem}
                        setPage={setMyZenPage}
                  />
                  {!(walkthroughComplete != null && walkthroughComplete == "TRUE") && showWalkthrough ?
                        <div className="zen-walkthrough-helper">
                              <h3 className="zen-walkthrough-text">
                                    Welcome to MyZen! Explore the App by working through your Tasks
                              </h3>
                              <div className="zen-walkthrough-text-next walkthrough-arrow-down" onClick={() => setShowWalkthrough(false)}></div>
                        </div>
                        : null
                  }
                  
                  <div className="MyZenProfile">
                        {rewardCard != null ?
                              <div style={{ position: "fixed", top: "150px", width: "100%", }}>
                                    <div style={{ position: "relative", margin: "auto", }}>
                                          <ZenCardMilestoneNotice cards={[rewardCard]} moveOnAction={() => setRewardCard(null)}></ZenCardMilestoneNotice>
                                    </div>
                              </div> :tcsOutofDate?
                                    <ZenPractitionerTermsAndConditions confirmSignUp = {() => agreeToPractitionerTCs()}/>
                              :<React.Fragment>
                                    {myZenPage == 'Practitioner'?
                              <Link to={"/practitioner/" + auth.username}>
                                    <div
                                          className="viewAsGeneralUserLink">
                                          {/* <FontAwesomeIcon icon={faLink} className="pbLink" /> */}
                                          View as general user
                                    </div>
                              </Link> : null}
                                    {zenUserContext.userInfoLoaded ?
                                          <div className="MyZenDetails">
                                                {(((myZenPage == "Practitioner-Create" && focusedMyZenItem != "practitioner-create-home") || (focusedMyZenItem == "practitioner-content")) && myZenPage != "ChangePhoto") ?
                                                      null :myZenPage != "ChangePhoto"?
                                                      <React.Fragment>
                                                            <div className = "myZenMain" style={{ margin: "auto", display: "flex", flexDirection: "row" }}>
                                                                  <div onClick={() => setMyZenPage("MyZen")} style={{ margin: "auto" }}>
                                                                        <img className={myZenPage == "MyZen" && isPractitioner ? "MyZenProfilePic PractitionerMyZenProfilePic" : myZenPage == "MyZen" ? "MyZenProfilePic" : "MyZenProfilePic MyZenProfilePicInactive"} src={zenUserContext.profile && zenUserContext.profile.userIcon != null ? zenUserContext.profile.userIcon : null} ></img>
                                                                        <h3 className=" zenK myzenDetails-username">{!isPractitioner && zenUserContext && zenUserContext.profile != null ? zenUserContext.profile.username : 'My Zen'}</h3>
                                                                  </div>
                                                                  {isPractitioner && practitionerInfo != null && practitionerInfo.image != null ?
                                                                        <div onClick={() => setMyZenPage("Practitioner")} style={{ margin: "auto" }}>
                                                                              <div className={myZenPage == "Practitioner" || myZenPage == "Practitioner-Create" ? "PractitionerMyZenProfilePic MyZenProfilePic practitionerMyZenPic" : "MyZenProfilePic practitionerMyZenPic MyZenProfilePicInactive"}>
                                                                              
                                                                              <ZenPicSizer
                                                                              imageToShow={practitionerInfo && practitionerInfo.image != null ? practitionerInfo.image : null}
                                                                              cropX = {practitionerInfo.imageCropX}
                                                                              cropY = {practitionerInfo.imageCropY}
                                                                              cropWidth = {practitionerInfo.imageCropWidth}
                                                                              newWidth = {myZenPage == "Practitioner" || myZenPage == "Practitioner-Create" ? 125 : 80}
                                                                              />
                                                                        
                                                                              </div>
                                                                              <h3 className=" zenK myzenDetails-username">Practitioner Profile</h3>
                                                                        </div>
                                                                        : isPractitioner ?
                                                                              <div onClick={(myZenPage == "Practitioner" || myZenPage == "Practitioner-Create") ? () => setMyZenPage("ChangePhoto") : () => setMyZenPage("Practitioner")} style={{ margin: "auto" }}>
                                                                                    <div className={myZenPage == "Practitioner" || myZenPage == "Practitioner-Create" ? "PractitionerMyZenProfilePic MyZenProfilePic myZenProfilePicChangeable" : "MyZenProfilePic MyZenProfilePicInactive myZenProfilePicChangeable"}>
                                                                                    </div>
                                                                                    <h3 className=" zenK myzenDetails-username">Practitioner Profile</h3>
                                                                              </div>
                                                                              : null}
                                                            </div>

                                                      </React.Fragment>:null
                                                }

                                                {myZenPage == "ChangePhoto" ? //for practitioner photo atm
                                                      <ChangeProfilePic
                                                            user={auth.username}
                                                            setPage={setMyZenPage}
                                                            getPractitionerInfo={getPractitionerInfo} 
                                                            currentImage={practitionerInfo && practitionerInfo.image != null ? practitionerInfo.image : null}/>
                                                      : myZenPage == "MyZen" ?
                                                            <React.Fragment>
                                                                  <div style={{ display: "flex", flexDirection: "row" }}>
                                                                        <h1 className={focusedMyZenItem == 'MyStuff' ? "subPageNavHeader subPageActiveNavHeader" : "subPageNavHeader"} onClick={() => setFocusedMyZenItem('MyStuff')}>My Stuff</h1>
                                                                        <h1 className={focusedMyZenItem == 'Tasks' ? "subPageNavHeader subPageActiveNavHeader" : "subPageNavHeader"} onClick={() => setFocusedMyZenItem('Tasks')}>Tasks</h1>
                                                                        <h1 className={focusedMyZenItem == 'Account' ? "subPageNavHeader subPageActiveNavHeader" : "subPageNavHeader"} onClick={() => setFocusedMyZenItem('Account')}>Account</h1>
                                                                  </div>
                                                                  {focusedMyZenItem == 'Tasks' ?
                                                                        <div className="zen-profile-lower-container">
                                                                              <MyZenTasks {...zenUserContext.profile}
                                                                                    setRewardCard={setRewardCard} />
                                                                        </div>
                                                                        : focusedMyZenItem == 'Account' ?
                                                                              <div className="zen-profile-lower-container">
                                                                                    <div style={{ paddingBottom: "75px" }}>
                                                                                          <Link to="/account">
                                                                                                <h3 className="MyZenInfoItem zenK">Account Details</h3>
                                                                                          </Link>
                                                                                          <Link to="/myzen/mysubscription">
                                                                                                <h3 className="MyZenInfoItem zenK">My Subscription</h3>
                                                                                          </Link>
                                                                                          <Link to="/privacy">
                                                                                                <h3 className="MyZenInfoItem zenK">Privacy</h3>
                                                                                          </Link>

                                                                                          <h3 className="MyZenInfoItem out-of-use zenK">Billing and Payments</h3>
                                                                                          <h3 className="MyZenInfoItem out-of-use zenK">Integrations and Plugins</h3>
                                                                                          <Link to="/changepassword">
                                                                                                <h3 className="MyZenInfoItem zenK">Change Password</h3>
                                                                                          </Link>
                                                                                          {/* <div className="choose-language" style={{ display: "flex", flexDirection: "row",marginBottom: "250px" }}>
                                                                  <h3 className="MyZenInfoItem zenK">Languages:</h3>
                                                                  <div className="languageIcon chooseEnglish"></div>
                                                                  <div className="languageIcon chooseSpanish"></div>
                                                                  <div className="plusLanguageIcon"></div>
                                                            </div> */}
                                                                                    </div>
                                                                              </div>
                                                                              :
                                                                              <div className="zen-profile-lower-container">
                                                                                    <Link to="/explore/communities/mycommunity">
                                                                                          <h2 className="MyZenInfoItem " >My Community</h2>
                                                                                    </Link>
                                                                                    <Link to="/bookmarks">
                                                                                          <h2 className="MyZenInfoItem " >Bookmarks</h2>
                                                                                    </Link>
                                                                                    <Link to="/favourites">
                                                                                          <h2 className="MyZenInfoItem " >Favourites</h2>
                                                                                    </Link>
                                                                                    <Link to="/zenverse/myportals">
                                                                                          <h2 className="MyZenInfoItem " >Zen Portals</h2>
                                                                                    </Link>
                                                                                    <Link to="/journal/practices">
                                                                                          <h2 className="MyZenInfoItem " >Practice History</h2>
                                                                                    </Link>
                                                                                    <Link to="/explore/courses/myCourses">
                                                                                          <h2 className="MyZenInfoItem " >Paths & Courses</h2>
                                                                                    </Link>
                                                                                    <Link to="/explore/zencards/mycards">
                                                                                          <h2 className="MyZenInfoItem " >Zen Cards</h2>
                                                                                    </Link>
                                                                                    <Link to={"/practitioner/" + auth.username}>
                                                                                          <h2 className="MyZenInfoItem out-of-use" style={{ marginBottom: "250px" }}>My Practitioner Content</h2>
                                                                                    </Link>
                                                                              </div>
                                                                  }
                                                            </React.Fragment>
                                                            : myZenPage == 'Practitioner' ?
                                                                  <PractitionerHome
                                                                        username={zenUserContext && zenUserContext.profile != null ? zenUserContext.profile.username : null}
                                                                        practitionerInfo={practitionerInfo}
                                                                        subPage={focusedMyZenItem}
                                                                        setSubPage={setFocusedMyZenItem}
                                                                        setPage={setMyZenPage}
                                                                        getPractitionerInfo={getPractitionerInfo}
                                                                  ></PractitionerHome>
                                                                  : myZenPage == 'Practitioner-Create' ?
                                                                        <PractitionerStudio
                                                                              userId={zenUserContext && zenUserContext.profile != null ? zenUserContext.profile.username : null}
                                                                              page={myZenPage}
                                                                              setPage={setMyZenPage}
                                                                              subPage={focusedMyZenItem}
                                                                              setSubPage={setFocusedMyZenItem} />
                                                                        : null
                                                }

                                          </div>
                                          : <PuffLoader />
                                    }
                              </React.Fragment>
                        }
                  </div>
            </PrivateRoute>);

}
