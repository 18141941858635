import * as React from "react";
import * as ReactDOM from "react-dom";
import './styling/common/index.scss';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import { MyZenProfile } from "./components/myzen/profile/MyZenProfile";
import { ZenSignUp } from "./components/home/SignUp/ZenSignUp";
import { ExploreBrowser } from "./components/explore/ExploreBrowser";
import { MyZenPlanner } from "./components/myzen/planner/MyZenPlanner";
import { MyZenJournal } from "./components/myzen/journal/MyZenJournal";
import { ProvideAuth } from "./hooks/auth";
import { ZenHome } from "./components/home/ZenHome";
import { LogIn } from "./components/home/LogIn";
import { StarterPortal } from "./components/practiceportal/starterPortal/StarterPortal";
import { ProvideStreamContext } from "./hooks/StreamContext";
import { ProvideMyStuffContext } from "./hooks/MyStuffContext";
import { ProvidezenExploreContext } from "./hooks/ZenExploreContext";
import { ZenVideoPlayer } from "./components/practiceportal/MediaPortals/ZenVideoPlayer";
import { ProvideZenJournalContext } from "./hooks/ZenJournalContext";
import { ProvideZenverseContext } from "./hooks/ZenverseContext";
import { ProvideMyZenContext } from "./hooks/ZenUserContext";
import { SimpleCountDownTimer } from "./components/zenverse/ZenscapePortal/SimpleCountDownTimer";
import ReactGA from "react-ga4";
import { PractitionerProfile } from "./components/practitioner/PractitionerProfile";
import { ZenGoalsRouter } from "./components/myzen/goals/ZenGoalsRouter";
import { PathSummary } from "./components/zencontent/courses/PathSummary";
import { ZenscapeBrowser } from "./components/zenverse/ZenscapeBrowser";
import { MyCourses } from "./components/myzen/goals/MyCourses";
import { ZenJournalWalkthrough } from "./components/common/walkthrough/ZenJournalWalkthrough";
import { StreamWalkthrough } from "./components/common/walkthrough/StreamWalkthrough";
import { ZenCardsBrowser } from "./components/explore/icons/ZenCardsBrowser";
import { ZenBotHome } from "./components/zenbot/ZenBotHome";
import { ProvideZenCardsContext } from "./hooks/ZenCardsContext";
import { PracticeSummary } from "./components/zencontent/practices/previews/PracticeSummary";
import { CourseBrowser } from "./components/explore/CourseBrowser";
import { PrivacyStatement } from "./components/home/PrivacyStatement";
import { ReactSession } from 'react-client-session';
import { AboutUs } from "./components/home/AboutUs";
import { ResetPassword } from "./components/home/SignUp/ResetPassword";
import { AccountDetails } from "./components/account/AccountDetails";
import { ConfirmZenergyPayment } from "./components/payments/ConfirmZenergyPayment";
import { GongWithTheWindPortal } from "./components/zenverse/ZenscapePortal/GongWithTheWind/GongWithTheWindPortal";
import { InformationBrowser } from "./components/explore/InformationBrowser";
import { ZenPayment } from "./components/payments/ZenPayment";
import { ZenergyPaymentComplete } from "./components/payments/ZenergyPaymentComplete";
import { ZencyclopediaDonation } from "./components/payments/ZencyclopediaDonation";
import { ZenergyPointsPayment } from "./components/payments/ZenergyPointsPayment";
import { AboutZenBot } from "./components/zenbot/AboutZenBot";
import { OurRoots } from "./components/home/OurRoots";
import { Philzentropy } from "./components/home/Philzentropy";
import { BecomeAPractitioner } from "./components/home/practitionerWelcome/BecomeAPractitioner";
import { DonationSuccessful } from "./components/payments/DonationSuccessful";
import { ZenQuiz } from "./components/quiz/ZenQuiz";
import { BuyZenergyPoints } from "./components/payments/BuyZenergyPoints";
import PrivateRoute from "./components/common/PrivateRoute";
import { ZenShop } from "./components/shop/ZenShop";
import { RetreatBrowser } from "./components/shop/Retreats/RetreatBrowser";
import { BuyTShirtsModal } from "./components/shop/tshirts/BuyTShirtsModal";
import { ConfirmTShirtPurchase } from "./components/shop/tshirts/ConfirmTShirtPurchase";
import { BuyStickersModal } from "./components/shop/stickers/BuyStickersModal";
import { ZenSubscriptionsTiersBrowser } from "./components/myzen/subscriptions/ZenSubscriptionsTiersBrowser";
import { CreateSubscriptionModal } from "./components/myzen/subscriptions/CreateSubscriptionModal";
import { SubscriptionConfirmation } from "./components/myzen/subscriptions/SubscriptionConfirmation";
import PublicRoute from "./components/common/PublicRoute";
import { MySubscription } from "./components/myzen/subscriptions/MySubscription";
import { ProvidezenSearchContext } from "./hooks/ZenSearchContext";
import { ConfirmStickerPurchase } from "./components/shop/stickers/ConfirmStickerPurchase";
import { ExploreArticles } from "./components/explore/ExploreArticles";
import { ProvideZenQuotasContext } from "./hooks/ZenQuotasContext";
import { CancelSubscriptionModal } from "./components/myzen/subscriptions/CancelSubscriptionModal";
import { UpgradeSubscriptionModal } from "./components/myzen/subscriptions/UpgradeSubscriptionModal";
import { DowngradeSubscription } from "./components/myzen/subscriptions/DowngradeSubscription";
import { ChooseMyZenSkills } from "./components/myzen/goals/ChooseMyZenSkills";
import { ProvideZenGoalsContext } from "./hooks/ZenGoalsContext";
import MP3Drop from "./components/practitioner/studio/MP3Drop";
import ImageDrop from "./components/practitioner/studio/ImageDrop";
import { PracticePortalTestContainer } from "./components/practiceportal/PracticePortalTestContainer";
import { ProvideMyCommunityContext } from "./hooks/MyCommunityContext";
import { ZenCommunitiesBrowser } from "./components/explore/communities/ZenCommunitiesBrowser";
import { ZenMediaPlayer } from "./components/practiceportal/MediaPortals/ZenMediaPlayer";
import { TextCoverImageStudio } from "./components/practitioner/studio/TextCoverImageStudio";
import { ZenUserTermsAndConditions } from "./components/home/ZenUserTermsAndConditions";
import { ZenArticle } from "./components/explore/articles/ZenArticle";
import { AboutSection } from "./components/home/AboutSection";
import { ContactUs } from "./components/home/ContactUs";
import { ZenImageBrowser } from "./components/practitioner/studio/ZenImageBrowser";
import { ProvideZenShopContext } from "./hooks/ZenShopContext";
import { ZenBasket } from "./components/shop/ZenBasket";
import { PractitionerWelcome } from "./components/home/practitionerWelcome/PractitionerWelcome";
import { PractitionerReadingList } from "./components/practitioner/PractitionerReadingList";
import { ChooseAccountsToFollow } from "./components/home/SignUp/ChooseAccountsToFollow";
import { CommunityProfile } from "./components/practitioner/CommunityProfile";
import { JustAnAd } from "./components/common/JustAnAd";
import ZenKfont from './static/fonts/Zen_Kurenaido/ZenKurenaido-Regular.ttf';
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";

const trackingID = "G-992LQYXQVJ";
ReactGA.initialize(trackingID);
const options = {
  // passing the client secret obtained from the server
  clientSecret: 'sk_test_51Mj2rZJnSZ6Tel4xXHg4bInfdZkitpE2bu22zlLwvlA8Q1i3TGT7vqrZ3FNLK7grYpIdT1Qw7G5ISDdpwtmJbjuX00yqlKKSDz',
};

ReactSession.setStoreType("localStorage");

const baseTheme = createTheme({
  typography: {
    fontFamily: 'ZenKurenaido, Arial',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'ZenKurenaido';
          font-style: normal;
          font-display: swap;
          font-weight: 900;
          src: local('ZenKurenaido'), local('ZenKurenaido'), url(${ZenKfont}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
});

ReactDOM.render(
  //<ProvideGoogleAnalyticsContext>

  <ThemeProvider theme={baseTheme}>
  <CssBaseline />
  <ProvideAuth>
    <ProvideMyStuffContext>
      <ProvidezenExploreContext>
        <ProvidezenSearchContext>
          <ProvideMyZenContext>
            <ProvideZenCardsContext>
              <ProvideZenShopContext>
                <Router>
                  <Routes>
                    
                  <Route path="/justAnAd" element={<JustAnAd />}>
                    </Route>
                    <Route path="/" element={<ZenHome />}>
                    </Route>
                    <Route path="/catf" element={
                    <ProvideMyCommunityContext><ChooseAccountsToFollow /></ProvideMyCommunityContext>}>
                    </Route>
                    <Route path="/testPortalAds" element={<PracticePortalTestContainer />}>
                    </Route>
                    <Route path="/home" element={<ZenHome />}>
                    </Route>
                    <Route path="/about" element={<AboutSection />}>
                    </Route>
                    <Route path="/contact" element={<ContactUs />}>
                    </Route>
                    <Route path="/about/:startPage" element={<AboutSection />}>
                    </Route>
                    <Route path="/ourroots" element={<OurRoots />}>
                    </Route>
                    <Route path="/signUp" element={<ZenSignUp />}>
                    </Route>
                    <Route path="/signUp/:refferer" element={<ZenSignUp />}>
                    </Route>
                    <Route path="/registrate" element={<ZenSignUp lang={"ESP"} />}>
                    </Route>
                    <Route path="/logIn" element={<LogIn />}>
                    </Route>
                    <Route path="/resetpassword" element={<ResetPassword />}>
                    </Route>
                    <Route path="/changepassword" element={<ResetPassword />}>
                    </Route>
                    <Route path="/account" element={<AccountDetails />}>
                    </Route>
                    <Route path="/zenbot/about" element={<AboutZenBot />}>
                    </Route>
                    <Route path="/zenbot" element={<ProvideZenQuotasContext><ZenBotHome /></ProvideZenQuotasContext>}>
                    </Route>
                    <Route path="/explore/zenbot" element={<ProvideZenQuotasContext><ZenBotHome /></ProvideZenQuotasContext>}>
                    </Route>
                    <Route path="/shop/basket" element={<PrivateRoute><ZenShop basket={true} /></PrivateRoute>}>
                    </Route>
                    <Route path="/shop/basket/added" element={<PrivateRoute><ZenShop basket={true} newItem = {true}/></PrivateRoute>}>
                    </Route>
                    <Route path="/explore/shop" element={<ZenShop />}>
                    </Route>
                    <Route path="/explore/shop/tshirt/buy" element={<BuyTShirtsModal />}>
                    </Route>
                    {/* <Route path="/explore/shop/order/start" element={<ZenShop />}>
                  </Route>
                  <Route path="/shop/order/start" element={<ZenShop />}>
                  </Route> */}
                    <Route path="/tshirtPaymentSuccess/:id" element={<ConfirmTShirtPurchase />}>
                    </Route>
                    <Route path="/stickerPaymentSuccess/:id" element={<ConfirmStickerPurchase />}>
                    </Route>
                    <Route path="/explore/shop/stickers/:type" element={<BuyStickersModal />}>
                    </Route>
                    <Route path="/explore/shop/retreats" element={<ZenShop retreats={true} />}>
                    </Route>
                    <Route path="/explore/shop/retreats/:type" element={<RetreatBrowser />}>
                    </Route>
                    <Route path="/explore/subscriptions" element={<PrivateRoute><ProvideZenQuotasContext><ZenSubscriptionsTiersBrowser /></ProvideZenQuotasContext></PrivateRoute>}>
                    </Route>
                    <Route path="/subscriptions/create/:subscriptionTier" element={<CreateSubscriptionModal />}>
                    </Route>
                    <Route path="/subscriptions/upgrade/:subscriptionTier" element={<PrivateRoute><ProvideZenQuotasContext><UpgradeSubscriptionModal /></ProvideZenQuotasContext></PrivateRoute>}>
                    </Route>
                    <Route path="/subscriptions/downgrade/:subscriptionTier" element={<PrivateRoute><ProvideZenQuotasContext><DowngradeSubscription /></ProvideZenQuotasContext></PrivateRoute>}>
                    </Route>

                    <Route path="/subscribed/:subscriptionTier/:subscriptionId" element={<SubscriptionConfirmation />}>
                    </Route>
                    <Route path="/termsAndConditions" element={<ZenUserTermsAndConditions />}>
                    </Route>

                    <Route path="/createTextCover" element={<TextCoverImageStudio />}>
                    </Route>

                    <Route path="/zenStream/walkthrough" element={<StreamWalkthrough />}>
                    </Route>
                    <Route path="/zenStream" element={<ZenHome />}>
                    </Route>
                    <Route path="/zenPortalWalkthrough" element={<ProvideZenverseContext><StarterPortal /></ProvideZenverseContext>}>
                    </Route>
                    <Route path="/myzen" element={<MyZenProfile />}>
                    </Route>
                    <Route path="/myzen/mysubscription" element={<PrivateRoute><ProvideZenQuotasContext><MySubscription /></ProvideZenQuotasContext></PrivateRoute>}>
                    </Route>
                    <Route path="/meditation/:id" element={<PracticeSummary />}>
                    </Route>
                    <Route path="/meditations/:id" element={<PracticeSummary />}>
                    </Route>
                    <Route path="/practice/:id" element={<PrivateRoute><ProvideZenverseContext><ZenMediaPlayer /></ProvideZenverseContext></PrivateRoute>}>
                    </Route>
                    <Route path="/explore" element={<ExploreBrowser />}>
                    </Route>
                    <Route path="/explore/communities" element={<ProvideMyCommunityContext><PrivateRoute><ZenCommunitiesBrowser /></PrivateRoute></ProvideMyCommunityContext>}>
                    </Route>
                    <Route path="/explore/communities/:searchRoute" element={<ProvideMyCommunityContext><PrivateRoute><ZenCommunitiesBrowser /></PrivateRoute></ProvideMyCommunityContext>}>
                    </Route>
                    <Route path="/explore/zencards" element={<ProvideZenCardsContext><PublicRoute><ZenCardsBrowser /></PublicRoute></ProvideZenCardsContext>}>
                    </Route>
                    <Route path="/explore/zencards/:searchRoute" element={<ProvideZenCardsContext><PublicRoute><ZenCardsBrowser /></PublicRoute></ProvideZenCardsContext>}>
                    </Route>
                    <Route path="/explore/meditations/:searchId" element={<ExploreBrowser meditations={true} />}>
                    </Route>
                    <Route path="/explore/courses" element={<CourseBrowser />}>
                    </Route>
                    <Route path="/explore/courses/:searchId" element={<CourseBrowser />}>
                    </Route>
                    <Route path="/explore/course/:id" element={<PathSummary />}>
                    </Route>
                    <Route path="/explore/article/:searchId" element={<ProvideZenCardsContext><ZenArticle /></ProvideZenCardsContext>}>
                    </Route>
                    <Route path="/explore/articles" element={<ProvideZenCardsContext><ExploreArticles /></ProvideZenCardsContext>}>
                    </Route>
                    <Route path="/explore/articles/:searchId" element={<ProvideZenCardsContext><ExploreArticles /></ProvideZenCardsContext>}>
                    </Route>
                    <Route path="/explore/thezencyclopedia" element={<ProvideZenCardsContext><InformationBrowser infomatic={true} /></ProvideZenCardsContext>}>
                    </Route>
                    <Route path="/explore/thezencyclopedia/:searchId" element={<ProvideZenCardsContext><InformationBrowser infomatic={true} /></ProvideZenCardsContext>}>
                    </Route>
                    <Route path="/explore/information" element={<ProvideZenCardsContext><InformationBrowser infomatic={true} /></ProvideZenCardsContext>}>
                    </Route>
                    <Route path="/explore/:searchId" element={<ExploreBrowser />}>
                    </Route>
                    <Route path="/explore/zenscapes" element={<ProvideZenverseContext><ZenscapeBrowser /></ProvideZenverseContext>}>
                    </Route>
                    <Route path="/zenverse" element={<ProvideZenverseContext><ZenscapeBrowser /></ProvideZenverseContext>}>
                    </Route>
                    <Route path="/zenverse/myportals/:searchId" element={<ProvideZenverseContext><ZenscapeBrowser /></ProvideZenverseContext>}>
                    </Route>
                    <Route path="/zenverse/:searchId" element={<ProvideZenverseContext><ZenscapeBrowser /></ProvideZenverseContext>}>
                    </Route>
                    <Route path="/search/:searchId" element={<ExploreBrowser />}>
                    </Route>
                    <Route path="/zencyc" element={<ExploreBrowser />}>
                    </Route>
                    <Route path="/shop/tshirts" element={<ZenShop tshirts={true} />}>
                    </Route>
                    <Route path="/shop/stickers" element={<ZenShop stickers={true} />}>
                    </Route>
                    <Route path="/shop" element={<ZenShop />}>
                    </Route>
                    <Route path="/zenShop" element={<ZenShop />}>
                    </Route>
                    <Route path="/privacy" element={<PrivacyStatement />}>
                    </Route>
                    <Route path="/info/:id" element={<ExploreBrowser infomatic={true} />}>
                    </Route>
                    <Route path="/planner" element={<MyZenPlanner />}>
                    </Route>
                    <Route path="/bookmarks" element={<ProvideMyStuffContext><MyZenPlanner bookmarks={true} /></ProvideMyStuffContext>}>
                    </Route>
                    <Route path="/favourites" element={<ProvideMyStuffContext><MyZenPlanner favourites={true} /></ProvideMyStuffContext>}>
                    </Route>
                    <Route path="/plannerwalkthrough" element={<MyZenPlanner walkthroughActive={true} />}>
                    </Route>
                    <Route path="/journal/practices" element={<ProvideZenJournalContext><MyZenJournal startFilter="Practices" /></ProvideZenJournalContext>}>
                    </Route>
                    <Route path="/journal/walkthrough" element={<ProvideZenJournalContext><ZenJournalWalkthrough /></ProvideZenJournalContext>}>
                    </Route>
                    <Route path="/journal" element={<ProvideZenJournalContext><MyZenJournal /></ProvideZenJournalContext>}>
                    </Route>
                    <Route path="/goals/walkthrough" element={<ZenGoalsRouter walkthrough={true} />}>
                    </Route>
                    <Route path="/philzentropy" element={<Philzentropy />}>
                    </Route>
                    <Route path="/goals" element={<ZenGoalsRouter />}>
                    </Route>
                    <Route path="/timer" element={<SimpleCountDownTimer time={30} onFinish={() => { alert("FINISHED"); }} />}>
                    </Route>
                    <Route path="/practitioner/readingList/:practitioner" element={<PractitionerReadingList />}>
                    </Route>
                    <Route path="/practitioner/:practitonerId" element={<ProvideMyCommunityContext><PractitionerProfile /></ProvideMyCommunityContext>}>
                    </Route>
                    <Route path="/community/:communityId" element={<ProvideMyCommunityContext><CommunityProfile /></ProvideMyCommunityContext>}>
                    </Route>
                    <Route path="/community/:communityId" element={<ProvideMyCommunityContext><CommunityProfile /></ProvideMyCommunityContext>}>
                    </Route>
                    <Route path="/zenergyPaymentSuccess/:stripeConfirmation" element={<div><ZenergyPaymentComplete /></div>}>
                    </Route>
                    <Route path="/donationPaymentSuccess/:amount" element={<DonationSuccessful />}>
                    </Route>
                    <Route path="/gwtw" element={<GongWithTheWindPortal />}>
                    </Route>
                    <Route path="/zenergy/purchase" element={<BuyZenergyPoints />}>
                    </Route>
                    <Route path="/donate" element={<ZencyclopediaDonation />}>
                    </Route>
                    <Route path="/practitionerwelcome" element={<PractitionerWelcome />}>
                    </Route>
                    <Route path="/oneofus" element={<BecomeAPractitioner />}>
                    </Route>
                    <Route path="/zenQuiz" element={<ZenQuiz />}>
                    </Route>
                    <Route path="/cancelsubscription" element={<PrivateRoute><ProvideZenQuotasContext><CancelSubscriptionModal /></ProvideZenQuotasContext></PrivateRoute>}>
                    </Route>

                    {/* <Route path="/zenergy/purchase" element={<Elements stripe={stripePromise} {...options}>
                      <ConfirmZenergyPayment zenergyPoints= {500}/>
                    </Elements>}>
                    </Route>
                    <Route path="/zenergy/purchase/:searchId" element={<Elements stripe={stripePromise} options={options}>
                      <PurchaseZenergyPointsModal />
                    </Elements>}>
                    </Route> */}
                  </Routes>
                </Router>
              </ProvideZenShopContext>
            </ProvideZenCardsContext>
          </ProvideMyZenContext>
        </ProvidezenSearchContext>
      </ProvidezenExploreContext>
    </ProvideMyStuffContext>
  </ProvideAuth >
  </ThemeProvider>

  //</ProvideGoogleAnalyticsContext>
  ,
  document.getElementById("root")
);

