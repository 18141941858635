import React, { useState } from 'react';
import "../../../styling/vacana/vacana.scss";
import "../../../styling/common/index.scss";
import "../../../styling/common/simpleCountdown.scss";
import "../../../styling/zenverse/bubbleEnergy.scss";
import "../../../styling/stream/stream.scss";
import "../../../styling/zenverse/Zenverse.scss";
import { useCountdownTimer } from 'use-countdown-timer';

export const SimpleCountDownTimer = (props:any) => {

  
  const { countdown, start, reset, pause, isRunning } = useCountdownTimer({
    timer: 1000 * props.time, 
    onExpire: () => props.timerFinished()
  });

  const [walkthroughStage, setWalkthroughStage] = useState(1);
  const [practicePlaying, setPracticePlaying] = useState(false);
  const [speed, setSpeed] = useState("med");
  //const [timeRemaining, setTimeRemaining] = useState(null);

  // const [countdown, setCountdown] = useState(null);
  // const [countType, setCountType] = useState(null);

  if (walkthroughStage > 5 && !practicePlaying) {
    setPracticePlaying(true);
  }
  const minutesRef:any = React.createRef();
  const secondsRef:any = React.createRef();
  const startRef:any = React.createRef();
  const pauseRef:any = React.createRef();
  const resetRef:any = React.createRef();
  const countdownRef:any = React.createRef();
  const countTypeRef:any = React.createRef();
  const remainingTimeRef:any = React.createRef();


  var totalSeconds = Math.floor(countdown / 1000);
  var mins = Math.floor(totalSeconds / 60);
  var seconds = totalSeconds % 60;

  return (
    <div className = "timer-holder-pmdro">
<div id="pomodoro">
  <div id="clock">
    <div id="timer">
        {!isRunning?
      <div id="title">Ready?</div>:
      <div id="title">Relax.</div>
        }
      <div id="countdown">
        <span id="minutes" ref={minutesRef}>{mins}</span>
        <span id="seconds">
          {seconds<10? 
          <>

          0{seconds}
          </>
          :
          seconds
          }</span>
      </div>
      <div id="controls" className="reset">
        {!isRunning?
        <div id="start" onClick = {() => start()}><i className="fas fa-play"></i> Start</div>
        :
        <div id="start" onClick = {() => pause()}><i className="fas fa-play"></i> Pause</div>
        }
        {/* <div id="reset"><i class="fas fa-sync-alt"></i> Reset</div> */}
      </div>
    </div>
  </div>
</div></div>
  );
}