import * as React from "react";
import '../../styling/common/index.scss';
import { useState } from "react";
import { useAuth } from "../../hooks/auth";
import ReactCodeInput from 'react-verification-code-input';

export const ValidateSignUp = (props: any) => {

  const auth = useAuth();

  const [code, setCode] = useState("");
  const [loginAttempted, setloginAttempted] = useState(false);
  const [validationResent, setValidationResent] = useState(false);
  const [loginSuccess, setloginSuccess] = useState(false);

  const executeValidate = async () => {

    const result = await auth.validate(auth.username, code);

    if (result.success) {
      props.next();
    } else {
      setloginAttempted(true);
      setloginSuccess(false);
    }
  };

  const resendValidate = async () => {

    const result = await auth.resendValidate(auth.username);
    if (result.success) {
      setValidationResent(true);
      setTimeout(() => { setValidationResent(false) }, 25000);
    }
  };

  return (

    <div className="sign-up-details-container">
      <h1 className="val-email-header okashi">Validate Email</h1>
      <h3 className="val-email-prompt zenK">You should have received a confirmation code to your email. Enter it below to confirm your account</h3>
      <div className="EnterValidationCodeHolder" >
        <ReactCodeInput
          className="email-validator"
          autoFocus={false}
          onChange={(e) => setCode(e)} />
      </div>
      {loginAttempted && !loginSuccess ? <h4>Validation Failed!</h4>
        : null
      }

      {validationResent ? <div className="ValidationResent" >Another confirmation code has been sent to your email</div> : null}

      <div className="zenc-button-row" style = {{marginTop:"25px"}}>
        <div className="zencTextButton zencRedButton" onClick={() => { if (!validationResent) { resendValidate() } }} style={validationResent ? { opacity: 0.7 } : { opacity: 1 }}>
          <h3>Resend Code</h3>
        </div>
        <div className="zencTextButton zencGreenButton" onClick={() => executeValidate()}>
          <h3>Confirm</h3>
        </div>
      </div>
    </div>
  );
}






