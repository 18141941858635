

import * as React from "react";
import '../../styling/common/index.scss';
import { useState } from "react";
import axios from "axios";
import { useAuth } from "../../hooks/auth";
import { ZEN_C_ENDPOINT } from "../../config/EnvironmentalVariables";
import Draggable, { DraggableData, DraggableEvent, DraggableEventHandler } from "react-draggable";
import { Link } from "react-router-dom";
import { ZenSliderQuiz } from "./ZenSliderQuiz";
import { useZenCardsService } from "../../hooks/ZenCardsContext";
import { ZenCardMilestoneNotice } from "../explore/icons/ZenCardMilestoneNotice";

export default interface SignUpQuizQuestion {
    value: string,
    id?: number,
    positiveTags?: string[],
    negativeTags?: string[],
    blockedByTags?: string[],
    negativeAffirmTags?: string[],
    occurances?: number,
}

export default interface ZenStatementTag {
    value: string,
    score?: number,
}


export const ZenQuiz = (props: any) => {

    const [page, setPage] = React.useState(0);
    //const [questionsAnswered,setQuestionsAnswered] = React.useState(0);
    let auth = useAuth();

    const [rewardCard, setRewardCard] = React.useState(null);

    const cardsContext = useZenCardsService();

    const FIRST_QUIZ_MILESTONE_KEY = "FQC_MS";

    const finishQuiz = async (tagResults: any) => {
        axios.post(ZEN_C_ENDPOINT + `/myzen/zenStatements/logQuizTags`, {
            username: auth.username,
            tagResults: tagResults
        });

        var cardExists = cardsContext.checkForMilestone(FIRST_QUIZ_MILESTONE_KEY);
        if (!cardExists) {
            var rewardCard = await cardsContext.claimMilestone(FIRST_QUIZ_MILESTONE_KEY);
            if (rewardCard != null && rewardCard.cardKey != null) {
                setRewardCard(rewardCard);
                setPage(3);
            } else {
                setPage(3);
            }
        } else {
            setPage(3);
        }

    }

    return (
        <React.Fragment>
            {page != 3 && rewardCard != null ?
                <div className="ming-page">
                    <ZenCardMilestoneNotice cards={[rewardCard]} moveOnAction={() => { setPage(3)}}></ZenCardMilestoneNotice>
                </div>
                :
                <React.Fragment>
                    {page == 0 ?
                        <div className="ming-page">
                            <div className="ming-page-text">
                                <h1>Zen Slider Quiz</h1>
                                <h3 className="ming-page-instructions-tag">instructions</h3>
                                <h4 className="navajo-text">Swipe right on a statement to agree with it</h4>
                                <h4 className="navajo-text">Swipe left on a statement to disagree</h4>
                                <h4 className="navajo-text">The statements on the page will refresh based on your answers in the quiz.</h4>
                                <h4 className="navajo-text">Don't worry! We won't share your answers with anyone, ever. We'll only use them to tailor our reccomendations in your Zen Stream.</h4>
                                <div className="zenGlowButton navajoGlowButton" onClick={() => setPage(1)}>let's go!</div>
                            </div>
                        </div>
                        : page == 1 ?
                            <ZenSliderQuiz finishQuiz={(results) => finishQuiz(results)} />
                            :
                            <div className="ming-page">
                                <div className="ming-page-text">
                                    <h1>Well Done!</h1>
                                    {/* <h4>you earnt 15 ZENergy points!</h4> */}
                                    <h4 className="navajo-text">We'll use your answers to improve our future reccomendations to you.</h4>
                                    <Link to="/myZen"><div className="zenGlowButton navajoGlowButton">done</div></Link>
                                </div>
                            </div>
                    }
                </React.Fragment>}
        </React.Fragment>

    );

}












// const checkForGroupClash = (statement1: SignUpQuizQuestion, statement2: SignUpQuizQuestion) => {
//     //return true;
//     var match = false;

//     //ensure that statement not included in answers.
//     if (state.answers) {
//         state.answers.map((statement) => {
//             if (statement1 && statement1.value && statement1.value.includes(statement.value)) {
//                 match = true;
//             }
//         });
//     }

//     //Check that state blacklisted tags does not include any of statement tags.
//     if (state.negativeTags) {
//         state.negativeTags.map((group) => {
//             if (statement1 && statement1.positiveTags && statement1.positiveTags.includes(group)) {
//                 match = true;
//             }
//         });
//     }

//     //Check that statement blacklisted tags are not included in state (flagged) tags
//     if (state.positiveTags) {
//         state.positiveTags.map((group) => {
//             if (statement1 && statement1.negativeTags && statement1.negativeTags.includes(group)) {
//                 match = true;
//             }
//         });
//     }

//     return match;
// }