import * as React from "react";
import "../../../styling/explore/explore.scss";
import "../../../styling/common/index.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { faYinYang } from '@fortawesome/free-solid-svg-icons'
import { ZenNavBar } from "../../common/navbar/ZenNavBar";
import { Link } from "react-router-dom";

export const ZencyclopaediaTextItemPreview = (props : {title:string, image?:any, photoBottom?:boolean, itemType?:string, translation?:string, itemResultId?:string, searchRoute?:string, colorClass?:string}) => {

    const sliderRef:any = React.createRef();

    const contentsItemLink = props.itemType && props.itemType == 'Infomatic' ?
    "/info/"
    : props.itemType && props.itemType == 'ZencycResultItem' ?
    "/search/"+props.itemResultId
    :
    props.itemType && props.itemType == 'zenShop' ?
    "/shop"
    :
    props.itemType && props.itemType == 'medBrowser' ?
    "/search/2"
    :
    props.itemType && props.itemType == 'zenverse' ? 
    "/zenverse"
    : props.searchRoute ? props.searchRoute
    :
    "";
    //ZencycResultItem
    //practitioner(s)/school/community
    //zenverse
    //meditation browser
    //zencyclopaedia
    //course browser
    var classN = props.colorClass? 'zencyclopaedia-contents-text-item ' + props.colorClass : 'zencyclopaedia-contents-text-item';

    return (
        <Link to={contentsItemLink}>
            
        <div className ='zencyclopaedia-contents-item-holder'>
            <div className ={classN}>
                <h1 className="zencyc-contents-text-item-header zenK"><b>{props.title}</b> <br/><span className = 'zencyc-contents-item-header-trans'>{props.translation}</span></h1>
            </div>
            </div>
        </Link> 

    );
  
}

