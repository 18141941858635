import * as React from "react";
import "../../../styling/common/index.scss";
import axios from "axios";
import { useAuth } from "../../../hooks/auth";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { ZEN_C_ENDPOINT } from "../../../config/EnvironmentalVariables";
import { SubscriptionEnroller } from "./SubscriptionEnroller";

export const SubscriptionConfirmation = (props: any) => {

  const [subSent, setSubSent] = React.useState(false);

  const { subscriptionTier } = useParams();
  const { subscriptionId } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  let pI = searchParams.get("payment_intent");
  let auth = useAuth();


    if (!auth.isLoading && !subSent) {
      setSubSent(true);
      axios.post(ZEN_C_ENDPOINT + `/myzen/mysubscription/confirm`, {
        username: auth.username,
        stripePaymentIntent: pI,
        subscriptionTier: subscriptionTier,
        subscriptionId:subscriptionId
      }).then(function (response) {

      }).catch(function (error) {
        console.log(error);
        console.log("failed to make t shirt confirm req")
      });
    }
    const nth = function(d) {
      if (d > 3 && d < 21) return 'th';
      switch (d % 10) {
          case 1:  return "st";
          case 2:  return "nd";
          case 3:  return "rd";
          default: return "th";
      }
  }

  const billingDate  = new Date(); 
  const billingDay = billingDate.getDate() + nth(billingDate.getDate());

  return (
    <React.Fragment>
      {/* <SubscriptionEnroller username = {auth.username} subscriptionTier = {subscriptionTier} paymentIntent = {pI}/> */}
      <div className="ZenBasicPage zen-page-holder" style={{ paddingTop: "35px" }}>

        <h1 className="okashi congrats-header" style={{ margin:"auto", textAlign:"center", marginBottom: 0 }}>Congratulations</h1>

        <h4 style={{ margin:"auto", textAlign:"center", marginTop: "25px" }}>You are now a {subscriptionTier=="PREMIUM"? "Premium" : "Basic"} subscriber</h4>

        {/* Check for discount... you will pay JUST 1.99 for X months, after that,  */}
        <h4 className="subscription-conf-billing-info" style ={{marginTop: "25px"}}>You will be charged {subscriptionTier=="PREMIUM"? "£1.99" : "99p"} on the {billingDay} of every month.</h4>
        <h4  className="subscription-conf-billing-info">You can easily manage your subscription from your MyZen Profile, the Zen Cards browser, or by chatting to Zen Bot!</h4>

        <Link to="/"><div className="zenGlowButton mingGlowButton" style ={{marginTop: "25px"}}>done</div></Link>

      </div>
    </React.Fragment>
  );
}

