import * as React from "react";
import "../../styling/zenverse/Zenverse.scss";
import "../../styling/common/zenCoreUIComponents.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // req
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useNavigate, useParams } from "react-router";
import { ZencyclopaediaItemPreview } from "../explore/previews/ZencyclopaediaItemPreview";
import { Link } from "react-router-dom";
import { ZencycyclopaediaPreviewGroup } from "../explore/ZencycyclopaediaPreviewGroup";
import PrivateRoute from "../common/PrivateRoute";
import { ZenNavBar } from "../common/navbar/ZenNavBar";
import { ZencyclopaediaTextItemPreview } from "../explore/previews/ZencyclopaediaTextItemPreview";
import { useZenverseService } from "../../hooks/ZenverseContext";
import { getPortalJSX } from "../../service/ZenverseService";
import { Configuration, OpenAIApi } from 'openai';
import Chatbot from "react-chatbot-kit";
import { ZencTitle } from "../home/ZencTitle";
import MetaTags from 'react-meta-tags';
import { ZenBotChatOne } from "./ZenBotChatOne";

export const AboutZenBot = () => {
  return (
    <div className="ZenBasicPage">
    <MetaTags>
      <meta id="meta-description" name="description" content={"Learn all about what our platform has to offer, the people behind it and our ambitous mission to empower people to take control of their mental health by offering free mindfulness resources."} />
      <meta id="og-title" property="og:title" content="Zen Bot - Zencyclopedia" />
    </MetaTags>
    <ZenNavBar home={true} />
    <ZencTitle />


    <div style={{ display: "flex", flexDirection: "row" }}>
      <div>
      <h2 className="okashi">What is Zen Bot?</h2>
      <h3 className = "justifiedText">Zen bot is a state of the art AI powered meditation assistant, capable of giving you advice on your meditation practice, help you navigate and understand our site, and answer anything you ever wanted to know about ZEN!
      </h3>
      </div>
              <div className="zenBotImage"></div>
      
      </div>
      
      <h2 className="okashi">The Making of Zen Bot</h2>
      <h3 className = "justifiedText">Built using a custom chatGPT model (davinci) which has been trained in the mysterious arts of zen through extensive dialogue as well as bveing fed informastion regarding Zen, 
        from the ancient texts of the east of Hindhusim, Buddism and Zen. </h3>

        <h2 className="okashi">Origins</h2>
      <h3 className = "justifiedText">When I first heard of chatGPT, generative AI, I had one thing on my mind, let's talk spirituality. 
        
        Does Ai have the answers to esoteric questions that have plague the minds of mortals for decades? 
        
        The following images show my first few conversations, on X & Y. 
        
        I hope you'll be just as pleasantly surprised with it's aptitude before I'd started feeding in my own opinion or even told it about Zencyclopedia.</h3>
      
      <h2>Chat 1: <span className = "okashi">PURPOSE</span></h2>
      <ZenBotChatOne/>

      <h2>Chat 2: </h2>

      <h2 className="okashi">The Future of Zen Bot</h2>
      <h3>... maybe mention zen bot themes, style after famus zen masters such as Bodi Darma, Dalai Lama, Lao Tzu
      currently developing ability to add audio and make zen bot talk (in the style of the late Alan Watts)</h3>

    </div>
  );

}
