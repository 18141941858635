import * as React from "react";
import "../../../../styling/stream/stream.scss"
import "../../../../styling/common/index.scss"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ZenContentInterface from "../ZenContentInterface";
import { Link } from "react-router-dom";
import { PracticePreviewControls } from "./PracticePreviewControls";
import { MeditationIcons } from "../MeditationIcons";

export const PracticeFullyFocusedPreview = (props: ZenContentInterface) => {

  var practicePortalLink = "/practice/" + props.id;
  return (
    <div className="FullyFocusedMeditationStreamItem stream-item" >
      <div className="focused-preview-overlay-text">
        <h1 className="fully-focused-med-title okashi">
          {props.title}
        </h1>
        <h2 className="fully-focused-med-subheader zenK">
          {props.description.substring(0,175)}{props.description.length>175?'...':null}
        </h2>
      </div>
      <div className="FullyFocusedMeditationStreamItemImageHolder">

        <img className="FocusedMeditationStreamItemImage" src={props.image} >

        </img>
      </div>
      <div className = "zen-desktop">
      <PracticePreviewControls {...props} />
      </div>
      
      <div className="focused-preview-overlay-text-mobile" >
        <h1 className="fully-focused-med-title okashi">
          {props.title}
        </h1>
        <h2 className="fully-focused-med-subheader zenK" style = {{paddingLeft:"25px", paddingRight:"25px"}}>
          {props.description.substring(0,200)}
        </h2>
        <Link to={"/meditation/" + props.id}>
          <h4 style={{ width: "100%", margin: "auto", textAlign: "center", marginTop: "15px", marginBottom: "20px" }}>See More</h4>
        </Link>
        <MeditationIcons {...props} />
        <PracticePreviewControls {...props} />
      </div>






    </div>
  );

}