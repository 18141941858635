import * as React from "react";
import { OurRootsTranslation } from "../../content/ZenHome/OurRootsTranslations";
import '../../styling/common/index.scss';
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom";
import { ZencTitle } from "./ZencTitle";
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ZenNavBar } from "../common/navbar/ZenNavBar";

export const ContactUs = (props: any) => {

  const [showToadInfo, setShowToadInfo] = React.useState(false);
  const [showAlexiInfo, setShowAlexInfo] = React.useState(false);

  return (

    <div className="ZenBasicPage" style={{ paddingBottom: "150px" }}>
      <MetaTags>
        <meta id="meta-description" name="description" content={"Learn all about what our platform has to offer, the people behind it and our ambitous mission to empower people to take control of their mental health by offering free mindfulness resources."} />
        <meta id="og-title" property="og:title" content="Contact Us - Zencyclopedia" />
      </MetaTags>
      <ZenNavBar home={true}></ZenNavBar>
      <div style={{ paddingBottom: "200px" }}>
        <h3>Want to get in contact? Why not connect with us on social media?</h3>
        <div style={{ display: "flex", flexDirection: "row", marginTop: "25px", marginBottom: "75px" }}>

          <FontAwesomeIcon icon={faInstagram} className='homeSM' onClick={() => { window.open('https://instagram.com/zencyclopaedia?igshid=NTc4MTIwNjQ2YQ==', '_blank'); }} />
          <FontAwesomeIcon icon={faTwitter} className='homeSM' />
          <FontAwesomeIcon icon={faTiktok} className='homeSM' />
          <FontAwesomeIcon icon={faFacebookF} className='homeSM' />
        </div>
        <h3>For any general enquiries, please reach out to:</h3>
        <h4><a href="mailto:contact@zencyclopedia.co.uk"><b>contact@zencyclopedia.co.uk </b> </a></h4>

        <div style = {{paddingTop:"75px"}}>

          <ZencTitle />
          <div style={{ display: "flex", flexDirection: "row", padding:"40px", paddingTop:"0" }}>
            <div className = "compHouseLogo"></div>
            <div style = {{padding:"8px"}}>
              <h3 style = {{marginBottom:"0"}}><b>ZENcyclopaedia LTD</b></h3>
              <h4 style = {{marginTop:"0"}}>Company Number: 14127976</h4>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}