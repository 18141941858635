import * as React from "react";
import '../../../styling/common/index.scss';
import { ZenNavBar } from "../../common/navbar/ZenNavBar";
import { ZencTitle } from "../ZencTitle";
import { Link } from "react-router-dom";
export const BecomeAPractitioner = (props: any) => {

  return (

    <div className="ZenBasicPage">

      <ZenNavBar />
      <ZencTitle />

      <div className="philzentopry-text">

        <h2>Calling all aspiring practitioners!</h2>
        <h3 style={{ textAlign: "justify" }}>If you're passionate about sharing uplifting and positive content, while also having the opportunity to earn income, Zencyclopedia welcomes you. We are actively seeking experienced individuals in meditation and mindfulness to become practitioners on our platform. By creating your own following and sharing inspiring content, you can make a positive impact and guide others on their personal growth journey.
        </h3>

        <h4 style={{ textAlign: "justify"}}>
          We are dedicated to building a rich and diverse practice library, and we believe in the power of community and shared wisdom. That's why we invite experienced individuals in meditation and mindfulness to join us as practitioners. 
          <br></br><br></br>As a practitioner, you can contribute your own valuable content to our site, sharing your insights, techniques, and teachings with the Zencyclopedia community. With time and dedication, even our users have the opportunity to become practitioners by using Zencyclopedia extensively and adhering to our conduct guidelines. By fostering this collaborative environment, users can follow practitioners, access their unique content, and together, we can build a vibrant ecosystem of knowledge and growth.
        </h4>

        <h4 style={{ textAlign: "justify"}}>
          We offer a larger share of ad & subscription revenue back to our practitioner community than our competitiors such as YouTube, currently we are committed to sharing 60% of ad revenue and at least 55% of subscriptions.
        </h4>
          
        <div className="white-page-inset" >
          To be considered for early access as a practitioner:<br></br>
          <Link to = "/practitionerWelcome">
          <div className = "zenGlowButton mingGlowButton" style = {{marginTop:"15px"}}>Check out the practitioner Welcome Page</div>
          </Link>
          Or drop us an email: <a href="mailto:contact@zencyclopedia.co.uk"><b>contact@zencyclopedia.co.uk </b> </a> <br></br><br></br>Join us in uplifting lives through the power of mindfulness and positive content.
        </div>




          {/* <br></br><br></br>
          In addition to our community-driven approach, Zencyclopedia harnesses the power of data analytics to provide you with the most relevant content. Through analyzing user preferences and behavior, we tailor our recommendations to your specific needs and interests. This enables us to offer a constant flow of fresh content, covering a vast range of meditation and spiritual topics.
        </h4> */}

        {/* <h4 className="justifiedText">Zencyclopedia is the social network built with mindfulness in mind. We're trying to build something new and different and disrupt the game! </h4>
      <h4 className="justifiedText">Most meditation platforms are simply a catalogue of videos you have to pay to access, Rather than showing everyone the same meditaions, we want to build a large practice library by allowing experienced users to post content onto our site</h4>
      <h4 className="justifiedText"> This will allow us to use data analytics to recommend the most relevant content to our users, we can offer fresh content regularly and cover the many topics around the vast meditation and spirituality.</h4> 
      
      <h4 className="justifiedText">We're positive we can out compete the other video sharing platforms that independent 'Yogis' currently use to share their original content such as YouTube, since all our algorithms are built with spiritual growth in the front of our minds. We also promise to share a larger return of our revenue with our content creators - and currently we have plaged 50% of our profits to the ZenC Creator Fund</h4> 
      
      <h2>So you think you have what it takes...</h2>
      
      <h4 className="justifiedText"> Want to get rewarded with real money for creating uplifting and positive content on ZENcyclopedia? </h4> 
      <h4 className="justifiedText"> This could be in the form of your own original guided meditations, or simply by sharing quotes and other uplifting posts to our Zen Stream!</h4> 
      <h4 className="justifiedText"> Get in touh by emailing tom.woodhouse@zencyclopedia.co.uk if you want to be considered for early practitioner access. </h4>  */}


      </div>
    </div>
  );
}

