import * as React from "react";
import "../../../styling/explore/explore.scss";
import "../../../styling/common/index.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";


export const ZenSearchFilterTagsDisplay = (props: any) => {

    return (
        <div className="zen-search-tag-filters-container">
            <div className="zen-search-tag-filters">
                {props.activeTags.filter(t => t != null && t.length > 0).map(at => <div className="zen-search-filter-tag-holder"><div className="zen-search-filter-tag active-filter-tag" onClick = {() => props.deActivateTag(at)}>{at}</div></div>)}
                {props.suggestedTags != null && props.suggestedTags.length > 0 ? props.suggestedTags.map(st => <div className="zen-search-filter-tag-holder"><div onClick={() => props.activateTag(st)} className="zen-search-filter-tag">{st}</div></div>) : null}
            </div>
        </div>
    );
}
