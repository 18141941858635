import React, { useState } from 'react';
import "../../../styling/vacana/vacana.scss";
import "../../../styling/common/index.scss";
import axios from 'axios';
import { Link } from 'react-router-dom';
import { ZEN_C_ENDPOINT } from '../../../config/EnvironmentalVariables';
import PractitionerInterface from '../../practitioner/PractitionerInterface';
import ZenContentInterface from './ZenContentInterface';
import { ZenLoader } from '../../common/ZenLoader';
import { ZenPicSizer } from '../../practitioner/ZenPicSizer';

export const MeditationIcons = (props: ZenContentInterface) => {


    const [practitioner, setPracitioner] = useState(null as PractitionerInterface);
    const [getTries, setGetTries] = useState(0);
    const [searchPending, setSearchPending] = useState(null);

    const getPractitionerInfo = (username: string) => {
        setSearchPending(true);
        axios.get(ZEN_C_ENDPOINT + `/myzen/practitioner/` + username)
            .then(res => {
                if (res.data) {
                    setPracitioner(res.data);
                }
            })

    }


    if (practitioner == null && props.practitioner != null && getTries < 5 && !searchPending) {
        setGetTries(getTries + 1);
        getPractitionerInfo(props.practitioner);
    }

    const practiceLength = props && props.durationSeconds ? Math.round(props.durationSeconds / 60) : "-";

    return (

        <div className="med-icons" >
            <div className="med-icon">
                {practitioner && practitioner.image ?
                    <Link to={practitioner ? "/practitioner/" + practitioner.username : null}>
                        <div>
                            <div className="PractitionerPhotoIcon meditation-icons-practitioner-image" >
                                <ZenPicSizer
                                    imageToShow={practitioner.image}
                                    cropX={practitioner.imageCropX}
                                    cropY={practitioner.imageCropY}
                                    cropWidth={practitioner.imageCropWidth}
                                    newWidth={70}
                                />
                            </div>
                            <h3 className="PractitionerName"> {practitioner ? practitioner.username : 'TomAndHisCat'} </h3>
                        </div>

                    </Link>
                    : null
                }
            </div>
            <div className="med-icon">
                <h1 className="iconNumber">{practiceLength}</h1>
                <h2 className="iconMetric">Minutes</h2>
            </div>
            <div className="med-icon zenK" style={{ color: "#165369" }}>
                <h1 className="iconNumber">+15</h1>
                <h2 className="iconMetric">ZENergy</h2>
            </div>
            {/* <div className = "med-icon zenK" style={{color:"#033f63" }}>
                <h1 className="iconNumber"><FontAwesomeIcon icon={faPlus} style={{fontSize:"75%" }} />5 </h1>
                <h2 className="iconMetric">META</h2>
            </div>
            <div className = "med-icon zenK" style={{color:"#28666E" }}>
                <h1 className="iconNumber"><FontAwesomeIcon icon={faPlus} style={{fontSize:"75%" }}/>7</h1>
                <h2 className="iconMetric">ZEN</h2>
            </div> */}
        </div>
    );
}