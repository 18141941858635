import React, { useState } from 'react';
import "../../../../styling/vacana/vacana.scss";
import "../../../../styling/common/index.scss";
import "../../../../styling/zenverse/bubbleEnergy.scss";
import "../../../../styling/stream/stream.scss";
import "../../../../styling/zenverse/Zenverse.scss";
import OhmSoundInterface from '../OhmBoard/OhmSoundInterface';
import { GongPreview } from './GongPreview';
import { ZEN_C_ENDPOINT } from '../../../../config/EnvironmentalVariables';
import Slider from 'react-slick';
import { SimpleCountDownTimer } from '../SimpleCountDownTimer';
import { GongCounter } from './GongCounter';
import { useGongSoundContext } from '../../../../hooks/sound/GongSoundContext';

export interface GongInterface extends OhmSoundInterface {
  clickAction?: any,
  isPrimaryGong?: boolean,
  isFinishGong?: boolean,
  playGongPreview?: any,
  previewPlaying?: boolean
}

export const GongWithTheWindPortal = () => {

  const [gongSessionDuration, setGongSessionDuration] = useState(null);
  const [primaryGong, setPrimaryGong] = useState(null);
  const [startFinishGong, setStartFinishGong] = useState(null);
  const [page, setPage] = useState("set-gong");
  const [finishGongSoundSource, setFinishGongSoundSource] = useState(null);
  const [previewPlaying, setPreviewPlaying] = useState(false);

  let gongSoundContext = useGongSoundContext();

  const startGongTimer = async () => {

    gongSoundContext.playGong("PRIMARY", 11 * 1000);
    gongSoundContext.playGong("START");
    setPage("gonging");

  }

  const playGongPreview = (gongName: string) => {
    if (!previewPlaying) {
      setPreviewPlaying(true);
      gongSoundContext.soundGongPreview(gongName);
      setTimeout(() => { setPreviewPlaying(false); }, 10000);
    }
  }

  let gongOptions = [{
    "title": "Bao Gong",
    "audio": "Buddha Bowls",
    "image": require('../../../../static/photos/zenscapes/Gong.png')
  }, {
    "title": "Buddha Bowls",
    "audio": "Buddha Bowls",
    "image": require('../../../../static/photos/zenscapes/Soundbowlsportal.png')
  }, {
    "title": "Standing Gong",
    "audio": "Buddha Bowls",
    "image": require('../../../../static/photos/zenscapes/Standinggong.png')
  }] as OhmSoundInterface[];

  return (
    <div style={{ position: "relative" }}>
      <div className="zen-page-holder GongWithWindPage">
        {page == "set-gong" || page == "set-finish-gong" ?
          <div className="gongs-holder">
            <div className="gwtw-option-header splashedBacking" style={{ paddingTop: "0" }}>
              <div style={{ position: "fixed", top: "45px", margin: "auto", width: "100%", textAlign: "center" }}>
                {page == "set-gong" ?
                  <h2>Choose Your Primary Gong</h2>
                  : page == "set-finish-gong" ?
                    <h2>Choose Your Start/Finish Gong</h2>
                    : null}
              </div>
              <div className="primary-gong-options">
                <div className="zen-mobile">
                  <Slider className="zen-portal-slider"
                    centerMode={true}
                    centerPadding='20%'>
                    {gongOptions.map(g => {
                      return (<GongPreview {...g} clickAction={() => {
                        if (page == "set-gong") {
                          console.log("creating primary gong");
                          setPrimaryGong(g);
                          gongSoundContext.createSound(g.title, "PRIMARY", true);
                          setPage("set-finish-gong");
                        } else {
                          setStartFinishGong(g);
                          gongSoundContext.createSound(g.title, "START", false);
                          gongSoundContext.createSound(g.title, "FINISH", false);
                          setTimeout(() => {
                            setPage("gong-duration");
                          }, 1500);
                        }
                      }}
                        isPrimaryGong={primaryGong && primaryGong.title == g.title}
                        isFinishGong={startFinishGong && startFinishGong.title == g.title}
                        playGongPreview={() => playGongPreview(g.title)}
                        previewPlaying={previewPlaying}
                      />)
                    })}
                  </Slider>
                </div>
                <div className="zen-desktop">
                  <Slider className="zen-portal-slider"
                    centerMode={true}
                    centerPadding='30%'>
                    {gongOptions.map(g => {
                      return (<GongPreview {...g} clickAction={() => {
                        if (page == "set-gong") {
                          setPrimaryGong(g);
                          gongSoundContext.createSound(g.title, "PRIMARY", true);
                          setPage("set-finish-gong");
                        } else {
                          setStartFinishGong(g);
                          gongSoundContext.createSound(g.title, "START", false);
                          gongSoundContext.createSound(g.title, "FINISH", false);
                          setTimeout(() => {
                            setPage("gong-duration");
                          }, 1500);
                        }
                      }}
                        isPrimaryGong={primaryGong && primaryGong.title == g.title}
                        isFinishGong={startFinishGong && startFinishGong.title == g.title}
                        playGongPreview={() => playGongPreview(g.title)}
                      />)
                    })}
                  </Slider>
                </div>
              </div>
            </div>

            <div className="gwtw-option-pair splashedBacking reveresedBacking portalBottomReversed">
              <div style={{ position: "fixed", bottom: "100px", rotate: "180deg", paddingTop: "25px", width: "100%", margin: "auto", textAlign: "center", fontSize: "80%" }}>
                {page == "set-gong" ?
                  <React.Fragment>
                    <h4 style={{ paddingLeft: "55px", paddingRight: "55px" }}>The primary gong will sound intermittently for the duration of your practice, intended to be the focus of your attention.</h4>
                  </React.Fragment>
                  : page == "set-finish-gong" ?
                    <React.Fragment>
                      <h4 style={{ paddingLeft: "55px", paddingRight: "55px" }}>The Start/Finish gong will sound to as the practice begins, and then will play continuously once your duration is up to bring a graceful end to your session.</h4>
                    </React.Fragment>
                    : null}
              </div>
            </div>
          </div>
          // : page == "gong-interval" ?
          //   <React.Fragment>
          //     <div className="gwtw-option-header splashedBacking" style={{ paddingTop: "35px" }}>
          //       <div style={{ position: "fixed", top: "45px", margin: "auto", width: "100%", textAlign: "center" }}>
          //       </div>
          //       <div style={{ marginLeft: "10vw", position: "fixed", zIndex: "99999999999", paddingTop: "35px", paddingBottom: "35px", width: "80vw" }}>
          //         <div className="t-shirt-color-options">
          //           {[10, 15, 20].map(t => <div className={gongInterval == t ? "t-shirt-size-name t-shirt-size-name-chosen" : "t-shirt-size-name"} onClick={() => { setGongInterval(t); setPage("gong-duration"); }}>{t}s</div>)}

          //         </div>
          //         <div className="t-shirt-color-options">
          //           {[25, 30, 45].map(t => <div className={gongInterval == t ? "t-shirt-size-name t-shirt-size-name-chosen" : "t-shirt-size-name"} onClick={() => { setGongInterval(t); setPage("gong-duration"); }}>{t}s</div>)}

          //         </div>
          //         <div className="t-shirt-color-options">
          //           {[1, 2, 3].map(t => <div className={gongInterval == t * 60 ? "t-shirt-size-name t-shirt-size-name-chosen" : "t-shirt-size-name"} onClick={() => { setGongInterval(t * 60); setPage("gong-duration"); }}>{t}m</div>)}

          //         </div></div>
          //     </div>

          //     <div className="gwtw-option-pair splashedBacking reveresedBacking">
          //       <div style={{ position: "fixed", bottom: "100px", rotate: "180deg", paddingTop: "60px", width: "100%", margin: "auto", textAlign: "center" }}>
          //         <h4 style={{ paddingLeft: "75px", paddingRight: "75px" }}> Choose the time between each hit of the gong!</h4>
          //       </div>
          //     </div>

          //   </React.Fragment>
          : page == "gong-duration" ?
            <React.Fragment>
              <div className="gwtw-option-header splashedBacking" style={{ paddingTop: "35px" }}>
                <h4></h4>
                <div style={{ marginLeft: "10vw", position: "fixed", zIndex: "99999999999", paddingTop: "35px", paddingBottom: "35px", width: "80vw" }}>
                  <div className="t-shirt-color-options">
                    {[2, 5, 10].map(t => <div className={gongSessionDuration == t ? "t-shirt-size-name t-shirt-size-name-chosen" : "t-shirt-size-name"} onClick={() => { setGongSessionDuration(t); setPage("summary"); }}>{t}m</div>)}

                  </div>
                  <div className="t-shirt-color-options">
                    {[15, 25, 30].map(t => <div className={gongSessionDuration == t ? "t-shirt-size-name t-shirt-size-name-chosen" : "t-shirt-size-name"} onClick={() => { setGongSessionDuration(t); setPage("summary"); }}>{t}m</div>)}

                  </div>
                  <div className="t-shirt-color-options">
                    {[1, 2, 3].map(t => <div className={gongSessionDuration == t * 60 ? "t-shirt-size-name t-shirt-size-name-chosen" : "t-shirt-size-name"} onClick={() => { setGongSessionDuration(t * 60); setPage("summary"); }}>{t}h</div>)}

                  </div></div>
              </div>

              <div className="gwtw-option-pair splashedBacking reveresedBacking">
                <div style={{ position: "fixed", bottom: "50px", rotate: "180deg", paddingTop: "60px", width: "100%", margin: "auto", textAlign: "center" }}>
                  <h4 style={{ paddingLeft: "75px", paddingRight: "75px" }}> Set the total length of your session</h4>
                </div>
              </div>

            </React.Fragment>
            : page == "summary" ? <React.Fragment>
              <div className="gwtw-option-pair splashedBacking" style={{ paddingTop: "35px" }}>

                <div className="gong-options-summary">
                  <div style={{ display: "flex", flexDirection: "row", margin: "auto", paddingTop: "0px", width: "fit-content" }}>
                    <h4 style={{ paddingTop: "25px" }}> &nbsp;&nbsp;&nbsp;Primary Gong:&nbsp;&nbsp;&nbsp;&nbsp;</h4><img className='gong-option-image chosen-gong-image' src={primaryGong.image} onClick={() => {
                    }}></img>
                  </div>

                  <div style={{ display: "flex", flexDirection: "row", margin: "auto", paddingTop: "25px", width: "fit-content" }}>
                    <h4 style={{ paddingTop: "25px" }}>Start/Finish Gong:&nbsp;</h4><img className='gong-option-image chosen-gong-image' src={startFinishGong.image} onClick={() => {
                    }}></img>
                  </div>
                  {/* 
                  <div style={{ display: "flex", flexDirection: "row", margin: "auto", paddingTop: "5px", width: "fit-content", textAlign: "center" }}>
                    <h4>Interval: {gongInterval}s</h4>
                  </div> */}

                  <div style={{ display: "flex", flexDirection: "row", margin: "auto", paddingTop: "5px", width: "fit-content" }}>
                    <h4>Duration: {gongSessionDuration}m</h4>
                  </div>
                  <div className='zenGlowButton mingGlowButton' onClick={() => startGongTimer()} >start</div>
             
                </div>
              </div>

              <div className="gwtw-option-pair splashedBacking reveresedBacking" style={{ display: "flex", flexDirection: "row", margin: "auto", paddingTop: "50px" }}>
                 </div>

            </React.Fragment>
              : page == "gonging" ? <React.Fragment>
                <div className="gwtw-option-pair splashedBacking" style={{ paddingTop: "35px" }}>

                  <div className="gong-options-summary">

                    <GongCounter time={gongSessionDuration * 60} timerFinished={() => {
                      finishGongSoundSource.start(0);
                      setPage("finished");
                    }}></GongCounter>

                    <div style = {{marginTop:"20px"}}className='zenGlowButton mingGlowButton' onClick={() => {
                      //stopSounds();
                      gongSoundContext.stopAllSound();
                      setPage("set-gong");
                    }}>finish</div>
                  </div>
                </div>

                <div className="gwtw-option-pair splashedBacking reveresedBacking" style={{ display: "flex", flexDirection: "row", margin: "auto", paddingTop: "50px" }}>


                </div>


              </React.Fragment>
                : page == "finished" ? <React.Fragment>
                  <div className="gwtw-option-pair splashedBacking" style={{ paddingTop: "35px" }}>

                    <div className="gong-options-summary">
                      <h1>Nice Work!</h1>
                    </div>

                  </div>

                  <div className="gwtw-option-pair splashedBacking reveresedBacking" style={{ display: "flex", flexDirection: "row", margin: "auto", paddingTop: "50px" }}>


                  </div>
                  <div className='zenGlowButton mingGlowButton' onClick={() => startGongTimer()}>start</div>
                </React.Fragment>
                  : null
        }



      </div >
    </div>
  );
}