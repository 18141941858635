import * as React from "react";
import '../../styling/common/index.scss';
import { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { ZencTitle } from "./ZencTitle";
import { ScrollDownHint } from "./ScrollDownHint";
import { ZenStream } from "./ZenStream";
import { useAuth } from "../../hooks/auth";
import { useLocation } from 'react-router';
import { useMyZenService } from "../../hooks/ZenUserContext";
import { ArtificalIntelligenceTranslation, BornInLondon, CuttingEdgeTranslation, HomeContentTranslations, HomeLogInPrompt, LogInText, MadeInBCNTranslation, MentalHealthEscapesTranslation, MindfulnessTrainingTranslation, OurRootsTranslation, ResourcesTranslationTranslation, SignUpButtonTranslations, SocialMediaFeelGoodTranslation, WhatDoWeOfferTranslation } from "../../content/HomeContentTranslations";
import { LogIn } from "./LogIn";
import { ReactSession } from 'react-client-session';
import { PuffLoader } from "react-spinners";
import { ProvideStreamContext } from "../../hooks/StreamContext";
import { ProvideZenQuotasContext } from "../../hooks/ZenQuotasContext";

export const ZenHome = () => {

  const location = useLocation();
  const [scrolled, setScrolled] = useState(false);
  const [showSMI, setShowSMI] = useState(true);
  const [showNav, setShowNav] = useState(true);

  let auth = useAuth();
  let userContext = useMyZenService();

  const [hasLoaded, setHasLoaded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const username = ReactSession.get("username");

  const loadUserDetails = async () => {
    setLoading(true);
    let log_in_name = username != null ? username : auth.username;
    if (log_in_name != null) {
      console.log("Acqired User Name of: " + log_in_name);
      await userContext.getProfile(log_in_name);
      //if not logged in in cognito, do this too!!!!!
      if (!auth.isAuthenticated || (username && auth.username != username)) {
        auth.signIn(log_in_name, ReactSession.get("mindfulnest"));
      }
      setHasLoaded(true);
    }
    setHasLoaded(true);
    setLoading(false);
  }

  if (!userContext.userInfoLoaded && !loading && !hasLoaded) {
    loadUserDetails();
  }

  const grantAccess = userContext.userInfoLoaded && auth.isAuthenticated && userContext.profile && auth.username == userContext.profile.username;

  const handleScroll = async (e) => {

    if (!scrolled) { setScrolled(true) };

    if ((e.target.scrollHeight - e.target.scrollTop < (1.9 * e.target.clientHeight))) {
      setShowSMI(false);
    }

    if (showNav && e.target.scrollTop > 60) {
      setShowNav(false);
    } else if (!showNav&& e.target.scrollTop < 60){
      setShowNav(true);
    } 
  }

  //if path is /home, go to home unless FULLY authenticated
  return grantAccess && (location.pathname != '/home') ? 
<ProvideZenQuotasContext>
  <ProvideStreamContext><ZenStream /></ProvideStreamContext>
  </ProvideZenQuotasContext>
   // && userContext.profile
    // : grantAccess && (location.pathname != '/home') ? if we have user details, but no password, send to log in!
    //   <LogIn></LogIn>
    : !hasLoaded && (location.pathname != '/home')?
      <div className="zencLoader">
        <PuffLoader color="#033f63" size={300}></PuffLoader>
      </div> : (<React.Fragment>
        <div className="ZenHomeHolder" onScroll={(e) => handleScroll(e)}>
          {showNav?
          <div style = {{width:"100%", position:"fixed", top:"0", display:"flex", flexDirection:"row", paddingTop:"7px"}}>
            <h3 style = {{margin:"auto"}}>Home</h3>
            <h3 style = {{margin:"auto"}}><Link to = "/about">About</Link></h3>
            <h3 style = {{margin:"auto"}}><Link to = "/contact">Contact</Link></h3>
          </div>:null}
          {showSMI ?
            <div className="home-social-media-icons">
              
              <FontAwesomeIcon icon={faInstagram} className='homeSM' onClick = {() => {window.open('https://instagram.com/zencyclopaedia?igshid=NTc4MTIwNjQ2YQ==', '_blank');}}/>
              <FontAwesomeIcon icon={faTiktok} className='homeSM' />
              <FontAwesomeIcon icon={faYoutube} className='homeSM' />
              <FontAwesomeIcon icon={faFacebookF} className='homeSM' />
            </div>
            :
            <div className="home-social-media-icons home-social-media-icons-faded">
              <FontAwesomeIcon icon={faInstagram} className='homeSM' onClick = {() => {window.open('https://instagram.com/zencyclopaedia?igshid=NTc4MTIwNjQ2YQ==', '_blank');}}/>
              <FontAwesomeIcon icon={faTiktok} className='homeSM'  onClick = {() => {window.open('https://www.tiktok.com/@zencyclopedia', '_blank');}}/>
              <FontAwesomeIcon icon={faYoutube} className='homeSM' />
              <FontAwesomeIcon icon={faFacebookF} className='homeSM' />
            </div>}

          {!scrolled ? <ScrollDownHint ></ScrollDownHint> : null}

          <div className="ZenHomeStreamItem zen-home-p1">
            <div className="zen-su-title-row">
              <div className="ZenClogoLarge"></div>
              <ZencTitle />
              <div className="sign-up-launch">
                <Link to={userContext.language == 'ESP' ? "/registrate" : "/signUp"}>
                  <div className="sign-up-here-button">
                    {userContext.language == 'ESP' ? HomeContentTranslations.SignUpButtonTranslations.Spanish : HomeContentTranslations.SignUpButtonTranslations.English}
                  </div>
                </Link>

                <h4 className="home-launch-li-text">
                  {userContext.language == 'ESP' ? HomeLogInPrompt.Spanish : HomeLogInPrompt.English}<Link to="/logIn"> {userContext.language == 'ESP' ? LogInText.Spanish : LogInText.English}</Link>
                </h4>
              </div>
            </div>
            <div className="home-choose-langauge">
              <div className="homeLanguageIcon">
                <div className="chooseEnglish" onClick={() => userContext.setLanguage("ENG")}></div>
              </div>
              <div className="homeLanguageIcon">
                <div className="chooseSpanish" onClick={() => userContext.setLanguage("ESP")}></div>
              </div>
            </div>
          </div>

          <div className="ZenHomeStreamItem alternte">
            <div className="ZenHomeAboutUs">
              <h2>{userContext.language == 'ESP' ? WhatDoWeOfferTranslation.Spanish : WhatDoWeOfferTranslation.English}</h2>
              <ul className="ZenHomeAboutUs-WWO-list">
                <li>
                  <h4 className="" >
                    {userContext.language == 'ESP' ? MindfulnessTrainingTranslation.Spanish : MindfulnessTrainingTranslation.English}</h4>
                </li>
                <li>
                  <h4 className="" style={{ color: "#FEDC97" }}>
                    {userContext.language == 'ESP' ? ResourcesTranslationTranslation.Spanish : ResourcesTranslationTranslation.English}</h4>
                </li>
                <li>
                  <h4 className="" >
                    {userContext.language == 'ESP' ? ArtificalIntelligenceTranslation.Spanish : ArtificalIntelligenceTranslation.English}</h4>
                </li>
                <li>
                  <h4 className="" style={{ color: "#FEDC97" }}>
                    {userContext.language == 'ESP' ? MentalHealthEscapesTranslation.Spanish : MentalHealthEscapesTranslation.English}</h4>
                </li>
                <li>
                  <h4 className="">
                    {userContext.language == 'ESP' ? CuttingEdgeTranslation.Spanish : CuttingEdgeTranslation.English}
                  </h4>
                </li>
                <li>
                  <h4 className="" style={{ color: "#FEDC97" }}>
                    {userContext.language == 'ESP' ? SocialMediaFeelGoodTranslation.Spanish : SocialMediaFeelGoodTranslation.English}</h4>
                </li>
              </ul>
              <Link to = {"/about"}>
                <div className='zenBlockButton monksWanted-button' style={{ margin:"auto",marginTop: "25px" , marginBottom: "25px" }}>
                  learn more
                </div>
                </Link>
            </div>

          </div>

          <div className="ZenHomeStreamItem">
            <div className="ZenHomeBarcelona">

              <div className="madeInBarcelonaHeaderContainer">
                <h1 className="madeInBarcelonaHeader" style={{ textAlign: "center" }}>{userContext.language == 'ESP' ? BornInLondon.Spanish : BornInLondon.English}</h1>
                <h1 className="madeInBarcelonaHeader" style={{ textAlign: "center" }}>{userContext.language == 'ESP' ? MadeInBCNTranslation.Spanish : MadeInBCNTranslation.English}</h1>
                
              <Link to = "/about/ourcompany">
               <div className='zenBlockButton goldBlockButton zen-desktop' style={{ marginTop: "10px" }}>
                {userContext.language == 'ESP' ? OurRootsTranslation.Spanish : OurRootsTranslation.English}
                </div> 
              </Link>
              </div>
              <img className="madeInBarcelona" src={require("../../static/photos/home/roots.png")} />
              <Link to = "/about/ourcompany">
              <div className='zenBlockButton goldBlockButton zen-mobile zenK' style={{ marginTop: "10px",marginBottom: "10px"  }}>
                {userContext.language == 'ESP' ? OurRootsTranslation.Spanish : OurRootsTranslation.English}
                </div>
                </Link>
            </div>
          </div>
          

          <div className="ZenHomeStreamItem">
            <div className="ZenHomePactitioners">

              <div className="radar" >
                <h1 className="monksWanted okashi" style={{ textAlign: "center" }}>
                  {userContext.language == 'ESP' ? HomeContentTranslations.ZenMonksWanted.Spanish : HomeContentTranslations.ZenMonksWanted.English}</h1>
                <h4 className="monksWanted-text zenK" style={{ textAlign: "center" }}>
                  {userContext.language == 'ESP' ? HomeContentTranslations.DoYouHaveWhatItTakes.Spanish : HomeContentTranslations.DoYouHaveWhatItTakes.English}</h4>
                  <Link to = "/oneofus">
                <div className='zenBlockButton monksWanted-button'>
                  {userContext.language == 'ESP' ? HomeContentTranslations.JourneyStartsHere.Spanish : HomeContentTranslations.JourneyStartsHere.English}
                </div></Link>
              </div>
            </div>
          </div>

          <div className="ZenHomeStreamItem" style={{ boxShadow: "none" }}>
            <div className="ZenHomePhilzentropy">
              <h2 className="philzentropyTitle okashi" style={{ textAlign: "center", color: "#033f63" }}>
                {userContext.language == 'ESP' ? HomeContentTranslations.Philzentropy.Spanish : HomeContentTranslations.Philzentropy.English}</h2>
              <Link to = "/philzentropy">
              <div className='philzentropyLearnMore' style={{ marginTop: "10px" }}>
                {userContext.language == 'ESP' ? HomeContentTranslations.GetInvolved.Spanish : HomeContentTranslations.GetInvolved.English}
              </div>
              </Link>
            </div>
            <img className="philzentropy" src={require("../../static/photos/home/philzentropy.png")} />
          </div>
        </div>
        <div className="ZenArms" ></div>
      </React.Fragment>
      );
}




