import * as React from "react";
import "../../../styling/common/index.scss";
import "../../../styling/explore/explore.scss";
import { useState } from "react";
import axios from "axios";
import { useAuth } from "../../../hooks/auth";
import { ZEN_C_ENDPOINT } from "../../../config/EnvironmentalVariables";
import { ZenImageBrowser } from "./ZenImageBrowser";
import { MeditationIcons } from "../../zencontent/practices/MeditationIcons";
import ImageDrop from "./ImageDrop";
import BrowseLocalFilesButton from "./BrowseLocalFilesButton";
import { BarLoader } from "react-spinners";
import { PathContentFinder } from "./PathContentFinder";
import ZenContentInterface from "../../zencontent/practices/ZenContentInterface";
import { PathSyllabusLesson } from "./PathSyllabusLesson";

export const PathSyllabusStudio = (props: { userId: any, page?: string, setPage?: any, cofirmSessions?:any }) => {

      const [stepNum, setStepNum] = useState("syllabus-view");
      const [sessions, setSessions] = useState([]);

      let auth = useAuth();



      const addSessionToPath = (sessionInfo: any) => {
            var newSessions = [];
            sessions.map(s => newSessions.push(s));
            newSessions.push(sessionInfo);
            setSessions(newSessions);
            setStepNum("syllabus-view");
      }

      const removeSessionFromPath = (sessionId: any) => {
            console.log("removing session with id: "+sessionId);
            var newSessions = sessions.filter(s => s.id != sessionId);
            setSessions(newSessions);
            setStepNum("syllabus-view");
      }


      const changeLessonOrder = (lessonNumber:number, direction:string) => {
            var newSessions = [];
            sessions.map((s, index) => {
                  if(direction == "UP"){
                        if((index+2) == lessonNumber){
                              newSessions.push(sessions[lessonNumber-1]);
                              newSessions.push(s);
                        } else if ((index+1) == lessonNumber){
      
                        } else {
                              newSessions.push(s);
                        }
                  } else if (direction == "DOWN"){

                        // e.g lesson number = 5.
                        //we want to put that to 6 (index 5), and the one at 6 to 5 (index 4)
                        //all others remain the same.

                        // for index == 0, just push.... same for 1-4.
                        // if index = 5. we want to push 6. 
                        //if index = 6, we want to push 5.
                        // then carry on.
                        //list = [any,any,any,any, target, tbm]
                        // 
                        
                        if((index+1) == lessonNumber){
                              newSessions.push(sessions[index+1]);
                        } else if ((index) == lessonNumber){
                              newSessions.push(sessions[index-1]);
                        } else {
                              newSessions.push(s);
                        }
                  }
                  
            });
            setSessions(newSessions);
            setStepNum("syllabus-view");
      }



      return (
            <div>
                  {stepNum == "syllabus-view" ?
                        <div>
                              <h2 className="" style={{ margin: "auto", textAlign: "center", marginBottom:"15px" }}>Choose Course Content</h2>
                              <div>
                                    {sessions.map((s, index) => <PathSyllabusLesson lessonContent={s} lessonNumber={index + 1} changeLessonOrder = {(lessonNum, direction) => changeLessonOrder(lessonNum, direction)} removeItem = {(sessionId) => removeSessionFromPath(sessionId)}></PathSyllabusLesson>)}
                              </div>
                              <div className="path-add-content-banner" onClick={() => setStepNum("add-content")}>
                                    <div style={{ width: "250px", margin: "auto", fontSize: "90%", textAlign:"center" }}>
                                          <h2 className="">Add Content</h2>
                                    </div>
                                    <div className="addContentPlus" style = {{marginLeft:"-20px"}}></div>
                              </div>
                        </div>
                        : stepNum == "add-content" ?
                              <PathContentFinder userId={props.userId} addSessionToPath={(session) => addSessionToPath(session)} />
                              : null}

                  {stepNum != "add-content" && sessions != null && sessions.length > 0 ?
                        <div className="confirmChooseSessionBanner">
                              <div className="chooseSessionBannerButton" onClick={() => props.cofirmSessions(sessions)}>Next
                              </div>
                        </div>
                        : null}
            </div>
      )
}