import React, { useState } from 'react';
import "../../../styling/vacana/vacana.scss";
import "../../../styling/common/index.scss";
import "../../../styling/zenverse/bubbleEnergy.scss";
import "../../../styling/stream/stream.scss";
import "../../../styling/zenverse/Zenverse.scss";
import { ZEN_C_ENDPOINT } from '../../../config/EnvironmentalVariables';
import OhmSoundInterface from './OhmBoard/OhmSoundInterface';
import ReactPlayer from 'react-player';

export const YoutubeZenscapePlayer = (props: any) => {

  return (
    <React.Fragment>

      <div className={props.themeClass != null ? "zenscape-yt-container " + props.themeClass : "zenscape-yt-container"}>
        <div className="zenscape-yt-video-theme-container"><div className='zenscape-yt-video'>
          <ReactPlayer
            playing={true}
            loop={true}
            url={props.videoUrl}
            width='100%'
            autoPlay={true} />
        </div>
        </div></div>
    </React.Fragment>
  );
}