import * as React from "react";
import { useState } from "react";
import { useAuth } from "../../../hooks/auth";
import ZenPracticeSessionInterface from "../interface/ZenPracticeSessionInterface";

import "../../../styling/myzen/myzen.scss";
import Picker from 'react-scrollable-picker';
import { ZenPracticeSessionPreview } from "./ZenPracticeSessionPreview";
import axios from "axios";
import { useMyStuffService } from "../../../hooks/MyStuffContext";
import { PracticePreview } from "../../zencontent/practices/previews/PracticePreview";
import { useZenExploreService } from "../../../hooks/ZenExploreContext";
import { ZEN_C_ENDPOINT } from "../../../config/EnvironmentalVariables";
import { LinkPracticeToPlannerSessionModal } from "./LinkPracticeToPlannerSessionModal";

export const PracticeSessionModify = (props: any) => {


      const auth = useAuth();

      const [durationH, setDurationH] = useState(props.session && props.session.duration ? "" + Math.floor(props.session.duration / 60) : null);
      const [durationM, setDurationM] = useState(props.session && props.session.duration ? "" + props.session.duration % 60 : null);
      const [days, setDays] = useState(props.session ? [props.session.dayOfWeek] : []);
      const [title, setTitle] = useState(props && props.session.title ? props.session.title : "");
      const [sessionType, setSessionType] = useState(props.session && props.session.sessionType ? props.session.sessionType : "");
      const [isBlueprintSession, setIsBlueprintSession] = useState(null);
      const [page, setPage] = useState('updateType');
      const [reccommendedSessions, setReccomendedSessions] = useState([]);
      const [chosenLinkSession, setChosenLinkedSession] = useState(null);

      const dow = ["Monday", "Tuesday", "Wednesday", "Thursday", 'Friday', "Saturday", "Sunday"];

      const updateSession = async () => {
            var sessionDuration = durationH ? (60 * parseInt(durationH)) + parseInt(durationM) : parseInt(durationM);
            days.map((day) => requestSessionUpdate(title, sessionDuration, day, sessionType));
            props.setShowAddSession(false);
      }

      const requestSessionUpdate = async (title: string, duration: number, day: string, sessionType: string) => {
            let session = {
                  id: props.session.id,
                  title: title,
                  username: auth.username,
                  duration: duration,
                  dayOfWeek: day,
                  sessionType: sessionType,
                  linkedPracticeId: chosenLinkSession ? chosenLinkSession.id : null,

            };
            await axios.post(ZEN_C_ENDPOINT + `/myzen/zenPlanner/editSession`, session)
                  .then(function (response) {
                        props.finish();
                  })
                  .catch(function (error) {
                        console.log(error);
                  });
      }


      const linkSessionToPractice = async () => {
            let session = {
                  id: props.session.id,
                  title: props.session.title,
                  username: auth.username,
                  duration: props.session.duration,
                  dayOfWeek: props.session.dayOfWeek,
                  date: props.session.date,
                  sessionType: props.session.sessionType,
                  linkedPracticeId: chosenLinkSession.id,

            };
            await axios.post(ZEN_C_ENDPOINT + `/myzen/zenPlanner/editSession`, session)
                  .then(function (response) {
                        props.finish();
                  })
                  .catch(function (error) {
                        console.log(error);
                  });
      }

      const dayOptions = dow.map((day) => {
            return days.includes(day) ? (
                  <div className='option-button-small chosenZenGoal' onClick={() => setDays(days.filter(prefferedD => prefferedD != day))}>
                        {day}
                  </div>)
                  :
                  (<div className='option-button-small'
                        onClick={() => {
                              days.push(day);
                              var newList = days.splice(0, days.length);
                              setDays(newList);
                        }}
                  >
                        {day}
                  </div>)
                  ;
      })

      const chooseSessionToLink = (session:any) => {
            setChosenLinkedSession(session);
            setPage("summary");
      }


      // if(page == "linkContent" && !useExplore.searchPending){
            // Check if we have enough to render: 

            //If not, we need to search for more content.
      //       if((reccommendedSessions == null || (reccommendedSessions && reccommendedSessions.length == 0)){

      //       }
      // }

  
      // }

      return (

            <div className="edit-session-form">
                  {page == 'updateType' ?
                        <div >

                              <h1>Edit Session</h1>
                              <div className="choose-session-modify-type-holder">
                                    <div className="choose-session-modify-type">
                                          <div className="choose-session-modify-type-icon planner-edit-type-icon" onClick={() => {
                                                setPage('type');
                                          }}>

                                          </div>
                                          Change Details
                                    </div>
                                    <div className="choose-session-modify-type">
                                          <div className="choose-session-modify-type-icon planner-link-content-icon" onClick={() => {
                                                setPage('linkContent');
                                          }}>

                                          </div>
                                          Link Session
                                    </div>
                              </div>
                        </div>

                        : page == 'linkContent' ?
                              <LinkPracticeToPlannerSessionModal chosenLinkSession={chosenLinkSession} setChosenLinkedSession={(session) => chooseSessionToLink(session)} />

                              : page == 'type' ?
                                    <div style={{ padding: '15px' }}>
                                          <h3>Choose Session Type</h3>
                                          <div className="chooseSessionRow">
                                                {/* These should be rendered using a stream from array of session types. */}
                                                <div className={sessionType == 'meditation' ? "chooseSessionType med-session chosenSessionType" : "chooseSessionType med-session"} onClick={() => {
                                                      setSessionType('meditation');
                                                      setTimeout(() => {
                                                            setPage('duration');
                                                      }, 1000);
                                                }}>
                                                      Meditation
                                                </div>
                                                <div className={sessionType == 'yoga' ? "chooseSessionType yoga-session chosenSessionType" : "chooseSessionType yoga-session"} onClick={() => {
                                                      setSessionType('yoga');
                                                      setTimeout(() => {
                                                            setPage('duration');
                                                      }, 1000);
                                                }}>
                                                      Movement
                                                </div>
                                                <div className={sessionType == 'relax' ? "chooseSessionType relax-session chosenSessionType" : "chooseSessionType relax-session"} onClick={() => {
                                                      setSessionType('relax');
                                                      setTimeout(() => {
                                                            setPage('duration');
                                                      }, 1000);
                                                }}>
                                                      Relax
                                                </div>
                                                <div className={sessionType == 'lecture' ? "chooseSessionType lecture-session chosenSessionType" : "chooseSessionType lecture-session"} onClick={() => {
                                                      setSessionType('lecture');
                                                      setTimeout(() => {
                                                            setPage('duration');
                                                      }, 1000);
                                                }}>
                                                      Lecture
                                                </div>
                                                <div className={sessionType == 'hobby' ? "chooseSessionType hobby-session chosenSessionType" : "chooseSessionType hobby-session"} onClick={() => {
                                                      setSessionType('hobby');
                                                      setTimeout(() => {
                                                            setPage('duration');
                                                      }, 1000);
                                                }}>
                                                      Hobby
                                                </div>
                                                <div className={sessionType == 'zazen' ? "chooseSessionType zazen-session chosenSessionType" : "chooseSessionType zazen-session"} onClick={() => {
                                                      setSessionType('zazen');
                                                      setTimeout(() => {
                                                            setPage('duration');
                                                      }, 1000);
                                                }}>
                                                      Zazen
                                                </div>

                                          </div>

                                    </div>
                                    : page == 'duration' ?
                                          <div className="create-session-page zenK">
                                                <h3>Set Session Duration</h3>
                                                <div style={{ width: "280px", display: "flex", flexDirection: "row", margin: "auto" }}>
                                                      <div style={{ margin: "auto" }}>
                                                            <input
                                                                  placeholder="Hours"
                                                                  value={durationH}
                                                                  onChange={(e) => setDurationH(e.target.value)}
                                                                  className='cs-duration-input'
                                                                  type="number"
                                                            />
                                                            {durationH ?
                                                                  <h3>Hours</h3>
                                                                  :
                                                                  null
                                                            }
                                                      </div>
                                                      <div style={{ margin: "auto" }}>
                                                            <input
                                                                  placeholder="Minutes"
                                                                  value={durationM}
                                                                  onChange={(e) => setDurationM(e.target.value)}
                                                                  className='cs-duration-input'
                                                                  type="number"
                                                            />
                                                            {durationH ?
                                                                  <h3>Minutes</h3>
                                                                  :
                                                                  null
                                                            }
                                                      </div>

                                                </div>
                                          </div>
                                          : page == 'title' ?
                                                <div className="create-session-page zenK">
                                                      <h3>Set Title</h3>
                                                      <input
                                                            placeholder={title ? title : "e.g Mindful Breathing"}
                                                            value={title}
                                                            onChange={(e) => setTitle(e.target.value)}
                                                            className='su-text-input'
                                                            type="text"
                                                      />
                                                </div>

                                                : page == 'occur' ?
                                                      <div className="create-session-page zenK">
                                                            <h3>This session occurs</h3>
                                                            {props.session.date ?
                                                                  <h3>On {props.session.date} and does not re-occur</h3>
                                                                  :
                                                                  <h3>On {props.session.dayOfWeek} of every week.</h3>
                                                            }
                                                      </div>
                                                      : page == 'days' ?
                                                            <div className="create-session-page zenK">
                                                                  {isBlueprintSession != null ?
                                                                        <>
                                                                              <h3>Choose which days you want the session to occur:</h3>
                                                                              <div style={{ width: "330px", display: "flex", flexDirection: "row", flexWrap: "wrap", margin: "auto", marginTop: "15px" }}>
                                                                                    {dayOptions}
                                                                              </div>
                                                                        </>
                                                                        : <></>
                                                                  }
                                                            </div>
                                                            : page == 'change-occur' ?
                                                                  <div className="create-session-page zenK">
                                                                        <h3>When do you want this session to occur?</h3>
                                                                        <div className="task-button-alt" style={{ marginTop: "25px" }} onClick={() => { setIsBlueprintSession('no'); setPage("days") }}>
                                                                              <h1>Just This Week</h1>
                                                                        </div>
                                                                        <div className="task-button-alt" style={{ marginTop: "25px" }} onClick={() => { setIsBlueprintSession('yes'); setPage("days"); }}>
                                                                              <h1>Reoccur Each Week</h1>
                                                                        </div>
                                                                  </div>
                                                                  : page == 'days' ?
                                                                        <div className="create-session-page zenK">
                                                                              {isBlueprintSession != null ?
                                                                                    <>
                                                                                          <h3>Choose which days you want the session to occur:</h3>
                                                                                          <div style={{ width: "330px", display: "flex", flexDirection: "row", flexWrap: "wrap", margin: "auto", marginTop: "15px" }}>
                                                                                                {dayOptions}
                                                                                          </div>
                                                                                    </>
                                                                                    : <></>
                                                                              }
                                                                        </div>

                                                                        : page == 'summary' ?
                                                                              <div>
                                                                                    <ZenPracticeSessionPreview session={{ dayOfWeek: days.join(","), sessionType: sessionType && sessionType.length > 0 ? sessionType : props.session.sessionType, title: title, durationSeconds: durationH && durationM ? (60 * 60 * parseInt(durationH)) + (60 * parseInt(durationM)) : durationH ? 60 * 60 * parseInt(durationH) : 50 * parseInt(durationM), linkedPracticeId:chosenLinkSession != null? chosenLinkSession.id : null}} ></ZenPracticeSessionPreview>
                                                                              </div>
                                                                              :
                                                                              <></>

                  }

                  <div className="zenc-button-row">
                        {page == "occur" ?
                              <div className="zencTextButton zencRedButton" onClick={() => setPage('change-occur')}>
                                    Change
                              </div>
                              :
                              <div className="zencTextButton zencRedButton" onClick={() => props.setShowAddSession(false)}>
                                    Cancel
                              </div>}

                        <div className="zencTextButton zencGreenButton" onClick={() => {
                              page == "type"? setPage('duration')
                              :page == "linkContent" ? linkSessionToPractice()
                                    : page == "duration" ? setPage('title')
                                          : page == "summary" ? updateSession()
                                                : page == "duration" ? setPage('title')
                                                      : page == "title" ? setPage('occur')
                                                            : setPage('summary')
                        }}>
                              Confirm
                        </div>
                  </div>
            </div>


      );

}