import * as React from "react";
import PrivateRoute from "../../common/PrivateRoute";
import { ProvideZenGoalsContext} from "../../../hooks/ZenGoalsContext";
import { MyZenGoals } from "./MyZenGoals";

export const ZenGoalsRouter = (props: {walkthrough?: boolean}) => {
      return (
            <PrivateRoute>
                  <ProvideZenGoalsContext>
                        <MyZenGoals walkthrough = {props.walkthrough}/>
                  </ProvideZenGoalsContext>
            </PrivateRoute>);
}
