import * as React from "react";
import { MyZenNavBar } from "../../common/navbar/MyZenNavBar";
import "../../../styling/common/index.scss";
import { ZenNavBar } from "../../common/navbar/ZenNavBar";
import { useState } from "react";
import axios from "axios";
import { useAuth } from "../../../hooks/auth";
import PrivateRoute from "../../common/PrivateRoute";
import { useParams } from "react-router-dom";
import { ZEN_C_ENDPOINT } from "../../../config/EnvironmentalVariables";
import PractitionerInterface from "../PractitionerInterface";
import ZenContentInterface from "../../zencontent/practices/ZenContentInterface";
import { getContentListPreviews } from "../../../service/ContentService";
import { ZenImageBrowser } from "./ZenImageBrowser";
import { ZenNugget } from "../../zencontent/nuggets/ZenNugget";
import ImageDrop from "./ImageDrop";
import BrowseLocalFilesButton from "./BrowseLocalFilesButton";

export const TextCoverImageStudio = () => {

      const { practitonerId } = useParams();

      const [title, setTitle] = useState(null);
      const [description, setDescription] = useState<string>();
      const [nuggetType, setNuggetType] = useState(null);
      const [imageFile, setImageFile] = useState<File | null>(null);
      const [colour, setColur] = useState<string>();
      const [textColour, settextColour] = useState<string>("white");
      
      const [stepNum, setStepNum] = useState("set-details");

      const textTopPadding = "0";
      const fontSize = "200%";

      const getPaddingTop = (text:string) => {
            return text && text.length > 70? "50px": text && text.length > 40? "75px": "100px";
      }

      const getSidePadding = (text:string) => {
            return text && text.length > 70? "7%": text && text.length > 40? "10%": "15%";
      }

      const getFontSize = (text:string) => {
            return text && text.length > 70? "200%": text && text.length > 40? "250%": text && text.length < 20? "500%": "350%";
      }

      return (

            <div className = "ZenBasicPage" style = {{overflowY:"scroll", paddingTop:"0"}}>

                  {stepNum == "set-details" ?
                        <div style={{ paddingTop: "125px", paddingBottom:"175px"}}>
                              <textarea
                                    placeholder="Write your text here..."
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    className={'nugget-text-input'}
                              />
                              <h3 style={{ margin: "auto", textAlign: "center", marginBottom: "15px", marginTop: "15px" }}>Choose Background Colour:</h3>
                              <div className='chooseColour' style={{ display: "flex", flexDirection: "row", margin:"auto", width:"fit-content" }}>
                                    <div className={colour == "#033f63"? 'choosePrimaryCol choosePrimaryColActive chooseBlue':'choosePrimaryCol chooseBlue'} onClick={() => setColur('#033f63')}></div>
                                    <div className={colour == "#FFBC42"? 'choosePrimaryCol choosePrimaryColActive chooseOrange': 'choosePrimaryCol chooseOrange'} onClick={() => setColur('#FFBC42')}></div>
                                    <div className={colour == "#D1603D"? 'choosePrimaryCol choosePrimaryColActive chooseLRed': 'choosePrimaryCol chooseLRed'} onClick={() => setColur('#D1603D')}></div>
                                    <div className={colour == "#28666E"? 'choosePrimaryCol choosePrimaryColActive': 'choosePrimaryCol'} style = {{backgroundColor:"#28666E"}} onClick={() => setColur('#28666E')}></div>
                                    <div className={colour == "#B5B682"? 'choosePrimaryCol choosePrimaryColActive': 'choosePrimaryCol'} style = {{backgroundColor:"#B5B682"}} onClick={() => setColur('#B5B682')}></div>
                                    : null

                              </div>
                              <div style={{ display: "flex", flexDirection: "row", marginTop:"25px"}}>
                                    <div className = "zenGlowButton mingGlowButton" onClick = {() => settextColour("black")}>Make Text Black</div>
                                    <div className = "zenGlowButton navajoGlowButton" onClick = {() => settextColour("white")}>Make Text White</div>
                              </div>
                              <div className="zenGlowButton mingGlowButton" onClick={() => setStepNum("show-cover-image")} style={{ marginTop: "35px" }}>
                                    Next
                              </div>
                        </div>
                        : stepNum == "show-cover-image" ?
                              <div style = {{width:"100vw", maxWidth:"100vw", height:"100vh", marginTop:"0", backgroundColor:colour}}>
                                    <h2 className = "okashi" style = {{color:textColour, paddingLeft:getSidePadding(description), paddingRight: getSidePadding(description), margin:"auto", textAlign:"center", paddingTop:getPaddingTop(description),  fontSize:getFontSize(description)}}>{description}</h2>
                              </div>
                              : null

                  }

            </div>);

}
