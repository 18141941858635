import * as React from "react";
import "../../styling/common/index.scss";
import "../../styling/common/zenCoreUIComponents.scss";
import "../../styling/myzen/myzen.scss";
import { ZenNavBar } from "../common/navbar/ZenNavBar";
import { useState } from "react";
import { useZenExploreService } from "../../hooks/ZenExploreContext";
import axios from "axios";
import { ZEN_C_ENDPOINT } from "../../config/EnvironmentalVariables";
import { useAuth } from "../../hooks/auth";
import { PathPreview } from "../zencontent/courses/PathPreview";
import { useNavigate, useParams } from "react-router";
import { PuffLoader } from "react-spinners";
import { getContentCatagories } from "../../service/ZencyclopaediaService";
import { ZenPopUpMessage } from "../common/ZenPopUpMessage";
import { ZencTitle } from "../home/ZencTitle";
import { ShopMainItem } from "./ShopMainItem";
import { Link } from "react-router-dom";
import { ZenBasket } from "./ZenBasket";
import { useZenShopService } from "../../hooks/ZenShopContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBasketShopping } from '@fortawesome/free-solid-svg-icons';

export const ZenShop = (props: { retreats?: boolean, tshirts?: boolean, stickers?: boolean, basket?: boolean, newItem?: boolean }) => {

      const useExplore = useZenExploreService();
      const [page, setPage] = useState(props.basket ? "basket" : props.retreats ? "retreats" : props.tshirts ? "tshirts" : props.stickers ? "stickers" : "main");

      let shopService = useZenShopService();
      
      let auth = useAuth();

      let navigate = useNavigate();

      React.useEffect(() => {
            if (shopService && shopService.basket == null) {
                  console.log("Reloading Basket Items");
                  shopService.setUserBasket();
            }
      }, []);

      const ref = React.useRef(null);
      const handleClick = () => {
            ref.current?.scrollIntoView({ behavior: 'smooth' });
      };
      

      return (
            <React.Fragment>
                  <div className="zen-page-holder">
                        <Link to = {"/"}>
                        <div className="logoUpperLeft">
                              <div className="zenCLogo">

                              </div>
                        </div>
                        </Link>
                        {shopService != null && shopService.basket !=null?
                         <div className="logoUpperRight" onClick = {() => setPage("basket")}>
                       <FontAwesomeIcon icon={faBasketShopping} style = {{margin:"auto", textAlign:"center", fontSize:"300%"}}/>
                  </div>
                        :null}
                        
                        {/* 
                  <div style={{ display: "flex", flexDirection: "row", margin:"auto"}}> */}
                        <div className="shop-back-arrow" onClick={() => { page != "main" ? setPage("main") : navigate(-1) }}></div>
                        <div onClick = {() => setPage("main")}>
                              <h1 className="zen-sign-up-title okashi centered-text shopTitle" style={{ color: "#033f63", top: "0", marginTop: "25px" }}>
                                    <span>
                                          Z
                                    </span>
                                    <span style={{ color: "#B5B682" }}>
                                          E
                                    </span>
                                    <span style={{ color: "#28666E" }}>
                                          N
                                    </span>
                                    <span style={{ color: "#D1603D" }}>
                                          0
                                    </span>
                                    <span className=''>
                                          S
                                    </span>
                                    <span style={{ color: "#B5B682" }}>
                                          H
                                    </span>
                                    <span style={{ color: "#28666E" }}>
                                          O
                                    </span>
                                    <span >
                                          P
                                    </span>
                                    {/* {page == "tshirts" ?
                                    <span className="navajoTextCol"> (T-shirts)</span>
                                    : page == "retreats" ?
                                          <span className="navajoTextCol"> (Retreats)</span>
                                          : null} */}
                              </h1>
                        </div>
                        {/* </div> */}
                        {page == "basket" ?
                              <ZenBasket newItem={props.newItem} />
                              : page == "main" ?
                                    <div className="splashedBacking zen-shop-items-container" style={{ height: "100vh", width: "100vw" }}>
                                          <div className="zen-mobile" >
                                                <div className="t-shirt-photo-carousel" style={{ display: "flex", flexDirection: "row", margin: "auto", width: "90vw" }}>
                                                      <ShopMainItem title={"T-Shirts"} image={require("../../static/photos/shop/t-shirt-square.png")} clickAction={() => setPage("tshirts")} />

                                                      <ShopMainItem title={"Retreats"} tagLine={"Hand picked by our in house Zen Masters"} image={require("../../static/photos/shop/BuddhaRelaxingWide.png")} clickAction={() => setPage("retreats")} />
                                                </div>

                                                <div className="t-shirt-photo-carousel" style={{ display: "flex", flexDirection: "row", margin: "auto", width: "90vw", marginTop: "15px" }}>
                                                      <ShopMainItem title={"Stickers & Badges"} tagLine={"The perfect reminder to stay mindful"} image={require("../../static/photos/shop/stickers.png")} clickAction={() => setPage("stickers")} />

                                                      <ShopMainItem inactive={true} title={"Digital Upgrades"} image={require("../../static/photos/shop/zenCoin.png")} />
                                                </div>
                                          </div>
                                          <div className="zen-desktop" style={{ marginTop: "0" }}>
                                                <div className="t-shirt-photo-carousel" style={{ display: "flex", flexDirection: "row", margin: "auto", width: "90vw" }}>
                                                      <ShopMainItem title={"T-Shirts"} image={require("../../static/photos/shop/t-shirt-square.png")} clickAction={() => setPage("tshirts")} />

                                                      <ShopMainItem title={"Retreats"} tagLine={"Hand picked by our in house Zen Masters"} image={require("../../static/photos/shop/retreat.png")} clickAction={() => setPage("retreats")} />

                                                      <ShopMainItem title={"Stickers & Badges"} tagLine={"The perfect reminder to stay mindful"} image={require("../../static/photos/shop/stickers.png")} clickAction={() => setPage("stickers")} />

                                                      <ShopMainItem inactive={true} title={"Digital Upgrades"} image={require("../../static/photos/shop/DigitalUpgrade.png")} />
                                                </div>
                                          </div>
                                          {/* <h3 className="shop-current-offer-rext">Get 25% off merchandise using code GREY_HEN_ZEN</h3>
                                    <h3 className="shop-current-offer-ends">offer ends 1st June.</h3> */}
                                          {<div>
                                                <div className="ecoPledgeBanner scrollActivateBanner" onClick={() => handleClick()}>
                                                      <div className="ecoFriendlyBannerIcon ecoFriendlyPledgeIcon"></div>
                                                      <div className="ecoFriendlyBannerText">We are committed to ethical and fair practices accross all of our merchandise</div>
                                                </div>
                                                <div className="ecoPledgeBanner">
                                                      <div className="ecoFriendlyBannerIcon packagingPledgeIcon"></div>
                                                      <div className="ecoFriendlyBannerText">All packaging is eco friendly certified</div>
                                                </div>
                                                <div className="ecoPledgeBanner" ref={ref}>
                                                      <div className="ecoFriendlyBannerIcon fairTradePledgeIcon"></div>
                                                      <div className="ecoFriendlyBannerText">All our T-Shirts are made with fair-trade organic cotton</div>
                                                </div>
                                          </div>}
                                    </div> : page == "stickers" ?
                                          <div className="splashedBacking zen-shop-items-container" style={{ height: "100vh", width: "100vw" }}>
                                                <h3 className="shop-current-offer-rext" style={{ width: "300px", margin: "auto" }}>Our ZEN stickers are perfect to liven up a range of surfaces, and are the perfect reminder to stay present & mindful!</h3>

                                                <div className="t-shirt-photo-carousel" style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", margin: "auto", width: "90vw", marginTop: "15px" }}>

                                                      <ShopMainItem title={"Mindfulness Reminder Stickers"} image={require("../../static/photos/shop/stickers.png")} clickAction={(() => navigate("/explore/shop/stickers/reminder"))} />

                                                      <ShopMainItem title={"Zen Master Stickers"} image={require("../../static/photos/shop/Confucious.png")} outOfStock={true} />

                                                      <ShopMainItem title={"Sew Patches"} image={require("../../static/photos/shop/sewPatch.png")} outOfStock={true} />

                                                      <ShopMainItem title={"Giant Stickers"} image={require("../../static/photos/shop/giantSticker.png")} outOfStock={true} />

                                                </div>

                                                {/* <div className="t-shirt-photo-carousel" style={{ display: "flex", flexDirection: "row", margin: "auto", width: "90vw", marginTop: "15px" }}>

                                                <ShopMainItem title={"Zen Card Stickers & Badges"} image={require("../../static/photos/shop/retreat.png")} />
                                             

                                          </div> */}

                                                {/* 
                                          <h3 className="shop-current-offer-rext">Get 25% off merchandise using code GREY_HEN_ZEN</h3>
                                          <h3 className="shop-current-offer-ends">offer ends 1st June.</h3> */}
                                          </div> : page == "retreats" ?
                                                <div className="splashedBacking zen-shop-items-container" style={{ width: "100vw" }}>
                                                      <h3 className="shop-current-offer-rext">We've hand picked a list of the top mindfulness retreats from around the globe</h3>

                                                      <div className="t-shirt-photo-carousel" style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", margin: "auto", width: "90vw", marginTop: "15px" }}>

                                                            <ShopMainItem title={"Yoga Retreats"} image={require("../../static/photos/shop/yoga-retreat.png")} clickAction={() => navigate("/explore/shop/retreats/YOGA")} />

                                                            <ShopMainItem title={"Spiritual Retreats"} image={require("../../static/photos/shop/spiritual-retreat.png")} clickAction={() => navigate("/explore/shop/retreats/SPIRITUAL")} />


                                                            <ShopMainItem title={"International Retreats"} image={require("../../static/photos/shop/luxury-retreat.png")} clickAction={() => navigate("/explore/shop/retreats/INTERNATIONAL")} />

                                                            <ShopMainItem title={"UK retreats"} image={require("../../static/photos/shop/uk-retreat.png")} clickAction={() => navigate("/explore/shop/retreats/UK")} />

                                                            <ShopMainItem title={"Luxury Relaxation Retreats"} image={require("../../static/photos/shop/luxury-retreat.png")} clickAction={() => navigate("/explore/shop/retreats/LUXURY")} />

                                                            <ShopMainItem title={"Online Retreats"} image={require("../../static/photos/shop/yoga-retreat.png")} clickAction={() => navigate("/explore/shop/retreats/ONLINE")} />


                                                            <ShopMainItem title={"Health & Wellness Retreats"} image={require("../../static/photos/shop/detox-retreat.png")} clickAction={() => navigate("/explore/shop/retreats/HEALTH")} />

                                                            <ShopMainItem title={"Buddhist Retreats"} image={require("../../static/photos/shop/buddhist-retreat.png")} clickAction={() => navigate("/explore/shop/retreats/SPIRITUAL")} />

                                                            <ShopMainItem title={"Communal Retreats"} image={require("../../static/photos/shop/communal-retreat.png")} clickAction={() => navigate("/explore/shop/retreats/SPIRITUAL")} />

                                                            <ShopMainItem title={"Ayurveda Retreats"} image={require("../../static/photos/shop/nature-retreat.png")} clickAction={() => navigate("/explore/shop/retreats/AYURVEDA")} />
                                                            {/* <ShopMainItem title={"Nature Retreats"} image={require("../../static/photos/shop/nature-retreat.png")} clickAction={() => navigate("/explore/shop/retreats/HEALTH")}/> */}

                                                            <ShopMainItem title={"Cleansing Retreats"} image={require("../../static/photos/shop/nature-retreat.png")} clickAction={() => navigate("/explore/shop/retreats/DETOX")} />

                                                            <ShopMainItem title={"Healing Retreats"} image={require("../../static/photos/shop/yoga-retreat.png")} clickAction={() => navigate("/explore/shop/retreats/HEALTH")} />

                                                      </div>


                                                </div>
                                                : page == "eco-pledge" ?
                                                      <div className="splashedBacking zen-shop-items-container" style={{ height: "100vh", width: "100vw", textAlign:"center" }}>

                                                            <h3 style = {{maxWidth:"80%", margin:"auto", marginBottom:"0"}}>Our T-Shirts are all produced by Cottover, as well as being really pleased with the quality of their product they also have a really impressive reputation for being environmentally friendly:</h3>

                                                            <h3 className="mediaQuotedText">"Cottover was created to try to address the social and environmental challenges throughout the manufacturing chain"</h3>
                                                            <h3 style = {{color:"#033f63"}} onClick={() => { window.open('https://profiltex.no/bruk-wordpress-til-din-nye-nettside/', '_blank'); }}>You can read a review on the company here</h3>

                                                            <div>
                                                                  <div className="ecoPledgeBanner scrollActivateBanner" onClick={() => handleClick()}>
                                                                        <div className="ecoFriendlyBannerIcon ecoFriendlyPledgeIcon"></div>
                                                                        <div className="ecoFriendlyBannerText">We are committed to ethical and fair practices accross all of our merchandise</div>
                                                                  </div>
                                                                  <div className="ecoPledgeBanner">
                                                                        <div className="ecoFriendlyBannerIcon packagingPledgeIcon"></div>
                                                                        <div className="ecoFriendlyBannerText">All packaging is eco friendly certified</div>
                                                                  </div>
                                                                  <div className="ecoPledgeBanner" ref={ref}>
                                                                        <div className="ecoFriendlyBannerIcon fairTradePledgeIcon"></div>
                                                                        <div className="ecoFriendlyBannerText">All our T-Shirts are made with fair-trade organic cotton</div>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                      : <div className="splashedBacking zen-shop-items-container" style={{ height: "100vh", width: "100vw" }}>
                                                            <div className="t-shirt-shop-holder" style={{ display: "flex", flexDirection: "row", width: "350px", margin: "auto" }}>
                                                                  <div className="t-shirt-pic-portrait"></div>
                                                                  <div style={{ margin: "auto" }}>
                                                                        <div className="t-shirt-pic-sq"></div>
                                                                        <div className="eco-friendly-pledge" onClick={() => setPage("eco-pledge")}>
                                                                              <div className="eco-friendly-pic"></div>
                                                                              <div className="eco-friendly-pledge-text">
                                                                                    <h3>Read Our</h3>
                                                                                    <h1>Eco-Friendly</h1>
                                                                                    <h3>Promise</h3>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                            <h3 className="centered-text" style={{ marginTop: "15px" }}>All colours and sizes <span className="toms-blue-text">£19.99</span></h3>
                                                            <Link to={"/explore/shop/tshirt/buy"}>
                                                                  <div className="buy-now-button zenGlowButton navajoGlowButton">buy now!</div>
                                                            </Link>
                                                      </div>
                        }
                  </div>
            </React.Fragment>
      );
}
