import * as React from "react";
import '../../styling/common/index.scss';

export const ZencTitle = (props: any) => {
  return (
    <React.Fragment>
      <h1 className="zen-sign-up-title okashi" style={{ color: "#033f63" }}>
        <span >
          Z
        </span>
        <span style={{ color: "#D1603D" }}>
          E
        </span>
        <span style={{ color: "#28666E" }} >
          N
        </span>
        <span style={{ color: "#B5B682" }}>
          C
        </span>
        <span className=''>
          Y
        </span>
        <span style={{ color: "#D1603D" }}>
          C
        </span>
        <span style={{ color: "#28666E" }}>
          L
        </span>
        <span style={{ color: "#B5B682" }}>
          O
        </span>
        <span className=''>
          P
        </span>
        <span style={{ color: "#28666E" }}>
          E
        </span>
        <span style={{ color: "#B5B682" }}>
          D
        </span>
        <span className=''>
          I
        </span>
        <span style={{ color: "#D1603D" }}>
          A
        </span>
      </h1>
    </React.Fragment>
  );
}




