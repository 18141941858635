import React, { createContext, useContext, useState } from "react";
import { ZEN_C_ENDPOINT } from "../../config/EnvironmentalVariables";

interface UseGongSound {
  createSound: (soundName: string, gongType: string, loops?: boolean) => void;
  stopAllSound: () => void;
  soundGongPreview: (gongName: string) => void;
  playGong: (gongType: any, delay?: number) => void;
}

type Props = {
  children?: React.ReactNode;
};

const gongSoundContext = createContext({} as UseGongSound);

export const ProvideGongSoundContext: React.FC<Props> = ({ children }) => {
  const gongSoundService = GongSoundService();
  return <gongSoundContext.Provider value={gongSoundService}>{children}</gongSoundContext.Provider>;
};

export const useGongSoundContext = () => {
  return useContext(gongSoundContext);
};

export const GongSoundService = () => {

  const [primaryGongSoundSource, setPrimaryGongSoundSource] = useState(null);
  const [startGongSoundSource, setStartGongSoundSource] = useState(null);
  const [finishGongSoundSource, setFinishGongSoundSource] = useState(null);

  const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
  const audioContextRef = React.useRef<AudioContext>();

  audioContextRef.current = audioContext;


  const createSound = async (soundName: string, gongType: string, loops?: boolean) => {
    let soundRequest = new XMLHttpRequest();
    await soundRequest.open('GET', ZEN_C_ENDPOINT + `/zen/ohmMedia/ohmSounds/stream/` + soundName, true);
    soundRequest.responseType = 'arraybuffer';
    soundRequest.addEventListener('load', async () => {

      await audioContext.decodeAudioData(soundRequest.response).then((buffer) => {
        let source = audioContext.createBufferSource();

        source.buffer = buffer;
        source.loop = loops;
        source.connect(audioContext.destination);
        if(gongType == "PRIMARY"){
          setPrimaryGongSoundSource(source);
        } else if (gongType == "START"){
          setStartGongSoundSource(source);
        } else if (gongType == "FINISH"){
          setFinishGongSoundSource(source);
        }
      });

    });
    soundRequest.send();
  }

  const soundGong = (gongType: any) => {
    if(gongType == "PRIMARY"){
      primaryGongSoundSource.start(0);
    } else if (gongType == "START"){
      startGongSoundSource.start(0);
    } else if (gongType == "FINISH"){
      finishGongSoundSource.start(0);
    }
  }

  const playGong = (gongType: any, delay?: number) => {
      if(delay && delay > 0){
          console.log("setting primary gong timeout...");
          setTimeout(() => {
            console.log("playing primary gong");
            soundGong(gongType)
          },delay);
      } else {
        soundGong(gongType);
      }
  }

  const soundGongPreview = (gongName: string) => {
      
      console.log("PLaying gong preview: "+gongName);
      
      let soundRequest = new XMLHttpRequest();
      soundRequest.open('GET', ZEN_C_ENDPOINT + `/zen/ohmMedia/ohmSounds/stream/` + gongName, true);
      soundRequest.responseType = 'arraybuffer';
      soundRequest.addEventListener('load', () => {

        audioContext.decodeAudioData(soundRequest.response).then((buffer) => {
          let source = audioContext.createBufferSource();

          source.buffer = buffer;
          source.loop = false;
          source.connect(audioContext.destination);
          source.start(0);
        });

      });
      soundRequest.send();
  }

  const forceFixAudio = () => {
    if (audioContext) {
      // Create empty buffer
      var buffer = audioContext.createBuffer(1, 1, 22050);
      var source = audioContext.createBufferSource();
      source.buffer = buffer;
      // Connect to output (speakers)
      source.connect(audioContext.destination);
      // Play sound
      source.start(0);
    }
  }

  

  const stopAllSound = () => {
    primaryGongSoundSource.stop(0);
    finishGongSoundSource.stop(0);
  }

  return {
    createSound,
    stopAllSound,
    soundGongPreview,
    playGong
  };

}