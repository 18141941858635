import React, { useState } from 'react';
import "../../styling/vacana/vacana.scss";
import "../../styling/common/index.scss";
import "../../styling/stream/stream.scss";
import "../../styling/zenverse/Zenverse.scss";
import { EnsoPlayer } from '../media/audio/EnsoPlayer';
import Zenscape from '../zenverse/interfaces/ZenscapeInterface';
import { PortalIcon } from '../zenverse/ZenscapePortal/PortalIcon';
import { ZenverseSidebar } from '../zenverse/ZenverseSidebar';
import ZenscapeInterface from '../zenverse/interfaces/ZenscapeInterface';
import { Link, useNavigate } from "react-router-dom";
import { PracticeCompletePage } from './PracticeCompletePage';
import { getPortalJSX, getPortalPreview } from '../../service/ZenverseService';
import { ZenscapePortal } from '../zenverse/ZenscapePortal/ZenscapePortal';
import { useZenverseService } from '../../hooks/ZenverseContext';
import { ProvideZenAudioContext } from '../../hooks/ZenAudioContext';
import { ZenPopUpMessage } from '../common/ZenPopUpMessage';
import { useAuth } from '../../hooks/auth';
import axios from 'axios';
import { ZEN_C_ENDPOINT } from '../../config/EnvironmentalVariables';
import { ProvideZenJournalContext } from '../../hooks/ZenJournalContext';
import { PracticePortalTimeKeeper } from './PracticePortalTimeKeeper';
import MetaTags from 'react-meta-tags';
import { ZenSplashPopUp } from '../common/ZenSplashPopUp';
import { PortalTimeBoostPowers } from '../explore/icons/CardSubtypes';
import { ProvideMyStuffContext } from '../../hooks/MyStuffContext';
import { generatePortalAdItem } from '../../service/portalAdService';

interface PracticePortalState {
  activePortal: string,
  started: boolean,
  practicePortalQuotaStarted: boolean,
  playing: boolean,
  zenscape: any,
  totalZenTime: any
}

interface PracticePortalProps {
  duration?: any,
  curTime?: number,
  setPracticePlaying: any,
  setVideoStarted?: any,
  practicePlaying?: boolean,
  finished?: boolean,
  setFinished?: any,
  finishPage?: any,
  setClickedTime?: any,
  children?: any,
  guide?: boolean,
  practice?: any,
  zenScapeOvverride?: boolean,
  practiceZenergyBoost?: number,
  practiceId?: any
}

export const PracticePortalAdContainer = (props: any) => {

  const [currentAd, setCurrentAd] = useState(null);

  const setNewAd = async () => {

    const adItem = await generatePortalAdItem();
    setCurrentAd(adItem);
  }

  React.useEffect(() => {
    setNewAd();
    setInterval(() => setNewAd(), 7000);
  }, []);

  return (
    
      <React.Fragment>
        <div className = "zen-portal-ad-holder">
             {currentAd}
        </div>
      </React.Fragment>
  );




}


