
import * as React from "react";
import "../../../styling/explore/explore.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ZenNavBar } from "../../common/navbar/ZenNavBar";
import { MyZenNavBar } from "../../common/navbar/MyZenNavBar";
import { useZenCardsService } from "../../../hooks/ZenCardsContext";
import { useMyZenService } from "../../../hooks/ZenUserContext";
import { useAuth } from "../../../hooks/auth";
import { useMyCommunityService } from "../../../hooks/MyCommunityContext";
import { PractitionerPreview } from "./PractitionerPreview";
import { ZenLoader } from "../../common/ZenLoader";

export const MyZenCommunity = () => {

    const [searchFilter, setSearchFilter] = React.useState(null);
    const [practitionerGroups, setPractitionerGroups] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [getTries, setGetTries] = React.useState(0);
    
    const communityService = useMyCommunityService();

    const getCommunityAlphabetGroups = (communities:any []) => {
        setLoading(true);
        console.log("generating community alphabet groups:");
        let allCommunities = [];
        const alphabet = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"]
    
        alphabet.forEach(letter => {
          console.log("generating: "+letter);
          let letterPractitionerItems = communities.filter(community => community != null && community.practitionerId != null && community.practitionerId.startsWith(letter))
          .map(com => com.practitionerId)
          .map(practitioner  => <PractitionerPreview practitionerId = {practitioner} key = {"ppi-"+practitioner} linkOnClick = {true}/>);
          
          if(letterPractitionerItems != null && letterPractitionerItems.length >0){
            let letterSection = <div key = {"zcg-"+letter}>
            <h1 className="zencyc-contents-group-header zenK">{letter.toUpperCase()}</h1>
            {letterPractitionerItems}
            </div>;
          allCommunities.push(letterSection);
          }
        });
        console.log("returning Alphabet Groups");
        
        setPractitionerGroups(allCommunities);
        setLoading(false);
    }

    if(practitionerGroups == null && !loading && communityService != null && communityService.myCommunityResults != null && getTries<=4){
        setGetTries(getTries+1);
        getCommunityAlphabetGroups(communityService.myCommunityResults);
    }

    
   

    return (
        <div style = {{overflowY:"scroll"}}>
            {practitionerGroups && practitionerGroups.length > 0?
            <div style = {{paddingBottom:"150px"}}>
                {practitionerGroups}
            </div>
        :<ZenLoader/>}
        </div >
    );

}

