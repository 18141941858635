import * as React from "react";
import "../../../styling/common/index.scss";
import "../../../styling/myzen/myzen.scss";
import { useState } from "react";
import { PracticePreview } from "../../zencontent/practices/previews/PracticePreview";
import { useMyStuffService } from "../../../hooks/MyStuffContext";
import { PuffLoader } from "react-spinners";


export const ZenBookmarks = (props: any) => {

  const [showSFL, setShowSFL] = useState(!props.favouritesOpen);
  const [showFav, setShowFav] = useState(props.favouritesOpen);
  const [bookmarkJSX, setBookmarkJSX] = useState(null);
  const [favouriteJSX, setFavouriteJSX] = useState(null);

  const myStuffService = useMyStuffService();

  React.useEffect(() => {
    myStuffService.getBookmarks();
    myStuffService.getFavourites();
  }, []);

  if (myStuffService.myBookmarkSessions && bookmarkJSX == null) {
    setBookmarkJSX(myStuffService.myBookmarkSessions.map(p => <PracticePreview listItem={'Bookmark'} {...p}></PracticePreview>));
  }

  if (myStuffService.myFavouriteSessions && favouriteJSX == null) {
    setFavouriteJSX(myStuffService.myFavouriteSessions.map(p => <PracticePreview listItem={'Favourite'} {...p}></PracticePreview>));
  }

  return (
    <div className="my-zen-bookmarks">
      <div style={{ display: "flex", flexDirection: "row" }}>

        <div className={showFav ? 'bookmark-filter bookmark-favourite-filter-selected' : 'bookmark-filter bookmark-favourite-filter'} onClick={() => { setShowSFL(false); setShowFav(true) }} />
        <div className="bookmarks-search-bar-container">
          <div className="box bookmark-search">
            <form name="search">
              <input type="text" className="input" name="txt" onMouseLeave={() => { }} />
              <div className="zencyc-search-go bookmark-search-bar"></div>
            </form>

          </div>
        </div>
        <div className={showSFL ? 'bookmark-filter bookmark-bookmark-filter-selected' : 'bookmark-filter bookmark-bookmark-filter'} onClick={() => { setShowSFL(true); setShowFav(false) }} />

      </div>

      {showSFL && bookmarkJSX != null && bookmarkJSX.length > 0 ?
        <div className="my-content-list">
          {bookmarkJSX}
        </div>
        : favouriteJSX != null && favouriteJSX.length > 0 ?
          <div className="my-content-list">
            {favouriteJSX}
          </div>
          : (showSFL && bookmarkJSX == null && myStuffService.myBookmarkSessions == null) ?
            <div className="zencLoader">
              <PuffLoader color="#033f63" size={300}></PuffLoader>
            </div>
            : <div className="centered-text">No {showSFL ? "bookmarks" : "favourites"} found, you can add some by hitting the {showSFL ? <div className="PracticePreviewBookmark"></div> : <div className="PracticePreviewFavourite"></div>} icon whenever you see it!</div>
      }
    </div>

  );

}
