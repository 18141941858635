import React, { useState } from 'react';
import "../../../styling/stream/stream.scss";
import "../../../styling/common/index.scss";

export const ImageNugget = (props: any) => {

  return (
    <div className = {props.streamItem? "zen-nugget-stream-item stream-item " : "zen-nugget-stream-item list-item"}>  <div className='zen-nugget'>
          {props && props.image ?
            <img className="image-nugget-image" src={props.image} ></img>
            : null}
          <div style = {{margin:"auto"}}>
            <h2 className='image-nugget-content zenK'>
              {props.description}
            </h2>
          </div>
        </div>

    </div>
  );
}