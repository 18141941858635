import React, { useState } from 'react';
import "../../../styling/stream/stream.scss";
import "../../../styling/common/index.scss";

export const TextNugget = (props: any) => {

  const [showMore, setShowMore] = useState(false);

  return (
    <div className = {props.streamItem? "zen-nugget-stream-item stream-item " : "zen-nugget-stream-item list-item"}>
      <div className='zen-nugget'>
        <div style={props.description && props.description.length>250? {} : { display: "flex", flexDirection: "row" }}>
          {props && props.image ?
            <img className={props.description && props.description.length>250? "texNuggetImage texNuggetImageLarger": "texNuggetImage"} src={props.image} ></img>
            : null}

          <div style = {{margin:"auto", width:"fit-content"}}>
            <h1 className='text-nugget-title okashi'>{props.title}</h1>
            <h2 className={props.description && props.description.length>250? 'text-nugget-content long-text-nugget-content zenK' : 'text-nugget-content zenK'}>
              {showMore?
              props.description
              :props.description.substring(0, 250)
              }
              {props.description.length>250 && !showMore?
              <div 
              className = "zenGlowButton whiteGlowButton" 
              style = {{margin:"auto", marginTop:"5px"}}
              onClick = {() => setShowMore(true)}
              >Show More</div>
              :props.description.length>250 && showMore?
              <div 
              className = "zenGlowButton whiteGlowButton" 
              style = {{margin:"auto", marginTop:"5px"}}
              onClick = {() => setShowMore(false)}
              >Show Less</div>
              :null}
            </h2>
          </div>
        </div>

      </div>
    </div>
  );
}