import React, { useState } from 'react';
import "../../../../styling/vacana/vacana.scss";
import "../../../../styling/common/index.scss";
import "../../../../styling/stream/stream.scss";
import "../../../../styling/explore/explore.scss";

import ZenContentInterface from '../ZenContentInterface';
import { PracticePreviewControls } from './PracticePreviewControls';
import axios from 'axios';
import PractitionerInterface from '../../../practitioner/PractitionerInterface';
import { ZEN_C_ENDPOINT } from '../../../../config/EnvironmentalVariables';
import { Link } from 'react-router-dom';
import { ProvideMyStuffContext } from '../../../../hooks/MyStuffContext';
import { useZenJournalService } from '../../../../hooks/ZenJournalContext';

export interface PracticePreviewInterface extends ZenContentInterface {
  listItem?: string;
  summary?: boolean;
  removeBookmark?: () => void;
  unclickable?: boolean;
  selectedItem?:boolean;
  move?:any;
  showCompletion?:boolean;
  remove?:any;
}


export const PracticePreview = (props: PracticePreviewInterface) => {
  const [showSyllabus, setShowSyllabus] = useState(false);
  const [practitioner, setPracitioner] = useState(null as PractitionerInterface);
  const [getTries, setGetTries] = useState(0);
  const [searchPending, setSearchPending] = useState(null);

  const getPractitionerInfo = (username: string) => {
    setSearchPending(true);
    axios.get(ZEN_C_ENDPOINT + `/myzen/practitioner/` + username)
      .then(res => {
        if (res.data) {
          setPracitioner(res.data);
        }
      })

  }


  if (practitioner == null && props.practitioner != null && getTries < 5 && !searchPending) {
    setGetTries(getTries + 1);
    getPractitionerInfo(props.practitioner);
  }

  var practiceType = 'Lecture';
  var practicePortalLink = "/meditation/" + props.id;

  //var holderClass = props.previewSize == '3'? 'practice-preview medium-preview' : 'practice-preview small-preview';

  let practiceLogService = useZenJournalService();

  if (props.showCompletion && practiceLogService && practiceLogService.journalLogs == null) {
    let result = practiceLogService.getUserJournal();
  }

  let completedPractices = props.showCompletion && practiceLogService && practiceLogService.journalLogs && practiceLogService.journalLogs.length > 0 ? practiceLogService.journalLogs.filter(e => (e != null && e.practiceLog != null)).map(pl => pl.practiceLog.practiceId) : [];
 
  var userHasCompletedPractice = completedPractices.includes(props.id);

  var holderClass = props.selectedItem? 'zencyclopaedia-content-list-item practice-preview selected-list-prievew':'zencyclopaedia-content-list-item practice-preview';

  if(props.showCompletion && !userHasCompletedPractice){
    console.log("showing as incomplete practice");
    holderClass = holderClass + " uncompletedPractice"
  } else if (props.showCompletion){
    console.log("showing as complete practice");
  } else {
    console.log("not showing completio status")
  }

  
  return (

    <ProvideMyStuffContext>
      <div className={holderClass}>
        {props.unclickable ?
          <img className="content-preview-image content-preview-image-sSmaller" src={props.image ? props.image : require("../../../../static/photos/AwM.png")} onClick={() => setShowSyllabus(!showSyllabus)}></img>
          : <Link to={practicePortalLink}>
            <img className="content-preview-image" src={props.image ? props.image : require("../../../../static/photos/AwM.png")} onClick={() => setShowSyllabus(!showSyllabus)}></img>
          </Link>
        }


        <div className='practice-preview-info'>
          <h4 className="practice-preview-title okashi">{props.title}</h4>
          <h4 className="practitioner-preview-name zenK">by {practitioner ? practitioner.username : 'ZENcyclopedia'}</h4>
          {!props.unclickable ?
            <div className="practice-preview-controls">
              <PracticePreviewControls {...props} listItem={"zenc"}></PracticePreviewControls>
            </div>
            : null
          }

        </div>
        
        {props.move? 
        <div style = {{margin:"auto", marginTop:"0", paddingTop:"0"}}>
            <div className = "movePracticeIcon movePracticeUp" onClick = {() => props.move("UP")}  style = {{marginBottom:"15px"}}></div>
            <div className="deletePracticeIcon" onClick={() => props.remove()} style = {{marginBottom:"15px"}}></div>
            <div  className = "movePracticeIcon movePracticeDown"  onClick = {() => props.move("DOWN")}></div>
        </div>
        :null}
      </div>
    </ProvideMyStuffContext>
  );
}