import React, { useState } from 'react';
import "../../styling/vacana/vacana.scss";
import "../../styling/common/index.scss";
import "../../styling/stream/stream.scss";
import "../../styling/zenverse/Zenverse.scss";
import { EnsoPlayer } from '../media/audio/EnsoPlayer';
import Zenscape from '../zenverse/interfaces/ZenscapeInterface';
import { PortalIcon } from '../zenverse/ZenscapePortal/PortalIcon';
import { ZenverseSidebar } from '../zenverse/ZenverseSidebar';
import ZenscapeInterface from '../zenverse/interfaces/ZenscapeInterface';
import { Navigate, useNavigate } from "react-router-dom";
import { getPortalJSX, getPortalPreview } from '../../service/ZenverseService';
import { ZenscapePortal } from '../zenverse/ZenscapePortal/ZenscapePortal';
import { useZenverseService } from '../../hooks/ZenverseContext';
import { ProvideZenAudioContext } from '../../hooks/ZenAudioContext';
import { useCountdownTimer } from 'use-countdown-timer';
import Slider from 'react-slick';
import { ProvideZenJournalContext } from '../../hooks/ZenJournalContext';
import { useAuth } from '../../hooks/auth';
import { ZenSplashPopUp } from '../common/ZenSplashPopUp';
import { ProvideGongSoundContext } from '../../hooks/sound/GongSoundContext';

interface DemoPortalPortalState {
  activePortal: string,
  started: boolean,
  practicePortalQuotaStarted: boolean,
  playing: boolean,
  zenscape: any,
  totalZenTime: any
}

export const ZenscapeDemoPortal = (props:
  {
    zenscapes?: any,
    showExitIcon?: boolean,
    duration?: any,
    curTime?: number,
    //setPracticePlaying: any,
    setVideoStarted?: any,
    practicePlaying?: boolean,
    finished?: boolean,
    setFinished?: any,
    finishPage?: any,
    setClickedTime?: any,
    children?: any,
    guide?: boolean,
    practice?: any,
    zenScapeOvverride?: boolean,
    practiceZenergyBoost?: number
  }) => {

  const [zenPracticePortalState, updatePracticePortalState] = useState({} as DemoPortalPortalState);
  const [themeClass, setThemeClass] = useState("");
  const [prevTimeoutId, setprevTimeout] = useState(null);

  const practicePortalQuotaTimeCheckPeriod = 5;

  const buddBackedPortals = ["GongWithTheWind", "Zen Den"];
  const blendNavBarZenscapes = ["GongWithTheWind"];
  let navigate = useNavigate();

  let zenverse = useZenverseService();

  const sliderRef: any = React.createRef();
  const desktopSliderRef: any = React.createRef();

  const zenPortalsIcons = props.zenscapes != null && props.zenscapes.length > 0 ? props.zenscapes.map((portal, index) => {
    return <PortalIcon
      {...portal}
      index={index}
      parentSlider={sliderRef}
      desktopParentSlider={desktopSliderRef}
      // notifyShowPreview={props.removeIntro ? () => props.removeIntro() : () => { }}
      preview={getPortalPreview(portal, (portal) => loadZenscapeJSX(portal))}
      jsx={getPortalJSX(portal, true)}
      loadZenscapeJSX={(scape) => loadZenscapeJSX(scape)}
      key={"dp_p" + portal.id}></PortalIcon>;
  }) : [];

  const { countdown, start, reset, pause, isRunning } = useCountdownTimer({
    timer: (1.5 * 60 * 1000)
  });


  const startZenscapeDemoTimer = (zenscapeId: string) => {
    reset();
    if (prevTimeoutId) {
      clearTimeout(prevTimeoutId);
    }
    start();
    setprevTimeout(setTimeout(() => {
      setThemeClass("");
      updatePracticePortalState({
        activePortal: "zenscape-preview",
        started: true,
        playing: false,
        zenscape: zenPracticePortalState.zenscape,
        practicePortalQuotaStarted: zenPracticePortalState.practicePortalQuotaStarted,
        totalZenTime: zenPracticePortalState.totalZenTime
      });
    }, (1.5 * 60 * 1000)));
  }


  const loadZenscapeJSX = (scape: ZenscapeInterface, activePortal?: string) => {


    var oldActivePortal = activePortal ? activePortal : zenPracticePortalState.activePortal;
    var newActivePortal = oldActivePortal == "practice" || oldActivePortal == "practice-only" ? "zenscape-preview" :
      oldActivePortal == "zenscape-preview" && zenPracticePortalState.zenscape && zenPracticePortalState.zenscape.id != scape.id ? "zenscape-preview" :
        oldActivePortal == "zenscape-preview" && zenPracticePortalState.zenscape && zenPracticePortalState.zenscape.id == scape.id ? "zenscape" : "zenscape-preview";

    setThemeClass(newActivePortal == "zenscape" && scape.details && buddBackedPortals.includes(scape.details.title) ? " budd-backed" : "");

    if (newActivePortal == "zenscape") {
      startZenscapeDemoTimer(scape.id);
    }
    updatePracticePortalState({
      activePortal: newActivePortal,
      started: true,
      playing: zenPracticePortalState.playing,
      zenscape: scape,
      practicePortalQuotaStarted: zenPracticePortalState.practicePortalQuotaStarted,
      totalZenTime: zenPracticePortalState.totalZenTime
    });
  }

  if (zenPracticePortalState.zenscape == null && props.zenscapes != null && props.zenscapes.length > 0) {
    loadZenscapeJSX(props.zenscapes[0])
  }

  const zenPortals = props.zenscapes && props.zenscapes.length > 0 ? props.zenscapes.map((portal, index) => <ZenscapePortal smallImage={true} active={zenPracticePortalState.activePortal == "zenscape" && zenPracticePortalState.zenscape.id == portal.id} parentSlider={sliderRef} index={index} {...portal} activePortal={zenPracticePortalState ? zenPracticePortalState.activePortal : "zenscape-preview"} jsx={getPortalJSX(portal, true)} key={portal && portal.id ? "zpp" + portal.id : "zppN"} additionalText={portal.additionalText} details={portal && portal.details ? portal.details : null} video={portal.video ? portal.video : portal.videoUrl ? portal.videoUrl : null} loadZenscapeJSX={(scape, activePortal) => loadZenscapeJSX(scape, activePortal)}></ZenscapePortal>) : props.zenscapes && props.zenscapes.length == 0 ? [] : null;

  const formatTimeRemaining = (time: any) => {
    return time / 1000;
  }


  // let portalIsBuddBacked = zenPracticePortalState.zenscape && zenPracticePortalState.zenscape.details && buddBackedPortals.includes(zenPracticePortalState.zenscape.details.title);
  // let blendSidebar = zenPracticePortalState.zenscape && zenPracticePortalState.zenscape.details && blendNavBarZenscapes.includes(zenPracticePortalState.zenscape.details.title);



  return (
    <ProvideZenAudioContext>
      <ProvideGongSoundContext>
        <div className={"zen-portal-demo-browser" + themeClass}>

          {zenPracticePortalState.activePortal == "zenscape" ?
            <div className="demo-time-remaining-display">
              Demo Time Remaining: {formatTimeRemaining(countdown)}
            </div>
            : null
          }

            {!(props.finishPage && props.finished)? 
            <div className='portal-exit-icon' 
            style = {props.finishPage && zenPracticePortalState.activePortal == "zenscape-preview"? {rotate:"180deg", left:"auto", right:"25px"} : {}}
            onClick={() => {
              if (zenPracticePortalState.activePortal == "zenscape") {
                setThemeClass("");
                updatePracticePortalState({
                  activePortal: "zenscape-preview",
                  started: zenPracticePortalState.started,
                  playing: zenPracticePortalState.playing,
                  zenscape: zenPracticePortalState.zenscape,
                  practicePortalQuotaStarted: zenPracticePortalState.practicePortalQuotaStarted,
                  totalZenTime: zenPracticePortalState.totalZenTime
                })
              } else if (props.finishPage != null && !props.finished) {
                props.setFinished(true);
              } else if (!props.finishPage) {
                navigate("/explore/zenscapes");
              }
            }}>
            </div>:null}


          {!props.finished ?
            <ZenverseSidebar loading={zenverse.searchPending} zenPortalsIcons={zenPortalsIcons} setActivePortal={(portal) => updatePracticePortalState({
              activePortal: portal,
              started: zenPracticePortalState.started,
              playing: zenPracticePortalState.playing,
              zenscape: zenPracticePortalState.zenscape,
              practicePortalQuotaStarted: zenPracticePortalState.practicePortalQuotaStarted,
              totalZenTime: zenPracticePortalState.totalZenTime
            })}
              blendMode={themeClass == " budd-backed"}
            ></ZenverseSidebar>
            : null
          }

          <div className={'practice-portal-content-container ppzc-controls-provided'}>
            {zenPracticePortalState.activePortal == "zenscape" ?
              <div className='zenscape-demo-portal-holder'>
                <ZenscapePortal {...zenPracticePortalState.zenscape} active={true} jsx={getPortalJSX(zenPracticePortalState.zenscape, true)} activePortal={zenPracticePortalState ? zenPracticePortalState.activePortal : "zenscape-preview"} ></ZenscapePortal>
              </div>
              : null}
            <div className="zen-mobile zenportal-carousel" style={zenPracticePortalState.activePortal == "zenscape-preview" ? { display: "block" } : { display: "none" }}>
              <React.Fragment>
                <div className="zen-mobile">
                  <Slider ref={sliderRef} className="zen-portal-slider">
                    {zenPortals}
                  </Slider>
                </div>
                <div className="zen-desktop">
                  <Slider ref={desktopSliderRef}
                    centerMode={true}
                    centerPadding='30%'>
                    {zenPortals}
                  </Slider>
                </div>
              </React.Fragment>
            </div>

          </div>


          {/* Practice Completion Page */}
          {props.finished && props.finishPage ?
            <>{props.finishPage}</>
            : props.finished ?
              <Navigate to={"/myZen"} />
              : null}
        </div>
      </ProvideGongSoundContext>
    </ProvideZenAudioContext>
  );




}