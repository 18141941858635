import * as React from "react";
import { MyZenNavBar } from "../../common/navbar/MyZenNavBar";
import "../../../styling/common/index.scss";
import { ZenNavBar } from "../../common/navbar/ZenNavBar";
import { useState } from "react";
import axios from "axios";
import { useAuth } from "../../../hooks/auth";
import PrivateRoute from "../../common/PrivateRoute";
import { useParams } from "react-router-dom";
import { ZEN_C_ENDPOINT } from "../../../config/EnvironmentalVariables";
import PractitionerInterface from "../PractitionerInterface";
import ZenContentInterface from "../../zencontent/practices/ZenContentInterface";
import { getContentListPreviews } from "../../../service/ContentService";
import { ZenImageBrowser } from "./ZenImageBrowser";
import { ZenNugget } from "../../zencontent/nuggets/ZenNugget";
import ImageDrop from "./ImageDrop";
import BrowseLocalFilesButton from "./BrowseLocalFilesButton";
import { ZenLoader } from "../../common/ZenLoader";

export const NuggetStudio = (props: { page?: string, setPage?: any, subPage?: string, setSubPage?: any, userId: any }) => {

      const { practitonerId } = useParams();

      const [title, setTitle] = useState(null);
      const [description, setDescription] = useState(null);
      const [nuggetType, setNuggetType] = useState(null);
      const [imageFile, setImageFile] = useState<File | null>(null);
      const [previewURL, setPreviewURL] = useState<string | null>(null);
      const [stepNum, setStepNum] = useState("nugget-type");
      const [loading, setLoading] = useState(false);

      const uploadNugget = () => {
            setLoading(true);
            setStepNum("loading");
            if(!loading){

            axios.post(ZEN_C_ENDPOINT + `/zen/nuggets/create`, {
                  nuggetType: nuggetType,
                  previewType: nuggetType,
                  contentType:"NUGGET",
                  title: title,
                  description: description,
                  practitioner: props.userId
            }).then(function (response) {
                 //console.log(response);
                 console.log("Adding Image: ");
                 let id = response.data.contentId;
                 const formData = new FormData();
                 formData.append("image", imageFile);
                 axios.post(ZEN_C_ENDPOINT + `/zen/content/addPhoto/`+id, formData, {
                       headers: {
                         "Content-Type": "multipart/form-data",
                       },
                     }).then(function (response) {
                       //console.log(response);
                       setStepNum("success");
                       setLoading(false);                       
                 }).catch(function (error) {
                       console.log(error);
                       setStepNum("success");
                       setLoading(false);
                 });
                 
                  // LEt user know it was a success:
            })
                  .catch(function (error) {
                        setStepNum("success");
                        setLoading(false);
                  });
            }
      }

      const handleFileSelect = (files: FileList | null) => {
            if (files && files.length > 0) {
                  const selectedFile = files[0];
                  if (selectedFile && (selectedFile.type === "image/jpeg" || selectedFile.type === "image/png")) {
                        setImageFile(selectedFile);
                        setPreviewURL(URL.createObjectURL(selectedFile));
                  }
            }
      };

      return (


            // ASK IMAGE OR TEXT BASED
            <div style={{ overflowY: "scroll", maxHeight: "100vh"}}>
            <div style={{ paddingBottom: "125px", paddingTop:"125px" }}>

                  <div className='explore-back-icon' style={{ top: "150px" }}
                        onClick={() => {
                              props.setPage("MyZen");
                              props.setSubPage("Tasks");
                        }}></div>
                  {stepNum == "nugget-type" ?
                        <React.Fragment>
                              <h2 style={{ margin: "auto", textAlign: "center", marginBottom: "45px" }}>Choose Nugget Type</h2>
                              <div className="zencyclopaedia-main-menu-item-holder zen-create-option"
                                    onClick={() => {
                                          props.setPage("Practitioner-Create");
                                          setStepNum("text-nugget-details");
                                          setNuggetType("TEXT");
                                    }}
                              >
                                    <div style={{ width: "100%" }}>
                                          <h2>Text</h2>
                                          <h3>Up to 300 characters and 1 small image</h3>
                                    </div>
                              </div>
                              <h2 className="okashi" style={{ margin: "auto", textAlign: "center", marginBottom: "15px", marginTop: "15px" }}>or</h2>
                              <div className="zencyclopaedia-main-menu-item-holder zen-create-option"
                                    onClick={() => {
                                          props.setPage("Practitioner-Create");
                                          setNuggetType("IMAGE");
                                          setStepNum("image-nugget-details");
                                    }}
                              >
                                    <div style={{ width: "100%" }}
                                    >
                                          <h2>Image</h2>
                                          <h3>1 Large image and up to 150 characters</h3>
                                    </div>
                              </div>
                        </React.Fragment>
                        : stepNum == "text-nugget-details" ?

                              <React.Fragment>
                                    <textarea
                                          placeholder="Share some wisdom..."
                                          value={description}
                                          onChange={(e) => setDescription(e.target.value)}
                                          className={'nugget-text-input'}
                                    />
                                    <h3 style={{ margin: "auto", textAlign: "center", marginBottom: "15px", marginTop: "15px" }}>Add a title (optional)</h3>
                                    <input
                                          type="text"
                                          placeholder={"Enter a title..."}
                                          value={title}
                                          onChange={(e) => setTitle(e.target.value)}
                                          className={'su-text-input'}
                                    />


                                    <div className="zenGlowButton mingGlowButton" onClick={() => setStepNum("upload-image")} style={{ marginTop: "35px" }}>
                                          Next
                                    </div>
                              </React.Fragment>
                              : stepNum == "image-nugget-details" ?
                                    <React.Fragment>
                                          <div className="zen-desktop">
                                                <div className="practice-image-holder" style={{ minWidth: "50w", maxWidth: "50vw" }}>
                                                      <ImageDrop
                                                            endpoint={""}
                                                            setFile={setImageFile}
                                                            setPreviewURL={setPreviewURL}
                                                            file={imageFile}
                                                            previewURL={previewURL}
                                                            handleFileSelect = {handleFileSelect}
                                                      />
                                                </div>
                                          </div>

                                          <div className="zen-mobile">

                                                {previewURL ?
                                                      <img className="content-preview-image" src={previewURL} ></img>
                                                      :
                                                      <div>
                                                            <h4 style={{ margin: "auto", textAlign: "center", marginTop: "9px", marginBottom: "15px" }}>Choose Image:</h4>
                                                            <div style={{ display: "flex", flexDirection: "row", width: "275px", margin: "auto" }}>
                                                                  <div className="zenGlowButton mingGlowButton">Zen C Library</div>
                                                                  <BrowseLocalFilesButton accept="image/jpeg, image/png" onFileSelect={handleFileSelect} />
                                                            </div>
                                                      </div>}

                                          </div>
                                          <h3 style={{ margin: "auto", textAlign: "center", marginBottom: "15px", marginTop: "15px" }}>Add a description (optional)</h3>
                                          
                                    <textarea
                                          placeholder="Add Nugget Caption..."
                                          value={description}
                                          onChange={(e) => setDescription(e.target.value)}
                                          className={'nugget-text-input nugget-caption-input'}
                                    />


                                          <div className="zenGlowButton mingGlowButton" onClick={() => setStepNum("confirm")} style={{ marginTop: "35px" }}>
                                                Next
                                          </div>
                                    </React.Fragment>
                                    : stepNum == "image" || stepNum == "upload-image" ?
                                          <React.Fragment>
                                                <h2 className="okashi" style={{ margin: "auto", textAlign: "center", marginBottom: "5px" }}>Add Practice Image</h2>
                                                {/* <div style={{ display: "flex", flexDirection: "row" }}>
                                                      <h1 className={stepNum == 'image' ? "subPageNavHeader subPageActiveNavHeader" : "subPageNavHeader"} onClick={() => setStepNum('image')}>Choose</h1>
                                                      <h1 className={stepNum == 'upload-image' ? "subPageNavHeader subPageActiveNavHeader" : "subPageNavHeader"} onClick={() => setStepNum('upload-image')}>Upload</h1>
                                                </div> */}
                                                {stepNum == 'upload-image' ?
                                                      <div className="image-upload-area">
                                                            <div className="zen-desktop">
                                                                  <div className="practice-image-holder" style={{ minWidth: "50w", maxWidth: "50vw" }}>
                                                                        <ImageDrop
                                                                              endpoint={""}
                                                                              setFile={setImageFile}
                                                                              setPreviewURL={setPreviewURL}
                                                                              file={imageFile}
                                                                              previewURL={previewURL}
                                                                              handleFileSelect = {handleFileSelect}
                                                                        />
                                                                  </div>
                                                            </div>

                                                            <div className="zen-mobile">

                                                                  {previewURL ?
                                                                        <img className="content-preview-image" src={previewURL} ></img>
                                                                        :
                                                                        <div>
                                                                              <div style={{ display: "flex", flexDirection: "row", width: "275px", margin: "auto" }}>
                                                                                    <BrowseLocalFilesButton accept="image/jpeg, image/png" onFileSelect={handleFileSelect} />
                                                                                    <div className="zenGlowButton mingGlowButton"  onClick={() => setStepNum("confirm")}>Skip</div>
                                                                              </div>
                                                                        </div>}

                                                            </div>
                                                            {imageFile !=null? 
                                                            <div className="zenGlowButton sageGlowButton" style={{ marginTop: "20px" }}  onClick={() => setStepNum("confirm")}>Next</div>
                                                      :null}
                                                      </div>
                                                      :
                                                      <ZenImageBrowser confirmChoice = {() => {}}/>}
                                          </React.Fragment>

                                          : loading?
                                          <div style={{ marginTop:"-15px"}}>
                                               <ZenLoader/>
                                          </div>
                                          : stepNum == "confirm" ?
                                                <div style={{ marginTop:"-15px"}}>
                                                      <h2 style={{ margin: "auto", textAlign: "center", marginBottom: "15px", marginTop: "0" }}>Nugget Preview:</h2>
                                                      <div>
                                                            <ZenNugget
                                                                  title={title}
                                                                  description={description}
                                                                  image={previewURL}
                                                                  nuggetType={nuggetType}
                                                            ></ZenNugget>
                                                            <div className="zenGlowButton sageGlowButton" style={{ marginTop: "20px" }} onClick={() => uploadNugget()}>Upload</div>
                                                      </div>
                                                </div>
                                                : stepNum == "success" ?
                                                <div style = {{margin:"auto"}}>
                                                      <h1 className = "okashi">SUCCESS!</h1>
                                                      <h3 >The nugget has been added to your catalogue!</h3>
                                                            <div className="zenGlowButton mingGlowButton" style={{ marginTop: "20px" }} onClick={() => {
                                                                  props.setPage("Practitioner")
                                                            }}>Finish</div>
                                                </div>
                                                : null

                  }
</div>
            </div>);

}
