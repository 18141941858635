import * as React from "react";
import '../../../styling/common/index.scss';
import { useState } from "react";
import { useAuth } from "../../../hooks/auth";
import axios from "axios";
import { ZEN_C_ENDPOINT } from "../../../config/EnvironmentalVariables";
import { useMyZenService } from "../../../hooks/ZenUserContext";
import { PuffLoader } from "react-spinners";
import { ZenLoader } from "../../common/ZenLoader";

interface ZenCard {
  cardKey?: number;
  image?: any;
}

export const ChooseProfiePick = (props: any) => {

  const auth = useAuth();
  const [pics, setPics] = useState([]);
  const [getPicAttempts, setGetPicAttempts] = useState(0);
  const [picsPending, setPicsPending] = useState(false);

  let myZen = useMyZenService();


  const submitChoice = async (picId: string) => {

    axios.post(ZEN_C_ENDPOINT + `/myzen/myZen/setPhoto/` + auth.username.toLowerCase() + `/` + picId).then(function (response) {
    //myZen.getProfile(auth.username.toLowerCase());
    props.next();
    })
      .catch(function (error) {
        console.log(error);
        props.next();
      });


  };

  const setUserIconOptions = async () => {
    setPicsPending(true);
    await axios.get(ZEN_C_ENDPOINT + `/myzen/cards/getProfilePicCards`)
      .then(res => {
        const allPics: ZenCard[] = res.data;
        setPics(allPics);
        setPicsPending(false);
      }).catch(() => {
        setPicsPending(false);
      });
  }

  if (pics.length == 0 && !picsPending && getPicAttempts < 5) {
    setGetPicAttempts(getPicAttempts + 1);
    setUserIconOptions();
  }

  let picJSXList = pics && pics.length > 0 ? pics.map((p) => <img className="choosePP" src={p.image} onClick={() => submitChoice(p.cardKey)}></img>) : null;
  return (
    <div className="choosePPHolder" >
      <h2 className="choosePPHeader" >Choose Your Profile Photo</h2>

      {picJSXList && picJSXList.length > 0 ? picJSXList.map((p, index) => {
        if (index % 2 === 0) {
          return (
            <div style={{ display: "flex", flexDirection: "row", paddingTop: "25px", width: "350px", margin: "auto" }}>
              {p}
              {picJSXList.length >= index + 1 ?
                picJSXList[index + 1]
                : 
                <div className="zencLoader">
                      <PuffLoader color="#033f63" size={300}>
                      </PuffLoader>
                </div>}
            </div>
          );
        }
      }) : <ZenLoader/>}

      <div>

      </div>
    </div>

  );
}




