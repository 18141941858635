import React  from "react";
import ZenContentInterface from "../components/zencontent/practices/ZenContentInterface";
import { PracticeFullyFocusedPreview } from "../components/zencontent/practices/previews/PracticeFullyFocusedPreview";
import { PracticeOverview } from "../components/zencontent/practices/previews/PracticeOverview";
import { PracticePortalPreview } from "../components/zencontent/practices/previews/PracticePortalPreview";
import { PracticePreview } from "../components/zencontent/practices/previews/PracticePreview";
import { InfomaticStreamItem } from "../components/zencontent/infomatics/InfomaticStreamItem";
import { ZenNugget } from "../components/zencontent/nuggets/ZenNugget";
import { PathOverview } from "../components/zencontent/courses/PathOverview";
import { Adsense } from "@ctrl/react-adsense";
import { ContentTypes } from "../components/zencontent/common/ContentTypes";


export const getSubscriptionPrice = (subscriptionTier:string) => {
  return subscriptionTier == "Basic Tier"?
<h3>Just 99p per month!</h3>
:subscriptionTier == "Premium Tier"?
<h3>£1.99 per month</h3>
: null;

}

export const getSubscriptionAllowanceMessage = (subscriptionTier:string) => {
    return subscriptionTier == "Free Tier"? <ul className = "zen-sub-card-perks-list">
      <li><span className = "innerText">3 Hours of free Zen Portal time per week</span></li>
      <li><span className = "innerText">Access to the Zen Stream and endless uplifting content!</span></li>
    </ul>
    :subscriptionTier == "Basic Tier"?
    <ul className = "zen-sub-card-perks-list">
      <li><span className = "innerText">Premium access to the Zencyclopedia information library</span></li>
      <li><span className = "innerText">5 Hours of free Zen Portal time per week</span></li>
      <li><span className = "innerText">Access to the Zen Stream and endless uplifting content!</span></li>
      <li><span className = "innerText">Premium content discounts</span></li>
    </ul>
    :subscriptionTier == "Premium Tier"?
    <ul className = "zen-sub-card-perks-list">
      <li><span className = "innerText">Unlimited access to the Zencyclopedia information library</span></li>
      <li><span className = "innerText">Unlimited meditation allowance in the Zen Portal</span></li>
      <li><span className = "innerText">Exclusive early access & premium content discounts</span></li>
    </ul>
    : null;
}

