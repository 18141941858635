import React from "react";
import ReactPlayer from "react-player";
import { ExplorationMilestone, MindfulMinutesMilestone, PortalInventorySizeBoostPowers, PortalTimeBoostPowers, PracticesCompletedMilestone, SocialMilestones, ZenJourneyMilestone, ZenStreakMilestone, ZenSubscriptions } from "../components/explore/icons/CardSubtypes";
import { ZenCardsGroup } from "../components/explore/icons/ZenCardsGroup";


const rankSubCard = (cardKey : string, subTier: string) => {
  console.log("Ranking "+cardKey+" for: "+subTier);
    if(cardKey == "PREMIUM_SUBSCRIBER" && subTier != "FREE"){
      return 1;
    } else if (cardKey == "PREMIUM_SUBSCRIBER" && subTier == "FREE"){
        return 2;
    } else if(cardKey == "BASIC_SUBSCRIBER" && subTier == "FREE"){
      return 1;
    } else if (cardKey == "BASIC_SUBSCRIBER" && subTier == "PREMIUM"){
        return 2;
    } else {
      return 3;
    }
}


export const getCardSubGroups = (cardList: any [], setConfirm: (card:any) => void, subscriptionTier: string) => {
  
  console.log("setting card groups for: ");
  console.log(cardList);

  let allGroups = [];

  //Powers:
  let timeBoostPowers = cardList.filter(c => c.subtype == PortalInventorySizeBoostPowers.Power);
  let timeBoostSection = <ZenCardsGroup groupName = {PortalInventorySizeBoostPowers.DisplayName} cards = {timeBoostPowers} setConfirmIcon = {(card) => setConfirm(card)}/>;
  

  let inventoryBoostPowers = cardList.filter(c => c.subtype == PortalTimeBoostPowers.Power);
  let inventoryBoostSection = <ZenCardsGroup groupName = {PortalTimeBoostPowers.DisplayName}  cards = {inventoryBoostPowers} setConfirmIcon = {(card) => setConfirm(card)}/>;
  

  //Milestones
  let totalMedsMilestones = cardList.filter(c => c.subtype == PracticesCompletedMilestone.Milestone);
  let totalMedsMilestonesSection = <ZenCardsGroup groupName = {PracticesCompletedMilestone.DisplayName}  cards = {totalMedsMilestones} setConfirmIcon = {(card) => setConfirm(card)}/>;
  

  let mindfulMinsMilestones = cardList.filter(c => c.subtype == MindfulMinutesMilestone.Milestone);
  let mindfulMinsSection = <ZenCardsGroup groupName = {MindfulMinutesMilestone.DisplayName}  cards = {mindfulMinsMilestones} setConfirmIcon = {(card) => setConfirm(card)}/>;
  

  let zenStreakMilestones = cardList.filter(c => c.subtype == ZenStreakMilestone.Milestone);
  let zenStreakSection = <ZenCardsGroup groupName = {ZenStreakMilestone.DisplayName}  cards = {zenStreakMilestones} setConfirmIcon = {(card) => setConfirm(card)}/>;

  let zenJourneyMilestones = cardList.filter(c => c.subtype == ZenJourneyMilestone.Milestone);
  let zenJourneySection = <ZenCardsGroup groupName = {ZenJourneyMilestone.DisplayName}  cards = {zenJourneyMilestones} setConfirmIcon = {(card) => setConfirm(card)}/>;

  let courseCompletionMilestones = cardList.filter(c => c.subtype == "COURSE_COMPLETION_MILESTONE");
  let courseMilestones = courseCompletionMilestones && courseCompletionMilestones.length >0? <ZenCardsGroup groupName = {"Course Completion"}  cards = {courseCompletionMilestones} setConfirmIcon = {(card) => setConfirm(card)}/> : null;

  let socialMilestones = cardList.filter(c => c.subtype == SocialMilestones.Milestone);
  let socialSection = <ZenCardsGroup groupName = {SocialMilestones.DisplayName}  cards = {socialMilestones} setConfirmIcon = {(card) => setConfirm(card)}/>;

  let explorationMilestones = cardList.filter(c => c.subtype == ExplorationMilestone.Milestone);
  let explorationSection = <ZenCardsGroup groupName = {ExplorationMilestone.DisplayName}  cards = {explorationMilestones} setConfirmIcon = {(card) => setConfirm(card)}/>;

  let subscriptionCards = cardList.filter(c => c.subtype == ZenSubscriptions.Key);
 
  //Order subscription cards:
  //Show nearest upgrade first. 
  //If not possible, show current card (if premium user).

  let subscriptionSection = <ZenCardsGroup groupName = {ZenSubscriptions.DisplayName}  cards = {subscriptionCards.sort((s1, s2) => rankSubCard(s1.cardKey, subscriptionTier) - rankSubCard(s2.cardKey, subscriptionTier))}/>;


  allGroups.push(mindfulMinsSection);
  allGroups.push(totalMedsMilestonesSection);
  allGroups.push(zenStreakSection);
  allGroups.push(zenJourneySection);
  allGroups.push(courseMilestones);
  allGroups.push(socialSection);
  allGroups.push(explorationSection);
  allGroups.push(inventoryBoostSection);
  allGroups.push(timeBoostSection);
  allGroups.push(subscriptionSection);

  return allGroups;
}


export const cardIsMilestone = (card:any) => {
  return card.cardType == "MILESTONE";
}

export const cardIsPower = (card:any) => {
  return card.cardType == "POWER";
}

export const checkCardExpiry = (card:any) => {
  console.log("CHECKING EXPIRY: ");
  console.log(card.expiry);
  return true;
}