import * as React from "react";
import "../../styling/explore/explore.scss";
import "../../styling/common/index.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../styling/common/searchBox.scss";
import { ZenNavBar } from "../common/navbar/ZenNavBar";
import { useNavigate, useParams } from "react-router";
import PrivateRoute from "../common/PrivateRoute";
import { useZenExploreService } from "../../hooks/ZenExploreContext";
import { createAlphabetGroups, generateItemPreview, getContentCatagories, getMeditationGroups } from "../../service/ZencyclopaediaService";
import { InfomaticTitlePage } from "./Infomatics/InfomaticTitlePage";
import { PuffLoader } from "react-spinners";
import { PracticePreview } from "../zencontent/practices/previews/PracticePreview";
import { useState } from "react";
import { PathPreview } from "../zencontent/courses/PathPreview";
import MetaTags from 'react-meta-tags';
import { ZenCardMilestoneNotice } from "./icons/ZenCardMilestoneNotice";
import { useZenCardsService } from "../../hooks/ZenCardsContext";
import { getNativeSelectUtilityClasses } from "@mui/material";

export const InformationBrowser = (props: any) => {


  const { searchId } = useParams();
  const [search, setSearch] = useState(null);
  const [hideBackArrow, setHideBackArrow] = useState(false);
  const [rewardCard, setRewardCard] = useState(null);
  const [cardAttempts,setCardAttempts] = useState(0);

  const useExplore = useZenExploreService();
  const cardsContext = useZenCardsService();
  let navigate = useNavigate();

  
  const EXPLORE_ZEN_LIBRARY_MILESTONE_KEY = "EX_ZL";

  const claimZenCExploreCard = async () => {
    let rewardCard = await cardsContext.claimMilestone(EXPLORE_ZEN_LIBRARY_MILESTONE_KEY);
    if(rewardCard != null){
      setRewardCard(rewardCard);
    }
  }

  const searchIsValid = (search: string) => {
    return search && (search.length > 2 || !Number.isNaN(Number(search)));
  }

  const determineSearchNeeded = () => {
    var search_item = search ? search : searchId;
    return searchIsValid(search_item) && useExplore.searchType != search_item && !useExplore.searchPending;
  }
  const getCurrentSearch = () => {
    return search != null ? search : searchId;
  }

  //Generate result items:   articles
  if (!useExplore.searchPending && getCurrentSearch() == null && (useExplore.contentItems == null || useExplore.searchType != "articles")){
      useExplore.getZencyclopaedia();
  } else if (determineSearchNeeded()) {
    useExplore.searchInfomatics(searchId);
  } 

  var items = [];

  if (useExplore.content != null && getCurrentSearch() == useExplore.searchType && getCurrentSearch() != "articles") {
    var content = useExplore.content;

    items = [];
    var jsx = <InfomaticTitlePage {...content} />;
    items.push(jsx);
  } else {
    items = createAlphabetGroups(useExplore.contentItems);
    if(cardAttempts<5 && !cardsContext.searchPending && !cardsContext.checkForMilestone(EXPLORE_ZEN_LIBRARY_MILESTONE_KEY)){
      setCardAttempts(cardAttempts+1);
      claimZenCExploreCard();
    } else {
    }
  }

  // Need to add private route if we are not on 'information'
  return (
    <React.Fragment>
      <MetaTags>
        <meta id="meta-description" name="description" content={"Explore the Zencyclopedia library, soon to be the worlds largest collection of free mindfulness resources. Learn everything you ever wanted to know about buddhism and ZEN!"} />
        <meta id="og-title" property="og:title" content="Information & Articles - Zencyclopedia" />
      </MetaTags>
      <ZenNavBar></ZenNavBar>
      <div className="information-browser">
        {rewardCard!=null?
        <div style={{marginTop:"45px"}}>
        <ZenCardMilestoneNotice cards = {[rewardCard]} moveOnAction={() => setRewardCard(null)}></ZenCardMilestoneNotice> 
        </div>
        :
        <React.Fragment>

        {searchId && !hideBackArrow || (search && search.length > 2) ? <div className='explore-back-icon' onClick={() => navigate(-1)}></div> : null}

        <div className="zencyc-search-bar-container" onClick={() => setHideBackArrow(true)}>
          {!props.infomatic ? <div className="box">
            <form name="search">
              <input value={search} type="text" className="input" name="txt" onMouseLeave={() => { }} onChange={(e) => { setSearch(e.target.value); }} />
              <div className="zencyc-search-go"></div>
            </form>
          </div> : null
          }
        </div>


        <div className="zencyclopaedia-results">
          {useExplore.searchPending ?
            <div className="zencLoader">
              <PuffLoader color="#033f63" size={300}>
              </PuffLoader>
            </div>
            : items.length > 0 ?
              <>
                {props.meditations && search ?
                  <h1 className="zen-c-results-header">Results for: {search}</h1>
                  : props.meditations && searchId ?
                    <h1 className="zen-c-results-header">Results for: {searchId}</h1>
                    :
                    <></>}
                {items}
              </>
              :
              <div className="zencLoader">
                <h1>NO RESULTS</h1>
              </div>
          }
        </div>
        </React.Fragment>}
      </div>
    </React.Fragment>
  );
}

