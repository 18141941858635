import React from "react";
import ReactPlayer from "react-player";
import { BreathEnergyPortal } from "../components/zenverse/ZenscapePortal/BreathEnergyPortal";
import { BubbleBreathPortal } from "../components/zenverse/ZenscapePortal/BubbleBreathPortal";
import { GongWithTheWindPortal } from "../components/zenverse/ZenscapePortal/GongWithTheWind/GongWithTheWindPortal";
import { NordiiPortal } from "../components/zenverse/ZenscapePortal/NordiiPortal";
import { OhmBoard } from "../components/zenverse/ZenscapePortal/OhmBoard/OhmBoard";
import { PortalPreview } from "../components/zenverse/ZenscapePortal/PortalPreview";
import { SimpleTimerPortal } from "../components/zenverse/ZenscapePortal/SimpleTimerPortal";
import { YoutubeZenscapePlayer } from "../components/zenverse/ZenscapePortal/YoutubeZenscapePlayer";

const rankZenscape = (zenscape: any, searchTerm: string) => {
  if(zenscape == null  || zenscape.details == null ){
      return 0;
  }
  console.log("ranking: "+zenscape.details.title);
  const musicPrefferedIds = [381,391];
  const starterPrefferedIds = [361,381,363,362,391];
  if (searchTerm == "music") {
    if (musicPrefferedIds.includes(zenscape.details.id)) {
      return musicPrefferedIds.indexOf(zenscape.details.id);
    } else {
      return zenscape.details.id + musicPrefferedIds.length;
    }
  } else if (searchTerm == "starter"){
    console.log("ranking from starter list");
    if (starterPrefferedIds.includes(zenscape.details.id)) {
      console.log("ranking from list: "+starterPrefferedIds.indexOf(zenscape.details.id));
      return starterPrefferedIds.indexOf(zenscape.details.id);
    } else {
      console.log("ranking from not in list: "+(zenscape.details.id + musicPrefferedIds.length));
      return zenscape.details.id + musicPrefferedIds.length;
    }
  } else {
    console.log("can't rank this one: "+zenscape.details.title);
    return zenscape.details.id;
  }
}
export const orderZenscapes = (zenscapeList: any[], searchTerm: string) => {

  console.log("ranking zenscapes");
  const sortedSearchTerms = ["music", "starter"]
    if (zenscapeList && zenscapeList.length > 0 && sortedSearchTerms.includes(searchTerm)){
        return zenscapeList.sort((s1, s2) => rankZenscape(s1, searchTerm) - rankZenscape(s2, searchTerm));
    } else return zenscapeList;
}

export const getPortalJSX = (portal: any, demoPortal?: boolean, setActivePortal?: any,) => {

  if (portal && portal.details && portal.details.title == 'Nordii') {
    return <NordiiPortal />
  } else if (portal && portal.details && portal.details.title == 'Light Over Dark') {
    return <BreathEnergyPortal />
  } else if (portal && portal.details && portal.details.title == 'Bubble Breath') {
    return <BubbleBreathPortal />
  } else if (portal && portal.details && portal.details.title == 'OmHome') {
    return <OhmBoard setActivePortal={(portal) => setActivePortal(portal)} demoPortal={demoPortal} />
    //return <OhmBoard activePortal={active} setActivePortal={(portal) => setActivePortal(portal)}/>
  } else if (portal && portal.details && portal.details.title == 'Simple Timer') {
    return <SimpleTimerPortal />
  } else if (portal && portal.details && portal.details.title == 'GongWithTheWind') {
    return <GongWithTheWindPortal />
  } else if (portal && portal.details && portal.details.title == 'Zen Den') {
    return <GongWithTheWindPortal />;
    // APPLY YOU TUBE THEMES:
  } else if (portal && portal.details && portal.details.title == 'JUMP') {
    return <YoutubeZenscapePlayer videoUrl={portal.videoUrl} themeClass={"strings-vid"}></YoutubeZenscapePlayer>;
  } else if (portal && portal.details && portal.details.title == 'Magic Mountain') {
    return <YoutubeZenscapePlayer videoUrl={portal.videoUrl} themeClass={"magic-mountain"}></YoutubeZenscapePlayer>;
  } else if (portal && portal.details && portal.details.title == 'Elegance Eden') {
    return <YoutubeZenscapePlayer videoUrl={portal.videoUrl} themeClass={"magic-mountain"}></YoutubeZenscapePlayer>;
  } else if (portal && portal.details && portal.details.title == 'Secret Dojo') {
    return <YoutubeZenscapePlayer videoUrl={portal.videoUrl} themeClass={"secret-dojo"}></YoutubeZenscapePlayer>;
  } else if (portal && portal.details && portal.details.title == 'String-Scape') {
    return <YoutubeZenscapePlayer videoUrl={portal.videoUrl} themeClass={"strings-vid"}></YoutubeZenscapePlayer>;
  } else if (portal && portal.videoUrl != null) {
    return <YoutubeZenscapePlayer videoUrl={portal.videoUrl} themeClass={portal.title}></YoutubeZenscapePlayer>;
  } else {
    return null;
  }
}

export const getPortalPreview = (portal: any, loadZenscapeJSX?: any) => {
  return <PortalPreview {...portal} hideControls={true} loadZenscapeJSX={(portal) => loadZenscapeJSX(portal)} />;
  //   };
}
