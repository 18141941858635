import * as React from "react";
import "../../../styling/common/index.scss";
import "../../../styling/common/zenCoreUIComponents.scss";
import "../../../styling/myzen/myzen.scss";
import { useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router";
import { PuffLoader } from "react-spinners";
import { ZEN_C_ENDPOINT } from "../../../config/EnvironmentalVariables";
import { ZenNavBar } from "../../common/navbar/ZenNavBar";
import { DeliveryDetailsModal } from "../common/DeliveryDetailsModal";
import { StickerPayment } from "./StickerPayment";
import { useAuth } from "../../../hooks/auth";
import { useZenShopService } from "../../../hooks/ZenShopContext";
import { Link } from "react-router-dom";

export const BuyStickersModal = () => {

      let auth = useAuth();
      let [page, setPage] = useState("main");
      let [loading, setLoading] = useState(false);
      let [packSize, setPackSize] = useState("medium");
      let [orderId, setOrderId] = useState(null);
      let navigate = useNavigate();
      
      let shopService = useZenShopService();
      
      const {type} = useParams();
      var oderSubtype = type == "giant"? "GIANT" : "REMINDER"

      const createStickerOrderRequest = (name: string, email: string, deliveryDetails: any) => {

            //Send request to back end:
            axios.post(ZEN_C_ENDPOINT + `/myzen/shop/order/start`, {
                  name: name,
                  email: email,
                  deliveryDetails: deliveryDetails,
                  orderItems: [
                        {
                              type: "STICKER",
                              subtype:oderSubtype,
                              amount:packSize == "small"? 3 : packSize == "large"? 10 : 6
                        }
                  ]
            }).then(function (response) {
                  let newOrder = response.data;
                  setOrderId(newOrder.id);
                  setPage("payment");
            }).catch(function (error) {
                  console.log(error);
                  console.log("failed to make t shirt req")
            });
      }


      const requestAddStickerToBasket = async () => {
            setLoading(true);
            addStickerToBasket();

      }

const addStickerToBasket = async () => {
      let packNum = packSize == "large"? 10 : packSize == "medium"? 6 : 3;
      let res = await shopService.addItemToBasket("STICKER", null, null, packNum, null);
      navigate("/shop/basket/added");
}


var stickerTypeDisplayText = type == "reminder"? "Mindfulness Reminder" :"Giant";


      return (
            <React.Fragment>
                  <div className="ZenBasicPage zen-page-holder">
                        <ZenNavBar />
                        {page == "main" ?
                              <div>
                                    <h4>Buy {stickerTypeDisplayText} Stickers</h4>
                                    <h2>Choose pack size:</h2>
                                    <div style = {{display:"flex",flexDirection:"row"}}>
                                          <div className = {packSize=="small"? "sticker-buy-amount-option sticker-buy-amount-option-active":"sticker-buy-amount-option"} onClick = {() => {
                                                setPackSize("small");
                                          }}>
                                                <div className = "sticker-buy-amount-option-value">
                                                      <b>Small</b><br/><span style={{fontSize:"90%"}}>(3 Stickers)</span>
                                                </div>
                                                <div className = "sticker-buy-amount-option-cost">
                                                      £1.50
                                                </div>
                                          </div>
                                          <div className = {packSize=="medium"? "sticker-buy-amount-option sticker-buy-amount-option-active":"sticker-buy-amount-option"} onClick = {() => {
                                                setPackSize("medium");
                                          }}>
                                                <div className = "sticker-buy-amount-option-value">
                                                <b>Medium</b> <br/><span style={{fontSize:"90%"}}>(6 Stickers)</span>
                                                </div>
                                                <div className = "sticker-buy-amount-option-cost">
                                                      £2.50
                                                </div>
                                          </div>
                                          <div className = {packSize=="large"? "sticker-buy-amount-option sticker-buy-amount-option-active":"sticker-buy-amount-option"} onClick = {() => {
                                                setPackSize("large");
                                          }}>
                                                <div className = "sticker-buy-amount-option-value">
                                                <b>Large</b> <br/><span style={{fontSize:"90%"}}>(10 Stickers)</span>
                                                </div>
                                                <div className = "sticker-buy-amount-option-cost">
                                                      £3.50
                                                </div>
                                          </div>
                                    </div>
                                    
                                    <div style={{ margin: "auto", display: "flex", flexDirection: "row", marginTop: "25px", maxWidth: "250px" }}>
                                          {auth.username != null && auth.username.length > 0 && !loading ?
                                                <div className="zenGlowButton mingGlowButton" onClick={() => requestAddStickerToBasket()}>

                                                      <React.Fragment>Add to basket</React.Fragment>

                                                </div>
                                                : loading ?
                                                      <div className="zenGlowButton mingGlowButton" onClick={() => {}}>
                                                            <PuffLoader color="#033f63" size={15}>
                                                            </PuffLoader>

                                                      </div>
                                                      : null
                                          }
                                          <div className="zenGlowButton sageGlowButton" onClick={() => setPage("delivery-details")}>
                                                Buy now
                                          </div>

                                    </div>
                                    
                                    {!(auth.username != null && auth.username.length > 0 && !loading) ?
                                          <Link to = "/signUp"><h4  style={{ margin:"auto", textAlign:"center"}}>
                                                Sign Up to create basket
                                          </h4> </Link>
                                          : null}
                                   
                              </div>
                              : page == "delivery-details" ?
                                    <DeliveryDetailsModal onComplete={(na, em, deliveryD) => createStickerOrderRequest(na, em, deliveryD)} />
                                    : page == "payment" ?
                                          <StickerPayment orderId={orderId} orderDetails={{
                                                stickerType:oderSubtype,
                                                packSize:packSize
                                          }} />
                                          : null}
                  </div>
            </React.Fragment>
      );
}
