import React, { useState } from 'react';
import ZenscapeInterface from "../interfaces/ZenscapeInterface";
import "../../../styling/common/index.scss";
import "../../../styling/zenverse/Zenverse.scss";
import ReactPlayer from 'react-player';
import { useZenverseService } from '../../../hooks/ZenverseContext';
import { ZenPopUpMessage } from '../../common/ZenPopUpMessage';
import { ZenSplashPopUp } from '../../common/ZenSplashPopUp';
import { Link } from 'react-router-dom';
import { PortalInventorySizeBoostPowers } from '../../explore/icons/CardSubtypes';
import { useAuth } from '../../../hooks/auth';

interface PracticePortalInterface extends ZenscapeInterface {
  activePortal?: string;
  controlsProvided?: boolean;
  smallImage?: boolean;
  active?: boolean;
  playing?: boolean;
}
export const ZenscapePortal = (props: PracticePortalInterface) => {
  const [previewMode, setPreviewMode] = useState(true);
  const zenverse = useZenverseService();

  let userPortalDetails = zenverse && zenverse.userPortalDetails && zenverse.userPortalDetails.length > 0? zenverse.userPortalDetails : [];


  const [isFavourite, setIsFavourite] = useState(props.details ? userPortalDetails.filter((p) => p.favourite == true).map((portal) => portal.zenscapeId).includes(props.details.id) : false);
  
  const [userHasPortal, setUserHasPortal] = useState(props.details ? userPortalDetails.map((portal) => portal.zenscapeId).includes(props.details.id) : false);

  
  const [showInventoryOutOfSpace, setShowInventoryOutOfSpace] = useState(false);
  const [showLogIn, setShowLogIn] = useState(false);

  let auth = useAuth();

  const loadZenscape = () => {
    if (auth.isAuthenticated && props.loadZenscapeJSX != null) {
      props.loadZenscapeJSX(props, props.activePortal);
    } else if (!auth.isAuthenticated) {
      setShowLogIn(true);
    } else {
      setPreviewMode(!previewMode);
    }
  }

  const claimZenscape = () => {
    if (zenverse.portalInventorySpaces > 0 && props && props.details) {
      zenverse.claimZenscape(props.details.id);
      setUserHasPortal(true);
    } else {
      setShowInventoryOutOfSpace(true);
    }
  }

  const unclaimZenscape = () => {
    if (props && props.details) {
      zenverse.unclaimZenscape(props.details.id);
      setUserHasPortal(false);
    }
  }

  const favouriteZenscape = () => {
    if (props && props.details) {
      zenverse.addPortalToFavourites(props.details.id);
      setIsFavourite(true);
    }
  }

  const unfavouriteZenscape = () => {
    if (props && props.details) {
      zenverse.removePortalFromFavourites(props.details.id);
      setIsFavourite(false);
    }
  }

  return props && props.id ? (

    <div className="zenscape-container">
      {showInventoryOutOfSpace ?
        <ZenSplashPopUp messageType="warning">
          <h2>Oh No! </h2>
          <h4>You don't have any space in your inventory to claim another portal...</h4>
          <ul>
            <Link to={"/explore/zencards/" + PortalInventorySizeBoostPowers.Power}><li><div className="zenGlowButton sageGlowButton">Increase the size of your Zen Inventory</div></li></Link>
            <li>Get a subscription so you don't get bothered again!</li>
            <li>or go to 'My portals' to remove old portals you no longer want to make space for this one!</li>
          </ul>
          <div className='zencTextButton' onClick={() => setShowInventoryOutOfSpace(false)}>
            ok
          </div>
        </ZenSplashPopUp>
        : showLogIn ?
          <ZenSplashPopUp>
            You need to log in to use ZENscapes!
            <div style = {{display:"flex",flexDirection:"row"}}>
            <div className="zenGlowButton navajoGlowButton" onClick={() => setShowLogIn(false)}>back</div>
            <Link to="/login">
              <div className="zenGlowButton navajoGlowButton" >login</div>
            </Link>
            </div>
          </ZenSplashPopUp>
          // : props.active && props.video ?

          //   <div className="zenscape-yt-video" style={props.controlsProvided ? { marginTop: "50px" } : {}}>
          //     <ReactPlayer playing={true} loop={true} url={props.video} width='100%' />
          //   </div>
            : props.active ?
              <div className="zenscape-jsx" style={props.controlsProvided ? { marginTop: "45px" } : {}}>
                {/* {!props.controlsProvided? 
            <div className="stopPortalCross" onClick={() => setPreviewMode(true)}></div>:
            <div></div>
            } */}
                {props.jsx}
              </div>
              : (props.activePortal == "zenscape-preview") || (props.activePortal == null && previewMode) ?
                <div className="zenscape-preview" >
                  <h1 className="zenscape-preview-title okashi">{props && props.details ? props.details.title : null}</h1>
                  {props.smallImage ?
                    <img className="portal-preview-image portal-preview-image-small" src={props && props.details && props.details.image ? props.details.image : null} onClick={() => loadZenscape()}></img>

                    : <img className="portal-preview-image" src={props && props.details && props.details.image ? props.details.image : null} onClick={() => loadZenscape()}></img>
                  }

                  <div className="zenscape-preview-overview">
                    <h2 className="zenscape-preview-desc zenK">{props && props.details ? props.details.description.trim() : null}</h2>
                    <h3 className="zenscape-preview-desc zenK">{props && props.additionalText ? props.additionalText : null}</h3>
                  </div>
                  <div className="zenscape-preview-controls">

                    {userHasPortal ?
                      <div className="zenscapeControlIcon removeZenscapeIcon" onClick={() => unclaimZenscape()}></div>
                      :
                      <div className="zenscapeControlIcon addZenscapeIcon" onClick={() => claimZenscape()}></div>

                    }
                    {isFavourite ?
                      <div className="zenscapeControlIcon favouritedScapeIcon" onClick={() => unfavouriteZenscape()}></div>
                      :
                      <div className="zenscapeControlIcon favouriteScapeIcon" onClick={() => favouriteZenscape()}></div>

                    }
                    {/* <div className="zenscapeControlIcon ZenscapeStartMobile"></div> */}

                  </div>
                </div>
                : null
      }
    </div>
  ) : (<React.Fragment>
    <h2 className="centered-text"> Find a Portal to brighten your practice ... </h2>
  </React.Fragment>);
}