import React from "react";
import ZenContentInterface from "../components/zencontent/practices/ZenContentInterface";
import { PracticeFullyFocusedPreview } from "../components/zencontent/practices/previews/PracticeFullyFocusedPreview";
import { PracticeOverview } from "../components/zencontent/practices/previews/PracticeOverview";
import { PracticePortalPreview } from "../components/zencontent/practices/previews/PracticePortalPreview";
import { PracticePreview } from "../components/zencontent/practices/previews/PracticePreview";
import { InfomaticStreamItem } from "../components/zencontent/infomatics/InfomaticStreamItem";
import { ZenNugget } from "../components/zencontent/nuggets/ZenNugget";
import { PathOverview } from "../components/zencontent/courses/PathOverview";
import { Adsense } from "@ctrl/react-adsense";
import { ContentTypes } from "../components/zencontent/common/ContentTypes";
import { Link } from "react-router-dom";
import { ZEN_C_ENDPOINT } from "../config/EnvironmentalVariables";
import axios from "axios";
import { RetreatStreamAd } from "../components/shop/Retreats/RetreatStreamAd";

const getGooglestreamAd = () => {
  //Ad GOOGLE AD: (AdSense)
  let ad = <Adsense
    client="ca-pub-3377323199127790"
    slot="3158038429"
    format="fluid"
    layoutKey="-e7+5w+4e-db+86"
    style={{ width: "100%", height: 300, display: "block" }}
  />

  return ad;
}

const getShopMainAd = () => {
  return <div className="zen-stream-ad zen-stream-ad-bsc shopMainAd">
    <div className="zen-stream-ad-text">
      <Link to="/explore/shop">
        <div className="zen-portal-ad-photo-holder">
          <img src={require("../static/photos/exploreIcons/zenshopwhite.png")} className="zen-portal-ad-photo"></img>
        </div>
        <h2>Why not check out the Zen Shop?!</h2>
        <h4>Offering T-Shirts, Zen Retreats, Stickers & More</h4>
      </Link>
    </div>

  </div>;
}

const getShopTShirtPortalAd = () => {
  return <div className="" style={{}}>
    <div className="t-shirt-shop-holder" style={{ display: "flex", flexDirection: "row", width: "350px", margin: "auto" }}>
      <div className="t-shirt-pic-portrait"></div>
      <div style={{ margin: "auto" }}>
        <div className="t-shirt-pic-sq"></div>
        <div className="eco-friendly-pledge">
          <div className="eco-friendly-pic"></div>
          <div className="eco-friendly-pledge-text">
            <h3>Read Our</h3>
            <h1>Eco-Friendly</h1>
            <h3>Promise</h3>
          </div>
        </div>
      </div>
    </div>
    <h3 className="centered-text" style={{ marginTop: "15px" }}>All colours and sizes <span className="toms-blue-text">£19.99</span></h3>
    <Link to={"/explore/shop/tshirt/buy"}>
      <div className="buy-now-button zenGlowButton navajoGlowButton">buy now!</div>
    </Link>
  </div>;
}

const getShopTShirtStreamAd = () => {
  return <div className="zen-stream-ad zen-stream-ad-bsc shopMainAd">
    <div className="zen-stream-ad-text">
      <Link to="/shop/tshirts">
        <h2>Check out our T-Shirts!</h2>
        <h4>Now available in 3 colours</h4>
      </Link>
    </div>
    <div className="zen-stream-ad-photo-holder shadow-photo-holder">
      <Link to="/shop/tshirts">
        <img src={require("../static/photos/shop/t-shirt-square.png")} className="zen-stream-ad-photo  shadow-photo"></img>
      </Link>
    </div>
  </div>;
}

const getMindfulStickersAd = () => {
  return <div className="zen-stream-ad zen-stream-ad-bsc shopMainAd">
    <div className="zen-stream-ad-text">
      <Link to="/shop/stickers">
        <h3>Our ZEN stickers are perfect to liven up a range of surfaces, and are the perfect reminder to stay present & mindful!</h3>

      </Link>
    </div>
    <div className="zen-stream-ad-photo-holder shadow-photo-holder">
      <Link to="/shop/stickers">
        <img src={require("../static/photos/shop/stickers.png")} className="zen-stream-ad-photo  shadow-photo"></img>
      </Link>
    </div>
  </div>;
}

const getZenShopPortalAd = () => {
  var adTypeChoice = Math.floor(Math.random() * 3);

  if (adTypeChoice < 1) {
    return getShopTShirtPortalAd();
  } else if (adTypeChoice < 2) {
    return getMindfulStickersAd();
  } else {
    return getShopMainAd();
  }
}



const getRetreatMainAd = () => {
  return <div className="zen-stream-ad zen-stream-ad-bsc shopMainAd">
    <div className="zen-stream-ad-text">
      <Link to="/explore/shop/retreats">
        <h2>Feeling the need to take a break? </h2>
        <h4>Check out our Zencyclopedia approved retreats!</h4>
      </Link>
    </div>
    <div className="zen-stream-ad-photo-holder shadow-photo-holder">
      <Link to="/explore/shop/retreats">
        <img src={require("../static/photos/shop/nature-retreat.png")} className="zen-stream-ad-photo  shadow-photo"></img>
      </Link>
    </div>
  </div>;
}


const getRetreatMainPortalAd = () => {
  return <div className="zen-stream-ad" style={{ display: "block" }}>
    <div className="zen-stream-ad-text">
      <Link to="/explore/shop/retreats">
        <h2>Feeling the need to take a break? </h2>
        <img src={require("../static/photos/shop/BuddhaRelaxing.png")} className="zen-stream-ad-photo  shadow-photo"></img>
        <h4>Check out our Zencyclopedia approved retreats!</h4>
      </Link>
    </div>
  </div>;
}


const getRandomRetreatAd = async () => {

  let retreat = await axios.get(ZEN_C_ENDPOINT + `/zen/retreats/tag/international`)
    .then(res => {
      if (res.data && res.data.length > 0) {
        return res.data[Math.floor(Math.random() * res.data.length)]
      };
    }).catch(() => {
      return null;
    });

  if (retreat != null) {
    console.log("serving retreat: ");
    return <RetreatStreamAd {...retreat} />
  } else {
    return getRetreatMainAd();
  }

}

const getRetreatPortalAd = () => {
  var adTypeChoice = Math.floor(Math.random() * 3);

  if (adTypeChoice < 1) {
    return getRetreatMainPortalAd();
  } else {
    return getRandomRetreatAd();
  }

}

const getRetreatAd = () => {
  var adTypeChoice = Math.floor(Math.random() * 3);

  if (adTypeChoice < 1) {
    return getRetreatMainAd();
  } else {
    return getRandomRetreatAd();
  }

}

const getPleaseDonate = () => {
  return <div className="zen-stream-ad zen-stream-ad-to shopMainAd">
    <h3>Do you believe in our mission of providing accessible midnfulness & mental health resources for all? <br></br>
      Donate to our creator fund! 100% of donations will go to providing more free content within Zencyclopedia </h3>
    <Link to="/donate">
      <div className="zenGlowButton mingGlowButton">Find Out More</div>
    </Link>
  </div>;
}

const getZenPowerCardAd = () => {
  var adTypeChoice = Math.floor(Math.random() * 2);

  if (adTypeChoice < 1) {
    return <div className="zen-stream-ad zen-stream-ad-bsc shopMainAd">
      <div style={{ margin: "auto" }}>
        <h2 style={{ margin: "auto" }}>Looking for more portals or things to do? </h2>
        <h4 style={{ margin: "auto" }}>Why not increase your inventory size?</h4>
        <Link to="/explore/cards/powers">
          <div className="zenGlowButton mingGlowButton">Find Out More</div>
        </Link>
      </div>
    </div>;
  } else {

    return <div className="zen-stream-ad zen-stream-ad-bsc shopMainAd">
      <div style={{ margin: "auto" }}>
        <h2 style={{ margin: "auto" }}>Looking for more time using our portals? </h2>
        <h4 style={{ margin: "auto" }}>Why not increase your meditation time allowance or even make it unlimited? </h4>
        <Link to="/explore/cards/powers">
          <div className="zenGlowButton mingGlowButton">Find Out More</div>
        </Link>
      </div>
    </div>;
  }

}

const getSubscriptionAd = (userSubLevel?: string) => {
  if (userSubLevel == "BASIC") {
    return <div className="zen-stream-ad zen-stream-ad-bsc shopMainAd">
      <div style={{ margin: "auto" }}>
        <h2 style={{ margin: "auto" }}>Time to take your practice to the next level?</h2>
        <h4 style={{ margin: "auto" }}>Unlock more features with the ZENcyclopedia Premium subscription</h4>
        <Link to="//explore/subscriptions">
          <div className="zenGlowButton mingGlowButton">Find Out More</div>
        </Link>
      </div>
    </div>;
  } else {
    return <div className="zen-stream-ad zen-stream-ad-bsc shopMainAd">
      <div style={{ margin: "auto" }}>
        <h2 style={{ margin: "auto", textAlign: "center" }}>Why not subscribe and unlock more Zen goodness?!</h2>
        <h4 style={{ margin: "auto", textAlign: "center" }}>We have plans starting from as little as 99p!</h4>
        <Link to="//explore/subscriptions" style={{ margin: "auto" }}>
          <div className="zenGlowButton mingGlowButton" style={{ margin: "auto", marginTop: "20px" }}>Find Out More</div>
        </Link>
      </div>
    </div>;
  }
}

const getCumilativeWeight = (weightedList: any, topIndex: number) => {
  var totalWeight = 0;
  for (var i = 0; i <= topIndex; i++) {
    totalWeight = totalWeight + weightedList[i].weight;
  }

  return totalWeight;
}

export const generatePortalAdItem = (userSubLevel?: string) => {

  // alternates between google ad and internal ad.
  const googleAdsEnabled = false;

  let userIsSubscribed = userSubLevel != null && (userSubLevel == "BASIC" || userSubLevel == "PREMIUM");

  const addTypeChoices = [{ name: "SHOP", weight: 5 }, { name: "RETREAT", weight: 4 }, { name: "POWER", weight: 1 }, { name: "DONATE", weight: 1 }]; //, { name: "ZENERGY", weight: 1 }];

  if (!userIsSubscribed) {
    addTypeChoices.push({ name: "SUBSCRIBE", weight: 2 });
  }
  if (googleAdsEnabled) {
    addTypeChoices.push({ name: "GOOGLE", weight: 6 });
  }

  var adTypeChoice = Math.floor(Math.random() * getCumilativeWeight(addTypeChoices, addTypeChoices.length - 1));

  if (adTypeChoice < getCumilativeWeight(addTypeChoices, 0)) {
    return getZenShopPortalAd();
  } else if (adTypeChoice < getCumilativeWeight(addTypeChoices, 1)) {
    return getRetreatPortalAd();
  } else if (adTypeChoice < getCumilativeWeight(addTypeChoices, 2)) {
    return getZenPowerCardAd();

  } else if (adTypeChoice < getCumilativeWeight(addTypeChoices, 3)) {
    return getPleaseDonate();
    // } else if (adTypeChoice < getCumilativeWeight(addTypeChoices, 5)) {
    //   return getZenShopAd();
  } else if (!userIsSubscribed && adTypeChoice < getCumilativeWeight(addTypeChoices, 4)) {
    return getSubscriptionAd(userSubLevel);
  } else if (!userIsSubscribed && googleAdsEnabled && adTypeChoice < getCumilativeWeight(addTypeChoices, 5)) {
    return getGooglestreamAd();
  } else if (userIsSubscribed && googleAdsEnabled && adTypeChoice < getCumilativeWeight(addTypeChoices, 4)) {
    return getGooglestreamAd();
  } else {
    console.log("failed to return an ad.");
    return null;
  }
}



export const getPracticeListItem = (practice: ZenContentInterface) => {
  return <PracticePreview {...practice} />
}


