

import * as React from "react";
import '../../styling/common/index.scss';
import '../../styling/common/zenCoreUIComponents.scss';
import { useState } from "react";
import axios from "axios";
import { useAuth } from "../../hooks/auth";
import { ZEN_C_ENDPOINT } from "../../config/EnvironmentalVariables";


export const ZenSplashPopUp = (props : any) => {
  let className = props.messageType == "warning"? "zen-splash-pop-up warning-pop-up" : "zen-splash-pop-up";
    return ( 
      <div className={className}>
        {props.children}
      </div>
    );

}