import * as React from "react";
import "../../styling/common/index.scss";
import "../../styling/common/zenCoreUIComponents.scss";
import "../../styling/myzen/myzen.scss";
import { ZenNavBar } from "../common/navbar/ZenNavBar";
import { useState } from "react";
import { useZenExploreService } from "../../hooks/ZenExploreContext";
import axios from "axios";
import { ZEN_C_ENDPOINT } from "../../config/EnvironmentalVariables";
import { useAuth } from "../../hooks/auth";
import { PathPreview } from "../zencontent/courses/PathPreview";
import { useNavigate, useParams } from "react-router";
import { PuffLoader } from "react-spinners";
import { getContentCatagories } from "../../service/ZencyclopaediaService";
import { ZenPopUpMessage } from "../common/ZenPopUpMessage";
import { ZenSearchFilterTagsDisplay } from "./search/ZenSearchFilterTagsDisplay";
import { ZenSearchBar } from "./search/ZenSearchBar";
import { useZenSearchService } from "../../hooks/ZenSearchContext";
import { CourseCarousel } from "./CourseCarousel";

export const CourseBrowser = () => {


  const { searchId } = useParams();
  const [hideBackArrow, setHideBackArrow] = useState(false);
  const [searchKey, setSearchKey] = useState(searchId);
  const [searchPending, setSearchPending] = useState(false);
  const [retrievePending, setRetrievePending] = useState(false);
  const [results, setResults] = useState(null);
  const [resultsType, setResultsType] = useState(null);
  const [userCourses, setUserCourses] = useState(null);
  const [completedCourses, setCompletedCourses] = useState(null);
  const [newCourses, setNewCourses] = useState(null);
  const [focusedItem, setFocusedItem] = useState("Active");
  const [page, setPage] = useState("Explore");

  let auth = useAuth();

  const useSearch = useZenSearchService();
  let navigate = useNavigate();

  const generateCourseCarousel = () => {
      return <CourseCarousel></CourseCarousel>;
  }

  const retreiveResults = async () => {
    let result = await useSearch.filterCoursesByTag(searchId,[]);
    setResults(result.map(p => <PathPreview {...p}/>));
    setResultsType(searchId);
    setRetrievePending(false);
  }

  if (searchId && resultsType != searchId && useSearch.searchType != searchId + "-FAIL" && !useSearch.searchPending && !retrievePending && searchId.toLowerCase() != "courses") {
      setRetrievePending(true);
      retreiveResults();
  } 

  else if (searchId == null && resultsType != "courses"&& !searchPending && !retrievePending){
      console.log("Loadig Courses Menu.");
      setResultsType("courses");
      setResults(generateCourseCarousel());
  }
  else {
    console.log("nothing to see there: "+searchId);
  }

      
      const updateSearch = async (searchText: string, tags: string []) => {
            setRetrievePending(true);
            let result = await useSearch.filterCoursesByTag(searchText, tags);
            setResults(result.map(p => <PathPreview {...p}/>));
            setRetrievePending(false);
      }


      const getUserCourses = () => {
            setSearchPending(true);
            //Get User Path Ids From My Zen Service.
            axios.get(ZEN_C_ENDPOINT + `/myzen/myZenContent/getUserPaths/` + auth.username)
                  .then(res => {
                        if (res.data) {
                              setUserCourses(res.data);
                              setSearchPending(false);
                        } else {
                              setUserCourses([]);
                              setSearchPending(false);
                        }
                  }).catch(() => {
                        setUserCourses([]);
                        setSearchPending(false);
                  })
      }

      const getCourseCompletion = (courseId: any) => {
            return axios.get(ZEN_C_ENDPOINT + `/myzen/myZenContent/pathCompletion/` + auth.username + `/` + courseId)
                  .then(res => {
                        return res.data;
                  }).catch(() => {
                        return -1;
                  })
      }

      const calculateUserCourseCompletion = async () => {
            setSearchPending(true);
            let completedCourses = [];
            let newCourses = [];

            for (var i = 0; i < userCourses.length; i++) {
                  let course = userCourses[i];
                  let completionRate = await getCourseCompletion(course);
                  if (!(completionRate && completionRate.lessonsCompleted > 0)) {
                        newCourses.push(course.id);
                  } else if (completionRate && completionRate.lessonsCompleted == completionRate.totalLessons) {
                        completedCourses.push(course.id);
                  }
            }
            setCompletedCourses(completedCourses);
            setNewCourses(newCourses);
            setSearchPending(false);
      }


      if (userCourses == null && !searchPending) {
            getUserCourses();
      }

      if (userCourses != null && userCourses.length > 0 && !searchPending && completedCourses == null && completedCourses == null) {
            calculateUserCourseCompletion();
      }

      return (
            <React.Fragment>
                  <ZenNavBar activeItem="MyZen" primaryColor="#D1603D"></ZenNavBar>
                  <div className="my-courses-page">

                        <div style={{ display: "flex", flexDirection: "row" }}>
                              {["My Courses", "Explore"].map(option =>
                                    <h1 className={page == option ? 'myZenPlannerHeader subPageActiveNavHeader' : 'myZenPlannerHeader'} onClick={() => setPage(option)}>{option}</h1>
                              )}
                        </div>


                        {searchId && !hideBackArrow || (searchKey && searchKey.length > 2) ? <div className='explore-back-icon' onClick={() => navigate(-1)}></div> : null}

                        {page == "My Courses" ?
                              <>
                                    <div style={{ display: "flex", flexDirection: "row", margin:"auto", marginTop: "15px", marginBottom: "25px" }}>
                                          <div className='zencyclopaedia-contents-item-holder' onClick={() => setFocusedItem('Active')}>
                                                <div className={'zencyclopaedia-contents-text-item ZenCGradient1'}>
                                                      <h1 className="zencyc-contents-text-item-header zenK"><b>Active</b></h1>
                                                </div>
                                          </div>
                                          <div className='zencyclopaedia-contents-item-holder'>
                                                <div className={'zencyclopaedia-contents-text-item ZenCGradient2'}>
                                                      <h1 className="zencyc-contents-text-item-header zenK" onClick={() => setFocusedItem('New')}><b>New</b></h1>
                                                </div>
                                          </div>
                                          <div className='zencyclopaedia-contents-item-holder'>
                                                <div className={'zencyclopaedia-contents-text-item ZenCGradient3'}>
                                                      <h1 className="zencyc-contents-text-item-header zenK" onClick={() => setFocusedItem('Completed')}><b>Completed</b></h1>
                                                </div>
                                          </div>
                                    </div>
                                    <div>
                                          {userCourses && userCourses.length > 0 && focusedItem == "Active" && completedCourses ? userCourses.filter((c) => !completedCourses.includes(c)).map((p) => <PathPreview id={p} />)
                                                : userCourses && userCourses.length > 0 && focusedItem == "Active" ? userCourses.map((p) => <PathPreview id={p} />)
                                                      : userCourses && userCourses.length > 0 && focusedItem == "New" ? newCourses.map((p) => <PathPreview id={p} />)
                                                            : userCourses && userCourses.length > 0 && focusedItem == "Completed" ? completedCourses.map((p) => <PathPreview id={p} />)
                                                                  : <ZenPopUpMessage>
                                                                        <div>
                                                                              You don't have any courses! 
                                                                              
                                                                              Head over to the explore section to find some!
                                                                        </div>
                                                                  </ZenPopUpMessage>}
                                    </div></>
                              : <>
                                    <ZenSearchBar 
                                    handleSearchBarActive = {() => setHideBackArrow(true)} 
                                    updateSearch = {(search,tags) => updateSearch(search,tags)}
                                    searchKey={searchId}
                                    />
                                    <div className="zencyclopaedia-results">
                                          {retrievePending?
                                                <div className="zencLoader">
                                                      <PuffLoader color="#033f63" size={300}>
                                                      </PuffLoader>
                                                </div>
                                                : results?
                                                      <>
                                             
                                                            {results}
                                                      </>
                                                      :
                                                      <div className="zencLoader">
                                                            <h1>NO RESULTS</h1>
                                                      </div>
                                          }
                                    </div></>
                        }
                  </div>
            </React.Fragment>
      );
}
