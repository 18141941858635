import * as React from "react";
import '../../styling/common/index.scss';
import { ZenNavBar } from "../common/navbar/ZenNavBar";
import { ZencTitle } from "./ZencTitle";
import MetaTags from 'react-meta-tags';
import { Link, useParams } from "react-router-dom";
import { AboutUs } from "./AboutUs";
import { OurRoots } from "./OurRoots";

export const AboutSection = (props: any) => {
  
  let { startPage } = useParams();

  const [page, setPage] = React.useState(startPage == "ourcompany"? "Company": "Product");

  return (
    <div className="ZenBasicPage">
      <MetaTags>
        <meta id="meta-description" name="description" content={"Learn all about what our platform has to offer, the people behind it and our ambitous mission to empower people to take control of their mental health by offering free mindfulness resources."} />
        <meta id="og-title" property="og:title" content="About Us - Zencyclopedia" />
      </MetaTags>
      <div className={"ZenNavBar"} >
        <h3 style = {{margin:"auto"}}><Link to = "/home">Home</Link></h3>
        <h3 style = {{margin:"auto"}} onClick ={() => setPage("Product")}>Our Product</h3>
        <h3 style = {{margin:"auto"}} onClick ={() => setPage("Company")}>Our Company</h3>
      </div>
      {page=="Product"?
      <AboutUs/>
      :page=="Company"?
      <OurRoots/>
      :null}
    </div>
  );
}
