import * as React from "react";
import "../../../styling/common/index.scss";
import "../../../styling/explore/explore.scss";
import { useState } from "react";
import axios from "axios";
import { useAuth } from "../../../hooks/auth";
import { ZEN_C_ENDPOINT } from "../../../config/EnvironmentalVariables";
import { MeditationIcons } from "../../zencontent/practices/MeditationIcons";
import { BarLoader } from "react-spinners";
import { useZenSearchService } from "../../../hooks/ZenSearchContext";
import { getContentListPreviews } from "../../../service/ContentService";
import ZenContentInterface from "../../zencontent/practices/ZenContentInterface";
import { PracticePreview } from "../../zencontent/practices/previews/PracticePreview";
import { ZenNugget } from "../../zencontent/nuggets/ZenNugget";
import { PathPreview } from "../../zencontent/courses/PathPreview";
import { ZenLoader } from "../../common/ZenLoader";
import BrowseLocalFilesButton from "../studio/BrowseLocalFilesButton";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from '@fortawesome/free-solid-svg-icons';
import { faVideo } from '@fortawesome/free-solid-svg-icons';
import { faPodcast } from '@fortawesome/free-solid-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

export const ContentReccomendationCreationModal = (props: any) => {

      const useSearch = useZenSearchService();
      const [activeContentReccs, setActiveContent] = useState(null);
      const [pageNumber, setPageNumber] = useState(1);
      const [activePage, setActivePage] = useState("external-recc");
      const [sessionToConfirm, setSessionToConfirm] = useState(null);
      const [title, setTitle] = useState(null);
      const [description, setDescription] = useState(null);
      const [type, setType] = useState(null);
      const [link, setLink] = useState(null);
      // const [imageFile, setImageFile] = useState<File | null>(null);
      // const [previewURL, setPreviewURL] = useState<string | null>(null);

      const generateChooseItemPreview = (item: ZenContentInterface) => {
            if (item.contentType == 'MEDITATION') {
                  return <PracticePreview {...item} unclickable={true} selectedItem={sessionToConfirm && sessionToConfirm.id == item.id} />;
            }
      }

      const options = [{
            label: <div className = "recc-type-choice">Book <FontAwesomeIcon icon={faBook}  /></div>,
            value: 'Book'},{
                  label: <div className = "recc-type-choice">Video <FontAwesomeIcon icon={faVideo}  /></div>,
                  value: 'Video'},{
                        label: <div className = "recc-type-choice">Podcast <FontAwesomeIcon icon={faPodcast}  /></div>,
                        value: 'Podcast'},{
                              label: <div className = "recc-type-choice">Website <FontAwesomeIcon icon={faGlobe}  /></div>,
                              value: 'Website'}
          ];
          
      return (
            <div style = {{overflowY:"scroll", maxHeight:"70vh"}}>
                  {activePage == "external-recc" ?
                        <div style = {{paddingBottom:"100px"}}>
                              <div className = "desktopFlex">
                              <div className = "dfWE">
                              <h3 style={{ margin: "auto", textAlign: "center", marginBottom: "15px" }}>Create a new content reccomendation:</h3>
                              <input
                                    type="text"
                                    placeholder={"Enter the title of your reccomendation"}
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    className={'readingListInput'}
                              />
                              <Dropdown className = "reccomendation-type-dropdown" options={options} onChange={(e) => setType(e.value)} value={type} placeholder="Resource Type" />

                              <input
                                    type="text"
                                    placeholder={"Add Link"}
                                    value={link}
                                    onChange={(e) => setLink(e.target.value)}
                                    className={'readingListInput'}
                              />
                              </div>
                              {/* {previewURL ?
                                    <img className="content-preview-image" src={previewURL} ></img>
                                    :
                                    <div style = {{marginTop:"10px"}}>
                                    <BrowseLocalFilesButton accept="image/jpeg, image/png" onFileSelect={handleFileSelect} title = {"Add Image (optional)"}/>
                                    </div>} */}
                              <textarea
                                    placeholder="Write a brief description or review of the content"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    className={'nugget-text-input'}
                              />
                              </div>

                              <div className = "zenGlowButton sageGlowButton" 
                              style = {{marginTop:"10px"}}
                              onClick = {() => { 
                                    props.addContentRecc({
                                          title:title,
                                          description:description,
                                          type:type,
                                          link:link
                                    });
                              }}>Add Recommendation</div> 
                              
                        </div>
                        : null}
            </div>
      )
}