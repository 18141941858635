
import * as React from "react";
import "../../../styling/explore/explore.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ZenNavBar } from "../../common/navbar/ZenNavBar";
import { MyZenNavBar } from "../../common/navbar/MyZenNavBar";
import { useZenCardsService } from "../../../hooks/ZenCardsContext";
import { useMyZenService } from "../../../hooks/ZenUserContext";
import { useAuth } from "../../../hooks/auth";
import { useMyCommunityService } from "../../../hooks/MyCommunityContext";
import { PractitionerPreview } from "./PractitionerPreview";
import { ZenLoader } from "../../common/ZenLoader";
import { PractitionerSliderPreview } from "./PractitionerSliderPreview";
import Slider from "react-slick";

export const ZenCommunityReccomendationsBrowser = () => {

    const sliderRef: any = React.createRef();
    const desktopSliderRef: any = React.createRef();

    const [practitionerReccomendations, setPractitionerReccomendations] = React.useState(null);

    const produceReccomendationGroupTitle = (recType:string, recSubType?:string) => {
        return recType;
    }

    const createPractitioenrReccomendationSlider = (practitionerReccomendationGroup:any) => {

        let sliderItems = practitionerReccomendationGroup!= null && practitionerReccomendationGroup.practitionerReccomendations != null?
        practitionerReccomendationGroup.practitionerReccomendations.filter(p => p.bio != null && p.bio.length>0).map(pr => <PractitionerSliderPreview practitioner={pr}/>)
        :null;
        return <div>
            <h1>{produceReccomendationGroupTitle(practitionerReccomendationGroup.reccomendationType)}</h1>
            <div className="zen-mobile">
                        <Slider
                              centerMode={true}
                              centerPadding='15%'
                              autoplay={true}
                              ref={sliderRef}
                              dots={true}
                              infinite={true}
                        >
                              {sliderItems}
                        </Slider>
                  </div>
                  <div className="zen-desktop">
                        <Slider
                              centerMode={true} 
                              centerPadding='35%'
                              autoplay={true}
                              ref={desktopSliderRef}
                              dots={true}
                              infinite={true}
                        >
                        {sliderItems}
                        </Slider>
                  </div>
            
        </div>;
    }
    

    const producePractitionerEReccomendationsJSX = (practitionerReccomendations:any) => {
        if(practitionerReccomendations!=null){
            let primaryReccs = practitionerReccomendations.primaryReccomendation;
            // let secondaryReccs = practitionerReccomendations.primaryReccomendation;
            // let additionalReccs = practitionerReccomendations.primaryReccomendation;

            //Generate primary reccomendations Slide: 
            let primaryRecSlider = createPractitioenrReccomendationSlider(primaryReccs);

            let reccomendationJSX = <div> 
                {primaryRecSlider}
            </div>

            return reccomendationJSX;
        } else return null;

    }
    
    const communityService = useMyCommunityService();

    const loadPractitionerRecs = async () => {
        let reccomendations = await communityService.getPractitionerReccomendations();
        let practitionerRecsJSX = producePractitionerEReccomendationsJSX(reccomendations);
        setPractitionerReccomendations(practitionerRecsJSX);
    }

    React.useEffect(() => {
        loadPractitionerRecs();
    },[]);

   

    return (
        <div>
            {practitionerReccomendations!= null?
            <React.Fragment>
            {practitionerReccomendations}
            </React.Fragment>
            :<ZenLoader/>
            }
        </div >
    );

}

