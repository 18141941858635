import * as React from "react";
import '../../../styling/common/index.scss';
import { useState } from "react";
import { useAuth } from "../../../hooks/auth";
import axios from "axios";
import { ZEN_C_ENDPOINT } from "../../../config/EnvironmentalVariables";
import { useMyZenService } from "../../../hooks/ZenUserContext";
import { PuffLoader } from "react-spinners";
import { useMyCommunityService } from "../../../hooks/MyCommunityContext";
import { ZenLoader } from "../../common/ZenLoader";
import { PractitionerPreview } from "../../explore/communities/PractitionerPreview";


export const ChooseAccountsToFollow = (props: any) => {

  const auth = useAuth();

  const [practitionerReccomendations, setPractitionerReccomendations] = React.useState(null);


    const createPractitionerReccomendationList = (practitionerReccomendationGroup:any) => {

        let sliderItems = practitionerReccomendationGroup!= null && practitionerReccomendationGroup.practitionerReccomendations != null?
        practitionerReccomendationGroup.practitionerReccomendations.filter(p => p.bio != null && p.bio.length>0).map(pr => <PractitionerPreview practitioner={pr}/>)
        :null;
        return <div>
           {sliderItems}
            
        </div>;
    }
  

    const producePractitionerEReccomendationsJSX = (practitionerReccomendations:any) => {
        if(practitionerReccomendations!=null){
            let primaryReccs = practitionerReccomendations.primaryReccomendation;
            // let secondaryReccs = practitionerReccomendations.primaryReccomendation;
            // let additionalReccs = practitionerReccomendations.primaryReccomendation;

            //Generate primary reccomendations Slide: 
            let primaryRecList = createPractitionerReccomendationList(primaryReccs);

            let reccomendationJSX = <div> 
                {primaryRecList}
            </div>

            return reccomendationJSX;
        } else return null;

    }
    
    const communityService = useMyCommunityService();

    const loadPractitionerRecs = async () => {
        let reccomendations = await communityService.getPractitionerReccomendations();
        console.log("Initial Practitioenr Recs: ");
        console.log(reccomendations);
        let practitionerRecsJSX = producePractitionerEReccomendationsJSX(reccomendations);
        setPractitionerReccomendations(practitionerRecsJSX);
    }

    React.useEffect(() => {
        console.log("Loading practitioner reccomendations.");
        loadPractitionerRecs();
    },[]);

   

    return (
        <div>
            <h3>Choose some Practitioner accounts to follow:</h3>
            <div className = "practitionerRecListHolder">
            {practitionerReccomendations!= null?
            <div style = {{paddingBottom:"100px"}}>
            {practitionerReccomendations}
            </div>
            :<ZenLoader/>
            }
            </div>
            <div className ="bottomNextButtonHolder">
            <div className = "bottomNextButton" onClick ={() => props.next()}>Next</div>
            </div>
        </div >
    );

}