import * as React from "react";
import "../../../styling/common/index.scss";
import "../../../styling/myzen/myzen.scss";
import { useState } from "react";
import axios from "axios";
import { ZEN_C_ENDPOINT } from "../../../config/EnvironmentalVariables";
import ZenContentInterface from "../../zencontent/practices/ZenContentInterface";
import { parseZenAPIDateToString } from "../../../utils/commonUtils";
import { Link } from "react-router-dom";

export const JournalEntryPreview = (props: any) => {

        const [showComments, setShowComments] = useState(false);
        const [linkedPractice, setLinkedPractice] = useState(null);

        const mood = props.mood && props.mood.emojis ? ("" + props.mood.emojis[0]).toLowerCase() : null;
        const practiceId = props.practiceLog && props.practiceLog.practiceId > 0 ? props.practiceLog.practiceId : null;

        if (practiceId && linkedPractice == null) {
                getMeditation(practiceId);
        }

        // just get content not whole thng
        function getMeditation(id: any): any {
                axios.get(ZEN_C_ENDPOINT + `/zen/meditations/` + id)
                        .then(res => {
                                const practiceData: ZenContentInterface = res.data.practiceDetails;
                                setLinkedPractice(practiceData);
                        })
        }

        const entryDate = props && props.date ? parseZenAPIDateToString(props.date as string) : null;

        var practiceSummaryLink =  "/meditation/" + practiceId;

        return (

                <div className="journal-entry-preview">
                        {!showComments ?

                                <>
                                        {linkedPractice ?
                                                <div>
                                                        <h2 className="journal-title-shared">{props.title}</h2>
                                                        <h3 className="journal-title-shared journal-practice-title">{linkedPractice.title}</h3>
                                                </div>
                                                :
                                                <h1>{props.title}</h1>
                                        }
                                        {entryDate ?
                                                <h2 className="journal-date">
                                                        {entryDate.day} {entryDate.month}
                                                </h2>
                                                :
                                                <></>
                                        }

                                        {/* practice image */}
                                        {/* practice liked? */}
                                        {/* practice title? */}
                                        <div style={{ width: "90%", margin: "auto", display: "flex", flexDirection: "row", marginTop: "5px" }}>

                                                {linkedPractice && linkedPractice.image != null ?
                                                <Link to = {practiceSummaryLink}>
                                                <img className="MedPhotoIcon journalMedPhoto" src={linkedPractice.image} ></img> </Link>: null
                                                }

                                                {props.practiceLog && props.practiceLog.enjoyed != null  && props.practiceLog.enjoyed != undefined?
                                                        <img className="journal-thumbs-rating" src={props.practiceLog.enjoyed ? require('../../../static/photos/languageIcons/thumbs_up.png') : require('../../../static/photos/languageIcons/thumbs_down.png')}></img>
                                                        :props.content?
                                                        <div className="view-comments-button" onClick={() => setShowComments(true)}>
                                                                View Comments
                                                        </div>
                                                        :null
                                                }

                                                {mood ?
                                                        <img className='mood-preview-image' src={require('../../../static/photos/languageIcons/' + mood + '.png')}></img>
                                                        :
                                                        <></>
                                                }

                                        </div>{props.practiceLog && props.practiceLog.enjoyed != null && props.content?
                                                <div className="view-comments-button" onClick={() => setShowComments(true)}>
                                                        View Comments
                                                </div>
                                                :
                                                <></>
                                        }
                                </>
                                :
                                <div>
                                        <h1>{props.title}</h1>
                                        <h4 className="journal-entry-content">{props.content}</h4>
                                        <div className="view-comments-button" onClick={() => setShowComments(false)}>
                                                Hide Comments
                                        </div>
                                </div>
                        }
                </div>
        );
}
