import * as React from "react";
import "../../../styling/zenverse/Zenverse.scss";
import "../../../styling/common/zenCoreUIComponents.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // req
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { TShirtPaymentCheckout } from "./TShirtPaymentCheckout";
import { ZenShopPaymentCheckout } from "./ZenShopPaymentCheckout";

export const ZenShopOrderPayment = (props: any) => {


  const [stripePromise, setStripePromise] = React.useState(null);
  const [stripeError, setStripeError] = React.useState("NONE");
  const [clientSecret, setClientSecret] = React.useState("");

  let zenStripeAPI = "https://www.zencyclopedia.co.uk/myzen/zenPayments";

  React.useEffect(() => {
    console.log("Getting Stripe Config.");
    axios.get(zenStripeAPI + `/stripeConfig`)
      .then(res => {
        console.log("PK "+res.data);
        setStripePromise(loadStripe(res.data));
      }).catch(() => {
        setStripeError("config");
      })
  }, []);

  React.useEffect(() => {
    console.log("making payment intent");
    axios.post(zenStripeAPI + `/createPaymentIntent/`+props.order.orderprice)
    .then(res => {
        setClientSecret(res.data);
    }).catch(function (error) {
      setClientSecret(null);
      setStripeError("sec");
      console.log(error);
    });
  }, []);

  return (//myZen.profile? (
    <div >
      {clientSecret && stripePromise?
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <ZenShopPaymentCheckout order = {props.order} />
        </Elements>
      :stripeError == "config"?
      <h3>Sorry, there was en arror loading the Payments service. Please email contact@zencyclopedia.co.uk for support</h3>
      :stripeError == "sec"?
      <h3>Sorry, there was en arror loading Payments. Please email contact@zencyclopedia.co.uk for support</h3>
      :null}
    </div>
  );

}
