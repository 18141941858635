import React, { useState } from "react";
import axios from "axios";

interface MP3DropProps {
  setMediaFile: any;
  setMediaFileType:any;
  mediaFile:any;
}

const MP3Drop: React.FC<MP3DropProps> = ({ mediaFile, setMediaFile, setMediaFileType }) => {

  const handleMP3Drop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer.items) {
      for (let i = 0; i < event.dataTransfer.items.length; i++) {
        const droppedFile = event.dataTransfer.items[i].getAsFile();
        if (droppedFile && droppedFile.type === "audio/mpeg") {
          setMediaFileType("audio/mpeg");
          setMediaFile(droppedFile);
          break;
        } else if (droppedFile && droppedFile.type === "video/mp4") {
          setMediaFileType("video/mp4");
          setMediaFile(droppedFile);
          break;
        } else if (droppedFile && droppedFile.type === "video/mov") {
          setMediaFileType("video/mov");
          setMediaFile(droppedFile);
          break;
        }else {
          console.log("unsupported media type: ");
          console.log(droppedFile.type);
        }
      }
    }
  };


  return (
    <div
      style={{
        width: "300px",
        height: "200px",
        border: "2px dashed #ccc",
        borderRadius: "4px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        margin:"auto",
        marginBottom:"15px"
      }}
      onDrop={handleMP3Drop}
      onDragOver={(event) => event.preventDefault()}
    >
      {mediaFile ? (
        <div>
          <p>File selected: {mediaFile.name}</p>
        </div>
      ) : (
        <p>Drag and drop a MOV, MP3 or MP4 file here</p>
      )}
    </div>
  );
};

export default MP3Drop;
