import * as React from "react";
import "../../styling/zenverse/Zenverse.scss";
import "../../styling/common/zenCoreUIComponents.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // req
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useNavigate, useParams } from "react-router";
import { ZencyclopaediaItemPreview } from "../explore/previews/ZencyclopaediaItemPreview";
import { Link } from "react-router-dom";
import { ZencycyclopaediaPreviewGroup } from "../explore/ZencycyclopaediaPreviewGroup";
import PrivateRoute from "../common/PrivateRoute";
import { ZenNavBar } from "../common/navbar/ZenNavBar";
import { ZencyclopaediaTextItemPreview } from "../explore/previews/ZencyclopaediaTextItemPreview";
import { useZenverseService } from "../../hooks/ZenverseContext";
import { getPortalJSX } from "../../service/ZenverseService";
import { Configuration, OpenAIApi } from 'openai';
import Chatbot from "react-chatbot-kit";
import { ZencTitle } from "../home/ZencTitle";
import { MyZenNavBar } from "../common/navbar/MyZenNavBar";
import { useMyZenService } from "../../hooks/ZenUserContext";
import { useAuth } from "../../hooks/auth";
import { CardElement, Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {ZenergyPaymentCheckout} from "./ZenergyPaymentCheckout";
import axios from "axios";
import { ZEN_C_ENDPOINT } from "../../config/EnvironmentalVariables";
import { ReactSession } from 'react-client-session';

export const ZenergyPaymentComplete = (props: any) => {

  const [pointsApplied, setPointsApplied] = React.useState(false);
  const [pending, setPending] = React.useState(false);

  let stripeConfirmation = useParams();
  let amount = stripeConfirmation.stripeConfirmation;
  const searchParams = new URLSearchParams(document.location.search);
  
  const auth = useAuth();

  //basic client validation to check this is valid zenergy boost request (back end checks payment intent exists.)
  const validateZenergyBoost = () => {
    const lastPaymentIntent = ReactSession.get("lastpaymentintent");
    return !(lastPaymentIntent != null && lastPaymentIntent == searchParams.get('payment_intent'));
  }

  const applyPoints = async () => {
    console.log("applying points:");
    console.log(amount);
    console.log(auth.username);
    setPending(true);
    if(validateZenergyBoost()){
      let x = await axios.post(ZEN_C_ENDPOINT + `/myzen/zenergy/zenergyBoost`,{
        username:auth.username,
        amount:amount,
        stripeConfirmation: searchParams.get('payment_intent')
      }).then(() => {
        ReactSession.set("lastpaymentintent",searchParams.get('payment_intent'));
        setPointsApplied(true);
      });
      setPending(false);
    } else {
      console.log("ZENERGY POINTS ALREADY APPLIED TO YOUR ACCOUNT");
    }
  }

  if(auth.username && !pointsApplied && !pending){
    applyPoints();
  }

  return (//myZen.profile? (
    <div className="ZenBasicPage">
      <h1>Payment Successful</h1>
      <div className = "payment-successful-image"></div>
      <h3> {amount} zenergy points have been applied to your account!</h3>
      <Link to = "/myZen"><div className="zenGlowButton navajoGlowButton">done</div></Link>
    </div>
  );

}
