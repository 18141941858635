import React, { useState } from 'react';
import "../../styling/vacana/vacana.scss";
import "../../styling/common/index.scss";
import "../../styling/stream/stream.scss";
import "../../styling/zenverse/Zenverse.scss";
import { useZenverseService } from '../../hooks/ZenverseContext';
import { ProvideZenAudioContext } from '../../hooks/ZenAudioContext';
import { ZenPopUpMessage } from '../common/ZenPopUpMessage';
import { useAuth } from '../../hooks/auth';
import axios from 'axios';
import { ZEN_C_ENDPOINT } from '../../config/EnvironmentalVariables';
import { ProvideZenJournalContext } from '../../hooks/ZenJournalContext';



export const PracticePortalTimeKeeper = (props:
  {
    practicePortalQuotaStarted?: boolean,
    outOfTime?: any,
    showOutOfTimeWarning?: any,
    addTime?:any
  }) => {
  
  const [started, setStarted] = React.useState(false);
  const [timeCheckWaiting, setTimeCheckWaiting] = React.useState(false);
  const [totalTimeSpent, setTotalTimeSpent] = React.useState(0);
  const practicePortalQuotaTimeCheckPeriod = 2;


  let zenverse = useZenverseService();

  let auth = useAuth();

  const runZenPortalTimeCheck = async () => {
    console.log("running zen portal time check.");

    console.log("Logging Zen Portal Time");
    zenverse.logUserZenPortaTime(practicePortalQuotaTimeCheckPeriod);
    props.addTime(practicePortalQuotaTimeCheckPeriod);
    
    console.log("getting user reamining time");
    var outOfTime = false;
    await axios.get(ZEN_C_ENDPOINT + `/myzen/quotas/getUserPortalTimeRemaining/` + auth.username)
      .then(res => {
        let remainingTime = res.data;
        console.log("Time left:");
        console.log(remainingTime);
        if (remainingTime < 1){
          props.outOfTime();
        } else if (remainingTime < 1){
            props.showOutOfTimeWarning();
        }
      })
  }

  if (props.practicePortalQuotaStarted && !started) {
    console.log("Strarting Zen Portal Time Check Interval");
    let firstRun = runZenPortalTimeCheck();
    setInterval(() => runZenPortalTimeCheck(),
      1000 * 60 * practicePortalQuotaTimeCheckPeriod
    );
    setStarted(true);
  }

  return (
    <></>
  );




}