
import * as React from "react";
import { ZenSearchFilterTagsDisplay } from "./ZenSearchFilterTagsDisplay";
import { useState } from "react";
import "../../../styling/common/index.scss";

export const ZenSearchBar = (props: any) => {

    const [activeTags, setActiveTags] = React.useState(props.searchKey != null? [props.searchKey] : []);
    const [search, setSearch] = useState(null);
    const [suggestedTags, setSuggestedTags] = React.useState([]);
    const [expanded, setExpanded] = React.useState([]);

    if(props.searchKey && !activeTags.includes(props.searchKey)){
        if(activeTags != null && activeTags.length > 0 && activeTags[0] != null){
            activeTags.push(props.searchKey);
        } else {
            setActiveTags([props.searchKey]);
        }
    }

    console.log("Sear ID: "+props.searchKey);

    const tagOptions = ["Beginner", "Anxiety", "Breathing", "Depression", "Relaxation", "Lectures", "Documentaries", "Movement", "Shorts", "Morning", "Teachings", "Buddhism", "Sleep"].map(fi => fi.toLowerCase()).filter(t => t != props.searchKey);

    const chooseFilterTagVisibleSuggestions = (availableTags: any[]) => {
        // Usually base this off the current search (obtained from props, combined with the current activeTags)
        let numberOfTags = activeTags != null ? 5 - activeTags.length : 5;
        if (expanded) { numberOfTags = numberOfTags + 10 }
        setSuggestedTags(availableTags != null && availableTags.length >= numberOfTags ? availableTags.splice(0, numberOfTags) : availableTags);
    }

    React.useEffect(() => {
        chooseFilterTagVisibleSuggestions(tagOptions);
    }, []);

    const activateTag = (tag: string) => {
        let newActiveTags = activeTags.map(t => t);
        newActiveTags.push(tag);
        setActiveTags(newActiveTags);
        setSuggestedTags(suggestedTags.filter(t => t != tag));
        props.updateSearch(search, newActiveTags);
    }

    const deActivateTag = (tag: string) => {
        console.log("DEACTIVATING");
        let newActiveTags = activeTags.filter(t => t != tag);
        setActiveTags(newActiveTags);
        let newSuggestedTags = suggestedTags.map(t => t);
        newSuggestedTags.push(tag);
        setSuggestedTags(newSuggestedTags);
        props.updateSearch(search, newActiveTags);

    }

    const handleSearchBarChange = (newSearch: string) => {
        setSearch(newSearch);
        props.updateSearch(newSearch, activeTags);
    }

    return (
        <div className="zencyc-search-bar-container" onClick={() => props.handleSearchBarActive()}>
            <div className="box">
                <div className="zen-search-active-components">
                    <div className="zen-search-bar-filters">
                        <ZenSearchFilterTagsDisplay 
                        suggestedTags = {suggestedTags} 
                        activeTags = {activeTags}
                        activateTag = {activateTag}
                        deActivateTag = {deActivateTag}
                        />
                    </div>
                    <form name="search">
                        <input 
                        placeholder="Search for something..."
                        className="input zenK zen-search-input" value={search} type="text" name="txt" onMouseLeave={() => { }} onChange={(e) => { handleSearchBarChange(e.target.value); }} />
                        <div className="zencyc-search-go"></div>
                    </form>
                </div>
            </div>
        </div>
    );
}