import * as React from "react";
import "../../../styling/explore/explore.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { faYinYang } from '@fortawesome/free-solid-svg-icons'
import { ZenNavBar } from "../../common/navbar/ZenNavBar";
import ZenInfomaticPageInterface from "./ZenInfomaticPageInterface";
import ZenContentInterface from "../../zencontent/practices/ZenContentInterface";
import { useNavigate } from "react-router";
import ZenInfomaticInterface from "../../zencontent/infomatics/ZenInfomaticInterface";
import { PracticePreview } from "../../zencontent/practices/previews/PracticePreview";
import { useMyStuffService } from "../../../hooks/MyStuffContext";

export const InfomaticTitlePage = (props: ZenInfomaticInterface) => {

    let navigate = useNavigate();

    const sliderRef:any = React.createRef();

    const parentGroups = null; // ZencycyclopaediaPreviewGroup

    const linkedContent = props.linkedContent? props.linkedContent.map(content => <PracticePreview {...content}></PracticePreview>) : null;

    const myStuff = useMyStuffService();

    React.useEffect(() => {
      myStuff.logContentInteraction(props.id);
    },[]);

    return (
        <div className ='zencyclopaedia-infomatic-page'>
            <div>
              <h1 className="infomatic-header"><b>{props.title}</b> </h1>
              <div style = {{margin:"auto", width:"fit-content"}}>
                <img className = "zencyc-infomatic-image" src={props.image? props.image : null} ></img>
                </div>
              <h4 className="infomatic-description zenK"><b>{props.description}</b> </h4>
            </div>
            {linkedContent && linkedContent.length<0?
            <div className = "infomatic-linked-content">
                <h3 style={{textAlign:"center", marginBottom:"30px"}}>Related Content: </h3>
             {linkedContent}
            </div>
            :<></>
            }
            <h3 style={{textAlign:"center", margin:"auto", marginTop:"30px",marginBottom:"30px"}}>Want to make a submission for this page? email <a href="mailto:contact@zencyclopedia.co.uk"><b>contact@zencyclopedia.co.uk </b> </a></h3>
        </div>
    );
  
}



