import { useState, useEffect } from "react";

function useZenVideoPlayer() {
  const [duration, setDuration] = useState();
  const [curTime, setCurTime] = useState();
  const [playing, setPlaying] = useState(false);
  const [clickedTime, setClickedTime] = useState();
  const [volume, setVolume] = useState(50);


  return {
    curTime,
    duration,
    playing,
    volume,
    setPlaying,
    setClickedTime,
    setVolume
  }
}

export default useZenVideoPlayer;