import * as React from "react";
import "../../../styling/common/index.scss";
import "../../../styling/common/zenCoreUIComponents.scss";
import "../../../styling/myzen/myzen.scss";
import { useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router";
import { PuffLoader } from "react-spinners";
import { ZEN_C_ENDPOINT } from "../../../config/EnvironmentalVariables";
import { RetreatPreview } from "./RetreatPreview";
import { ZenNavBar } from "../../common/navbar/ZenNavBar";

export const RetreatBrowser = () => {

      let [retreats, setRetreats] = useState(null);
      const [updating, setUpdating] = useState(false);
      const [pageNum, setPageNum] = useState(1);

      //search retreats by tag:
      const { type } = useParams();
      var tagSearch = "" + type + "";


      React.useEffect(() => {
            axios.get(ZEN_C_ENDPOINT + `/zen/retreats/type/` + tagSearch.toLowerCase() + `/`+pageNum)
                  .then(res => {
                        setRetreats(res.data);
                        setPageNum(pageNum+1);
                  });
      }, [])

      const loadMoreResults = () => {
            setUpdating(true);
            axios.get(ZEN_C_ENDPOINT + `/zen/retreats/type/` + tagSearch.toLowerCase() + `/`+pageNum)
                  .then(res => {
                        if(res && res.data && res.data.length > 0){
                              var retreatItems = []
                              retreats.map(i => retreatItems.push(i));
                              res.data.map(i => retreatItems.push(i));
                              setRetreats(retreatItems);
                              setPageNum(pageNum+1);
                        }
                        setUpdating(false);
                  });
      }

      let navigate = useNavigate();

      const handleScroll = async (e) => {

            if ((!updating) && (e.target.scrollHeight - e.target.scrollTop < (1.6 * e.target.clientHeight))) {
                  console.log("LOADING MORE RE£TREATS");
                  setUpdating(true);
                  await loadMoreResults();
                  setUpdating(false);
            }
      }

      return (
            <React.Fragment>
                  <div className="ZenBasicPage zen-page-holder"  style = {{paddingBottom:"200px"}} onScroll={(e) => handleScroll(e)}>
                        <ZenNavBar />
                        <div className="zen-retreats-results-container">
                              <div style={{ display: "flex", flexDirection: "row", margin: "auto" }}>
                                    <div className="zenscape-back-icon" onClick={() => navigate("/explore/shop/retreats")}></div> Find {tagSearch.toLowerCase()} retreats:
                              </div>
                              {retreats != null && retreats.length > 0 ?
                                    retreats.map(r => <RetreatPreview {...r} />)
                                    : null}
                              <div>

                              </div>
                        </div>
                  </div>
            </React.Fragment>
      );
}
