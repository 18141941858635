import React, { createContext, useContext} from "react";

interface UseZenAudio {
  addSound: (zenSound : ZenAudioInterface ) => void;
  updateVolume: (soundName : string, vol : number) => void;
  stopSound: (soundName : string) => void;
}

interface ZenAudioInterface {
  name: string,
  soundSource: any
}

type Props = {
  children?: React.ReactNode;
};
const zenAudioContext = createContext({} as UseZenAudio);

export const ProvideZenAudioContext: React.FC<Props> = ({ children }) => {
    const zenAudioService = ZenAudioService();
    return <zenAudioContext.Provider value ={zenAudioService}>{children}</zenAudioContext.Provider>;
};

export const useZenAudioContext = () => {
    return useContext(zenAudioContext);
};

export const ZenAudioService = () => {

    const [zenSounds, setZenSounds] =  React.useState([]);

    const addSound =  (zenSound : ZenAudioInterface ) => {
      setZenSounds(zenSounds.concat(zenSound))
    }

    const updateVolume = (soundName : string, vol : number) => {

    }

    const stopSound = (soundName : string) => {
        zenSounds.forEach((sound : ZenAudioInterface) => {
            if(sound.name == soundName){
                if(sound.soundSource != null){
                  sound.soundSource.stop(0);
                }
            }
        });
    }

    return {
      addSound,
      updateVolume,
      stopSound,
    };

}