import * as React from "react";
import { OurRootsTranslation } from "../../content/ZenHome/OurRootsTranslations";
import '../../styling/common/index.scss';
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom";
import { ZencTitle } from "./ZencTitle";
import { ZenNavBar } from "../common/navbar/ZenNavBar";
import ReactCardFlip from 'react-card-flip';
import Slider from "react-slick";

export const OurRoots = (props: any) => {

  const [showToadInfo, setShowToadInfo] = React.useState(false);
  const [showAlexiInfo, setShowAlexInfo] = React.useState(false);

  return (

    <div className="ZenBasicPage" style = {{paddingBottom:"150px"}}>
      <ZencTitle />
      <div  style = {{paddingBottom:"200px"}}>
      <h2 className="okashi">Our Mission</h2>
      <h3>Our company started from very humble origins with two people who simply wanted to make a difference. Over the last 18 months we have worked hard to build a platform capable of sharing the information that has been so useful to us with a wide audience and make mindfulness activities more accessible for those that need it.
      </h3>

      <h2 className="okashi">Our People</h2>
{/* 
      <div className="zenCWorkerHolder" onClick={() => setShowAlexInfo(false)} style = {showAlexiInfo? {display:"block"} : {display:"none"}}>
          <div className="zenCWorkerAbout" style={{ padding: "15px" }}>
            <h2>Background</h2>
            <p className="justifiedText">
              Alexia was born and bred in Barcelona, she moved to London in 2015 for university where she met the other Co-Founder for the first time and stayed to complete her Masters degree in International relations before turning her skills to the tech work and gaining experience within the city of London.<br/><br/>
              <b>Fun Fact: </b>She speaks 5 languages including Catalan!
            </p>

            <h2>Why I work here</h2>
            <p className="justifiedText" style={{ marginBottom: "50px" }}>
                </p>
            <div className="zenGlowButton navajoGlowButton" onClick={() => setShowAlexInfo(false)}> go back </div>
           
            I am also hugely passionate about modernising working conditions, champion diversity and inclusion through practices such as hybrid working. Read more about this in our Anyone Can Work here programme.
           
          </div>
        </div>  */}

      <Slider 
                        centerMode={true}
                        centerPadding='15%'
                        infinite={true}>
      
        <div className="zenCWorkerHolder" onClick={() => {
          //setShowAlexInfo(true)
        }} style = {showAlexiInfo? {display:"none"} : {display:"block"}}>
          <div className="alexiaImage"></div>
          <div className="zenCWorkerName">Alexia Otero</div>
          <div className="zenCWorkerTitle">CEO & Co-Founder of ZENcyclopedia</div>
          <h4 style = {{paddingLeft:"15px", paddingRight:"15px"}}><b>Fun Fact: </b>She speaks 5 languages including Catalan!</h4>
          <div className="zenWorkerQuote">
          <h4 className = ""><span className='prompt-qouted'><i>“I am still learning”</i></span><span className='prompt-qouted-auth'> - Michelangelo</span></h4>
          </div>
        </div>
        
        <div>
        <div className="zenCWorkerHolder" onClick={() => {
          //setShowToadInfo(true)
        }} style = {!showToadInfo? {display:"block"} : {display:"none"}}>
          <div className="tomImage"></div>
          <div className="zenCWorkerName">Tom Woodhouse</div>
          <div className="zenCWorkerTitle">CEO & Co-Founder of ZENcyclopedia</div>
          {/* <div className="zenWorkerQuote">
            <i><h3><span className="striked-text">My Life Is My Message</span><br />
              My message is my message,<br />
              My life is my warning!</h3></i>
          </div> */}
          {/* <div className="zenGlowButton mingGlowButton" >
            See More
          </div> */}
        </div>


        <div className="zenCWorkerHolder" onClick={() => setShowToadInfo(false)} style = {showToadInfo? {display:"block"} : {display:"none"}}>
          <div className="zenCWorkerAbout" style={{ padding: "15px", paddingBottom:"50px" }}>
            <h2>Background</h2>
            <p className="justifiedText">Tom learned his trade working within the National Security sector, working on a range of cutting edge technologies that are used to keep people safe on a daily basis.</p>

            <h2>Why I work here</h2>
            <p className="justifiedText" style={{ marginBottom: "50px" }}>
              My early life was filled with existential crisis’, constant questioning and confusion.<br /><br />

              Becoming practiced in Zen allowed me to tackle these challenges in a new way. It revolutionised my mental health, put an end to the incessant chatter of my mind, I found peace, and my body did too, the tinnitus, migraines and insomnia that had bothered me for years with no relief suddenly disappeared.<br /><br />

              I want other people to discover the power of self care practice and mindfulness meditation, and to be enabled to genuinely enjoy their life and get the most out of it.<br /><br />

              To me, ZENcyclopedia represents both the the call for reform in the way we handle mental health challenges in the West, as well as how we practice and think about Buddhism, Zen and other Eastern religions.<br /><br />

              My aim is to make the benefits of mindfulness practice more accessible by modernising practices that were developed to be useful in other contexts and cultures so that they can bring relief to those living in the West within an ever changing world filled with more chaos and stress by the day.
            </p>
            <div className="zenGlowButton navajoGlowButton" onClick={() => setShowToadInfo(false)}> go back </div>
            {/* 
            I am also hugely passionate about modernising working conditions, champion diversity and inclusion through practices such as hybrid working. Read more about this in our Anyone Can Work here programme.
           */}
           </div>
          </div>
          </div>
        </Slider>
      </div>
      <h3>

      </h3>

    </div>

  );
}