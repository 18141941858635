import React from "react";
import ZenContentInterface from "../components/zencontent/practices/ZenContentInterface";
import { PracticeFullyFocusedPreview } from "../components/zencontent/practices/previews/PracticeFullyFocusedPreview";
import { PracticeOverview } from "../components/zencontent/practices/previews/PracticeOverview";
import { PracticePortalPreview } from "../components/zencontent/practices/previews/PracticePortalPreview";
import { PracticePreview } from "../components/zencontent/practices/previews/PracticePreview";
import { InfomaticStreamItem } from "../components/zencontent/infomatics/InfomaticStreamItem";
import { ZenNugget } from "../components/zencontent/nuggets/ZenNugget";
import { PathOverview } from "../components/zencontent/courses/PathOverview";
import { Adsense } from "@ctrl/react-adsense";
import { ContentTypes } from "../components/zencontent/common/ContentTypes";
import { Link } from "react-router-dom";
import { ZEN_C_ENDPOINT } from "../config/EnvironmentalVariables";
import axios from "axios";
import { RetreatStreamAd } from "../components/shop/Retreats/RetreatStreamAd";
import { ReelStreamReelItem } from "../components/zencontent/reels/ReelStreamReelItem";
import { ZenStreamReelPreview } from "../components/zencontent/reels/ZenStreamReelPreview";

const getGooglestreamAd = () => {
  //Ad GOOGLE AD: (AdSense)
  let ad = <Adsense
    client="ca-pub-3377323199127790"
    slot="3158038429"
    format="fluid"
    layoutKey="-e7+5w+4e-db+86"
    style={{ width: "100%", height: 300, display: "block" }}
  />

  return ad;
}

const getShopMainAd = () => {
  return <div className="zen-stream-ad zen-stream-ad-bsc shopMainAd">
    <div className="zen-stream-ad-text">
      <Link to="explore/shop">
        <h2>Why not check out the Zen Shop?!</h2>
        <h4>Offering T-Shirts, Zen Retreats, Stickers & More</h4>
      </Link>
    </div>
    <div className="zen-stream-ad-photo-holder">
      <Link to="explore/shop">
        <img src={require("../static/photos/exploreIcons/zenshopwhite.png")} className="zen-stream-ad-photo"></img>
      </Link>
    </div>
  </div>;
}

const getShopTShirtAd = () => {
  return <div className="zen-stream-ad zen-stream-ad-bsc shopMainAd">
    <div className="zen-stream-ad-text">
      <Link to="shop/tshirts">
        <h2>Check out our T-Shirts!</h2>
        <h4>Now available in 3 colours</h4>
      </Link>
    </div>
    <div className="zen-stream-ad-photo-holder shadow-photo-holder">
      <Link to="shop/tshirts">
        <img src={require("../static/photos/shop/t-shirt-square.png")} className="zen-stream-ad-photo  shadow-photo"></img>
      </Link>
    </div>
  </div>;
}

const getMindfulStickersAd = () => {
  return <div className="zen-stream-ad zen-stream-ad-bsc shopMainAd">
    <div className="zen-stream-ad-text">
      <Link to="shop/stickers">
        <h3>Our ZEN stickers are perfect to liven up a range of surfaces, and are the perfect reminder to stay present & mindful!</h3>

      </Link>
    </div>
    <div className="zen-stream-ad-photo-holder shadow-photo-holder">
      <Link to="shop/stickers">
        <img src={require("../static/photos/shop/stickers.png")} className="zen-stream-ad-photo  shadow-photo"></img>
      </Link>
    </div>
  </div>;
}

const getZenShopAd = () => {
  var adTypeChoice = Math.floor(Math.random() * 3);

  if (adTypeChoice < 1) {
    return getShopTShirtAd();
  } else if (adTypeChoice < 2) {
    return getMindfulStickersAd();
  } else {
    return getShopMainAd();
  }
}

const getRetreatMainAd = () => {
  return <div className="zen-stream-ad zen-stream-ad-bsc shopMainAd">
    <div className="zen-stream-ad-text">
      <Link to="explore/shop/retreats">
        <h2>Feeling the need to take a break? </h2>
        <h4>Check out our Zencyclopedia approved retreats!</h4>
      </Link>
    </div>
    <div className="zen-stream-ad-photo-holder shadow-photo-holder">
      <Link to="explore/shop/retreats">
        <img src={require("../static/photos/shop/nature-retreat.png")} className="zen-stream-ad-photo  shadow-photo"></img>
      </Link>
    </div>
  </div>;
}

const getRandomRetreatAd = async () => {

  let retreat = await axios.get(ZEN_C_ENDPOINT + `/zen/retreats/tag/international`)
    .then(res => {
      if (res.data && res.data.length > 0) {
        return res.data[Math.floor(Math.random() * res.data.length)]
      };
    }).catch(() => {
      return null;
    });

  if (retreat != null) {
    console.log("serving retreat: ");
    return <RetreatStreamAd {...retreat} />
  } else {
    return getRetreatMainAd();
  }

}

const getRetreatAd = () => {
  var adTypeChoice = Math.floor(Math.random() * 3);

  if (adTypeChoice < 1) {
    return getRetreatMainAd();
  } else {
    return getRandomRetreatAd();
  }

}

const getPleaseDonate = () => {
  return <div className="zen-stream-ad zen-stream-ad-to shopMainAd">
    <h3>Do you believe in our mission of providing accessible midnfulness & mental health resources for all? <br></br>
      Donate to our creator fund! 100% of donations will go to providing more free content within Zencyclopedia </h3>
    <Link to="donate">
      <div className="zenGlowButton mingGlowButton">Find Out More</div>
    </Link>
  </div>;
}

const getZenPowerCardAd = () => {
  var adTypeChoice = Math.floor(Math.random() * 2);

  if (adTypeChoice < 1) {
    return <div className="zen-stream-ad zen-stream-ad-bsc shopMainAd">
      <div style={{ margin: "auto" }}>
        <h2 style={{ margin: "auto" }}>Looking for more portals or things to do? </h2>
        <h4 style={{ margin: "auto", marginBottom:"15px" }}>Why not increase your inventory size?</h4>
        <Link to="explore/cards/powers">
          <div className="zenGlowButton mingGlowButton">Find Out More</div>
        </Link>
      </div>
    </div>;
  } else {

    return <div className="zen-stream-ad zen-stream-ad-bsc shopMainAd">
      <div style={{ margin: "auto" }}>
        <h2 style={{ margin: "auto" }}>Looking for more time using our portals? </h2>
        <h4 style={{ margin: "auto", marginBottom:"15px" }}>Why not increase your meditation time allowance or even make it unlimited? </h4>
        <Link to="explore/cards/powers">
          <div className="zenGlowButton mingGlowButton">Find Out More</div>
        </Link>
      </div>
    </div>;
  }

}

const getSubscriptionAd = (userSubLevel?: string) => {
  if (userSubLevel == "BASIC") {
    return <div className="zen-stream-ad zen-stream-ad-bsc shopMainAd">
      <div style={{ margin: "auto" }}>
        <h2 style={{ margin: "auto" }}>Time to take your practice to the next level?</h2>
        <h4 style={{ margin: "auto" }}>Unlock more features with the ZENcyclopedia Premium subscription</h4>
        <Link to="/explore/subscriptions">
          <div className="zenGlowButton mingGlowButton">Find Out More</div>
        </Link>
      </div>
    </div>;
  } else {
    return <div className="zen-stream-ad zen-stream-ad-bsc shopMainAd">
      <div style={{ margin: "auto" }}>
        <h2 style={{ margin: "auto", textAlign:"center" }}>Why not subscribe and unlock more Zen goodness?!</h2>
        <h4 style={{ margin: "auto" , textAlign:"center"}}>We have plans starting from as little as 99p!</h4>
        <Link to="/explore/subscriptions"  style={{ margin: "auto"}}>
          <div className="zenGlowButton mingGlowButton"  style={{ margin: "auto", marginTop:"20px" }}>Find Out More</div>
        </Link>
      </div>
    </div>;
  }
}

const getCumilativeWeight = (weightedList: any, topIndex: number) => {
  var totalWeight = 0;
  for (var i = 0; i <= topIndex; i++) {
    totalWeight = totalWeight + weightedList[i].weight;
  }

  return totalWeight;
}


const generateAdItem = async (userSubLevel?: string) => {

  // alternates between google ad and internal ad.
  const googleAdsEnabled = false;

  let userIsSubscribed = userSubLevel != null && (userSubLevel == "BASIC" || userSubLevel == "PREMIUM");

  const addTypeChoices = [{ name: "SHOP", weight: 5 }, { name: "RETREAT", weight: 4 }, { name: "POWER", weight: 1 }, { name: "DONATE", weight: 1 }]; //, { name: "ZENERGY", weight: 1 }];

  if (!userIsSubscribed) {
    addTypeChoices.push({ name: "SUBSCRIBE", weight: 2 });
  }
  if (googleAdsEnabled) {
    addTypeChoices.push({ name: "GOOGLE", weight: 6 });
  }

  var adTypeChoice = Math.floor(Math.random() * getCumilativeWeight(addTypeChoices, addTypeChoices.length - 1));

  if (adTypeChoice < getCumilativeWeight(addTypeChoices, 0)) {
    return getZenShopAd();
  } else if (adTypeChoice < getCumilativeWeight(addTypeChoices, 1)) {
    return getRetreatAd();
  } else if (adTypeChoice < getCumilativeWeight(addTypeChoices, 2)) {
    return getZenPowerCardAd();

  } else if (adTypeChoice < getCumilativeWeight(addTypeChoices, 3)) {
    return getPleaseDonate();
    // } else if (adTypeChoice < getCumilativeWeight(addTypeChoices, 5)) {
    //   return getZenShopAd();
  } else if (!userIsSubscribed && adTypeChoice < getCumilativeWeight(addTypeChoices, 4)) {
    return getSubscriptionAd(userSubLevel);
  } else if (!userIsSubscribed && googleAdsEnabled && adTypeChoice < getCumilativeWeight(addTypeChoices, 5)) {
    return getGooglestreamAd();
  } else if (userIsSubscribed && googleAdsEnabled && adTypeChoice < getCumilativeWeight(addTypeChoices, 4)) {
    return getGooglestreamAd();
  } else {
    console.log("failed to return an ad.");
  }
}


export const getStreamJSXNoAds = (items: ZenContentInterface[], userSubLevel?: string) => {
  const streamMockItems: any[] = [];
  //var allPortals = getStarterZenscapes(); 
  items.map(i => {
    if (i.contentType == 'MEDITATION') {
      if (i.previewType && i.previewType == 'portal') {
        streamMockItems.push(<PracticePortalPreview {...i} />);
      } else if (i && i.previewType && i.previewType == 'FullyFocused') {
        streamMockItems.push(<PracticeFullyFocusedPreview {...i} />);
      } else {
        //random number between 1-4, 1 & 2 == practice overview, 3 - Portal, 4 = Fully Focused
        const rndInt = Math.floor(Math.random() * 4) + 1;
        if (rndInt == 1) {
          streamMockItems.push(<PracticePortalPreview {...i} />);
        } else if (rndInt == 2) {
          streamMockItems.push(<PracticeFullyFocusedPreview {...i} />);
        } else {
          streamMockItems.push(<PracticeOverview {...i} />);
        }
      }
    } else if (i.contentType == 'INFOMATIC' || i.contentType == 'INFO_GROUP' || i.contentType == 'ZENC_INFO_GROUP') {
      streamMockItems.push(<InfomaticStreamItem {...i}></InfomaticStreamItem>);
    } else if (i.contentType == 'NUGGET') {
      streamMockItems.push(<ZenNugget {...i} streamItem = {true}></ZenNugget>);
    } else if (i.contentType == 'PATH') {
      streamMockItems.push(<PathOverview {...i}></PathOverview>);
    } else if (i.contentType == ContentTypes.ZENSCAPE) {
      // var chosenId = Math.floor(Math.random() * allPortals.length);
      // var portal = allPortals[chosenId];
      //streamMockItems.push(<ZenscapeStreamPreview {...portal}></ZenscapeStreamPreview>);
    } else if (i.contentType == 'REEL'){
      streamMockItems.push(<ZenStreamReelPreview {...i}/>);
    }
  })

  return streamMockItems;

}

export const getStreamJSX = async (items: ZenContentInterface[], userSubLevel?: string) => {
  const streamMockItems: any[] = [];
  //var allPortals = getStarterZenscapes(); 
  items.map(i => {
    if (i.contentType == 'MEDITATION') {
      if (i.previewType && i.previewType == 'portal') {
        streamMockItems.push(<PracticePortalPreview {...i} />);
      } else if (i && i.previewType && i.previewType == 'FullyFocused') {
        streamMockItems.push(<PracticeFullyFocusedPreview {...i} />);
      } else {
        //random number between 1-4, 1 & 2 == practice overview, 3 - Portal, 4 = Fully Focused
        const rndInt = Math.floor(Math.random() * 4) + 1;
        if (rndInt == 1) {
          streamMockItems.push(<PracticePortalPreview {...i} />);
        } else if (rndInt == 2) {
          streamMockItems.push(<PracticeFullyFocusedPreview {...i} />);
        } else {
          streamMockItems.push(<PracticeOverview {...i} />);
        }
      }
    } else if (i.contentType == 'INFOMATIC' || i.contentType == 'INFO_GROUP' || i.contentType == 'ZENC_INFO_GROUP') {
      streamMockItems.push(<InfomaticStreamItem {...i}></InfomaticStreamItem>);
    } else if (i.contentType == 'NUGGET') {
      streamMockItems.push(<ZenNugget {...i} streamItem = {true}></ZenNugget>);
    } else if (i.contentType == 'PATH') {
      streamMockItems.push(<PathOverview {...i}></PathOverview>);
    } else if (i.contentType == ContentTypes.ZENSCAPE) {
      // var chosenId = Math.floor(Math.random() * allPortals.length);
      // var portal = allPortals[chosenId];
      //streamMockItems.push(<ZenscapeStreamPreview {...portal}></ZenscapeStreamPreview>);
    } else if (i.contentType == 'REEL'){
      streamMockItems.push(<ZenStreamReelPreview {...i}/>);
    }
  })

  let adItem = await generateAdItem(userSubLevel);
  streamMockItems.push(adItem);

  return streamMockItems;

}



export const getPracticeListItem = (practice: ZenContentInterface) => {
  return <PracticePreview {...practice} />
}


