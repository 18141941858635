import * as React from "react";
import "../../styling/zenverse/Zenverse.scss";
import "../../styling/common/index.scss";
import "../../styling/common/zenCoreUIComponents.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // req
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ZenPayment } from "./ZenPayment";
import { ZencTitle } from "../home/ZencTitle";
import { DonationPayment } from "./DonationPayment";
import { ZenergyPointsPayment } from "./ZenergyPointsPayment";

export const BuyZenergyPoints = (props: any) => {

  const [page, setPage] = React.useState(1);
  const [amount, setAmount] = React.useState(0);
  const [zenPoints, setZenPoints] = React.useState(0);

  return (//myZen.profile? (
    <div className="ZenBasicPage" style={{ paddingTop: "25px" }}>
      {page == 1 ?
        <div>
          <h1>Buy Zenergy Points</h1>

          <h3>Choose An Amount:</h3>
       

          <div className="zen-donation-choose-amount">
            <div style={{ display: "flex", flexDirection: "row", margin: "auto", marginTop: "15px", marginBottom: "25px", width:"fit-content",maxWidth: "210px" }}>

              <div className = "buyZenergyPointsOption" onClick = {() => {setAmount(2); setZenPoints(50); setPage(2); }}>
                <div className = "buyZenergyPointsZenergyValue">50</div>
                <div className = "buyZenergyPointsPrice">£2</div>
              </div>
                  
              <div className = "buyZenergyPointsOption" onClick = {() => {setAmount(3); setZenPoints(100); setPage(2); }}>
                <div className = "buyZenergyPointsZenergyValue">100</div>
                <div className = "buyZenergyPointsPrice">£3</div>
              </div>
                  
              <div className = "buyZenergyPointsOption" onClick = {() => {setAmount(5); setZenPoints(250); setPage(2); }}>
                <div className = "buyZenergyPointsZenergyValue">250</div>
                <div className = "buyZenergyPointsPrice">£5</div>
              </div>

            </div>
            <div style={{ display: "flex", flexDirection: "row", margin: "auto", marginTop: "15px", marginBottom: "25px", maxWidth: "210px" }}>

              <div className = "buyZenergyPointsOption" onClick = {() => {setAmount(7); setZenPoints(500); setPage(2); }}>
                <div className = "buyZenergyPointsZenergyValue">500</div>
                <div className = "buyZenergyPointsPrice">£7</div>
              </div>
                  
              <div className = "buyZenergyPointsOption" onClick = {() => {setAmount(10); setZenPoints(750); setPage(2); }}>
                <div className = "buyZenergyPointsZenergyValue">750</div>
                <div className = "buyZenergyPointsPrice">£10</div>
              </div>
                  
              <div className = "buyZenergyPointsOption" onClick = {() => {setAmount(25); setZenPoints(5000); setPage(2); }}>
                <div className = "buyZenergyPointsZenergyValue">5000</div>
                <div className = "buyZenergyPointsPrice">£25</div>
              </div>
              
            </div>
          </div>
        </div>
        : <ZenergyPointsPayment amount={amount * 100} zenergyPoints={zenPoints}/>
      }
    </div>
  );

}
