export enum SignUpTranslation {
    English = "Sign Up",
    Spanish = "Registrate"
}

export enum UsernameTranslation {
  English = "Username",
  Spanish = "Nombre de Usuario"
}

export enum PasswordTranslation {
  English = "Password",
  Spanish = "Contraseña"
}
 

export enum InvitationNeededTranslation {
  English = "Zencyclopaedia is currently subject to invitation. Contact zencycloapedia@gmail.com with subject ACTIVATE to request a code",
  Spanish = "Zencyclopaedia está actualmente sujeta a invitación. Póngase en contacto con zencyclopaedia@gmail.com con el asunto “activar” para solicitar un código"
}

export enum ActivationCodeTranslation {
  English = "Activation Code",
  Spanish = "Codigo de Seguridad"
}

export enum ConfirmTranslation {
  English = "Confirm",
  Spanish = "Confirmar"
}

export enum  PasswordRequirementsTranslation {
  English = "Password must be at least 6 characters long",
  Spanish = "Password must be at least 6 characters long"
}


export enum InvalidEmailTranslation {
  English = "Not a valid email",
  Spanish = "Not a valid email"
}

export enum SignUpFailedTranslation {
  English = "Sign Up Failed!",
  Spanish = "Sign Up Failed!"
}

export enum UserNameWarningTranslation {
  English = "Username must be at least 3 characters and contain no spaces",
  Spanish = "Username must be at least 3 characters"
}

export enum ValidateEmailTranslation {
  English = "Sign Up Failed!",
  Spanish = "Sign Up Failed!"
}



