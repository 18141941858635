import * as React from "react";
import "../../../styling/common/index.scss";
import "../../../styling/explore/explore.scss";
import { useState } from "react";
import axios from "axios";
import { useAuth } from "../../../hooks/auth";
import { ZEN_C_ENDPOINT } from "../../../config/EnvironmentalVariables";
import { ZenImageBrowser } from "./ZenImageBrowser";
import { MeditationIcons } from "../../zencontent/practices/MeditationIcons";
import ImageDrop from "./ImageDrop";
import BrowseLocalFilesButton from "./BrowseLocalFilesButton";
import { BarLoader } from "react-spinners";
import { getContentListPreview } from "../../../service/ContentService";

export const PathSyllabusLesson = (props: { lessonContent?:any, lessonNumber?:number, changeLessonOrder:any, removeItem?:any}) => {
      const move = (direction:string) => {
            props.changeLessonOrder(props.lessonNumber, direction);
      }

      const remove = () => {
            props.removeItem(props.lessonContent.id);
      }
      
      return (
            <div>
                  <h3>Lesson {props.lessonNumber}</h3>
                  {getContentListPreview(props.lessonContent, false, move, remove)}
            </div>
      )
}