import * as React from "react";
import "../../../styling/common/index.scss";
import "../../../styling/myzen/myzen.scss";
import { useState } from "react";
import ZenPracticeSessionInterface from "../interface/ZenPracticeSessionInterface";
import axios from "axios";
import { ZEN_C_ENDPOINT } from "../../../config/EnvironmentalVariables";
import { Link } from "react-router-dom";



export const ZenPracticeSessionPreview = (props: { session: ZenPracticeSessionInterface, editSession?: any, refreshBlueprint?: any, setEditSession?: any, chooseSession?: any}) => {

    const [viewActions, setViewActions] = useState(false);
    const [practice, setPractice] = useState(null);
    const [showLaunchConfirmation, setShowLaunchConfirmation] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [linkedSessionDetails, setlinkedSessionDetails] = useState(null);




    if (linkedSessionDetails == null) {
        setlinkedSessionDetails({ blueprint: (props.session && props.session.linkedPracticeId == 0), ...props.session });
    }

    if (linkedSessionDetails && linkedSessionDetails.linkedPracticeId && (practice == null)) {
        axios.get(ZEN_C_ENDPOINT + `/zen/content/` + linkedSessionDetails.linkedPracticeId)
            .then(res => {
                setPractice(res.data);
            });
    }

    const removeBlueprintSession = async () => {
        setDeleted(true);
        await axios.post(props.session && linkedSessionDetails.id ? ZEN_C_ENDPOINT + `/myzen/zenPlanner/removeSession/` + linkedSessionDetails.id : '')
            .then(function (response) {
            })
            .catch(function (error) {
                console.log(error);
            });
        props.refreshBlueprint();
    }

    const launchSessionConfirmationModal = async () => {
        setShowLaunchConfirmation(true);
        setViewActions(true);
    }


    return linkedSessionDetails ? (

        <div className={linkedSessionDetails && linkedSessionDetails.sessionType == "practical" ? "MyZenPracticeSession pract-session" :
            linkedSessionDetails && linkedSessionDetails.sessionType == "hobby" ? "MyZenPracticeSession hobby-session" :
                linkedSessionDetails && linkedSessionDetails.sessionType == "meditation" ? "MyZenPracticeSession med-session" :
                    linkedSessionDetails && linkedSessionDetails.sessionType == "zazen" ? "MyZenPracticeSession zazen-session" :
                        linkedSessionDetails && linkedSessionDetails.sessionType == "yoga" ? "MyZenPracticeSession yoga-session" :
                            linkedSessionDetails && linkedSessionDetails.sessionType == "relax" ? "MyZenPracticeSession relax-session" :
                                linkedSessionDetails && linkedSessionDetails.sessionType == "lecture" ? "MyZenPracticeSession lecture-session" :
                                    linkedSessionDetails && linkedSessionDetails.sessionType == "planned" ? "MyZenPracticeSession planned-session" :
                                        ""}
            style={deleted ? { borderColor: "#9e2e0b" } : {}}>
            {!viewActions ?
                <>
                    <div className="practice-session-title-row">
                        <div className="blueprint-session-controls">
                            <div className="sessionControl deleteSession" onClick={() => removeBlueprintSession()}></div>

                            <div className="sessionControl editSession" onClick={() => props.setEditSession(props.session)}></div>
                        </div>

                        {(practice && practice.image) ?
                            <img className="MedPhotoIcon" src={practice ? practice.image : null} ></img> :
                            <></>
                        }

                        {practice && practice.title ?
                            <>
                                <h3 className="session-preview-title">{practice ? practice.title : linkedSessionDetails.title}</h3>
                            </>
                            : linkedSessionDetails ?
                                <h3 className="session-preview-title">{linkedSessionDetails ? linkedSessionDetails.title : null}</h3>
                                : <></>
                        }

                        <h4 className="session-preview-subtitle">{linkedSessionDetails ? <>({linkedSessionDetails.sessionType})</> : null}</h4>
                        <h4 className="session-preview-duration">{linkedSessionDetails ? linkedSessionDetails.duration + " minutes" : null}</h4>
                        {practice && practice.id?
                        <Link to={"/practice/"+practice.id}>
                            <div className="zenGlowButton navajoGlowButton">Start</div>
                        </Link>
                        :null
                        }

                        {/* <h4 className="">{props? linkedSessionDetails.dayOfWeek : null}</h4> */}
                        {linkedSessionDetails && linkedSessionDetails.practiceType ?
                            <div className='practice-preview-type'>
                                {linkedSessionDetails.practiceType}
                            </div>
                            : null}
                    </div>

                </>

                :
                <div style={{ display: 'flex', flexDirection: 'row' }}>

                </div>
            }
        </div>

    ) : (<></>);

}