import * as React from "react";
import { MyZenNavBar } from "../../common/navbar/MyZenNavBar";
import "../../../styling/common/index.scss";
import { ZenNavBar } from "../../common/navbar/ZenNavBar";
import { useState } from "react";
import axios from "axios";
import { useAuth } from "../../../hooks/auth";
import PrivateRoute from "../../common/PrivateRoute";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ZEN_C_ENDPOINT } from "../../../config/EnvironmentalVariables";
import { PuffLoader } from "react-spinners";
import { useMyZenService } from "../../../hooks/ZenUserContext";
import { useZenCardsService } from "../../../hooks/ZenCardsContext";
import { ZenCardMilestoneNotice } from "../../explore/icons/ZenCardMilestoneNotice";
import { ZenCardPreviewInterface } from "../../explore/icons/ZenCardPreview";
import { getSubscriptionAllowanceMessage } from "../../../service/ZenSubscriptionsService";
import { SubscriptionCustomerDetailsModal } from "./SubscriptionCustomerDetailsModal";
import { SubscriptionPayment } from "./SubscriptionPayment";
import { getSubscriptionCost } from "../../../stubs/SubscriptionService";
import { useZenQuotasService } from "../../../hooks/ZenQuotasContext";


export const CreateSubscriptionModal = (props: any) => {

  const { subscriptionTier } = useParams();
  const [page, setPage] = useState("main");
  const [customerId, setCustomerId] = useState();
  let auth =  useAuth();
  
  let quotasContext = useZenQuotasService();
      
  let currentSubscriptionLevel = quotasContext && quotasContext.zenQuotasResults? quotasContext.zenQuotasResults.subscriptionLevel : "FREE";
 


  let subscriptionTierDisplayName = subscriptionTier == "PREMIUM" ? "Premium" : "Basic";

  const createSubscriberCustomer = (name: string, address: any) => {


    axios.post(ZEN_C_ENDPOINT + `/myzen/customers/create`, {
      username:auth.username,
      name: name,
      address: address
    }).then(function (response) {
        setCustomerId(response.data);
        setPage("pay");
    }).catch(function (error) {
      console.log(error);
    });
  }
  
  var subscriptionCost = getSubscriptionCost(subscriptionTier);
  const nth = function (d) {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1: return "st";
      case 2: return "nd";
      case 3: return "rd";
      default: return "th";
    }
  }
  let billingDate = new Date();
  const billingDay = billingDate.getDate() + nth(billingDate.getDate());

  return (
    <div className="ZenBasicPage"  style = {{padding:"10%", paddingTop: "25px" }}>
      {page == "main" ?
        <React.Fragment>

          {/* Back Icon */}

          <h1 style={{}}>Upgrade to {subscriptionTierDisplayName}</h1>

          {/* List Perks? */}

          <h2>{subscriptionCost} Per Month</h2>
          <h4>Billed on the {billingDay} of every month</h4>
          <h2>Simple no-fuss cancellation any time</h2>
          <h4>You can easily manage your subscription by going to My Zen, Account, My Subscription. <br></br> You can also manage your subscription from the the Zen Cards browser, or by chatting to Zen Bot!</h4>
          <div className="zenGlowButton navajoGlowButton" style={{ marginTop: "50px" }} onClick = {() => setPage("details")}>Upgrade Now</div>

        </React.Fragment>
        : page == "details" ?
          <React.Fragment>
            <SubscriptionCustomerDetailsModal onComplete={(name, address) => createSubscriberCustomer(name, address)} />
          </React.Fragment>
          : page == "pay" ?
            <React.Fragment>
              <SubscriptionPayment customerId = {customerId} subscriptionTier = {subscriptionTier}/>
            </React.Fragment>
            : page == "confirmation" ?
              <React.Fragment>
                You can change by any time:
                take form other screen.
              </React.Fragment>
              : null}
    </div>
  );

}

