import * as React from "react";
import "../../../styling/common/index.scss";
import "../../../styling/common/zenCoreUIComponents.scss";
import "../../../styling/myzen/myzen.scss";
import { useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router";
import { PuffLoader } from "react-spinners";
import { ZEN_C_ENDPOINT } from "../../../config/EnvironmentalVariables";
import { ZenNavBar } from "../../common/navbar/ZenNavBar";
import { TShirtPayment } from "./TShirtPayment";
import { DeliveryDetailsModal } from "../common/DeliveryDetailsModal";
import { useAuth } from "../../../hooks/auth";
import { Link } from "react-router-dom";
import { useZenShopService } from "../../../hooks/ZenShopContext";

export const BuyTShirtsModal = () => {

      let auth = useAuth();
      let [page, setPage] = useState("main");
      let [loading, setLoading] = useState(false);
      let [size, setSize] = useState("medium");
      let [colour, setColour] = useState("grey");
      let [orderId, setOrderId] = useState(null);

      let shopService = useZenShopService();

      let navigate = useNavigate();

      const createTShirtRequest = (name: string, email: string, deliveryDetails: any) => {

            //Send request to back end:
            axios.post(ZEN_C_ENDPOINT + `/myzen/shop/order/start`, {
                  name: name,
                  email: email,
                  deliveryDetails: deliveryDetails,
                  orderItems: [
                        {
                              type: "TSHIRT",
                              size: size,
                              colour: colour
                        }
                  ]
            }).then(function (response) {
                  let newOrder = response.data;
                  setOrderId(newOrder.id);
                  setPage("payment");
            }).catch(function (error) {
                  console.log(error);
                  console.log("failed to make t shirt req")
            });
      }

      const requestAddTShirtToBasket = async () => {
            setLoading(true);
            addTShirtToBasket();

      }

      const addTShirtToBasket = async () => {

            let res = await shopService.addItemToBasket("TSHIRT", size, colour, 1, null);
            navigate("/shop/basket/added");

      }

      return (
            <React.Fragment>
                  <div className="zen-page-holder">
                        <div className="logoUpperLeft">
                              <div className="zenCLogo">

                              </div>
                        </div>
                        {/* 
                  <div style={{ display: "flex", flexDirection: "row", margin:"auto"}}> */}
                        <div className="shop-back-arrow" onClick={() => { page != "main" ? setPage("main") : navigate(-1) }}></div>
                        <Link to="/shop">
                              <h1 className="zen-sign-up-title okashi centered-text" style={{ color: "#033f63", top: "0", marginTop: "25px" }}>
                                    <span>
                                          Z
                                    </span>
                                    <span style={{ color: "#B5B682" }}>
                                          E
                                    </span>
                                    <span style={{ color: "#28666E" }}>
                                          N
                                    </span>
                                    <span style={{ color: "#D1603D" }}>
                                          0
                                    </span>
                                    <span className=''>
                                          S
                                    </span>
                                    <span style={{ color: "#B5B682" }}>
                                          H
                                    </span>
                                    <span style={{ color: "#28666E" }}>
                                          O
                                    </span>
                                    <span >
                                          P
                                    </span>
                                    {/* {page == "tshirts" ?
                                    <span className="navajoTextCol"> (T-shirts)</span>
                                    : page == "retreats" ?
                                          <span className="navajoTextCol"> (Retreats)</span>
                                          : null} */}
                              </h1>
                        </Link>
                        {page == "main" ?
                              <div className="splashedBacking zen-shop-items-container" style={{ height: "100vh", width: "100vw" }}>

                                    <div className="tshirtModalHolder">
                                          <div style={{ margin: "auto", width: "250px" }}>
                                                {colour == "grey" ?
                                                      <div className="t-shirt-checkout-pic  tShirt-LargeP greyT"></div>
                                                      : colour == "blue" ?
                                                            <div className="t-shirt-checkout-pic tShirt-LargeP blueT"></div>
                                                            : colour == "white" ?
                                                                  <div className="t-shirt-checkout-pic  tShirt-LargeP whiteT"></div>
                                                                  :
                                                                  <div className="t-shirt-checkout-pic  tShirt-LargeP orangeT"></div>}
                                          </div>
                                          <div style={{ margin: "auto", width: "325px" }}>
                                                <div>
                                                      <h3>Choose Colour:</h3>
                                                      <div className="t-shirt-color-options">
                                                            <div className="t-shirt-color-option" onClick={() => setColour("grey")}>
                                                                  <div className={colour == "grey" ? "t-shirt-color-example t-shirt-color-example-chosen grey-color-example" : "t-shirt-color-example grey-color-example"}></div>
                                                                  <div className="t-shirt-color-name">Grey</div>
                                                            </div>
                                                            <div className="t-shirt-color-option" onClick={() => setColour("blue")}>
                                                                  <div className={colour == "blue" ? "t-shirt-color-example t-shirt-color-example-chosen toms-color-example" : "t-shirt-color-example toms-color-example"}></div>
                                                                  <div className="t-shirt-color-name">Blue</div>
                                                            </div>
                                                            <div className="t-shirt-color-option" onClick={() => setColour("orange")}>
                                                                  <div className={colour == "orange" ? " t-shirt-color-example t-shirt-color-example-chosen budd-color-example" : "t-shirt-color-example budd-color-example"}></div>
                                                                  <div className="t-shirt-color-name">Orange</div>
                                                            </div>
                                                            <div className="t-shirt-color-option" onClick={() => setColour("white")}>
                                                                  <div className={colour == "white" ? "t-shirt-color-example t-shirt-color-example-chosen white-color-example" : "t-shirt-color-example white-color-example"}></div>
                                                                  <div className="t-shirt-color-name">White</div>
                                                            </div>
                                                      </div>
                                                </div>
                                                <div>
                                                      <h3>Choose Size:</h3>
                                                      <div className="t-shirt-color-options">

                                                            <div className={size == "small" ? "t-shirt-size-name t-shirt-size-name-chosen" : "t-shirt-size-name"} onClick={() => setSize("small")}>Small</div>

                                                            <div className={size == "medium" ? "t-shirt-size-name t-shirt-size-name-chosen" : "t-shirt-size-name"} onClick={() => setSize("medium")}>Medium</div>

                                                            <div className={size == "large" ? "t-shirt-size-name t-shirt-size-name-chosen" : "t-shirt-size-name"} onClick={() => setSize("large")}>Large</div>

                                                      </div>
                                                      <div className="t-shirt-color-options">

                                                            <div className={size == "x-large" ? "t-shirt-size-name t-shirt-size-name-chosen" : "t-shirt-size-name"} onClick={() => setSize("x-large")}>X-Large</div>

                                                            <div className={size == "xx-large" ? "t-shirt-size-name t-shirt-size-name-chosen" : "t-shirt-size-name"} onClick={() => setSize("xx-large")}>2XL</div>

                                                            <div className={size == "xxx-large" ? "t-shirt-size-name t-shirt-size-name-chosen" : "t-shirt-size-name"} onClick={() => setSize("xxx-large")}>3XL</div>

                                                      </div>
                                                </div>

                                          </div>
                                    </div>
                                    <div style={{ margin: "auto", display: "flex", flexDirection: "row", marginTop: "25px", maxWidth: "250px" }}>
                                          {auth.username != null && auth.username.length > 0 && !loading ?
                                                <div className="zenGlowButton mingGlowButton" onClick={() => requestAddTShirtToBasket()}>

                                                      <React.Fragment>Add to basket</React.Fragment>

                                                </div>
                                                : loading ?
                                                      <div className="zenGlowButton mingGlowButton" onClick={() => requestAddTShirtToBasket()}>
                                                            <PuffLoader color="#033f63" size={15}>
                                                            </PuffLoader>

                                                      </div>
                                                      : null
                                          }

                                          <div className="zenGlowButton sageGlowButton" onClick={() => setPage("delivery-details")}>
                                                Buy now
                                          </div>
                                    </div>
                                    {!(auth.username != null && auth.username.length > 0 && !loading) ?
                                          <Link to = "/signUp"><h4  style={{ margin:"auto", textAlign:"center"}}>
                                                Sign Up to create basket
                                          </h4> </Link>
                                          : null}
                              </div>
                              : page == "delivery-details" ?
                                    <div className="splashedBacking zen-shop-items-container" style={{ height: "100vh", width: "100vw", textAlign: "center" }}>
                                          <DeliveryDetailsModal onComplete={(na, em, deliveryD) => createTShirtRequest(na, em, deliveryD)} />
                                    </div>
                                    : page == "payment" ?

                                          <div className="splashedBacking zen-shop-items-container" style={{ height: "100vh", width: "100vw", textAlign: "center" }}>
                                                <TShirtPayment orderId={orderId} orderDetails={{
                                                      size: size,
                                                      colour: colour
                                                }} />
                                          </div>
                                          : null}
                  </div>
            </React.Fragment>
      );
}
