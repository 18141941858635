//Powers

export enum PortalTimeBoostPowers {
  Power = "PORTAL_TIME_BOOST_POWER",
  DisplayName = "Portal Time Boosts"
}

export enum PortalInventorySizeBoostPowers {
  Power = "INVENTORY_SIZE_BOOST_POWER",
  DisplayName = "Portal Inventory Size Boosts"
}

//Milestones
export enum PracticesCompletedMilestone {
  Milestone = "TOTAL_MEDS_MILESTONE",
  DisplayName = "Total Meditations Milestones"
}

export enum MindfulMinutesMilestone {
  Milestone = "MEDITATED_MINS_MILESTONE",
  DisplayName = "Mindful Minutes Milestones"
}

export enum ZenStreakMilestone {
  Milestone = "ZEN_STREAK_MILESTONE",
  DisplayName = "Zen Streak Milestones"
}

export enum CourseProgressMilestone {
  Milestone = "INVENTORY_SIZE_BOOST_POWER",
  DisplayName = "Portal Inventory Size Boosts"
}

export enum ZenJourneyMilestone {
  Milestone = "ZEN_JOURNEY_MILESTONE",
  DisplayName = "Zen Journey Milestones"
}

export enum ExplorationMilestone {
  Milestone = "EXPLORATION_MILESTONE",
  DisplayName = "Exploration Milestones"
}

export enum SocialMilestones {
  Milestone = "SOCIAL_MILESTONE",
  DisplayName = "Social Milestones"
}

export enum ZenSubscriptions {
  Key = "ZEN_C_SUBSCRIPTION",
  DisplayName = "Subscription Cards"
}

export const getAllPowerCodes = () => {
  let codes = [];
  codes.push(PortalTimeBoostPowers.Power);
  codes.push(PortalInventorySizeBoostPowers.Power);
  codes.push(PracticesCompletedMilestone.Milestone);
  codes.push(ZenStreakMilestone.Milestone);
  return codes;
}
