import * as React from "react";
import '../../../styling/common/index.scss';
import { useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { ZencTitle } from "../ZencTitle";
import { SignUp } from "./SignUp";
import { ChooseProfiePick } from "./ChooseProfiePick";
import { ValidateSignUp } from "../ValidateSignUp";
import { ZenStatementsQuiz } from "../../common/ZenStatementsQuiz";
import { ZenStatementsQuizTest } from "../../common/ZenStatementsQuizTest";
import MetaTags from 'react-meta-tags';
import { MyZenProfile } from "../../myzen/profile/MyZenProfile";
import { ChooseAccountsToFollow } from "./ChooseAccountsToFollow";
import { ProvideMyCommunityContext } from "../../../hooks/MyCommunityContext";
import PrivateRoute from "../../common/PrivateRoute";

export const ZenSignUp = (props: any) => {

  const { referrer } = useParams();

  const signUpRef: any = React.createRef();
  const [pageNum, setPageNum] = useState(1);

  const nextPage = () => {
    setPageNum(pageNum + 1);
  }

  return (

    <div className="ZenSignUp">

      <MetaTags>
        <meta id="meta-description" name="description" content={"Create an account and find out what all of the fuss is about."} />
        <meta id="og-title" property="og:title" content="Sign Up - Zencyclopedia" />
      </MetaTags>
      {pageNum == 1 ?
        <div className="zen-su-title-row su-title-row">
          <ZencTitle />
        </div>
        : <></>
      }
      <div className="signUpHolder in-view" ref={signUpRef}>
        {pageNum == 1 ? <SignUp nextPage={() => nextPage()} lang={props.lang} />
          : pageNum == 2 ? <ValidateSignUp next={() => nextPage()} />
            : pageNum == 3 ? <ChooseProfiePick next={() => nextPage()} />
            : pageNum == 4 ? <PrivateRoute><ProvideMyCommunityContext><ChooseAccountsToFollow next={() => nextPage()} /></ProvideMyCommunityContext></PrivateRoute>
                  : <MyZenProfile />
        }

      </div>
    </div>

  );
}




