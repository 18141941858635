import * as React from "react";
import '../../styling/common/index.scss';
import { ZenNavBar } from "../common/navbar/ZenNavBar";
import { ZencTitle } from "./ZencTitle";
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom";

const BringingZenIntoTwentyFirst = () => {
  return (<h2 className="okashi" style={{ paddingBottom: "0", marginBottom: "0" }}>
    <span style={{ fontSize: "200%" }}>Bringing</span><br />
    <span style={{ fontSize: "335%", paddingBottom: "0", marginBottom: "0", position: "relative", top: "-10px" }}>
      <span style={{ color: "#033f63" }}>
        Z
      </span>
      <span style={{ color: "#D1603D" }}>
        E
      </span>
      <span style={{ color: "#28666E" }} >
        N
      </span></span> <br />
    <span style={{ fontSize: "75%", position: "relative", top: "-30px" }}> into the <span className="zenK">21</span>st century</span>
  </h2>
  );
}

const HarnessingPower = () => {
  return (<React.Fragment><h4 className="justifiedText" style={{ paddingTop: "0", marginTop: "0" }}>We harness the power of data-driven techniques to craft predictive algorithms that are adapted to our users’ needs - in order to make sure you are shown the content you are interested in, exactly when you need it.</h4>
    <h4 className="justifiedText">But that's not all — we're pushing the boundaries of meditation technology by embracing immersive VR escapes and enlisting the assistance of AI meditation guides. Prepare to transcend the ordinary and explore the extraordinary depths of inner peace.</h4>
  </React.Fragment>
  );
}

const ZenFeedIntro = () => {
  return (<React.Fragment>
    <h3 >Zen Feed</h3>
    <h4 className="justifiedText" style={{ fontSize: "110%" }}>At the centre of our platform is a social media style feed delivering an uplifting blend of wholesome good news, inspiring quotes, and hand-picked recommendations from our vast collection of meditations and mindfulness courses.</h4>
    <h4 className="justifiedText" style={{ fontSize: "110%" }}>Immerse yourself in a curated collection of content carefully designed to awaken your mindfulness. Within our stream, you'll encounter a wide range of content designed to elevate your well-being and making your time using Zencyclopedia more fun!</h4>
  </React.Fragment>
  );
}

const ZenFeedContent = () => {
  return (<div style={{ padding: "25px" }}>
    <h3>📰 Good News Articles: </h3>
    <h4 className="justifiedText">In a world often dominated by negative news, Zencyclopedia strives to uplift your spirits by highlighting heartwarming and uplifting stories. Dive into a refreshing stream of good news articles that celebrate the triumphs of humanity. From inspiring tales of communities coming together to support a common cause, to remarkable individuals making a positive impact, these stories will reignite your faith in the goodness that exists all around us.</h4>
    <h3>💫 Inspirational Quotes: </h3>
    <h4 className="justifiedText">Discover a treasure trove of wisdom through our collection of inspirational quotes. With each scroll, you'll encounter timeless words of renowned thinkers, spiritual leaders, and everyday philosophers. Let these powerful quotes resonate within you, guiding you on a path of self-reflection, motivation, and personal growth. From gentle reminders to bold affirmations, these words of wisdom will inspire you to unlock your full potential and embrace the beauty of life.</h4>
    <h3>🧘 Guided Meditations: </h3>
    <h4 className="justifiedText">Take a moment for yourself as you indulge in our guided meditations, designed to transport you to a state of tranquility and inner harmony. Explore a diverse array of mindfulness practices, visualization techniques, and breathing exercises, all expertly crafted to help you find peace amidst the chaos of daily life. Each session is accompanied by soothing audio, allowing you to immerse yourself fully in the present moment and cultivate a deeper connection with your inner self.</h4>
    <h4 className="justifiedText">As you navigate through our stream, feel free to engage with the content that resonates with you. Like, share, and spread positivity among our vibrant community of like-minded individuals on the same journey of self-discovery and personal growth.</h4>
    <h4 className="justifiedText">We believe in the power of collective growth. As an experienced user, your contributions are not only cherished, but also rewarded. Join us in creating a thriving ecosystem of inspiration, connection, and continuous growth by contributing to a fresh flow of new content everyday.</h4>
  </div>
  );
}

const ZenscapesPartOne = () => {
  return (<React.Fragment>
    <h3 className="justifiedText">Elevate your meditation practice with our unique ZENscapes, designed to immerse yourself in a world of possibilities and elevate your journey as you explore an array of transformative tools.</h3>
  </React.Fragment>
  );
}

const ZenscapesPartTwo = () => {
  return (<React.Fragment>
    <h3 className="justifiedText">Choose from breathing aids, meditation timers, sound portals, as well as dynamic meditation voice assistants that repsond to your progress and responses, and our soon to be releases Virtual Reality (VR) immersions.</h3>
  </React.Fragment>
  );
}

const ZenBotAbout = () => {
  return (<React.Fragment>

    <h3 className="justifiedText">Our state of the art AI powered meditation assistant, Zen Bot will give you advice on your meditation practice, help you navigate and understand our site, and answer anything you ever wanted to know about ZEN!
    </h3>
  </React.Fragment>
  );
}

const ZencyclopediaArchiveAbout = () => {
  return (<React.Fragment>
    <h3 className="justifiedText">
      At the foundations of our site is the Zencyclopedia - an online catalogue of free information on anything you ever wanted to know about Mindfulness. We're determined to build it into the largest library of free Zen material on the internet.
    </h3>
  </React.Fragment>
  );
}

{/* <h3 className="justifiedText">Supplement your practice with one of our ZENscapes which can be used to layer your practice with ZEN goodness. </h3>
        */}

export const AboutUs = (props: any) => {

  return (
    <div >
      {/* <MetaTags>
        <meta id="meta-description" name="description" content={"Learn all about what our platform has to offer, the people behind it and our ambitous mission to empower people to take control of their mental health by offering free mindfulness resources."} />
        <meta id="og-title" property="og:title" content="About Us - Zencyclopedia" />
      </MetaTags>
      <ZenNavBar home={true} /> */}
      <ZencTitle />


      {/* <h3 className="justifiedText">You can learn more about our roots and the people behind the project here.</h3> */}

      <div className="zen-mobile" style={{ marginBottom: "150px" }}>
        <h3 className="justifiedText">We're on a mission to offer free & accessible meditation training, empowering people to take control of their mental well-being one breath at a time!</h3>

        <div className="AboutSectionTwo">

          <BringingZenIntoTwentyFirst />

          <div style={{ padding: "25px", paddingTop: "0", marginTop: "0", paddingBottom: "0" }}>
            <HarnessingPower />
          </div>
        </div>

        <h2 className="okashi" style={{ marginBottom: "0", marginTop: "20px" }}>App Features</h2>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ maxWidth: "50vw", paddingLeft: "25px", paddingRight: "20px", paddingTop: "10px" }}>
            <ZenFeedIntro />
          </div>
          <div className="zenStreamExampleImage"></div>
        </div>
        <ZenFeedContent />

        <h2 className="okashi zenSubHeadered">ZENscapes</h2>
        <h3 className="zenSubHeader">(Virtual immersive escapes)</h3>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className="nordiiImage"></div>
          <ZenscapesPartOne />
        </div>
        <ZenscapesPartTwo />
        <h2 className="okashi">Zen Bot</h2>
        <ZenBotAbout />
        <div className="zenBotImage"></div>
        <Link to="/zenbot/about"><h3>
          Learn more here including how we have trained our AI model in the mysterious art of ZEN!
        </h3>
        </Link>
        <h2 className="okashi">The Zencyclopedia</h2>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <ZencyclopediaArchiveAbout />
          <div className="informationExample"></div>
        </div>


        {/* <h2>The Mindfulnest</h2>
      <h3 className="zenSubHeader">(Zen Communities)</h3> */}
        {/* <div style={{ marginBottom: "100px" }}>
          <h3>Zenergy points, Zen Cards</h3>
        </div> */}
        <Link to = "/signUp">
        <div className = "zenGlowButton mingGlowButton giantHomeButton">
          Sign Up Now - It's Free!
        </div>
        </Link>

      </div>

      <div className="zen-desktop" style={{ marginBottom: "150px" }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ width: "70%", margin: "auto", marginTop: "0", paddingTop: "0", paddingLeft: "75px", paddingRight: "75px" }}>
            <h3 className="justifiedText">We're on a mission to offer free & accessible meditation training, empowering people to take control of their mental well-being one breath at a time!</h3>

            <div className="AboutSectionTwo" style={{ display: "flex", flexDirection: "row", margin: "auto", marginTop: "0", paddingTop: "0", width: "700px" }}>

              <BringingZenIntoTwentyFirst />

              <div style={{ padding: "25px", paddingTop: "15px", marginTop: "0", paddingBottom: "0" }}>
                <HarnessingPower />
              </div>
            </div>


            <h2 className="okashi" style={{ marginBottom: "0", marginTop: "20px" }}>App Features</h2>

            <ZenFeedIntro />

            <ZenFeedContent />

          </div>




          <div className="zenStreamExampleImageLarge"></div>
        </div>
        <div style={{ width: "90%", margin: "auto" }}>
          <h2 className="okashi zenSubHeadered">ZENscapes</h2>
          <h3 className="zenSubHeader">(Virtual immersive escapes)</h3>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="nordiiImage nordiiDesktopAbout"></div>
            <div>
              <ZenscapesPartOne />
              <ZenscapesPartTwo />
            </div>
          </div>

          <h2 className="okashi">Zen Bot</h2>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <div>
              <ZenBotAbout />
              <Link to="/zenbot/about">
                <div className="smallTextLink">
                  Learn more <b>here</b> including how we have trained our AI model in the mysterious art of ZEN!
                </div>
              </Link>
            </div>
            <div className="zenBotImage" style={{ marginTop: "-25px" }}></div>
          </div>



          <div style={{ display: "flex", flexDirection: "row", marginTop: "35px" }}>
            <div className="informationExample desktopInfoExample"></div>
            <div >
              <h2 className="okashi">The Zencyclopedia</h2>
              <ZencyclopediaArchiveAbout />
        <Link to = "/signUp">
        <div className = "zenGlowButton mingGlowButton giantHomeButtonDesktop">
          Sign Up Now - It's Free!
        </div>
        </Link>  
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}