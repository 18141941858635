import * as React from "react";
import "../../../styling/explore/explore.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate, useParams } from "react-router";
import { ZenNavBar } from "../../common/navbar/ZenNavBar";
import { MyZenNavBar } from "../../common/navbar/MyZenNavBar";
import { useZenCardsService } from "../../../hooks/ZenCardsContext";
import { ZenCardPreview } from "./ZenCardPreview";
import { UnlockZenCardModal } from "./UnlockZenCardModal";
import { ConfirmTranslation } from "../../../content/SignUpTranslations";
import { determineUserOwnsPortal } from "./zenCardsService";
import { PuffLoader } from "react-spinners";
import { cardIsMilestone, cardIsPower, checkCardExpiry, getCardSubGroups } from "../../../service/ZenCardsService";
import { PortalInventorySizeBoostPowers, PortalTimeBoostPowers } from "./CardSubtypes";
import { Link } from "react-router-dom";
import { useZenGoals } from "../../../hooks/ZenGoalsContext";
import { useMyZenService } from "../../../hooks/ZenUserContext";
import { useZenQuotasService } from "../../../hooks/ZenQuotasContext";
import { useAuth } from "../../../hooks/auth";
import { ZenLoader } from "../../common/ZenLoader";

export const ZenCardsBrowser = () => {

    let { searchRoute } = useParams();

    const [page, setPage] = React.useState(searchRoute == "mycards" ? "mycards" : searchRoute == "powers" ? "powers" : searchRoute == "milestones" ? "milestones" : "explore");
    const [searchFilter, setSearchFilter] = React.useState(null);
    const [subpage, setSubPage] = React.useState(null);
    const [confirmIcon, setConfirmIcon] = React.useState(null);
    const [showPowers, setShowPowers] = React.useState(true);
    const [showMilestones, setShowMilestones] = React.useState(true);
    const [allowInactiveUserCards, setAllowInactiveUserCards] = React.useState(true);
    const [zenAllowances, setZenAllowances]  = React.useState(null);

    let zenCardsService = useZenCardsService();
    let myZen = useMyZenService();

    let search = searchFilter != null ? searchFilter
        : searchRoute != null ? searchRoute
            : null;

    if (search == "mycards" && !zenCardsService.searchPending && zenCardsService.resultType != "all") {
        zenCardsService.getZenCards();
    } else if (search != null && !zenCardsService.searchPending && zenCardsService.resultType != search) {
        zenCardsService.filterZenCards(search);
    } else if (search == null && !zenCardsService.searchPending && zenCardsService.resultType != "all") {
        zenCardsService.getZenCards();
    }
    
    let auth = useAuth();
    let quotasContext = useZenQuotasService();

    // React.useEffect(() => {
    //     getUserAllowances();
    // },[]);
    
    const cardsResults = !(zenCardsService.zenCardsResults && zenCardsService.zenCardsResults.length > 0) ?
        null
        : page == "mycards" && zenCardsService.userIcons != null ?
            zenCardsService.zenCardsResults
                .filter(i => zenCardsService.userIcons.map(ui => ui.card).includes(i.cardKey))
                .filter(crd => {

                    //Check for power filter
                    let powersAllow = !showPowers ? !cardIsPower(crd) : true;

                    //check for milestones filter
                    let milestoneAllow = !showMilestones ? !cardIsMilestone(crd) : true;

                    //check for inactive filter:
                    // let userIconItemL = zenCardsService.userIcons.filter(ui => ui.card == crd.cardKey)
                    // let userIconItem = userIconItemL? userIconItemL[0] : null;
                    // let inactiveAllow = allowInactiveUserCards ? true : checkCardExpiry(userIconItem);

                    return powersAllow && milestoneAllow;// && inactiveAllow;
                })
            : zenCardsService.zenCardsResults
                .filter(crd => {

                    //Check for power filter
                    let powersAllow = !showPowers ? !cardIsPower(crd) : true;

                    //check for milestones filter
                    let milestoneAllow = !showMilestones ? !cardIsMilestone(crd) : true;

                    return powersAllow && milestoneAllow;
                });

    let subTier = quotasContext && quotasContext.zenQuotasResults? quotasContext.zenQuotasResults.subscriptionLevel : "FREE";

    let cardsJSX = cardsResults != null && cardsResults.length > 0 ? getCardSubGroups(cardsResults, setConfirmIcon, subTier) : null;

    return (
        <div className='zen-page-holder'>
            <ZenNavBar />
            <MyZenNavBar />
            <div className='zen-icons-container'>
                {confirmIcon ?
                    <div className='zen-icon-unlock-holder'>
                        <UnlockZenCardModal {...confirmIcon}/>
                    </div>
                    : null}
                <div>
                    <div>
                        <div style={{ display: "flex", flexDirection: "row", paddingBottom: "0", margin: "auto", marginBottom: "15px" }}>
                            <h1 className={page == 'mycards' ? "subPageNavHeader subPageActiveNavHeader" : "subPageNavHeader"} style={{ margin: "auto" }} onClick={() => setPage("mycards")}>
                                My Cards
                            </h1>
                            <h1 className={page == 'explore' ? "subPageNavHeader subPageActiveNavHeader" : "subPageNavHeader"} style={{ margin: "auto" }} onClick={() => setPage("explore")}>
                                Explore
                            </h1>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", paddingBottom: "0", margin: "auto" }}>
                
                            <div className={showMilestones ? "milestoneCardsFilterButton zenCardsFilterButton" : "milestoneCardsFilterButton zenCardsFilterButton inactiveZenCardsFilter"} onClick={() => setShowMilestones(!showMilestones)}>
                                Milestones
                            </div>            
                            <div className={showPowers ? "powerCardsFilterButton zenCardsFilterButton" : "powerCardsFilterButton zenCardsFilterButton inactiveZenCardsFilter"} onClick={() => setShowPowers(!showPowers)}>
                                Powers
                            </div>            
                            <div className={showPowers ? "subscriptionCardsFilterButton zenCardsFilterButton" : "subscriptionCardsFilterButton zenCardsFilterButton inactiveZenCardsFilter"} onClick={() => setShowPowers(!showPowers)}>
                                Subscriptions
                            </div>

                   
                        </div>

                        <div className="zen-icons-explore-container">
                            <div className="zen-icons-holder">{
                                cardsJSX && cardsJSX.length > 0 ?
                                    <React.Fragment>
                                        {cardsJSX}
                                    {page != "mycards" ?
                                    <div  style = {{margin:"auto", display:"flex", flexDirection:"row"}}>
                                        <Link to={"/zenergy/purchase"}>
                                        <div className="zenGlowButton goldGlowButton" style = {{margin:"auto"}}>Buy ZENergy points</div>
                                        </Link>
                                        <Link to={"/explore/subscriptions"}>
                                        <div className="zenGlowButton sageGlowButton" style = {{margin:"auto"}}>Upgrade!</div>
                                        </Link>
                                    </div>:null
                                        }
                                        </React.Fragment>  :
                                    zenCardsService.searchPending ?
                                    <div style = {{margin:"auto", marginTop:"0"}}>
                                        <ZenLoader />
                                    </div>
                                        : <div className="centered-text">No Cards!</div>
                            }
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div >
    );

}

