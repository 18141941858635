import * as React from "react";
import "../../../styling/explore/explore.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

export const ZencyclopaediaMainMenuItemPreview = (props : {active?: boolean, title:string, image:any, photoBottom?:boolean, itemType?:string, translation?:string, itemResultId?:string, searchRoute?:string}) => {


    const sliderRef:any = React.createRef();

    const contentsItemLink = props.itemType && props.itemType == 'Infomatic' ?
    "/info/"
    : props.itemType && props.itemType == 'ZencycResultItem' ?
    "/search/"+props.itemResultId
    :
    props.itemType && props.itemType == 'zenShop' ?
    "/shop"
    :
    props.itemType && props.itemType == 'medBrowser' ?
    "/search/2"
    :
    props.itemType && props.itemType == 'zenverse' ?
    "/zenverse"
    : props.searchRoute ? props.searchRoute
    :
    "";
    //ZencycResultItem
    //practitioner(s)/school/community
    //zenverse
    //meditation browser
    //zencyclopaedia
    //course browser

    return (
        <Link to={contentsItemLink}>
            <div className = {props.active? 'zencyclopaedia-main-menu-item-holder' : 'zencyclopaedia-main-menu-item-holder inactive-item'}>
                
                <h1 className="zencyc-contents-item-header-large zenK"><b>{props.title}</b> <span className = 'zencyc-contents-item-header-trans'>{props.translation}</span></h1>
                <img className = "zencyc-main-menu-item-image" src={props.image? props.image : null} ></img>
          
            </div>
    </Link> 

    );
  
}

