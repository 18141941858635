import * as React from "react";
import { MyZenNavBar } from "../../common/navbar/MyZenNavBar";
import "../../../styling/common/index.scss";
import { ZenNavBar } from "../../common/navbar/ZenNavBar";
import { useState } from "react";
import axios from "axios";
import { useAuth } from "../../../hooks/auth";
import PrivateRoute from "../../common/PrivateRoute";
import { useParams } from "react-router-dom";
import { ZEN_C_ENDPOINT } from "../../../config/EnvironmentalVariables";
import PractitionerInterface from "../PractitionerInterface";
import ZenContentInterface from "../../zencontent/practices/ZenContentInterface";
import { getContentListPreviews } from "../../../service/ContentService";
import { ZenLoader } from "../../common/ZenLoader";

export const ZenImageBrowser = (props: { confirmChoice:any }) => {

      const [images, setImages] = useState(null);
      const [chosenImage, setChosenImage] = useState(null);

      const loadImagePage = (pageNum:number) => {
            axios.get(ZEN_C_ENDPOINT + `/zen/zenImages/getImagePage/` + pageNum)
            .then(res => {
                  setImages(res.data);
            }).catch(() => {
            })
      } 

      React.useEffect(() => {
            loadImagePage(1);
      },[]);

      return (

            <div >
                  <h4 style = {{textAlign:"center", margin:"auto"}}>Choose an Image from the Zencyclopedia Library</h4>
                  <div style = {{display:"flex", flexDirection:"row", flexWrap:"wrap"}}>
                  {images != null && images.length>0? images.map(image => <div className = "zen-image-option">
                        <img className = {image == chosenImage? "zen-image-option-image zen-image-option-chosen" : "zen-image-option-image"} src = {image} onClick = {() => setChosenImage(image)}></img>
                  </div>)
                  : <ZenLoader/>}
                  </div>

                  
                  {chosenImage != null?
                        <div className="confirmChooseSessionBanner">
                              <div className="chooseSessionBannerButton" onClick={() => props.confirmChoice(chosenImage)}>Choose Image
                              </div>
                        </div>
                        : null}
            </div>
      );

}
