import * as React from "react";
import "../../../styling/explore/explore.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useParams } from "react-router";
import { ZenNavBar } from "../../common/navbar/ZenNavBar";
import { MyZenNavBar } from "../../common/navbar/MyZenNavBar";
import { useZenCardsService } from "../../../hooks/ZenCardsContext";
import { useMyZenService } from "../../../hooks/ZenUserContext";
import { useAuth } from "../../../hooks/auth";
import { MyZenCommunity } from "./MyZenCommunity";
import { ZenCommunityReccomendationsBrowser } from "./ZenCommunityReccomendationsBrowser";

export const ZenCommunitiesBrowser = () => {

    let { searchRoute } = useParams();
    
    const [page, setPage] = React.useState(searchRoute == "mycommunity" ? "mycommunity" : "explore");
    const [searchFilter, setSearchFilter] = React.useState(null);
    const [subpage, setSubPage] = React.useState(null);

    let auth = useAuth();
    
    console.log("Page: "+page);


    return (
        <div className='zen-page-holder'>
            <ZenNavBar />
            <MyZenNavBar />
            <div className='zen-icons-container'>
                <div>
                        <div style={{ display: "flex", flexDirection: "row", paddingBottom: "0", margin: "auto", marginBottom: "15px" }}>
                            <h1 className={page == 'mycommunity' ? "subPageNavHeader subPageActiveNavHeader" : "subPageNavHeader"} style={{ margin: "auto" }} onClick={() => {
                                console.log("loading my zen community");
                                setPage("mycommunity");
                                console.log("set page: "+"mycommunity");
                            }
                            }>
                                My Community
                            </h1>
                            <h1 className={page == 'explore' ? "subPageNavHeader subPageActiveNavHeader" : "subPageNavHeader"} style={{ margin: "auto" }} onClick={() => setPage("explore")}>
                                Explore
                            </h1>
                        </div>
                        {page=="mycommunity"?
                        <MyZenCommunity
                        />
                        :<ZenCommunityReccomendationsBrowser></ZenCommunityReccomendationsBrowser>
                        }

                </div>
            </div>
        </div >
    );

}

