// import React from "react";
// import Zenscape from "../components/Zenverse/interfaces/ZenscapeInterface";
// import { NordiiPortal } from "../components/Zenverse/ZenscapePortal/NordiiPortal";
// import { OhmBoard } from "../components/Zenverse/ZenscapePortal/OhmBoard/OhmBoard";
// import OhmSoundInterface from "../components/Zenverse/ZenscapePortal/OhmBoard/OhmSoundInterface";
// import { PortalPreview } from "../components/Zenverse/ZenscapePortal/PortalPreview";

import { ContentTypes } from "../components/zencontent/common/ContentTypes";
import OhmSoundInterface from "../components/zenverse/ZenscapePortal/OhmBoard/OhmSoundInterface";

// export function getYouTubeZenscapes() {

//   // RHUBARB

//   // STONE IN FOCUS

//   // THE SHIRE

//   // WINDOW


//   // YOUR HAND IN MINE

//   const bluePic = require("../static/blueRoom.png");
  
//   var blueRoom : Zenscape = {
//     title :"Zen Den Blue",
//     description:"A peace filled and tranquil escape accompannied by soft indie tones.",
//     imagePath :bluePic,
//     video:"https://www.youtube.com/watch?v=K69tbUo3vGs&list=RDCMUC-lTO5rbkJmgHS9AGzQJpsQ&start_radio=1"
//   };
// }

// export function getZenscapeGroups(){
  


// }

// function getPortalJSX(title:string){

//    const getOMHomeJSX = (active:string,setActivePortal:any) => {return <OhmBoard activePortal={active} setActivePortal={(portal) => setActivePortal(portal)}/> };
//    const getNordiiJSX = (active:string,setActivePortal:any) => {return <NordiiPortal/> };

//    return title =='Nordii'? getNordiiJSX : title == 'OmHome' ? getOMHomeJSX : getNordiiJSX;

// }

// export function getStarterZenscapes() {
//   const zenPortals: Zenscape [] = [];
//   const nordiiPic = require("../static/photos/nordii.png");
  
//   var eleganceEdenPortal : Zenscape = {
//     title :"Nordii",
//     description:"graceful elegance. gliding across still waters. avian playtime.",
//     imagePath :nordiiPic,
//     jsx:getPortalJSX("Nordii"),
//     preview:<PortalPreview tagline="Regulate your breath in time with Nordii. " description = "A simple mindfulness exercise for any skill level, simply try to breath in time with the picture"></PortalPreview>
//   };


//   const ohmPic = require("../static/photos/medHead.png");
  
//   var OmHomePortal : Zenscape = {
//     title :"OmHome",
//     description:"graceful elegance. gliding across still waters. avian playtime.",
//     imagePath :ohmPic,
//     jsx:getPortalJSX("OmHome"),
//     preview:<PortalPreview tagline="Some OM For Your Home" description = "elevate your meditations by immersing yourself in tranquil soundscapes with our sound board."></PortalPreview>
//   };



//   const medHeadPic = require("../static/photos/medHead.png");
  
//   var medHeadPortal : Zenscape = {
//     title :"MedHead",
//     description:"graceful elegance. gliding across still waters. avian playtime.",
//     imagePath :medHeadPic,
//     audio:'',
//     video:'',
//     preview:<PortalPreview tagline="Some OM For Your Home" description = "elevate your meditations by immersing yourself in tranquil soundscapes with our sound board."></PortalPreview>
//   };

//   const hobbitHolePic = require("../static/Hobbiton.png");
  
//   var hobbitHolePortal : Zenscape = {
//     title :"Hobbit Hole",
//     description:"graceful elegance. gliding across still waters. avian playtime.",
//     imagePath :hobbitHolePic, 
//     audio:require('../static/zenscapes/LOTR.mp3'),
//     video:require('../static/zenscapes/LOTR.mp4'),
//     preview:<PortalPreview tagline="The Hobbit Hole" description = "elevate your meditations by immersing yourself in tranquil soundscapes with our sound board."></PortalPreview>
//   };



//   const bluePic = require("../static/zenden.png");
  
//   var blueRoom : Zenscape = {
//     title :"Zen Den Blue",
//     description:"A peace filled and tranquil escape accompannied by soft indie tones.",
//     imagePath :bluePic
//   };



//   zenPortals.push(eleganceEdenPortal);
//   zenPortals.push(blueRoom);
//   zenPortals.push(OmHomePortal);
//   zenPortals.push(hobbitHolePortal);


//   return zenPortals;


// }

export function mockGetZenscapes() {
  
      
  const response = [
    {
        "id": 90,
        "details": {
            "id": 90,
            "image":  require("../static/photos/BlueOhm.png"),
            "title": "GongWithTheWind",
            "durationSeconds": 0,
            "contentType": ContentTypes.ZENSCAPE,
            "previewType": "portal",
            "description": "Regulate your breath in time with Nordii."
        },
        "additionalText": "A simple mindfulness exercise for any skill level, simply try to breath in time with the picture",
        "portalType": "STARTER",
        "video": "",
        "videoUrl": "",
        "audio": null,
        "audioUrl": null
    }, {
      "id": 11,
      "details": {
          "id": 11,
          "image":  require("../static/photos/BlueOhm.png"),
          "title": "OmHome",
          "durationSeconds": 0,
          "contentType": ContentTypes.ZENSCAPE,
          "previewType": "portal",
          "description": "Regulate your breath in time with Nordii."
      },
      "additionalText": "A simple mindfulness exercise for any skill level, simply try to breath in time with the picture",
      "portalType": "STARTER",
      "video": "",
      "videoUrl": "",
      "audio": null,
      "audioUrl": null
  },{
      "id": 95,
      "details": {
          "id": 95,
          "image":  require("../static/photos/zen-clock.png"),
          "title": "Simple Timer",
          "durationSeconds": 0,
          "contentType": ContentTypes.ZENSCAPE,
          "previewType": "portal",
          "description": "Regulate your breath in time with Nordii."
      },
      "additionalText": "A simple mindfulness exercise for any skill level, simply try to breath in time with the picture",
      "portalType": "STARTER",
      "video": "",
      "videoUrl": "",
      "audio": null,
      "audioUrl": null
  },{
    "id": 91,
    "details": {
        "id": 91,
        "image":  require("../static/photos/nordii.png"),
        "title": "Nordii",
        "durationSeconds": 0,
        "contentType": ContentTypes.ZENSCAPE,
        "previewType": "portal",
        "description": "Regulate your breath in time with Nordii."
    },
    "additionalText": "A simple mindfulness exercise for any skill level, simply try to breath in time with the picture",
    "portalType": "STARTER",
    "video": "",
    "videoUrl": "",
    "audio": null,
    "audioUrl": null
},{
    "id": 92,
    "details": {
        "id": 92,
        "image":  require("../static/photos/nordii.png"),
        "title": "Bubble Breath",
        "durationSeconds": 0,
        "contentType": ContentTypes.ZENSCAPE,
        "previewType": "portal",
        "description": "Regulate your breath in time with Nordii."
    },
    "additionalText": "A simple mindfulness exercise for any skill level, simply try to breath in time with the picture",
    "portalType": "STARTER",
    "video": "",
    "videoUrl": "",
    "audio": null,
    "audioUrl": null
},{
  "id": 99,
  "details": {
      "id": 93,
      "image":  require("../static/photos/BlueOhm.png"),
      "title": "JUMP",
      "durationSeconds": 0,
      "contentType": ContentTypes.ZENSCAPE,
      "previewType": "portal",
      "description": "Regulate your breath in time with Nordii."
  },
  "additionalText": "A simple mindfulness exercise for any skill level, simply try to breath in time with the picture",
  "portalType": "STARTER",
  "video": "",
  "videoUrl": "https://www.youtube.com/watch?v=LML6SoNE7xE",
  "audio": null,
  "audioUrl": null
},{
  "id": 94,
  "details": {
      "id": 94,
      "image":  require("../static/photos/BlueOhm.png"),
      "title": "Bubble Breath",
      "durationSeconds": 0,
      "contentType": ContentTypes.ZENSCAPE,
      "previewType": "portal",
      "description": "Regulate your breath in time with Nordii."
  },
  "additionalText": "A simple mindfulness exercise for any skill level, simply try to breath in time with the picture",
  "portalType": "STARTER",
  "video": "",
  "videoUrl": "", //"https://www.youtube.com/watch?v=LML6SoNE7xE" as string,
  "audio": null,
  "audioUrl": null
}
  ];
  
    
    return response;
  }

  export function getZenSounds() {
    const sounds: OhmSoundInterface [] = [];
  
    var orangeOhm : OhmSoundInterface = {
      title :"OrangeOhm",
      image : require("../static/photos/OrangeOhm.png"),
      // audio:require('../static/DEMO/mongolian-folk-chant.mp3'),
      // id:'ohm1'
    };
    
    var purpleOhm : OhmSoundInterface = {
      title :"PurpleOhm",
      image : require("../static/photos/PurpleOhm.png"),
      // audio:require('../static/DEMO/ohm-singing.mp3'),
      // id:'ohm2'
    };
  
    var blueOhm : OhmSoundInterface = {
      title :"BlueOhm",
      image : require("../static/photos/BlueOhm.png"),
      // audio:require('../static/DEMO/ohm-3.mp3'),
      // id:'ohm3'
    };

    var ascendOhm : OhmSoundInterface = {
      title :"Ascend",
      image : require("../static/photos/ascend.png"),
    };

    var kathakaku : OhmSoundInterface = {
      title :"kathakaku",
      image : require("../static/photos/cybercitysqaure.png"),
      // audio:require('../static/DEMO/kathakaku.mp3'),
      // id:'ohm5'
    };
      
    // var medheadPortal : OhmSoundInterface = {
    //   title :"ChakraFlows",
    //   image : require("../static/photos/medHead.png"),
    //   audio: require('../static/DEMO/StoneInFocus.mp3'),
    //   id:'Chakra'
    // };

    // var LOTR : OhmSoundInterface = {
    //   title :"LOTR",
    //   image : require("../static/photos/medHead.png"),
    //   audio: require('../static/zenscapes/LOTR.mp3'),
    //   id:'LOTR'
    // };

    sounds.push(orangeOhm);
    sounds.push(blueOhm);
    sounds.push(purpleOhm);
    sounds.push(ascendOhm);
    sounds.push(kathakaku);
    //sounds.push(medheadPortal);
    // sounds.push(LOTR);



    return sounds;
  }