import * as React from "react";
import { Link } from "react-router-dom";
import '../../styling/common/index.scss';
import '../../styling/common/zenCoreUIComponents.scss';
import { ZenPopUpMessage } from "../common/ZenPopUpMessage";
import { ZenHome } from "./ZenHome";

export const PrivacyStatement = (props: any) => {

  return (

    <div className="ming-page">
      <div className="ming-page-text">
        <div style = {{paddingBottom:"150px"}}>
        <h1>Our Privacy Promise:</h1>

        <h3 className = "zen-white-text">We never share your personal data with anyone, and only collect information regarding suggesting you relevant content on the site. None of your responses to our quizes or content feedback will be shared with third parties.</h3>

        <h3 className = "zen-white-text">We're passionate about building a meditation service highly tailored to our users needs using data analytics, we only use data we collect on the site to improve our reccomendations to you, not to sell it off to big tech to let others know about your life!</h3>

        <h3 className = "zen-white-text">Free tier users will recieve ads powered by google, but we do not submit any zencyclopedia user data to any advertising partners.</h3>

        <h3 className = "zen-white-text">Zen Journal entries are stored using encryption so that no one else can have a peek!</h3>
        <Link to="/myzen">
          <div className = "mingGlowButton zenGlowButton">Finished</div>
        </Link>
        </div>
      </div>
    </div>

  );
}