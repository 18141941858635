import React, { useState } from 'react';
import "../../styling/vacana/vacana.scss";
import "../../styling/common/index.scss";
import "../../styling/stream/stream.scss";
import "../../styling/zenverse/Zenverse.scss";
import { Navigate } from 'react-router';
import { useMyStuffService } from '../../hooks/MyStuffContext';
import { useZenJournalService } from '../../hooks/ZenJournalContext';
import axios from 'axios';
import { ZEN_C_ENDPOINT } from '../../config/EnvironmentalVariables';
import { useAuth } from '../../hooks/auth';
import { ZenCardMilestoneNotice } from '../explore/icons/ZenCardMilestoneNotice';

export const PracticeCompletePage = (props:
  {
    id?: any,
    duration?: any,
    practiceZenergyBoost?: number,
    practice?: any
  }) => {

  const [page, setPage] = useState(1);
  const [liked, setLiked] = useState(null);
  const [comment, setComment] = useState(null);
  const [newCards, setNewCards] = useState(null);

  const myStuff = useMyStuffService();

  React.useEffect(() => {
      myStuff.getBookmarks();
      myStuff.getFavourites();
    axios.post(ZEN_C_ENDPOINT + `/myzen/zenergy/logUserPractice`, {
      username: auth.username,
      zenergyPoints: zenergyBoost,
      totalMeditatedMinutes: props.duration
    }).then(res => {
      console.log("Logged Practice.");
      axios.get(ZEN_C_ENDPOINT + `/myzen/milestones/checkPracticeCompleteMilestones/` + auth.username)
        .then(res => {
          if (res.data && res.data.length > 0) {
            console.log(res.data);
            setNewCards(res.data);
          }
        }).catch(() => {

        })
    }
    )
  }, []);



  let auth = useAuth();

  const [favourited, setFavourited] = useState(myStuff.favourites && myStuff.favourites.map(b => b.id).includes(props.id));
  const [bookmarked, setBookmarked] = useState(myStuff.bookmarks && myStuff.bookmarks.map(b => b.id).includes(props.id));

  const journalService = useZenJournalService();

  const toggleFavourited = (val: boolean) => {

    if (val) {
      myStuff.addFavourite(props.id);
    } else {
      myStuff.removeFavourite(props.id);
    }
    setFavourited(val);

  }

  const toggleBookmarked = (val: boolean) => {

    if (val) {
      myStuff.addBookmark(props.id);
    } else {
      myStuff.removeBookmark(props.id);
    }
    setBookmarked(val);

  }

  let zenergyBoost = props.practiceZenergyBoost ? props.practiceZenergyBoost : 1;

  const logPractice = async (mood?: string) => {

    //Log Content Global Stats in Zen Content Back End:
    axios.post(ZEN_C_ENDPOINT + `/zen/content/addView/` + props.id)
      .then(res => {
      })


    //Log Usert Stats in My Zen:
    const payload = mood ? {
      username: auth.username,
      enjoyed: liked,
      completed: true,
      contentId: props.id,
      journalEntry: {
        username: auth.username,
        content: comment,
        mood: {
          emojis: [mood]
        },
        practiceLog: {
          practiceId: props.id,
          enjoyed: liked,
          completed: true
        }
      }
    } : {
      username: auth.username,
      enjoyed: liked,
      completed: true,
      contentId: props.id,
      journalEntry: {
        username: auth.username,
        content: comment,
        practiceLog: {
          practiceId: props.id,
          enjoyed: liked,
          completed: true
        }
      }
    }

    let x = await axios.post(ZEN_C_ENDPOINT + `/myzen/honeycohm/logPractice`, payload)
      .then(res => {
        setPage(4);
      }).catch(() => {
        setPage(4);
      })
  }

  return (
    <React.Fragment>
      {page == 4 && newCards != null ?
      <div className='ZenBasicPage zen-page-holder'>
        <ZenCardMilestoneNotice cards={newCards} />
        </div>
        :
        <div className='ming-page'>

          {page == 1 ?
            <>
              {/* Bookmark & Favourite */}
              <div className='pf-bookmark-holder'>
                <div className={favourited ? "PracticePreviewFavourited" : "PracticePreviewFavourite"} onClick={() => toggleFavourited(!favourited)}></div>
                <div className={bookmarked ? "PracticePreviewBookmarked" : "PracticePreviewBookmark"} onClick={() => toggleBookmarked(!bookmarked)}></div>
              </div>

              <div style={{ marginTop: "55px" }}> {/* Progress */}
                <h3 className="practice-finished-header">Well Done!</h3>
                <div className="finished-practice-details">
                  <h4 className="minutes-acquired">+{props.duration ? props.duration : "2"} mindful minutes</h4>
                  <h4 className="zen-score">+{zenergyBoost} ZENergy!</h4>

                </div>

              </div>

              {/* Thumbs */}
              <h3 className="practice-finished-sub-header">did you enjoy the practice?</h3>
              <div className='thumbs-rating-holder'>
                <img className="thumbs-rating" src={require('../../static/photos/languageIcons/thumbs_up.png')} onClick={() => {
                  setLiked(true);
                  setPage(2);
                }}></img>
                <img className="thumbs-rating" src={require('../../static/photos/languageIcons/thumbs_down.png')} onClick={() => {
                  setPage(2);
                }}></img>
              </div>

              <h4 className='practice-finished-skip' onClick={() => {
                setPage(2);
              }}>skip rating</h4>
            </>
            : page == 2 ?  // Should edit create journal modal in order to facilitate switching of pages (we want mood after comments here)
              <div className="practice-feedback-container">

                <h3 className="practice-finished-header">Practice Log</h3>
                <h3 className="practice-finished-sub-header" style={{ marginTop: "-5px" }}>Document any thoughts on your practice, they will be saved to your Journal!</h3>
                <form noValidate >
                  <textarea
                    placeholder="Comments, Thoughts and Insights go here..."
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    className={'journal-text-input'}
                  />
                </form>
                <div className="zenc-button-row">
                  <div className="zencTextButton zencRedButton" onClick={() => setPage(3)}>
                    skip
                  </div>
                  <div className="zencTextButton zencGreenButton" onClick={() => setPage(3)}>
                    submit
                  </div>
                </div>
              </div>
              : page == 3 ?  // Mood
                <div className="practice-feedback-container">
                  <h3 className="practice-finished-header sage-text">Log Your Mood</h3>
                  <div className="mood-options" style={{ marginTop: "25px;" }}>
                    <div className='mood-option'>
                      <img className='mood-option-image' src={require('../../static/photos/languageIcons/happy.png')} onClick={() => {
                        logPractice("HAPPY");


                      }}></img>
                      {/* Inconsistency between display value & ENUM value leads to excess code duplication */}
                      <h4>Happy & Content</h4>
                    </div>
                    <div className='mood-option'>
                      <img className='mood-option-image' src={require('../../static/photos/languageIcons/tired.png')} style={{ marginTop: "-5px" }} onClick={() => {
                        logPractice("TIRED");


                      }}></img>
                      <h4>Tired</h4>
                    </div>
                    <div className='mood-option'>
                      <img className='mood-option-image' src={require('../../static/photos/languageIcons/anxious.png')} onClick={() => {
                        logPractice("ANXIOUS");


                      }}></img>
                      <h4>Anxious</h4>
                    </div>
                    <div className='mood-option'>
                      <img className='mood-option-image' src={require('../../static/photos/languageIcons/sad.png')} onClick={() => {
                        logPractice("SAD");


                      }}></img>
                      <h4>Sad</h4>
                    </div>
                    <div className='mood-option'>
                      <img className='mood-option-image' src={require('../../static/photos/languageIcons/angry.png')} onClick={() => {
                        logPractice("ANGRY");


                      }}></img>
                      <h4>Angry</h4>
                    </div>
                    <div className='mood-option'>
                      <img className='mood-option-image' src={require('../../static/photos/languageIcons/unsure.png')} onClick={() => {
                        logPractice("UNSURE");


                      }}></img>
                      <h4>Unsure</h4>
                    </div>

                  </div>
                  <h4 className='practice-finished-skip' onClick={() => {
                    logPractice(null);


                  }}>skip </h4>
                </div>
                : page == 4 && newCards != null ?
                  <ZenCardMilestoneNotice cards={newCards} />
                  : <Navigate to="/"></Navigate>

          }

        </div>
      }
    </React.Fragment>

  );




}