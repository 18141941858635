import * as React from "react";
import "../../../styling/common/index.scss";
import "../../../styling/explore/explore.scss";
import { useState } from "react";
import axios from "axios";
import { useAuth } from "../../../hooks/auth";
import { ZEN_C_ENDPOINT } from "../../../config/EnvironmentalVariables";
import { ZenImageBrowser } from "./ZenImageBrowser";
import { MeditationIcons } from "../../zencontent/practices/MeditationIcons";
import ImageDrop from "./ImageDrop";
import BrowseLocalFilesButton from "./BrowseLocalFilesButton";
import { BarLoader } from "react-spinners";
import { PathSyllabusStudio } from "./PathSyllabusStudio";
import { PathIcons } from "../../zencontent/courses/PathIcons";

export const PathStudio = (props: { userId: any, page?: string, setPage?: any }) => {

      const [title, setTitle] = useState(null);
      const [description, setDescription] = useState(null);
      const [stepNum, setStepNum] = useState("details");
      const [imageFile, setImageFile] = useState<File | null>(null);
      const [previewURL, setPreviewURL] = useState<string | null>(null);
      const [mediaFile, setMediaFile] = useState<File | null>(null);
      const [mediaFileType, setMediaFileType] = useState(null);
      const [sessions, setSessions] = useState(null);

      let auth = useAuth();

      const createCourse = () => {
            setStepNum("uploading");
            axios.post(ZEN_C_ENDPOINT + `/zen/paths/create`, {
                        title: title,
                        contentType: "PATH",
                        description: description,
                        practitioner: auth.username,
                        syllabus:sessions
            }).then(function (response) {
                  //console.log(response);
                  console.log("Adding Image: ");
                  let content_id = response.data.id;
                  const formData = new FormData();
                  formData.append("image", imageFile);
                  axios.post(ZEN_C_ENDPOINT + `/zen/content/addPhoto/` + content_id, formData, {
                        headers: {
                              "Content-Type": "multipart/form-data",
                        },
                  }).then(function (response) {
                        
                        setStepNum("success");

                  }).catch(function (error) {
                        setStepNum("success");
                  });



            }).catch(function (error) {
                  console.log(error);
                  setStepNum("success");
            });
      }

      
      const handleFileSelect = (files: FileList | null) => {
            if (files && files.length > 0) {
                  const selectedFile = files[0];
                  if (selectedFile && (selectedFile.type === "image/jpeg" || selectedFile.type === "image/png")) {
                        setImageFile(selectedFile);
                        setMediaFileType(selectedFile.type);
                        setPreviewURL(URL.createObjectURL(selectedFile));
                  }
            }
      };

      const handleMediaFileSelect = (files: FileList | null) => {
            if (files && files.length > 0) {
                  const selectedFile = files[0];
                  if (selectedFile && (selectedFile.type === "video/mp4" || selectedFile.type === "audio/mpeg")) {
                        setMediaFile(selectedFile);
                  }
            }
      };

      const choosePracticeSessions = (chosenSessions:any) => {
            setSessions(chosenSessions);
            setStepNum("confirm");
      }

      return (
            <div style={{ overflowY: "scroll", maxHeight: "100vh" }}>
                  <div style={{ paddingBottom: "125px" }}>

                        {stepNum == "addContent" ?
                              <div style={{ margin: "auto", paddingTop: "125px" }}>
                             
                                          <PathSyllabusStudio 
                                          userId = {props.userId}
                                          cofirmSessions = {(s) => choosePracticeSessions(s)}
                                          />
                                    
                              </div>
                              : stepNum == "uploading" ?
                                    <div style={{ margin: "auto", textAlign: "center", paddingTop: "145px" }}>
                                          <h1>Uploading Content</h1>
                                          <div style={{ width: "fit-content", margin: "auto" }}>
                                                <BarLoader color="#033f63">
                                                </BarLoader>
                                          </div>

                                    </div>
                                    : stepNum == "success" ?
                                          <div style = {{margin:"auto",marginTop:"250px"}}>
                                                <h1 className="okashi">SUCCESS!</h1>
                                                <h3 >The Path has been added to your catalogue!</h3>
                                                <div className="zenGlowButton mingGlowButton" style={{ marginTop: "20px" }} onClick={() => {
                                                      props.setPage("Practitioner")
                                                }}>Finish</div>
                                          </div>
                                          : null}
                        <div className="zen-mobile" >
                              <div style={{ paddingTop: "125px" }}>
                                    <div className='explore-back-icon' style={{ top: "150px" }}
                                          onClick={() => {
                                                props.setPage("MyZen");
                                          }}></div>


                                    {stepNum == "details" ?
                                          <div style={{ overflowY: "scroll" }}>


                                                <input
                                                      type="text"
                                                      placeholder={"Enter a title..."}
                                                      value={title}
                                                      onChange={(e) => setTitle(e.target.value)}
                                                      className={'su-text-input'}
                                                />

                                                {previewURL ?
                                                      <img className="content-preview-image" src={previewURL} ></img>
                                                      :
                                                      <div>
                                                            <h4 style={{ margin: "auto", textAlign: "center", marginTop: "9px", marginBottom: "15px" }}>Choose Cover Image:</h4>
                                                            <div style={{ display: "flex", flexDirection: "row", width: "275px", margin: "auto" }}>
                                                                  <div className="zenGlowButton mingGlowButton">Zen C Library</div>
                                                                  <BrowseLocalFilesButton accept="image/jpeg, image/png" onFileSelect={handleFileSelect} />
                                                            </div>
                                                      </div>}

                                                <textarea
                                                      placeholder="Enter Course Description"
                                                      value={description}
                                                      onChange={(e) => setDescription(e.target.value)}
                                                      className={'practice-text-input'}
                                                />
                                                <div className="zenGlowButton mingGlowButton" style={{ marginTop: "35px", marginBottom: "150px" }} onClick={() => setStepNum("addContent")}>
                                                      Next
                                                </div>
                                          </div>
                                          : stepNum == "image" || stepNum == "upload-image" ?
                                                <React.Fragment>
                                                      <h2 className="okashi" style={{ margin: "auto", textAlign: "center", marginBottom: "5px" }}>Add Practice Image</h2>
                                                      <div style={{ display: "flex", flexDirection: "row" }}>
                                                            <h1 className={stepNum == 'image' ? "subPageNavHeader subPageActiveNavHeader" : "subPageNavHeader"} onClick={() => setStepNum('image')}>Choose</h1>
                                                            <h1 className={stepNum == 'upload-image' ? "subPageNavHeader subPageActiveNavHeader" : "subPageNavHeader"} onClick={() => setStepNum('upload-image')}>Upload</h1>
                                                      </div>
                                                      {stepNum == 'upload-image' ?
                                                            <div className="image-upload-area">

                                                            </div>
                                                            :
                                                            <ZenImageBrowser confirmChoice = {() => {}}/>}
                                                </React.Fragment>
                                                : stepNum == "confirm" ?
                                                      <div>
                                                            <h3 className="okashi" style={{ margin: "auto", textAlign: "center", marginBottom: "35px" }}>Confirm & Upload</h3>

                                                            <div className="lecture-preview practice-create-confirm">

                                                                  <div className="">

                                                                        <div className="practice-title-row">
                                                                              <div className="practice-image-holder-mobile">
                                                                                    <img className="content-preview-image" src={previewURL} ></img>
                                                                              </div>
                                                                              <div>
                                                                                    <h4 className="practice-title okashi">{title}</h4>
                                                                                    {/* <h4 className = "practitioner-name zenK">by {props.practitioner? props.practitioner.name:''}</h4> */}
                                                                              </div>
                                                                        </div>
                                                                        <div className="zen-mobile" style={{ margin: "auto" }}>
                                                                              <MeditationIcons
                                                                                    practitioner={auth.username}
                                                                              ></MeditationIcons>
                                                                        </div>

                                                                  </div>
                                                            </div>
                                                            <div className="zenGlowButton mingGlowButton" onClick={() => createCourse()} style={{ marginTop: "35px", marginBottom:"125px" }}>
                                                                  I'm happy - create the path please!
                                                            </div>
                                                      </div>
                                                      : null
                                    }
                              </div>


                        </div>
                        <div className="zen-desktop" style={{ overflowX: "hidden" }}>
                              <div style={{ paddingTop: "0px" }}>
                                    <div className='explore-back-icon' style={{ top: "150px" }}
                                          onClick={() => {
                                                props.setPage("MyZen");
                                          }}>

                                    </div>
                              </div>
                              {stepNum == "details" ?
                                    <div style={{ paddingTop: "20px" }}>

                                          <div style={{ display: "flex", flexDirection: "row", paddingTop: "45px" }}>

                                                <div className="practice-image-holder" style={{ minWidth: "50w", maxWidth: "50vw" }}>
                                                      <ImageDrop
                                                            endpoint={""}
                                                            setFile={setImageFile}
                                                            setPreviewURL={setPreviewURL}
                                                            file={imageFile}
                                                            previewURL={previewURL}
                                                            handleFileSelect={handleFileSelect}
                                                      />
                                                </div>

                                                <div className="practice-details-section" >
                                                      <input
                                                            type="text"
                                                            placeholder={"Enter a title..."}
                                                            value={title}
                                                            onChange={(e) => setTitle(e.target.value)}
                                                            className={'su-text-input'}
                                                      />

                                                      <textarea
                                                            placeholder="Enter Course Description"
                                                            value={description}
                                                            onChange={(e) => setDescription(e.target.value)}
                                                            className={'create-content-description-input'}
                                                      />
                                                </div>
                                          </div>
                                          <div className="zenGlowButton sageGlowButton" onClick={() => setStepNum("uploadMedia")}>
                                                Next
                                          </div>

                                    </div>
                                    : stepNum == "confirm" ?
                                          <div style={{ margin: "auto", paddingTop: "105px", display: "flex", flexDirection: "row", width: "90vw" }}>
                                                <div style={{ margin: "auto", textAlign: "center" }}>
                                                      <h1 className="okashi" style={{ margin: "auto", textAlign: "center", marginBottom: "35px" }}>Confirm<br></br>&<br></br>Upload</h1>

                                                      <div className="zenGlowButton mingGlowButton" onClick={() => createCourse()}>
                                                            I'm happy - post the content please!
                                                      </div>
                                                </div>
                                                <div className="lecture-preview practice-create-confirm">
                                                      <img className="content-preview-image" src={previewURL} ></img>
                                                      <h2 style={{ margin: "auto", textAlign: "center", marginBottom: "25px" }}><b>Title:</b>&nbsp;{title}</h2>
                                                      <PathIcons
                                                            practitioner={auth.username}
                                                            pathSessionNumber = {sessions.length}
                                                            pathDuration = {"-"}
                                                      ></PathIcons>
                                                </div>
                                          </div>

                                          :
                                          null
                              }

                        </div>
                  </div>
            </div>
      );

}
