

import * as React from "react";
import '../../styling/common/index.scss';
import { useState } from "react";
import axios from "axios";
import { useAuth } from "../../hooks/auth";
import { ZEN_C_ENDPOINT } from "../../config/EnvironmentalVariables";
import Draggable, { DraggableData, DraggableEvent, DraggableEventHandler } from "react-draggable";
import { Link } from "react-router-dom";

export default interface SignUpQuizQuestion {
    value: string,
    id?: number,
    positiveTags?: string[],
    negativeTags?: string[],
    blockedByTags?: string[],
    negativeAffirmTags?: string[],
    occurances?: number,
}

export default interface ZenStatementTag {
    value: string,
    score?: number,
}


export const ZenSliderQuiz = (props: any) => {

    const auth = useAuth();

    const [state, setState] = useState({
        discardedQuestion: null,
        questions: [],
        positiveTags: ['START_QUESTION'],
        negativeTags: [],
        blockedByTags: [],
        negativeAffirmTags: [],
        answers: [],
        rejected: [],
        tagResults: [],
        finished: false,
        visibleQuestions: [],
        toBeRemovedQuestions: [],
        newQuestions: []
    });

    const checkForActivation = (statement: SignUpQuizQuestion, validStatement: SignUpQuizQuestion) => {
        var activate = false;

        if (statement && statement.positiveTags) {
            statement.positiveTags.map((group) => {
                if (state.positiveTags && state.positiveTags.includes(group)) {
                    activate = true;
                }
            });
        }
        return activate;
    }

    const colorClasses = ["", "28666E", "#033f63", "#272727"];

    const rankStatement = (statement: SignUpQuizQuestion) => {
        var rank = 0;

        // Positive Tag Ranking
        if (statement && statement.positiveTags) {
            statement.positiveTags.map((tag) => {
                state.tagResults.filter(tr => tr.value == tag).map(ts => rank = rank + (3 * ts.score));
                // if (state.positiveTags && state.positiveTags.includes(tag)) {
                //     rank++;
                // }
            });
        }

        //Blocked Tag Ranking: 
        if (statement && statement.blockedByTags) {
            statement.blockedByTags.map((tag) => {
                state.tagResults.filter(tr => tr.value == tag).map(ts => rank = rank - (2 * ts.score));
            });
        }
        return rank;
    }


    const sortStatements = (statement: SignUpQuizQuestion, statement2: SignUpQuizQuestion) => {
        return (rankStatement(statement2) - statement2.occurances) - (rankStatement(statement) - statement.occurances); // seems risky
    }

    //highlights visible questions which are about to be removed, green for if it was choisen, red for if it has been blocked.
    const determineNewQuestions = (statement: SignUpQuizQuestion) => {

        var visibleQs = state.questions.filter(q => !state.answers.includes(q) && !state.rejected.includes(q) && statement.id != q.id); //.filter((q) => !checkForGroupClash(q, statement)).filter((q) => checkForActivation(q, statement));
        console.log("rejected Qs so far:");
        console.log(state.rejected);
        // if (visibleQs && visibleQs.length < 5 && state.answers.length < 9) {
        //     var unActivatedButValidQuestions = state.questions.filter((q) => !checkForGroupClash(q, statement)).filter((q) => !checkForActivation(q, statement));
        //     visibleQs.push(...unActivatedButValidQuestions);
        // }

        visibleQs.sort((a, b) => sortStatements(a, b));
        visibleQs = visibleQs.slice(0, 5);

        return visibleQs;
    }

    const chooseStatement = (statement: SignUpQuizQuestion) => {

        var newAnswers = state.answers;
        newAnswers.push(statement);

        // var allResultTags = [...state.tagResults];

        // Add Positive Tags
        if (statement.positiveTags) {
            statement.positiveTags.map((t) => {
                state.positiveTags.push(t);
                var posFound = false;
                state.tagResults.filter(tr => tr.value == t).map(tag => {
                    posFound = true;
                    tag.score = tag.score + 3;
                });
                if (!posFound) {
                    state.tagResults.push({
                        value: t,
                        score: 3
                    });
                }
            });
        }

        // Add Negative Tags
        if (statement.negativeTags) {
            statement.negativeTags.map((t) => {
                state.negativeTags.push(t);
                var negFound = false;
                state.tagResults.filter(tr => tr.value == t).map(tag => {
                    tag.score = tag.score - 2;
                });
                if (!negFound) {
                    state.tagResults.push({
                        value: t,
                        score: -2
                    });
                }
            });
        }

        state.questions.filter(s => state.visibleQuestions.includes(s)).forEach(st => { st.occurances = st.occurances + 1 });

        axios.post(ZEN_C_ENDPOINT + `/myzen/zenStatements/choose/` + auth.username + `/` + statement.id, {}).then(function (response) {
            //console.log(response);
        }).catch(function (error) {
            console.log(error);
        });

        //Highlight chosen question green, highlight any that have been removed with red:
        var visibleQs = determineNewQuestions(statement);
        let questionsToBeRemoved = state.visibleQuestions.filter(vq => !visibleQs.map(nvq => nvq.id).includes(vq.id)).map(qtbr => qtbr.id);

        setState({
            rejected: state.rejected,
            discardedQuestion: null,
            answers: newAnswers,
            questions: state.questions,
            negativeTags: state.negativeTags,
            positiveTags: state.positiveTags,
            blockedByTags: state.blockedByTags,
            negativeAffirmTags: state.negativeAffirmTags,
            finished: false,
            visibleQuestions: state.visibleQuestions,
            toBeRemovedQuestions: questionsToBeRemoved,
            newQuestions: state.newQuestions,
            tagResults: state.tagResults
        });

        // Remove Questions after 750 ms.
        setTimeout(() => {


            setState({
                rejected: state.rejected,
                discardedQuestion: null,
                finished: false,
                answers: newAnswers,
                negativeTags: [...state.negativeTags],
                positiveTags: [...state.positiveTags],
                blockedByTags: state.blockedByTags,
                negativeAffirmTags: state.negativeAffirmTags,
                questions: [...state.questions],
                visibleQuestions: state.visibleQuestions.filter(vq => !questionsToBeRemoved.includes(vq.id)),
                toBeRemovedQuestions: state.toBeRemovedQuestions,
                newQuestions: [],
                tagResults: state.tagResults
            });

            //Add new questions after 250ms, highlight with gold.
            setTimeout(() => {
                let newQuestions = visibleQs.filter(vq => !state.visibleQuestions.map(nvq => nvq.id).includes(vq.id)).map(qtbr => qtbr.id);
                setState({
                    rejected: state.rejected,
                    discardedQuestion: null,
                    finished: false,
                    answers: newAnswers,
                    negativeTags: [...state.negativeTags],
                    positiveTags: [...state.positiveTags],
                    blockedByTags: state.blockedByTags,
                    negativeAffirmTags: state.negativeAffirmTags,
                    questions: [...state.questions],
                    visibleQuestions: visibleQs,
                    toBeRemovedQuestions: [],
                    newQuestions: newQuestions,
                    tagResults: state.tagResults
                });
                //remove highlight after 1.5 seconds
                setTimeout(() => {
                    console.log(state.tagResults);
                    setState({
                        rejected: state.rejected,
                        discardedQuestion: null,
                        finished: false,
                        answers: newAnswers,
                        negativeTags: [...state.negativeTags],
                        positiveTags: [...state.positiveTags],
                        blockedByTags: state.blockedByTags,
                        negativeAffirmTags: state.negativeAffirmTags,
                        questions: [...state.questions],
                        visibleQuestions: visibleQs,
                        toBeRemovedQuestions: [],
                        newQuestions: [],
                        tagResults: state.tagResults
                    });
                }, 1500);

            }, 500);
        }, 750);

    }

    const rejectStatement = (statement: SignUpQuizQuestion) => {

        var newRejected = state.rejected;
        newRejected.push(statement);

        // Add Negative Affirm Tags
        if (statement.positiveTags) {
            statement.positiveTags.map((t) => {
                state.positiveTags.push(t);
                state.tagResults.filter(tr => tr.value == t).map(tag => {
                    tag.score = tag.score + 3;
                });
            });
        }

        // down vote positive tags
        if (statement.negativeTags) {
            statement.negativeTags.map((t) => {
                state.negativeTags.push(t);
                state.tagResults.filter(tr => tr.value == t).map(tag => {
                    tag.score = tag.score - 1;
                });
            });
        }

        state.questions.filter(s => state.visibleQuestions.includes(s)).forEach(st => { st.occurances = st.occurances + 1 });

        axios.post(ZEN_C_ENDPOINT + `/myzen/zenStatements/choose/` + auth.username + `/` + statement.id, {}).then(function (response) {
            //console.log(response);
        }).catch(function (error) {
            console.log(error);
        });

        //Highlight discarded question totem red, highlight any that have been removed with red:
        var visibleQs = determineNewQuestions(statement);
        let questionsToBeRemoved = state.visibleQuestions.filter(vq => !visibleQs.map(nvq => nvq.id).includes(vq.id)).map(qtbr => qtbr.id);

        setState({
            rejected: newRejected,
            discardedQuestion: statement.id,
            answers: state.answers,
            questions: state.questions,
            negativeTags: state.negativeTags,
            positiveTags: state.positiveTags,
            blockedByTags: state.blockedByTags,
            negativeAffirmTags: state.negativeAffirmTags,
            finished: false,
            visibleQuestions: state.visibleQuestions,
            toBeRemovedQuestions: questionsToBeRemoved,
            newQuestions: state.newQuestions,
            tagResults: state.tagResults
        });

        // Remove Questions after 750 ms.
        setTimeout(() => {


            setState({
                rejected: state.rejected,
                discardedQuestion: null,
                finished: false,
                answers: state.answers,
                negativeTags: [...state.negativeTags],
                positiveTags: [...state.positiveTags],
                blockedByTags: state.blockedByTags,
                negativeAffirmTags: state.negativeAffirmTags,
                questions: [...state.questions],
                visibleQuestions: state.visibleQuestions.filter(vq => !questionsToBeRemoved.includes(vq.id)),
                toBeRemovedQuestions: state.toBeRemovedQuestions,
                newQuestions: [],
                tagResults: state.tagResults
            });

            //Add new questions after 250ms, highlight with gold.
            setTimeout(() => {
                let newQuestions = visibleQs.filter(vq => !state.visibleQuestions.map(nvq => nvq.id).includes(vq.id)).map(qtbr => qtbr.id);
                setState({
                    rejected: state.rejected,
                    discardedQuestion: null,
                    finished: false,
                    answers: state.answers,
                    negativeTags: [...state.negativeTags],
                    positiveTags: [...state.positiveTags],
                    blockedByTags: state.blockedByTags,
                    negativeAffirmTags: state.negativeAffirmTags,
                    questions: [...state.questions],
                    visibleQuestions: visibleQs,
                    toBeRemovedQuestions: [],
                    newQuestions: newQuestions,
                    tagResults: state.tagResults
                });
                //remove highlight after 1.5 seconds
                setTimeout(() => {
                    console.log(state.tagResults);
                    setState({
                        rejected: state.rejected,
                        discardedQuestion: null,
                        finished: false,
                        answers: state.answers,
                        negativeTags: [...state.negativeTags],
                        positiveTags: [...state.positiveTags],
                        blockedByTags: state.blockedByTags,
                        negativeAffirmTags: state.negativeAffirmTags,
                        questions: [...state.questions],
                        visibleQuestions: visibleQs,
                        toBeRemovedQuestions: [],
                        newQuestions: [],
                        tagResults: state.tagResults
                    });
                }, 1500);

            }, 500);
        }, 750);

    }


    const handleStatementDragComplete = (statement: any, draggableEvent: DraggableEvent, data: DraggableData) => {
        console.log(data);
        if (data.x > 150) {
            chooseStatement(statement);
        } else if (data.x < -150) {
            rejectStatement(statement);
        }
    }

    React.useEffect(() => {
            axios.get(ZEN_C_ENDPOINT + `/myzen/zenStatements/statements/signup`)
                .then(res => {
                    const statements: SignUpQuizQuestion[] = res.data;
                    statements.forEach(s => { s.occurances = 0 });
                    setState({
                        rejected: state.rejected,
                        discardedQuestion: null,
                        finished: false,
                        questions: statements,
                        positiveTags: ['START_QUESTION'],
                        negativeTags: state.negativeTags,
                        blockedByTags: state.blockedByTags,
                        negativeAffirmTags: state.negativeAffirmTags,
                        answers: [],
                        visibleQuestions: statements.filter((q) => checkForActivation(q, null)).slice(0, 5),
                        toBeRemovedQuestions: state.toBeRemovedQuestions,
                        newQuestions: state.newQuestions,
                        tagResults: state.tagResults
                    });
                });
    }, []);




    let questionsAnswered = state.answers ? state.answers.length : 0;
    questionsAnswered = state.rejected ? questionsAnswered + state.rejected.length : questionsAnswered;
    if (questionsAnswered > 7) {
        props.finishQuiz(state.tagResults);
    }

    return (
        <div className="WelcomeQuiz">
            <div className="WelcomeQuizInner">
                <h3 style={{ marginBottom: "25px" }}>Which of the following applies to you?</h3>
                {state.visibleQuestions.map((q, index) => {

                    return <div className="quiz-statement-background">
                        <Draggable
                            key={'DQ' + index}
                            allowAnyClick={true}
                            axis='x'
                            position={{ x: 0, y: 0 }}
                            onStop={(e, data) => handleStatementDragComplete(q, e, data)}
                        >
                            <div key={'Q' + index} className={state.discardedQuestion == q.id ? 'quiz-statement rejected-qs' : state.answers.includes(q) ? 'quiz-statement chosen-qs' : state.toBeRemovedQuestions.includes(q.id) ? "quiz-statement to-be-removed-q" : state.newQuestions.includes(q.id) ? "quiz-statement new-q" : 'quiz-statement'}>{q ? q.value.replace("â€™", "'").replace("â€™", "'") : ''}</div>
                        </Draggable>
                    </div>
                    //return <div key={'Q' + index} className={state.answers.includes(q) ? 'quiz-statement chosen-qs' : state.toBeRemovedQuestions.includes(q.id) ? "quiz-statement to-be-removed-q" : state.newQuestions.includes(q.id) ? "quiz-statement new-q" : 'quiz-statement'} onClick={() => chooseStatement(q)}>{q ? q.value.replace("â€™", "'").replace("â€™", "'") : ''}</div>;
                }
                )}

            </div>
            {questionsAnswered < 4 ?
                <h3 style={{ marginTop: "25px" }}>{state.answers ? state.answers.length + 1 : 1}/4</h3>
                :
                <div className="FinishStartQuiz" onClick={() => props.finishQuiz(state.tagResults)}>Finish</div>
            }

        </div>

    );

}












// const checkForGroupClash = (statement1: SignUpQuizQuestion, statement2: SignUpQuizQuestion) => {
//     //return true;
//     var match = false;

//     //ensure that statement not included in answers.
//     if (state.answers) {
//         state.answers.map((statement) => {
//             if (statement1 && statement1.value && statement1.value.includes(statement.value)) {
//                 match = true;
//             }
//         });
//     }

//     //Check that state blacklisted tags does not include any of statement tags.
//     if (state.negativeTags) {
//         state.negativeTags.map((group) => {
//             if (statement1 && statement1.positiveTags && statement1.positiveTags.includes(group)) {
//                 match = true;
//             }
//         });
//     }

//     //Check that statement blacklisted tags are not included in state (flagged) tags
//     if (state.positiveTags) {
//         state.positiveTags.map((group) => {
//             if (statement1 && statement1.negativeTags && statement1.negativeTags.includes(group)) {
//                 match = true;
//             }
//         });
//     }

//     return match;
// }