import * as React from "react";
import '../../styling/common/index.scss';
import '../../styling/common/zenCoreUIComponents.scss';
import { ZenHome } from "./ZenHome";
import { useNavigate } from "react-router";

export const ZenPractitionerTermsAndConditions = (props: any) => {

  let navigate = useNavigate();

  return (
    <div className="zenPractitionerTermsAndConditions" style={{ paddingLeft: "5%", paddingRight: "5%", height: "100vh", overflowY: "scroll" }}>
      <div style={{ paddingBottom: "175px" }}>

        <h2 className = "prcsNotice">You need to agree to the latest Practitioner Terms and conditions in order to continue sharing content on the platform.</h2>

        <h1>Practitioner Terms and Conditions of Use for Zencyclopedia Website and Mobile App</h1>
        <p className = "tcsDate"><b>Last Updated:</b> 22/08/2023</p>

        <h2>Introduction</h2>
        <h4>This Content Creation Agreement ("Agreement") is made and entered between Zencyclopedia ("Company") and any practitioner or content creator that decides to create a practitioner profile and provide content for the Company.
        </h4>

        <h2>Conduct Guidelines</h2>
        <ul style={{ listStyle: "none", textAlign: "justify" }}>
        <li><b>a. </b>Content Creator agrees to adhere to the established conduct guidelines of Zencyclopedia and maintain the highest standards of professionalism, integrity, and respect.</li><br></br>
        <li><b>b. </b>Content Creator shall ensure that all created content is free from offensive, defamatory, discriminatory, or inappropriate material.</li><br></br>
        <li><b>c. </b>ontent Creator shall not engage in any activity that may harm the reputation or credibility of Zencyclopedia or its affiliated entities.</li><br></br>
       </ul>

       <h2>Ownership and Usage Rights</h2>
        <ul style={{ listStyle: "none", textAlign: "justify" }}>
        <li><b>a. </b>The Content Creator acknowledges and agrees that Zencyclopedia has full royalty free rights to share and distribute submitted content across any of its platforms including social media sites, with the original ownership and intellectual property rights being maintained by the submitting practitioner.</li><br></br>
        <li><b>b. </b>The Company shall have the right to modify, reproduce, distribute, publicly display, and otherwise use the Content for promotional, marketing, or any other purposes deemed appropriate by the Company.</li><br></br>
       </ul>

       <h2>Terms and Termination</h2>
        <ul style={{ listStyle: "none", textAlign: "justify" }}>
        <li><b>a. </b>This Agreement shall commence on the day the “Practitioner account/profile” was created and continue until terminated by either party.</li><br></br>
        <li><b>b. </b>Either party may terminate this Agreement with written notice provided to the other party at least 5 days in advance.</li><br></br>
       </ul>

       <h2>Confidentiality</h2>
        <ul style={{ listStyle: "none", textAlign: "justify" }}>
        <li><b>a. </b>Content Creator shall maintain strict confidentiality regarding any non-public information received from the Company during the course of this Agreement.</li><br></br>
        <li><b>b. </b>Content Creator shall not disclose or use any such confidential information for any purpose other than as required to fulfil the obligations under this Agreement.</li><br></br>
       </ul>

       <h2>Content Creation Scope</h2>
        <ul style={{ listStyle: "none", textAlign: "justify" }}>
        <li><b>a. </b>In consideration for the Content Creator's services, the Company agrees to provide 60% of the advertisement  profits generated by the Content, based on views and popularity on the Zencyclopedia platform. </li><br></br>
        <li><b>b. </b>Zencyclopedia also agrees to share 50% of the profits generated through subscriptions to the platform, which will be shared across practitioners based on views and popularity of the practitioner content.</li><br></br>
       </ul>
        <h2 className = "prcsNotice">Zencyclopedia reserves the right to make amendments to this agreement through the release of any new “Terms and conditions'”.</h2>

       
      <h2>Governing Law and Jurisdiction</h2>
      <p style={{ textAlign: "justify" }}>
      This Agreement shall be governed by and construed in accordance with the laws of England and Wales. Any disputes arising out of or in connection with this Agreement shall be subject to the exclusive jurisdiction of the courts of England and Wales.
      </p>

      <h1 className = "prcsNotice">Thank you for your commitment to contributing valuable content to Zencyclopedia. We look forward to a successful collaboration.</h1>

      
      <div style={{ margin: "auto", textAlign: "center", marginBottom: "150px", marginTop:"50px" }} className="zenGlowButton sageGlowButton" onClick={() => {
        if (props.confirmSignUp != null) {
          props.confirmSignUp()
        }
      }}>I agree</div>
    </div>
    </div >
  );
}