import React, { useState } from 'react';
import "../../../../styling/vacana/vacana.scss";
import "../../../../styling/common/index.scss";
import "../../../../styling/stream/stream.scss";

import ZenContentInterface from '../ZenContentInterface';
import { MeditationIcons } from '../MeditationIcons';
import { PracticePreviewControls } from './PracticePreviewControls';
import { ZenNavBar } from '../../../common/navbar/ZenNavBar';
import { MyZenNavBar } from '../../../common/navbar/MyZenNavBar';
import { useParams } from 'react-router';
import { ZEN_C_ENDPOINT } from '../../../../config/EnvironmentalVariables';
import axios from 'axios';
import MetaTags from 'react-meta-tags';
import PrivateRoute from '../../../common/PrivateRoute';
import { useAuth } from '../../../../hooks/auth';
import { ProvideMyStuffContext, useMyStuffService } from '../../../../hooks/MyStuffContext';
import { PracticeLoggedOutPreview } from './PracticeLoggedOutPreview';
import { Link } from 'react-router-dom';


export const PracticeSummary = () => {
  const [showSyllabus, setShowSyllabus] = useState(false);
  const [practice, setPractice] = useState(null);
  const [loading, setLoading] = useState(false);
  const [atttempts, setAttempts] = useState(0);

  const { id } = useParams();

  const [props, setProps] = useState(null);
  const [practicePlaying, setPracticePlaying] = useState(false);
  const [practiceVolume, setPracticeVolume] = useState(50);
  const [finished, setFinished] = useState(false);

  if (practice == null && !loading && atttempts < 4) {
    setAttempts(atttempts + 1)
    setLoading(true);
    //check is id number or string?
    if (isNaN(id as any)) {

      getMeditationByName(id);
    } else {

      getMeditation(id);
    }
  }

  const myStuffService = useMyStuffService();
  
  React.useEffect(() => {
    myStuffService.getBookmarks();
    myStuffService.getFavourites();
  }, []);

  function getMeditation(id: any): any {
    axios.get(ZEN_C_ENDPOINT + `/zen/content/` + id)
      .then(res => {
        setPractice(res.data);
        setLoading(false);
        if (res.data && res.data.title) {
          document.title = `ZENcyclopedia - ` + res.data.title;
        }
      }).catch(() => {
        setLoading(false);
      })
  }

  function getMeditationByName(id: string): any {
    axios.get(ZEN_C_ENDPOINT + `/zen/content/getContentIdFromName/` + id)
      .then(res => {
        getMeditation(res.data);
      }).catch(() => {
        setLoading(false);
      })
  }

  let auth = useAuth();

  var practicePortalLink = "/practice/" + id;

  const myStuff = useMyStuffService();

  const favourited = myStuff.favourites && myStuff.favourites.map(b => b.contentId).includes(id as any);
  const bookmarked = myStuff.bookmarks && myStuff.bookmarks.map(b => b.contentId).includes(id as any);

  // React.useEffect(() => {
  //   myStuff.logContentInteraction(id);
  // },[]);

  return (
    <PrivateRoute loggedOutComponent={<PracticeLoggedOutPreview />}>
      <ProvideMyStuffContext>
        <div className="zen-page-holder">
          <MetaTags>
            <link rel="icon" href={practice && practice.image ? practice.image : require("../../../../static/photos/zencyclogo.png")} />
            <meta id="meta-description" name="description" content={practice ? practice.title : "The social media network built with mindfulness in mind."} />
            <meta id="og-title" property="og:title" content="Practice Overview" />
            <meta id="og-image" property="og:image" content={practice && practice.image ? practice.image : require("../../../../static/photos/zencyclogo.png")} />
            <meta id="og-icon" property="og:icon" content={practice && practice.image ? practice.image : require("../../../../static/photos/zencyclogo.png")} />
          </MetaTags>
          <ZenNavBar></ZenNavBar>
          {practice != null ?
            <div className="practice-summary">
              <Link to={practicePortalLink}><div className="zenGlowButton navajoGlowButton">Start Practice</div></Link>
              <Link to={practicePortalLink}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div className="practice-image-holder">
                    <img className="practice-overview-image" src={practice && practice.image ? practice.image : require("../../../../static/photos/AwM.png")} onClick={() => setShowSyllabus(!showSyllabus)}></img>
                    <div className="zen-desktop">
                      <MeditationIcons {...practice}></MeditationIcons>
                    </div>
                  </div>
                  <div className="practice-details-section">

                    <div className="practice-title-row practice-summary-title-row">
                      <div className="practice-image-holder-mobile">
                        <img className="practice-overview-image" src={practice && practice.image ? practice.image : require("../../../../static/photos/AwM.png")} onClick={() => setShowSyllabus(!showSyllabus)}></img>
                      </div>
                      <div>
                        <h4 className="practice-title okashi practice-summary-title">{practice ? practice.title : null}</h4>
                        {/* <h4 className = "practitioner-name zenK">by {practice.practitioner? practice.practitioner.name:''}</h4> */}
                      </div>
                    </div>

                    <div className="zen-mobile">
                      <MeditationIcons {...practice}></MeditationIcons>
                    </div>
                    {practice && practice.description ?
                      <h3 className="practice-description zenK"> {practice ? practice.description : null}</h3>
                      :
                      null}
                  </div>
                </div></Link>
              {auth.isAuthenticated ?
                <div className="practice-preview-controls">
                  <>
                    <div className={favourited ? "PracticePreviewFavourited" : "PracticePreviewFavourite"} onClick={() => { favourited ? myStuff.removeFavourite(id as any) : myStuff.addFavourite(id as any) }}></div>
                    <div className={bookmarked ? "PracticePreviewBookmarked" : "PracticePreviewBookmark"} onClick={() => { bookmarked ? myStuff.removeBookmark(id as any) : myStuff.addBookmark(id as any) }}></div>
                  </>
                </div>
                : null}
            </div> : null}
        </div>
      </ProvideMyStuffContext>
    </PrivateRoute>
  );
}