import * as React from "react";
import "../../styling/common/index.scss";
import "../../styling/common/zenCoreUIComponents.scss";
import "../../styling/myzen/myzen.scss";
import { ZenNavBar } from "../common/navbar/ZenNavBar";
import { useState } from "react";
import { useZenExploreService } from "../../hooks/ZenExploreContext";
import axios from "axios";
import { ZEN_C_ENDPOINT } from "../../config/EnvironmentalVariables";
import { useAuth } from "../../hooks/auth";
import { PathPreview } from "../zencontent/courses/PathPreview";
import { useNavigate, useParams } from "react-router";
import { PuffLoader } from "react-spinners";
import { getContentCatagories } from "../../service/ZencyclopaediaService";
import { ZenPopUpMessage } from "../common/ZenPopUpMessage";
import { ZencTitle } from "../home/ZencTitle";
import { ShopMainItem } from "./ShopMainItem";
import { Link } from "react-router-dom";
import { ZenLoader } from "../common/ZenLoader";
import { ZenShopPaymentCheckout } from "./tshirts/ZenShopPaymentCheckout";
import { DeliveryDetailsModal } from "./common/DeliveryDetailsModal";
import { useZenShopService } from "../../hooks/ZenShopContext";
import { ZenShopOrderPayment } from "./tshirts/ZenShopOrderPayment";
import { formatOrderPrice, generateItemBasketPreview } from "../../service/ContentService";

export const ZenBasket = (props: any) => {

      let auth = useAuth();
      let shopService = useZenShopService();
      const [basketPage, setBasketPage] = useState(null);
      let [orderId, setOrderId] = useState(null);

      const executeCheckout = async (name: string, email: string, deliveryDetails: any) => {
            let preparedBasket = await shopService.checkoutBasket(name, email, deliveryDetails);
            if (preparedBasket) {
                  setOrderId(preparedBasket.id);
                  setBasketPage("payment");
            } else {
                  alert("there has been an error, email contact@zencyclopedia.co.uk for support");
            }
      }


      return (
            <div className="splashedBacking zen-shop-items-container" style={{ height: "100vh", width: "100vw", textAlign:"center" }}>
                  {basketPage == "deliveryDetails" ?
                        <DeliveryDetailsModal onComplete={(na, em, deliveryD) => executeCheckout(na, em, deliveryD)} />
                        : basketPage == "payment" ?
                        <div >
                              <ZenShopOrderPayment order = {shopService.basket}/>
                              </div>
                  :shopService.basket && shopService.basket.orderItems ?
                        <div>
                             
                              {shopService.basket.orderItems.length > 0 ?
                                    <React.Fragment>
                                           {props.newItem ?
                                    <div>
                                          <h3 style={{ margin: "auto", textAlign: "center", marginBottom: "5px" }}>Item added to basket!</h3>
                                          <Link to="/shop"><div style={{ marginTop: "15px" }} className="zenGlowButton sageGlowButton">Keep shopping</div></Link>
                                    </div>
                                    : null
                              }
                                          <h3 style={{ margin: "auto", textAlign: "center", marginBottom: "5px" }}>You currently have {shopService.basket.orderItems.length == 1 ?
                                                <>1 item</> :
                                                <>{shopService.basket.orderItems.length} items</>} in your basket</h3>

                                          <div>
                                                {shopService.basket.orderItems.map(item => generateItemBasketPreview(item, shopService.removeItemFromBasket))}
                                          </div>
                                          <h3 style={{ margin: "auto", textAlign: "center" }}>Total Cost: {formatOrderPrice(shopService.basket.orderprice)}</h3>
                                          <div style={{ margin: "auto", display: "flex", flexDirection: "row", marginTop: "25px", maxWidth: "250px" }}>

                                                <div className="zenGlowButton mingGlowButton" onClick={() => shopService.clearBasket()}>
                                                      Clear basket
                                                </div>

                                                <div className="zenGlowButton sageGlowButton" onClick={() => setBasketPage("deliveryDetails")}>
                                                      Checkout
                                                </div>
                                          </div>
                                    </React.Fragment>
                                    :
                                    <div><h3 style={{ margin: "auto", textAlign: "center" }}>You don't have any items in your basket.</h3>
                                          <Link to="/shop"><div style={{ marginTop: "15px" }} className="zenGlowButton sageGlowButton">Start shopping now</div></Link>
                                    </div>}


                        </div>
                        : <ZenLoader />}
            </div>
      );
}
