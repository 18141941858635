import * as React from "react";
import "../../../styling/zenverse/Zenverse.scss";
import ZenscapeInterface from "../interfaces/ZenscapeInterface";
import Zenscape from "../interfaces/ZenscapeInterface";


interface PortalIconInterface extends ZenscapeInterface {
  practiceicon?: boolean;
  practiceiconPlaying?: boolean;
}

export const PortalIcon = (props : PortalIconInterface) => {

  const goToPortal = () => {
    props.parentSlider.current.slickGoTo(props.index);
  }

  const portalClicked = () => {

    //We should jsut load the ZenscapePortal which has ability to load own preview.
    if(props.notifyShowPreview!=null){props.notifyShowPreview()};
    if(props.parentSlider){
      props.parentSlider.current.slickGoTo(props.index);
    } 
    if(props.desktopParentSlider){
      props.desktopParentSlider.current.slickGoTo(props.index);
    } 
     if (props.loadZenscapeJSX){
      props.loadZenscapeJSX(props);
    }
    else {
      props.loadZenscape(props);
    }
  }

    return (
      <div className={props.practiceiconPlaying? "portal-icon practice-portal-icon-playing" : props.practiceicon? "portal-icon practice-portal-icon" : "portal-icon"} onClick = {() => portalClicked()}>
        <img className = "portal-icon-image" src={props && props.details && props.details.image? props.details.image : require("../../../static/photos/blotches.png")} ></img>
      </div>
    );
}