import React, { useState } from 'react';
import "../../../styling/vacana/vacana.scss";
import "../../../styling/common/index.scss";
import "../../../styling/zenverse/bubbleBreath.scss";
import "../../../styling/stream/stream.scss";
import "../../../styling/zenverse/Zenverse.scss";

export const BubbleBreathPortal = () => {

  const [walkthroughStage, setWalkthroughStage] = useState(1);
  const [practicePlaying, setPracticePlaying] = useState(false);
  const [speed, setSpeed] = useState("med");

  if (walkthroughStage > 5 && !practicePlaying) {
    setPracticePlaying(true);
  }



  // set time out once we begin

  return (
    <React.Fragment>
    <div className='bubble-breath-holder'>
      <div className ="bubble-breath-speed-opts">
        <h3 style={{ margin: "auto" }} onClick={() => { setSpeed("slow") }}>Slow</h3>
        <h3 style={{ margin: "auto" }} onClick={() => { setSpeed("easy") }}>Easy</h3>
        <h3 style={{ margin: "auto" }} onClick={() => { setSpeed("fast") }}>Quick</h3>
      </div>
     
      <div className="bubble-container">
        <div className={speed == "slow" ? "bubble-breath bubble-breath-slow" : speed == "fast" ? "bubble-breath bubble-breath-fast" : "bubble-breath"}>
        <div className={speed == "slow" ? "bubble-breath-text breath-in-text-slow" : speed == "fast" ? "bubble-breath-text breath-in-text-fast" : "bubble-breath-text breath-in-text-reg"}></div>
      {/*<div className = "hideForFirstRun">*/}
          {/* <div className={speed=="slow"? "breath-out-text breath-out-text-slow" : speed=="fast"? "breath-out-text breath-out-text-fast" : "breath-out-reg breath-out-text" }></div>
          */}
        </div>
      </div>
    </div>
    </React.Fragment>
  );
}