import * as React from "react";
import '../../../styling/common/index.scss';
import { useState } from "react";
import { useAuth } from "../../../hooks/auth";
import axios from "axios";
import { useMyZenService } from "../../../hooks/ZenUserContext";
import { ConfirmTranslation, InvalidEmailTranslation, PasswordRequirementsTranslation, PasswordTranslation, SignUpFailedTranslation, SignUpTranslation, UserNameWarningTranslation, UsernameTranslation } from "../../../content/SignUpTranslations";
import { MadeInBCNTranslation } from "../../../content/HomeContentTranslations";
import { ZEN_C_ENDPOINT } from "../../../config/EnvironmentalVariables";
import { GdprBanner } from "../GdprBanner";
import { ReactSession } from 'react-client-session';
import { ZenUserTermsAndConditions } from "../ZenUserTermsAndConditions";
import { generateRandomName } from "../../../service/ZenUserService";
import { BarLoader } from "react-spinners";
import { Link } from "react-router-dom";

export const SignUp = (props: any) => {

  const auth = useAuth();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [sUPageNum, setsUPageNum] = useState(1);
  // const [code, setCode] = useState("");
  const [number, setNumber] = useState("");
  const [loginAttempted, setloginAttempted] = useState(false);
  const [loginSuccess, setloginSuccess] = useState(false);
  const [usernameTaken, setUsernameTaken] = useState(false);
  const [signUpFailed, setSignUpFailed] = useState(false);

  const userContext = useMyZenService();
  const [gdprAccepted, setGdprAccepted] = React.useState(false);

  const executeSignUp = () => {
    setsUPageNum(3);
    executeSignIn();
  }

  const executeSignIn = async () => {
    if (gdprAccepted && !usernameTaken && ValidateUsername(username) && ValidateEmail(email) && ValidatePassword(password)) { // code =='RAFFY' || 'SANGRIA FAMILIAR'){
      const result = await auth.signUp(username.toLowerCase(), password, email, number);
      const apiresult = axios.post(ZEN_C_ENDPOINT + `/myzen/myZen/create`, {
        username: username,
      });
      if (result.success) {
        ReactSession.set("username", username.toLowerCase());
        ReactSession.set("mindfulnest", password);
        setloginAttempted(true);
        setloginSuccess(true);
        props.nextPage();

      } else {
        setloginAttempted(true);
        setloginSuccess(false);
        console.log(apiresult);
        setSignUpFailed(true);
      }
    } else {
      setsUPageNum(1);
    }
  };


  const checkUserExists = async (user: string) => {
    var userExists = await axios.get(ZEN_C_ENDPOINT + `/myzen/myZen/checkUserNameExists/` + user)
      .then(res => {
        return res.data
      }).catch(() => {
        return false;
      });

    setUsernameTaken(userExists);
  }


  const ValidateUsername = (inputText: string) => {
    if (inputText.length % 2 == 1) {
      checkUserExists(inputText);
    }
    var containsSpacesRegex = /\s/;
    return !containsSpacesRegex.test(inputText) && inputText.length > 2;
  }

  const ValidateEmail = (inputText: string) => {
    var mailformat = /^\S+@\S+\.\S+$/;

    return inputText.match(mailformat);
  }

  const ValidatePassword = (inputText: string) => {
    return inputText && inputText.length > 5;
    // var pwformat = /^(?=.*[!@#$%-^&*.])[a-zA-Z0-9!@#$%^&*.]{6,25}$/;
    // return inputText.match(pwformat);
  }

  const setRandomUsername = async () => {
    var suggestedName = generateRandomName();
    var userTaken = await axios.get(ZEN_C_ENDPOINT + `/myzen/myZen/checkUserNameExists/` + suggestedName)
      .then(res => {
        return res.data
      }).catch(() => {
        return false;
      });

    if (userTaken) {
      var randomInt = Math.floor(Math.random() * 15);
      suggestedName = suggestedName + randomInt
    }
    setUsername(suggestedName);
  }




  return (
    <React.Fragment>
      {sUPageNum == 1 ?
        <React.Fragment>
          <GdprBanner accepted={gdprAccepted} setAccepted={(val) => setGdprAccepted(val)} />
          <div className="sign-up-details-container">
            <h1 className="sign-up-header zenK">{((props.lang && props.lang == "ESP") || userContext.language == 'ESP') ? SignUpTranslation.Spanish : SignUpTranslation.English}</h1>
            <Link to = {"/logIn"}><h4>Already have an account? Log In</h4></Link>
            <form noValidate >
              {/* Username Input: */}
              <input
                type="text"
                placeholder={((props.lang && props.lang == "ESP") || userContext.language == 'ESP') ? UsernameTranslation.Spanish : UsernameTranslation.English}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className={ValidateUsername(username) ? 'su-text-input validated-field' : 'su-text-input'}
              />
              <h4 className="tomBlueText" style={{ margin: "auto", textAlign: "center" }} onClick={() => setRandomUsername()}>generate random username</h4>
              {/* Password Input: */}
              <input
                type="password"
                placeholder={((props.lang && props.lang == "ESP") || userContext.language == 'ESP') ? PasswordTranslation.Spanish : PasswordTranslation.English}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={ValidatePassword(password) ? 'su-text-input validated-field' : 'su-text-input'}
              />
              {/* Email Input: */}
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={ValidateEmail(email) ? 'su-text-input validated-field' : 'su-text-input'}
              />

              {/* Validation Code Input: */}
              {/* <input
                type="text"
                placeholder={((props.lang && props.lang == "ESP")  ||  userContext.language == 'ESP')? ActivationCodeTranslation.Spanish : ActivationCodeTranslation.English}
                value={code}
                className={code && code.length>2? 'su-text-input validated-field':'su-text-input'}
                onChange={(e) => setCode(e.target.value)}
            /> */}
              <div className='su-input-help-text'>

                {/* <h4 className = "activation-code-info">{((props.lang && props.lang == "ESP")  ||  userContext.language == 'ESP')? InvitationNeededTranslation.Spanish : InvitationNeededTranslation.English}</h4>
             */}
                <ul className="su-errors-list">
                  {usernameTaken ?
                    <li>Sorry, that username is already taken!</li>
                    : null}
                  {username.length > 0 && !ValidateUsername(username) ?
                    <li>{((props.lang && props.lang == "ESP") || userContext.language == 'ESP') ? UserNameWarningTranslation.Spanish : UserNameWarningTranslation.English}</li>
                    :
                    <h4></h4>
                  }

                  {password.length > 0 && !ValidatePassword(password) ?
                    <li>{((props.lang && props.lang == "ESP") || userContext.language == 'ESP') ? PasswordRequirementsTranslation.Spanish : PasswordRequirementsTranslation.English}</li>
                    :
                    <h4></h4>
                  }
                  {email && !ValidateEmail(email) ?
                    <li>{((props.lang && props.lang == "ESP") || userContext.language == 'ESP') ? InvalidEmailTranslation.Spanish : InvalidEmailTranslation.English}</li>
                    :
                    <h4></h4>
                  }
                  {loginAttempted && !gdprAccepted ?
                    <li>You need to agree to the use of cookies before you can make an account...</li>
                    :
                    <h4></h4>
                  }

                </ul>
              </div>
            </form>

            {loginSuccess ?
              <div className="log-mood-button">
              </div>
              :
              <React.Fragment>
                {loginAttempted && !loginSuccess ? <h4 className='su-failed-text'>{((props.lang && props.lang == "ESP") || userContext.language == 'ESP') ? SignUpFailedTranslation.Spanish : SignUpFailedTranslation.English}</h4> : null}
                <div className="zencTextButton zencGreenButton" onClick={() => setsUPageNum(2)}>
                  {((props.lang && props.lang == "ESP") || userContext.language == 'ESP') ? ConfirmTranslation.Spanish : ConfirmTranslation.English}
                </div>
              </React.Fragment>
            }
          </div>
        </React.Fragment>
        : sUPageNum == 2 ?
          <ZenUserTermsAndConditions
            confirmSignUp={() => executeSignUp()}
          />
          :signUpFailed?
          <div  style={{ margin: "auto", textAlign: "center" }}>
            <h2>Oh no, this is embarressing!</h2>
            <h4>Something went wrong whilst creating your account... If the problem persists, contact support by emailing <a href="mailto:contact@zencyclopedia.co.uk"><b>contact@zencyclopedia.co.uk </b> </a> or try signing up via a desktop/laptop browser!</h4>
          </div>
          :
          <div>
            <h3 style={{ margin: "auto", textAlign: "center" }}>Hold on tight while we set up your account...</h3>

            <BarLoader color="#033f63">
            </BarLoader>
          </div>}

    </React.Fragment>
  );
}






