import React, { useState } from "react";
import axios from "axios";
import BrowseLocalFilesButton from "./BrowseLocalFilesButton";

interface ImageDropProps {
  endpoint: string;
  file: File | null;
  previewURL: any;
  setFile: any;
  setPreviewURL: any;
  handleFileSelect:any;
  size?:any;

}

const ImageDrop: React.FC<ImageDropProps> = (props: { endpoint, file, previewURL, setFile, setPreviewURL, handleFileSelect, size }) => {

  const handleImageDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer.items) {
      for (let i = 0; i < event.dataTransfer.items.length; i++) {
        const droppedFile = event.dataTransfer.items[i].getAsFile();
        if (droppedFile && (droppedFile.type === "image/jpeg" || droppedFile.type === "image/png")) {
          props.setFile(droppedFile);
          props.setPreviewURL(URL.createObjectURL(droppedFile));
          break;
        }
      }
    }
  };

  const handleFileSubmit = async () => {
    if (props.file) {
      try {
        const formData = new FormData();
        formData.append("file", props.file);

        await axios.post(props.endpoint, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        // Reset file and preview state after successful upload
        props.setFile(null);
        props.setPreviewURL(null);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  const width = props.size == "small"? "190px" : "300px";
  const height = props.size == "small"? "150px" : "200px";

  return (
    <div>

      {props.previewURL ? (
        <div style={{ marginTop: "-35px" }}>
          <div className="practice-image-holder">
            <img className="practice-overview-image" src={props.previewURL} ></img>
          </div>
          <p style={{ fontSize: "90%", textAlign: "center", margin: "auto" }}>File selected: {props.file?.name}</p>
        </div>
      ) : (
        <div>

          <div
            style={{
              width: width,
              minHeight: height,
              border: "2px dashed #ccc",
              borderRadius: "4px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              padding: "20px",
            }}
            onDrop={handleImageDrop}
            onDragOver={(event) => event.preventDefault()}
          >

            <p>Drag and drop a JPG or PNG image here or click below</p>
            
            <BrowseLocalFilesButton accept="image/jpeg, image/png" onFileSelect={props.handleFileSelect} />
          </div>

       
        </div>
      )}

    </div>
  );
};

export default ImageDrop;
