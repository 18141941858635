import * as React from "react";
import "../../../styling/explore/explore.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router";
import ZenCardInterface from "./ZenCardInterface";
import { ZenCardPreview } from "./ZenCardPreview";


export const ZenCardMilestoneNotice = (props: any) => {


    const [cardPage, setCardPage] = React.useState(0);
    // Mock function for which sub they are in. 
    let zenBasicSubscriptionTierIncludedIconKeys = ["PonderingPear"];

    let navigate = useNavigate();

    let currentCard = cardPage != null && !(cardPage >= props.cards.length) ? props.cards[cardPage] : null;

    return (
        <div className="zen-milestone-notice" style={{paddingTop:"25px"}}>
            <h1 className = "centered-text">Congratulations! </h1>
            <h2 className = "centered-text">You unlocked a Zen Card</h2>
            {currentCard != null?
                    <ZenCardPreview {...currentCard} newCard = {true}/>
                : null
            }
            {cardPage != null && !(cardPage >= props.cards.length - 1) ?
                <>
                    You have {(props.cards.length - cardPage - 1)} new cards left to receive!
                    <div className="zenGlowButton navajoGlowButton" onClick={() => setCardPage(cardPage + 1)}>
                        show next
                    </div>
                </>
                :
                <div className="zenGlowButton mingGlowButton" style = {{marginTop:"15px"}} onClick={props.moveOnAction? () => props.moveOnAction() : () => navigate("/")}>
                    finish
                </div>
            }
        </div>
    );

}

