import axios from "axios";
import React, { createContext, useContext, useState } from "react";
import { getAllPowerCodes } from "../components/explore/icons/CardSubtypes";
import ZenCardInterface from "../components/explore/icons/ZenCardInterface";
import { ZEN_C_ENDPOINT } from "../config/EnvironmentalVariables";
import { useAuth } from "./auth";
import { PractitionerPreview } from "../components/explore/communities/PractitionerPreview";

interface UseZenShop {
  basket: any,
  setUserBasket:any,
  checkoutBasket:any,
  addItemToBasket:any,
  removeItemFromBasket,
  clearBasket:any

}

type Props = {
  children?: React.ReactNode;
};
const zenShopContext = createContext({} as UseZenShop);

export const ProvideZenShopContext: React.FC<Props> = ({ children }) => {
  const zenShopService = ZenShopService();
  return <zenShopContext.Provider value={zenShopService}>{children}</zenShopContext.Provider>;
};

export const useZenShopService = () => {
  return useContext(zenShopContext);
};

export const ZenShopService = () => {

  const auth = useAuth();
  const [basket, setBasket] = useState<any | null>();

  const getUserBasket = async () => {
    let basket = await axios.get(ZEN_C_ENDPOINT + `/myzen/shop/getBasket/`+auth.username)
    .then(function (response) {
      if(response.data){
        return response.data;
      } else return null;
    }).catch(function (error) {
          return null;
    });
    return basket;
  }

  const setUserBasket = () => {
    axios.get(ZEN_C_ENDPOINT + `/myzen/shop/getBasket/`+auth.username).then(function (response) {
      if(response.data){
        setBasket(response.data);
      } else {
        setBasket(null);
      }
    }).catch(function (error) {
          setBasket(null);
    });
  }

  const updateUserBasket = (newItems:any) => {
    axios.post(ZEN_C_ENDPOINT + `/myzen/shop/updateBasket`,{
          name:auth.username,
          orderItems:newItems
    }).then(function (response) {
      if(response.data){
        setBasket(response.data);
      }
    }).catch(function (error) {
          setBasket(null);
    });
  }

  const addItemToBasket = async (itemType:string, itemSize:string, itemColour:string, amount:string, subType:string) => {
    let newBasket ={
      orderItems:[]
    }

    if(basket && basket.orderItems && basket.orderItems.length>0){
      basket.orderItems.map(it => newBasket.orderItems.push(it));
    }  else {
      newBasket = await getUserBasket();
    } 

    if(newBasket && newBasket.orderItems){
      newBasket.orderItems.push({
        type:itemType,
        size:itemSize,
        colour:itemColour,
        amount:amount
      });
    } else {
      newBasket = {
        orderItems:[{
          type:itemType,
          size:itemSize,
          colour:itemColour,
          amount:amount
        }]
      }
    }


    updateUserBasket(newBasket.orderItems);
  }



  const clearBasket = async () => {
    updateUserBasket([]);
  }
  const removeItemFromBasket = async (itemId: any) => {
    console.log("removing item: "+itemId);
    let newBasket = [];

    if(basket && basket.orderItems && basket.orderItems.length>0){
      basket.orderItems.filter(item => {
          console.log("checking if item should be removed from basket: ");
          console.log(item.id);
          console.log(itemId);
          console.log("removing? "+!(item.id != itemId));
          return item.id != itemId;
      }).map(it => {
        console.log("re-Adding to basket: "+it.id);
        newBasket.push(it)
      });
    } 
    console.log("Basket Here: ");
    console.log(newBasket);
    updateUserBasket(newBasket);

  }

  const checkoutBasket = async (name: string, email: string, deliveryDetails: any) => {

    //Send request to back end:
    let result = await axios.post(ZEN_C_ENDPOINT + `/myzen/shop/prepareBasketForPayment`, {
          name: auth.username,
          email: email,
          deliveryDetails: deliveryDetails
    }).then(function (response) {
      return response.data;
    }).catch(function (error) {
          console.log(error);
          console.log("failed to make t shirt req")
          return null;
    });

    return result;

}

  return {
    basket,
    setUserBasket,
    checkoutBasket,
    addItemToBasket,
    removeItemFromBasket,
    clearBasket
  }
}