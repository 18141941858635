import React from "react";
import ZenContentInterface from "../components/zencontent/practices/ZenContentInterface";
import { PracticePreview } from "../components/zencontent/practices/previews/PracticePreview";
import { PathPreview } from "../components/zencontent/courses/PathPreview";
import { Link } from "react-router-dom";
import { ZenNugget } from "../components/zencontent/nuggets/ZenNugget";
import { NuggetSliderPreview } from "../components/zencontent/nuggets/NuggetSliderPreview";
import { ZenArticleTextPage } from "../components/explore/articles/ZenArticleTextPage";
import Slider from "react-slick";
import { PathSliderPreview } from "../components/zencontent/courses/PathSliderPreview";
import { PracticeSliderPreview } from "../components/zencontent/practices/PracticeSliderPreview";
import { ZenStreamReelPreview } from "../components/zencontent/reels/ZenStreamReelPreview";


export const getContentListPreview = (item: ZenContentInterface, clickable?:boolean, move?:any, remove?:any) => {
  if (item.contentType == 'MEDITATION') {
    return <PracticePreview {...item} unclickable = {!clickable} move = {move} remove = {remove}/> ;
  }else if (item.contentType == 'NUGGET') {
    return <div className="zen-content-list-item-holder"><ZenNugget {...item} nuggetType = {item.nuggetType? item.nuggetType: item.previewType}></ZenNugget></div>;
  }
  else if (item.contentType == 'PATH') {
    return <div className="zen-content-list-item-holder"><PathPreview {...item}></PathPreview></div>;
  } else if (item != null) {
    return <div>{item.title}</div>
  }
}

export const getContentListPreviews = (items: ZenContentInterface[]) => {

  const quoteItems = items.filter(ci => (ci.contentType == "NUGGET" && ci.nuggetType == "QOUTE"));
  const streamMockItems: any[] = [];
  //var allPortals = getStarterZenscapes(); 
  items.map(i => {
    if (i.contentType == 'MEDITATION') {
      streamMockItems.push(<PracticePreview {...i} />);
    }
    // else if (i.contentType =='INFOMATIC'|| i.contentType =='INFO_GROUP'||i.contentType =='ZENC_INFO_GROUP'){
    //   streamMockItems.push(<InfomaticStreamItem {...i}></InfomaticStreamItem>);
    // } 
    // else if (i.contentType =='NUGGET'){
    //   streamMockItems.push(<ZenNugget {...i}></ZenNugget>);
    // }
    else if (i.contentType == 'NUGGET') {
      streamMockItems.push(<div className="zen-content-list-item-holder"><ZenNugget {...i} nuggetType = {i.nuggetType? i.nuggetType: i.previewType}></ZenNugget></div>);
    }
    else if (i.contentType == 'PATH') {
      streamMockItems.push(<div className="zen-content-list-item-holder"><PathPreview {...i}></PathPreview></div>);
    } else if (i.contentType == 'PORTAL') {
      // var chosenId = Math.floor(Math.random() * allPortals.length);
      // var portal = allPortals[chosenId];
      //streamMockItems.push(<ZenscapeStreamPreview {...portal}></ZenscapeStreamPreview>);
    } else if (i.contentType == 'REEL'){
      streamMockItems.push(<div style = {{margin:"auto", marginBottom:"15px", width:"90%", borderRadius:"10px", overflow:"hidden"}}><ZenStreamReelPreview {...i}/></div>);
    }
  })
  return streamMockItems;
}

export const getContentSliderPreviews = (items: ZenContentInterface[], focusItem?:any, showingMore?:boolean) => {
  const streamMockItems: any[] = [];
  items.map((i, index) => {
    let newItem = i.contentType == "NUGGET" && i.nuggetType != "QOUTE" ? 
    <NuggetSliderPreview {...i} focusItem = {(item) => focusItem(item)} index = {index} showingMore = {showingMore}/>
      :i.contentType == "PATH"? 
      <PathSliderPreview {...i}/>
      :i.contentType == "MEDITATION"?
      <PracticeSliderPreview {...i}/>
      :i.contentType == "REEL"?
      <div className = "zen-reel-slider-item-holder">
      <ZenStreamReelPreview {...i} streamItem={false}/>
      </div>
      :null;

      streamMockItems.push(newItem);
  })
  return streamMockItems;
}

export const calculateTotalPracticeTime = (practiceList: any[]) => {
  let totalTime = 0;
  practiceList.map((p: ZenContentInterface) => {
    if (p != null && p.durationSeconds != null) {
      totalTime = totalTime + p.durationSeconds;
    }
  });
  return Math.floor(totalTime / 60);
}

export const getPracticeListItem = (practice: ZenContentInterface) => {
  return <PracticePreview {...practice} />
}

export const createArticleSliderImage = (page:any) => {
    return <div  key = {"sai"+page.id}  style= {{maxWidth:"300px", maxHeight:"275px", margin:"auto", display:"block"}}>
      <div style = {{flex:"1"}}>
      <img src = {page.image} style= {{maxWidth:"300px", maxHeight:"275px",objectFit:"contain", display:"block", width:"100%"}}></img></div>
      </div>
}

export const createArticleJSX = (pages: any []) => {
    var currentSlider = "N/A";
    var jsx = [];

    var pageType = "start";

    for(var index = 0; index < pages.length; index++){
      var currentPage = pages[index];
 

      console.log("Adding Page: ");
      if(currentPage.pageType == "TEXT_PAGE"){
        console.log("Creating text page, "+pageType);
        pageType = pageType == "start"? 
        "reg"
        :pageType == "alt"? 
        "reg"
        :"alt";
        jsx.push(<ZenArticleTextPage key = {"zap"+currentPage.id} {...currentPage} pageType = {pageType}></ZenArticleTextPage>);
        
      } else if (currentPage.pageType == "SLIDER_IMAGE"){
        var sliderItems = [];
        sliderItems.push(createArticleSliderImage(pages[index]));
        while(pages[index+1] != null && pages[index+1].pageType == "SLIDER_IMAGE"){
          sliderItems.push(createArticleSliderImage(pages[index+1]));
          index ++;
        } 
        jsx.push(<div className = {pageType=="reg"? "zen-info-page-reg":"zen-info-page-alt"} style = {{marginBottom:"0", paddingBottom:"50px"}}>
          <Slider
              autoplay={true}
              dots={true}
              infinite={true}
              centerMode={true}
              
        >
          {sliderItems}
        </Slider></div>);
      }
    }

    return jsx;
}

export const formatOrderPrice = (priceInPennies:any) => {
    // Convert pennies to pounds
    let priceInPounds = priceInPennies / 100.0;

    // Format the price as UK currency
    const ukCurrencyFormat = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP'
    });

    // Return the formatted price
    return ukCurrencyFormat.format(priceInPounds);
}

export const getTiShirtPreview = (tShirtItem: any, removeItem?: any) => {
  return <div className="shopBasketItemPreview">
    {removeItem?
        <div className="deleteLink zenBasketItemRemove" onClick = {() => removeItem(tShirtItem.id)}></div>
    :null}
        <div style={{ margin: "auto", display: "flex", flexDirection: "row" }}>
              <div className="">
                    {tShirtItem.colour == "grey" ?
                          <div className="shopBasketItemPreviewPhoto greyT"></div>
                          : tShirtItem.colour == "blue" ?
                                <div className="shopBasketItemPreviewPhoto blueT"></div>
                                : tShirtItem.colour == "white" ?
                                      <div className="shopBasketItemPreviewPhoto whiteT"></div>
                                      :
                                      <div className=" shopBasketItemPreviewPhoto orangeT"></div>}
              </div>
              <div className="shopBasketItemPreviewDetails">
                    <h4 style={{ margin: "auto", textAlign: "center", marginTop: "10px", marginBottom: "0" }}>ZenC Logo T-Shirt</h4>
                    <p style={{ margin: "auto", textAlign: "center", marginTop: "0px" }}>{tShirtItem.colour} - {tShirtItem.size}</p>
                    <p style={{ margin: "auto", textAlign: "center", marginTop: "0px" }}><b>£19.99</b></p>
              </div>
        </div>
  </div>;
}

export const getStickerPreview = (tShirtItem: any, removeItem?: any) => {
  console.log("making preview for: ");
  console.log(tShirtItem);
  return <div className="shopBasketItemPreview">
    {removeItem?
        <div className="deleteLink zenBasketItemRemove" onClick = {() => removeItem(tShirtItem.id)}></div>
    :null}
        <div style={{ margin: "auto", display: "flex", flexDirection: "row" }}>
              <div className="">
                    <div className="shopBasketItemPreviewPhoto mindfulStickers"></div>
              </div>
              <div className="shopBasketItemPreviewDetails">
                    <h4 style={{ margin: "auto", textAlign: "center", marginTop: "10px", marginBottom: "0" }}>Mindfulness Reminder Stickers ({tShirtItem.amount} pack)</h4>
                    <p style={{ margin: "auto", textAlign: "center", marginTop: "0px" }}><b>{formatOrderPrice(tShirtItem.itemsoldprice)}</b></p>
              </div>
        </div>
  </div>;
}

export const generateItemBasketPreview = (item: any, removeItem?:any) => {
  if (item.type == "TSHIRT") {
        return getTiShirtPreview(item, removeItem);
  } else if (item.type == "STICKER") {
    return getStickerPreview(item, removeItem);
}return null;

}


