import * as React from "react";
import "../../styling/zenverse/Zenverse.scss";
import "../../styling/common/index.scss";
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ZenNavBar } from "../common/navbar/ZenNavBar";
import { useState } from "react";
import PrivateRoute from "../common/PrivateRoute";
import { PuffLoader, BarLoader } from "react-spinners";
import { useStreamService } from "../../hooks/StreamContext";
import ZenContentInterface from "../zencontent/practices/ZenContentInterface";
import { ProvideMyStuffContext, useMyStuffService } from "../../hooks/MyStuffContext";
import { ReelStreamReelItem } from "../zencontent/reels/ReelStreamReelItem";
import axios from "axios";
import { ZEN_C_ENDPOINT } from "../../config/EnvironmentalVariables";
import { ZenLoader } from "../common/ZenLoader";

export const PractitionerReelStream = (props:any) => {
  
  const [pageNumber, setPageNumber] = useState(0);
  const [practitionerReels, setPractitionerReels] = useState([]);
  const [loading, setLoading] = useState(true);

  const loadMoreContent = async (pageNum: number) => {

    //getContentListPreviews
    await axios.get(ZEN_C_ENDPOINT + `/zen/explore/practitionerReels/` + props.username + `/` + pageNum)
          .then(res => {
                console.log("fetching practitioner reels");
                var newReelItems = [];
                var resItems = res.data.map((ri, index) => <ReelStreamReelItem key = {index+'rsi'+ri.id} {...ri} colourCode = {index%4}/>)
                resItems.map(nri => newReelItems.push(nri));
                practitionerReels.map(i2 => newReelItems.push(i2));
                setPractitionerReels(newReelItems);
                setLoading(false);
                setPageNumber(pageNum);
                console.log("set practitioner reels");
          }).catch(() => {
            console.log("Error setting practitioner reels");
                // setSearchPending(false);
          });
  }
  
  React.useEffect(() => {
    loadMoreContent(1);
  },[]);

  const [primaryColor, setPrimaryColor] = useState('#D1603D');

  return (
    <React.Fragment> 
      {loading? <div style = {{paddingTop:"200px"}}><ZenLoader/></div>
      :
    <div className="reelStream">
      <div className="reelStreamColumn">
      {practitionerReels && practitionerReels.length> 0? practitionerReels.filter((ri, index) => index % 2 == 0): null}
    </div>
    <div className="reelStreamColumn">
      <div className="reelSteamSpacer">
        <h2>Reel Stream</h2>
      </div>
      {practitionerReels && practitionerReels.length> 0? practitionerReels.filter((ri, index) => index % 2 == 1): null}
    </div>
    </div>
    }
    </React.Fragment>
  );

}

