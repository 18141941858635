import React, { useState } from 'react';
import "../../styling/zenverse/BuddhaBot.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import Draggable, { DraggableData } from 'react-draggable';

export const MasterBot = (props : any) => {


    const [showZenBotActions, setShowZenBotActions] = useState(false);
    // <Draggable 
    // key={'master-bot'}
    // allowAnyClick={true}>

    return (
 
        <div >
          <div className = "masterBot">
          </div>
        </div>
    );
}

