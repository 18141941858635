import * as React from "react";
import "../../../styling/explore/explore.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router";
import ZenInfomaticInterface from "../../zencontent/infomatics/ZenInfomaticInterface";
import { PracticePreview } from "../../zencontent/practices/previews/PracticePreview";
import ZenCardInterface from "./ZenCardInterface";
import { useZenCardsService } from "../../../hooks/ZenCardsContext";
import { useAuth } from "../../../hooks/auth";
import { ZenPopUpMessage } from "../../common/ZenPopUpMessage";
import axios from "axios";
import { ZEN_C_ENDPOINT } from "../../../config/EnvironmentalVariables";
import { Link } from "react-router-dom";

interface ZenCardUnlockModalInterface extends ZenCardInterface {
    style?: string,
    close?:any
}

export const UnlockZenCardModal = (props: ZenCardUnlockModalInterface) => {

    let zenCardsService = useZenCardsService();
    let auth = useAuth();
    let navigate = useNavigate();

    const [code,setCode] = React.useState(null);  
    const [zenergyStats, setZenergyStats] = React.useState(null);
    const [getTries, setGetTreis] = React.useState(0);
    const [searchPending, setSearchPending] = React.useState(false);


    const getZenergyStats = async () => {
      setSearchPending(true);
      await axios.get(ZEN_C_ENDPOINT + `/myzen/zenergy/getZenergyStats/` + auth.username)
      .then(res => {
          if (res && res.data) {
              setZenergyStats(res.data);
              setSearchPending(false);
          } else {
              setSearchPending(false);
          }
      })
      .catch(function (error) {
        setSearchPending(false);
      });
  
    }
  
    if(!searchPending && getTries <3 && zenergyStats == null){
      setGetTreis(getTries+1);
      getZenergyStats();
    }


    return (

        <ZenPopUpMessage style={props.style}>
            <h2 className="centered-text">Confirm Zen Icon Unlock:</h2>

            <div className="zenCardPreviewLockHolder" style={{ marginTop: "15px" }}>
                <img className="zenCardPreviewPhoto" src={props.image} ></img>
                <div className="zenCardPreviewLock">
                    <div style={{ position: "relative", paddingTop: "65px" }}>{props.zenergyCost}</div>
                </div>
            </div>
            <h2 className="centered-text"><b>{props.title}</b></h2>
            {zenergyStats && zenergyStats.zenergyPoints && zenergyStats.zenergyPoints > props.zenergyCost? 
            <React.Fragment>
            <h4 className="centered-text"><b>Cost:</b> {props.zenergyCost}</h4>
            <h4 className="centered-text"><b>Zenergy Points Remaining:</b>{zenergyStats && zenergyStats.zenergyPoints? zenergyStats.zenergyPoints : 0}</h4>
            </React.Fragment>
            :<React.Fragment>
            <h4 className="centered-text dRedText"><b>Cost:</b> {props.zenergyCost}</h4>
            <h4 className="centered-text dRedText"><b>Zenergy Points Remaining:</b>{zenergyStats && zenergyStats.zenergyPoints? zenergyStats.zenergyPoints : 0}</h4>
            <Link to = "/zenergy/purchase"><h4 className="centered-text dRedText">Looks like you don't have enough ZENergy points... buy more here?</h4></Link>
            </React.Fragment>}
            <input
                type="text"
                placeholder={"Have a code?"}
                value={code}
                onChange={(e) => setCode(e.target.value)}
                className={'su-text-input zen-card-code-input'}
            />
            <div className="zencTextButton" onClick={() => {
                zenCardsService.claimZenCard(auth.username, props.cardKey, code); 
                navigate("/explore/zencards/mycards")}} style={{ marginTop: "15px" }}>
                confirm
            </div>

        </ZenPopUpMessage>
    );

}

