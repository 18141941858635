import React, { useState } from 'react';
import "../../styling/vacana/vacana.scss";
import "../../styling/common/index.scss";
import "../../styling/stream/stream.scss";
import "../../styling/zenverse/Zenverse.scss";
import { EnsoPlayer } from '../media/audio/EnsoPlayer';
import Zenscape from '../zenverse/interfaces/ZenscapeInterface';
import { PortalIcon } from '../zenverse/ZenscapePortal/PortalIcon';
import { ZenverseSidebar } from '../zenverse/ZenverseSidebar';
import ZenscapeInterface from '../zenverse/interfaces/ZenscapeInterface';
import { Link, useNavigate } from "react-router-dom";
import { PracticeCompletePage } from './PracticeCompletePage';
import { getPortalJSX, getPortalPreview } from '../../service/ZenverseService';
import { ZenscapePortal } from '../zenverse/ZenscapePortal/ZenscapePortal';
import { useZenverseService } from '../../hooks/ZenverseContext';
import { ProvideZenAudioContext } from '../../hooks/ZenAudioContext';
import { ZenPopUpMessage } from '../common/ZenPopUpMessage';
import { useAuth } from '../../hooks/auth';
import axios from 'axios';
import { ZEN_C_ENDPOINT } from '../../config/EnvironmentalVariables';
import { ProvideZenJournalContext } from '../../hooks/ZenJournalContext';
import { PracticePortalTimeKeeper } from './PracticePortalTimeKeeper';
import MetaTags from 'react-meta-tags';
import { ZenSplashPopUp } from '../common/ZenSplashPopUp';
import { PortalTimeBoostPowers } from '../explore/icons/CardSubtypes';
import { ProvideMyStuffContext } from '../../hooks/MyStuffContext';
import { PracticePortalAdContainer } from './PracticePortalAdContainer';

interface PracticePortalState {
  activePortal: string,
  started: boolean,
  practicePortalQuotaStarted: boolean,
  playing: boolean,
  zenscape: any,
  totalZenTime: any
}

interface PracticePortalProps {
  duration?: any,
  curTime?: number,
  setPracticePlaying: any,
  setVideoStarted?: any,
  practicePlaying?: boolean,
  finished?: boolean,
  setFinished?: any,
  finishPage?: any,
  setClickedTime?: any,
  children?: any,
  guide?: boolean,
  practice?: any,
  zenScapeOvverride?: boolean,
  practiceZenergyBoost?: number,
  practiceId?: any
}

export const ZenPracticePortal = (props: PracticePortalProps) => {

  const [zenPracticePortalState, updatePracticePortalState] = useState({} as PracticePortalState);
  const [zenScapes, setZenscapes] = React.useState(null as Zenscape[]);
  const [outOfTime, setShowOutOfTime] = React.useState(false);
  const [showOutOfTimeWarning, setShowOutOfTimeWarning] = React.useState(false);
  const [outOfTimeNotified, setShowOutOfTimeNotified] = React.useState(false);
  const [showOutOfTimeAd, setShowOutOfTimeAd] = React.useState(false);

  const practicePortalQuotaTimeCheckPeriod = 2;
  const [timeMeditated, setTimeMeditated] = useState(0);

  const addToTime = (val: any) => {
    setTimeMeditated(timeMeditated + val);
  }

  let zenverse = useZenverseService();
  let navigate = useNavigate();

  if (zenScapes == null && zenverse.myPortals != null && !props.zenScapeOvverride) {
    setZenscapes(zenverse.myPortals);
  } else if (zenScapes == null && !zenverse.searchPending && !props.zenScapeOvverride) {
    zenverse.getUserPortals();
  }

  const zenPortalsIcons = zenScapes != null && zenScapes.length > 0 ? zenScapes.map((portal) => {
    return <PortalIcon
      {...portal}
      preview={getPortalPreview(portal, (portal) => loadZenscapeJSX(portal))}
      jsx={getPortalJSX(portal, false)}
      loadZenscapeJSX={(scape) => loadZenscapeJSX(scape)}
      key={portal.id}></PortalIcon>;
  }) : [];

  var practiceIcon = props && props.practice ? <PortalIcon practiceiconPlaying={props.practicePlaying} practiceicon={true} loadZenscapeJSX={(scape) => {
    updatePracticePortalState({
      activePortal: "practice-only",
      started: zenPracticePortalState.started,
      playing: zenPracticePortalState.playing,
      zenscape: scape,
      practicePortalQuotaStarted: zenPracticePortalState.practicePortalQuotaStarted,
      totalZenTime: zenPracticePortalState.totalZenTime
    });
  }} key={"practice123"} details={props.practice as ZenscapeInterface}></PortalIcon> : null;

  // Used by Practice play state controllers such as Enso Timer
  const togglePracticePlaying = (playing_value: boolean) => {
    var newActivePortal = zenPracticePortalState.activePortal == "zenscape-preview" ? "practice-only" :
      zenPracticePortalState.activePortal == "zenscape" ? "zenscape-preview" :
        zenPracticePortalState.activePortal;

    updatePracticePortalState({
      activePortal: newActivePortal,
      started: true,
      playing: playing_value ? playing_value : zenPracticePortalState.playing,
      zenscape: zenPracticePortalState.zenscape,
      practicePortalQuotaStarted: true,
      totalZenTime: zenPracticePortalState.totalZenTime
    });
    props.setPracticePlaying(playing_value);
    props.setVideoStarted(playing_value);
  }

  const loadZenscapeJSX = (scape: ZenscapeInterface, activePortal?: string) => {
    var oldActivePortal = activePortal ? activePortal : zenPracticePortalState.activePortal;
    var newActivePortal = oldActivePortal == "practice" || oldActivePortal == "practice-only" ? "zenscape-preview" :
      oldActivePortal == "zenscape-preview" && zenPracticePortalState.zenscape && zenPracticePortalState.zenscape.id != scape.id ? "zenscape-preview" :
        oldActivePortal == "zenscape-preview" && zenPracticePortalState.zenscape && zenPracticePortalState.zenscape.id == scape.id ? "zenscape" : "zenscape-preview";

    updatePracticePortalState({
      activePortal: newActivePortal,
      started: zenPracticePortalState.started,
      playing: zenPracticePortalState.playing,
      zenscape: scape,
      practicePortalQuotaStarted: true,
      totalZenTime: zenPracticePortalState.totalZenTime
    });
  }

  const showAd = () => {
    setShowOutOfTimeNotified(true);
    const fifteenMinInterval = 15 * 60 * 1000;
    setInterval(() => {
      showAdFor21();
    }, fifteenMinInterval);

    // Also show immediately:
    showAdFor21();

  }

  const showAdFor21 = () => {
    setShowOutOfTimeAd(true);
    setTimeout(() => {
      setShowOutOfTimeAd(false);
    }, 21000);
  }

  return (
    <ProvideZenAudioContext>
      <React.Fragment>
        <MetaTags>
          <link rel="icon" href={props.practice && props.practice.image ? props.practice.image : require("../../static/photos/zencyclogo.png")} />
          <meta id="meta-description" name="description" content={"The Zen Practice portal is the place to enjoy your favourite meditations supplemented by a range of mindfulness aids."} />
          <meta id="og-title" property="og:title" content="Zen Practice Portal" />
          <meta id="og-image" property="og:image" content={props.practice && props.practice.image ? props.practice.image : require("../../static/photos/zencyclogo.png")} />
          <meta id="og-icon" property="og:icon" content={props.practice && props.practice.image ? props.practice.image : require("../../static/photos/zencyclogo.png")} />
        </MetaTags>
        <PracticePortalTimeKeeper addTime={(val) => addToTime(val)} practicePortalQuotaStarted={zenPracticePortalState.started} outOfTime={() => setShowOutOfTime(true)} showOutOfTimeWarning={() => setShowOutOfTimeWarning(true)} />

        <div className='practice-portal-container'>

          {!props.finished ?
            <ZenverseSidebar loading={zenverse.searchPending} zenPortalsIcons={[practiceIcon, ...zenPortalsIcons]} practiceGuide={props.guide ? practiceIcon : null} setActivePortal={(portal) => updatePracticePortalState({
              activePortal: portal,
              started: zenPracticePortalState.started,
              playing: zenPracticePortalState.playing,
              zenscape: zenPracticePortalState.zenscape,
              practicePortalQuotaStarted: zenPracticePortalState.practicePortalQuotaStarted,
              totalZenTime: zenPracticePortalState.totalZenTime
            })}></ZenverseSidebar>
            : null
          }

          {outOfTime && !outOfTimeNotified ?
            <div>
              <ZenSplashPopUp>
                <h2>Oh No!</h2>
                You've run out of Ad-Free Zen Portal time.

                <Link to={"/explore/zencards/" + PortalTimeBoostPowers.DisplayName.trim()}><div className="zenGlowButton navajoGlowButton">Claim a Zen Card and gain more time</div></Link>
                {/* <li>Subscribe so this doesn't happen again!</li> */}
                or wait until your Zen Portal quota renews - basic tier users get 100 free minful minutes every week!

                <div className="zenGlowButton navajoGlowButton" onClick={() => showAd()}>ok</div>
              </ZenSplashPopUp>
            </div>

            : showOutOfTimeWarning ?
              <div>
                <ZenSplashPopUp>
                  <h2>You've almost used up all of your Ad-Free Zen Portal Time!</h2>
                  <h4>(you have <b>15</b> minutes left!)</h4>
                  <ul>
                    <Link to={"/explore/zencards/" + PortalTimeBoostPowers.DisplayName.trim()}><li><div className="zenGlowButtonNavajoGlowButton">Claim a Zen Card and gain more time</div></li></Link>
                    {/* <li>Subscribe so this doesn't happen again!</li> */}
                    <li>or wait until your Zen Portal quota renews - basic tier users get 100 free minutes every month.</li>
                  </ul>
                  <div className="zenGlowButton navajoGlowButton" onClick={() => setShowOutOfTimeWarning(false)}>ok</div>
                </ZenSplashPopUp>
              </div>

              : showOutOfTimeAd ?
                <PracticePortalAdContainer />
                :

                <div className='practice-portal-content-container'>

                  <div >
                    {/* Portal Exit Icon */}
                    {!props.finished && zenPracticePortalState.activePortal != "preview" && zenPracticePortalState.started ? <></> : <div className='portal-exit-icon' onClick={() => {
                      console.log("back clicked.");
                      navigate("/");
                    }}></div>}

                    {/* Practice Controls (Play, Pause) */}
                    {!(props.finished || zenPracticePortalState.activePortal == "preview") ?
                      <div className='practice-portal-timer-holder'>
                        <EnsoPlayer activePortal={zenPracticePortalState.activePortal} setActivePortal={(portal) => updatePracticePortalState({
                          activePortal: portal,
                          started: zenPracticePortalState.started,
                          playing: zenPracticePortalState.playing,
                          zenscape: zenPracticePortalState.zenscape,
                          practicePortalQuotaStarted: zenPracticePortalState.practicePortalQuotaStarted,
                          totalZenTime: zenPracticePortalState.totalZenTime
                        })} curTime={props.curTime} duration={props.duration} playing={props.practicePlaying} volume={40} setPlaying={(playing) => togglePracticePlaying(playing)} setClickedTime={(time) => props.setClickedTime(time)} setVolume={(volume) => { }} zenscape_volume={null} zenscape_setVolume={(volume) => { }}></EnsoPlayer>

                        {/* Practice Finish Icon */}
                        {zenPracticePortalState.started ?
                          <div className='zen-portal-finish-icon' onClick={props.setFinished ? () => props.setFinished(true) : () => { }}>
                            <div className="zenscape-back-icon"></div>
                          </div>
                          : null}

                      </div>
                      : null
                    }

                  </div>

                  {/* Active Zen Portal Holder */}
                  {!props.finished && (zenPracticePortalState.activePortal != "practice-only" && zenPracticePortalState.zenscape) ?
                    <div className='zenscape-practice-portal-holder'>
                      <ZenscapePortal {...zenPracticePortalState.zenscape} smallImage={true} active={zenPracticePortalState.activePortal == "zenscape"} key={zenPracticePortalState && zenPracticePortalState.zenscape ? "zpp" + zenPracticePortalState.zenscape.id : "zppN"} controlsProvided={true} jsx={getPortalJSX(zenPracticePortalState.zenscape, false)} activePortal={zenPracticePortalState.activePortal} loadZenscapeJSX={(scape, activePortal) => loadZenscapeJSX(scape, activePortal)} > </ZenscapePortal>
                    </div>
                    : null}

                  {/* Zen Practice Item Holder */}
                  {!props.finished && zenPracticePortalState.activePortal != "zenscape-only" ?
                    <div className="practice-portal" style={zenPracticePortalState.activePortal == "practice" || zenPracticePortalState.activePortal == "practice-only" || zenPracticePortalState.activePortal == null ? { display: 'block' } : { display: 'none' }}>
                      {props.children}
                    </div>
                    : null}

                </div>
          }
          {/* Practice Completion Page */}
          {props.finished && props.finishPage ?
            <>{props.finishPage}</>
            : props.finished ?
              <ProvideMyStuffContext>
                <ProvideZenJournalContext>
                  <PracticeCompletePage id={props.practiceId ? props.practiceId : props && props.practice ? props.practice.id : null} duration={timeMeditated} practiceZenergyBoost={props.practiceZenergyBoost} practice={props.practice} />
                </ProvideZenJournalContext>
              </ProvideMyStuffContext> : null}
        </div>

      </React.Fragment>
    </ProvideZenAudioContext>
  );




}