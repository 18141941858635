import * as React from "react";
import "../../../styling/explore/explore.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

export const ZencyclopaediaItemPreview = (props: { id?: number, title: string, image: any, photoBottom?: boolean, itemType?: string, translation?: string, itemResultId?: string, searchRoute?: string, clickAction?: any }) => {


    const contentsItemLink = props.itemType && props.itemType == 'Infomatic' ?
        "/info/"
        : props.itemType && props.itemType == 'ZencycResultItem' ?
            "/search/" + props.itemResultId
            :
            props.itemType && props.itemType == 'zenShop' ?
                "/shop"
                :
                props.itemType && props.itemType == 'medBrowser' ?
                    "/search/2"
                    :
                    props.itemType && props.itemType == 'zenverse' ?
                        "/zenverse"
                        : props.searchRoute ? props.searchRoute

                            : props.id ? props.id.toString()
                                :
                                "";

    var holderClass = props.photoBottom ? 'zencyclopaedia-contents-item' : 'zencyclopaedia-contents-item zencyclopaedia-contents-item-alt';

    if (props.itemType == "ZENSCAPE") {
        holderClass = holderClass + " zenscape-contents-item";
    } else if (props.itemType == "ZENC_INFO_GROUP") {
        holderClass = holderClass + " zenc-article-contents-item";
    }


    return (
        <div>
            <Link to={contentsItemLink}>

                <div className={holderClass} onClick={props.clickAction ? () => props.clickAction() : () => { }}>
                    {props.photoBottom ?
                        <>
                            <h1 className="zencyc-contents-item-header zenK">{props.title} <br /><span className='zencyc-contents-item-header-trans'>{props.translation}</span></h1>
                            <img className="zencyc-contents-item-image" src={props.image ? props.image : null} ></img>
                        </>
                        :
                        <>
                            <img className="zencyc-contents-item-image" src={props.image ? props.image : null} ></img>
                            <h1 className="zencyc-contents-item-header-bottom zenK"><b>{props.title}</b> <span className='zencyc-contents-item-header-trans'>{props.translation}</span></h1>
                        </>
                    }
                </div>
            </Link>
        </div>

    );

}

