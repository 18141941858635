import * as React from "react";
import { MyZenNavBar } from "../common/navbar/MyZenNavBar";
import "../../styling/common/index.scss";
import { ZenNavBar } from "../common/navbar/ZenNavBar";
import { useState } from "react";
import axios from "axios";
import { useAuth } from "../../hooks/auth";
import PrivateRoute from "../common/PrivateRoute";
import { useParams } from "react-router-dom";
import { ZEN_C_ENDPOINT } from "../../config/EnvironmentalVariables";
import PractitionerInterface from "./PractitionerInterface";
import { getContentListPreviews, getContentSliderPreviews } from "../../service/ContentService";
import { PractitionerNavBar } from "./PractitionerNavBar";
import { PractitionerContent } from "./PractitionerContent";
import Slider from "react-slick";
import { ExploreSliderPreview } from "../explore/previews/ExploreSliderPreview";
import ZenContentInterface from "../zencontent/practices/ZenContentInterface";
import { ZenLoader } from "../common/ZenLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { getStreamJSX, getStreamJSXNoAds } from "../../service/StreamService";

export const PractitionerProfileHome = (props: { practitionerInfo?: any, setActivePage?:any }) => {


      const { practitonerId } = useParams();
      const [pageNumber, setPageNumber] = useState(0);

      const [getContentAttempts, setGetContentAttempts] = useState(0);
      const [searchPending, setSearchPending] = useState(null);
      const [practitionerContent, setPractitionerContent] = useState(null as ZenContentInterface[]);
      const [focusedItemIndex, setFocusedItem] = useState(null);

      const loadPractitionerContent = () => {
            loadMoreContent(1);

      }

      const loadMoreContent = async (pageNum: number) => {

            //getContentListPreviews
            await axios.get(ZEN_C_ENDPOINT + `/zen/explore/practitioner/` + props.practitionerInfo.username + `/` + pageNum)
                  .then(res => {
                        console.log("got practitioner content");
                        setPractitionerContent(res.data);
                        setSearchPending(false);
                        setPageNumber(pageNum);
                  }).catch(() => {
                        setSearchPending(false);
                  });

      }
      if (props.practitionerInfo != null && practitionerContent == null && getContentAttempts < 5 && !searchPending) {
            setGetContentAttempts(getContentAttempts + 1);
            setSearchPending(true);
            loadPractitionerContent();
      }
      const sliderRef: any = React.createRef();
      const desktopSliderRef: any = React.createRef();

      return (
            <div style = {{paddingBottom:"150px"}}>
                  <div className = "zenGlowButton readingListButton" onClick = {() => props.setActivePage("readingList")}><FontAwesomeIcon icon={faBook} className = "rlbi"/> Recommended Reading List</div>
                  <div style={{ display: "flex", flexDirection: "row", width: "100%", maxWidth:"450px", margin:"auto" }}>
                        <span className="paintedLine"></span><h4 style={{ marginTop: "5px", width: "200px", margin: "auto", textAlign: "center" }}>Hot Content</h4><span className="paintedLine"></span>
                  </div>
                  {focusedItemIndex? 
                  <div className = "sliderFocusedItemHolder" >
                        {getContentSliderPreviews([practitionerContent[focusedItemIndex]], () => setFocusedItem(null), true)}
                  </div>
                  
                  :practitionerContent != null ?
                        <div className="zenverseSliderHolder">
                              <div className="zen-mobile">
                              {getStreamJSXNoAds(practitionerContent.slice(0, 10))}
                                    {/* <Slider
                                          centerMode={true}
                                          centerPadding = '15%'
                                          autoplay={true}
                                          ref={sliderRef}
                                          infinite={true}
                                    >
                                          {getContentSliderPreviews(practitionerContent.slice(0, 10))}
                                    </Slider> */}
                              </div>
                              <div className="zen-desktop">
                                    <Slider
                                          centerMode={true}
                                          centerPadding='40%'
                                          autoplay={true}
                                          ref={desktopSliderRef}
                                          infinite={true}
                                    >
                                          {getContentSliderPreviews(practitionerContent.slice(0, 10), (item) => setFocusedItem(item))}
                                    </Slider>
                              </div>
                        </div>
                        : <ZenLoader />
                  }
            </div>

      );

}
