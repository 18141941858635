import React, { useState } from 'react';
import "../../../styling/vacana/vacana.scss";
import "../../../styling/common/index.scss";
import PathInterface from './PathInterface';
import { Link } from 'react-router-dom';

export const PathSliderPreview = (props : PathInterface) => {

    return (
      <div className="zenscape-slider-item content-rec-slider-item path-slider-item" >
        <img className={"med-slider-preview-image"} src={props.image ? props.image : null} ></img>
        <h1 className="path-slider-title zenK"><b>{props.title}</b> </h1>
        <h4 className="explore-slider-description">{props.description? props.description.substring(0,100) : ""}</h4> 
      <Link to = {"/explore/course/"+props.id}>
        <div className = "zenGlowButton navajoGlowButton">
          See More
        </div>
      </Link>
      </div>
    );
}