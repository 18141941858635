import React, { useState } from 'react';
import "../../../styling/stream/stream.scss";
import "../../../styling/common/index.scss";
import { TextNugget } from './TextNugget';
import { ImageNugget } from './ImageNugget';

export const ZenNugget = (props : any) => {




  return (
    <React.Fragment>
      {props.nuggetType == "TEXT"?
        <TextNugget streamItem = {props.streamItem}
        {...props}
        ></TextNugget>
      :props.nuggetType == "IMAGE"?
      <ImageNugget  streamItem = {props.streamItem}
      {...props}
      ></ImageNugget>
      :
      <div className = {props.streamItem? "zen-nugget-stream-item stream-item " : "zen-nugget-stream-item list-item"}  >
      <div className='zen-nugget'>
        <h1 className='nugget-title okashi'></h1>
        <h2 className='nugget-content zenK'>
          {props.description}
        </h2>
        <h2 className='nugget-content zenK'>
          {props.nuggetType == "QOUTE" && props.additionalText?
            <>
              - {props.additionalText}
            </>
          :props.nuggetType == "NEWS"?
                <div className = "zenGlowButton sageGlowButton" onClick = {() => {window.open(props.additionalText, '_blank');}}>
                  Read All About It
                </div>
          :
          <>
          </>
          }
        </h2>

      </div>
  
    </div>
      }
    </React.Fragment>

    
  );
}