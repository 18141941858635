import * as React from "react";
import { MyZenNavBar } from "../common/navbar/MyZenNavBar";
import "../../styling/common/index.scss";
import { ZenNavBar } from "../common/navbar/ZenNavBar";
import { useState } from "react";
import axios from "axios";
import { useAuth } from "../../hooks/auth";
import PrivateRoute from "../common/PrivateRoute";
import { useParams } from "react-router-dom";
import { ZEN_C_ENDPOINT } from "../../config/EnvironmentalVariables";
import PractitionerInterface from "./PractitionerInterface";
import { getContentListPreviews } from "../../service/ContentService";
import { PractitionerNavBar } from "./PractitionerNavBar";
import { PractitionerContent } from "./PractitionerContent";
import { PractitionerProfileHome } from "./PractitionerProfileHome";
import { useMyCommunityService } from "../../hooks/MyCommunityContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { PractitionerReadingList } from "./PractitionerReadingList";
import { PractitionerReelStream } from "./PractitionerReelStream";
import { ZenPicSizer } from "./ZenPicSizer";

export const PractitionerProfile = (props: { practitionerInfo?: any }) => {


      const { practitonerId } = useParams();

      const communityService = useMyCommunityService();

      const [activePage, setActivePage] = useState("home");
      const [practitionerAttempts, setPractitionerAttempts] = useState(0);
      const [loadingPractitioner, setLoadingPractitioner] = useState(false);
      const [showFollowButton, setShowFollowButton] = useState(true);
      const [practitionerInfo, setPractitionerInfo] = useState(null);

      const  handleScroll = async (e) => {

      
            if(e.target.scrollTop> 190 && showFollowButton){
                  setShowFollowButton(false);
                  console.log("stopping");
            } else if(e.target.scrollTop < 190 && !showFollowButton){
                  setShowFollowButton(true);
            }
           
      
          }

      const getPractitionerInfo = async () => {
            await axios.get(ZEN_C_ENDPOINT + `/myzen/practitioner/` + practitonerId)
                  .then(res => {
                        setPractitionerInfo(res.data);
                        setLoadingPractitioner(false);
                  }).catch(() => {
                        setLoadingPractitioner(false);
                  });
      }

      React.useEffect(() => {
            getPractitionerInfo();
      }, []);

      const links = practitionerInfo && practitionerInfo.links && practitionerInfo.links.split('|').length > 0 ? practitionerInfo.links.split('|') : null;

      return (

            <div className="MyZenProfile">
                  <ZenNavBar />
                  <PractitionerNavBar
                        setPage={setActivePage} />
                  {activePage == "ProfilePic" ?
                        <div className="ZoomedProfileItemHolder">
                              <div className="ZoomedProfileItem">
                                    <div className="CloseZoomedProfileItem" onClick={() => setActivePage("home")}></div>
                                    <img
                                          className={"ZoomedProfilePic"}
                                          src={practitionerInfo && practitionerInfo.image != null ? practitionerInfo.image : null}>
                                    </img>
                              </div>
                        </div>
                        : activePage == "fullBio" ?
                              <div className="ZoomedProfileItemHolder">
                                    <div className="ZoomedProfileItem">
                                          <div className="CloseZoomedProfileItem" onClick={() => setActivePage("home")}></div>
                                          <h4 style={{ padding: "15px" }}>{practitionerInfo ? practitionerInfo.bio : null}</h4>
                                    </div>
                              </div>
                              : null}
                  <div className="practitionerProfileMainArea" onScroll={(e) => handleScroll(e)}>
                        {activePage == "Reel" ? <PractitionerReelStream username={practitonerId}></PractitionerReelStream>
                              : activePage == "home" || activePage == "ProfilePic" ?
                                    <div  style={activePage == "ProfilePic" ? { opacity: "0.7" } : {}}>
                                          {showFollowButton?
                                          <React.Fragment>
                                          <div 
                                          className = "zenGlowButton mingGlowButton followPractitionerButton"
                                          onClick={() => {
                                                communityService.followPractitioner(practitionerInfo.username);
                                          }}>
                                          { practitionerInfo && communityService.checkIfUserFollowsPractitioner(practitionerInfo.username) ?
                                               <p>Unfollow</p>
                                                :
                                                <p>Follow</p>
                                          }
                                          </div>
                                          </React.Fragment>
                                          :null
                                    }
                                         

                                          <div style={{ margin: "auto", marginTop: "60px", width: "100%", minHeight: "200px" }}>
                                                {practitionerInfo && practitionerInfo.image != null && activePage != "ProfilePic" ?
                                                      <div style={practitionerInfo && practitionerInfo.bio ? { margin: "auto", minWidth: "45%" } : { margin: "auto" }}>
                                                            <div
                                                                  className={"practitionerProfilePic"}
                                                                  onClick={() => setActivePage("ProfilePic")}>
                                                                  {practitionerInfo && practitionerInfo.image != null ?
                                                                        <ZenPicSizer
                                                                              imageToShow={practitionerInfo && practitionerInfo.image != null ? practitionerInfo.image : null}
                                                                              cropX={practitionerInfo.imageCropX}
                                                                              cropY={practitionerInfo.imageCropY}
                                                                              cropWidth={practitionerInfo.imageCropWidth}
                                                                              newWidth={100}
                                                                        /> : null}
                                                            </div>
                                                            <h3 className=" zenK myzenDetails-username" style = {{marginBottom:0}}>{practitionerInfo ? practitionerInfo.username : null}</h3>
                                                      </div> : null}
                                                {practitionerInfo && practitionerInfo.bio ?
                                                      <div style={{ margin: "auto", textAlign: "justify", padding: "25px", paddingTop:0, maxWidth: "95%" }}>
                                                            <span onClick={() => setActivePage("fullBio")}>
                                                                  {practitionerInfo.bio.substring(0, 250)}{practitionerInfo.bio.length > 250 ? "..." : null}
                                                            </span>
                                                           
                                                      </div>
                                                      : null}
                                          </div>
                                          
                                          {links && links.length > 0 ?
                                                                  <div style={{ marginTop: "5px" }}>
                                                                        {links.map(l => <div
                                                                              className="zenPractitionerBlogLink"
                                                                              onClick={() => { window.open(l, '_blank'); }}>
                                                                              <FontAwesomeIcon icon={faLink} className="pbLink" />
                                                                              {l}
                                                                        </div>)}
                                                                  </div> : null}

                                          <PractitionerProfileHome
                                                practitionerInfo={practitionerInfo}
                                                setActivePage={setActivePage} />
                                    </div>
                                    : activePage == "readingList" ?
                                          <PractitionerReadingList userId={practitonerId} />
                                          : activePage == "content" ?
                                                <div style={{ marginTop: "25px", maxWidth: "100vw" }}>
                                                      <PractitionerContent
                                                            practitionerInfo={practitionerInfo}
                                                            username={practitonerId} />
                                                </div>

                                                : <div style={{ margin: "auto", textAlign: "center", marginTop: "175px" }}>
                                                      <h2>Zen Reels coming soon!</h2>
                                                </div>
                        }
                  </div>

            </div>

      );

}
