import React from 'react';
import "../../../../styling/vacana/vacana.scss";
import "../../../../styling/common/index.scss";
import "../../../../styling/stream/stream.scss";
import { Link } from 'react-router-dom';
import { useMyStuffService } from '../../../../hooks/MyStuffContext';
import { PracticePreviewInterface } from './PracticePreview';

export const PracticePreviewControls = (props : PracticePreviewInterface) => {

  const myStuff = useMyStuffService();
  const favourited = myStuff.favourites && myStuff.favourites.map(b => b.contentId).includes(props.id);
  const bookmarked = myStuff.bookmarks && myStuff.bookmarks.map(b => b.contentId).includes(props.id);

  var practicePortalLink = props.summary? "/practice/"+props.id : "/meditation/"+props.id;

    return (
          <div className = "practice-preview-controls">

                {props.listItem == 'Bookmark'? 
                  <div className = "remove-practice-preview-symbol" onClick = {() => myStuff.removeBookmark(props.id)}></div>
                  : props.listItem == 'Favourite'? 
                  <div className = "remove-practice-preview-symbol" onClick = {() => myStuff.removeFavourite(props.id)}></div>

                  : props.listItem ?
                  <>
                  <div  className = {favourited? "list-item-control PracticePreviewFavourited" : "list-item-control PracticePreviewFavourite"} onClick={() => {favourited? myStuff.removeFavourite(props.id) : myStuff.addFavourite(props.id)}}></div>
                  <div  className = {bookmarked? "list-item-control PracticePreviewBookmarked" : "list-item-control PracticePreviewBookmark"} onClick={() => {bookmarked? myStuff.removeBookmark(props.id) : myStuff.addBookmark(props.id)}}></div>
                  </>
                  :
                  <>
                  <div  className = {favourited? "PracticePreviewFavourited" : "PracticePreviewFavourite"} onClick={() => {favourited? myStuff.removeFavourite(props.id) : myStuff.addFavourite(props.id)}}></div>
                  <div  className = {bookmarked? "PracticePreviewBookmarked" : "PracticePreviewBookmark"} onClick={() => {bookmarked? myStuff.removeBookmark(props.id) : myStuff.addBookmark(props.id)}}></div>
                  </>
                  }
                
            <Link to={practicePortalLink} style = {{margin:"auto"}}>
                <div  className = {props.listItem? "PracticePreviewStartArrow PracticePreviewStartArrow-small" : "PracticePreviewStartArrow"}></div>
            </Link>
          </div>
   );
}