import * as React from "react";
import { useState } from "react";

import "../../../styling/myzen/myzen.scss";
import { useMyStuffService } from "../../../hooks/MyStuffContext";
import { PracticePreview } from "../../zencontent/practices/previews/PracticePreview";
import { useZenExploreService } from "../../../hooks/ZenExploreContext";
import PuffLoader from "react-spinners/PuffLoader";
import { useZenSearchService } from "../../../hooks/ZenSearchContext";
import { ZenSearchBar } from "../../explore/search/ZenSearchBar";

export const LinkPracticeToPlannerSessionModal = (props: any) => {

      const [search, setSearch] = useState(null);
      const [ready, setReady] = useState(false);
      const [sessionToConfirm, setSessionToConfirm] = useState(null);
      const [results, setResults] = useState(null);
      const [resultsType, setResultsType] = useState(null);
    
      const useSearch = useZenSearchService();
      const useMyStuff = useMyStuffService();
      
      const updateSearch = async (searchKey: string, tags: string[]) => {
        //setSearchKey(searchKey + tags.join(","));
        console.log("updating search");
        let result = await useSearch.filterMeditationsByTag(searchKey, tags);
        setResults(result.map(m => <PracticePreview {...m}/>));
      }

      const setStartReccomendations = async () => {

            console.log("Setting Start Reccomendations");
            var favs = await useMyStuff.fetchFavourites();
            var bms = await useMyStuff.fetchBookmarks();
            
            let result = await useSearch.filterMeditationsByTag("beginner", []);
            let recommendedPractices = bms.concat(favs).concat(result);
            setResults(recommendedPractices);
            setReady(true);
      }

      React.useEffect(() => {
            setStartReccomendations();
      },[]);

      return (

            <React.Fragment>
                  <h4 className="link-session-title">Find a practice to link to this session:</h4>
                  <ZenSearchBar
                        updateSearch={(searchKey, tags) => updateSearch(searchKey, tags)} />
                  {!ready ?
                        <div className="zencLoader">
                              <PuffLoader color="#033f63" size={250}>
                              </PuffLoader>
                        </div>
                        : props.chosenLinkSession == null || (props.chosenLinkSession && props.chosenLinkSession.title == null) ?
                              <div className="recommended-link-session-holder">
                                    {results && results.length > 0 ?
                                          results.map(p => <div key={"practice" + p.title} onClick={(e) => {
                                                e.preventDefault();
                                                setSessionToConfirm(p);
                                          }}><PracticePreview 
                                          listItem={'Bookmark'} {...p} 
                                          unclickable={true} 
                                          selectedItem = {sessionToConfirm && sessionToConfirm.id == p.id}></PracticePreview></div>)
                                          : <h3>No Sessions found.</h3>}
                              </div>
                              :
                              <div style={{ paddingTop: "35px" }}>
                                    <PracticePreview listItem={'Bookmark'} {...props.chosenLinkSession}></PracticePreview>
                              </div>
                  }
                  {sessionToConfirm != null?
                  <div className = "confirmChooseSessionBanner">
                        <div className = "chooseSessionBannerButton" onClick={() => props.setChosenLinkedSession(sessionToConfirm)}>Choose Session
                        </div>
                        <h4 style = {{textAlign:"center"}}>Title: {sessionToConfirm.title}</h4>
                  </div>
                  :null}
            </React.Fragment>
      );

}