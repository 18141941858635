import React, { useState } from 'react';
import "../../../styling/vacana/vacana.scss";
import "../../../styling/common/index.scss";
import "../../../styling/common/zenCoreUIComponents.scss";
import { Link } from 'react-router-dom';
import { useZenJournalService } from '../../../hooks/ZenJournalContext';
import { ZEN_C_ENDPOINT } from '../../../config/EnvironmentalVariables';
import axios from 'axios';


export const PracticeListItem = (props: { practice?: any, practiceId?: number, number?: number }) => {

  const [practiceDetails, setPracticeDetails] = React.useState(null);

  let practiceLogService = useZenJournalService();

  if (practiceLogService && practiceLogService.journalLogs == null) {
    let result = practiceLogService.getUserJournal();
  }

  let completedPractices = practiceLogService && practiceLogService.journalLogs && practiceLogService.journalLogs.length > 0 ? practiceLogService.journalLogs.filter(e => (e != null && e.practiceLog != null)).map(pl => pl.practiceLog.practiceId) : [];
  var practiceSummaryLink = props.practice ? "/meditation/" + props.practice.id : "/meditation/" + props.practiceId;

  var userHasCompletedPractice = completedPractices.includes(props.practiceId);

  var holderName = userHasCompletedPractice ? "practice-list-item practice-list-item-completed" : "practice-list-item";

  React.useEffect(() => {
    if(props.practice && props.practice.image !=null){
      setPracticeDetails(props.practice)
    } else if (props.practice && props.practice.id != null) {
       axios.get(ZEN_C_ENDPOINT+`/zen/content/`+props.practice.id)
      .then(res => {
        setPracticeDetails(res.data);
      })
    } else if (props.practiceId){
      axios.get(ZEN_C_ENDPOINT+`/zen/content/`+props.practice.id)
      .then(res => {
        setPracticeDetails(res.data);
      })
    }
  },[]);

  
  
  return props && props.practice ? (

    <React.Fragment>
      <Link to={practiceSummaryLink}>
        <div className={holderName}>
          <div className='practiceListItemDetails'>
            <div className="zenK practice-list-item-title">
              {practiceDetails ? practiceDetails.title : null}
            </div>
            {practiceDetails && practiceDetails.image != null ?
              <img className="practiceListItemPhoto" src={practiceDetails.image} ></img> :
              <></>
            }
          </div>
        </div>
      </Link>
    </React.Fragment>
  ) : null;
}