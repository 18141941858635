import * as React from "react";
import "../../../styling/explore/explore.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { ZEN_C_ENDPOINT } from "../../../config/EnvironmentalVariables";
import { Link } from "react-router-dom";
import { useMyCommunityService } from "../../../hooks/MyCommunityContext";


export const PractitionerPreview = (props: { practitioner?: any, practitionerId?: string, linkOnClick?: any }) => {

    const [practitionerAttempts, setPractitionerAttempts] = React.useState(0);
    const [loadingPractitioner, setLoadingPractitioner] = React.useState(false);
    const [readMore, setReadMore] = React.useState(false);
    const [practitionerInfo, setPractitionerInfo] = React.useState(props.practitioner != null ? props.practitioner : null);

    const communityService = useMyCommunityService();

    const getPractitionerInfo = async () => {
        setLoadingPractitioner(true);
        await axios.get(ZEN_C_ENDPOINT + `/myzen/practitioner/` + props.practitionerId)
            .then(res => {
                setPractitionerInfo(res.data);
                setLoadingPractitioner(false);
            }).catch(() => {
                setLoadingPractitioner(false);
            });
    }

    if (!practitionerInfo && !loadingPractitioner && practitionerAttempts < 5) {
        setPractitionerAttempts(practitionerAttempts + 1);
        getPractitionerInfo();
    }

    const inner = practitionerInfo ? 
    <div className={props.practitioner && props.practitioner.pracitionerType == "COMMUNITY"? 'practitioner-preview-item communityPractitionerItem' :'practitioner-preview-item'}>
        <div className='practice-preview'>
            <div className="pract-preview-image-fitter">
                <img className="content-preview-image practitioner-preview-image" src={practitionerInfo.image}></img>
            </div>

            <div style={{ width: "fit-content", margin: "auto" }}>
                <h4 className="practitioner-preview-title">{practitionerInfo.username}</h4>
                {practitionerInfo && communityService.checkIfUserFollowsPractitioner(practitionerInfo.username) ?
                    <div className="zenGlowButton buddGlowButton">Unfollow</div>
                    :
                    <div className="zenGlowButton sageGlowButton" onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        communityService.followPractitioner(practitionerInfo.username);
                    }}>Follow</div>
                }
            </div>
        </div>
        <p>{practitionerInfo.bio ? practitionerInfo.bio.substring(0, 150) : null}</p>
    </div> : null;

    return (
        <React.Fragment>
            {practitionerInfo != null ?
                <React.Fragment>
                    {!props.linkOnClick ?
                        <div onClick={() => {
                            if (practitionerInfo && practitionerInfo.bio != null) {
                                setReadMore(true);
                            }
                        }}>
                            {readMore ?
                                <div className='zencyclopaedia-content-list-item practice-preview' onClick={() => setReadMore(false)}>
                                    {practitionerInfo.bio}
                                </div>
                                : <div>
                                    {inner}
                                </div>}

                        </div>
                        : <Link to={"/practitioner/" + practitionerInfo.username}>
                            {inner}
                        </Link>}
                </React.Fragment>
                : null}
        </React.Fragment>
    );

}

