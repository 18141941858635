

export const parseZenAPIDateToString = (date:string) => {
    let months = ["Jan","Feb","March","April","June","July","Aug","Sep","Oct","Nov","Dec"];
    let dayth = ["st","nd","rd","th","th","th","th","th","th","th","th","nd","th","th","th","th","th","th","th","th","st","nd","rd","th","th","th","th","th","th","th","st"];

    let day = date.substring(8,9) == "0"? date.substring(9,10) : date.substring(8,10);
    let month = date.substring(5,6) == "0"? date.substring(6,7) : date.substring(5,7);

    
    let monthVal = months[parseInt(month)-1];
    let dayVal = day+dayth[parseInt(day)-1];
    return {
        year:date.substring(0,4),
        day:dayVal,
        month:monthVal,
    }

}