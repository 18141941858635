import axios from "axios";
import React, { createContext, useContext, useState } from "react";
import MyZenInterface from "../components/myzen/interface/MyZenInterface";
import { ZEN_C_ENDPOINT } from "../config/EnvironmentalVariables";
import { useAuth } from "./auth";

interface UseMyZen {
  profile: MyZenInterface;
  language:string;
  setLanguage: (lang:string) => void;
  getProfile:(user:string) => any;
  userInfoLoaded: boolean;
  logContentInteraction: any;
}

type Props = {
  children?: React.ReactNode;
};
const myZenContext = createContext({} as UseMyZen);

export const ProvideMyZenContext: React.FC<Props> = ({ children }) => {
    const myZenService = MyZenService();
    return <myZenContext.Provider value ={myZenService}>{children}</myZenContext.Provider>;
};

export const useMyZenService = () => {
    return useContext(myZenContext);
};

export const MyZenService = () => {
    const auth = useAuth();

    const [profile, setProfile] = useState(null);
    const [language, setLanguage] = useState("ENG");
    const [userInfoLoaded, setUserInfoLoaded] = useState(false);

    const getProfile = async (user : string) => {
      await axios.get(ZEN_C_ENDPOINT+`/myzen/myZen/`+user)
      .then(res => {
        setUserInfoLoaded(true);
        setProfile(res.data);
        return true;
      }).catch(() => {
      return true;
      });
      return true;
  }

  const logContentInteraction = async (contentId: string) => {
    if(auth != null && auth.username !=null){
      await axios.get(ZEN_C_ENDPOINT+`/myzen/honeycohm/logContentInteraction`+auth.username+`/`+contentId)
      .then(res => {
        return true;
      }).catch(() => {
      return true;
      });
      return true;
    }
   
 }

    return {
        profile,
        language,
        setLanguage,
        getProfile,
        userInfoLoaded,
        logContentInteraction
    };

}