import * as React from "react";
import { MyZenNavBar } from "../../common/navbar/MyZenNavBar";
import "../../../styling/common/index.scss";
import Slider from "react-slick";
import { MeditationStudio } from "./MeditationStudio";
import { ZencyclopaediaMainMenuItemPreview } from "../../explore/previews/ZencyclopaediaMainMenuItemPreview";
import { NuggetStudio } from "./NuggetStudio";
import { PathStudio } from "./PathStudio";
import { ReelStudio } from "./ReelStudio";

export const PractitionerStudio = (props: { page?: string, userId:any , setPage?: any, subPage?: string, setSubPage?: any }) => {

      const sliderRef: any = React.createRef();
      const desktopSliderRef: any = React.createRef();

      return (

            <div>
                  {props.subPage == "create-practice" ?
                        <MeditationStudio
                              page={props.page}
                              setPage={props.setPage}
                        />
                        :props.subPage == "create-nugget" ?
                        <NuggetStudio
                              page={props.page}
                              setPage={props.setPage}
                              setSubPage= {props.setSubPage}
                              userId={props.userId}
                        />
                        :props.subPage == "create-reel" ?
                        <ReelStudio
                              page={props.page}
                              setPage={props.setPage}
                        />
                        :props.subPage == "create-path" ?
                        <PathStudio
                              page={props.page}
                              setPage={props.setPage}
                              userId={props.userId}
                        />
                        :
                        <div style = {{overflowY:"scroll"}}>
                        <div style = {{paddingBottom:"150px"}}>
                              <h2 style={{ margin: "auto", textAlign: "center", marginBottom: "5px" }}>What do you want to create?</h2>
                              
                              <div className="zencyclopaedia-main-menu-item-holder zen-create-option"
                                     onClick = {() => props.setSubPage("create-reel")}
                                     >
                                    <div style  = {{width:"100%"}}>
                                          <h2>Create a Zen Reel</h2>
                                          <h3>Share a bite sized piece of Zen!</h3>
                                          <h4>Zen Reels are short videos less than 30 seconds long!</h4>
                                    </div>
                              </div>
                              <div className="zencyclopaedia-main-menu-item-holder zen-create-option"
                                    onClick = {() => props.setSubPage("create-nugget")}
                              >
                                    <div style  = {{width:"100%"}}>
                                          <h2>Zen Nugget</h2>
                                          <h3>Share some positivity or wisdom with the world.</h3>
                                    </div>
                              </div>
                              <div className="zencyclopaedia-main-menu-item-holder zen-create-option"
                                    onClick = {() => props.setSubPage("create-practice")}
                              >
                                    <div style  = {{width:"100%"}}>
                                          <h2>Practice/Meditation</h2>
                                          <h3>Add a Mindfulness practice to your catalogue</h3>
                                    </div>

                              </div>
                              <div className="zencyclopaedia-main-menu-item-holder zen-create-option"
                                     onClick = {() => props.setSubPage("create-path")}
                                     >
                                    <div style  = {{width:"100%"}}>
                                          <h2>Zen Course</h2>
                                          <h3>Create a collection of practices</h3>
                                    </div>
                              </div>

                              {/* Add Aother option for zen reel? (its a subtype of zen nugget...) */}

                        </div>
                        </div>
                        }

            </div>
      );

}
