import * as React from "react";
import "../../../styling/explore/explore.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate, useParams } from "react-router";
import { ZenNavBar } from "../../common/navbar/ZenNavBar";
import { MyZenNavBar } from "../../common/navbar/MyZenNavBar";
import { useZenCardsService } from "../../../hooks/ZenCardsContext";
import { ZenCardPreview } from "./ZenCardPreview";
import { UnlockZenCardModal } from "./UnlockZenCardModal";
import { ConfirmTranslation } from "../../../content/SignUpTranslations";
import { determineUserOwnsPortal } from "./zenCardsService";
import { PuffLoader } from "react-spinners";
import Slider from "react-slick";

export const ZenCardsGroup = (props: any) => {


    let zenCardsService = useZenCardsService();

    const cardItems = props.cards.map((zi) => <ZenCardPreview sliderCard = {true} {...zi} userOwnsIcon={determineUserOwnsPortal(zenCardsService.userIcons, zi.cardKey)} setConfirmIcon={() => props.setConfirmIcon(zi)} />);

    let cardSliderRef = React.useRef();

    var settings = {
        dots: true
    };

    return cardItems && cardItems.length > 0 ? (
        <div className='cardsGroupHolder'>
            <div className='cardsGroup'>
                <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                    <span className="paintedLine"></span><h4 style={{ marginTop: "5px", width: "200px", margin: "auto", textAlign: "center" }}>{props.groupName}</h4><span className="paintedLine"></span>
                </div>
                <div className = "zen-mobile">
                <Slider className="zen-cards-slider"
                    centerMode={true}
                    centerPadding='20%'>
                    {cardItems}
                </Slider>
                </div>
                <div className = "zen-desktop">
                <Slider className="zen-cards-slider"
                    centerMode={true}
                    centerPadding='40%'>
                    {cardItems}
                </Slider>
                </div>


                {/* <div className={"cardBrowser"}>
                    {cardItems}
                </div> */}
            </div>
        </div >
    ) : null;

}

