import * as React from "react";
import "../../../styling/common/index.scss";
import {
  Link
} from "react-router-dom";
import { ZenProfileTranslation } from "../../../content/NavBarTranslations";

export const MyZenNavBar = (props: {noBorder?:boolean, setPage?:any, activePage?:string, subPage?:string,setSubPage?:any}) => {
  return (
    <div className={props.noBorder? "MyZenNavBar noBorderedNavBar": "MyZenNavBar"}>
      {props.activePage == "Practitioner"  || props.activePage == "Practitioner-Create"?
      <React.Fragment>
      <div className="MyZenProfileNavBarLink">
          <h2 className="zenK" onClick = {() => {
            props.setPage("Practitioner");
            props.setSubPage("practitioner-bio");
            }}>
            Home
          </h2>
      </div>
      <div className="MyZenProfileNavBarLink">
        <h2 className="zenK" onClick = {() => {
          props.setPage("Practitioner-Create");
          props.setSubPage("practitioner-create-home");
          }}>
          Create
        </h2>
      </div>
      <div className="MyZenProfileNavBarLink">
        <h2 className="zenK" onClick = {() => props.setSubPage("practitioner-stats")}>
          Stats
        </h2>
      </div>
    </React.Fragment>
      :
      <React.Fragment>
      <div className="MyZenProfileNavBarLink">
        <Link to="/myzen">
          <h2 className="zenK">
            {ZenProfileTranslation.English}
          </h2>
        </Link>
      </div>
      <div className="MyZenProfileNavBarLink">
        <h2 className="zenK">
          <Link to="/journal">Journal</Link>
        </h2>
      </div>
      <div className="MyZenProfileNavBarLink">
        <h2 className="zenK">
          <Link to="/planner">Planner</Link>
        </h2>
      </div>
      <div className="MyZenProfileNavBarLink">
        <h2 className="zenK">
          <Link to="/goals">Progress</Link></h2>
      </div>
    </React.Fragment>}
    </div>
  );

}