import * as React from "react";
import { PuffLoader } from "react-spinners";
import "../../styling/zenverse/Zenverse.scss";

export const ZenverseSidebar = (props:any) => {

    let holderClassName = props.parentSlider? "zenverseSidebar zenverse-located-sidebar" : "zenverseSidebar";

    if(props.blendMode){
      holderClassName = holderClassName + " zenverseSidebarBlendMode";
    } 

    return (
      <div className={holderClassName}>
        <div className="zenverseSidebarIcons">
          {props.loading? <div className = "zen-side-bar-loader">
            <div><PuffLoader color="#033f63" size={60}/></div>
            </div>:null}
          {props.practiceGuide}
          {props.zenPortalsIcons && props.zenPortalsIcons.length > 0? props.zenPortalsIcons 
          : props.zenPortalsIcons && props.zenPortalsIcons.length == 0 && props.searchType == "myportals"?
          <h4 className="zen-side-bar-message"> You don't own any Zen Portals yet! </h4>
          : props.zenPortalsIcons && props.zenPortalsIcons.length == 0?
          <h4 className="zen-side-bar-message"> No Portals were found! </h4>
          :
          <h4 className="zen-side-bar-message"> We're having trouble loading Zen Portals ... </h4>}
          <h1>{props.loading}</h1>
        </div>
      </div>
    );
}