import React, { useState } from 'react';
import "../../../styling/vacana/vacana.scss";
import "../../../styling/common/index.scss";
import "../../../styling/stream/stream.scss";
import "../../../styling/zenverse/Zenverse.scss";
import ReactPlayer from 'react-player'
import { useParams } from 'react-router';
import axios from "axios";
import useZenVideoPlayer from '../../media/useZenVideoPlayer';
import { ZenPracticePortal } from '../ZenPracticePortal';
import { ZEN_C_ENDPOINT } from '../../../config/EnvironmentalVariables';
import PracticeInterface from '../../zencontent/practices/PracticeInterface';
import PrivateRoute from '../../common/PrivateRoute';
import { ZenMeditationAudioPlayer } from './ZenMeditationAudioPlayer';
import { ZenMeditationVideoPlayer } from './ZenMeditationVideoPlayer';

export const ZenMediaPlayer = () => {
  
  const {id} = useParams();
  
  const [props, setProps] = useState(null);
  const [practicePlaying, setPracticePlaying] = useState(false);
  const [practiceVolume, setPracticeVolume] = useState(50);
  const [finished, setFinished] = useState(false);
  React.useEffect(() => {
    getMeditation(id);
  }, []);

  function getMeditation(id:any) : any {
    axios.get(ZEN_C_ENDPOINT+`/zen/meditations/`+id)
       .then(res => {
        console.log("HERE:");
        console.log(res.data);
         const practiceData:PracticeInterface = res.data;
         setProps(practiceData);
       })
  }

  const [videoStarted, setVideoStarted] = useState(false);
  let sessionDuration = props && props.practiceDetails && props.practiceDetails.durationSeconds > 0? props.practiceDetails.durationSeconds : 0;

  const mediaType = props && props.videoUrl != null && props.videoUrl.includes("-SPLIT-")? "aws-video"
  : props && props.videoUrl? "yt-video"
  :props && props.audioUrl != null && props.audioUrl.includes("-SPLIT-")? "aws-audio"
  :props && props.audioUrl != null? "ex-audio"
  :null;


  console.log("Rendered Zen Media Player");
  console.log("Media Type: "+mediaType);

    return (
      <PrivateRoute>
      <ZenPracticePortal practiceId = {id} duration={Math.floor(sessionDuration/60)}  practiceZenergyBoost = {props && props.practiceDetails && props.practiceDetails.zenergyBoost? props.practiceDetails.zenergyBoost : 2} practice={props && props.practiceDetails? props.practiceDetails: null} setFinished={(finished) => setFinished(finished)} finished={finished} practicePlaying = {practicePlaying} setVideoStarted={() => setVideoStarted(true)} setPracticePlaying={(playing) => setPracticePlaying(playing)} {...useZenVideoPlayer()}>
        { videoStarted && props?
                 <div className='practice-video-holder'>
                  {mediaType=="aws-audio"?
                  <ZenMeditationAudioPlayer
                    meditationId={props.id} 
                    setFinished={setFinished}
                    practicePlaying={practicePlaying}
                    setPracticePlaying={setPracticePlaying} 
                    practiceVolume={practiceVolume}
                    ></ZenMeditationAudioPlayer>
                    :mediaType=="aws-video"?
                    <ZenMeditationVideoPlayer
                      meditationId={props.id} 
                      setFinished={setFinished}
                      practicePlaying={practicePlaying}
                      setPracticePlaying={setPracticePlaying} 
                      practiceVolume={practiceVolume}
                      ></ZenMeditationVideoPlayer>
                    :mediaType=="yt-video"?
                    <ReactPlayer controls = {true} onEnded ={()=>setFinished(true)} playing={practicePlaying}  loop={true} url={(props && props.videoUrl)? props.videoUrl : props.videoFile? props.videoFile : null}  width='100%' volume={practiceVolume} onPause={() => setPracticePlaying(false)} setPlaying={(playing) => setPracticePlaying(playing)}/>
                :null}
                 </div> : 
               <img className='practice-portal-image' src={(props && props.practiceDetails.image)? props.practiceDetails.image : require("../../../static/photos/blotches.png") }></img>
        }
      </ZenPracticePortal>
      </PrivateRoute>
    );  
}