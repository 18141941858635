import * as React from "react";
import "../../../styling/common/index.scss";
import "../../../styling/myzen/myzen.scss";
import { useState } from "react";
import { useZenGoals } from "../../../hooks/ZenGoalsContext";
import { useNavigate } from "react-router";
import { useZenCardsService } from "../../../hooks/ZenCardsContext";
import { ZenCardMilestoneNotice } from "../../explore/icons/ZenCardMilestoneNotice";


export const ChooseMyZenSkills = (props: { refresh: any }) => {

      let zenGoals = useZenGoals();

      const [rewardCard, setRewardCard] = React.useState(null);

      const cardsContext = useZenCardsService();

      const SET_SKILLS_MILESTONE_KEY = "SZT_MS";

      const submitSkills = async () => {
            //Check if we have a milestone card for this:
            var cardExists = cardsContext.checkForMilestone(SET_SKILLS_MILESTONE_KEY);
            zenGoals.chooseUserSkills(myChosenZenGoals);

            if (!cardExists) {
                  var rewardCard = await cardsContext.claimMilestone(SET_SKILLS_MILESTONE_KEY);
                  if (rewardCard != null && rewardCard.cardKey != null) {
                        setRewardCard(rewardCard);
                  } else {
                        props.refresh();
                  }
            } else {
                  props.refresh();
            }
      }


      const [myChosenZenGoals, setMyChosenZenGoals] = useState(zenGoals.userSkillGoals ? zenGoals.userSkillGoals : []);

      const coreGoalsJSX = [];

      if(zenGoals != null && zenGoals.allSkills != null){
            zenGoals.allSkills.map((goal, index) => {
                  if (!myChosenZenGoals.includes(goal)) {
                        coreGoalsJSX.push(<li key={'zgo' + index} className="ZenGoalsOption" onClick={() => addCoreAttribute(goal)}><h4 className="ZenGoalsOptionText">{goal.value}</h4></li>);
                  } else {
                        coreGoalsJSX.push(<li key={'zgo' + index} className="ZenGoalsOption chosenZenGoal" onClick={() => removeCoreAttribute(goal.id)}>{goal.value}</li>);
                  }
            })
      }

      const addCoreAttribute = (item: any) => {
            myChosenZenGoals.push(item);
            var newList = myChosenZenGoals.splice(0, myChosenZenGoals.length);
            setMyChosenZenGoals(newList);
      }

      const removeCoreAttribute = (item: string) => {
            var newList = myChosenZenGoals.filter((goal) => !(item == goal.id));
            setMyChosenZenGoals(newList);
      }

      return (
            <React.Fragment>
                  {rewardCard != null ? <div className="ZenBasicPage zen-page-holder">
                        <div style={{ position: "fixed", top: "15px", width: "100%" }}>
                              <div style={{ margin: "auto" }}>
                                    <ZenCardMilestoneNotice cards={[rewardCard]} moveOnAction={() => props.refresh()}></ZenCardMilestoneNotice>
                              </div>
                        </div>
                  </div> :
                        <div style={{ margin: "auto",  maxWidth:"100vw" }}>
                              <div className="ming-page" style={{ margin: "auto" , maxWidth:"100vw"}}>
                              <h4 className="MyChosenZenGoalsHeader">Identify which mindfulness traits you wish to cultivate</h4>
                                    <div className="ZenGoalsOptions" style={{ margin: "auto",  maxWidth:"100vw" }}>
                                          <ul className="chooseGoalsList" style={{ margin: "auto",  maxWidth:"100vw", paddingLeft:"0" }}>
                                                {coreGoalsJSX}
                                          </ul>

                                    </div>
                                    {myChosenZenGoals.length > 3 ?
                                          <div className='finish-session-icon' onClick={() => submitSkills()}></div>
                                          : <></>}
                              </div>
                        </div>}
            </React.Fragment>
      );

}
