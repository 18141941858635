import * as React from "react";
import { MyZenNavBar } from "../../common/navbar/MyZenNavBar";
import "../../../styling/common/index.scss";
import { ZenNavBar } from "../../common/navbar/ZenNavBar";
import { useState } from "react";
import axios from "axios";
import { useAuth } from "../../../hooks/auth";
import PrivateRoute from "../../common/PrivateRoute";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ZEN_C_ENDPOINT } from "../../../config/EnvironmentalVariables";
import { PuffLoader } from "react-spinners";
import { useMyZenService } from "../../../hooks/ZenUserContext";
import { useZenCardsService } from "../../../hooks/ZenCardsContext";
import { ZenCardMilestoneNotice } from "../../explore/icons/ZenCardMilestoneNotice";
import { ZenCardPreviewInterface } from "../../explore/icons/ZenCardPreview";
import { getSubscriptionAllowanceMessage } from "../../../service/ZenSubscriptionsService";
import { SubscriptionCustomerDetailsModal } from "./SubscriptionCustomerDetailsModal";
import { SubscriptionPayment } from "./SubscriptionPayment";
import { getSubscriptionCost } from "../../../stubs/SubscriptionService";
import { useZenQuotasService } from "../../../hooks/ZenQuotasContext";

export const UpgradeSubscriptionModal = (props: any) => {

  const { subscriptionTier } = useParams();
  const [page, setPage] = useState("main");
  const [sendingUpgrade, setSendingUpgrade] = useState(false);
  let auth = useAuth();

  let quotasContext = useZenQuotasService();

  let currentSubscriptionLevel = quotasContext && quotasContext.zenQuotasResults ? quotasContext.zenQuotasResults.subscriptionLevel : "FREE";
  let billingDate = quotasContext && quotasContext.zenQuotasResults ? quotasContext.zenQuotasResults.subscriptionBillingDate : "-";

  let subscriptionTierDisplayName = subscriptionTier == "PREMIUM" ? "Premium" : "Basic";

  var subscriptionCost = getSubscriptionCost(subscriptionTier);

  const performUpgrade = () => {
    if(!sendingUpgrade){
      setSendingUpgrade(true);
      axios.post(ZEN_C_ENDPOINT + `/myzen/mysubscription/upgradeSubscription`, {
        username: auth.username,
        subscriptionTier: subscriptionTier,
      }).then(function (response) {
        setPage("upgraded");
      }).catch(function (error) {
        setPage("upgraded");
        console.log(error);
      });
    }
  }

  const nth = function (d) {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1: return "st";
      case 2: return "nd";
      case 3: return "rd";
      default: return "th";
    }
  }

  function extractDayFromDate(dateString: string): number {
    const [, , day] = dateString.split('-');
    return parseInt(day, 10);
  }

  const billingDay = billingDate != null && billingDate!="-"? extractDayFromDate(billingDate) + nth(extractDayFromDate(billingDate)) : "-";

  return (
    <div className="ZenBasicPage" style={{ padding: "10%", paddingTop: "25px" }}>
      {page == "main" ?
        <React.Fragment>

          {/* Back Icon */}

          <h1 style={{}}>Upgrade to {subscriptionTierDisplayName}</h1>

          {/* List Perks? */}
          
          <h2>{subscriptionCost} Per Month</h2>
          <h4>Billed on the {billingDay} of every month*</h4>
          <h3>You will be upgraded immediatley and receive a bill today for the days remaining before your next invoice</h3>
          <h4>You can easily manage your subscription by going to My Zen, Account, My Subscription. <br></br> You can also manage your subscription from the the Zen Cards browser, or by chatting to Zen Bot!</h4>
          <div className="zenGlowButton navajoGlowButton" style={{ marginTop: "50px" }} onClick={() => performUpgrade()}>Upgrade Now</div>

        </React.Fragment>
        : page == "upgraded" ?
          <React.Fragment>
            <React.Fragment>
              {/* <SubscriptionEnroller username = {auth.username} subscriptionTier = {subscriptionTier} paymentIntent = {pI}/> */}
              <div className="ZenBasicPage zen-page-holder" style={{ paddingTop: "0" }}>

                <h1 className="okashi congrats-header" style={{ marginBottom: 0 }}>Congratulations</h1>

                <h4 style={{ marginTop: 0 }}>You are now a {subscriptionTier == "PREMIUM" ? "Premium" : "Basic"} subscriber</h4>

                {/* Check for discount... you will pay JUST 1.99 for X months, after that,  */}
                <h4 className="subscription-conf-billing-info">You will now be charged {subscriptionTier == "PREMIUM" ? "£1.99" : "99p"} on the {billingDay} of every month.</h4>
                <h4>You can easily manage your subscription from your MyZen Profile, the Zen Cards browser, or by chatting to Zen Bot!</h4>

                <Link to="/"><div className="zenGlowButton mingGlowButton">done</div></Link>

              </div>
            </React.Fragment>
          </React.Fragment>
          : null}
    </div>
  );
}

