import * as React from "react";
import "../../../styling/explore/explore.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

export const ZenscapeSliderItem = (props: { id?: number, description?:string, title: string, image: any, photoBottom?: boolean, itemType?: string, translation?: string, itemResultId?: string, searchRoute?: string, clickAction?: any }) => {

    const contentsItemLink = "tofix";

    var holderClass = 'zenscape-slider-item';
    return (
        <React.Fragment>
            <Link to={props.searchRoute}>
                <div className="zenscape-slider-item" onClick={props.clickAction ? () => props.clickAction() : () => { }}>
                    <img className="zen-slider-image" src={props.image ? props.image : null} ></img>
                    <h1 className="explore-slider-title zencyc-contents-item-header-bottom zenK"><b>{props.title}</b> <span className='zencyc-contents-item-header-trans'>{props.translation}</span></h1>
                    <h4 className = "explore-slider-description">{props.description}</h4>
                </div>
            </Link>
        </React.Fragment>
    );

}

