import * as React from "react";
import "../../../styling/zenverse/Zenverse.scss";
import "../../../styling/common/index.scss";
import {
  Link, useNavigate
} from "react-router-dom";

export const ZenNavBar = (props: { noBorder? :boolean, activeItem?: string, primaryColor?: string, enabled?: boolean, home?:boolean }) => {

  return (
    <div className={props.noBorder? "noBorderNavBar" : "ZenNavBar"} style={props.primaryColor? {backgroundColor: props.primaryColor } :{ }}>
      {!props.home?
      <div className="NavBarMenuItem">
        <Link to="/explore">
          <div className="NavbarLogoHolder ZencyclopaediaLogo"></div>
        </Link>
      </div>
    :null}
      <div className="NavBarMenuItem">
        <Link to={props.home? "/home" : "/"}>
          <div className="NavbarLogoHolder zencyclogo"></div>
        </Link>
      </div>
      {!props.home?
      <div className="NavBarMenuItem">
        <Link to="/myzen">
          <div className="NavbarLogoHolder MyZenLogo"></div>
        </Link>
      </div>
    :null}
    </div>
  );

}