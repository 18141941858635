import axios from "axios";
import React, { createContext, useContext, useState } from "react";
import ZenUserWeekGoalsInterface from "../components/myzen/goals/goalsInterface/ZenUserWeekGoalsInterface";
import ZenWeekGoalsInterface from "../components/myzen/interface/ZenWeekGoalsInterface";
import { ZEN_C_ENDPOINT } from "../config/EnvironmentalVariables";
import { ZenApiResultStatus } from "../config/ZenApiResultStatus";
import { useAuth } from "./auth";
import { useMyZenService } from "./ZenUserContext";

interface UseZenGoals {
    userSkillGoals: any[],
    weekGoals: ZenUserWeekGoalsInterface,
    allSkills: any[],
    resultStatus:string,
    hasLoaded: () => boolean,
    getUserWeekGoals: () => void,
    getUserSkillGoals: () => void,
    chooseUserSkills: (myChosenZenGoals:any) => void,
    chooseGoalTargets: (totalTime:number, medNumber:number) => void
}

type Props = {
    children?: React.ReactNode;
};
const zenGoalsContext = createContext({} as UseZenGoals);

export const ProvideZenGoalsContext: React.FC<Props> = ({ children }) => {
    const zenGoalsService = ZenGoalsService();
    return <zenGoalsContext.Provider value={zenGoalsService}>{children}</zenGoalsContext.Provider>;
};

export const useZenGoals = () => {
    return useContext(zenGoalsContext);
};

export const ZenGoalsService = () => {

    const auth = useAuth();
    const myZen = useMyZenService();
    const [weekGoals, setWeekGoals] = useState(null);
    const [userSkillGoals, setSkillGoals] = useState(null);
    const [allSkills, setAllSkills] = useState();
    const [getSkillsTries, setSkillsTries] = useState(0);
    const [getWeekGoalsTries, setWeekGoalTries] = useState(0);
    const [getSkillGoalTries, setSkillGoalTries] = useState(0);
    const [fetchingSkills, setFetchingSkills] = useState(false);
    const [fetchingWeekGoals, setFetchingWeekGoals] = useState(false);
    const [fetchingSkillGoals, setFetchingSkillGoals] = useState(false);
    const [resultStatus, setResultStatus] = useState<string>(null);

    const getUserWeekGoals = async () => {
        setFetchingWeekGoals(true);
        await axios.get(ZEN_C_ENDPOINT + `/myzen/zenGoals/getWeeklyGoals/` + myZen.profile.username)
            .then(res => {
                if (res && res.data) {
                    var weeklyGoals: ZenWeekGoalsInterface[] = res.data;
                    setWeekGoals(weeklyGoals);
                    setFetchingWeekGoals(false);
                    setResultStatus(ZenApiResultStatus.Success);
                } else {
                    setWeekGoalTries(getWeekGoalsTries + 1);
                    setFetchingWeekGoals(false);
                    setResultStatus(ZenApiResultStatus.Success);
                }
            })
            .catch(function (error) {
                setWeekGoalTries(getWeekGoalsTries + 1);
                setFetchingWeekGoals(false);
                setResultStatus(ZenApiResultStatus.Fail);
            });
    }

    const setSkills = async () => {
        setFetchingSkills(true);
        await axios.get(ZEN_C_ENDPOINT + `/myzen/zenergy/getAllSkills`)
            .then(res => {
                if (res && res.data) {
                    setAllSkills(res.data);
                    setFetchingSkills(false);
                    setResultStatus(ZenApiResultStatus.Success);
                } else {
                    setFetchingSkills(false);
                    setSkillsTries(getSkillsTries + 1);
                    setResultStatus(ZenApiResultStatus.Success);
                }
            })
            .catch(function (error) {
                setFetchingSkills(false);
                setSkillsTries(getSkillsTries + 1);
                setResultStatus(ZenApiResultStatus.Fail);
            });
    }

    const getUserSkillGoals = async () => {
        setFetchingSkillGoals(true);
        await axios.get(ZEN_C_ENDPOINT + `/myzen/zenergy/getSkillGoals/` + myZen.profile.username)
            .then(res => {
                if (res && res.data) {
                    setSkillGoals(res.data);
                    setFetchingSkillGoals(false);
                    setResultStatus(ZenApiResultStatus.Success);
                } else {
                    setSkillGoalTries(getSkillGoalTries + 1);
                    setFetchingSkillGoals(false);
                    setResultStatus(ZenApiResultStatus.Success);
                }
            })
            .catch(function (error) {
                setSkillGoalTries(getSkillGoalTries + 1);
                setFetchingSkillGoals(false);
                setResultStatus(ZenApiResultStatus.Fail);
            });
    }

    const chooseGoalTargets = (totalTime:number, medNumber:number) => {
        const apiresult = axios.post(ZEN_C_ENDPOINT+`/myzen/zenGoals/addWeeklyTarget`, {
              username:myZen.profile.username,
              meditationTime:totalTime,
              prefferedDays:[],
              meditationSessionNumber:medNumber,
              hobbyNumber:0,
              relaxNumber:0,
              mindfulMovementNumber:0
        }).then(function (response) {
              getUserWeekGoals();
        }).catch(function (error) {
                    console.log(error);
                    console.log(error.message);
        });
    }

    const chooseUserSkills = (myChosenZenGoals:any) => {
        let goalSkillList = [];
        const payload = myChosenZenGoals.map((goal) =>  goalSkillList.push({
              "username":myZen.profile.username, 
              "skillId":goal.id,
              "value":50
        }));
        axios.post(ZEN_C_ENDPOINT+`/myzen/zenergy/addSkillGoals/`+myZen.profile.username, 
              payload
              ).then(function (response) {
                    getUserSkillGoals();
              }).catch(function (error) {
                          console.log(error);
                          console.log(error.message);
              });
  }

    const hasLoaded = () => {
        var weekGoalsHasLoaded = (weekGoals != null) || getWeekGoalsTries == 5;
        var skillGoalsHasLoaded = (userSkillGoals != null) || getSkillGoalTries == 5;
        var skillsHasLoaded = (allSkills != null) || getSkillsTries == 5;

        return weekGoalsHasLoaded;// && skillGoalsHasLoaded && skillsHasLoaded;
    }

    if (!fetchingWeekGoals && weekGoals == null && getWeekGoalsTries < 5) {
        getUserWeekGoals();
    }

    if (!fetchingSkillGoals && userSkillGoals == null && getSkillGoalTries < 5) {
        getUserSkillGoals();
    }

    if (!fetchingSkills && allSkills == null && getSkillsTries < 5) {
        setSkills();
    }

    return {
        userSkillGoals,
        weekGoals,
        allSkills,
        resultStatus,
        hasLoaded,
        getUserWeekGoals,
        getUserSkillGoals,
        chooseUserSkills,
        chooseGoalTargets
    };

}  