import axios from "axios";
import React, { createContext, useContext, useState } from "react";
import { ZencyclopaediaItemPreview } from "../components/explore/previews/ZencyclopaediaItemPreview";
import { ZencyclopaediaMainMenuItemPreview } from "../components/explore/previews/ZencyclopaediaMainMenuItemPreview";
import { ZencyclopaediaMediumItemPreview } from "../components/explore/previews/ZencyclopaediaMediumItemPreview";
import { ZencyclopaediaTextItemPreview } from "../components/explore/previews/ZencyclopaediaTextItemPreview";
import { ZencycyclopaediaPreviewGroup } from "../components/explore/ZencycyclopaediaPreviewGroup";
import { PracticePreview } from "../components/zencontent/practices/previews/PracticePreview";
import { ExploreTranslations } from "../content/ExploreTranslations";



export const createAlphabetGroups = (contentItems : any []) => {

  const alphabet = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"]

  var allGroups = [];


  alphabet.map((letter) => {

    var matchingItems = contentItems? contentItems.filter((i) => (i.title.toLowerCase().charAt(0)==letter && (i.previewType != "main"))) : [];
    if(matchingItems && matchingItems.length>0){
      allGroups.push(<ZencycyclopaediaPreviewGroup groupName={letter.toUpperCase()} resultList = {matchingItems.map((i) => generateItemPreview(i))}/>);
    }

  });


  return allGroups;
}

export function getZencyclopaediaMainGroups(lang? : string) {

  const items = [];
  var frogImage = require("../static/photos/exploreIcons/vibe.png");
  var coursesImage = require("../static/photos/exploreIcons/growth.png");
  var CommsImage = require("../static/photos/exploreIcons/community.png");
  var InfoImage = require("../static/photos/exploreIcons/info.png");
  var ArticlesImage = require("../static/photos/exploreIcons/articles.png");
  var ZenShopImage = require("../static/photos/exploreIcons/zenshopwhite.png");
  var zenscapeImage = require("../static/photos/exploreIcons/zenscape.png");
  var zenCardsImage = require("../static/photos/exploreIcons/froggy.png");
  var zenBotIMage = require("../static/photos/exploreIcons/zenBot.png");

  var Meditations = { title : lang && lang == "ESP"? ExploreTranslations.Catagories.Meditations.Spanish : ExploreTranslations.Catagories.Meditations.English, image : frogImage, previewType:"main"}
  var Courses = { title : 'Courses', image : coursesImage, previewType:"main"}
  var Information = { title : 'The Zencyclopedia', image : InfoImage, previewType:"main"}
  var Articles = { title : 'Articles', image : ArticlesImage, previewType:"main"}
  var Zenverse = { title : 'Zenscapes', image : zenscapeImage, previewType:"main"}
  var Shop = { title : 'Shop', image : ZenShopImage, previewType:"main"}
  var Icons = { title : 'Zen Cards', image : zenCardsImage, previewType:"main"}
  var Communities = { title : 'Communities', image : CommsImage, previewType:"main"}
  var ZenBot = { title : 'Zen Bot', image : zenBotIMage, previewType:"main"}

  items.push(Meditations);
  items.push(Courses);
  items.push(Communities);
  items.push(Articles);
  items.push(Zenverse);
  items.push(Icons);
  items.push(Shop);
  items.push(ZenBot);
  items.push(Information);


  return items;
}

export const generateItemPreview = (item : any) => {

    if(item.previewType == "main"){
      return <ZencyclopaediaMainMenuItemPreview active = {true} searchRoute = {item.title.replace(' ', '').toLowerCase()} photoBottom = {true}title={item.title} image={item.image} ></ZencyclopaediaMainMenuItemPreview>
    } else if(item.previewType == "text-only"){
      return <ZencyclopaediaTextItemPreview title={item.title} image={item.image} />;
    } else if (item.contentType=="MEDITATION"){
      return <PracticePreview {...item}></PracticePreview>
    } else {
      return <ZencyclopaediaItemPreview  photoBottom = {true} key={"ZIP"+item.id} id = {item.id}  title={item.title} image={item.image} itemType = {item.contentType}/>;
    }
}

export function getMeditationGroups(){

  const contentGroups = [];
  
  const mainGroups = [];

  
  
  //mainGroups.push(<ZencyclopaediaTextItemPreview photoBottom = {true}title = 'All' searchRoute="/explore/meditations/all" image = {require("../static/photos/mainGroups/med_cat_frog.png")} colorClass={'ZenCGradient4'}></ZencyclopaediaTextItemPreview>);
  mainGroups.push(<ZencyclopaediaTextItemPreview photoBottom = {true}title = 'Top' searchRoute="/explore/meditations/top" colorClass={'ZenCGradient1'}></ZencyclopaediaTextItemPreview>);
  mainGroups.push(<ZencyclopaediaTextItemPreview photoBottom = {true}title = 'New' searchRoute="/explore/meditations/new" colorClass={'ZenCGradient3'}></ZencyclopaediaTextItemPreview>);
  mainGroups.push(<ZencyclopaediaTextItemPreview photoBottom = {true}title = 'Favourites' searchRoute="/favourites" colorClass={'ZenCGradient2'}></ZencyclopaediaTextItemPreview>);

  const topCats = [];
  
  topCats.push(<ZencyclopaediaMediumItemPreview photoBottom = {true}title = 'Beginners' searchRoute="/explore/meditations/beginner" image = {require("../static/photos/mainGroups/med_cat_frog.png")}></ZencyclopaediaMediumItemPreview>);
  topCats.push(<ZencyclopaediaMediumItemPreview photoBottom = {true}title = 'Anxiety' searchRoute="/explore/meditations/anxiety" image = {require("../static/photos/mainGroups/medforanxiety.png")}></ZencyclopaediaMediumItemPreview>);
  topCats.push(<ZencyclopaediaMediumItemPreview photoBottom = {true}title = 'Depression' searchRoute="/explore/meditations/depression" image = {require("../static/photos/mainGroups/zenForDepression.png")}></ZencyclopaediaMediumItemPreview>);
  //topCats.push(<ZencyclopaediaItemPreview photoBottom = {true}title = 'Pain' searchRoute="/explore/meditations" image = {require("../static/photos/mainGroups/pain.png")}></ZencyclopaediaItemPreview>);
  topCats.push(<ZencyclopaediaMediumItemPreview photoBottom = {true}title = 'Relaxation' searchRoute="/explore/meditations/relax" image = {require("../static/photos/mainGroups/relaxation.png")}></ZencyclopaediaMediumItemPreview>);

  const moreCats = [];
  moreCats.push(<ZencyclopaediaTextItemPreview photoBottom = {true}title = 'Breathing' searchRoute="/explore/meditations/breathwork"  colorClass={'ZenCGradient1'}></ZencyclopaediaTextItemPreview>);
  moreCats.push(<ZencyclopaediaTextItemPreview photoBottom = {true}title = 'Lectures' searchRoute="/explore/meditations/lecture" colorClass={'ZenCGradient3'}></ZencyclopaediaTextItemPreview>);
  moreCats.push(<ZencyclopaediaTextItemPreview photoBottom = {true}title = 'Documentaries' searchRoute="/explore/meditations/documentary"  colorClass={'ZenCGradient1'}></ZencyclopaediaTextItemPreview>);
  moreCats.push(<ZencyclopaediaTextItemPreview photoBottom = {true}title = 'Movement' searchRoute="/explore/meditations/move"  colorClass={'ZenCGradient3'}></ZencyclopaediaTextItemPreview>);
  moreCats.push(<ZencyclopaediaTextItemPreview photoBottom = {true}title = 'Shorts' searchRoute="/explore/meditations/shorts" colorClass={'ZenCGradient1'}></ZencyclopaediaTextItemPreview>);
  moreCats.push(<ZencyclopaediaTextItemPreview photoBottom = {true}title = 'Morning' searchRoute="/explore/meditations/morning" colorClass={'ZenCGradient3'}></ZencyclopaediaTextItemPreview>);
  moreCats.push(<ZencyclopaediaTextItemPreview photoBottom = {true}title = 'Teachings' searchRoute="/explore/meditations/teachings" colorClass={'ZenCGradient1'}></ZencyclopaediaTextItemPreview>);
  moreCats.push(<ZencyclopaediaTextItemPreview photoBottom = {true}title = 'Buddhism' searchRoute="/explore/meditations/buddhism" colorClass={'ZenCGradient3'}></ZencyclopaediaTextItemPreview>);
  moreCats.push(<ZencyclopaediaTextItemPreview photoBottom = {true}title = 'Sleep' searchRoute="/explore/meditations/sleep" colorClass={'ZenCGradient1'}></ZencyclopaediaTextItemPreview>);
  


  contentGroups.push(<ZencycyclopaediaPreviewGroup resultList = {mainGroups} /> );
  contentGroups.push(<ZencycyclopaediaPreviewGroup resultList = {topCats} groupName={'Meditations For...'} showSeeMore = {true} medium = {true}/> );
  //contentGroups.push(<ZencycyclopaediaPreviewGroup resultList = {moreCats}  /> );
  //contentGroups.push(<ZencycyclopaediaPreviewGroup groupName={''} resultList = {getAllMeds()}/>);
  return contentGroups;
}

export function getContentCatagories(contentType:string){

  const contentGroups = [];
  
  const mainGroups = [];
  
  //mainGroups.push(<ZencyclopaediaTextItemPreview photoBottom = {true}title = 'All' searchRoute="/explore/meditations/all" image = {require("../static/photos/mainGroups/med_cat_frog.png")} colorClass={'ZenCGradient4'}></ZencyclopaediaTextItemPreview>);
  if(contentType == "Courses"){
    mainGroups.push(<ZencyclopaediaTextItemPreview photoBottom = {true}title = 'My Courses' searchRoute={"/explore/"+contentType+"/myCourses"} image = {require("../static/photos/mainGroups/med_cat_frog.png")} colorClass={'ZenCGradient1'}></ZencyclopaediaTextItemPreview>);
  } else {
    mainGroups.push(<ZencyclopaediaTextItemPreview photoBottom = {true}title = 'Top' searchRoute={"/explore/"+contentType+"/top"} image = {require("../static/photos/mainGroups/med_cat_frog.png")} colorClass={'ZenCGradient1'}></ZencyclopaediaTextItemPreview>);
  }

  mainGroups.push(<ZencyclopaediaTextItemPreview photoBottom = {true}title = 'New' searchRoute={"/explore/"+contentType+"/new"} image = {require("../static/photos/mainGroups/med_cat_frog.png")} colorClass={'ZenCGradient3'}></ZencyclopaediaTextItemPreview>);
  if(contentType == "Courses"){
    mainGroups.push(<ZencyclopaediaTextItemPreview photoBottom = {true}title = 'Hot' searchRoute={"/explore/"+contentType+"/hot"} image = {require("../static/photos/mainGroups/med_cat_frog.png")} colorClass={'ZenCGradient2'}></ZencyclopaediaTextItemPreview>);
  } else {
    mainGroups.push(<ZencyclopaediaTextItemPreview photoBottom = {true}title = 'Favourites' searchRoute="/explore/meditations/favourites" image = {require("../static/photos/mainGroups/med_cat_frog.png")} colorClass={'ZenCGradient2'}></ZencyclopaediaTextItemPreview>);
  }
  const topCats = [];
  
  topCats.push(<ZencyclopaediaMediumItemPreview photoBottom = {true}title = 'Beginners' searchRoute={"/explore/"+contentType+"/beginner"} image = {require("../static/photos/mainGroups/med_cat_frog.png")}></ZencyclopaediaMediumItemPreview>);
  topCats.push(<ZencyclopaediaMediumItemPreview photoBottom = {true}title = 'Anxiety' searchRoute={"/explore/"+contentType+"/anxiety"} image = {require("../static/photos/mainGroups/medforanxiety.png")}></ZencyclopaediaMediumItemPreview>);
  topCats.push(<ZencyclopaediaMediumItemPreview photoBottom = {true}title = 'Depression' searchRoute={"/explore/"+contentType+"/depression"} image = {require("../static/photos/mainGroups/zenForDepression.png")}></ZencyclopaediaMediumItemPreview>);
  //topCats.push(<ZencyclopaediaItemPreview photoBottom = {true}title = 'Pain' searchRoute="/explore/meditations" image = {require("../static/photos/mainGroups/pain.png")}></ZencyclopaediaItemPreview>);
  topCats.push(<ZencyclopaediaMediumItemPreview photoBottom = {true}title = 'Relaxation' searchRoute={"/explore/"+contentType+"/relax"} image = {require("../static/photos/mainGroups/relaxation.png")}></ZencyclopaediaMediumItemPreview>);

  const moreCats = [];
  moreCats.push(<ZencyclopaediaTextItemPreview photoBottom = {true}title = 'Breathing' searchRoute={"/explore/"+contentType+"/breathwork"}  colorClass={'ZenCGradient1'}></ZencyclopaediaTextItemPreview>);
  moreCats.push(<ZencyclopaediaTextItemPreview photoBottom = {true}title = 'Lectures' searchRoute={"/explore/"+contentType+"/lecture"} colorClass={'ZenCGradient3'}></ZencyclopaediaTextItemPreview>);
  moreCats.push(<ZencyclopaediaTextItemPreview photoBottom = {true}title = 'Documentaries' searchRoute={"/explore/"+contentType+"/documentary"}  colorClass={'ZenCGradient1'}></ZencyclopaediaTextItemPreview>);
  moreCats.push(<ZencyclopaediaTextItemPreview photoBottom = {true}title = 'Movement' searchRoute={"/explore/"+contentType+"/move"}  colorClass={'ZenCGradient3'}></ZencyclopaediaTextItemPreview>);
  moreCats.push(<ZencyclopaediaTextItemPreview photoBottom = {true}title = 'Shorts' searchRoute={"/explore/"+contentType+"/shorts"} colorClass={'ZenCGradient1'}></ZencyclopaediaTextItemPreview>);
  moreCats.push(<ZencyclopaediaTextItemPreview photoBottom = {true}title = 'Morning' searchRoute={"/explore/"+contentType+"/morning"} colorClass={'ZenCGradient3'}></ZencyclopaediaTextItemPreview>);
  moreCats.push(<ZencyclopaediaTextItemPreview photoBottom = {true}title = 'Teachings' searchRoute={"/explore/"+contentType+"/teachings"} colorClass={'ZenCGradient1'}></ZencyclopaediaTextItemPreview>);
  moreCats.push(<ZencyclopaediaTextItemPreview photoBottom = {true}title = 'Buddhism' searchRoute={"/explore/"+contentType+"/buddhism"} colorClass={'ZenCGradient3'}></ZencyclopaediaTextItemPreview>);
  moreCats.push(<ZencyclopaediaTextItemPreview photoBottom = {true}title = 'Sleep' searchRoute={"/explore/"+contentType+"/sleep"} colorClass={'ZenCGradient1'}></ZencyclopaediaTextItemPreview>);
  


  contentGroups.push(<ZencycyclopaediaPreviewGroup resultList = {mainGroups} /> );
  contentGroups.push(<ZencycyclopaediaPreviewGroup resultList = {topCats} groupName={contentType+' For...'} showSeeMore = {true} medium = {true}/> );
  contentGroups.push(<ZencycyclopaediaPreviewGroup resultList = {moreCats}  /> );
  //contentGroups.push(<ZencycyclopaediaPreviewGroup groupName={''} resultList = {getAllMeds()}/>);
  return contentGroups;
}
