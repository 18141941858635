import React, { useState } from 'react';
import "../../../styling/vacana/vacana.scss";
import "../../../styling/common/index.scss";
import "../../../styling/common/completionMeter.scss";
import "../../../styling/stream/stream.scss";
import { Link } from 'react-router-dom';
import { PathCompletionMeter } from './PathCompletionMeter';
import axios from 'axios';
import PractitionerInterface from '../../practitioner/PractitionerInterface';
import { ZEN_C_ENDPOINT } from '../../../config/EnvironmentalVariables';
import { useAuth } from '../../../hooks/auth';
import ZenContentInterface from '../practices/ZenContentInterface';
import PathInterface from './PathInterface';
import { PropagateLoader } from 'react-spinners';

export const PathPreview = (props: ZenContentInterface) => {

  const [showSyllabus, setShowSyllabus] = useState(false);
  const [practitioner, setPracitioner] = useState(null as PractitionerInterface);
  const [getTries, setGetTries] = useState(0);
  const [getPractitionerTries, setGetPractitionerTries] = useState(0);
  const [searchPending, setSearchPending] = useState(false);
  const [getPathCompletionTries, setGetPathCompletionTries] = useState(0);
  const [pathCompletionRate, setPathCompletionRate] = useState(null);
  const [path, setPath] = useState(null as PathInterface);

  let auth = useAuth();

  const getCourse = (pathId: any) => {
    setSearchPending(true);
    axios.get(ZEN_C_ENDPOINT + `/zen/paths/` + pathId)
      .then(res => {
        if (res.data) {
          setPath(res.data);
          setSearchPending(false);
        }
      })
  }

  const getCourseCompletion = () => {
    setSearchPending(true);
    axios.get(ZEN_C_ENDPOINT + `/myzen/myZenContent/pathCompletion/` + auth.username + `/` + props.id)
      .then(res => {
        setPathCompletionRate(res.data);
        setSearchPending(false);
      }).catch(() => {
        setSearchPending(false);
      })
  }

  if (path == null && props.id && getTries < 5 && !searchPending) {
    setGetTries(getTries + 1);
    getCourse(props.id);
  }


  if (pathCompletionRate == null && getPathCompletionTries < 5 && !searchPending) {
    setGetPathCompletionTries(getPathCompletionTries + 1);
    getCourseCompletion();
  }

  return (

    <div style={{ display: "block" }}>

      <Link to={"/explore/course/" + props.id} style={{ display: "flex", flexDirection: "row", textAlign: "center" }}>
        <div className='zencyclopaedia-content-list-item path-preview'>
          {path != null ?
            <React.Fragment>
              <img className="content-preview-image path-prev-image" src={path && path.image ? path.image : require("../../../static/photos/AwM.png")} onClick={() => setShowSyllabus(!showSyllabus)}></img>

              <div className='practice-preview-info'>
                <h4 className="path-preview-title okashi">{path ? path.title : null}</h4>
                <h4 className="practitioner-preview-name zenK">{props.practitioner ? "by " + props.practitioner : null}</h4>
                {pathCompletionRate != null && pathCompletionRate.totalLessons != null ?
                <PathCompletionMeter pathId={props.id} pathCompletionRate={pathCompletionRate} preview={true} />
                :null}
              </div>
            </React.Fragment>
            :
            <div className = "zen-menu-item-prpgate-loader">
            <PropagateLoader>

            </PropagateLoader>
            </div>
          }
        </div>
      </Link>
    </div>


  );
}