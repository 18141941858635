import React from "react";
import { useAuth } from "../../hooks/auth";
import { useMyZenService } from "../../hooks/ZenUserContext";
import { LogIn } from "../home/LogIn";
import { ReactSession } from 'react-client-session';
import { PuffLoader } from "react-spinners";

type Props = {
    children?: React.ReactNode;
    overrideRoute?: string;
    loggedOutComponent?:React.ReactNode;
};

const PrivateRoute: React.FC<Props> = ({ children, overrideRoute }) => {

    let auth = useAuth();
    let userContext = useMyZenService();

    const [hasLoaded, setHasLoaded] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [attemptsAllowed, setAttamptsAllowed] = React.useState(5);


    const username = ReactSession.get("username");

    const loadUserDetails = async () => {
        setLoading(true);
        let log_in_name = username != null ? username : auth.username;
        if (log_in_name != null) {
            console.log("Acqired User Name of: " + log_in_name);
            await userContext.getProfile(log_in_name);
            //if not logged in in cognito, do this too!!!!!
            if (!auth.isAuthenticated || (username && auth.username != username) ) {
                auth.signIn(log_in_name,ReactSession.get("mindfulnest"));
            }
            setHasLoaded(true);
        }
        setLoading(false);
    }
    
    if (!userContext.userInfoLoaded && !loading && attemptsAllowed >0) {
        setAttamptsAllowed(attemptsAllowed-1);
        loadUserDetails();
    }

    const grantAccess = userContext.userInfoLoaded && auth.isAuthenticated && userContext.profile && auth.username == userContext.profile.username;// AND COGNITO LOGGED IN PLZ!!!!! || ZEN_RUN_MODE == "Local";

    return grantAccess ? <>{children}</> : !hasLoaded ? 
    <div className="zencLoader">
          <PuffLoader color="#033f63" size={300}></PuffLoader>
    </div> : <LogIn overrideRoute={overrideRoute}></LogIn>;
};

export default PrivateRoute;