import * as React from "react";
import "../../styling/common/index.scss";
import ImageDrop from "./studio/ImageDrop";
import BrowseLocalFilesButton from "./studio/BrowseLocalFilesButton";
import axios from "axios";
import { ZEN_C_ENDPOINT } from "../../config/EnvironmentalVariables";
import { BarLoader } from "react-spinners";
import { useNavigate } from "react-router";
import 'react-image-crop/dist/ReactCrop.css';
import { ZenPicResizer } from "./ZenPicResizer";

export const ChangeProfilePic = (props: any) => {

      const [imageFile, setImageFile] = React.useState<File | null>(null);
      const [previewURL, setPreviewURL] = React.useState<string | null>(null);
      const [loadingStatus, setLoadingStatus] = React.useState<string>(null);

      const handleFileSelect = (files: FileList | null) => {
            if (files && files.length > 0) {
                  const selectedFile = files[0];
                  if (selectedFile && (selectedFile.type === "image/jpeg" || selectedFile.type === "image/png")) {
                        setImageFile(selectedFile);
                        setPreviewURL(URL.createObjectURL(selectedFile));
                  }
            }
      };

      let navigate = useNavigate();

      const choosePhoto = (cropDetails:any) => {
            setLoadingStatus("loading");
            uploadPhoto(cropDetails.cropX, cropDetails.cropY, cropDetails.cropWidth);
      }

      const uploadPhoto = (cropX,cropY,cropWidth) => {
            if(imageFile != null){
                  const mediaFormData = new FormData();
                  mediaFormData.append("image", imageFile);
                  axios.post(ZEN_C_ENDPOINT + `/myzen/practitioner/addImage/` + props.user, mediaFormData, {
                        headers: {
                              "Content-Type": "multipart/form-data",
                        }
                  }
                  ).then(function (response) {
                        props.getPractitionerInfo();
                        addPhotoCrop(cropX,cropY,cropWidth);
                        props.setPage("Practitioner");
                  }).catch(function (error) {
                        setLoadingStatus("error");
                        addPhotoCrop(cropX,cropY,cropWidth);
                        console.log(error);
                  });
            } else {
                  addPhotoCrop(cropX,cropY,cropWidth);
            }
            
      }

      const addPhotoCrop = (cropX,cropY,cropWidth) => {
            axios.post(ZEN_C_ENDPOINT + `/myzen/practitioner/cropImage/` + props.user, {
                  username:props.user,
                  imageCropX:cropX,
                  imageCropY:cropY,
                  imageCropWidth:cropWidth
            }
            ).then(function (response) {
                  props.getPractitionerInfo();
                  props.setPage("Practitioner");
            }).catch(function (error) {
                  setLoadingStatus("error");
                  console.log(error);
            });
      }

      return (
            <div style={{ margin: "auto", paddingTop:"100px" }}>
                  {loadingStatus == "loading" ?
                        <div>
                              <h3 style={{ margin: "auto", textAlign: "center" }}>Uploading Photo...</h3>

                              <BarLoader color="#033f63">
                              </BarLoader>
                        </div>
                        : loadingStatus == "error" ?
                              <div>
                                    <h3 style={{ margin: "auto", textAlign: "center" }}>Oh no! Something went wrong...</h3>
                                    <h4 style={{ margin: "auto", textAlign: "center" }}>Please message <a href="mailto:contact@zencyclopedia.co.uk"><b>contact@zencyclopedia.co.uk </b> </a> and we'll get this sorted for you!</h4>

                              </div>
                              
                              : loadingStatus == "changeSize" ?
                              <ZenPicResizer
                              previewURL={props.currentImage}
                              upload = {(cropDetails) => choosePhoto(cropDetails)}
                              ></ZenPicResizer>
                              : loadingStatus == "confirmSize" ?
                              <ZenPicResizer
                              previewURL={previewURL}
                              upload = {(cropDetails) => choosePhoto(cropDetails)}
                              ></ZenPicResizer>
                                   
                                    :

                                    <React.Fragment>
                                         

                                          <div className="zen-mobile">
                                          <div className="zenGlowButton mingGlowbutton"
                                          onClick = {() => {
                                                setLoadingStatus("changeSize");
                                          }}>Crop/Edit Existing Photo</div>
                                          <h3 style = {{textAlign:"center"}}>or</h3>
                                          <h4 style={{ margin: "auto", textAlign: "center", marginTop: "9px", marginBottom: "15px" }}>Choose New Profile Pic:</h4>
                                                
                                                {previewURL ?
                                                      <img className="content-preview-image" src={previewURL} ></img>
                                                      :
                                                      <div>
                                                            <div style={{ display: "flex", flexDirection: "row", width: "275px", margin: "auto" }}>
                                                                  {/* <div className="zenGlowButton mingGlowButton" onClick = {() => setStepNum("choose-image")}>Zen C Library</div> */}
                                                                  <BrowseLocalFilesButton accept="image/jpeg, image/png" onFileSelect={handleFileSelect} />
                                                            </div>
                                                      </div>}
                                          </div>
                                          <div className="zen-desktop changeProfRow" style={{ margin: "auto"}}>
                                                
                                          <div style = {{width:"300px", height:"300px"}}>
                                          <div className="redOptionText"
                                          onClick = {() => {
                                                setLoadingStatus("changeSize");
                                          }}>Crop/Edit Existing Photo</div>
                                          <h3 style = {{textAlign:"center"}}>or</h3>
                                          <h4 style={{ margin: "auto", textAlign: "center", marginTop: "9px", marginBottom: "15px" }}>Choose New Profile Pic:</h4>
                                         
                                          </div>
                                          <div>
                                                <ImageDrop
                                                      endpoint={""}
                                                      setFile={setImageFile}
                                                      setPreviewURL={setPreviewURL}
                                                      file={imageFile}
                                                      previewURL={previewURL}
                                                      handleFileSelect={handleFileSelect}
                                                      size="small"
                                                />
                                          </div>
                                          </div></React.Fragment>
                  }
                  <div style={{ display: "flex", flexDirection: "row", width: "250px", margin: "auto", marginTop: "15px" }}>
                        <div className="zenGlowButton buddGlowButton" onClick={() => props.setPage("Practitioner")}>Cancel</div>
                        {imageFile && loadingStatus != "confirmSize"?
                        <div className="zenGlowButton mingGlowButton" onClick={() =>setLoadingStatus("confirmSize")}>Next</div>
                        : null}
                  </div>

            </div>
      );

}
