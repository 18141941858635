import * as React from "react";
import "../../../styling/common/index.scss";
import "../../../styling/common/zenCoreUIComponents.scss";
import "../../../styling/myzen/myzen.scss";
import { useState } from "react";
import { useZenJournalService } from "../../../hooks/ZenJournalContext";
import { ZenCardMilestoneNotice } from "../../explore/icons/ZenCardMilestoneNotice";

export const CreateJournalModal = (props: any) => {


  const [page, setPage] = useState(1);
  const [journalComments, setJournalComments] = useState("");
  const [mood, setMood] = useState("");
  const [rewardCard, setRewardCard] = useState(null);

  const journalService = useZenJournalService();

  const submitJournalEntry = async () => {
      let result = await journalService.createJournalEntry(mood, journalComments);
      console.log("created journal entry.");
      if(result != null && result.cardKey != null){
        console.log("SHOWING JOURNAL REWARD CARD");
        setRewardCard(result);
        setPage(3);
      } else {
        console.log("NO REWARD CARD");
        props.setFocusedMyZenItem("entries");
        journalService.getUserJournal();
      }
  }

  return (
    <React.Fragment>
    {page == 3 && rewardCard != null ?
          <ZenCardMilestoneNotice cards={[rewardCard]} moveOnAction={() => {journalService.getUserJournal();
            props.setFocusedMyZenItem("entries");}}/>
          :
    <div className="create-journal-entry">

      {page == 1 ?
        <div className="create-mood-log">
          <h2 className="journal-log-header">How are you feeling today?</h2>
          <div className="mood-options">
            <div className='mood-option'>
              <img className='mood-option-image' src={require('../../../static/photos/languageIcons/happy.png')} onClick={() => {
                setMood("HAPPY");
                setPage(2);
              }}></img>
              <h4>Happy & Content</h4>
            </div>
            <div className='mood-option'>
              <img className='mood-option-image' src={require('../../../static/photos/languageIcons/tired.png')} style={{ marginTop: "-5px" }} onClick={() => {
                setMood("TIRED");
                setPage(2);
              }}></img>
              <h4>Tired</h4>
            </div>
            <div className='mood-option'>
              <img className='mood-option-image' src={require('../../../static/photos/languageIcons/anxious.png')} onClick={() => {
                setMood("ANXIOUS");
                setPage(2);
              }}></img>
              <h4>Anxious</h4>
            </div>
            <div className='mood-option'>
              <img className='mood-option-image' src={require('../../../static/photos/languageIcons/sad.png')} onClick={() => {
                setMood("SAD");
                setPage(2);
              }}></img>
              <h4>Sad</h4>
            </div>
            <div className='mood-option'>
              <img className='mood-option-image' src={require('../../../static/photos/languageIcons/angry.png')} onClick={() => {
                setMood("ANGRY");
                setPage(2);
              }}></img>
              <h4>Angry</h4>
            </div>
            <div className='mood-option'>
              <img className='mood-option-image' src={require('../../../static/photos/languageIcons/unsure.png')} onClick={() => {
                setMood("UNSURE");
                setPage(2);
              }}></img>
              <h4>Unsure</h4>
            </div>
            <h4 className='journal-skip' onClick={() => submitJournalEntry()}>skip mood</h4>

          </div>
        </div>
        : page == 2 ?
          <div className="practice-feedback-container">

            <h3 className="journal-log-header">What's on your mind?</h3>
            <form noValidate >
              <textarea
                placeholder="Thoughts, feelings & revelations go here..."
                value={journalComments}
                onChange={(e) => setJournalComments(e.target.value)}
                className={'journal-text-input'}
              />
            </form>
            <div className = "zenc-button-row">
              <div className="zencTextButton zencRedButton" onClick={() => {
                submitJournalEntry();
              }}>
                skip
              </div>
              <div className="zencTextButton zencGreenButton" onClick={() => {
                submitJournalEntry();
              }}>
                submit
              </div>
            </div>
          </div>
          :
          <></>
      }
    </div>
}
</React.Fragment>
  );
}
