import * as React from "react";
import "../../../styling/common/index.scss";
import axios from "axios";
import { useAuth } from "../../../hooks/auth";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { ZEN_C_ENDPOINT } from "../../../config/EnvironmentalVariables";
import { SubscriptionEnroller } from "./SubscriptionEnroller";
import { useZenQuotasService } from "../../../hooks/ZenQuotasContext";

export const CancelSubscriptionModal = (props: any) => {

  const [subscriptionCancelled, setSubscriptionCancelled] = React.useState(false);
  
  let auth = useAuth();

  let quotasContext = useZenQuotasService();

  const cancelSubscription = () => {
    axios.post(ZEN_C_ENDPOINT + `/myzen/mysubscription/cancel`, {
      username: auth.username
    }).then(function (response) {
      setSubscriptionCancelled(true);
    }).catch(function (error) {
      console.log(error);
      console.log("failed to make cancellation req")
    });
  }

  return (
    <React.Fragment>
      {/* <SubscriptionEnroller username = {auth.username} subscriptionTier = {subscriptionTier} paymentIntent = {pI}/> */}
      <div className="ZenBasicPage zen-page-holder" style={{ paddingTop: "0" }}>
        {!subscriptionCancelled ?
          <React.Fragment>
            <h2 style={{ marginTop: 0 }}>Cancel your subscription</h2>

            <h4 style={{ marginTop: "20px" }}>Are you sure you want to cancel your subscription?</h4>

            {quotasContext.zenQuotasResults && quotasContext.zenQuotasResults.subscriptionBillingDate != null ? 
            <h4 style={{ marginTop: "20px" }}>You will lose access to your current advantages on {quotasContext.zenQuotasResults.subscriptionBillingDate}</h4>
            :null}
          
            <div className="zenGlowButton mingGlowButton" onClick = {() => cancelSubscription()} style={{ marginTop: "30px"}}>Yes, please cancel my subscription</div>

            <Link to = {"/myzen/mysubscription"} style = {{margin:"auto"}}>
            <div className="zenGlowButton mingGlowButton">No, I've changed my mind</div>
            </Link>
            <h4 style={{ padding: "25px" }}>If there is something you are not happy with, why not get in touch by emailing <a href="mailto:contact@zencyclopedia.co.uk"><b>contact@zencyclopedia.co.uk </b> </a>, we'll try to make sure you feel you're getting value for money!</h4>
            {"PREMIUM" && false ?
              <h4>Why not try downgrading to a cheaper subscription instead?</h4>
              : null}
           
          </React.Fragment>
          :<React.Fragment>

           <h2 style={{ marginTop: 0 }}>Your subscription has been cancelled</h2>
           <h4 style={{ marginTop: "20px" }}>If there is something you are not happy with, why not get in touch by emailing <a href="mailto:contact@zencyclopedia.co.uk"><b>contact@zencyclopedia.co.uk </b> </a></h4>
           {quotasContext.zenQuotasResults && quotasContext.zenQuotasResults.subscriptionBillingDate != null ? 
            <h4 style={{ marginTop: "20px" }}>Don't worry, You can still use your subscription advantages until {quotasContext.zenQuotasResults.subscriptionBillingDate}</h4>
            :null}
          
          <Link to = {"/"} style = {{margin:"auto"}}>
            Great, thanks!
          </Link>
          </React.Fragment>}
      </div>
    </React.Fragment>
  );
}

