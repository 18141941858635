

import * as React from "react";
import '../../../styling/common/zenCoreUIComponents.scss';
import { MyZenJournal } from "../../myzen/journal/MyZenJournal";
import { ZenStream } from "../../home/ZenStream";

export const ZenJournalWalkthrough = () => {
    const [page, setPage] = React.useState(1);
    
    return (
        <React.Fragment>
            {page == 1?
            <div className="ming-page">
                <div className="ming-page-text" style = {{marginTop:"35px"}}>
                <h4 className = "goldText"><span className='prompt-qouted'><i>The best time to start keeping a journal is whenever you decide to.</i></span></h4>
                              <br></br>

                    Use the Zen Journal to keep track of any thoughts and feelings that arise during your mindfulness journey, this can be a powerful tool in looking back and gaining valuable insights into your progress. 
                    <br></br> <br></br>
                    You'll also be prompted to complete a journal entry after each practice, we'll use the feedback to deliver personalised content that leaves you feeling the best! 


                    <div className='finish-session-icon' onClick={() => setPage(2)} style = {{marginTop:"25px"}}></div>
                </div>
            </div>
            :<MyZenJournal/>
            }
        </React.Fragment>
    );
}