import * as React from "react";
import "../../../styling/common/index.scss";
import "../../../styling/explore/explore.scss";
import { useState } from "react";
import axios from "axios";
import { useAuth } from "../../../hooks/auth";
import { ZEN_C_ENDPOINT } from "../../../config/EnvironmentalVariables";
import { MeditationIcons } from "../../zencontent/practices/MeditationIcons";
import { BarLoader } from "react-spinners";
import { ContentReccomendationCreationModal } from "./ContentReccomendationCreationModal";
import ZenContentInterface from "../../zencontent/practices/ZenContentInterface";
import { PractitionerContentReccomendation } from "./PractitionerContentReccomendation";
import { useNavigate } from "react-router";

export const ReadingListStudio = (props: { userId: any, closePage?: any }) => {

      const [stepNum, setStepNum] = useState("content-list-view");
      const [contentReccs, setContentReccs] = useState([]);

      const getPractitionerReccs = async () => {
            await axios.get(ZEN_C_ENDPOINT + `/zen/practitionerRecommendations/` + props.userId)
                  .then(res => {
                        setContentReccs(res.data);
                  });
      }

      React.useEffect(() => {
            getPractitionerReccs();
      }, []);

      let auth = useAuth();

      const addContentRecc = (contentReccInfo: any) => {
            var newSessions = [];
            contentReccs.map(s => newSessions.push(s));
            newSessions.push(contentReccInfo);
            setContentReccs(newSessions);
            setStepNum("content-list-view");
      }

      const removeRecc = (contentReccIndex: any) => {
            var newSessions = contentReccs.filter((s, index) => index != contentReccIndex);
            setContentReccs(newSessions);
            setStepNum("content-list-view");
      }


      const changeLessonOrder = (lessonNumber: number, direction: string) => {
            var newSessions = [];
            contentReccs.map((s, index) => {
                  if (direction == "UP") {
                        if ((index + 2) == lessonNumber) {
                              newSessions.push(contentReccs[lessonNumber - 1]);
                              newSessions.push(s);
                        } else if ((index + 1) == lessonNumber) {

                        } else {
                              newSessions.push(s);
                        }
                  } else if (direction == "DOWN") {
                        if ((index + 1) == lessonNumber) {
                              newSessions.push(contentReccs[index + 1]);
                        } else if ((index) == lessonNumber) {
                              newSessions.push(contentReccs[index - 1]);
                        } else {
                              newSessions.push(s);
                        }
                  }
            });
            setContentReccs(newSessions);
            setStepNum("content-list-view");
      }

      let navigate = useNavigate();

      const updateReadingList = () => {
            const newReadingListItems = contentReccs.map((cr, index) => {
                  return {
                        practitioner: props.userId,
                        type: cr.type,
                        title: cr.title,
                        description: cr.description,
                        link: cr.link,
                        order: index
                  }

            });

            axios.put(ZEN_C_ENDPOINT + `/zen/practitionerRecommendations/updateReccList`, newReadingListItems)
                  .then(function (response) {
                        props.closePage();
                  }).catch(function (error) {
                        console.log(error);
                  });
      }

      return (
            <div>
                  {stepNum == "content-list-view" ?
                        <div>
                              <h2 className="" style={{ margin: "auto", textAlign: "center", marginBottom:"10px"}}>Reccomended Content List</h2>
                              <div>
                                    {contentReccs.map((s, index) => <PractitionerContentReccomendation lessonNumber={index + 1} changeLessonOrder={(lessonNum, direction) => changeLessonOrder(lessonNum, direction)} removeRecc={(id) => removeRecc(id)}{...s}></PractitionerContentReccomendation>)}
                              </div>
                              <div className="path-add-content-banner add-content-recc-banner" onClick={() => setStepNum("add-content")}>
                                    <div style={{ width: "250px", margin: "auto", fontSize: "90%", textAlign:"center" }}>
                                          <h2 className="">Add Reccomendation</h2>
                                    </div>
                                    <div className="addContentPlus" style = {{marginLeft:"-20px"}}></div>
                              </div>
                        </div>
                        : stepNum == "add-content" ?
                              <ContentReccomendationCreationModal userId={props.userId} addContentRecc={(session) => addContentRecc(session)} />
                              : null}

                  {stepNum != "add-content" && contentReccs != null ?
                        <div className="confirmChooseSessionBanner">
                              <div
                                    className="zenGlowButton buddGlowButton"
                                    style={{ width: "100px" }}
                                    onClick={() => props.closePage()} >Cancel</div>
                              <div
                                    className="zenGlowButton sageGlowButton"
                                    style={{ width: "100px" }}
                                    onClick={() => updateReadingList()} >Save & Finish
                              </div>
                        </div>
                        : null}
            </div>
      )
}