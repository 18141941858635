import * as React from "react";
import { Link } from "react-router-dom";
import '../../styling/common/index.scss';
import { ZenNavBar } from "../common/navbar/ZenNavBar";
import { ZencTitle } from "./ZencTitle";
import { ZenHome } from "./ZenHome";

export const Philzentropy = (props: any) => {

  // <h4 className="justifiedText">We're on a mission to offer free & accessible meditation training and to empower people to take control of their mental well-being one breath at a time!</h4>

  // <div>

  //   <p className="justifiedText">Mental health services in the UK and elsewhere are in a pretty dire state, most people do not understand their mind, and sadly,
  //   neither does society in general.</p>


  //   <p className="justifiedText"> Our goal is to offer free and accessible mindfulness training and mental health resources to those that need it.</p>

  //   <h2> How you can help: </h2>
  //   <Link to = "/donate">
  //   <div className = "zenGlowButton mingGlowButton">Make a Donation</div>
  //   </Link>
  //   What will we do with your donation?
  //   <ul>
  //   <li>Provide free premium accounts to those that need it most. </li> 
  //   <li>Curate content that is free for all users.</li> 
  //   <li>Design and Innovation of new tools for the Zen Philzentropy programme.</li> 
  //   </ul>
  //   {/* By becoming a sponsor, you will be given the opportunity to dictate which of the above areas your money goes to, and potentially
  //   receive recognition & get your name on the finished product* 

  //   * Either need to agree a solo sponsorship offer with us, or meet the minimum sponsorship donation of £50. (smaller donations welcomed above.) */}

  //   {/* Sponsored Users:

  //   It costs £X per user, per month.
  //   - Unlimited time in the Zen Practice Portal.
  //   - Access to Smart Planning Tools, Zen Journal, Mood Tracking, Zen Skills.
  //   - Unlimited Free Access to the Zencyclopaedia and Zen Articles covering a range of mental health topics.
  //   - Ad Free experience if under 18.
  //   - 1000 ZENergy points a month to facilitate access to premium courses and meditations.

  return (
    <div className="ZenBasicPage">
      <ZenNavBar />
      <ZencTitle />
      <div className="philzentopry-text">

        <h4><span className='prompt-qouted zenc-page-header-quote okashi'><i>“Not all of us can do great things, but we can do small things with great love.”</i></span><span className='prompt-qouted-auth zenc-page-header-quote okashi'> - Mother Teresa </span></h4>

        <h3 style={{ textAlign: "justify" }}>At Zencyclopedia, our mission is to provide free and accessible meditation training, empowering individuals to prioritize their mental well-being. We recognize the concerning state of mental health services, with many people lacking understanding of their own minds and society as a whole. That's why we are dedicated to offering free mindfulness training and mental health resources to those in need.</h3>
        <div className="white-page-inset">
          <h3 style={{ textAlign: "justify" }}>You can be part of this transformative movement by making a donation. Your contribution will have a direct impact on our efforts to make a difference. With your support, we can:</h3>

          <ul>
            <li>Provide free premium accounts to those who need them most.</li>
            <li>Curate content that is accessible and free for all users.</li>
            <li>Drive the design and innovation of new tools for the Zen Philzentropy program.</li>
          </ul>
          <Link to="/donate">
            <div className="zenGlowButton mingGlowButton" style = {{marginTop:"25px"}}>Make a Donation</div>
          </Link>
        </div>
        <h3 style={{ textAlign: "justify" }}>By donating, you enable us to reach more individuals and make a positive impact on their mental well-being. Together, we can foster a society that understands and prioritizes mental health. Join us in our mission to empower individuals, one breath at a time. Your donation will contribute to creating a positive impact on mental health and well-being, providing support and resources to those who need it most. Together, let's make a difference and build a brighter future for mental health.</h3>

      </div>
    </div>
  );
}

