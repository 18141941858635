import * as React from "react";
import "../../styling/common/index.scss";
import "../../styling/common/zenCoreUIComponents.scss";
import "../../styling/myzen/myzen.scss";
import { ZenNavBar } from "../common/navbar/ZenNavBar";
import { useState } from "react";
import { useZenExploreService } from "../../hooks/ZenExploreContext";
import axios from "axios";
import { ZEN_C_ENDPOINT } from "../../config/EnvironmentalVariables";
import { useAuth } from "../../hooks/auth";
import { PathPreview } from "../zencontent/courses/PathPreview";
import { useNavigate, useParams } from "react-router";
import { PuffLoader } from "react-spinners";
import { getContentCatagories } from "../../service/ZencyclopaediaService";
import { ZenPopUpMessage } from "../common/ZenPopUpMessage";
import { ZenSearchFilterTagsDisplay } from "./search/ZenSearchFilterTagsDisplay";
import { ZenSearchBar } from "./search/ZenSearchBar";
import { useZenSearchService } from "../../hooks/ZenSearchContext";
import Slider from "react-slick";
import { ExploreSliderPreview } from "./previews/ExploreSliderPreview";

export const ArticlesCarousel = () => {

      const sliderRef: any = React.createRef();
      const desktopSliderRef: any = React.createRef();


      const generateCarouselInner = (carouselRef:any) => {
            return [
                  // <div style={{ margin: "auto" }}>
                  //       <div className="zenscape-slider-item explore-slider-item" onClick={() => {
                  //             carouselRef.current.slickGoTo(1);
                  //       }}>
                  //             <h3 className="explore-slider-title" >Explore our range of ZEN courses to bring direction to the next stage of your mindfulness journey!</h3>
                  //             <h4 className="explore-slider-description" >Find our top user rated courses in the carousel - or use the search bar to look for something more specific</h4>
                  //       </div>
                  // </div>,

                  <ExploreSliderPreview index={0}
                        description={"For most folks, No news is good news. For the press, good news is not news. We'd like to change that."}
                        photoBottom={true}
                        article={true}
                        title='Good News'
                        searchRoute={"/explore/articles/goodnews"} image={require("../../static/photos/exploreIcons/celbratingbuddhas.png")}></ExploreSliderPreview>,
                  <ExploreSliderPreview index={2}
                        photoBottom={true}
                        article={true}
                        description={"A selection of top shorter reads."}
                        title='Zen Bites' searchRoute={"/explore/articles/shortreads"} image={require("../../static/photos/exploreIcons/laughingAtPhone.png")}></ExploreSliderPreview>,
                  <ExploreSliderPreview index={2}
                        photoBottom={true}
                        article={true}
                        description={"Get lost in something wonderful!"}
                        title='Longer Reads' searchRoute={"/explore/articles/longreads"} image={require("../../static/photos/exploreIcons/buddhasRead.png")}></ExploreSliderPreview>,
                  <ExploreSliderPreview index={4}
                  article={true}
                        image={require("../../static/photos/exploreIcons/infoBacked.png")}
                        description={"Explore the transformative power of conscious breathing exercises and their benefits in meditation."}
                        title='The Zencyclopedia' searchRoute={"/explore/thezencyclopedia"} colorClass={'ZenCGradient1'}></ExploreSliderPreview>,
                  // <ExploreSliderPreview index={5}
                  // article={true}
                  //       image={require("../../static/photos/exploreIcons/zen-studies.png")}
                  //       description={"Combine meditation with gentle movements and postures to cultivate mindfulness in motion."}
                  //       title='Zen Studies' searchRoute={"/explore/articles/study"} colorClass={'ZenCGradient3'}></ExploreSliderPreview>,
            ];
      }

      return (
            <div className="zenverseSliderHolder">
                  <div className="zen-mobile">
                        <Slider
                              centerMode={true}
                              centerPadding='15%'
                              autoplay={true}
                              ref={sliderRef}
                              dots={true}
                              infinite={true}
                        >
                              {generateCarouselInner(sliderRef)}
                        </Slider>
                  </div>
                  <div className="zen-desktop">
                        <Slider
                              centerMode={true} 
                              centerPadding='35%'
                              autoplay={true}
                              ref={desktopSliderRef}
                              dots={true}
                              infinite={true}
                        >
                        {generateCarouselInner(desktopSliderRef)}
                        </Slider>
                  </div>
            </div>
      )
}