export const searchForPracticeCompleted = (practiceId: number) => {
  return false;
}

export const getPathCompletionMeterClass = (completionRate: any) => {

  if (completionRate != null && completionRate.totalLessons == null && completionRate.lessonsCompleted == null) return "meter-0";

  let percentage = calculatePathCompletionPercentage(completionRate);

  return percentage > 0 && percentage <= 10? "meter-10" : 
  percentage > 10 && percentage < 20? "meter-20" : 
  percentage > 20 && percentage < 30? "meter-30" :
  percentage > 30 && percentage < 40? "meter-40" :
  percentage > 40 && percentage < 50? "meter-50" :
  percentage > 50 && percentage < 60? "meter-60" :
  percentage > 60 && percentage < 70? "meter-70" :
  percentage > 70 && percentage < 80? "meter-80" :
  percentage > 80 && percentage < 90? "meter-90" 
  : "meter-100";
}

export const getPathCompletionProgressMeterClass = (completionRate: any) => {

  if (completionRate != null && completionRate.totalLessons == null && completionRate.lessonsCompleted == null) return "progress0";

  let percentage = calculatePathCompletionPercentage(completionRate);

  return percentage > 0 && percentage <= 10? "progress10" : 
  percentage > 10 && percentage < 20? "progress20" : 
  percentage > 20 && percentage < 30? "progress30" :
  percentage > 30 && percentage < 40? "progress40" :
  percentage > 40 && percentage < 50? "progress50" :
  percentage > 50 && percentage < 60? "progress60" :
  percentage > 60 && percentage < 70? "progress70" :
  percentage > 70 && percentage < 80? "progress80" :
  percentage > 80 && percentage < 90? "progress90" 
  : "progress100";
}

export const calculatePathCompletionPercentage = (completionRate:{totalLessons:any,lessonsCompleted:any}) => {
    return (100/completionRate.totalLessons)*completionRate.lessonsCompleted;
}

const introList = [
  "the_",
  ""
]
const zenNameStarterList = [
  "wandering",
  "noble",
  "mysterious",
  "first",
  "last",
  "warrior",
  "cyber",
  "purple",
  "orange",
  "lost",
  "cloudhidden",
];

const zenPart2List = [
  "zen",
  "enlightened",
  "cosmic",
  "moon",
  "mushroom",
  "chakra",
  "sunset",
  "happy",
  "peaceful",
  "cherry",
  
];

const zenNamePart3List = [
  "fruit",
  "inscense",
  "hope",
  "love",
  "avatar",
  "chan",
  "root",
  "home",
  "enso",
  "ram",
  "baba"
];

const zenNameFinisherList = [
  "guru",
  "ram",
  "dalai",
  "roshi",
  "osho",
  "yogii",
  "yoga",
  "Tulku",
  "shaman",
  "samurai"
];

export const generateRandomName = () => {
    var nameIntro = introList[Math.floor(Math.random() * introList.length)];
    var nameStart = zenNameStarterList[Math.floor(Math.random() * zenNameStarterList.length)];
    var namePart3 = zenPart2List[Math.floor(Math.random() * zenPart2List.length)];
    var namePart4 = zenNamePart3List[Math.floor(Math.random() * zenNamePart3List.length)];
    var namePart5 = zenNameFinisherList[Math.floor(Math.random() * zenNameFinisherList.length)];

    return nameIntro  + nameStart + '_' + namePart3 + '_' + namePart4 + '_' + namePart5;
}




