import * as React from "react";
import { MyZenNavBar } from "../../common/navbar/MyZenNavBar";
import "../../../styling/common/index.scss";
import { ZenNavBar } from "../../common/navbar/ZenNavBar";
import { useState } from "react";
import axios from "axios";
import { useAuth } from "../../../hooks/auth";
import PrivateRoute from "../../common/PrivateRoute";
import { useNavigate } from "react-router-dom";
import { useMyZenService } from "../../../hooks/ZenUserContext";
import { useZenCardsService } from "../../../hooks/ZenCardsContext";
import { ZenCardMilestoneNotice } from "../../explore/icons/ZenCardMilestoneNotice";
import { SubscriptionSummaryCard } from "./SubscriptionSummaryCard";
import { getSubscriptionAllowanceMessage } from "../../../service/ZenSubscriptionsService";
import Slider from "react-slick";
import { useZenQuotasService } from "../../../hooks/ZenQuotasContext";
import { ZenLoader } from "../../common/ZenLoader";

export const ZenSubscriptionsTiersBrowser = () => {

      const auth = useAuth();
      const [slideNum, setSlideNum] = useState(0);
      const [subscriptionTierCards, setSubscriptionTierCards] = useState(null);
      let zenUserContext = useMyZenService();

      let quotasContext = useZenQuotasService();

      let userSubscriptionLevel = quotasContext != null && quotasContext.zenQuotasResults != null ? quotasContext.zenQuotasResults.subscriptionLevel : "FREE";

      console.log("YOUR SUBSCRIPTION LEVEL IS: " + userSubscriptionLevel);
      console.log("Another way: " + quotasContext != null && quotasContext.zenQuotasResults != null ? quotasContext.zenQuotasResults.subscriptionLevel : "nothing");
      let zenCardsService = useZenCardsService();

      const getSubscriptionCards = async () => {
            if (subscriptionTierCards == null) {
                  let result = await zenCardsService.searchZenCards("SUBSCRIPTION");
                  setSubscriptionTierCards(result && result.length > 0? result.reverse() : []);
            }
      }
      React.useEffect(() => {
            getSubscriptionCards();
      });

      let navigate = useNavigate();


      return (
            <React.Fragment>
                  <ZenNavBar activeItem="MyZen" primaryColor="#D1603D" enabled={(zenUserContext.profile && zenUserContext.profile.portalWalkthroughCompleted)}></ZenNavBar>

                  <div className="ZenBasicPage" style={{ paddingTop: "100px", paddingBottom: "100px" }}>
                        
                        {userSubscriptionLevel != "FREE" ?
                              <h3 style={{ marginBottom: "15px" }}>You are currently a {userSubscriptionLevel} Subscriber</h3>
                              :quotasContext != null && quotasContext.zenQuotasResults != null && quotasContext.zenQuotasResults.subscriptionLevel == "FREE" ? 
                              <h3 style={{ marginBottom: "15px" }}>You are currently on the Free Tier</h3>
                              :null
                        }
                        {subscriptionTierCards && subscriptionTierCards.length > 0 ?
                              <React.Fragment>
                                    <div className="zen-mobile">
                                          <Slider className="zen-subscriptions-slider"
                                                centerMode={true}
                                                centerPadding='13%'
                                                afterChange={(slideNumber) => setSlideNum(slideNumber)}>
                                                {subscriptionTierCards.map(st => <SubscriptionSummaryCard {...st} />)}

                                          </Slider>
                                    </div>
                                    <div className="zen-desktop">
                                          <Slider className="zen-subscriptions-slider"
                                                centerMode={true}
                                                centerPadding='25%'
                                                afterChange={(slideNumber) => setSlideNum(slideNumber)}>
                                                {subscriptionTierCards.map(st => <SubscriptionSummaryCard {...st} />)}
                                          </Slider>
                                    </div>
                                    {userSubscriptionLevel == "PREMIUM" && slideNum == 1 ?
                                          <div className="zenGlowButton navajoGlowButton" style={{ marginTop: "50px" }} onClick={() => {
                                                navigate("/subscriptions/downgrade/BASIC");
                                          }}>Downgrade to Basic</div>
                                          : (userSubscriptionLevel == "PREMIUM" || userSubscriptionLevel == "BASIC") && slideNum == 2 ?
                                                <div className="zenGlowButton navajoGlowButton" style={{ marginTop: "50px" }} onClick={() => {
                                                      navigate("/myzen/mysubscription");
                                                }}>Downgrade to Free</div>
                                                : userSubscriptionLevel == "PREMIUM" ?
                                                      null
                                                      : userSubscriptionLevel == "BASIC" && slideNum == 0 ?
                                                            <div className="zenGlowButton navajoGlowButton" style={{ marginTop: "50px" }} onClick={() => {
                                                                  navigate("/subscriptions/upgrade/PREMIUM");
                                                            }}> Upgrade to Premium </div>
                                                            : userSubscriptionLevel == "FREE" && slideNum == 1 ?
                                                                  <div className="zenGlowButton navajoGlowButton" style={{ marginTop: "50px" }} onClick={() => {
                                                                        navigate("/subscriptions/create/BASIC");
                                                                  }}>
                                                                        Upgrade to Basic</div>
                                                                  : userSubscriptionLevel == "FREE" && slideNum == 0 ?
                                                                        <div className="zenGlowButton navajoGlowButton" style={{ marginTop: "50px" }} onClick={() => {
                                                                              navigate("/subscriptions/create/PREMIUM");
                                                                        }}>
                                                                              Upgrade to Premium</div>
                                                                        : null
                                    }
                              </React.Fragment>
                              : <ZenLoader />
                        }



                  </div>
            </React.Fragment>);

}
