import * as React from "react";
import { useState } from "react";
import { useAuth } from "../../../hooks/auth";
import ZenPracticeSessionInterface from "../interface/ZenPracticeSessionInterface";

import "../../../styling/myzen/myzen.scss";
import Picker from 'react-scrollable-picker';
import { ZenPracticeSessionPreview } from "./ZenPracticeSessionPreview";
import axios from "axios";
import { ZEN_C_ENDPOINT } from "../../../config/EnvironmentalVariables";
import { ZenCardMilestoneNotice } from "../../explore/icons/ZenCardMilestoneNotice";

export const CreatePracticeSession = (props: any) => {


      const auth = useAuth();

      const [blueprint, setBlueprint] = useState(null);
      const [durationH, setDurationH] = useState(null);
      const [durationM, setDurationM] = useState(null);
      const [days, setDays] = useState([]);
      const [title, setTitle] = useState(null);
      const [sessionType, setSessionType] = useState(null);
      const [isBlueprintSession, setIsBlueprintSession] = useState(null);
      const [page, setPage] = useState('type');
      const [rewardCard, setRewardCard] = useState(null);

      const dow = ["Monday", "Tuesday", "Wednesday", "Thursday", 'Friday', "Saturday", "Sunday"];


      const addSessions = async () => {

            var sessionDuration = durationH ? (60 * durationH) + parseInt(durationM) : durationM;
            var cardReceived = false;
            if (days.length > 1) {
                  var cardRewardGranted = await createPracticeSessions(title, sessionDuration, sessionType);
                  if (cardRewardGranted) { cardReceived = true }
            } else {
                  cardReceived = await createPracticeSession(title, sessionDuration, days[0], sessionType);
            }


            if (!cardReceived) {
                  props.setShowAddSession(false);
                  props.finish();
            }
      }
      const createPracticeSessions = async (title: string, duration: number, sessionType: string) => {

            let sessions = [];
            days.map(day => {
                  sessions.push({
                        title: title,
                        username: auth.username,
                        duration: duration,
                        dayOfWeek: day,
                        sessionType: sessionType

                  });
            }
            );

            return await axios.post(ZEN_C_ENDPOINT + `/myzen/zenPlanner/addSessions`, sessions)
                  .then(function (response) {
                        // Tell user if they received a user card:
                        let cardResult = response.data;
                        console.log(response);
                        console.log(response.data);
                        if (cardResult != null && cardResult.cardKey != null) {
                              setRewardCard(cardResult);
                              setPage("rewardCard");
                              return true;
                        }
                  })
                  .catch(function (error) {
                        console.log(error);
                        return false;
                  });
      }

      const createPracticeSession = async (title: string, duration: number, day: string, sessionType: string) => {
            let session = {
                  title: title,
                  username: auth.username,
                  duration: duration,
                  dayOfWeek: day,
                  sessionType: sessionType

            };
            return await axios.post(ZEN_C_ENDPOINT + `/myzen/zenPlanner/addSession`, session)
                  .then(function (response) {
                        // Tell user if they received a user card:
                        let cardResult = response.data;
                        console.log(response);
                        console.log(response.data);
                        if (cardResult != null && cardResult.cardKey != null) {
                              setRewardCard(cardResult);
                              setPage("rewardCard");
                              return true;
                        }
                  })
                  .catch(function (error) {
                        console.log(error);
                        return false;
                  });
      }

      const dayOptions = dow.map((day) => {
            return days.includes(day) ? (
                  <div className='option-button-small chosenZenGoal' onClick={() => setDays(days.filter(prefferedD => prefferedD != day))}>
                        {day}
                  </div>)
                  :
                  (<div className='option-button-small'
                        onClick={() => {
                              days.push(day);
                              var newList = days.splice(0, days.length);
                              setDays(newList);
                        }}
                  >
                        {day}
                  </div>)
                  ;
      })

      return (
            <React.Fragment>
                  {page == "rewardCard" && rewardCard != null ?
                        <ZenCardMilestoneNotice cards={[rewardCard]} moveOnAction={() => {
                              props.setShowAddSession(false);
                              props.finish();
                        }} />
                        :
                        <div className="create-session-form">
                              {page == 'type' ?
                                    <div style={{ padding: '15px' }}>
                                          <h3>Choose Session Type</h3>
                                          <div className="chooseSessionRow">
                                                <div className="chooseSessionType med-session" onClick={() => {
                                                      setSessionType('meditation');
                                                      setPage('duration');
                                                }}>
                                                      Meditation
                                                </div>
                                                <div className="chooseSessionType yoga-session" onClick={() => {
                                                      setSessionType('yoga');
                                                      setPage('duration');
                                                }}>
                                                      Movement
                                                </div>
                                                <div className="chooseSessionType relax-session" onClick={() => {
                                                      setSessionType('relax');
                                                      setPage('duration');
                                                }}>
                                                      Relax
                                                </div>
                                                <div className="chooseSessionType lecture-session" onClick={() => {
                                                      setSessionType('lecture');
                                                      setPage('duration');
                                                }}>
                                                      Lecture
                                                </div>
                                                <div className="chooseSessionType hobby-session" onClick={() => {
                                                      setSessionType('hobby');
                                                      setPage('duration');
                                                }}>
                                                      Hobby
                                                </div>
                                                <div className="chooseSessionType zazen-session" onClick={() => {
                                                      setSessionType('zazen');
                                                      setPage('duration');
                                                }}>
                                                      Zazen
                                                </div>

                                          </div>

                                    </div>
                                    : page == 'duration' ?
                                          <div className="create-session-page zenK">
                                                <h3>Set Session Duration</h3>
                                                <div style={{ width: "200px", display: "flex", flexDirection: "row", margin: "auto" }}>

                                                      <input
                                                            placeholder="Hours"
                                                            value={durationH}
                                                            onChange={(e) => setDurationH(e.target.value)}
                                                            className='cs-duration-input'
                                                            type="number"
                                                      />
                                                      <input
                                                            placeholder="Minutes"
                                                            value={durationM}
                                                            onChange={(e) => setDurationM(e.target.value)}
                                                            className='cs-duration-input'
                                                            type="number"
                                                      />

                                                </div>
                                          </div>
                                          : page == 'title' ?
                                                <div className="create-session-page zenK">
                                                      <h3>Set Title</h3>
                                                      <input
                                                            placeholder={sessionType == 'zazen' ? "Do Nothing"
                                                                  : sessionType == 'hobby' ? "Go for a mindful walk"
                                                                        : sessionType == 'lecture' ? "Listen to a mindfulness lecture"
                                                                              : sessionType == 'relax' ? "Some me time"
                                                                                    : sessionType == 'yoga' ? "Yoga"
                                                                                          : "Mindful Breathing"}
                                                            value={title}
                                                            onChange={(e) => setTitle(e.target.value)}
                                                            className='su-text-input'
                                                            type="text"
                                                      />
                                                </div>
                                                // : page == 'occur' ?
                                                //       <div className="create-session-page zenK">
                                                //             <h3>When do you want this session to occur?</h3>
                                                //             <div className="task-button-alt" style={{ marginTop: "25px" }} onClick={() => { setIsBlueprintSession('no'); setPage("days") }}>
                                                //                   <h1>Just This Week</h1>
                                                //             </div>
                                                //             <div className="task-button-alt" style={{ marginTop: "25px" }} onClick={() => { setIsBlueprintSession('yes'); setPage("days"); }}>
                                                //                   <h1>Reoccur Each Week</h1>
                                                //             </div>
                                                //       </div>
                                                : page == 'days' ?
                                                      <div className="create-session-page zenK">
                                                            <h3>Choose which days you want the session to occur:</h3>
                                                            {dayOptions}
                                                      </div>

                                                      : page == 'summary' ?
                                                            <div>
                                                                  <ZenPracticeSessionPreview session={{ dayOfWeek: days.join(","), sessionType: sessionType, title: title, duration: durationH && durationM ? (60 * parseInt(durationH)) + (parseInt(durationM)) : durationH ? 60 * parseInt(durationH) : parseInt(durationM) }}></ZenPracticeSessionPreview>
                                                            </div>
                                                            :
                                                            <></>
                              }

                              <div className="zenc-button-row">
                                    <div className="zencTextButton zencRedButton" onClick={() => props.setShowAddSession(false)}>
                                          cancel
                                    </div>
                                    <div className="zencTextButton zencGreenButton" onClick={() => {
                                          page == "summary" ?
                                                addSessions()
                                                : page == "duration" ? setPage('title')
                                                      : page == "title" ? setPage('days')
                                                            : setPage('summary')
                                    }}>
                                          confirm
                                    </div>
                              </div>
                        </div>
                  }
            </React.Fragment>
      );
}