import * as React from "react";
import { MyZenNavBar } from "../../common/navbar/MyZenNavBar";
import "../../../styling/common/index.scss";
import { ZenNavBar } from "../../common/navbar/ZenNavBar";
import { useState } from "react";
import axios from "axios";
import { useAuth } from "../../../hooks/auth";
import PrivateRoute from "../../common/PrivateRoute";
import { Link, useNavigate } from "react-router-dom";
import { ZEN_C_ENDPOINT } from "../../../config/EnvironmentalVariables";
import { PuffLoader } from "react-spinners";
import { useMyZenService } from "../../../hooks/ZenUserContext";
import { useZenCardsService } from "../../../hooks/ZenCardsContext";
import { ZenCardMilestoneNotice } from "../../explore/icons/ZenCardMilestoneNotice";
import { ZenCardPreviewInterface } from "../../explore/icons/ZenCardPreview";
import { getSubscriptionAllowanceMessage, getSubscriptionPrice } from "../../../service/ZenSubscriptionsService";


export const SubscriptionSummaryCard = (props: ZenCardPreviewInterface) => {

      let navigate = useNavigate();

      return (
          <div className={props.newCard? "cardFocusedSummary zenSubscriptionCard newZenCard" : "zenSubscriptionCard cardFocusedSummary"}  onClick={props.cardType == "POWER"? () => props.setConfirmIcon() : () => {}}>
             
                  <img className="zenCardSummaryPhoto" src={props.image? props.image : require("../../../static/photos/mainGroups/frog.png")} ></img>

  
              <h2 className="zenCardSummaryTitle centered-text">{props.title}</h2>
              <div className="zen-card-summary-description">
                  {getSubscriptionAllowanceMessage(props.title)}
                  {getSubscriptionPrice(props.title)}
              </div>


          </div>
      );
  
  }
  
  