import * as React from "react";
import { MyZenNavBar } from "../common/navbar/MyZenNavBar";
import "../../styling/common/index.scss";
import { ZenNavBar } from "../common/navbar/ZenNavBar";
import { useState } from "react";
import axios from "axios";
import { useAuth } from "../../hooks/auth";
import PrivateRoute from "../common/PrivateRoute";
import { useParams } from "react-router-dom";
import { ZEN_C_ENDPOINT } from "../../config/EnvironmentalVariables";
import PractitionerInterface from "./PractitionerInterface";
import { getContentListPreviews } from "../../service/ContentService";
import { PractitionerNavBar } from "./PractitionerNavBar";
import { PractitionerContent } from "./PractitionerContent";
import { PractitionerProfileHome } from "./PractitionerProfileHome";
import { useMyCommunityService } from "../../hooks/MyCommunityContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { PractitionerReadingList } from "./PractitionerReadingList";
import { PractitionerReelStream } from "./PractitionerReelStream";
import { CommunityProfileHome } from "./CommunityProfileHome";
import { useMyZenService } from "../../hooks/ZenUserContext";

export const CommunityProfile = (props: { practitionerInfo?: any }) => {

      const { communityId } = useParams();
      const communityService = useMyCommunityService();

      const [activePage, setActivePage] = useState("home");
      const [practitionerAttempts, setPractitionerAttempts] = useState(0);
      const [loadingPractitioner, setLoadingPractitioner] = useState(false);
      const [practitionerInfo, setPractitionerInfo] = useState(null);
      const [communityPractitioners, setCommunityPractitioners] = useState(null);
      const [isCommunityMember, setIsCommunityMember] = useState(false);

      let zenUserContext = useMyZenService();
      let auth = useAuth();

      React.useEffect(() => {
            zenUserContext.getProfile(auth.username);
      },[]);
      
      const isPractitioner = zenUserContext != null && zenUserContext.profile != null && zenUserContext.profile.practitioner;
      
      if(isPractitioner && !isCommunityMember){
            //get communityMemberDetails
      }
      

      const getPractitionerInfo = async () => {
            await axios.get(ZEN_C_ENDPOINT + `/myzen/practitioner/` + communityId)
                  .then(res => {
                        setPractitionerInfo(res.data);
                        setLoadingPractitioner(false);
                  }).catch(() => {
                        setLoadingPractitioner(false);
                  });
      }

      React.useEffect(() => {
            getPractitionerInfo();
      }, []);

      const links = practitionerInfo && practitionerInfo.links && practitionerInfo.links.split('|').length > 0 ? practitionerInfo.links.split('|') : null;

      return (

            <div className="MyZenProfile">
                  <ZenNavBar />
                  <PractitionerNavBar
                        setPage={setActivePage} />
                  {activePage == "ProfilePic" ?
                        <div className="ZoomedProfileItemHolder">
                              <div className="ZoomedProfileItem">
                        <div className = "CloseZoomedProfileItem" onClick = {() => setActivePage("home")}></div>
                                    <img
                                          className={"ZoomedProfilePic"}
                                          src={practitionerInfo && practitionerInfo.image != null ? practitionerInfo.image : null}>
                                    </img>
                              </div>
                        </div> 
                  :activePage == "fullBio"?
                  <div className="ZoomedProfileItemHolder">
                              <div className="ZoomedProfileItem">
                                     <div className = "CloseZoomedProfileItem" onClick = {() => setActivePage("home")}></div>
                                    <h4 style = {{padding:"15px"}}>{practitionerInfo? practitionerInfo.bio: null}</h4>
                              </div>
                        </div> 
                  : null}
                  <div className = "practitionerProfileMainArea">
                        {activePage == "Reel"? <PractitionerReelStream username = {communityId}></PractitionerReelStream>
                        :activePage == "home" || activePage == "ProfilePic" ?
                              <div style={activePage == "ProfilePic" ? { opacity: "0.7" } : {}}>
                                    <div style={{ display: "flex", flexDirection: "row", margin: "auto", marginTop: "60px", width: "100%", minHeight:"200px" }}>
                                          {practitionerInfo && practitionerInfo.image != null && activePage != "ProfilePic" ?
                                                <div style={practitionerInfo && practitionerInfo.bio ? { margin: "auto", minWidth: "45%" } : { margin: "auto" }}>
                                                      <img
                                                            className={"practitionerProfilePic communityProfilePic"}
                                                            src={practitionerInfo && practitionerInfo.image != null ? practitionerInfo.image : null}
                                                            onClick={() => setActivePage("ProfilePic")}></img>
                                                      <h3 className=" zenK myzenDetails-username">{practitionerInfo ? practitionerInfo.username : null}</h3>
                                                </div> : null}
                                          {practitionerInfo && practitionerInfo.bio ?
                                                <div style={{ margin: "auto", textAlign: "center", padding: "25px", maxWidth: "60%" }}>
                                                      <span onClick = {() => setActivePage("fullBio")}>
                                                      {practitionerInfo.bio.substring(0,250)}{practitionerInfo.bio.length>250?"...":null}
                                                      </span>
                                                      {links && links.length > 0 ?
                                                            <div style={{ marginTop: "5px" }}>
                                                                  {links.map(l => <div
                                                                        className="zenPractitionerBlogLink"
                                                                        onClick={() => { window.open(l, '_blank'); }}>
                                                                        <FontAwesomeIcon icon={faLink} className="pbLink" />
                                                                        {l}
                                                                  </div>)}
                                                            </div> : null}
                                                </div>
                                                : null}
                                    </div>
                                    <div style = {{display:"flex", flexDirection:"row", width:"350px", margin:"auto"}}>
                                    {practitionerInfo && communityService.checkIfUserFollowsPractitioner(practitionerInfo.username) ?
                                          <div className="zenGlowButton buddGlowButton">Unfollow</div>
                                          :
                                          <div className="zenGlowButton sageGlowButton" onClick={() => {
                                                communityService.followPractitioner(practitionerInfo.username);
                                          }}>Follow</div>
                                    }
                                    {isPractitioner?
                                    <div className = "zenGlowButton mingGlowButton">Join as Practitioner</div>
                                    :null}
                                    </div>

                                    {practitionerInfo?
                                    <CommunityProfileHome
                                          practitionerInfo={practitionerInfo} 
                                          setActivePage = {setActivePage}/>
                                    :null}
                              </div>
                              : activePage == "content" ?
                                    <div style={{ marginTop: "25px", maxWidth: "100vw" }}>
                                          <PractitionerContent
                                                practitionerInfo={practitionerInfo}
                                                username={communityId} />
                                    </div>

                                    : <div style={{ margin: "auto", textAlign: "center", marginTop: "175px" }}>
                                          <h2>Zen Reels coming soon!</h2>
                                    </div>
                        }
                  </div>

            </div>

      );

}
