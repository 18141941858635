import React, { useState } from 'react';
import "../../../styling/vacana/vacana.scss";
import "../../../styling/common/index.scss";
import "../../../styling/zenverse/Nordii300.scss";
import "../../../styling/stream/stream.scss";
import "../../../styling/zenverse/Zenverse.scss";
import "../../../styling/common/zenCoreUIComponents.scss";
import { useNavigate } from 'react-router';
import { ZenPracticePortal } from '../ZenPracticePortal';
import { ZenscapeDemoPortal } from '../ZenscapeDemoPortal';
import { ZenNavBar } from '../../common/navbar/ZenNavBar';
import { useZenverseService } from '../../../hooks/ZenverseContext';
import ZenscapeInterface from '../../zenverse/interfaces/ZenscapeInterface';
import PrivateRoute from '../../common/PrivateRoute';

export const StarterPortal = () => {

  const [showInstructions, setShowInstructions] = useState(true);
  const [finished, setFinished] = useState(false);
  let navigate = useNavigate();

  const finishMed = () => {
    setFinished(true);
  }

  let zenverse = useZenverseService();

  if (zenverse.resultPortals == null && !zenverse.searchPending) {
    zenverse.searchPortalsByTag("starter");
  }

  const finishPage = <div className='ming-page'>
    <div className="ming-page-text">
      <h1>Nice Work! </h1>
      <h4>You’ve made your first steps towards developing a mindfulness routine. And It wasn’t too hard, was it? Absolutely anyone can do it really. We recommend you to introduce yourself slowly and it doesn’t have to get too much more difficult than that!</h4>
      <br></br>You’ve also unlocked our range of Zen Smart Planning Tools. Check out you’re new MyZen tasks to find out more!
      {/* Set Honeycohm acheivements -  portalWalkthrough */}
      <div className='finish-session-icon' onClick={() => navigate('/myzen')}></div>
    </div>
  </div>;

  return (
    <PrivateRoute>
    <React.Fragment>
      {showInstructions ?
        <div className='ming-page'>
          <div className="ming-page-text">
            <h3>Welcome to the Zen Practice Portal Browser</h3>
            <h4>The Zen Practice Portal Browser can be used to listen to guided meditations, watch "how to" videos, launch virtual escapes, and much more!</h4>
            <h4>Let's start by exploring some of the Zen Portals which can be used to supplement a meditation practice by providing breathing aids, background music</h4>
            <h4>If you see some you like, hit the + icon to add them to your inventory, they'll be available whenever you launch a practice!</h4>
            <div className='finish-session-icon' onClick={() => setShowInstructions(false)}></div>
          </div>
        </div>
        :
        <div className="zenverseBrowser">
        <ZenscapeDemoPortal zenscapes ={zenverse.resultPortals} finishPage={finishPage} finished={finished} curTime={0} setFinished={(finished) => finishMed()}/>
        </div>
      }
    </React.Fragment>
    </PrivateRoute>
  );
}