import axios from "axios";
import React, { createContext, useContext, useState } from "react";
import { getAllPowerCodes } from "../components/explore/icons/CardSubtypes";
import ZenCardInterface from "../components/explore/icons/ZenCardInterface";
import { ZEN_C_ENDPOINT } from "../config/EnvironmentalVariables";
import { useAuth } from "./auth";

interface UseZenQuotas {
  zenQuotasResults:any
}

type Props = {
  children?: React.ReactNode;
};
const zenQuotasContext = createContext({} as UseZenQuotas);

export const ProvideZenQuotasContext: React.FC<Props> = ({ children }) => {
  const zenQuotasService = ZenQuotasService();
  return <zenQuotasContext.Provider value={zenQuotasService}>{children}</zenQuotasContext.Provider>;
};

export const useZenQuotasService = () => {
  return useContext(zenQuotasContext);
};

export const ZenQuotasService = () => {
  const auth = useAuth();

  const [portalInventorySize, setInventorySize] = useState(0);
  const [portalTimeAllowance, setPortalTimeAllowance] = useState(0);
  const [subscriptionLevel, setSubscriptionLevel] = useState("Free");

  const [zenQuotasResults, setZenQuotasResults] = useState(null);
  const [userIcons, setUserIcons] = useState(null);
  const [searchPending, setSearchPending] = useState(false);
  const [getUserPOrtalTries, setGetUserPortalTries] = useState(4);
  const [resultType, setResultType] = useState(null);
  const [failedAttempts, setFailedAttempts] = useState(0);

  const getZenUserQuotas = async () => {
    setSearchPending(true);
    let result = await axios.get(ZEN_C_ENDPOINT + `/myzen/mysubscription/getUserAllowances/` + auth.username)
      .then(res => {
        setSearchPending(false);
        setZenQuotasResults(res.data);
      }).catch(() => {
        setFailedAttempts(failedAttempts + 1);
        setSearchPending(false);
      });

    return result;
  }

  if(zenQuotasResults == null && failedAttempts<5 && !searchPending){
    getZenUserQuotas();
  }

  return {
    zenQuotasResults

  };

}