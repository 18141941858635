import React, { useState } from 'react';
import "../../../../styling/vacana/vacana.scss";
import "../../../../styling/common/index.scss";
import "../../../../styling/zenverse/bubbleEnergy.scss";
import "../../../../styling/stream/stream.scss";
import "../../../../styling/zenverse/Zenverse.scss";
import OhmSoundInterface from '../OhmBoard/OhmSoundInterface';
import { GongInterface } from './GongWithTheWindPortal';
import { isNull } from 'util';

export const GongPreview = (props: GongInterface) => {

  return (
    <div className={props.isPrimaryGong ? "gong-option primary-gong-option" : props.isFinishGong ? "gong-option finish-gong-option" : "gong-option"}>
      <img className='gong-option-image' src={props.image} ></img>

      <div style={{ display: "flex", flexDirection: "row", width:"200px", margin:"auto", paddingTop:"15px", paddingBottom:"15px"}}>
        <div className="gong-label previewGongLabel" onClick={(e) => {
          props.playGongPreview();
        }} style = {props.previewPlaying? {opacity:0.4}: {}}>
          Play Preview
        </div>
        {props.isPrimaryGong ?
           <div className="gong-label choosenGongLabel">
           Primary Gong
       </div>
       : props.isFinishGong ?
          <div className="gong-label choosenGongLabel">
          Start/Finish Gong
      </div>
       :
            <div className="gong-label chooseGongLabel"  onClick={() => props.clickAction()}>
                Choose Gong
            </div>

        }

      </div>


      <h3 style={{ textAlign: "center", marginTop: "0" }}>{props.title}</h3>
    </div>
  );
}