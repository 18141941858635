import * as React from "react";
import "../../styling/explore/explore.scss";
import "../../styling/common/index.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../styling/common/searchBox.scss";
import { ZenNavBar } from "../common/navbar/ZenNavBar";
import { useLocation, useNavigate, useParams } from "react-router";
import { useZenExploreService } from "../../hooks/ZenExploreContext";
import { createAlphabetGroups, generateItemPreview, getMeditationGroups, getZencyclopaediaMainGroups } from "../../service/ZencyclopaediaService";
import { InfomaticTitlePage } from "./Infomatics/InfomaticTitlePage";
import { PuffLoader } from "react-spinners";
import { PracticePreview } from "../zencontent/practices/previews/PracticePreview";
import { useState } from "react";
import { PathPreview } from "../zencontent/courses/PathPreview";
import { ZenSearchBar } from "./search/ZenSearchBar";
import { useZenSearchService } from "../../hooks/ZenSearchContext";
import { MeditationsCarousel } from "./MeditationsCarousel";
import { useMyStuffService } from "../../hooks/MyStuffContext";

export const ExploreBrowser =  (props: any) => {

  const { searchId } = useParams();
  const [hideBackArrow, setHideBackArrow] = useState(false);
  const [searchKey, setSearchKey] = useState(searchId);
  const [searchPending, setSearchPending] = useState(false);
  const [retrievePending, setRetrievePending] = useState(false);
  const [results, setResults] = useState(null);
  const [resultsType, setResultsType] = useState(null);

  const useSearch = useZenSearchService();
  let navigate = useNavigate();

  const mockedSearchRoutes = ["main", "meditations", "communities"]; //searchKey routes where results are hard coded not received from back end.

  const retreiveResults = async () => {
    let result = await useSearch.filterMeditationsByTag(searchId,[]);
    setResults(result.map(m => <PracticePreview {...m}/>));
    setResultsType(searchId);
    setRetrievePending(false);
  }

  if (searchId && resultsType != searchId && useSearch.searchType != searchId + "-FAIL" && !useSearch.searchPending && !retrievePending &&  !mockedSearchRoutes.includes(searchId.toLowerCase())) {
    setRetrievePending(true);
    retreiveResults();
  } else if (searchId  && searchId.toLowerCase() == "meditations" && resultsType != "meditations"&& !searchPending && !retrievePending && mockedSearchRoutes.includes(searchId.toLowerCase())) {
      setResultsType("meditations");
      setResults([<MeditationsCarousel />]);
  } else if (searchId == null && resultsType != "main" && !useSearch.searchPending && !searchPending && !retrievePending){
    setResultsType("main");
    setResults(getZencyclopaediaMainGroups().map(i => generateItemPreview(i)));
  } else {
    console.log("nothing to see there: "+searchId);
  }

  const getContentSearchType = () => {
    if (searchId && searchId == "meditations" || props.meditations) {
      return 'MEDITATIONS';
    } else if (searchId && searchId == "articles" || props.infomatic) {
      return 'ARTICLES';

    } else return null;
  }

  const updateSearch = async (searchKey: string, tags: string[]) => {
    console.log("updating search");
    setRetrievePending(true);
    let result = await useSearch.filterMeditationsByTag(searchKey, tags);
    setResults(result.map(m => <PracticePreview {...m}/>));
    setRetrievePending(false);
  }
  
  const myStuffService = useMyStuffService();

  React.useEffect(() => {
    myStuffService.getBookmarks();
    myStuffService.getFavourites();
  }, []);

  return (
    <React.Fragment>
      <ZenNavBar noBorder = {true}></ZenNavBar>
      <div className="zencyclopaedia-browser splashedBacking">

        {searchId && !hideBackArrow || (searchKey && searchKey.length > 2) ? <div className='explore-back-icon' onClick={() => navigate(-1)}></div> : null}
        {searchId == "meditations" || getContentSearchType() == "MEDITATIONS" ?
          <ZenSearchBar
            handleSearchBarActive={() => setHideBackArrow(true)}
            searchKey={searchId && searchId != "meditations"? searchId : null}
            updateSearch={(searchKey, tags) => updateSearch(searchKey, tags)} />
          : null}

        <div className="zencyclopaedia-results">

          {retrievePending ?
            <div className="zencLoader">
              <PuffLoader color="#033f63" size={300}>
              </PuffLoader>
            </div>
            : results && results.length > 0 ?
              <>
                {props.meditations && searchKey ?
                  <h1 className="zen-c-results-header">Results for: {searchKey}</h1>
                  : props.meditations && searchId ?
                    <h1 className="zen-c-results-header">Results for: {searchId}</h1>
                    :
                    <></>}
                {results}
              </>
              :
              <div className="zencLoader">
                <h1>NO RESULTS</h1>
              </div>
          }
        </div>
      </div>
    </React.Fragment>
  );
}

