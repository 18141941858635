import * as React from "react";
import { useState } from "react";
import "../../styling/common/index.scss";
import ReactCrop, { type Crop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css';

export const ZenPicResizer = (props: any) => {

      const [croppedImage, setCroppedImage] = useState(null);

      const [crop, setCrop] = useState<Crop>({
            unit: 'px', // Can be 'px' or '%'
            x: 0,
            y: 0,
            width: 250,
            height: 250
      })

      const imageRef = React.useRef(null);

      const calculateImageDimensionsAndOffsets = () => {
            const originalWidth = imageRef.current && imageRef.current.clientWidth >0 ? imageRef.current.clientWidth : 250;
            const originalHeight = imageRef.current  && imageRef.current.clientHeight >0? imageRef.current.clientHeight : 250; // Replace with your original image height
            console.log(originalWidth);
            console.log(originalHeight);

            const targetSquareSize = 150;

            // Calculate the scaling factor based on the crop dimensions
            const hScaleFactor = originalHeight / crop.height;
            const wScaleFactor = originalWidth / crop.width;

            // Calculate the scaled image dimensions
            const scaledWidth = targetSquareSize * wScaleFactor;
            const scaledHeight = targetSquareSize * hScaleFactor;

            const xOffSetSF = crop.x / originalWidth;
            const xOffset = -1 * (scaledWidth * xOffSetSF);

            const yOffSetSF = crop.y / originalHeight;
            const yOffset = -1 * (scaledHeight * yOffSetSF)

            // const xOffset = -1 * (((targetSquareSize * wScaleFactor) / originalWidth) * crop.x);
            // const yOffset = -1 * (((targetSquareSize * hScaleFactor) / originalHeight) * crop.y);

            return { scaledWidth, scaledHeight, xOffset, yOffset };
      };

      const { scaledWidth, scaledHeight, xOffset, yOffset } = calculateImageDimensionsAndOffsets();







      return (
            <div className="confirmProfilePicSizeContainer">
                  <h2 style = {{textAlign:"center"}}>Confirm Picture Dimensions</h2>
                  <p  style = {{textAlign:"center"}}>Make sure your profile photo display's nicely in square & circle formats</p>
                  <div className="imageReSizerContainer">
                        <div className = "imageResizeResults">
                              <div style={{ display: "flex", flexDirection: "row" }}>


                                    <div className="content-preview-image practitioner-preview-image"
                                          style={{
                                                overflow: "hidden",
                                                height: "150px",
                                                maxHeight: "150px",
                                                minHeight: "150px",
                                                width: "150px",
                                                maxWidth: "150px"
                                          }}>

                                          <img
                                                style={{
                                                      position: "relative",
                                                      marginTop: yOffset,
                                                      marginLeft: xOffset,
                                                      width: scaledWidth,
                                                      height: scaledHeight,
                                                      objectFit: "contain"
                                                }}
                                                src={props.previewURL}></img>
                                    </div>


                                    <div className="content-preview-image practitioner-preview-image"
                                          style={{
                                                overflow: "hidden",
                                                height: "150px",
                                                maxHeight: "150px",
                                                minHeight: "150px",
                                                width: "150px",
                                                maxWidth: "150px",
                                                borderRadius: "50%"
                                          }}>

                                          <img
                                                style={{
                                                      position: "relative",
                                                      marginTop: yOffset,
                                                      marginLeft: xOffset,
                                                      width: scaledWidth,
                                                      height: scaledHeight,
                                                      objectFit: "contain"
                                                }}
                                                src={props.previewURL}></img>
                                    </div>


                              </div>

                              <div className="zenGlowButton sageGlowButton" onClick={() => props.upload({
                                    cropX: crop.x,
                                    cropY: crop.y,
                                    cropWidth: crop.width
                              })}>
                                    Upload
                              </div>
                        </div>
                        <div style={{ margin: "auto", width: "300px" }}>
                              <ReactCrop crop={crop} aspect={1} onChange={c => setCrop(c)}>
                                    <img ref={imageRef} className="image-dimensions-image" src={props.previewURL}></img>
                              </ReactCrop>
                        </div>
                  </div>


            </div>
      )


}
