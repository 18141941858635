import React, { useState } from 'react';
import "../../../styling/vacana/vacana.scss";
import "../../../styling/common/index.scss";
import "../../../styling/zenverse/Nordii300.scss";
import "../../../styling/stream/stream.scss";
import "../../../styling/zenverse/Zenverse.scss";

export const NordiiPortal = () => {

  return (
    <div className='nordii-holder'>
      <div className="nordii">
        <div className="illustration">
          <div className="yogi-lady">
            <div className="breath">
              <div className="inhale"></div>
              <div className="exhale"></div>
              <div className="hold hold1"></div>
              <div className="hold hold2"></div>
              <span className="text inhale-text">inhale</span>
              <span className="text hold1-text">hold</span>
              <span className="text exhale-text">exhale</span>
              <span className="text hold2-text">hold</span>
            </div>
            <div className="hair">
              <div className="face-up">
                <div className="eyes"></div>
              </div>
              <div className="face body">
                <div className="nose"></div>
                <div className="lips"></div>
              </div>
              <div className="neck body"></div>
              <div className="tshirt"></div>
              <div className="decoltee body"></div>
            </div>
            <div className="arms body"></div>
            <div className="right-hand body">
              <div className="right-hand-in">
                <div className="finger finger-1"></div>
                <div className="finger finger-2"></div>
                <div className="finger finger-3"></div>
                <div className="finger finger-4"></div>
              </div>
            </div>
            <div className="left-hand body">
              <div className="left-hand-in">
                <div className="finger-l finger-l-1"></div>
                <div className="finger-l finger-l-2"></div>
                <div className="finger-l finger-l-3"></div>
                <div className="finger-l finger-l-4"></div>
              </div>
            </div>
          </div>
        </div></div></div>
  );
}